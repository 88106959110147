import {
   Box,
   Card,
   CardContent,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
} from "@material-ui/core"
import { format, parseISO } from "date-fns"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { PreSaleAction } from "services/commonApi"
import Label from "views/common/Label"
import DeletePreSaleActionCTA from "../DeletePreSaleActionCTA"
import EditPreSaleActionCTA from "../EditPreSaleActionCTA"

const fields: {
   key: keyof PreSaleAction
   format: (value: PreSaleAction[keyof PreSaleAction]) => ReactNode
   translationId: string
}[] = [
   {
      key: "threshold",
      format: (value: PreSaleAction[keyof PreSaleAction]) => (
         <ListItemText primary={value} />
      ),
      translationId: "settings.pre_sale_actions.fields.threshold",
   },
   {
      key: "action_type",
      format: (value: PreSaleAction[keyof PreSaleAction]) => (
         <Label color="primary">{value}</Label>
      ),
      translationId: "settings.pre_sale_actions.fields.action_type",
   },
   {
      key: "target",
      format: (value: PreSaleAction[keyof PreSaleAction]) => (
         <ListItemText primary={value} />
      ),
      translationId: "settings.pre_sale_actions.fields.target",
   },
   {
      key: "enabled",
      format: (value: PreSaleAction[keyof PreSaleAction]) => (
         <Label color={value ? "success" : "error"}>{String(value)}</Label>
      ),
      translationId: "settings.pre_sale_actions.fields.enabled",
   },
   {
      key: "running",
      format: (value: PreSaleAction[keyof PreSaleAction]) => (
         <Label color={value ? "success" : "error"}>{String(value)}</Label>
      ),
      translationId: "settings.pre_sale_actions.fields.running",
   },
   {
      key: "created_at",
      format: (value: PreSaleAction[keyof PreSaleAction]) =>
         typeof value === "string" ? (
            <ListItemText
               primary={format(parseISO(value), "dd MMM yyyy HH:mm:ss")}
            />
         ) : null,
      translationId: "settings.pre_sale_actions.fields.created_at",
   },
   {
      key: "updated_at",
      format: (value: PreSaleAction[keyof PreSaleAction]) =>
         typeof value === "string" ? (
            <ListItemText
               primary={format(parseISO(value), "dd MMM yyyy HH:mm:ss")}
            />
         ) : null,
      translationId: "settings.pre_sale_actions.fields.updated_at",
   },
]

type Props = {
   preSaleAction: PreSaleAction
}

const Item = ({ preSaleAction }: Props) => {
   const { t } = useTranslation()

   return (
      <Card>
         <Box display="flex" p={2}>
            <Box mr={1}>
               <EditPreSaleActionCTA preSaleAction={preSaleAction} />
            </Box>
            <DeletePreSaleActionCTA uuid={preSaleAction.uuid} />
         </Box>

         <CardContent>
            <List>
               {fields.map(({ key, format, translationId }) => (
                  <ListItem key={key}>
                     <ListItemText primary={t(translationId)} />

                     <ListItemSecondaryAction>
                        {format(preSaleAction[key])}
                     </ListItemSecondaryAction>
                  </ListItem>
               ))}
            </List>
         </CardContent>
      </Card>
   )
}

export default Item
