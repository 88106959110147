import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   Checkbox,
   Grid,
   Select,
   Typography,
} from "@material-ui/core"
import AccordionActions from "@material-ui/core/AccordionActions"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { DateInput, dateInputDelimiter } from "components/DateInput"
import { Field, Formik, FormikHelpers } from "formik"
import { TextField } from "formik-material-ui"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { formatDate } from "services/dates"
import {
   useGetBusinessRegistryConfigurationsQuery,
   useLazyGetBusinessRegistryConfigurationQuery,
} from "services/gov-it/businessRegistryConfigurations"
import {
   SmartReceiptStatus,
   TableSmartReceiptKeyType,
} from "services/gov-it/smartReceipt/type"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   resetFilters,
   selectTableFilters,
   TablesState,
   updateFilter,
} from "store/tablesState"
import { readTableFilters } from "store/tablesState/utils"
import { formatBusinessRegistryConfigurations } from "views/BusinessRegistryConfigurations/helper"
import { Marking } from "views/common/Marking"
import SelectAutoComplete from "views/common/SelectAutoComplete"
import { SmartReceiptEmptyValues, SmartReceiptFormValues } from "./utils"

type TableFilters = TablesState[TableSmartReceiptKeyType]["filters"]
export type TableFilter = keyof TableFilters

const selectInput = [
   {
      label: "smartReceipt.status",
      name: "status",
   },
] as {
   label: string
   name: TableFilter
}[]

type smartReceiptTableFiltersProps = {
   table: TableSmartReceiptKeyType
}
const SmartReceiptTableFilters = ({ table }: smartReceiptTableFiltersProps) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const [
      getBusinessRegistryConfiguration,
      { isLoading, isError: isErrorFetching },
   ] = useLazyGetBusinessRegistryConfigurationQuery()

   const { data: BusinessRegistryData, isLoading: isLoadingFiscalId } =
      useGetBusinessRegistryConfigurationsQuery({})

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const [businessRegistryFiscalIdsReset, setBusinessRegistryFiscalIdsReset] =
      React.useState<boolean>(false)

   const after = filters.createdAt.after

   const before = filters.createdAt.before

   const { createdAt, documentDate, ...filtersRest } = filters

   const initialValues = {
      ...filtersRest,
      createdAt:
         createdAt.after && createdAt.before
            ? `${createdAt.after}${dateInputDelimiter}${createdAt.before}`
            : "",
      documentDate:
         documentDate.after && documentDate.before
            ? `${documentDate.after}${dateInputDelimiter}${documentDate.before}`
            : "",
   }

   const filterChanged = () => {
      const temps = readTableFilters(table)
      if (!temps) return false
      return !_.isEqual(temps, {
         ...SmartReceiptEmptyValues,
         createdAt: {
            after: filters.createdAt.after,
            before: filters.createdAt.before,
         },
         documentDate: {
            after: filters.documentDate.after,
            before: filters.documentDate.before,
         },
      })
   }

   const [expanded, setExpanded] = React.useState(filterChanged)

   const handleReset = (
      setValues: FormikHelpers<SmartReceiptFormValues>["setValues"]
   ) => {
      dispatch(changePage({ table, page: 1 }))
      dispatch(resetFilters({ table }))
      setBusinessRegistryFiscalIdsReset(true)
      const values = {
         ...SmartReceiptEmptyValues,
         createdAt:
            createdAt.after && createdAt.before
               ? `${createdAt.after}${dateInputDelimiter}${createdAt.before}`
               : "",
         documentDate:
            documentDate.after && documentDate.before
               ? `${documentDate.after}${dateInputDelimiter}${documentDate.before}`
               : "",
      }
      setValues(values)
      handleFilter(values)
      setExpanded(false)
   }

   const handleFilter = (values: SmartReceiptFormValues) => {
      dispatch(changePage({ table, page: 1 }))
      const dateFilters = ["createdAt", "documentDate"]
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]

            if (dateFilters.includes(filter)) {
               const [after, before] = (value as string).split(
                  dateInputDelimiter
               )

               let filterChangedDate =
                  after !== undefined &&
                  after !== "" &&
                  before !== undefined &&
                  before !== ""

               const tempFilter = filters[filter] as {
                  after: string
                  before: string
               }

               if (
                  filterChangedDate &&
                  (after !== tempFilter.after || before !== tempFilter.before)
               ) {
                  dispatch(
                     updateFilter({ table, filter, value: { after, before } })
                  )
               }
            }

            if (filterChanged && !dateFilters.includes(filter)) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   const getSelectInput = (name: TableFilter) => {
      if (name === "status") return SmartReceiptStatus
      return []
   }

   const updateOptionsFiscalIds = async (value: string) => {
      const { data } = await getBusinessRegistryConfiguration({
         fiscal_id: value,
      })

      let fiscalIds: string[] = []
      if (isErrorFetching) {
         fiscalIds = []
      }

      if (data) {
         fiscalIds = [data.fiscal_id]
      }

      return fiscalIds
   }

   const itemsBusinessRegistryData = BusinessRegistryData
      ? "hydra:member" in BusinessRegistryData
         ? formatBusinessRegistryConfigurations(
              BusinessRegistryData["hydra:member"]
           )
         : formatBusinessRegistryConfigurations([BusinessRegistryData])
      : undefined

   return (
      <Box mb={4}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleFilter}
            enableReinitialize
         >
            {({
               handleBlur,
               handleChange,
               handleSubmit,
               values,
               setValues,
               resetForm,
               setFieldValue,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Accordion
                     elevation={1}
                     expanded={expanded}
                     onChange={(_, expanded) => setExpanded(expanded)}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="smart_receipt_filters"
                        id="smart_receipt_filters"
                     >
                        <div>
                           <Typography>
                              {t("Default.Filtra i risultati")}{" "}
                              {after &&
                                 before &&
                                 t("smartReceipt.dateRange", {
                                    from: formatDate(after),
                                    to: formatDate(before),
                                 })}
                           </Typography>
                        </div>
                     </AccordionSummary>

                     <AccordionDetails>
                        <Grid container spacing={3}>
                           <Grid item md={6} xs={12}>
                              <DateInput
                                 label={t("smartReceipt.createdAt[after]")}
                                 name="createdAt"
                                 onChange={handleChange("createdAt")}
                                 value={values.createdAt}
                              />
                           </Grid>
                           <Grid item md={6} xs={12}>
                              <DateInput
                                 label={t("smartReceipt.documentDate[after]")}
                                 name="documentDate"
                                 onChange={handleChange("documentDate")}
                                 value={values.documentDate}
                              />
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t("smartReceipt.id")}
                                 name="id"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                           {selectInput.map(({ label, name }) => (
                              <Grid
                                 item
                                 md={4}
                                 xs={12}
                                 key={`${label}-${name}`}
                              >
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor={name}>
                                       {t(label)}
                                    </InputLabel>
                                    <Select
                                       disabled={
                                          values.id !== "" ||
                                          getSelectInput(name).length === 0
                                       }
                                       name={name}
                                       multiple
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (
                                             (
                                                e.target.value as string[]
                                             ).includes("None")
                                          ) {
                                             setFieldValue(name, [])
                                             return
                                          }
                                          handleChange(e)
                                       }}
                                       value={
                                          values[
                                             name as keyof SmartReceiptFormValues
                                          ]
                                       }
                                       renderValue={(selected) => {
                                          const tmps =
                                             (selected as string[]).length > 3
                                                ? (selected as string[]).slice(
                                                     -3
                                                  )
                                                : (selected as string[])
                                          return (
                                             <Box
                                                display="flex"
                                                flexWrap="wrap"
                                             >
                                                {(selected as string[]).length >
                                                   3 && "..."}{" "}
                                                {tmps.map((marking) => (
                                                   <Marking
                                                      key={marking}
                                                      marking={marking}
                                                   />
                                                ))}
                                             </Box>
                                          )
                                       }}
                                       fullWidth
                                    >
                                       <MenuItem value={"None"}>
                                          <Marking marking={"None"} />
                                       </MenuItem>
                                       {getSelectInput(name).map((state) => (
                                          <MenuItem key={state} value={state}>
                                             <Checkbox
                                                checked={
                                                   values[
                                                      name as
                                                         | "status"
                                                         | "businessRegistryConfigurationsFiscalId"
                                                         | "documentNumber"
                                                   ].indexOf(state) > -1
                                                }
                                             />
                                             <Marking marking={state} />
                                          </MenuItem>
                                       ))}
                                    </Select>
                                 </FormControl>
                              </Grid>
                           ))}
                           <Grid item md={4} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t("smartReceipt.document_number")}
                                 name="documentNumber"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item md={12} xs={12}>
                              <SelectAutoComplete
                                 name="businessRegistryConfigurationsFiscalId"
                                 label={t(
                                    "smartReceipt.businessRegistryConfigurationsFiscalIds"
                                 )}
                                 initialOptions={
                                    itemsBusinessRegistryData?.map((data) => ({
                                       name: data.fiscal_id,
                                    })) ?? []
                                 }
                                 loading={isLoading || isLoadingFiscalId}
                                 handleUpdateOptions={updateOptionsFiscalIds}
                                 multiple
                                 handleChange={(value) => {
                                    const fiscalIds = value.map(
                                       (fiscalId) => fiscalId.name
                                    )

                                    if (
                                       fiscalIds !==
                                       values.businessRegistryConfigurationsFiscalId
                                    ) {
                                       setFieldValue(
                                          "businessRegistryConfigurationsFiscalId",
                                          value
                                       )
                                    }
                                 }}
                                 value={values.businessRegistryConfigurationsFiscalId.map(
                                    (fiscalId) => ({
                                       name: fiscalId as string,
                                    })
                                 )}
                                 resetForce={businessRegistryFiscalIdsReset}
                                 disabledResetForce={() =>
                                    setBusinessRegistryFiscalIdsReset(false)
                                 }
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>
                     <AccordionActions>
                        <Button
                           onClick={() => {
                              resetForm()
                              handleReset(setValues)
                           }}
                           variant="contained"
                        >
                           {t("Default.Cancella")}
                        </Button>
                        <Button
                           type="submit"
                           color="primary"
                           variant="contained"
                        >
                           {t("Default.Cerca")}
                        </Button>
                     </AccordionActions>
                  </Accordion>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default SmartReceiptTableFilters
