import { BUSINESS_REGISTRY_CONFIGURATIONS } from "endpoints"
import { buildSearchParams } from "helper/buildSearchParams"
import { govItApi, GovITPaginationParams } from "services/gov-it"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import { HydraResponse } from "services/types"

type GetBusinessRegistryConfigurationsParams = GovITPaginationParams & {
   receipts_enabled?: 0 | 1
}

type BusinessRegistryConfigurationsResponse =
   HydraResponse<BusinessRegistryConfigurationType>

type BusinessRegistryConfigurationResponse = BusinessRegistryConfigurationType

type Nullable<T> = {
   [K in keyof T]: Nullable<T[K]> | null
}

type EditBusinessRegistryConfigurationParams = Nullable<
   Omit<
      BusinessRegistryConfigurationType,
      | "email_for_preservation_requested_at"
      | "supplier_invoice"
      | "legal_storage_active"
      | "signature"
      | "api_configurations"
   >
> & {
   api_configurations?: string[]
}

type CreateBusinessRegistryConfigurationParams = Omit<
   BusinessRegistryConfigurationType,
   | "fiscal_id"
   | "email_for_preservation_requested_at"
   | "supplier_invoice"
   | "legal_storage_active"
   | "signature"
   | "api_configurations"
> & {
   api_configurations?: string[]
}

type BusinessRegistryConfigurationSetCredentials = {
   codice_fiscale: string
   password: string
   pin: string
}

type UpdateSettingsRelatesToSmartReceipt = {
   receipts_alert_recipients: string[]
   phone_number: string | null
}

const govItApiBusinessRegistryConfigurationsApi = govItApi.injectEndpoints({
   endpoints: (builder) => ({
      getBusinessRegistryConfigurations: builder.query<
         BusinessRegistryConfigurationsResponse,
         GetBusinessRegistryConfigurationsParams
      >({
         query: (params) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}${buildSearchParams({
               ...params,
               ...(params.refetch
                  ? { refetch: params.refetch * Math.random() }
                  : { refetch: Math.random() * 100 }),
            })}`,
         }),
         providesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      getBusinessRegistryConfiguration: builder.query<
         BusinessRegistryConfigurationResponse,
         { fiscal_id: string }
      >({
         query: ({ fiscal_id }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}?refetch=${Math.random()}`,
         }),
         providesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      createBusinessRegistryConfiguration: builder.mutation<
         BusinessRegistryConfigurationResponse,
         CreateBusinessRegistryConfigurationParams
      >({
         query: (body) => ({
            url: BUSINESS_REGISTRY_CONFIGURATIONS,
            method: "POST",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      editBusinessRegistryConfiguration: builder.mutation<
         BusinessRegistryConfigurationResponse,
         EditBusinessRegistryConfigurationParams
      >({
         query: ({ fiscal_id, ...body }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      deleteBusinessRegistryConfiguration: builder.mutation<
         void,
         { fiscal_id: string }
      >({
         query: ({ fiscal_id }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      setCredentialsBusinessRegistryConfiguration: builder.mutation<
         void,
         BusinessRegistryConfigurationSetCredentials & { id: string }
      >({
         query: ({ id, ...body }) => ({
            url: `/business-registry-configurations/${id}/credentials/fisconline`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      updateSettingsRelatesToSmartReceipt: builder.mutation<
         UpdateSettingsRelatesToSmartReceipt,
         UpdateSettingsRelatesToSmartReceipt & { id: string }
      >({
         query: ({ id, ...body }) => ({
            url: `/business-registry-configurations/${id}/receipt-settings`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      getSettingRelatesToSmartReceipt: builder.query<
         UpdateSettingsRelatesToSmartReceipt,
         { id: string }
      >({
         query: ({ id }) => ({
            url: `/business-registry-configurations/${id}/receipt-settings?refetch=${Math.random()}`,
            headers: {
               accept: "application/json",
            },
         }),
      }),

      listBusinessRegistryConfigurationsBySubAccountEmail: builder.query<
         BusinessRegistryConfigurationResponse[],
         { email: string }
      >({
         query: ({ email }) => ({
            url: `/business-registry-configurations/sub-accounts/${email}?refetch=${Math.random()}`,
         }),
      }),

      listSubAccountConnectedToBusinessRegistryConfiguration: builder.query<
         { email: string }[],
         { id: string; refresh?: string }
      >({
         query: ({ id, refresh }) => ({
            url: `/business-registry-configurations/${id}/sub-accounts${buildSearchParams(
               {
                  ...(refresh
                     ? { refresh }
                     : {
                          refetch: Math.random() * 100,
                       }),
               }
            )}`,
         }),
         providesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS_SUB_ACCOUNTS"],
      }),

      addNewSubAccountToBusinessRegistryConfiguration: builder.mutation<
         { email: string },
         { email: string; password?: string | null; id: string }
      >({
         query: ({ id, ...body }) => ({
            url: `/business-registry-configurations/${id}/sub-accounts`,
            method: "POST",
            body: {
               ...body,
               password: body.password ?? "",
            },
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS_SUB_ACCOUNTS"],
      }),

      removeSubAccountFromBusinessRegistryConfiguration: builder.mutation<
         void,
         { email: string; id: string }
      >({
         query: ({ id, email }) => ({
            url: `/business-registry-configurations/${id}/sub-accounts/${email}`,
            method: "DELETE",
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS_SUB_ACCOUNTS"],
      }),

      handleInteractiveSetCredentialsBusinessRegistryConfiguration:
         builder.mutation<{ url: string }, { id: string }>({
            query: ({ id }) => ({
               url: `/business-registry-configurations/${id}/credentials/fisconline/interactive`,
               method: "POST",
            }),
            invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
         }),
   }),
})

export const {
   useGetBusinessRegistryConfigurationsQuery,
   useGetBusinessRegistryConfigurationQuery,
   useLazyGetBusinessRegistryConfigurationQuery,
   useCreateBusinessRegistryConfigurationMutation,
   useEditBusinessRegistryConfigurationMutation,
   useDeleteBusinessRegistryConfigurationMutation,
   useSetCredentialsBusinessRegistryConfigurationMutation,
   useUpdateSettingsRelatesToSmartReceiptMutation,
   useListSubAccountConnectedToBusinessRegistryConfigurationQuery,
   useAddNewSubAccountToBusinessRegistryConfigurationMutation,
   useRemoveSubAccountFromBusinessRegistryConfigurationMutation,
   useLazyListSubAccountConnectedToBusinessRegistryConfigurationQuery,
   useHandleInteractiveSetCredentialsBusinessRegistryConfigurationMutation,
   useListBusinessRegistryConfigurationsBySubAccountEmailQuery,
   useLazyListBusinessRegistryConfigurationsBySubAccountEmailQuery,
   useGetSettingRelatesToSmartReceiptQuery,
   useLazyGetSettingRelatesToSmartReceiptQuery,
} = govItApiBusinessRegistryConfigurationsApi
