// tslint:disable:ordered-imports
import {
   Box,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   makeStyles,
   Paper,
   Radio,
   RadioGroup,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import AceEditor from "react-ace"
import "ace-builds/webpack-resolver"
import InputFile from "views/common/InputFile"
import { InvoicePayload } from "services/gov-it/types"
require("ace-builds/src-noconflict/mode-json")
require("ace-builds/src-noconflict/mode-xml")

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

type CreateInvoiceFormProps = {
   source?: string
   handleSave: (
      value: string,
      format: "json" | "xml",
      type: "simple" | "simplified"
   ) => void
   handleClose: () => void
   isLoading: boolean
}
function CreateInvoiceForm(props: CreateInvoiceFormProps) {
   const classes = useStyles()
   const { t } = useTranslation()
   const [format, setFormat] = React.useState("json")
   const [type, setType] = React.useState("simple")
   const [source, setSource] = React.useState(props.source || "")

   const handleSubmit = function () {
      props.handleSave(
         source,
         format as "json" | "xml",
         type as "simple" | "simplified"
      )
   }

   const handleChangeFormat = function (
      event: React.ChangeEvent<HTMLInputElement>
   ) {
      setFormat(event.target.value)
   }

   const handleChangeType = function (
      event: React.ChangeEvent<HTMLInputElement>
   ) {
      setType(event.target.value)
   }

   const handleLoadFile = function (content: string, fileName: string) {
      let extension = fileName.split(".").pop()
      if (extension === "json") {
         setFormat("json")
      }
      if (extension === "xml") {
         setFormat("xml")
      }
      setSource(content)
   }

   const onChangeSource = function (value: string) {
      setSource(value)
   }

   return (
      <Box>
         <Grid container spacing={3}>
            <Grid item sm={4} xs={12} key={"create-invoice-type"}>
               <Paper>
                  <Box p={2}>
                     <FormControl
                        component="fieldset"
                        className={classes.formControl}
                     >
                        <FormLabel
                           component="legend"
                           className={classes.formLabel}
                        >
                           {t("CustomerInvoices.CreateInvoice.Invoice type")}
                        </FormLabel>
                        <RadioGroup
                           row
                           aria-label="invoice type"
                           name="type"
                           value={type}
                           onChange={handleChangeType}
                        >
                           <FormControlLabel
                              value="simple"
                              control={<Radio size="small" color="primary" />}
                              label={t(
                                 "CustomerInvoices.CreateInvoice.Fattura elettronica italiana"
                              )}
                           />
                           <FormControlLabel
                              value="simplified"
                              control={<Radio size="small" color="primary" />}
                              label={t(
                                 "CustomerInvoices.CreateInvoice.Fattura elettronica italiana semplificata"
                              )}
                           />
                        </RadioGroup>
                     </FormControl>
                  </Box>
               </Paper>
            </Grid>
            <Grid item sm={4} xs={12} key={"create-invoice-format"}>
               <Paper>
                  <Box p={2}>
                     <FormControl
                        component="fieldset"
                        className={classes.formControl}
                     >
                        <FormLabel
                           component="legend"
                           className={classes.formLabel}
                        >
                           {t("CustomerInvoices.CreateInvoice.Invoice format")}
                        </FormLabel>
                        <RadioGroup
                           row
                           aria-label="invoice format"
                           name="format"
                           value={format}
                           onChange={handleChangeFormat}
                        >
                           <FormControlLabel
                              value="json"
                              control={<Radio size="small" color="primary" />}
                              label="JSON"
                           />
                           <FormControlLabel
                              value="xml"
                              control={<Radio size="small" color="primary" />}
                              label="XML"
                           />
                        </RadioGroup>
                     </FormControl>
                  </Box>
               </Paper>
            </Grid>
            <Grid item sm={4} xs={12} key={"create-invoice-file-input"}>
               <Paper>
                  <Box p={1}>
                     <FormControl
                        component="fieldset"
                        className={classes.formControl}
                     >
                        <FormLabel
                           component="legend"
                           className={classes.formLabel}
                        >
                           {t("CustomerInvoices.CreateInvoice.Upload file")}
                        </FormLabel>
                        <InputFile
                           id="create-invoice-file-input"
                           callback={handleLoadFile}
                        />
                     </FormControl>
                  </Box>
               </Paper>
            </Grid>
            <Grid item xs={12} key={"create-invoice-textarea"}>
               {format && (
                  <AceEditor
                     placeholder={t(
                        "CustomerInvoices.CreateInvoice.Insert here the invoice source"
                     )}
                     mode={format}
                     theme="textmate"
                     name="source"
                     value={source}
                     onChange={(value) => {
                        onChangeSource(value)
                     }}
                     fontSize={16}
                     showPrintMargin={false}
                     showGutter={true}
                     highlightActiveLine={true}
                     width={"100%"}
                     setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                     }}
                  />
               )}
            </Grid>
         </Grid>

         <div className={classes.buttons}>
            <Button
               onClick={handleSubmit}
               color="primary"
               variant="contained"
               disabled={props.isLoading}
            >
               {t("Default.Invia")}
            </Button>
            <Button
               onClick={props.handleClose}
               color="default"
               variant="contained"
            >
               {t("Default.Annulla")}
            </Button>
         </div>
      </Box>
   )
}

CreateInvoiceForm.propTypes = {
   className: PropTypes.string,
}

export default CreateInvoiceForm
