import * as Yup from "yup"
// import { format, parseISO } from "date-fns"
import {
   Box,
   Button,
   CircularProgress,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   TextField,
   Typography,
} from "@material-ui/core"
import Description from "@material-ui/icons/Description"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   DemoInvoice,
   useArchiveSessionMutation,
   useGetLambdaInfoQuery,
} from "services/gov-pl/demoInvoices"
import AnimatedCheckmark from "views/common/AnimatedCheckmark"
import DownloadReceipt from "../DownloadReceipt"

const initialValues = { nip1: "" }

type FormValues = typeof initialValues

const validationSchema = Yup.object().shape({
   nip1: Yup.string().required(),
})

type Props = {
   invoice: DemoInvoice | undefined
}

const ArchiveSession = ({ invoice }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const { data: lambdaInfo } = useGetLambdaInfoQuery(undefined, {
      skip: invoice == null,
   })
   const [archiveSession, { isUninitialized, isLoading }] =
      useArchiveSessionMutation()

   const { invoiceNumber, attachments } = invoice ?? {}

   // const syncScheduledDate = lambdaInfo?.find(
   //    ({ name }) => name === "gov-pl-invoice-sync"
   // )?.expected_run_time
   // const upoFetchScheduledAt = lambdaInfo?.find(
   //    ({ name }) => name === "gov-pl-invoice-upo"
   // )?.expected_run_time

   const handleArchiveSession = async (values: FormValues) => {
      const response = await archiveSession({ nip1: Number(values.nip1) })

      if ("error" in response) {
         enqueueSnackbar(t("invoices_demo.error.generic"), { variant: "error" })
         return
      }
   }

   if (lambdaInfo == null || invoice == null) {
      return null
   }

   return (
      <Box mt={4}>
         <Box
            color="success.main"
            display="flex"
            alignItems="center"
            gridGap={8}
            mb={2}
         >
            <Box display="flex" mr={1}>
               <AnimatedCheckmark size={40} />
            </Box>
            <Typography variant="h3">
               {t("invoices_demo.single_invoice.success.send")}
            </Typography>
         </Box>

         <Box display="flex" flexDirection="column" mb={2}>
            <Typography variant="body1">
               {t("invoices_demo.single_invoice.details.invoice_number")}{" "}
               {invoiceNumber}
            </Typography>
         </Box>

         {attachments != null && attachments.length > 0 && (
            <Box display="flex" flexDirection="column" mb={2}>
               <Typography variant="body1">
                  {t("invoices_demo.single_invoice.details.attachments")}
               </Typography>
               <List dense>
                  {attachments.map(({ filename, filesize }) => (
                     <ListItem>
                        <ListItemIcon>
                           <Description />
                        </ListItemIcon>
                        <ListItemText
                           primary={filename}
                           secondary={`${filesize} MB`}
                        />
                     </ListItem>
                  ))}
               </List>
            </Box>
         )}

         {/* <Box display="flex" borderTop={1} borderColor="grey.300" pt={2}> */}
         {/*    <Typography variant="body1"> */}
         {/*       {t("invoices_demo.single_invoice.next_sync_date")}{" "} */}
         {/*       {syncScheduledDate && */}
         {/*          format(parseISO(syncScheduledDate), "dd MMM yyyy HH:mm")} */}
         {/*    </Typography> */}
         {/* </Box> */}

         {/* <Box display="flex" mb={2}> */}
         {/*    <Typography variant="body1"> */}
         {/*       {t("invoices_demo.single_invoice.upo_fetch_date")}{" "} */}
         {/*       {upoFetchScheduledAt && */}
         {/*          format(parseISO(upoFetchScheduledAt), "dd MMM yyyy HH:mm")} */}
         {/*    </Typography> */}
         {/* </Box> */}

         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleArchiveSession}
         >
            {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
               <form onSubmit={handleSubmit}>
                  <Box display="flex" gridGap={8}>
                     <TextField
                        error={touched.nip1 != null && errors.nip1 != null}
                        fullWidth
                        helperText={errors.nip1}
                        label={t("invoices_demo.single_invoice.fields.nip1")}
                        margin="none"
                        name="nip1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                     />

                     <Box>
                        <Button
                           disabled={isLoading}
                           color="primary"
                           size="large"
                           type="submit"
                           variant="contained"
                        >
                           {isLoading ? (
                              <>
                                 <Box display="flex" mr={1}>
                                    <CircularProgress
                                       size={24}
                                       color="inherit"
                                    />
                                 </Box>
                                 {t(
                                    "invoices_demo.single_invoice.archive_cta_loading"
                                 )}
                              </>
                           ) : (
                              t("invoices_demo.single_invoice.archive_cta")
                           )}
                        </Button>
                     </Box>
                  </Box>
               </form>
            )}
         </Formik>

         <DownloadReceipt
            invoiceUuid={invoice.uuid}
            isReady={!isUninitialized && !isLoading}
         />
      </Box>
   )
}

export default ArchiveSession
