import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { useEffect } from "react"
import { useGetBusinessRegistryConfigurationsQuery } from "services/gov-it/businessRegistryConfigurations"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectRefetch,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import ActionsButtons from "views/BusinessRegistryConfigurations/ActionsButtons"
import { formatBusinessRegistryConfigurations } from "views/BusinessRegistryConfigurations/helper"
import { RowCTAs } from "views/BusinessRegistryConfigurations/Table/RowCTAs"
import LabelBoolean from "views/common/LabelBoolean"

const table: TableName = "business_registry_configurations"
export type BusinessRegistryConfigurationsTableConfigurableColumns =
   NonNullable<(typeof columnsDefinition)[number]["rowKey"]>

const BusinessRegistryConfigurationsTable = () => {
   const dispatch = useAppDispatch()
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const { data, isFetching, isError } =
      useGetBusinessRegistryConfigurationsQuery({
         ...filters,
         ...(itemsPerPage === null ? {} : { itemsPerPage }),
         ...(currentPage === null ? {} : { page: currentPage }),
         ...(refresh === null ? {} : { refresh }),
      })

   const items = data
      ? "hydra:member" in data
         ? formatBusinessRegistryConfigurations(data["hydra:member"])
         : formatBusinessRegistryConfigurations([data])
      : undefined

   const fetchedTotalItems =
      data && "hydra:totalItems" in data ? data["hydra:totalItems"] : null

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={items}
            rowKeyId="fiscal_id"
            isFetching={isFetching}
            isError={isError}
            buttonActions={<ActionsButtons />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="global.messages.no_results"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
         />
      </Paper>
   )
}

export default BusinessRegistryConfigurationsTable

const columnsDefinition: TableColumn<BusinessRegistryConfigurationType>[] = [
   {
      rowKey: "fiscal_id",
      labelTranslationId: "BusinessRegistry.fields.Fiscal Id",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "email",
      labelTranslationId: "BusinessRegistry.fields.Email",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "name",
      labelTranslationId: "BusinessRegistry.fields.Name",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "supplier_invoice",
      labelTranslationId: "BusinessRegistry.fields.Supplier invoice",
      configurable: true,
      format: (value) => {
         return <LabelBoolean value={value as string | boolean} />
      },
   },
   {
      rowKey: "signature",
      labelTranslationId: "BusinessRegistry.fields.Signature",
      configurable: true,
      format: (value) => {
         return <LabelBoolean value={value as string | boolean} />
      },
   },
   {
      rowKey: "apply_legal_storage",
      labelTranslationId: "BusinessRegistry.fields.Legal storage",
      configurable: true,
      format: (value) => {
         return <LabelBoolean value={value as string | boolean} />
      },
   },
   {
      rowKey: "receipts_enabled",
      labelTranslationId: "BusinessRegistry.fields.receipts_enabled",
      configurable: true,
      format: (value) => {
         return <LabelBoolean value={value as string | boolean} />
      },
   },
   {
      rowKey: null,
      labelTranslationId: null,
      configurable: false,
      format: (value, item) => <RowCTAs item={item} />,
   },
]
