import { Box, DialogContent, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import { makeStyles } from "@material-ui/core/styles"
import BaseDialog from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import {
   useGetDetailOrPDFSmartReceiptQuery,
   useGetSmartReceiptQuery,
} from "services/gov-it/smartReceipt"
import { TableSmartReceiptKeyType } from "services/gov-it/smartReceipt/type"
import { DialogId, SmartReceiptDetailsMode } from "store/slices/dialogs"
import Loader from "views/common/Loader"
import SmartReceiptDetailsTabs from "./SmartReceiptDetails"

const DIALOG_ID: DialogId = "smartReceipt.details"

type ContentProps = {
   handleClose: VoidFunction
   uuid: string
   table: TableSmartReceiptKeyType
   mode: SmartReceiptDetailsMode
}
const Content = ({ handleClose, uuid, mode, table }: ContentProps) => {
   const { t } = useTranslation()
   const classes = useStyles()

   const { data: smartReceiptData, isFetching: isFetchingSmartReceiptData } =
      useGetSmartReceiptQuery({
         id: uuid,
      })

   const { data, isFetching } = useGetDetailOrPDFSmartReceiptQuery({
      id: uuid,
   })

   return (
      <>
         <DialogContent>
            {isFetching || isFetchingSmartReceiptData ? (
               <Loader />
            ) : !smartReceiptData ? (
               <Box
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <Typography component={"h3"}>
                     {t("smartReceipt.not_found")}
                  </Typography>
               </Box>
            ) : (
               <SmartReceiptDetailsTabs
                  mode={mode}
                  item={smartReceiptData}
                  itemDetails={data}
               />
            )}
         </DialogContent>
         <DialogActions className={classes.buttons}>
            <Button onClick={handleClose} color="default" variant="contained">
               {t("Default.Chiudi")}
            </Button>
         </DialogActions>
      </>
   )
}

type SmartReceiptDialogProps = {
   table: TableSmartReceiptKeyType
}
const SmartReceiptDialog = ({ table }: SmartReceiptDialogProps) => (
   <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
      {(data) => <Content {...data} table={table} />}
   </BaseDialog>
)

export default SmartReceiptDialog

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
