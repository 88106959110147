import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"
import { useAppDispatch, useAppSelector } from "store"
import { selectIsAuthenticated, tryLoginWithExistingSession } from "store/auth"

type Props = {
   children: ReactElement
}

const AuthGuard = ({ children }: Props) => {
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { t } = useTranslation()
   const { pathname, search } = useLocation()
   const isAuthenticated = useAppSelector(selectIsAuthenticated)
   const [loginAttempted, setLoginAttempted] = useState(isAuthenticated)

   const redirectTo = encodeURIComponent(`${pathname}${search}`)

   useEffect(() => {
      ;(async () => {
         if (isAuthenticated) {
            return
         }

         await dispatch(tryLoginWithExistingSession()).unwrap()
         setLoginAttempted(true)
      })()
   }, [dispatch, isAuthenticated])

   if (!loginAttempted) {
      return (
         <Box color="grey.500">
            <CircularProgress size={16} color="inherit" />
         </Box>
      )
   }

   if (loginAttempted && !isAuthenticated) {
      return (
         <>
            <Box mb={2}>
               <Typography variant="body1">
                  {t("onboard.mode_2.auth_required")}
               </Typography>
            </Box>

            <Button
               color="primary"
               variant="contained"
               size="large"
               onClick={() => {
                  navigate(`/login?redirect_to=${redirectTo}`)
               }}
            >
               {t("global.login")}
            </Button>
         </>
      )
   }

   return <>{children}</>
}

export default AuthGuard
