import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { INVOICES } from "../../../../endpoints"
import serializeQuery from "../../serializer/serializeQuery"

export async function getInvoices(token, searchParams, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   let queryString = serializeQuery(searchParams)

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${INVOICES}?${queryString}`,
      requestInit
   )

   if (!response.ok) {
      return
   }

   const contentType = response.headers.get("Content-Type")

   // if ("application/zip" === contentType) {
   //   return await response.blob()
   // }

   if ("application/ld+json; charset=utf-8" === contentType) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getInvoice(token, invoiceUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${INVOICES}/${invoiceUuid}`,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getInvoiceXML(token, invoiceUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/xml",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${INVOICES}/${invoiceUuid}/source`,
      requestInit
   )

   if (response.ok) {
      return await response.text()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getInvoiceSourceJSON(token, invoiceUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${INVOICES}/${invoiceUuid}/source`,
      requestInit
   )

   if (response.ok) {
      return await response.text()
   }

   let text = await response.text()
   throw new Error(text)
}
