import DemoInvoicesForm from "./Form"
import InvoicesDemoTable from "./Table"

export const BATCH_INVOICES_PANEL_ID = "batch-invoices-tab"

const BatchInvoices = () => {
   return (
      <>
         <DemoInvoicesForm />

         <InvoicesDemoTable />
      </>
   )
}

export default BatchInvoices
