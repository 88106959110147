import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import React from "react"

type Props = {
   title: string
   message: string
   children: React.ReactNode
   type: "error" | "warning" | "info" | "success"
   handleClose: () => void
}

function Content({ title, message, children, type, handleClose }: Props) {
   const classes = useStyles()
   return (
      <>
         <DialogContent>
            <Box mt={2} mb={2}>
               <Alert severity={type}>
                  <AlertTitle>{title}</AlertTitle>
                  {message}
               </Alert>

               <Box mt={2} mb={2}>
                  {children}
               </Box>
            </Box>
         </DialogContent>

         <DialogActions className={classes.buttons}>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}
export default function AlertModal() {
   return (
      <BaseDialog id="alert.modal" maxWidth="md" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
