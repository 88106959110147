import { makeStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles"
import clsx from "clsx"
import {
   STATUS_FAILED,
   STATUS_NEW,
   STATUS_READY,
   STATUS_RETRY,
   STATUS_SUBMITTED,
   STATUS_VOIDED,
   TYPE_RETURN,
   TYPE_SALE,
   TYPE_VOID,
} from "../../services/gov-it/smartReceipt/type"

const useStyles = makeStyles((theme) => ({
   root: {
      alignItems: "center",
      borderRadius: 2,
      cursor: "default",
      display: "inline-flex",
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 15,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
      whiteSpace: "nowrap",
   },
   primary: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
   },
   secondary: {
      color: theme.palette.dark.main,
      backgroundColor: alpha(theme.palette.dark.main, 0.08),
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.08),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.08),
   },
   gray: {
      color: "#808080",
      backgroundColor: alpha("#808080", 0.08),
   },
   blue: {
      color: "#0000FF",
      backgroundColor: alpha("#0000FF", 0.08),
   },
   orange: {
      color: "#FFA500",
      backgroundColor: alpha("#FFA500", 0.08),
   },
   green: {
      color: "#00FF00",
      backgroundColor: alpha("#00FF00", 0.08),
   },
}))

export const Marking = (props) => {
   const classes = useStyles()

   if (!props.marking) {
      return <></>
   }

   const getColor = (marking) => {
      switch (marking) {
         case "delivered":
         case "received":
         case "accepted-pa":
         case STATUS_NEW:
         case TYPE_SALE:
            return "success"
         case "not-delivered":
         case "rejected":
         case "rejected-pa":
         case "draft-blocked":
            return "warning"
         case "quarantena":
         case "invoice-error":
         case STATUS_FAILED:
            return "error"
         case TYPE_VOID:
         case STATUS_VOIDED:
            return "gray"
         case TYPE_RETURN:
         case STATUS_SUBMITTED:
            return "blue"
         case STATUS_RETRY:
            return "orange"
         case STATUS_READY:
            return "green"
         case "None":
            return "secondary"
         default:
            return "primary"
      }
   }

   const color = getColor(props.marking)

   return (
      <span
         className={clsx(classes.root, {
            [classes[color]]: color,
         })}
      >
         {props.marking}
      </span>
   )
}
