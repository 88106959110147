import {
   Box,
   Checkbox,
   CircularProgress,
   FormControlLabel,
   FormHelperText,
   Grid,
   makeStyles,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Field, Formik, FormikHelpers } from "formik"
import { TextField } from "formik-material-ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
   useGetSubAccountsQuery,
   useLazyGetSubAccountsQuery,
} from "services/commonApi"
import {
   useGetApiConfigurationsQuery,
   useLazyGetApiConfigurationsQuery,
} from "services/gov-it/apiConfigurations"
import { ApiConfiguration } from "services/gov-it/apiConfigurations/types"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import SelectAutoComplete from "../../../common/SelectAutoComplete"

type Props = {
   businessRegistryConfiguration?: BusinessRegistryConfigurationType
   subAccounts?: { email: string }[]
   allowedToManageSubAccounts?: boolean
   handleClose: () => void
   handleSaveBusinessRegistryConfiguration: (
      uuid: string | null,
      values: FormValues,
      helpers: FormikHelpers<FormValues>
   ) => Promise<void>
   isLoading: boolean
}

type OptionType = { label: string; value: string }

export type FormValues = {
   fiscal_id: string
   uuid: string | null
   name: string
   email: string
   apply_signature: boolean
   apply_legal_storage: boolean
   supplier_invoice_enabled: boolean
   api_configurations: OptionType[]
   sub_accounts: OptionType[]
   receipts_enabled: boolean
}

function removeDuplicateOptions(arr: OptionType[]): OptionType[] {
   const uniqueLabels = new Set<string>()
   return arr.filter((item) => {
      if (uniqueLabels.has(item.label)) {
         return false
      } else {
         uniqueLabels.add(item.label)
         return true
      }
   })
}

const initialValues: FormValues = {
   fiscal_id: "",
   uuid: null,
   name: "",
   email: "",
   apply_signature: false,
   apply_legal_storage: false,
   supplier_invoice_enabled: false,
   receipts_enabled: false,
   api_configurations: [],
   sub_accounts: [],
}

const BusinessRegistryConfigurationsForm = ({
   businessRegistryConfiguration,
   handleClose,
   handleSaveBusinessRegistryConfiguration,
   isLoading,
   subAccounts,
   allowedToManageSubAccounts = false,
}: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   const [apiConfigurationsGetByTargetUrl, setApiConfigurationsGetByTargetUrl] =
      useState<OptionType[]>([])

   const [subAccountsGetByEmail, setSubAccountsGetByEmail] = useState<
      OptionType[]
   >([])
   const [loading, setLoading] = useState(false)

   const handleSubmit = async (
      values: FormValues,
      helpers: FormikHelpers<FormValues>
   ) => {
      setLoading(true)
      await handleSaveBusinessRegistryConfiguration(
         businessRegistryConfiguration?.fiscal_id ?? null,
         values,
         helpers
      )

      const { resetForm } = helpers

      resetForm({
         values,
      })

      setLoading(false)
   }

   const formatLabel = (apiConfiguration: ApiConfiguration) => {
      return `${apiConfiguration.event} -- ${apiConfiguration.target_url} (${apiConfiguration.uuid})`
   }

   const getSelectedOptionsAPIC = (apiConfigurations: ApiConfiguration[]) => {
      return apiConfigurations
         .map((apiConfiguration) => {
            return {
               value:
                  "@id" in apiConfiguration
                     ? (apiConfiguration["@id"] as string)
                     : null,
               label: formatLabel(apiConfiguration),
            } as OptionType
         })
         .filter((value) => value.value) as OptionType[]
   }

   const getSelectedOptionsSubAccounts = (
      subAccountsIn: { email: string }[]
   ) => {
      return subAccountsIn
         .map((subAccount) => {
            return {
               value: subAccount.email,
               label: subAccount.email,
            } as OptionType
         })
         .filter((value) => value.value) as OptionType[]
   }

   const { data, isLoading: isLoadingApiConfigurations } =
      useGetApiConfigurationsQuery({
         page: 1,
         itemsPerPage: 100,
      })

   const dataApiConfigurations = data ? data["hydra:member"] : []

   const [
      getApiConfigurations,
      {
         isLoading: isLoadingLazyApiConfiguration,
         isError: isErrorFetchingApiConfiguration,
      },
   ] = useLazyGetApiConfigurationsQuery()

   const { data: dataSubAccount, isLoading: isLoadingSubAccounts } =
      useGetSubAccountsQuery({
         page: 1,
      })

   const [getSubAccounts, { isLoading: isLoadingLazySubAccount }] =
      useLazyGetSubAccountsQuery()

   const updateOptionsSubAccounts = async (subAccountEmail: string) => {
      const { data } = await getSubAccounts({
         email: subAccountEmail,
      })

      let results: OptionType[] = []
      if (isErrorFetchingApiConfiguration) {
         return results.map((item) => item.label)
      }

      if (data) {
         results = data["hydra:member"].map((subAccount: any) => {
            return {
               value: subAccount.email,
               label: subAccount.email,
            } as OptionType
         })

         setSubAccountsGetByEmail((prevState) => {
            return removeDuplicateOptions([
               ...prevState,
               ...results.map((item) => ({
                  label: item.label,
                  value: item.value,
               })),
            ])
         })
      }
      return results.map((item) => item.label)
   }

   const updateOptionsTargetUrl = async (targetUrl: string) => {
      const { data } = await getApiConfigurations({ targetUrl })

      let targetUrls: OptionType[] = []
      if (isErrorFetchingApiConfiguration) {
         return targetUrls.map((item) => item.label)
      }

      if (data) {
         targetUrls = data["hydra:member"].map(
            (apiConfiguration: ApiConfiguration) => {
               return {
                  value:
                     "@id" in apiConfiguration
                        ? (apiConfiguration["@id"] as string)
                        : null,
                  label: formatLabel(apiConfiguration),
               } as OptionType
            }
         )

         setApiConfigurationsGetByTargetUrl((prevState) => {
            return removeDuplicateOptions([
               ...prevState,
               ...targetUrls.map((item) => ({
                  label: item.label,
                  value: item.value,
               })),
            ])
         })
      }
      return targetUrls.map((item) => item.label)
   }

   const initialFormValues = {
      ...(businessRegistryConfiguration
         ? {
              fiscal_id: businessRegistryConfiguration.fiscal_id,
              uuid: businessRegistryConfiguration.fiscal_id,
              name: businessRegistryConfiguration.name,
              email: businessRegistryConfiguration.email,
              apply_signature: businessRegistryConfiguration.apply_signature,
              apply_legal_storage:
                 businessRegistryConfiguration.apply_legal_storage,
              supplier_invoice_enabled:
                 businessRegistryConfiguration.supplier_invoice_enabled,
              receipts_enabled: businessRegistryConfiguration.receipts_enabled,
           }
         : initialValues),
      api_configurations: getSelectedOptionsAPIC(
         businessRegistryConfiguration?.api_configurations ?? []
      ),
      sub_accounts: getSelectedOptionsSubAccounts(subAccounts ?? []),
   }

   if (
      isLoading ||
      isLoadingApiConfigurations ||
      isLoadingSubAccounts ||
      isLoadingApiConfigurations
   ) {
      return (
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 200 }}
         >
            <Box mt={2}>
               <CircularProgress />
            </Box>
         </Box>
      )
   }

   return (
      <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
         {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
         }) => {
            return (
               <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={3}>
                     <Grid item md={6} xs={12}>
                        <Field
                           component={TextField}
                           disabled={values.uuid !== null || loading}
                           type="fiscal_id"
                           label={t("BusinessRegistry.fields.Fiscal Id")}
                           helperText={t(
                              "BusinessRegistry.The fiscal code or the vat number WITHOUT the country prefix"
                           )}
                           name="fiscal_id"
                           error={Boolean(
                              touched.fiscal_id && errors.fiscal_id
                           )}
                           onChange={handleChange}
                           required
                           fullWidth
                        />
                     </Grid>

                     <Grid item md={6} xs={12}>
                        <Field
                           component={TextField}
                           type="name"
                           label={t("BusinessRegistry.fields.Name")}
                           helperText={t(
                              "BusinessRegistry.The owner's name for the fiscal id"
                           )}
                           name="name"
                           error={Boolean(touched.name && errors.name)}
                           onChange={handleChange}
                           fullWidth
                           disabled={loading}
                        />
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <Field
                           component={TextField}
                           type="email"
                           label={t("BusinessRegistry.fields.Email")}
                           helperText={t(
                              "BusinessRegistry.The email where the owner of the fiscalId will receive communications from the platform"
                           )}
                           name="email"
                           onChange={handleChange}
                           error={Boolean(touched.email && errors.email)}
                           fullWidth
                           disabled={loading}
                        />
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={values.supplier_invoice_enabled}
                                 onChange={(event) =>
                                    setFieldValue(
                                       "supplier_invoice_enabled",
                                       event.target.checked
                                    )
                                 }
                                 name="supplier_invoice_enabled"
                                 color="primary"
                              />
                           }
                           label={t(
                              "BusinessRegistry.fields.Supplier invoice enabled"
                           )}
                        />
                        <FormHelperText>
                           {t(
                              "BusinessRegistry.The fiscal id is enabled to received supplier invoices"
                           )}
                        </FormHelperText>
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={values.apply_signature}
                                 onChange={(event) =>
                                    setFieldValue(
                                       "apply_signature",
                                       event.target.checked
                                    )
                                 }
                                 name="apply_signature"
                                 color="primary"
                                 disabled={loading}
                              />
                           }
                           label={t("BusinessRegistry.fields.Apply Signature")}
                        />
                        <FormHelperText>
                           {t(
                              "BusinessRegistry.Apply digital signature before sending invoices to SDI"
                           )}
                        </FormHelperText>
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={values.apply_legal_storage}
                                 onChange={(event) =>
                                    setFieldValue(
                                       "apply_legal_storage",
                                       event.target.checked
                                    )
                                 }
                                 name="apply_legal_storage"
                                 color="primary"
                                 disabled={loading}
                              />
                           }
                           label={t(
                              "BusinessRegistry.fields.Apply legal storage"
                           )}
                        />
                        <FormHelperText>
                           {t(
                              "BusinessRegistry.Apply the legal storage for invoices sent/received by the fiscal id"
                           )}
                        </FormHelperText>
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={values.receipts_enabled}
                                 onChange={(event) =>
                                    setFieldValue(
                                       "receipts_enabled",
                                       event.target.checked
                                    )
                                 }
                                 name="receipts_enabled"
                                 color="primary"
                                 disabled={loading}
                              />
                           }
                           label={t("BusinessRegistry.fields.receipts_enabled")}
                        />
                        <FormHelperText>
                           {t(
                              "BusinessRegistry.Enable the receipts for the fiscal id"
                           )}
                        </FormHelperText>
                     </Grid>

                     <Grid item md={12} xs={12}>
                        <SelectAutoComplete
                           disabled={loading}
                           name="api_configurations"
                           label={"API Configurations"}
                           placeholder={t("global.typeToSearch")}
                           initialOptions={
                              dataApiConfigurations?.map((data) => ({
                                 name: formatLabel(data),
                                 label: formatLabel(data),
                              })) ?? []
                           }
                           helperText={t(
                              "BusinessRegistry.ApiConfigurationsMessage"
                           )}
                           loading={
                              isLoadingApiConfigurations ||
                              isLoadingLazyApiConfiguration
                           }
                           handleUpdateOptions={updateOptionsTargetUrl}
                           multiple
                           handleChange={(value) => {
                              const currentApiConfigs =
                                 values.api_configurations.map((a) => a.label)
                              if (
                                 value.length !== currentApiConfigs.length ||
                                 value.some(
                                    (v) => !currentApiConfigs.includes(v.name)
                                 )
                              ) {
                                 const result = value.map((item) => {
                                    const findInApiConfigurations =
                                       dataApiConfigurations.find(
                                          (apiConfiguration) => {
                                             return (
                                                item.name ===
                                                formatLabel(apiConfiguration)
                                             )
                                          }
                                       )
                                    if (!findInApiConfigurations) {
                                       const findInApiConfigurationsGetByTargetUrl =
                                          apiConfigurationsGetByTargetUrl.find(
                                             (apiConfiguration) => {
                                                return (
                                                   item.name ===
                                                   apiConfiguration.label
                                                )
                                             }
                                          )

                                       return {
                                          value:
                                             findInApiConfigurationsGetByTargetUrl?.value ??
                                             null,
                                          label: item.name,
                                       }
                                    }

                                    return {
                                       value:
                                          "@id" in findInApiConfigurations
                                             ? findInApiConfigurations["@id"]
                                             : null,
                                       label: item,
                                    }
                                 })

                                 setFieldValue(
                                    "api_configurations",
                                    result as OptionType[]
                                 )
                              }
                           }}
                           value={values.api_configurations.map(
                              ({ label }) => ({
                                 name: label as string,
                                 label: label as string,
                              })
                           )}
                        />
                     </Grid>

                     {allowedToManageSubAccounts && (
                        <Grid item md={12} xs={12}>
                           <SelectAutoComplete
                              disabled={loading}
                              name="sub_accounts"
                              label={"Sub Accounts"}
                              placeholder={t("global.typeToSearch")}
                              initialOptions={
                                 dataSubAccount?.["hydra:member"]?.map(
                                    (data) => ({
                                       name: data.email,
                                    })
                                 ) ?? []
                              }
                              helperText={t(
                                 "BusinessRegistry.SubAccountsMessage"
                              )}
                              loading={
                                 isLoadingLazySubAccount || isLoadingSubAccounts
                              }
                              handleUpdateOptions={updateOptionsSubAccounts}
                              multiple
                              handleChange={(value) => {
                                 const currentSubAccounts =
                                    values.sub_accounts.map((a) => a.label)
                                 if (
                                    value.length !==
                                       currentSubAccounts.length ||
                                    value.some(
                                       (v) =>
                                          !currentSubAccounts.includes(v.name)
                                    )
                                 ) {
                                    const result = value.map((item) => {
                                       const findInSubAccounts =
                                          dataSubAccount?.[
                                             "hydra:member"
                                          ]?.find((subAccount) => {
                                             return (
                                                item.name === subAccount.email
                                             )
                                          })
                                       if (!findInSubAccounts) {
                                          const findInSubAccountsGetByEmail =
                                             subAccountsGetByEmail.find(
                                                (subAccount) => {
                                                   return (
                                                      item.name ===
                                                      subAccount.label
                                                   )
                                                }
                                             )

                                          return {
                                             value:
                                                findInSubAccountsGetByEmail?.value ??
                                                null,
                                             label: item.name,
                                          }
                                       }

                                       return {
                                          value: findInSubAccounts.email,
                                          label: item.name,
                                       }
                                    })

                                    setFieldValue(
                                       "sub_accounts",
                                       result as OptionType[]
                                    )
                                 }
                              }}
                              value={values.sub_accounts?.map(({ label }) => ({
                                 name: label as string,
                              }))}
                           />
                        </Grid>
                     )}
                  </Grid>

                  <div className={classes.buttons}>
                     <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isLoading || loading}
                        style={{ width: "max-content" }}
                     >
                        {t("Default.Salva")}
                     </Button>
                     <Button
                        onClick={handleClose}
                        color="default"
                        variant="contained"
                     >
                        {t("Default.Annulla")}
                     </Button>
                  </div>
               </form>
            )
         }}
      </Formik>
   )
}

export default BusinessRegistryConfigurationsForm

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
