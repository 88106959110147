import {
   Card,
   CardContent,
   CardHeader,
   Container,
   Divider,
   Grid,
   makeStyles,
} from "@material-ui/core"
import useApiOnMount from "hooks/useApiOnMount"
import { useTranslation } from "react-i18next"
import Page from "../common/components/Page"
import Statistics from "../common/Statistics"
import Consumptions from "./Consumptions"
import PreSales from "./PreSales"

import getConsumptions from "services/client/request/common/getConsumptions"
import getPresales from "services/client/request/common/getPreSales"
import { useAppSelector } from "store"
import { selectActiveContext } from "store/app"
import { selectHasPid, selectHasRole } from "store/auth"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const Dashboard = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   const [preSales] = useApiOnMount(getPresales)
   const [consumptions] = useApiOnMount(getConsumptions)

   const activeContext = useAppSelector(selectActiveContext)
   const isNotAllowedToSeeCosts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "common.api.acubeapi.com",
         role: "ROLE_NO_COSTS",
      })
   )
   const isSubAccount = useAppSelector((state) => selectHasPid(state))
   const isAllowedToSeeStats = useAppSelector(
      (state) =>
         selectHasRole(state, {
            context: "it.api.acubeapi.com",
            role: "ROLE_SHOW_STATS",
         }) || !isSubAccount
   )

   return (
      <Page className={classes.root} title={t("Menu.Dashboard")}>
         <Container maxWidth={false}>
            <Grid container spacing={3}>
               {!isNotAllowedToSeeCosts && (
                  <Grid item xs={12}>
                     <Card>
                        <CardHeader
                           title={t("Dashboard.title.credit_consumptions")}
                           p={2}
                        />

                        <>
                           <Divider />
                           <CardContent>
                              <Grid container spacing={3}>
                                 {preSales && preSales.length > 0 && (
                                    <Grid
                                       item
                                       xs={12}
                                       sm={consumptions ? 6 : 12}
                                    >
                                       <PreSales
                                          totalCredit={
                                             preSales[0]
                                                .current_credit_purchased
                                          }
                                          availableCredit={
                                             preSales[0]
                                                .current_credit_available
                                          }
                                          creditPurchasedDate={
                                             preSales[0]
                                                .current_credit_purchased_at
                                          }
                                          invoicesReceived={
                                             preSales[0].invoices_received
                                          }
                                          invoicesSent={
                                             preSales[0].invoices_sent
                                          }
                                       />
                                    </Grid>
                                 )}
                                 {consumptions && (
                                    <Grid item xs={12} sm={preSales ? 6 : 12}>
                                       <Consumptions
                                          monthlyConsumption={consumptions}
                                       />
                                    </Grid>
                                 )}
                              </Grid>
                           </CardContent>
                        </>
                     </Card>
                  </Grid>
               )}
               {(!activeContext ||
                  (activeContext === "it.api.acubeapi.com" &&
                     isAllowedToSeeStats)) && (
                  <Grid item xs={12}>
                     <Statistics />
                  </Grid>
               )}
            </Grid>
         </Container>
      </Page>
   )
}

export default Dashboard
