import { Box, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectActivationFailed } from "store/slices/onboard"
import AnimatedCheckmark from "views/common/AnimatedCheckmark"
import AnimatedCross from "views/common/AnimatedCross"

const Success = () => {
   const { t } = useTranslation()
   const activationFailed = useAppSelector(selectActivationFailed)

   if (activationFailed) {
      return (
         <>
            <Box
               display="flex"
               justifyContent="center"
               color="error.main"
               mb={6}
            >
               <AnimatedCross size={64} />
            </Box>

            <Box textAlign="center">
               <Typography variant="h2">
                  {t("onboard.steps.end.error.title")}
               </Typography>
            </Box>

            <Box marginTop={6} marginBottom={4}>
               <Box marginBottom={2} textAlign="center">
                  <Typography variant="body1">
                     {t("onboard.steps.end.error.body_1")}
                  </Typography>
               </Box>
            </Box>
         </>
      )
   }

   return (
      <>
         <Box
            display="flex"
            justifyContent="center"
            color="success.main"
            mb={6}
         >
            <AnimatedCheckmark size={64} />
         </Box>

         <Box textAlign="center">
            <Typography variant="h2">
               {t("onboard.steps.end.success.title")}
            </Typography>
         </Box>

         <Box marginTop={6} marginBottom={4}>
            <Box marginBottom={2} textAlign="center">
               <Typography variant="body1">
                  {t("onboard.steps.end.success.body_1")}
               </Typography>
            </Box>
         </Box>
      </>
   )
}

export default Success
