import { IconButton, Tooltip } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { useTranslation } from "react-i18next"
import { OpenbankingWebhook } from "services/openbankingApi/webhooks"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   webhook: OpenbankingWebhook
}

const EditWebhookCTA = ({ webhook }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const openEditWebhookDialog = async () => {
      dispatch(
         openDialog({
            id: "openbanking_edit_webhook",
            data: webhook,
         })
      )
   }

   return (
      <Tooltip title={t("openbanking.webhooks.edit_webhook_cta")}>
         <IconButton onClick={openEditWebhookDialog}>
            <EditIcon />
         </IconButton>
      </Tooltip>
   )
}

export default EditWebhookCTA
