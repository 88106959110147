import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useGetDemoInvoiceStatusQuery } from "services/gov-pl/demoInvoices"
import AnimatedCheckmark from "views/common/AnimatedCheckmark"
import Label from "views/common/Label"

type Props = {
   invoiceUuid: string | undefined
   isReady: boolean
}

const DownloadReceipt = ({ invoiceUuid, isReady }: Props) => {
   const { t } = useTranslation()

   const ref = useRef<HTMLAnchorElement | null>(null)

   const { data } = useGetDemoInvoiceStatusQuery(
      { uuid: invoiceUuid! },
      { skip: !isReady, pollingInterval: 10000 }
   )

   const upo = data?.upo

   useEffect(() => {
      if (ref.current != null && upo != null) {
         ref.current.href = `data:application/octet-stream;charset=utf-16le;base64,${upo}`
      }
   }, [upo, ref.current])

   const downloadReceipt = () => {
      ref.current?.click()
   }

   if (data != null) {
      return (
         <Box mt={4}>
            <Box
               display="flex"
               alignItems="center"
               mb={4}
               borderTop={1}
               borderColor="grey.300"
               pt={3}
            >
               <Typography variant="body1">
                  {t("invoices_demo.single_invoice.details.invoice_status")}
               </Typography>

               <Box ml={1} mr={1}>
                  <Label color="primary">{data.status}</Label>
               </Box>

               {data.status !== "confirmed" && (
                  <Box display="flex" color="grey.500">
                     <CircularProgress size={12} color="inherit" />
                  </Box>
               )}
            </Box>

            {data.status === "confirmed" && (
               <>
                  <Box
                     color="success.main"
                     display="flex"
                     alignItems="center"
                     gridGap={8}
                     mb={3}
                  >
                     <Box mr={1}>
                        <AnimatedCheckmark size={40} />
                     </Box>
                     <Typography variant="h3">
                        {t(
                           "invoices_demo.single_invoice.success.ready_for_download"
                        )}
                     </Typography>
                  </Box>

                  <a
                     ref={ref}
                     href="#"
                     download="upo.xml"
                     style={{ display: "none" }}
                  >
                     {t("invoices_demo.single_invoice.download_receipt")}
                  </a>
                  <Button
                     color="primary"
                     size="large"
                     type="button"
                     variant="contained"
                     onClick={downloadReceipt}
                  >
                     {t("invoices_demo.single_invoice.download_receipt")}
                  </Button>
               </>
            )}
         </Box>
      )
   }

   return null
}

export default DownloadReceipt
