import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { openbankingApi, OpenbankingPaginationParams } from ".."

export type OpenbankingTransaction = {
   transactionId: string
   status: string
   madeOn: string
   amount: string
   currencyCode: string
   description: string
   category: string
   duplicated: boolean
   createdAt: string
   updatedAt: string
   account: {
      uuid: string
      name: string
      nature: string
      providerName: string
   }
}

export type OpenbankingCategory = {
   category: string
}

type GetTransactionsResponse = HydraResponse<OpenbankingTransaction>

type GetTransactionsParams = OpenbankingPaginationParams & {
   category?: string[]
   "account.uuid"?: string[]
   "madeOn[after]"?: string
   "madeOn[before]"?: string
}

type GetCategoriesResponse = HydraResponse<OpenbankingCategory>

type GetCategoriesParams = OpenbankingPaginationParams

const openbankingTransactionsApi = openbankingApi.injectEndpoints({
   endpoints: (builder) => ({
      getTransactions: builder.query<
         GetTransactionsResponse,
         GetTransactionsParams & { fiscalId: string }
      >({
         query: ({ fiscalId, ...params }) => ({
            url: `/business-registry/${fiscalId}/transactions${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
            headers: {
               Accept: "application/ld+json",
            },
         }),
         providesTags: ["TRANSACTIONS"],
      }),

      getCategories: builder.query<GetCategoriesResponse, GetCategoriesParams>({
         query: ({ ...params }) => ({
            url: "/categories",
            params,
            headers: {
               Accept: "application/ld+json",
            },
         }),
      }),
   }),
})

export const { useGetTransactionsQuery, useGetCategoriesQuery } =
   openbankingTransactionsApi
