import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { EXPENSES } from "../../../endpoints"
import serializeQuery from "../serializer/serializeQuery"

export async function getExpenses(token, searchParams) {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/ld+json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   let queryString = serializeQuery(searchParams)

   const response = await fetch(
      process.env.REACT_APP_BASE_URL + EXPENSES + "?" + queryString,
      requestInit
   )

   if (!response.ok) {
      return
   }

   const contentType = response.headers.get("Content-Type")

   if ("application/zip" === contentType) {
      return await response.blob()
   }

   if ("application/ld+json; charset=utf-8" === contentType) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
