import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { ORDERS } from "../../../../endpoints"
import serializeQuery from "../../serializer/serializeQuery"

export async function getOrders(token, searchParams, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   let queryString = serializeQuery(searchParams)

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${ORDERS}?${queryString}`,
      requestInit
   )

   if (!response.ok) {
      return
   }

   const contentType = response.headers.get("Content-Type")

   // if ("application/zip" === contentType) {
   //   return await response.blob()
   // }

   if ("application/ld+json; charset=utf-8" === contentType) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getOrder(token, orderUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${ORDERS}/${orderUuid}`,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getOrderXML(token, orderUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/xml",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${ORDERS}/${orderUuid}/source`,
      requestInit
   )

   if (response.ok) {
      return await response.text()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getOrderSourceJSON(token, orderUuid, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${ORDERS}/${orderUuid}/source`,
      requestInit
   )

   if (response.ok) {
      return await response.text()
   }

   let text = await response.text()
   throw new Error(text)
}
