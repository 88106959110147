import {
   Box,
   Button,
   Container,
   makeStyles,
   Paper,
   Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import Page from "views/common/components/Page"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

type ErrorPageProps = {
   message?: string
   redirectUrl: string
}
export const ErrorPage = ({ message, redirectUrl }: ErrorPageProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [seconds, setSeconds] = useState(5)

   // Redirect to customer invoice after 5 seconds
   useEffect(() => {
      const interval = setInterval(() => {
         setSeconds((seconds) => seconds - 1)
      }, 1000)
      return () => clearInterval(interval)
   }, [])

   useEffect(() => {
      if (seconds === 0) {
         navigate(redirectUrl)
      }
   }, [seconds, navigate])

   return (
      <Page className={classes.root} title={t("gov_it.invoice_fix.title")}>
         <Container maxWidth={false}>
            <Paper>
               <Box p={3}>
                  <Box my={4}>
                     <Alert severity="error">
                        <AlertTitle>
                           {t("global.messages.generic_error")}
                        </AlertTitle>
                        <p>{message || t("global.messages.generic_error")}</p>
                     </Alert>
                  </Box>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => navigate("/customer-invoice")}
                  >
                     Back to customer invoice
                  </Button>

                  <Typography
                     variant="body1"
                     style={{
                        color: "black",
                        textAlign: "center",
                        display: "block",
                        marginTop: "20px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                     }}
                     gutterBottom
                  >
                     Redirecting to customer invoice in {seconds} seconds...
                  </Typography>
               </Box>
            </Paper>
         </Container>
      </Page>
   )
}
