import {
   Box,
   Button,
   Container,
   TextField,
   Typography,
} from "@material-ui/core"
import { Formik, FormikHelpers } from "formik"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import {
   useActivateAccessCodeMutation,
   useSendAccessCodeMutation,
} from "services/gov-pl/invoices"
import * as Yup from "yup"
import { fixedCacheKey } from "../SendAccessCodeCTA"

const initialValues = {
   accessCode: "",
}

const validationSchema = Yup.object().shape({
   accessCode: Yup.string().required(),
})

type FormValues = typeof initialValues

type Props = {
   onSuccessfulSubmit: (path: string) => void
}

const ActivateAccessCodeForm = ({ onSuccessfulSubmit }: Props) => {
   const { t } = useTranslation()
   const { invoiceUuid } = useParams()
   const [activateAccessCode, { isLoading }] = useActivateAccessCodeMutation()
   const [, { data }] = useSendAccessCodeMutation({ fixedCacheKey })
   const email = data?.email

   const handleSubmit = async (
      { accessCode }: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      const response = await activateAccessCode({
         accessCode,
         uuid: invoiceUuid!,
      })

      if ("error" in response) {
         setFieldError(
            "accessCode",
            t("pl.public_invoice.messages.invalid_access_code")
         )
         return
      }

      if ("data" in response) {
         onSuccessfulSubmit(response.data.access_code)
      }
   }

   return (
      <Container
         maxWidth="xs"
         style={{ border: "1px solid #c0c0c0", padding: "20px" }}
      >
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
         >
            {({
               values,
               errors,
               touched,
               handleBlur,
               handleChange,
               handleSubmit,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Box mb={2}>
                     <Typography variant="body1">
                        {t("pl.public_invoice.access_code_text", { email })}
                     </Typography>
                  </Box>

                  <TextField
                     value={values.accessCode}
                     error={
                        touched.accessCode != null && errors.accessCode != null
                     }
                     fullWidth
                     helperText={errors.accessCode}
                     label={t("pl.public_invoice.field_access_code")}
                     name="accessCode"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     type="text"
                  />

                  <Box display="flex" justifyContent="flex-end" mt={2}>
                     <Button
                        disabled={isLoading}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                     >
                        {t("global.submit")}
                     </Button>
                  </Box>
               </form>
            )}
         </Formik>
      </Container>
   )
}

export default ActivateAccessCodeForm
