import IconButton from "@material-ui/core/IconButton"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import React, { useEffect } from "react"
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"

export default function LanguagesMenu() {
   const { i18n } = useTranslation()
   const [anchorEl, setAnchorEl] = React.useState(null)
   const languages = [
      {
         code: "it",
         countryCode: "IT",
         title: "Italiano",
      },
      {
         code: "en",
         countryCode: "GB",
         title: "English",
      },
      {
         code: "pl",
         countryCode: "PL",
         title: "Polski",
      },
   ]

   const handleOpen = (event) => {
      setAnchorEl(event.currentTarget)
   }

   const handleClose = () => {
      setAnchorEl(null)
   }

   function getLanguageFromCountryCode(countryCode) {
      return languages.find((language) => {
         return language.countryCode === countryCode
      })
   }

   function getLanguageFromCode(code) {
      return languages.find((language) => {
         return language.code === code
      })
   }

   const handleClick = (countryCode) => {
      const language = getLanguageFromCountryCode(countryCode)

      i18n.changeLanguage(language.code)
      setAnchorEl(null)
   }

   useEffect(() => {}, [i18n.language])

   return (
      <>
         <IconButton
            color="default"
            aria-controls="languages-menu"
            aria-haspopup="true"
            onClick={handleOpen}
         >
            <ReactCountryFlag
               countryCode={getLanguageFromCode(i18n.language)?.countryCode}
               svg
               style={{
                  width: "1em",
                  height: "1em",
               }}
            />
         </IconButton>

         <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            onClose={handleClose}
            elevation={3}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
         >
            {languages.map((language, index) => {
               return (
                  <MenuItem
                     key={index}
                     disabled={i18n.language === language.code}
                     onClick={() => handleClick(language.countryCode)}
                  >
                     <ListItemIcon>
                        <ReactCountryFlag
                           countryCode={language.countryCode}
                           svg
                           style={{
                              width: "2em",
                              height: "2em",
                           }}
                           title={language.title}
                        />
                     </ListItemIcon>
                     <ListItemText primary={language.title} />
                  </MenuItem>
               )
            })}
         </Menu>
      </>
   )
}
