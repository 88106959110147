import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { saveImpersonatedUser } from "helper/impersonateUser"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { closeDialog, selectIsDialogOpen } from "store/slices/dialogs"

const initialValues = {
   username: "",
}

const useStyles = makeStyles((theme) => ({
   container: {
      paddingBottom: theme.spacing(2),
   },
   cta: {
      textAlign: "right",
      paddingTop: theme.spacing(2),
   },
}))

export default function ImpersonateUserDialog() {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const isDialogOpen = useSelector((state) =>
      selectIsDialogOpen(state, "impersonate_user")
   )
   const classes = useStyles()

   const handleCloseDialog = () => {
      dispatch(closeDialog())
   }

   const switchUser = ({ username }) => {
      if (username === "") {
         return
      }

      saveImpersonatedUser(username)
      handleCloseDialog()
   }

   return (
      <Dialog
         maxWidth="sm"
         fullWidth
         open={isDialogOpen}
         onClose={handleCloseDialog}
      >
         <DialogTitle>{t("ImpersonateUser.title")}</DialogTitle>

         <DialogContent>
            <Formik initialValues={initialValues} onSubmit={switchUser}>
               {({ handleSubmit }) => (
                  <Form className={classes.container}>
                     <Field
                        component={TextField}
                        disabled={false}
                        type="text"
                        name="username"
                        placeholder={t("ImpersonateUser.placeholder")}
                        fullWidth
                     />

                     <div className={classes.cta}>
                        <Button
                           onClick={handleSubmit}
                           color="default"
                           variant="contained"
                        >
                           {t("ImpersonateUser.cta")}
                        </Button>
                     </div>
                  </Form>
               )}
            </Formik>
         </DialogContent>
      </Dialog>
   )
}
