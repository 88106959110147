import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"
import DatePicker from "views/common/DatePicker"
import AccountsSelect from "./AccountsSelect"

const table: TableName = "openbanking.payments"

type TableFilters = TablesState[typeof table]["filters"]
type TableFilter = keyof TableFilters

export type FormValues = {
   system: string[]
   accountUuid: string[]
   madeAfter: string
   madeBefore: string
}

const PaymentsFilters = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const initialValues = filters

   const handleReset = () => {
      dispatch(resetFilters({ table }))
   }

   const handleFiltersChange = (values: FormValues) => {
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            // @TODO: extend equality for arrays
            const filterChanged =
               typeof value === "string" ? value !== filters[filter] : true

            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   return (
      <Formik
         initialValues={initialValues}
         onSubmit={handleFiltersChange}
         enableReinitialize
      >
         {({ handleBlur, handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
               <Accordion elevation={1}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="openbanking_payments_content"
                     id="openbanking_payments_filters"
                  >
                     <Typography variant="body1">
                        {t("Default.Filtra i risultati")}
                     </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                     <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Box display="flex" gridGap={16}>
                           <AccountsSelect />

                           <FormControl fullWidth>
                              <InputLabel>
                                 {t("openbanking.payments.filters.system")}
                              </InputLabel>

                              <Select
                                 multiple
                                 value={values.system}
                                 name="system"
                                 onChange={handleChange}
                              >
                                 {["sepa", "sepa_instant", "swift"].map(
                                    (value) => (
                                       <MenuItem key={value} value={value}>
                                          {value}
                                       </MenuItem>
                                    )
                                 )}
                              </Select>
                           </FormControl>
                        </Box>

                        <Box display="flex" gridGap={16} mt={2}>
                           <DatePicker
                              name="madeAfter"
                              label={"openbanking.payments.filters.made_after"}
                              helperText=""
                              onBlur={handleBlur}
                              autoSelectEndOfTheDay={true}
                              fullWidth
                           />

                           <DatePicker
                              name="madeBefore"
                              label={"openbanking.payments.filters.made_before"}
                              helperText=""
                              onBlur={handleBlur}
                              autoSelectEndOfTheDay={true}
                              fullWidth
                           />
                        </Box>

                        <Box display="flex" justifyContent="flex-end" mt={2}>
                           <Box mr={2}>
                              <Button onClick={handleReset} variant="contained">
                                 {t("Default.Cancella")}
                              </Button>
                           </Box>
                           <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                           >
                              {t("Default.Cerca")}
                           </Button>
                        </Box>
                     </Box>
                  </AccordionDetails>
               </Accordion>
            </form>
         )}
      </Formik>
   )
}

export default PaymentsFilters
