import {
   Box,
   Button,
   FormControl,
   FormLabel,
   Grid,
   IconButton,
   Typography,
} from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import DeleteIcon from "@material-ui/icons/Delete"
import React from "react"
import { useTranslation } from "react-i18next"
import { FieldGroup } from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import CustomField from "views/common/InvoiceForm/CommonInvoiceForm/components/CustomFields"

type ListOfFieldsProps = {
   section: FieldGroup
   register: any
   setError: any
   getValues: any
   setValue: any
   control: any
   watch: any
   errorsForm: any
   parentName?: string
   isSameSection?: boolean
   willBeSameSection?: boolean
   bgChange?: boolean
   actionsCTA?: React.ReactNode
   disableFields?: string[]
}

export const ListOfFields = ({
   section,
   register,
   getValues,
   setValue,
   setError,
   control,
   watch,
   errorsForm,
   parentName,
   isSameSection = false,
   willBeSameSection = false,
   bgChange = false,
   actionsCTA,
   disableFields,
}: ListOfFieldsProps) => {
   const { t } = useTranslation()
   const { sectionTranslationId, fields } = section
   const requiredFields = fields.filter(
      (field) => field.required === true || field.required === "essential"
   )
   const optionalFields = fields.filter((field) => !field.required)
   const optionalFieldsHasAtLeastOneFieldNotNull = optionalFields.some(
      (field) => {
         const value = getValues(field.name)
         return value != null && value !== ""
      }
   )

   const [open, setOpen] = React.useState(
      requiredFields.length === 0 || optionalFieldsHasAtLeastOneFieldNotNull
   )

   const ToggleOpen = () => {
      setOpen((open) => !open)
   }
   return (
      <Box width="100%">
         {!isSameSection && (
            <Box mb={1}>
               <FormLabel component="legend">
                  <Typography
                     variant="h3"
                     style={{
                        textDecoration: "underline",
                     }}
                  >
                     {parentName && t(parentName) + " - "}
                     {t(sectionTranslationId)}
                  </Typography>
               </FormLabel>
            </Box>
         )}

         <Box
            pr={{
               xs: 0,
               md: 2,
            }}
         >
            {actionsCTA && actionsCTA}
            <Box
               width="100%"
               style={{
                  ...(!section.children && {
                     backgroundColor: bgChange
                        ? "rgb(233 236 239 / 28%)"
                        : "transparent",
                     paddingRight: bgChange ? "1rem" : "0.5rem",
                     paddingLeft: bgChange ? "0.2rem" : "0.2rem",
                     paddingBottom: bgChange ? "2px" : "0",
                     paddingTop: "0.3rem",
                     borderRadius: "10px",
                     border:
                        isSameSection || willBeSameSection
                           ? "1px solid #ccc"
                           : "none",
                  }),
               }}
            >
               {requiredFields.length > 0 && (
                  <Box mb={2} ml={{ xs: 0, md: 2 }}>
                     <Grid container spacing={2}>
                        {requiredFields.map((field, index) => (
                           <CustomField
                              key={field.name}
                              field={field}
                              register={register}
                              setValue={setValue}
                              getValues={getValues}
                              setError={setError}
                              control={control}
                              watch={watch}
                              errorsForm={errorsForm}
                              disableFields={disableFields}
                           />
                        ))}
                     </Grid>
                  </Box>
               )}

               {optionalFields.length > 0 && (
                  <Box
                     bgcolor="rgba(0, 0, 0, 0.04)"
                     p={open ? 2 : 1}
                     mb={2}
                     ml={{ xs: 0, md: 2 }}
                     borderRadius={10}
                  >
                     {requiredFields.length > 0 && (
                        <Box
                           display="flex"
                           flexDirection="row"
                           justifyContent="start"
                           alignItems="center"
                           mb={open ? 2 : 0}
                        >
                           <FormLabel component="legend">
                              <Typography variant="h4">
                                 {t("global.facoltativi")}
                              </Typography>
                           </FormLabel>

                           <IconButton
                              aria-label="view facoltative input"
                              style={{
                                 borderRadius: "50%",
                                 border: "1px solid #ccc",
                                 marginLeft: "1rem",
                                 padding: "0.2rem",
                                 backgroundColor: "white",
                              }}
                              onClick={ToggleOpen}
                           >
                              {open ? (
                                 <KeyboardArrowUp />
                              ) : (
                                 <KeyboardArrowDown />
                              )}
                           </IconButton>
                        </Box>
                     )}

                     <Grid
                        container
                        spacing={2}
                        style={{
                           display: open ? "flex" : "none",
                        }}
                     >
                        {optionalFields.map((field) => (
                           <CustomField
                              key={field.name}
                              field={field}
                              register={register}
                              getValues={getValues}
                              setError={setError}
                              setValue={setValue}
                              control={control}
                              watch={watch}
                              errorsForm={errorsForm}
                              disableFields={disableFields}
                           />
                        ))}
                     </Grid>
                  </Box>
               )}
            </Box>
         </Box>
      </Box>
   )
}

type SectionItemProps = {
   sections: FieldGroup[]
   index: number
   register: any
   setValue: any
   getValues: any
   control: any
   watch: any
   errorsForm: any
   setError: any
   parentName?: string
   withBtnDelete?: boolean
   onRemove?: () => void
   onAdd?: React.ReactNode
   bgChange?: boolean
   disableFields?: string[]
}

const SectionItem = ({
   sections,
   index,
   register,
   getValues,
   setValue,
   setError,
   control,
   watch,
   parentName,
   withBtnDelete = false,
   onRemove,
   onAdd,
   bgChange,
   errorsForm,
   disableFields,
}: SectionItemProps) => {
   const { t } = useTranslation()
   const currentSection = sections[index]
   const previousSection = sections[index - 1]
   const afterSection = sections[index + 1]

   const isSameSection =
      previousSection?.sectionTranslationId ===
      currentSection.sectionTranslationId
   const willBeSameSection =
      afterSection?.sectionTranslationId === currentSection.sectionTranslationId
   return (
      <>
         <Box
            key={currentSection.key}
            style={{
               marginTop: willBeSameSection ? "10px" : "20px",
            }}
            width="100%"
         >
            <FormControl component="fieldset" style={{ width: "100%" }}>
               <ListOfFields
                  section={currentSection}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  setError={setError}
                  control={control}
                  watch={watch}
                  errorsForm={errorsForm}
                  isSameSection={isSameSection}
                  willBeSameSection={willBeSameSection}
                  parentName={parentName}
                  bgChange={
                     bgChange ??
                     ((isSameSection || willBeSameSection) &&
                        (index + 1) % 2 === 0)
                  }
                  actionsCTA={
                     withBtnDelete && (
                        <>
                           <Box
                              px={2}
                              mb={1}
                              style={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 alignItems: "center",
                              }}
                           >
                              <Typography variant="subtitle2">
                                 #{index + 1} -{" "}
                                 {t(currentSection.sectionTranslationId)}
                              </Typography>

                              <Box>
                                 {onAdd}
                                 <Button
                                    variant="contained"
                                    style={{
                                       background: "rgb(172,2,2)",
                                       color: "white",
                                       fontWeight: "bold",
                                    }}
                                    startIcon={<DeleteIcon />}
                                    onClick={onRemove}
                                 >
                                    {t("global.delete")}
                                 </Button>
                              </Box>
                           </Box>
                        </>
                     )
                  }
                  disableFields={disableFields}
               />

               {currentSection.children?.map(
                  (child, index) =>
                     !child.isChildVariable && (
                        <SectionItem
                           key={child.key}
                           watch={watch}
                           index={index}
                           sections={currentSection.children as FieldGroup[]}
                           parentName={currentSection.sectionTranslationId}
                           register={register}
                           getValues={getValues}
                           setError={setError}
                           control={control}
                           setValue={setValue}
                           withBtnDelete={false}
                           errorsForm={errorsForm}
                           disableFields={disableFields}
                        />
                     )
               )}
            </FormControl>
         </Box>
      </>
   )
}

export default SectionItem
