import { Box, Button, Grid, makeStyles, Select } from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionActions from "@material-ui/core/AccordionActions"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"
import LabelYesNo from "views/common/LabelYesNo"

const useStyles = makeStyles((theme) => ({
   root: {},
   button: {
      marginBottom: 12,
      marginRight: 12,
   },
}))

type FormValues = {
   fiscalId: string
   email: string
   name: string
   supplierInvoiceEnabled: boolean | null
   applySignature: boolean | null
   applyLegalStorage: boolean | null
   legalStorageActive: boolean | null
}

const table: TableName = "business_registry_configurations"

type TableFilters = TablesState[typeof table]["filters"]
type TableFilter = keyof TableFilters

type BusinessRegistryConfigurationsFiltersProps = {
   actions?: React.ReactNode
}

const BusinessRegistryConfigurationsFilters = ({
   actions,
}: BusinessRegistryConfigurationsFiltersProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )
   const dispatch = useAppDispatch()

   const handleReset = () => {
      dispatch(changePage({ table, page: 1 }))
      dispatch(resetFilters({ table }))
   }

   const handleFilter = (values: FormValues) => {
      dispatch(changePage({ table, page: 1 }))
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]
            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   return (
      <Box my={4}>
         <Formik initialValues={filters} onSubmit={handleFilter}>
            {({
               values,
               handleBlur,
               handleChange,
               handleSubmit,
               resetForm,
               touched,
               errors,
            }) => {
               return (
                  <form
                     onSubmit={handleSubmit}
                     noValidate
                     className={clsx(classes.root)}
                  >
                     <Accordion elevation={1}>
                        <Grid container alignItems={"center"}>
                           {actions && (
                              <Grid item xs={12} sm={3}>
                                 {actions}
                              </Grid>
                           )}

                           <Grid item xs>
                              <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls="panel1c-content"
                                 id="panel1c-header"
                              >
                                 <div>
                                    <Typography>
                                       {t("Default.Filtra i risultati")}
                                    </Typography>
                                 </div>
                              </AccordionSummary>
                           </Grid>
                        </Grid>

                        <AccordionDetails>
                           <Grid container spacing={3}>
                              <Grid item md={4} xs={12}>
                                 <Field
                                    component={TextField}
                                    type="text"
                                    label={t(
                                       "BusinessRegistry.fields.Fiscal Id"
                                    )}
                                    name="fiscalId"
                                    error={Boolean(
                                       touched.fiscalId && errors.fiscalId
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                 <Field
                                    component={TextField}
                                    type="text"
                                    label={t("BusinessRegistry.fields.Email")}
                                    name="email"
                                    error={Boolean(
                                       touched.email && errors.email
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                 <Field
                                    component={TextField}
                                    type="text"
                                    label={t("BusinessRegistry.fields.Name")}
                                    name="name"
                                    error={Boolean(touched.name && errors.name)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>

                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="supplierInvoiceEnabled">
                                       {t(
                                          "BusinessRegistry.fields.Supplier invoice enabled"
                                       )}
                                    </InputLabel>
                                    <Select
                                       name="supplierInvoiceEnabled"
                                       error={Boolean(
                                          touched.supplierInvoiceEnabled &&
                                             errors.supplierInvoiceEnabled
                                       )}
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (e.target.value === undefined) {
                                             e.target.value = null
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.supplierInvoiceEnabled}
                                       fullWidth
                                    >
                                       <MenuItem value={undefined}>
                                          <LabelYesNo value={null} />
                                       </MenuItem>
                                       <MenuItem value={1}>
                                          <LabelYesNo value={true} />
                                       </MenuItem>
                                       <MenuItem value={0}>
                                          <LabelYesNo value={false} />
                                       </MenuItem>
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="applySignature">
                                       {t("BusinessRegistry.fields.Signature")}
                                    </InputLabel>
                                    <Select
                                       name="applySignature"
                                       error={Boolean(
                                          touched.applySignature &&
                                             errors.applySignature
                                       )}
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (e.target.value === undefined) {
                                             e.target.value = null
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.applySignature}
                                       fullWidth
                                    >
                                       <MenuItem value={undefined}>
                                          <LabelYesNo value={null} />
                                       </MenuItem>
                                       <MenuItem value={1}>
                                          <LabelYesNo value={true} />
                                       </MenuItem>
                                       <MenuItem value={0}>
                                          <LabelYesNo value={false} />
                                       </MenuItem>
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="applyLegalStorage">
                                       {t(
                                          "BusinessRegistry.fields.Apply legal storage"
                                       )}
                                    </InputLabel>
                                    <Select
                                       name="applyLegalStorage"
                                       error={Boolean(
                                          touched.applyLegalStorage &&
                                             errors.applyLegalStorage
                                       )}
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (e.target.value === undefined) {
                                             e.target.value = null
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.applyLegalStorage}
                                       fullWidth
                                    >
                                       <MenuItem value={undefined}>
                                          <LabelYesNo value={null} />
                                       </MenuItem>
                                       <MenuItem value={1}>
                                          <LabelYesNo value={true} />
                                       </MenuItem>
                                       <MenuItem value={0}>
                                          <LabelYesNo value={false} />
                                       </MenuItem>
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="legalStorageActive">
                                       {t(
                                          "BusinessRegistry.fields.Legal storage activated"
                                       )}
                                    </InputLabel>
                                    <Select
                                       name="legalStorageActive"
                                       error={Boolean(
                                          touched.legalStorageActive &&
                                             errors.legalStorageActive
                                       )}
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (e.target.value === undefined) {
                                             e.target.value = null
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.legalStorageActive}
                                       fullWidth
                                    >
                                       <MenuItem value={undefined}>
                                          <LabelYesNo value={null} />
                                       </MenuItem>
                                       <MenuItem value={1}>
                                          <LabelYesNo value={true} />
                                       </MenuItem>
                                       <MenuItem value={0}>
                                          <LabelYesNo value={false} />
                                       </MenuItem>
                                    </Select>
                                 </FormControl>
                              </Grid>
                           </Grid>
                        </AccordionDetails>

                        <AccordionActions>
                           <Button
                              className={classes.button}
                              onClick={() => {
                                 resetForm()
                                 handleReset()
                              }}
                              variant="contained"
                           >
                              {t("Default.Cancella")}
                           </Button>
                           <Button
                              className={classes.button}
                              type="submit"
                              color="primary"
                              variant="contained"
                              style={{ width: "max-content" }}
                           >
                              {t("Default.Cerca")}
                           </Button>
                        </AccordionActions>
                     </Accordion>
                  </form>
               )
            }}
         </Formik>
      </Box>
   )
}

export default BusinessRegistryConfigurationsFilters
