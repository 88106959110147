import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import { Notification, useGetInvoiceFileQuery } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import TabPanelAttachments from "views/common/InvoiceDetails/tab/TabPanelAttachments"
import TabPanelInfo from "views/common/InvoiceDetails/tab/TabPanelInfo"
import TabPanelJson from "views/common/InvoiceDetails/tab/TabPanelJson"
import TabPanelXml from "views/common/InvoiceDetails/tab/TabPanelXml"

const tabNameToIndex = {
   info: 0,
   attachments: 1,
   json: 2,
   xml: 3,
}

type tabNameToIndexType = Omit<typeof tabNameToIndex, "xml"> & {
   xml: number | null
}

type IndexToTabNameType = (typeof tabNameToIndex)[keyof typeof tabNameToIndex]

type Props = {
   mode: "info" | "attachments" | "json" | "xml"
   invoice: GovITInvoice
   notifications: Notification[] | null
}
export default function InvoiceDraftsDialogTabs({
   mode,
   invoice,
   notifications,
}: Props) {
   const classes = useStyles()

   const { data: invoiceXml } = useGetInvoiceFileQuery({
      accept: "application/xml",
      uuid: invoice?.uuid,
   })

   const getTabsIndex = invoiceXml
      ? ({
           ...tabNameToIndex,
           xml: tabNameToIndex.xml,
        } as tabNameToIndexType)
      : ({
           ...tabNameToIndex,
           xml: null,
        } as tabNameToIndexType)

   const [value, setValue] = React.useState<IndexToTabNameType>(
      getTabsIndex[mode in getTabsIndex ? mode : "info"] || tabNameToIndex.info
   )

   const handleChange = (_event: any, newValue: IndexToTabNameType) => {
      setValue(newValue)
   }

   const a11yProps = (index: IndexToTabNameType) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
         >
            <Tab label="info" {...a11yProps(getTabsIndex.info)} />
            <Tab label="attachments" {...a11yProps(getTabsIndex.attachments)} />
            <Tab label="json" {...a11yProps(getTabsIndex.json)} />
            {invoiceXml && getTabsIndex.xml && (
               <Tab label="xml" {...a11yProps(getTabsIndex.xml)} />
            )}
         </Tabs>

         <TabPanelInfo
            invoice={invoice}
            value={value}
            index={getTabsIndex.info}
            notifications={notifications}
         />
         <TabPanelAttachments
            invoice={invoice}
            value={value}
            index={getTabsIndex.attachments}
         />
         <TabPanelJson
            invoice={invoice}
            value={value}
            index={getTabsIndex.json}
         />
         {invoiceXml && getTabsIndex.xml && (
            <TabPanelXml
               invoiceXml={invoiceXml}
               value={value}
               index={getTabsIndex.xml}
            />
         )}
      </Paper>
   )
}

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})
