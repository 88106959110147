import { Box, DialogContent, Link, Typography } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { HelpCircle } from "react-feather"
import { useTranslation } from "react-i18next"
import {
   useCreatePreSaleActionMutation,
   useLazyGetPreSalesQuery,
} from "services/commonApi"
import { DialogId } from "store/slices/dialogs"
import * as Yup from "yup"
import EditPreSaleActionForm, { FormValues } from "../EditPreSaleActionForm"

const DIALOG_ID: DialogId = "settings_new_pre_sale_action"

const initialValues: FormValues = {
   threshold: 0,
   action_type: "alert_mail",
   target: "",
   enabled: true,
}

const validationSchema = Yup.object().shape({
   threshold: Yup.number().integer().positive().required(),
   target: Yup.string().email().required(),
})

type Props = BaseDialogActions

const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [getPreSales] = useLazyGetPreSalesQuery()
   const [createPreSaleAction] = useCreatePreSaleActionMutation()

   const handleSubmit = async (values: FormValues) => {
      const { data } = await getPreSales()
      const preSaleUuid = data?.["hydra:member"][0].uuid

      if (preSaleUuid == null) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      const response = await createPreSaleAction({
         ...values,
         pre_sale_uuid: preSaleUuid,
      })

      if ("data" in response) {
         enqueueSnackbar(
            t("settings.pre_sale_actions.messages.pre_sale_action_created"),
            { variant: "success" }
         )
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={2}
         >
            <Typography variant="subtitle2">
               {t(
                  "settings.pre_sale_actions.edit_pre_sale_action_dialog_title"
               )}
            </Typography>
            <Link
               href="https://docs.acubeapi.com/documentation/common/presale-actions/"
               target="_blank"
               title="Documentation"
            >
               <HelpCircle />
            </Link>
         </Box>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditPreSaleActionForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const NewPreSaleActionDialog = () => (
   <BaseDialog id={DIALOG_ID}>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default NewPreSaleActionDialog
