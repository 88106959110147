import { Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

const ActionsButtons = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   return (
      <Grid
         container
         style={{
            marginBottom: "10px",
         }}
      >
         <Grid
            item
            xs={12}
            md={6}
            style={{
               padding: "5px",
            }}
         >
            <Button
               onClick={() => {
                  dispatch(
                     //@TODO: create translation for "business registry.configuration.form" dialog
                     openDialog({
                        id: "business.registry.configurations",
                        data: {
                           fiscal_id: null,
                        },
                     })
                  )
               }}
               color="primary"
               variant="contained"
               style={{ marginRight: "10px", width: "max-content" }}
            >
               {t("BusinessRegistry.Nuovo")}
            </Button>
         </Grid>
         <Grid item xs={false} md={6} />
      </Grid>
   )
}

export default ActionsButtons
