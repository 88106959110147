import { Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { useTranslation } from "react-i18next"
import XMLViewer from "react-xml-viewer"
import { useGetInvoiceFileQuery } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"

type Props = {
   value: number
   index: number
   invoiceXml?: string | Blob
   invoice?: GovITInvoice
}
export default function TabPanelXml({
   value,
   index,
   invoice,
   invoiceXml,
}: Props) {
   const { data: xml } = useGetInvoiceFileQuery(
      {
         accept: "application/xml",
         uuid: invoice?.uuid as string,
      },
      {
         skip: !invoice?.uuid,
      }
   )

   const { t } = useTranslation()

   const xmlData = invoiceXml || xml
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               {xmlData ? (
                  <XMLViewer xml={xmlData} indentSize={4} />
               ) : (
                  <Typography variant="body1">
                     {t("global.no_xml_payload")}
                  </Typography>
               )}
            </Box>
         )}
      </div>
   )
}
