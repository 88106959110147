import TextField from "@material-ui/core/TextField"

export default function InputFile(props) {
   let fileReader

   const handleFileChosen = (file, callback) => {
      fileReader = new FileReader()
      fileReader.onloadend = (e) => {
         callback(fileReader.result, file.name)
      }
      fileReader.readAsText(file)
   }

   return (
      <TextField
         type="file"
         id={props.id}
         accept={props.accept}
         inputProps={{ accept: props.accept }}
         onChange={(e) => handleFileChosen(e.target.files[0], props.callback)}
         fullWidth
      />
   )
}
