import { ButtonGroup, Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { useEffect } from "react"
import {
   OpenbankingWebhook,
   useGetWebhooksQuery,
} from "services/openbankingApi/webhooks"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTotalItems,
   TableName,
   updateTotalItems,
} from "store/tablesState"
import Label from "views/common/Label"
import DeleteWebhookCTA from "./DeleteWebhookCTA"
import EditWebhookCTA from "./EditWebhookCTA"

const table: TableName = "openbanking.webhooks"

const columns: TableColumn<OpenbankingWebhook>[] = [
   {
      rowKey: "uuid",
      configurable: true,
      labelTranslationId: "openbanking.webhooks.table_head.uuid",
      format: (value) => value,
   },
   {
      rowKey: "event",
      configurable: true,
      labelTranslationId: "openbanking.webhooks.table_head.event",
      format: (value) => <Label color="primary">{value}</Label>,
   },
   {
      rowKey: "targetUrl",
      configurable: true,
      labelTranslationId: "openbanking.webhooks.table_head.target_url",
      format: (value) => value,
   },
   {
      rowKey: "authenticationType",
      configurable: true,
      labelTranslationId: "openbanking.webhooks.table_head.authentication_type",
      format: (value) => value,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => (
         <ButtonGroup>
            <EditWebhookCTA webhook={row} />
            <DeleteWebhookCTA webhookUuid={row.uuid} />
         </ButtonGroup>
      ),
   },
]

const WebhooksTable = () => {
   const dispatch = useAppDispatch()

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const { data, isError, isFetching } = useGetWebhooksQuery({
      itemsPerPage,
      page: currentPage,
   })

   const items = data?.["hydra:member"]
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columns}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="openbanking.webhooks.messages.no_webhooks"
            withPagination
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default WebhooksTable
