import { Box, Button, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { setOnboardMode1CurrentStep } from "store/slices/onboard"

const Start = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const handleStartOnboard = async () => {
      dispatch(setOnboardMode1CurrentStep("download"))
   }

   return (
      <>
         <Box marginBottom={6}>
            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.start.body_1")}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.start.body_2", {
                     url: (text: string) => <a href={text}>{text}</a>,
                  })}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.start.body_3")}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.start.body_4")}
               </Typography>
            </Box>
         </Box>

         <Box display="flex" justifyContent="center">
            <Button
               color="primary"
               variant="contained"
               size="large"
               onClick={handleStartOnboard}
            >
               {t("onboard.steps.start.cta")}
            </Button>
         </Box>
      </>
   )
}

export default Start
