import Box from "@material-ui/core/Box"
import LinearProgress, {
   LinearProgressProps,
} from "@material-ui/core/LinearProgress"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

function LinearProgressWithLabel(
   props: LinearProgressProps & { value: number }
) {
   return (
      <Box display="flex" alignItems="center">
         <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} />
         </Box>
         <Box>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
               props.value
            )}%`}</Typography>
         </Box>
      </Box>
   )
}

const useStyles = makeStyles({
   root: {
      width: "100%",
   },
})

type ProgressBarProps = {
   progress: number
}
export default function ProgressBar({ progress }: ProgressBarProps) {
   const classes = useStyles()
   return (
      <div className={classes.root}>
         <LinearProgressWithLabel value={progress} />
      </div>
   )
}
