import { DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik, FormikHelpers } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   CreateLegalEntityBody,
   useCreateLegalEntityMutation,
} from "services/peppol/legalEntities"
import * as Yup from "yup"
import EditLegalEntityForm, { FormValues } from "../EditLegalEntityForm"

const initialValues = {
   registeredName: "",
   country: "",
   address: "",
   city: "",
   stateOrProvince: "",
   zipCode: "",
   identifierScheme: "",
   identifierValue: "",
   receivedDocumentNotificationEmails: "",
}

const validationSchema = Yup.object().shape({
   registeredName: Yup.string().required(),
   identifierScheme: Yup.string().required(),
   identifierValue: Yup.string().required(),
})

const toLegalEntity = (formValues: FormValues) =>
   Object.entries(formValues).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value !== "" ? value : null }),
      {} as CreateLegalEntityBody
   )

type Props = BaseDialogActions

const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [createLegalEntity] = useCreateLegalEntityMutation()

   const handleSubmit = async (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      const response = await createLegalEntity(toLegalEntity(values))

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         const violations = data?.violations?.reduce((acc, violation) => {
            if (violation.propertyPath in acc) {
               acc[violation.propertyPath] += ` ${violation.message}`
            } else {
               acc[violation.propertyPath] = violation.message
            }
            return acc
         }, {} as { [key: string]: string })

         Object.entries(violations ?? {}).forEach(([propertyPath, message]) => {
            setFieldError(propertyPath, message)
         })

         return
      }

      if ("data" in response) {
         enqueueSnackbar(
            t("peppol.legal_entities.messages.legal_entity_created"),
            { variant: "success" }
         )
         handleClose()

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>
            {t("peppol.legal_entities.edit_legal_entity_dialog_title")}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditLegalEntityForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                     type="create"
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const PeppolNewLegalEntityDialog = () => (
   <BaseDialog id="peppol_new_legal_entity" fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default PeppolNewLegalEntityDialog
