import { Box, Paper, Tooltip } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import { useEffect } from "react"
import {
   GovITInvoiceTransfer,
   useGetInvoiceTransfersQuery,
} from "services/gov-it"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import { Marking } from "views/common/Marking"
import ActionsButtons from "views/gov-it/InvoiceTransfers/ActionsButtons"
import ViolationMarking from "views/gov-it/InvoiceTransfers/component/ViolationMarking"
import DownloadCsvCTA from "../DownloadCsvCTA"
import RowCTAs from "./RowCTAs"

const table: TableName = `gov_it.invoice_transfers`

export type GovItInvoiceTransfersTableConfigurableColumns = Exclude<
   NonNullable<(typeof columnsDefinition)[number]["rowKey"]>,
   "supplier_fiscal_id" | "customer_fiscal_id"
>

const Actions = () => {
   return (
      <Box
         style={{
            display: "flex",
            gap: "1rem",
         }}
      >
         <DownloadCsvCTA />
      </Box>
   )
}

const GovITInvoiceTransfersTable = () => {
   const dispatch = useAppDispatch()

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table: "gov_it.invoice_transfers" })
   )

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const { data, isError, isFetching } = useGetInvoiceTransfersQuery({
      accept: "application/ld+json",
      "created_at[strictly_after]": `${filters.createdStrictlyAfter} 00:00:00`,
      "created_at[before]": `${filters.createdBefore} 23:59:59`,
      original_file_name: filters.originalFileName,
      ...(filters.existsViolations !== null && {
         "exists[violations]": filters.existsViolations,
      }),
      itemsPerPage,
      page: currentPage,
   })

   const items = data?.["hydra:member"]
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            BottomButtonActions={<Actions />}
            buttonActions={<ActionsButtons />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: "gov_it.invoice_transfers",
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
         />
      </Paper>
   )
}

export default GovITInvoiceTransfersTable

const columnsDefinition: TableColumn<GovITInvoiceTransfer>[] = [
   {
      rowKey: "original_file_name",
      configurable: true,
      labelTranslationId:
         "gov_it.invoice_transfers.table_head.original_file_name",
      format: (value) => value,
   },
   {
      rowKey: "reference",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.reference",
      format: (value) => value,
   },
   {
      rowKey: "supplier_name",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.supplier_name",
      format: (value, row) => (
         <Tooltip title={row.supplier_fiscal_id ?? "Not found"}>
            <span>{value ?? "Not found"}</span>
         </Tooltip>
      ),
   },
   {
      rowKey: "customer_name",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.customer_name",
      format: (value, row) => (
         <Tooltip title={row.customer_fiscal_id ?? "Not found"}>
            <span>{value ?? "Not found"}</span>
         </Tooltip>
      ),
   },
   {
      rowKey: "invoice_number",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.invoice_number",
      format: (value) => value,
   },
   {
      rowKey: "invoice_date",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.invoice_date",
      format: (value) => value,
   },
   {
      rowKey: "invoice_type",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.invoice_type",
      format: (value) => value,
   },
   {
      rowKey: "invoice_net_amount",
      configurable: true,
      labelTranslationId:
         "gov_it.invoice_transfers.table_head.invoice_net_amount",
      format: (value) => value,
   },
   {
      rowKey: "invoice_vat_amount",
      configurable: true,
      labelTranslationId:
         "gov_it.invoice_transfers.table_head.invoice_vat_amount",
      format: (value) => value,
   },
   {
      rowKey: "invoice_tot_amount",
      configurable: true,
      labelTranslationId:
         "gov_it.invoice_transfers.table_head.invoice_tot_amount",
      format: (value) => value,
   },

   {
      rowKey: "created_at",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.created_at",
      format: (value) => value,
   },
   {
      rowKey: "violations",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.violations",
      format: (value) => (
         <ViolationMarking
            isFilter={false}
            violations={value as string | null}
         />
      ),
   },
   {
      rowKey: "sent_at",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.sent_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },
   {
      rowKey: "sdi_id",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.sdi_id",
      format: (value) => value,
   },
   {
      rowKey: "sdi_file_name",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.sdi_file_name",
      format: (value) => value,
   },
   {
      rowKey: "invoice_uuid",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.invoice_uuid",
      format: (value) => value,
   },
   {
      rowKey: "marking",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.marking",
      format: (value) => <Marking marking={value} />,
   },
   {
      rowKey: "notice",
      configurable: true,
      labelTranslationId: "gov_it.invoice_transfers.table_head.notice",
      format: (value) => value,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_, row) => <RowCTAs invoiceTransfer={row} />,
   },
]
