import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { format, parseISO } from "date-fns"
import { useTranslation } from "react-i18next"
import { Notification } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import LabelYesNo from "views/common/LabelYesNo"
import {
   DisplayNotificationErrors,
   getErrorsForNSNotification,
} from "views/gov-it/InvoiceFix/ErrorMessage"
import { getLastNSNotification } from "views/gov-it/InvoiceFix/utils"
import { Marking } from "../../Marking"

type NotificationsStateProps = {
   notifications: Notification[]
   invoiceUuid: string
}
const NotificationsState = ({
   notifications,
   invoiceUuid,
}: NotificationsStateProps) => {
   const { t } = useTranslation()

   if (notifications == null) {
      return null
   }

   const lastNSNotification = getLastNSNotification(notifications)
   const errors = getErrorsForNSNotification(lastNSNotification)

   return (
      <>
         <DisplayNotificationErrors errors={errors} />
         <Box
            my={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
         >
            <Button
               variant="contained"
               color="primary"
               href={`/customer-invoice/${invoiceUuid}/fix`}
            >
               {t("gov_it.invoice_fix.fix")}
            </Button>
         </Box>
      </>
   )
}

type InvoiceDetailsProps = {
   invoice: GovITInvoice | null
}
function InvoiceDetails({ invoice }: InvoiceDetailsProps) {
   const { t } = useTranslation()

   return (
      <List>
         <ListItem divider={true}>
            <ListItemText primary="UUID" />
            <ListItemSecondaryAction>
               <ListItemText primary={invoice?.uuid ? invoice.uuid : null} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.Created at")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     invoice?.created_at &&
                     format(parseISO(invoice?.created_at), "dd MMM yyyy HH:mm")
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.Signed")} />
            <ListItemSecondaryAction>
               <ListItemText primary={<LabelYesNo value={invoice?.signed} />} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.To Government")} />
            <ListItemSecondaryAction>
               <ListItemText primary={<LabelYesNo value={invoice?.to_pa} />} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.Downloaded")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={<LabelYesNo value={invoice?.downloaded} />}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary="Sdi FileId" />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={invoice?.sdi_file_id ? invoice.sdi_file_id : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary="Sdi Filename" />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     invoice?.sdi_file_name ? invoice.sdi_file_name : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.Invoice Date")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={invoice?.invoiceDate ? invoice.invoiceDate : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("InvoiceDetail.Invoice Number")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     invoice?.invoiceNumber ? invoice.invoiceNumber : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary="Marking" />
            <ListItemSecondaryAction>
               <ListItemText primary={<Marking marking={invoice?.marking} />} />
            </ListItemSecondaryAction>
         </ListItem>
      </List>
   )
}

type TabPanelInfoProps = {
   value: number
   index: number
   invoice: GovITInvoice
   isAllowedToFixInvoice?: boolean
   notifications: Notification[] | null
}
export default function TabPanelInfo({
   value,
   index,
   invoice,
   notifications,
   isAllowedToFixInvoice,
}: TabPanelInfoProps) {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && invoice?.uuid && (
            <Box p={3}>
               {isAllowedToFixInvoice &&
                  invoice?.marking === "rejected" &&
                  notifications && (
                     <NotificationsState
                        notifications={notifications}
                        invoiceUuid={invoice?.uuid}
                     />
                  )}
               <InvoiceDetails invoice={invoice} />
            </Box>
         )}
      </div>
   )
}
