import { Box, Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import { selectIsUserStripe } from "store/auth"
import { openDialog } from "store/slices/dialogs"
import { selectTableLastCheckedColumns, TableName } from "store/tablesState"

const table: TableName = "invoice.drafts"
type props = {
   invoices: GovITInvoice[] | undefined
}
const ActionsButtons = ({ invoices }: props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))
   const lastCheckedColumns = useAppSelector((state) =>
      selectTableLastCheckedColumns(state, { table })
   )

   if (isStripeUser) {
      return <Box />
   }

   return (
      <Grid
         container
         style={{
            marginBottom: "10px",
         }}
      >
         <Grid
            item
            xs={12}
            md={6}
            style={{
               padding: "5px",
            }}
         >
            <Button
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "invoice.drafts.form",
                        data: {
                           mode: "new",
                        },
                     })
                  )
               }}
               color="primary"
               variant="contained"
               style={{ marginRight: "10px" }}
            >
               {t("gov_it.invoice_drafts.new")}
            </Button>

            <Button
               disabled={!lastCheckedColumns}
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "invoice.drafts.sentBlockInvoices",
                        data: {
                           mode: "view",
                           invoices: invoices ?? ([] as GovITInvoice[]),
                        },
                     })
                  )
               }}
               color="primary"
               variant="contained"
            >
               {t("gov_it.invoice_drafts.view_last_sent")}
            </Button>
         </Grid>
         <Grid item xs={false} md={6} />
      </Grid>
   )
}

export default ActionsButtons
