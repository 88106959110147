import { Box, CircularProgress, Switch, Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useUpdateBusinessRegistryMutation } from "services/openbankingApi/businessRegistry"
import { useAppDispatch } from "store"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"

type Props = {
   fiscalId: string
   enabled: boolean
}

const DataReadingToggle = ({ fiscalId, enabled }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [updateBusinessRegistry, { isLoading }] =
      useUpdateBusinessRegistryMutation()

   const toggleDataReading = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t(
               "openbanking.business_registry.enable_data_reading_confirmation"
            ),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await updateBusinessRegistry({
         fiscalId,
         enabled: !enabled,
      })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      const message = enabled
         ? "openbanking.business_registry.messages.data_reading_disabled"
         : "openbanking.business_registry.messages.data_reading_enabled"

      enqueueSnackbar(t(message, { fiscalId }), { variant: "success" })
   }

   if (isLoading) {
      return (
         <Box
            display="flex"
            justifyContent="flex-start"
            color="grey.500"
            style={{ paddingLeft: "12px" }}
         >
            <CircularProgress size={24} color="inherit" />
         </Box>
      )
   }

   return (
      <Tooltip
         title={t("openbanking.business_registry.toggle_data_reading_cta")}
      >
         <Switch
            checked={enabled}
            onChange={toggleDataReading}
            color="primary"
         />
      </Tooltip>
   )
}

export default DataReadingToggle
