import {
   Divider,
   IconButton,
   ListItemIcon,
   ListItemText,
   makeStyles,
   Menu,
   MenuItem,
} from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import AccountTreeRoundedIcon from "@material-ui/icons/AccountTreeRounded"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import {
   clearImpersonatedUser,
   getImpersonatedUser,
} from "helper/impersonateUser"
import React from "react"
import {
   Activity as ActivityIcon,
   LogOut as LogOutIcon,
   User as UserIcon,
} from "react-feather"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store"
import {
   logout as logoutAction,
   selectHasRole,
   selectIsUserStripe,
   selectUsername,
} from "store/auth"
import { openDialog } from "store/slices/dialogs"

const useStyles = makeStyles((theme) => ({
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))

const items = [
   {
      href: "/profile",
      icon: <UserIcon fontSize="small" />,
      title: "Menu.Il mio profilo",
   },
]

export default function ProfileMenu() {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const classes = useStyles()
   const [anchorEl, setAnchorEl] = React.useState(null)
   const isMenuOpen = Boolean(anchorEl)
   const username = useAppSelector(selectUsername)
   const impersonatedUser = getImpersonatedUser()
   const isImpersonatedUser = impersonatedUser != null
   const currentUser = impersonatedUser || username
   const isAllowedToSwitchUser = useAppSelector((state) =>
      selectHasRole(state, {
         context: "common.api.acubeapi.com",
         role: "ROLE_ADMIN",
         // role: "ROLE_ALLOWED_TO_SWITCH",
      })
   )
   const isAllowedToSubAccounts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "common.api.acubeapi.com",
         role: "ROLE_SUB_ACCOUNT_MANAGER",
      })
   )

   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))

   const handleProfileMenuClose = () => {
      setAnchorEl(null)
   }

   const restoreUser = () => {
      clearImpersonatedUser()
      handleProfileMenuClose()
   }

   const handleOpenImpersonateUserDialog = () => {
      dispatch(openDialog({ id: "impersonate_user" }))
      handleProfileMenuClose()
   }

   const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget)
   }

   const handleMenuItemClick = (menuItem) => {
      navigate(menuItem.href)
      handleProfileMenuClose()
   }

   const handleLogout = () => {
      dispatch(logoutAction())
   }

   return (
      <>
         <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="user-profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            className={classes.menuButton}
         >
            <AccountCircle />
         </IconButton>

         <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id="user-profile-menu"
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleProfileMenuClose}
         >
            {items.map((item, index) => {
               return (
                  <MenuItem
                     key={"menu-item-" + index}
                     onClick={() => handleMenuItemClick(item)}
                  >
                     <ListItemIcon>{item.icon}</ListItemIcon>
                     <ListItemText primary={t(item.title)} />
                  </MenuItem>
               )
            })}

            {!isStripeUser && (
               <MenuItem
                  onClick={() => {
                     navigate("/settings/pre-sale-actions")
                     handleProfileMenuClose()
                  }}
               >
                  <ListItemIcon>
                     {<ActivityIcon fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText primary={t("Menu.pre_sale_actions")} />
               </MenuItem>
            )}

            {isAllowedToSubAccounts && (
               <MenuItem
                  onClick={() => {
                     navigate("/settings/sub-accounts")
                     handleProfileMenuClose()
                  }}
               >
                  <ListItemIcon>
                     {<AccountTreeRoundedIcon fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText primary={t("common_subAccounts.title")} />
               </MenuItem>
            )}

            <MenuItem key={"menu-item-logout"} onClick={handleLogout}>
               <ListItemIcon>
                  <LogOutIcon fontSize="small" />
               </ListItemIcon>
               <ListItemText primary={t("Menu.Logout")} />
            </MenuItem>

            {isAllowedToSwitchUser && <Divider />}

            {isAllowedToSwitchUser &&
               (isImpersonatedUser ? (
                  <MenuItem onClick={restoreUser}>
                     <ListItemIcon>
                        <SwapHorizIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText primary={t("Menu.Exit switch")} />
                  </MenuItem>
               ) : (
                  <MenuItem onClick={handleOpenImpersonateUserDialog}>
                     <ListItemIcon>
                        <SwapHorizIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText primary={t("Menu.Switch user")} />
                  </MenuItem>
               ))}

            <Divider />

            <MenuItem disabled>
               <ListItemText primary={currentUser} />
            </MenuItem>
         </Menu>
      </>
   )
}
