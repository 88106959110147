import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from ".."

export type OpenbankingPaginationParams = {
   page?: number
   itemsPerPage?: number
}

type ConnectResponse = {
   connectUrl: string
}

type ConnectBody = {
   locale: string | null
   country: string | null
}

export const openbankingApi = createApi({
   reducerPath: "openbankingApi",
   tagTypes: [
      "BUSINESS_REGISTRY",
      "WEBHOOKS",
      "TRANSACTIONS",
      "ACCOUNTS",
      "PAYMENTS",
   ],
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_OB_API_BASE_URL as string,
   }),
   endpoints: (builder) => ({
      connect: builder.mutation<
         ConnectResponse,
         ConnectBody & { fiscalId: string }
      >({
         query: ({ fiscalId, ...body }) => ({
            url: `/business-registry/${fiscalId}/connect`,
            method: "POST",
            body,
         }),
      }),
   }),
})

export const { useConnectMutation } = openbankingApi
