import { Box, Button, FormLabel, Grid, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   AutoDecrementFieldGroup,
   FieldGroupByName,
   FieldGroupByNameKey,
   FormInvoiceType,
   getEmptyFieldsByFieldGroupSectionKey,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import SectionItem from "views/common/InvoiceForm/CommonInvoiceForm/components/ListOfFields"
type DisplayVariableSectionProps<T extends FormInvoiceType> = {
   type: T
   parentIndex?: number
   groupSections: FieldGroupByName<T>
   updateGroupSection: (
      sectionKey: FieldGroupByNameKey<T>,
      action?: "remove" | "add",
      index?: number,
      parentIndex?: number
   ) => void
   register: any
   setError: any
   getValues: any
   setValue: any
   control: any
   watch: any
   errorsForm: any
   bgChange?: boolean
   disableFields?: string[]
}

type ButtonAddNewSectionProps = {
   sectionTranslationId: string
   addFunction: () => void
}

const ButtonAddNewSection = ({
   sectionTranslationId,
   addFunction,
}: ButtonAddNewSectionProps) => {
   const { t } = useTranslation()

   return (
      <Box
         mt={2}
         style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "10px",
            backgroundColor: "#f5f5f5",
         }}
         width="100%"
      >
         <FormLabel component="legend">
            <Typography
               variant="h3"
               style={{
                  textDecoration: "underline",
               }}
            >
               {t(sectionTranslationId)}
            </Typography>
         </FormLabel>

         <Button
            color="primary"
            variant="contained"
            size="small"
            type="button"
            onClick={addFunction}
         >
            {t("global.add")} {t(sectionTranslationId)}
         </Button>
      </Box>
   )
}

const DisplayVariableSection = <T extends FormInvoiceType>({
   type,
   groupSections,
   register,
   setError,
   setValue,
   getValues,
   control,
   parentIndex,
   updateGroupSection,
   watch,
   errorsForm,
   disableFields,
}: DisplayVariableSectionProps<T>) => {
   const { t } = useTranslation()

   const { fields, append, remove } = useFieldArray({
      control,
      name: groupSections.name,
   })

   const [isRemovingState, setIsRemovingState] = useState<boolean>(false)

   const handleAutoDecrement = () => {
      if (
         isRemovingState &&
         AutoDecrementFieldGroup.includes(groupSections.name)
      ) {
         const values = getValues(groupSections.name)
         groupSections.sections.forEach((section, index) => {
            section.fields.forEach((field) => {
               if (field.autoDecrement) {
                  let fieldValue = getValues(field.name)
                  if (typeof fieldValue === "string") {
                     fieldValue = parseInt(fieldValue)
                  }
                  if (fieldValue > 1) {
                     setValue(field.name, fieldValue - 1)
                  }
               }
            })
         })
         setValue(groupSections.name, values)
         setIsRemovingState(false)
      }
   }

   useEffect(() => {
      if (getValues(groupSections.name)?.length === 0) {
         setValue(groupSections.name, null)
      }
      handleAutoDecrement()
   }, [getValues(groupSections.name)])

   return groupSections.initialExist ? (
      <>
         {getValues(groupSections.name)?.length > 0 ? (
            groupSections.sections.map(({ key, ...section }, index) => {
               const currentSection = groupSections.sections[index]
               const previousSection = groupSections.sections[index - 1]
               const afterSection = groupSections.sections[index + 1]

               const isSameSection =
                  previousSection?.sectionTranslationId ===
                  currentSection.sectionTranslationId
               const willBeSameSection =
                  afterSection?.sectionTranslationId ===
                  currentSection.sectionTranslationId
               const bgChange = !section.isChildVariable
                  ? isSameSection && (index + 1) % 2 === 0
                  : undefined

               return (
                  <Grid
                     item
                     xs={12}
                     lg={12}
                     style={{
                        ...(section.children && {
                           backgroundColor: bgChange
                              ? "rgb(233 236 239 / 28%)"
                              : "white",
                           paddingRight: bgChange ? "1rem" : "0.5rem",
                           paddingLeft: bgChange ? "0.2rem" : "0.2rem",
                           paddingBottom: bgChange ? "2px" : "0",
                           paddingTop: "0.3rem",
                           padding: "1rem",
                           borderRadius: "10px",
                           border:
                              isSameSection || willBeSameSection
                                 ? "1px solid #ccc"
                                 : "none",
                           marginTop: willBeSameSection ? "10px" : "20px",
                        }),
                     }}
                  >
                     <SectionItem
                        key={window.crypto.randomUUID()}
                        sections={groupSections.sections}
                        register={register}
                        setError={setError}
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        errorsForm={errorsForm}
                        getValues={getValues}
                        index={index}
                        bgChange={bgChange}
                        onRemove={() => {
                           setIsRemovingState(true)
                           remove(index)
                           updateGroupSection(
                              groupSections.key,
                              "remove",
                              fields.length - 1 ?? undefined,
                              parentIndex ?? undefined
                           )
                        }}
                        onAdd={
                           index === groupSections.sections.length - 1 && (
                              <Button
                                 color="primary"
                                 variant="contained"
                                 size="small"
                                 type="button"
                                 onClick={() => {
                                    append(
                                       getEmptyFieldsByFieldGroupSectionKey(
                                          groupSections.key,
                                          fields.length + 1
                                       )
                                    )
                                    updateGroupSection(
                                       groupSections.key,
                                       "add",
                                       index,
                                       parentIndex ?? undefined
                                    )
                                 }}
                                 style={{
                                    fontWeight: "bold",
                                    marginRight: "1rem",
                                 }}
                              >
                                 {t("global.add")}{" "}
                                 {t(groupSections.sectionTranslationId)}
                              </Button>
                           )
                        }
                        withBtnDelete={true}
                        disableFields={disableFields}
                     />

                     {section.children &&
                        section.children?.length > 0 &&
                        groupSections.childrenListNames?.map((child) => (
                           <DisplayVariableSection
                              type={type}
                              key={window.crypto.randomUUID()}
                              groupSections={{
                                 initialExist: true,
                                 key: `${child.key}` as FieldGroupByNameKey<T>,
                                 sectionTranslationId:
                                    child.sectionTranslationId,
                                 sections:
                                    section.children?.map(
                                       (childSection, idx) => ({
                                          ...childSection,
                                          key: `${child.key}_${idx}`,
                                       })
                                    ) ?? [],
                                 name: `${groupSections.name}[${index}].${child.key}`,
                              }}
                              register={register}
                              setError={setError}
                              setValue={setValue}
                              getValues={getValues}
                              control={control}
                              errorsForm={errorsForm}
                              updateGroupSection={updateGroupSection}
                              watch={watch}
                              parentIndex={index}
                              disableFields={disableFields}
                           />
                        ))}

                     {groupSections.childrenListNames?.map(
                        (child) =>
                           !(
                              getValues(
                                 `${groupSections.name}[${index}].${child.key}`
                              )?.length > 0
                           ) &&
                           section.children?.length === 0 && (
                              <ButtonAddNewSection
                                 sectionTranslationId={
                                    child.sectionTranslationId
                                 }
                                 addFunction={() => {
                                    setValue(
                                       `${groupSections.name}[${index}].${child.key}`,
                                       [
                                          getEmptyFieldsByFieldGroupSectionKey(
                                             child.key
                                          ),
                                       ]
                                    )

                                    updateGroupSection(
                                       child.key,
                                       "add",
                                       undefined,
                                       parentIndex ?? index
                                    )
                                 }}
                              />
                           )
                     )}
                  </Grid>
               )
            })
         ) : (
            <ButtonAddNewSection
               sectionTranslationId={groupSections.sectionTranslationId}
               addFunction={() => {
                  append(
                     getEmptyFieldsByFieldGroupSectionKey(groupSections.key)
                  )
                  updateGroupSection(groupSections.key)
               }}
            />
         )}
      </>
   ) : null
}

export default DisplayVariableSection
