import { TableName } from "store/tablesState"
import SwitchCommonInvoiceForm from "views/common/InvoiceForm/SwitchCommonInvoiceForm"

type TabPanelSendNewInvoiceProps = {
   value: number
   index: number
   handleClose: () => void
}

const table: TableName = "customer.invoices"
const TabPanelCreateNewInvoice = ({
   value,
   index,
}: TabPanelSendNewInvoiceProps) => {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
         style={{
            padding: 10,
         }}
      >
         <SwitchCommonInvoiceForm
            project="invoice_sent"
            showBlockButtonCheckBoxForDraft={false}
            showRadioToChooseFormType={true}
            table={table}
            mode="create"
            invoice={null}
            formType="simple"
         />
      </div>
   )
}

export default TabPanelCreateNewInvoice
