import { IconButton, Tooltip } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { useTranslation } from "react-i18next"
import { PeppolLegalEntity } from "services/peppol/legalEntities"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   legalEntity: PeppolLegalEntity
}

const EditLegalEntityCTA = ({ legalEntity }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const openEditLegalEntityDialog = async () => {
      dispatch(
         openDialog({
            id: "peppol_edit_legal_entity",
            data: legalEntity,
         })
      )
   }

   return (
      <Tooltip title={t("peppol.legal_entities.edit_legal_entity_cta")}>
         <IconButton onClick={openEditLegalEntityDialog}>
            <EditIcon />
         </IconButton>
      </Tooltip>
   )
}

export default EditLegalEntityCTA
