import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { format, parseISO } from "date-fns"
import { useTranslation } from "react-i18next"
import { getLastRequest } from "../../../../services/getLastRequest"
import Label from "../../Label"
import { LabelOutcome } from "../../LabelOutcome"

function ExpenseDetails(props) {
   let expense = props.expense
   const { t } = useTranslation()

   return (
      <List>
         <ListItem divider={true}>
            <ListItemText primary="UUID" />
            <ListItemSecondaryAction>
               <ListItemText primary={expense?.uuid ? expense.uuid : null} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("ExpenseDetail.Created at")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     expense.created_at &&
                     format(parseISO(expense.created_at), "dd MMM yyyy HH:mm")
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("ExpenseDetail.Outcome")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     <LabelOutcome
                        value={getLastRequest(expense.requests)?.esito_chiamata}
                     />
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("ExpenseDetail.Request Type")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     <Label color={"primary"}>
                        {getLastRequest(expense.requests)?.request_type}
                     </Label>
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>
      </List>
   )
}

export default function TabPanelInfo(props) {
   const { value, index, expense } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && expense?.uuid && (
            <Box p={3}>
               <ExpenseDetails expense={expense} />
            </Box>
         )}
      </div>
   )
}
