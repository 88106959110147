import { z } from "zod"

export const ConnectSubAccountToBRCSchema = z.object({
   data: z.array(
      z.object({
         businessRegistryId: z.string().nullable().optional(),
         businessRegistryName: z.string().nullable().optional(),
      })
   ),
})

export type ConnectSubAccountToBRCSchemaType = z.infer<
   typeof ConnectSubAccountToBRCSchema
>
