import { format, getYear, set } from "date-fns"
import { enUS, it } from "date-fns/locale"

const locales = { it, en: enUS }

export function getMonthNameFromNumber(n, locale) {
   // month are counted starting from 0 to 11
   return format(set(new Date(), { month: n - 1 }), "MMMM", {
      locale: locales[locale],
   })
}

export function formatDate(dateString, pattern = "dd/MM/yyyy") {
   return format(new Date(dateString), pattern)
}

export function getCurrentYear() {
   return getYear(new Date())
}

export function getYearsList(fromYear, toYear) {
   const diffYears = fromYear - toYear
   return Array.from({ length: diffYears + 1 }, (v, i) => i).map(
      (idx) => fromYear - idx
   )
}
