import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   Typography,
} from "@material-ui/core"
import AccordionActions from "@material-ui/core/AccordionActions"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { DateInput, dateInputDelimiter } from "components/DateInput"
import { format, sub } from "date-fns"
import { Field, Formik, FormikHelpers } from "formik"
import { TextField } from "formik-material-ui"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { formatDate } from "services/dates"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"
import { readTableFilters } from "store/tablesState/utils"
import ViolationMarking from "views/gov-it/InvoiceTransfers/component/ViolationMarking"

const table: TableName = "gov_it.invoice_transfers"

type TableFilters = TablesState[typeof table]["filters"]
export type TableFilter = keyof TableFilters

export type FormValues = {
   createdAt: string
   existsViolations: boolean | null
   originalFileName: string
}

const emptyFilters: FormValues = {
   createdAt: "",
   existsViolations: null,
   originalFileName: "",
}
const InvoiceTransfersTableFilters = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )
   const { createdStrictlyAfter, createdBefore, ...filterValues } = filters

   const initialValues = {
      ...filterValues,
      createdAt:
         createdStrictlyAfter && createdBefore
            ? `${createdStrictlyAfter}${dateInputDelimiter}${createdBefore}`
            : "",
   }

   const filterChanged = () => {
      const temps = readTableFilters(table)
      if (!temps) return false
      return !_.isEqual(temps, {
         ...emptyFilters,
         createdStrictlyAfter,
         createdBefore,
      })
   }

   const [expanded, setExpanded] = React.useState(filterChanged)

   const handleReset = (setValues: FormikHelpers<FormValues>["setValues"]) => {
      dispatch(changePage({ table, page: 1 }))
      dispatch(resetFilters({ table }))
      const values = {
         ...emptyFilters,
         createdStrictlyAfter: format(
            sub(new Date(), { days: 30 }),
            "yyyy-MM-dd"
         ),
         createdBefore: format(new Date(), "yyyy-MM-dd"),
         createdAt: `${format(
            sub(new Date(), { days: 30 }),
            "yyyy-MM-dd"
         )}${dateInputDelimiter}${format(new Date(), "yyyy-MM-dd")}`,
      }
      setValues(values)
      handleFilter(values)
      setExpanded(false)
   }

   const handleFilter = (values: FormValues) => {
      dispatch(changePage({ table, page: 1 }))
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]

            if (filter === "createdAt") {
               const [after, before] = (value as string).split(
                  dateInputDelimiter
               )
               if (
                  after !== filters.createdStrictlyAfter ||
                  before !== filters.createdBefore
               ) {
                  dispatch(updateFilter({ table, filter, value }))
                  dispatch(
                     updateFilter({
                        table,
                        filter: "createdStrictlyAfter",
                        value: after,
                     })
                  )
                  dispatch(
                     updateFilter({
                        table,
                        filter: "createdBefore",
                        value: before,
                     })
                  )
               }
            }
            if (filterChanged && filter !== "createdAt") {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   const after = filters.createdStrictlyAfter
   const before = filters.createdBefore

   return (
      <Box mb={4}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleFilter}
            enableReinitialize
         >
            {({
               handleBlur,
               handleChange,
               handleSubmit,
               values,
               setValues,
               resetForm,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Accordion
                     elevation={1}
                     expanded={expanded}
                     onChange={(_, expanded) => setExpanded(expanded)}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="invoice_transfers_content"
                        id="invoice_transfers_filters"
                     >
                        <div>
                           <Typography>
                              {t("Default.Filtra i risultati")}{" "}
                              {after &&
                                 before &&
                                 t("InvoiceFilters.dateRange", {
                                    from: formatDate(after),
                                    to: formatDate(before),
                                 })}
                           </Typography>
                        </div>
                     </AccordionSummary>

                     <AccordionDetails>
                        <Grid container spacing={3}>
                           <Grid item md={4} xs={12}>
                              <DateInput
                                 label={t("InvoiceFilters.createdAt[after]")}
                                 name="createdAt"
                                 onChange={handleChange("createdAt")}
                                 value={values.createdAt}
                              />
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t(
                                    "gov_it.invoice_transfers.filters.original_file_name"
                                 )}
                                 name="originalFileName"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                 <InputLabel htmlFor="existsViolations">
                                    {t(
                                       "gov_it.invoice_transfers.filters.status"
                                    )}
                                 </InputLabel>
                                 <Select
                                    id="existsViolations"
                                    name="existsViolations"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.existsViolations ?? undefined}
                                    fullWidth
                                 >
                                    <MenuItem value={undefined}>
                                       <ViolationMarking
                                          isFilter={true}
                                          withViolations={null}
                                       />
                                    </MenuItem>
                                    <MenuItem value={0}>
                                       <ViolationMarking
                                          isFilter={true}
                                          withViolations={false}
                                       />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                       <ViolationMarking
                                          isFilter={true}
                                          withViolations={true}
                                       />
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           </Grid>
                        </Grid>
                     </AccordionDetails>
                     <AccordionActions>
                        <Button
                           onClick={() => {
                              resetForm()
                              handleReset(setValues)
                           }}
                           variant="contained"
                        >
                           {t("Default.Cancella")}
                        </Button>
                        <Button
                           type="submit"
                           color="primary"
                           variant="contained"
                        >
                           {t("Default.Cerca")}
                        </Button>
                     </AccordionActions>
                  </Accordion>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default InvoiceTransfersTableFilters
