import { Box, Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasRole, selectIsUserStripe } from "store/auth"
import { openDialog } from "store/slices/dialogs"
import { InvoiceType } from ".."

type ActionsButtonsProps = {
   type: InvoiceType
}
const ActionsButtons = ({ type }: ActionsButtonsProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))

   const hasRoleCreateInvoice = useAppSelector((state) =>
      selectHasRole(state, {
         context: "stripe.acubeapi.com",
         role: "ROLE_CREATE_INVOICE",
      })
   )

   const isCustomerInvoice = type === "customer-invoice"

   if (isCustomerInvoice && isStripeUser && !hasRoleCreateInvoice) {
      return <Box />
   }

   const importInvoiceConfig = isCustomerInvoice
      ? {
           dialogId: "customer.invoices.import_invoice",
           label: "CustomerInvoices.import_invoice",
        }
      : {
           dialogId: "supplier.invoices.import_invoice",
           label: "SupplierInvoices.import_invoice",
        }

   return (
      <Grid
         container
         style={{
            marginBottom: "10px",
         }}
      >
         <Grid
            item
            xs={12}
            md={6}
            style={{
               padding: "5px",
            }}
         >
            {isCustomerInvoice && (
               <Button
                  onClick={() => {
                     dispatch(
                        openDialog({
                           id: "customer.invoices.new",
                        })
                     )
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
               >
                  {t("CustomerInvoices.send_new_invoice")}
               </Button>
            )}

            <Button
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: importInvoiceConfig.dialogId as any,
                     })
                  )
               }}
               color="primary"
               variant="contained"
            >
               {t(importInvoiceConfig.label)}
            </Button>
         </Grid>
         <Grid item xs={false} md={6} />
      </Grid>
   )
}

export default ActionsButtons
