export default function getParameter(url, parameterName) {
   const regexp = new RegExp(parameterName + "=([^&]+)")
   const search = regexp.exec(url)
   if (!search) {
      return null
   }

   const captured = search[1]
   return captured ? captured : null
}
