import { Box, Card, CardActions, CardContent } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import {
   NOTIFICATION_AT,
   NOTIFICATION_DT,
   NOTIFICATION_MC,
   NOTIFICATION_NE,
   NOTIFICATION_NS,
   NOTIFICATION_RC,
} from "config"
import { download } from "helper/download"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   Notification,
   useGetInvoiceNotificationsQuery,
   useGetIsFixableInvoiceQuery,
   useLazyGetNotificationXmlQuery,
   useSetArchiveRejectedInvoiceMutation,
} from "services/gov-it"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasContext, selectHasRole } from "store/auth"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"
import { updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { ATNotificationDetails } from "views/common/Notification/ATNotificationDetails"
import { DTNotificationDetails } from "views/common/Notification/DTNotificationDetails"
import { MCNotificationDetails } from "views/common/Notification/MCNotificationDetails"
import { NENotificationDetails } from "views/common/Notification/NENotificationDetails"
import { NSNotificationDetails } from "views/common/Notification/NSNotificationDetails"
import { RCNotificationDetails } from "views/common/Notification/RCNotificationDetails"
import { isFixableErrorCode } from "views/gov-it/InvoiceFix/ErrorCodeGuard"
import Loader from "../Loader"

function renderNotification(notification: Notification) {
   if (notification.type === NOTIFICATION_RC) {
      return <RCNotificationDetails notification={notification} />
   }
   if (notification.type === NOTIFICATION_NE) {
      return <NENotificationDetails notification={notification} />
   }
   if (notification.type === NOTIFICATION_DT) {
      return <DTNotificationDetails notification={notification} />
   }
   if (notification.type === NOTIFICATION_AT) {
      return <ATNotificationDetails notification={notification} />
   }
   if (notification.type === NOTIFICATION_MC) {
      return <MCNotificationDetails notification={notification} />
   }
   if (notification.type === NOTIFICATION_NS) {
      return <NSNotificationDetails notification={notification} />
   }

   return <></>
}

type Props = {
   isOpen: boolean
   handleClose: () => void
   invoiceUuid: string | null
   notificationType: string | null
}
export default function NotificationsDialog({
   isOpen,
   handleClose,
   notificationType,
   invoiceUuid,
}: Props) {
   const classes = useStyles()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const dispatch = useAppDispatch()

   const [getNotificationXml, { isLoading: isDownloading, error: errorXml }] =
      useLazyGetNotificationXmlQuery()

   const [setArchiveRejectedInvoice, { isLoading: isSettingArhive }] =
      useSetArchiveRejectedInvoiceMutation()

   const { data: invoiceIsFixableData } = useGetIsFixableInvoiceQuery(
      {
         uuid: invoiceUuid!,
      },
      { skip: !invoiceUuid, refetchOnMountOrArgChange: true }
   )

   const { data, isLoading } = useGetInvoiceNotificationsQuery(
      {
         uuid: invoiceUuid!,
      },
      {
         skip: !invoiceUuid,
         refetchOnMountOrArgChange: true,
      }
   )

   const notification = data?.find((item) => item.type === notificationType)

   const handleDownloadXml = async (id: string, fileName: string) => {
      const response = await getNotificationXml({
         uuid: id,
      })

      if (errorXml) {
         enqueueSnackbar(errorXml, {
            variant: "error",
         })
         return
      }

      download(response.data, fileName)
   }

   const handleSetArchiveRejectedInvoice = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("CustomerInvoices.isArchiveRejectedInvoiceConfirmation"),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      dispatch(closeDialog())

      const response = await setArchiveRejectedInvoice({
         uuid: invoiceUuid!,
      })

      if ("error" in response) {
         enqueueSnackbar(t("CustomerInvoices.archiveRejectedInvoiceError"), {
            variant: "error",
         })
         persistTableRefresh("customer.invoices")
         dispatch(
            updateRefetch({
               table: "customer.invoices",
            })
         )
         return
      }

      enqueueSnackbar(t("CustomerInvoices.archiveRejectedInvoiceSuccess"), {
         variant: "success",
      })

      persistTableRefresh("customer.invoices")
      dispatch(
         updateRefetch({
            table: "customer.invoices",
         })
      )
   }

   const hasRoleToFixInvoice = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_FIX_INVOICE",
      })
   )
   const hasContextToFixInvoice = useAppSelector((state) =>
      selectHasContext(state, "stripe.acubeapi.com")
   )

   const isAllowedToFixInvoice =
      (hasRoleToFixInvoice || hasContextToFixInvoice) &&
      invoiceIsFixableData?.is_fixable

   const isFixableError = (notification: Notification) => {
      return (
         isAllowedToFixInvoice &&
         notification.type === NOTIFICATION_NS &&
         notification?.message?.lista_errori?.some(({ Errore }) => {
            if (Array.isArray(Errore)) {
               return Errore.some(({ Codice }) => isFixableErrorCode(Codice))
            }
            //@ts-ignore
            return isFixableErrorCode(Errore?.Codice)
         })
      )
   }

   return (
      <Dialog
         fullWidth
         maxWidth="sm"
         open={isOpen}
         onClose={handleClose}
         aria-labelledby="notifications-dialog-title"
         aria-describedby="notifications-dialog-description"
         className={classes.container}
      >
         <DialogTitle id="notifications-dialog-title">Notification</DialogTitle>

         {isLoading || isDownloading ? (
            <Loader />
         ) : (
            <>
               {!notification ? (
                  <></>
               ) : (
                  <DialogContent>
                     <DialogContentText>
                        <Card>
                           <CardContent>
                              {renderNotification(notification)}
                           </CardContent>
                           <CardActions
                              style={{
                                 display: "flex",
                                 justifyContent: isFixableError(notification)
                                    ? "space-between"
                                    : "flex-end",
                                 alignItems: "center",
                              }}
                           >
                              {isFixableError(notification) && (
                                 <Box
                                    style={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       alignItems: "flex-start",
                                       gap: 4,
                                    }}
                                 >
                                    <Button
                                       variant="contained"
                                       color="primary"
                                       onClick={handleSetArchiveRejectedInvoice}
                                    >
                                       {!isSettingArhive
                                          ? t("CustomerInvoices.set_as_solved")
                                          : t(
                                               "CustomerInvoices.set_as_solving"
                                            )}
                                    </Button>

                                    <Button
                                       variant="contained"
                                       color="primary"
                                       href={`/customer-invoice/${invoiceUuid}/fix`}
                                    >
                                       {t("gov_it.invoice_fix.fix")}
                                    </Button>
                                 </Box>
                              )}

                              <Box
                                 style={
                                    isFixableError(notification)
                                       ? {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            gap: 4,
                                         }
                                       : {}
                                 }
                              >
                                 <Button
                                    onClick={() => {
                                       handleDownloadXml(
                                          notification.uuid,
                                          notification.message.nome_file +
                                             "_" +
                                             notification.type +
                                             ".xml"
                                       )
                                    }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                 >
                                    {t("InvoiceDetail.Scarica ricevuta XML")}
                                 </Button>
                                 <Button
                                    onClick={handleClose}
                                    color="default"
                                    variant="contained"
                                    style={{ marginLeft: 8 }}
                                 >
                                    {t("Default.Chiudi")}
                                 </Button>
                              </Box>
                           </CardActions>
                        </Card>
                     </DialogContentText>
                  </DialogContent>
               )}
            </>
         )}
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
