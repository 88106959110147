import { createApi } from "@reduxjs/toolkit/query/react"
import { blobToBase64 } from "helper/encodings"
import { baseQuery } from "services/index"
import { HydraResponse } from "services/types"

export const PRESERVATION_DOCUMENT_TYPES = [
   {
      value: "registro-volontari",
      label: "Registro Volontari",
   },
   {
      value: "registro-liquidazione-iva",
      label: "Registro Liquidazione IVA",
   },
   {
      value: "registro-iva-vendite",
      label: "Registro IVA Vendite",
   },
   {
      value: "registro-iva-acquisti",
      label: "Registro IVA Acquisti",
   },
   {
      value: "ordini-fornitori",
      label: "Ordini Fornitori",
   },
   {
      value: "ordini-clienti",
      label: "Ordini Clienti",
   },
   {
      value: "libro-giornale",
      label: "Libro Giornale",
   },
   {
      value: "libro-cespiti",
      label: "Libro Cespiti",
   },
   {
      value: "contratti-fornitori",
      label: "Contratti Fornitori",
   },
   {
      value: "contratti-clienti",
      label: "Contratti Clienti",
   },
   {
      value: "contratti-dipendenti",
      label: "Contratti Dipendenti",
   },
   {
      value: "buste-paga-dipendenti",
      label: "Buste Paga Dipendenti",
   },
] as const

export const PRESERVATION_DOCUMENT_STATUS = [
   {
      value: "to_be_stored",
      label: "To be stored",
   },
   {
      value: "sent",
      label: "Sent",
   },
   {
      value: "sent_metadata",
      label: "Sent metadata",
   },
   {
      value: "stored",
      label: "Stored",
   },
] as const

export type PreservationDocumentStatus =
   (typeof PRESERVATION_DOCUMENT_STATUS)[number]["value"]

export type PreservationDocumentType =
   (typeof PRESERVATION_DOCUMENT_TYPES)[number]["value"]

export type PreservationDocument = {
   uuid: string
   created_at: string
   updated_at: string | null
   preserved_object_id: string
   original_filename: string | null
   status: PreservationDocumentStatus
   status_history: {
      to_be_stored?: string
      sent?: string
      sent_metadata?: string
      stored?: string
   } | null
   error_message: string | null
}

export type PreservationPaginationParams = {
   page?: number
   itemsPerPage?: number
}

type GetPreservationDocumentsParams = PreservationPaginationParams & {
   documentType: PreservationDocumentType
   status?: PreservationDocumentStatus
   "created_at[after]"?: string
   "created_at[before]"?: string
   "created_at[strictly_after]"?: string
   "created_at[strictly_before]"?: string
}

type GetPreservationDocumentsResponse = HydraResponse<PreservationDocument>

export const preservationApi = createApi({
   reducerPath: "preservationApi",
   tagTypes: ["PRESERVATION_DOCUMENTS"],
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_PRESERVATION_BASE_URL as string,
   }),
   endpoints: (builder) => ({
      getPreservationDocuments: builder.query<
         GetPreservationDocumentsResponse,
         GetPreservationDocumentsParams
      >({
         query: ({ documentType, ...params }) => ({
            url: `/preserved-documents/it/${documentType}`,
            headers: {
               accept: "application/ld+json",
            },
            params,
         }),
      }),

      getPreservationDocumentFile: builder.query<
         string,
         { uuid: string; documentType: PreservationDocumentType }
      >({
         query: ({ uuid, documentType }) => ({
            url: `/preserved-documents/it/${documentType}/${uuid}/original-document`,
            headers: {
               Accept: "application/octet-stream",
            },
            responseHandler: async (response) => {
               return blobToBase64(await response.blob())
            },
         }),
      }),

      getPreservationDocumentReceipt: builder.query<
         string,
         {
            uuid: string
            documentType: PreservationDocumentType
            accept: "application/pdf" | "application/xml"
         }
      >({
         query: ({ uuid, documentType, accept }) => ({
            url: `/preserved-documents/it/${documentType}/${uuid}/receipt`,
            headers: {
               accept,
            },
            responseHandler: async (response) => {
               if (accept === "application/pdf") {
                  return blobToBase64(await response.blob())
               }
               return response.text()
            },
         }),
      }),
   }),
})

export const {
   useGetPreservationDocumentsQuery,
   useLazyGetPreservationDocumentFileQuery,
   useLazyGetPreservationDocumentReceiptQuery,
} = preservationApi
