export const CUSTOMER_INVOICE_TYPE = 0
export const SUPPLIER_INVOICE_TYPE = 1
export const CUSTOMER_EXTRA_SDI_INVOICE_TYPE = 2
export const SUPPLIER_EXTRA_SDI_INVOICE_TYPE = 3

export const NOTIFICATION_RC = "RC"
export const NOTIFICATION_NE = "NE"
export const NOTIFICATION_DT = "DT"
export const NOTIFICATION_AT = "AT"
export const NOTIFICATION_MC = "MC"
export const NOTIFICATION_NS = "NS"

export const WAITING = "waiting"
export const SENT = "sent"
export const RECEIVED = "received"
export const DELIVERED = "delivered"
export const DELIVERED_PA = "delivered-pa"
export const NOT_DELIVERED = "not-delivered"
export const ACCEPTED_PA = "accepted-pa"
export const REJECTED_PA = "rejected-pa"
export const REJECTED = "rejected"
export const DEADLINE_TERMS = "deadline-terms"
export const QUARANTENA = "quarantena"
export const INVOICE_ERROR = "invoice-error"
export const NOTIFICATION_ERROR = "notification-error"
export const DRAFT = "draft"
export const DRAFT_BLOCKED = "draft-blocked"

export const CUSTOMER_INVOICE_STATES = [
   WAITING,
   SENT,
   DELIVERED,
   DELIVERED_PA,
   NOT_DELIVERED,
   ACCEPTED_PA,
   REJECTED_PA,
   REJECTED,
   DEADLINE_TERMS,
   QUARANTENA,
   INVOICE_ERROR,
   NOTIFICATION_ERROR,
]

export const SUPPLIER_INVOICE_STATES = [RECEIVED, QUARANTENA]

export const DRAFT_INVOICE_STATES = [DRAFT, DRAFT_BLOCKED]

export type AppEnv = "sandbox" | "production"
