import {
   Box,
   Button,
   CircularProgress,
   IconButton,
   Tooltip,
} from "@material-ui/core"
import { PictureAsPdf } from "@material-ui/icons"
import { blobToBase64 } from "helper/encodings"
import { useSnackbar } from "notistack"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import {
   useLazyGetInvoiceQuery,
   useLazyGetProtectedInvoiceQuery,
} from "services/gov-pl/invoices"

type Props = {
   asIcon?: boolean
   protectedInvoiceCode?: string
   invoiceUuid: string
}

const DownloadInvoicePdf = ({
   asIcon,
   protectedInvoiceCode,
   invoiceUuid,
}: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const ref = useRef<HTMLAnchorElement | null>(null)

   const [getInvoicePdf, { isFetching: isFetchingInvoice }] =
      useLazyGetInvoiceQuery()
   const [getPritectedInvoicePdf, { isFetching: isFetchingProtectedInvoice }] =
      useLazyGetProtectedInvoiceQuery()

   const isFetching = isFetchingInvoice || isFetchingProtectedInvoice

   const handleDownload = async () => {
      const commonParams = {
         accept: "application/pdf" as const,
         uuid: invoiceUuid,
      }
      const response =
         protectedInvoiceCode != null
            ? await getPritectedInvoicePdf({
                 ...commonParams,
                 code: protectedInvoiceCode,
              })
            : await getInvoicePdf(commonParams)

      if (
         "error" in response ||
         ref.current == null ||
         !(response.data instanceof Blob)
      ) {
         enqueueSnackbar(t("pl.public_invoice.error.generic"), {
            variant: "error",
         })
         return
      }

      const data = await blobToBase64(response.data!)
      ref.current.href = `data:application/pdf;base64,${data}`
      ref.current.click()
   }

   return (
      <>
         <a
            ref={ref}
            href="#"
            download="invoice.pdf"
            style={{ display: "none" }}
         >
            {t("pl.public_invoice.download_pdf")}
         </a>

         {asIcon && (
            <Tooltip title="Invoice PDF">
               <IconButton onClick={handleDownload}>
                  {isFetching ? (
                     <Box display="flex">
                        <CircularProgress size={20} color="inherit" />
                     </Box>
                  ) : (
                     <PictureAsPdf />
                  )}
               </IconButton>
            </Tooltip>
         )}

         {!asIcon && (
            <Button
               color="primary"
               disabled={isFetching}
               size="large"
               type="button"
               variant="contained"
               onClick={handleDownload}
            >
               {isFetching ? (
                  <>
                     <Box display="flex" mr={1}>
                        <CircularProgress size={24} color="inherit" />
                     </Box>
                     {t("pl.public_invoice.download_pdf_loading")}
                  </>
               ) : (
                  t("pl.public_invoice.download_pdf")
               )}
            </Button>
         )}
      </>
   )
}

export default DownloadInvoicePdf
