import { Icon } from "@iconify/react"
import { ButtonGroup } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteBusinessRegistryConfigurationMutation } from "services/gov-it/businessRegistryConfigurations"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { DeleteButton } from "views/common/DeleteItem/DeleteButton"
import RecoverInvoicesCTA from "../../RecoverInvoicesCTA"

type Props = {
   item: BusinessRegistryConfigurationType
}

const table: TableName = "business_registry_configurations"
export const RowCTAs = ({ item }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [deleteBusinessRegistry] =
      useDeleteBusinessRegistryConfigurationMutation()

   const { enqueueSnackbar } = useSnackbar()

   const handleDeleteApiConfiguration = async (id: string) => {
      const response = await deleteBusinessRegistry({
         fiscal_id: id,
      })

      if ("error" in response && "message" in response.error) {
         enqueueSnackbar(response.error.message, { variant: "error" })
         return
      }

      persistTableRefresh(table)
      dispatch(
         updateRefetch({
            table,
         })
      )
      enqueueSnackbar(t("global.deleted_success"), { variant: "success" })
   }

   const handleOpenDialog = (id: string) => {
      dispatch(
         openDialog({
            id: "business.registry.configurations",
            data: {
               fiscal_id: id,
            },
         })
      )
   }
   const handleStatsDialog = (id: string) => {
      dispatch(
         openDialog({
            id: "business.registry.configurations",
            data: {
               fiscal_id: id,
               mode: "stats",
            },
         })
      )
   }

   const handleOpenSetCredentialsDialog = (id: string) => {
      dispatch(
         openDialog({
            id: "govItSetCredentialsBusinessRegistryConfiguration",
            data: {
               id,
            },
         })
      )
   }

   const handleOpenUpdateSettingsRelateToSmartReceitDialog = (id: string) => {
      dispatch(
         openDialog({
            id: "govItUpdateSettingsRelatesToSmartReceipt",
            data: {
               id,
            },
         })
      )
   }

   return (
      <ButtonGroup>
         <RecoverInvoicesCTA
            fiscalId={item.fiscal_id}
            supplierInvoiceEnabled={item.supplier_invoice_enabled}
         />

         <Tooltip title={t("BusinessRegistry.setCredentials")}>
            <IconButton
               onClick={() => {
                  handleOpenSetCredentialsDialog(item.fiscal_id)
               }}
            >
               <Icon
                  icon="fluent:password-reset-48-regular"
                  width={27}
                  height={27}
               />
            </IconButton>
         </Tooltip>

         <Tooltip
            title={t(
               "gov_it.business_registry_configurations.updateSettingsRelatesToSmartReceipt.title"
            )}
         >
            <IconButton
               onClick={() => {
                  handleOpenUpdateSettingsRelateToSmartReceitDialog(
                     item.fiscal_id
                  )
               }}
            >
               <Icon icon="fluent:receipt-24-regular" width={27} height={27} />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("BusinessRegistry.Visualizza statistiche")}>
            <IconButton
               onClick={() => {
                  handleStatsDialog(item.fiscal_id)
               }}
            >
               <Icon icon="akar-icons:statistic-up" width={25} height={25} />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("BusinessRegistry.Modifica")}>
            <IconButton
               onClick={() => {
                  handleOpenDialog(item.fiscal_id)
               }}
            >
               <Icon icon="fluent:edit-16-regular" width={25} height={25} />
            </IconButton>
         </Tooltip>

         <DeleteButton
            title={t("global.cancella")}
            handleDelete={() => handleDeleteApiConfiguration(item.fiscal_id)}
         />
      </ButtonGroup>
   )
}
