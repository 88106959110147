import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import { useTranslation } from "react-i18next"
import TabPanelInfo from "./tab/TabPanelInfo"
import TabPanelReceipts from "./tab/TabPanelReceipts"

export default function ExpenseDialogTabs(props) {
   const classes = useStyles()
   const [value, setValue] = React.useState(0)
   const { t } = useTranslation()

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const a11yProps = (index) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
         >
            <Tab label={t("ExpenseDialog.Info")} {...a11yProps(0)} />
            <Tab label={t("ExpenseDialog.Receipts")} {...a11yProps(1)} />
         </Tabs>

         <TabPanelInfo expense={props.expense} value={value} index={0} />
         <TabPanelReceipts receipts={props.receipts} value={value} index={1} />
      </Paper>
   )
}

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})
