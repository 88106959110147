import { useEffect } from "react"
import { AuthFlowStatus } from "services/gov-pl/legalEntities"
import { useAppDispatch, useAppSelector } from "store"
import {
   selectOnboardMode1CurrentStep,
   setOnboardMode1CurrentStep,
} from "store/slices/onboard"
import DownloadSignatureFile from "./Download"
import End from "./End"
import SignSignatureFile from "./Sign"
import Start from "./Start"
import UploadSignatureFile from "./Upload"

const authFlowStatusToOnboardStep = (status: AuthFlowStatus) => {
   if (status === AuthFlowStatus.NEW) {
      return null
   }

   if (status === AuthFlowStatus.IN_PROGRESS) {
      return "download"
   }

   if ([AuthFlowStatus.USED, AuthFlowStatus.COMPLETED].includes(status)) {
      return "end"
   }

   return null
}

type Props = {
   authFlowStatus: AuthFlowStatus
   authFlowUuid: string | null | undefined
   nip: string
}

const OnboardSteps = ({ authFlowStatus, authFlowUuid, nip }: Props) => {
   const dispatch = useAppDispatch()
   const currentStep = useAppSelector(selectOnboardMode1CurrentStep)

   useEffect(() => {
      dispatch(
         setOnboardMode1CurrentStep(authFlowStatusToOnboardStep(authFlowStatus))
      )
   }, [dispatch, authFlowStatus])

   switch (currentStep) {
      case "download":
         return (
            <DownloadSignatureFile
               authFlowStatus={authFlowStatus}
               authFlowUuid={authFlowUuid!}
            />
         )
      case "sign":
         return <SignSignatureFile />
      case "upload":
         return <UploadSignatureFile authFlowUuid={authFlowUuid!} nip={nip} />
      case "end":
         return <End />
      default:
         return <Start />
   }
}

export default OnboardSteps
