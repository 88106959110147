import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { USERS_ME_ACCEPT_ONLY_VERIFIED_INVOICES } from "../../../endpoints"

export async function getSupplierInvoiceSettings(token) {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      process.env.REACT_APP_BASE_URL + USERS_ME_ACCEPT_ONLY_VERIFIED_INVOICES,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
