import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { INVOICES } from "../../../endpoints"

export async function postInvoiceNotify(token, id, isNotifications) {
   let headers = new Headers()
   headers.append("Authorization", "Bearer " + token)
   Object.entries(getImpersonatedUserHeaders()).forEach(([name, value]) => {
      headers.append(name, value)
   })

   let method = "POST"
   let endpoint = process.env.REACT_APP_BASE_URL + INVOICES + "/" + id

   if (isNotifications) {
      endpoint += "/notifications"
   }

   endpoint += "/notify"

   const response = await fetch(endpoint, {
      headers,
      method,
   })

   if (response.ok) {
      return response
   }

   let text = await response.text()
   throw new Error(text)
}
