import { Paper, Tab, Tabs } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useTranslation } from "react-i18next"
import TabPanelSendNewInvoice from "./TabPanelSendNewInvoice"
import TabPanelCreateNewInvoice from "./TabPannelCreateNewInvoice"

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})

const tabNameToIndex = {
   sendNewInvoice: 1,
   createNewInvoice: 0,
}
type IndexToTabNameType = (typeof tabNameToIndex)[keyof typeof tabNameToIndex]

type CreateInvoiceTabsProps = {
   handleClose: () => void
}
const CreateInvoiceTabs = ({ handleClose }: CreateInvoiceTabsProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const [value, setValue] = React.useState<IndexToTabNameType>(0)
   const a11yProps = (index: IndexToTabNameType) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })
   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{
               position: "sticky",
               top: "-20px",
               backgroundColor: "white",
               zIndex: 20,
            }}
         >
            <Tab
               label={t("Invoices.create_invoice")}
               {...a11yProps(tabNameToIndex.sendNewInvoice)}
            />
            <Tab
               label={t("Invoices.create_invoice_source")}
               {...a11yProps(tabNameToIndex.createNewInvoice)}
            />
         </Tabs>

         <TabPanelCreateNewInvoice
            value={value}
            index={tabNameToIndex.createNewInvoice}
            handleClose={handleClose}
         />
         <TabPanelSendNewInvoice
            value={value}
            index={tabNameToIndex.sendNewInvoice}
            handleClose={handleClose}
         />
      </Paper>
   )
}

export default CreateInvoiceTabs
