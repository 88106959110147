import { Box, ButtonGroup, Container, makeStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import DescriptionIcon from "@material-ui/icons/Description"
import { format, parseISO, sub } from "date-fns"
import { getCacheInvalidationTimestamp } from "helper/cacheInvalidationTimestamp"
import { isEqual } from "lodash"
import { useSnackbar } from "notistack"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import formatExpenses from "../../services/client/formatter/formatExpenses"
import { getExpenses } from "../../services/client/request/getExpenses"
import Page from "../common/components/Page"
import CustomTable from "../common/CustomTable"
import ExpenseDialog from "../common/expenseDetails/ExpenseDialog"
import ExpenseFilters from "../common/ExpenseFilters"
import Label from "../common/Label"
import Loader from "../common/Loader"

const CACHE_INVALIDATION_ENDPOINT_ID = "sistema-ts/expenses"

const today = format(new Date(), "yyyy-MM-dd") + " 23:59:59"
const prevMonth =
   format(sub(new Date(), { months: 1 }), "yyyy-MM-dd") + " 00:00:00"

const initialFiltersState = {
   created_at: {
      after: prevMonth,
      before: today,
   },
   codice_fiscale_proprietario: "",
   codice_fiscale_cittadino: "",
}

const Expense = () => {
   const classes = useStyles()
   const pagination = 30
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const [page, setPage] = React.useState(1)
   const [filters, setFilters] = React.useState(initialFiltersState)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const [isOpenExpenseDetailsDialog, setOpenExpenseDetailsDialog] =
      React.useState(false)
   const [expenseId, setExpenseId] = React.useState()
   const [rows, setRows] = React.useState({
      count: 0,
      first: null,
      last: null,
      next: null,
      previous: null,
      items: [],
   })
   const [cacheInvalidationTimestamp, setCacheInvalidationTimestamp] = useState(
      getCacheInvalidationTimestamp(CACHE_INVALIDATION_ENDPOINT_ID)
   )

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleOpenExpenseDetailsDialog = (id) => {
      setOpenExpenseDetailsDialog(true)
      setExpenseId(id)
   }

   const handleCloseExpenseDetailsDialog = () => {
      setOpenExpenseDetailsDialog(false)
      // setExpenseId(null)
   }

   const applyFilters = (values) => {
      const shouldResetFilters = Object.keys(values).length === 0
      if (shouldResetFilters) {
         setFilters(initialFiltersState)
      } else {
         // append values to current filters
         setFilters((filters) => ({ ...filters, ...values }))
      }
      setPage(1)
   }

   const searchParams = () => ({
      ...filters,
      page: page,
      ...(cacheInvalidationTimestamp != null
         ? { ts: cacheInvalidationTimestamp }
         : {}),
   })

   const fetchData = async () => {
      // filter by invoice uuid is handled in findInvoice
      if (filters.uuid) {
         return false
      }

      setIsLoading(true)
      setError(false)

      try {
         const results = await getExpenses(token, searchParams())
         let expensesFormatted = formatExpenses(results)
         setRows(expensesFormatted)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      fetchData()
   }, [page, filters, cacheInvalidationTimestamp])

   const hasFiltersSelected = useMemo(() => {
      return !isEqual(initialFiltersState, filters)
   }, [filters])

   return (
      <Page className={classes.root} title={t("Menu.Expenses")}>
         {isLoading ? (
            <Loader />
         ) : (
            <Container maxWidth={false}>
               <ExpenseFilters
                  applyFilters={applyFilters}
                  filterValues={filters}
               />
               {error ? (
                  <></>
               ) : (
                  <Box mt={3}>
                     <CustomTable
                        columns={columns}
                        rows={rows}
                        page={page}
                        pagination={pagination}
                        handleChangePage={handleChangePage}
                        handleOpenDialog={handleOpenExpenseDetailsDialog}
                     />
                  </Box>
               )}

               <ExpenseDialog
                  isOpen={isOpenExpenseDetailsDialog}
                  handleClose={handleCloseExpenseDetailsDialog}
                  expenseId={expenseId}
               />
            </Container>
         )}
      </Page>
   )
}

const columns = [
   {
      id: "uuid",
      label: "Expense.uuid",
      disablePadding: false,
      minWidth: 310,
   },
   {
      id: "requests",
      label: "Expense.Requests",
      disablePadding: true,
      format: (values) => {
         return <Label color={"primary"}>{values.length}</Label>
      },
   },
   {
      id: "codice_fiscale_proprietario",
      label: "Expense.CFOwner",
      disablePadding: true,
      format: (value) => {
         return <Label color={"primary"}>{value}</Label>
      },
   },
   {
      id: "codice_fiscale_cittadino",
      label: "Expense.CFCitizen",
      disablePadding: true,
      format: (value) => {
         return <Label color={"primary"}>{value}</Label>
      },
   },
   {
      id: "amount",
      label: "Expense.Amount",
      disablePadding: true,
      format: (value) => {
         return <Label color={"primary"}>{value} €</Label>
      },
   },
   {
      id: "created_at",
      label: "Expense.CreatedAt",
      disablePadding: true,
      format: (value) => {
         return format(parseISO(value), "dd MMM yyyy HH:mm:ss")
      },
   },
   {
      empty: true,
      align: "right",
      format: (value, props, item) => {
         return (
            <ButtonGroup>
               <Tooltip title="Expense Details">
                  <IconButton
                     onClick={() => {
                        props.handleOpenDialog(item.uuid)
                     }}
                  >
                     <DescriptionIcon />
                  </IconButton>
               </Tooltip>
            </ButtonGroup>
         )
      },
   },
]

export default Expense

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))
