import { Button } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeletePreSaleActionMutation } from "services/commonApi"
import { useAppDispatch } from "store"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"

type Props = {
   uuid: string
}

const DeletePreSaleActionCTA = ({ uuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [deletePreSaleAction] = useDeletePreSaleActionMutation()

   const handleDeletePreSaleAction = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t(
               "settings.pre_sale_actions.delete_pre_sale_action_confirmation"
            ),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await deletePreSaleAction({ uuid })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(
         t("settings.pre_sale_actions.messages.pre_sale_action_deleted"),
         { variant: "success" }
      )
   }

   return (
      <Button
         onClick={handleDeletePreSaleAction}
         color="primary"
         variant="contained"
      >
         {t("settings.pre_sale_actions.delete_pre_sale_action_cta")}
      </Button>
   )
}

export default DeletePreSaleActionCTA
