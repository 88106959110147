import { Button, Grid, makeStyles } from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionActions from "@material-ui/core/AccordionActions"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import DatePicker from "views/common/DatePicker"

const useStyles = makeStyles((theme) => ({
   root: {},
   button: {
      marginBottom: 12,
      marginRight: 12,
   },
}))

export default function ExpenseFilters(props, { className, ...rest }) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values,
      { resetForm, setErrors, setSubmitting }
   ) => {
      values = {
         ...values,
         type: props.type,
      }

      await props.applyFilters(values)
   }

   return (
      <Formik initialValues={props.filterValues} onSubmit={handleSubmit}>
         {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            touched,
            errors,
         }) => {
            return (
               <form
                  onSubmit={handleSubmit}
                  noValidate
                  className={clsx(classes.root, className)}
               >
                  <Accordion elevation={1}>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                     >
                        <div className={classes.column}>
                           <Typography className={classes.secondaryHeading}>
                              {t("Default.Filtra i risultati")}
                           </Typography>
                        </div>
                     </AccordionSummary>

                     <AccordionDetails className={classes.details}>
                        <Grid container spacing={3}>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 // disabled={values.uuid}
                                 id="created_at.after"
                                 name="created_at.after"
                                 label={t("ExpenseFilters.createdAt[after]")}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 autoSelectBeginningOfTheDay={true}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 // disabled={values.uuid}
                                 id="created_at.before"
                                 name="created_at.before"
                                 label={t("ExpenseFilters.createdAt[before]")}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 autoSelectEndOfTheDay={true}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t("ExpenseFilters.CfOwner")}
                                 name="codiceFiscaleProprietario"
                                 error={Boolean(
                                    touched.codice_fiscale_proprietario &&
                                       errors.codice_fiscale_proprietario
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t("ExpenseFilters.CfCitizen")}
                                 name="codiceFiscaleCittadino"
                                 error={Boolean(
                                    touched.codice_fiscale_cittadino &&
                                       errors.codice_fiscale_cittadino
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>

                     <AccordionActions>
                        <Button
                           className={classes.button}
                           onClick={() => {
                              resetForm()
                              props.applyFilters({})
                           }}
                           variant="contained"
                        >
                           {t("Default.Cancella")}
                        </Button>
                        <Button
                           className={classes.button}
                           type="submit"
                           color="primary"
                           variant="contained"
                        >
                           {t("Default.Cerca")}
                        </Button>
                     </AccordionActions>
                  </Accordion>
               </form>
            )
         }}
      </Formik>
   )
}
