import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Typography,
} from "@material-ui/core"
import PropTypes from "prop-types"

export default function ConfirmationDialog(props) {
   return (
      <Dialog
         disableBackdropClick
         maxWidth="xs"
         aria-labelledby="confirmation-dialog-title"
         open={props.open}
      >
         <DialogTitle id="confirmation-dialog-title">
            <Typography variant="h4">{props.title}</Typography>
         </DialogTitle>
         <DialogContent dividers>
            <Typography variant="body1">{props.text}</Typography>
         </DialogContent>
         <DialogActions>
            <Button autoFocus onClick={props.onCancel}>
               Cancel
            </Button>
            <Button onClick={props.onConfirm} color="primary">
               Confirm
            </Button>
         </DialogActions>
      </Dialog>
   )
}

ConfirmationDialog.propTypes = {
   onConfirm: PropTypes.func.isRequired,
   onCancel: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   text: PropTypes.string.isRequired,
}
