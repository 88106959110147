import { useOpenDialogFromURL } from "hooks/useOpenDialogFromURL"
import { Outlet } from "react-router"

const SideEffectsManager = () => {
   useOpenDialogFromURL()

   return <Outlet />
}

export default SideEffectsManager
