import { Collapse, IconButton } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSentInvoiceToPreservationMutation } from "services/gov-it"

type InvoicePreservedDocumentButtonProps = {
   uuid: string
   handlePreservedDocument?: VoidFunction
}

type BoxAlertProps = {
   type: "error" | "success"
   message?: string
   title: string
   open: boolean
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const BoxAlert = ({
   type,
   title,
   message,
   open,
   setOpen,
}: BoxAlertProps) => {
   return (
      <Box mb={3}>
         <Collapse in={open}>
            <Alert
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false)
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
               severity={type}
            >
               <AlertTitle>{title}</AlertTitle>
               {message && (
                  <p>
                     <strong>{message}</strong>
                  </p>
               )}
            </Alert>
         </Collapse>
      </Box>
   )
}
const InvoicePreservedDocumentButton = ({
   uuid,
   handlePreservedDocument,
}: InvoicePreservedDocumentButtonProps) => {
   const { t } = useTranslation()
   const [sentInvoiceToPreservation, { isLoading, error }] =
      useSentInvoiceToPreservationMutation()
   const [message, setMessage] = React.useState<string | null>(null)
   const [messageType, setMessageType] = React.useState<
      "error" | "success" | null
   >(null)
   const [open, setOpen] = React.useState(true)
   const { enqueueSnackbar } = useSnackbar()

   const handleInvoicePreservedDocument = async () => {
      setOpen(true)

      const response = await sentInvoiceToPreservation({
         invoiceUuid: uuid,
      })

      if ("error" in response && "data" in response.error) {
         setMessageType("error")
         setMessage(
            ((response.error.data as any)?.message as string) ??
               "An error occurred"
         )
         enqueueSnackbar((response.error.data as any)?.message, {
            variant: "error",
         })
         return
      }

      if ("data" in response && handlePreservedDocument) {
         setMessageType("success")
         setMessage("")
         enqueueSnackbar(t("InvoiceDetail.Invoice to preservation succeeded"), {
            variant: "success",
         })
         handlePreservedDocument()
      }
   }

   return (
      <Box>
         {message && messageType === "success" && (
            <BoxAlert
               type={"success"}
               title={t("InvoiceDetail.Invoice to preservation succeeded")}
               open={open}
               setOpen={setOpen}
            />
         )}

         {message && messageType === "error" && (
            <BoxAlert
               type={"error"}
               message={message}
               title={t("InvoiceDetail.Invoice to preservation failed")}
               open={open}
               setOpen={setOpen}
            />
         )}

         <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleInvoicePreservedDocument}
         >
            {isLoading
               ? t("global.loading")
               : t("InvoiceDetail.Invoice to preservation")}
         </Button>
      </Box>
   )
}

export default InvoicePreservedDocumentButton
