import {
   Box,
   Button,
   CircularProgress,
   Paper,
   Typography,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
   useGetRejectedInvoicesCountQuery,
   useRecoverRejectedInvoicesMutation,
} from "services/gov-it/rejectedInvoices"
import Label from "views/common/Label"

type Props = {
   fiscalId: string
   from: string
   to: string
}

const RecoverInvoices = ({ fiscalId, from, to }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [recoverInvoices] = useRecoverRejectedInvoicesMutation()
   const { data, isError } = useGetRejectedInvoicesCountQuery({
      fiscalId,
      from_date: from,
      to_date: to,
   })

   const recoverRejectedInvoices = async () => {
      const response = await recoverInvoices({
         fiscalId,
         from_date: from,
         to_date: to,
      })

      if ("data" in response) {
         enqueueSnackbar(
            t(
               "gov_it.business_registry_configurations.recover_invoices_modal.invoices_recovered"
            ),
            {
               variant: "success",
            }
         )
         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), {
         variant: "error",
      })
   }

   useEffect(() => {
      if (isError) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
      }
   }, [isError, enqueueSnackbar, t])

   if (data == null) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <CircularProgress size={32} color="inherit" />
         </Box>
      )
   }

   return (
      <Box textAlign="center">
         <Typography variant="body1">
            <Trans
               i18nKey="gov_it.business_registry_configurations.recover_invoices_modal.invoices_count"
               values={{ from, to }}
            />
         </Typography>

         <Box display="flex" mt={1} justifyContent="center">
            <Box flexBasis="45%" p={2}>
               <Paper>
                  <Box p={2}>
                     <Typography variant="h3">{data.count}</Typography>

                     <Box mt={1}>
                        <Label color="error">
                           {t(
                              "gov_it.business_registry_configurations.recover_invoices_modal.to_recover"
                           )}
                        </Label>
                     </Box>
                  </Box>
               </Paper>
            </Box>

            <Box flexBasis="45%" p={2}>
               <Paper>
                  <Box p={2}>
                     <Typography variant="h3">{data.pending}</Typography>

                     <Box mt={1}>
                        <Label color="success">
                           {t(
                              "gov_it.business_registry_configurations.recover_invoices_modal.pending_recover"
                           )}
                        </Label>
                     </Box>
                  </Box>
               </Paper>
            </Box>
         </Box>

         <Box mt={2}>
            <Button
               disabled={data.count === 0}
               size="medium"
               color="primary"
               variant="contained"
               onClick={recoverRejectedInvoices}
               style={{ width: "max-content" }}
            >
               {t(
                  "gov_it.business_registry_configurations.recover_invoices_modal.recover_cta"
               )}
            </Button>
         </Box>
      </Box>
   )
}

export default RecoverInvoices
