import { Box, Button } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { cleanedJsonObject } from "helper/cleanedJsonObject"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import {
   useCreateDraftInvoiceMutation,
   useSendDraftInvoiceMutation,
   useUpdateDraftInvoiceMutation,
   useValidateInvoiceMutation,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch } from "store"
import {
   closeDialog,
   InvoiceDraftsFormMode,
   openDialog,
} from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { ViolationType } from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog"
import CreateInvoiceDraftForm from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog/CreateInvoiceForm"

type TabPanelNewInvoiceDraftProps = {
   value: number
   index: number
   handleClose: () => void
   invoice?: GovITInvoice
   mode: InvoiceDraftsFormMode
}

const table: TableName = "invoice.drafts"
const TabPanelNewInvoiceDraft = ({
   value,
   index,
   handleClose,
   invoice,
   mode,
}: TabPanelNewInvoiceDraftProps) => {
   const { t } = useTranslation()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const genericError = {
      propertyPath: "generic",
      message: "An error occurred",
   } as ViolationType
   const [validateInvoice, { isLoading: validateLoading }] =
      useValidateInvoiceMutation()
   const [createDraftInvoice, { isLoading, error, isError }] =
      useCreateDraftInvoiceMutation()

   const [updateDraftInvoice, { isLoading: isFetching, error: updateError }] =
      useUpdateDraftInvoiceMutation()

   const [sendDraftInvoice] = useSendDraftInvoiceMutation()
   const [isValidForm, setIsValidForm] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const navigate = useNavigate()

   const dispatch = useAppDispatch()

   const handleSave = async (
      value: string,
      format: "json" | "xml",
      type: "simple" | "simplified",
      blockInvoiceSent: boolean
   ) => {
      setMessage(null)
      setErrors([])

      switch (mode) {
         case "new":
         case "edit":
            return await createOrUpdateDraftInvoice(
               value,
               format,
               type,
               blockInvoiceSent
            )
         case "send":
            await createOrUpdateDraftInvoice(
               value,
               format,
               type,
               blockInvoiceSent,
               "edit"
            ).then(async () => {
               const response = await sendDraftInvoice({
                  uuid: invoice?.uuid as string,
                  type,
               })
               handleError(response)
            })
            break
      }
   }

   const handleError = (response: any) => {
      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })

            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors([
               {
                  propertyPath: "generic",
                  message: (response.error.data as any).detail as string,
               },
            ])
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }
         setErrors([genericError as ViolationType])
         return
      }

      if ("data" in response) {
         const { uuid } = response.data

         switch (mode) {
            case "new":
               setMessage(
                  `${t(
                     "gov_it.invoice_drafts.invoice_created"
                  )} - Document UUID ${uuid}`
               )
               break
            case "edit":
               setMessage(`${t("gov_it.invoice_drafts.invoice_draft_update")}`)
               break
            case "send":
               setMessage(`${t("gov_it.invoice_drafts.invoice_draft_send")}`)
               break
         }

         enqueueSnackbar(
            mode === "new"
               ? t("gov_it.invoice_drafts.invoice_created")
               : t("gov_it.invoice_drafts.invoice_draft_update"),
            {
               variant: "success",
            }
         )

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )

         if (mode == "send") {
            dispatch(closeDialog())
            dispatch(
               openDialog({
                  id: "alert.modal",
                  data: {
                     title: t("gov_it.invoice_drafts.invoice_draft_send"),
                     message: `${t(
                        "gov_it.invoice_drafts.invoice_draft_send"
                     )} - Document UUID ${uuid}`,
                     type: "success",
                     children: (
                        <Button
                           color={"primary"}
                           variant="contained"
                           size="medium"
                           onClick={() => {
                              persistTableRefresh("customer.invoices")
                              dispatch(
                                 updateRefetch({
                                    table: "customer.invoices",
                                 })
                              )
                              navigate(`/customer-invoice`)
                              dispatch(
                                 openDialog({
                                    id: "gov_it_invoices_details",
                                    data: {
                                       invoiceUuid: uuid,
                                       mode: "info",
                                    },
                                 })
                              )
                           }}
                        >
                           {t("Invoices.go_to_invoice")}
                        </Button>
                     ),
                  },
               })
            )
         }
      }
   }

   const createOrUpdateDraftInvoice = async (
      value: string,
      format: "json" | "xml",
      type: "simple" | "simplified",
      blockInvoiceSent: boolean = false,
      modeInternal?: "new" | "edit"
   ) => {
      if (modeInternal == null && mode == "send") return
      if (modeInternal == null && mode != "send") modeInternal = mode

      setMessage(null)
      setErrors([])

      value = cleanedJsonObject(value)
      let response
      switch (modeInternal) {
         case "new":
            response = await createDraftInvoice({
               //@ts-ignore
               body: value,
               format,
               type,
               blockInvoiceSent,
            })
            break
         case "edit":
            response = await updateDraftInvoice({
               //@ts-ignore
               body: value,
               format,
               uuid: invoice?.uuid as string,
               type,
               blockInvoiceSent,
            })
            break
      }

      handleError(response)
   }

   const handleValidate = async (value: string) => {
      // @ts-ignore
      const response = await validateInvoice(value)

      if ("error" in response && "data" in response.error) {
         setErrors((response.error.data as any).violations)
         setIsValidForm(false)
         enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
            variant: "error",
         })
         return
      }

      if ("data" in response) {
         setIsValidForm(true)
         enqueueSnackbar(t("gov_it.invoice_fix.invoice_valid"), {
            variant: "success",
         })
         return
      }
   }

   const source =
      invoice?.parsedPayload || invoice?.parsedPayloadSimplified
         ? invoice?.parsedPayloadSimplified
            ? JSON.stringify(invoice?.parsedPayloadSimplified, null, "\t")
            : JSON.stringify(invoice?.parsedPayload, null, "\t")
         : invoice?.payload

   const invoiceFormat = source
      ? "json"
      : invoice?.parsedPayload || invoice?.parsedPayloadSimplified
      ? "json"
      : "xml"
   const invoiceType =
      invoice?.transmission_format == "FSM10" ? "simplified" : "simple"

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
         style={{
            padding: 10,
         }}
      >
         {errors && errors.length > 0 && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="error"
                  onClose={() => {
                     setErrors([])
                  }}
               >
                  <AlertTitle>
                     {t("CustomerInvoices.CreateInvoice.Validation Error")}
                  </AlertTitle>
                  {errors.map(function (error) {
                     return (
                        <p>
                           <strong>{error.propertyPath}</strong> {error.message}
                        </p>
                     )
                  })}
               </Alert>
            </Box>
         )}

         {message && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="success"
                  style={{
                     margin: 2,
                  }}
                  onClose={() => {
                     setMessage(null)
                  }}
               >
                  <AlertTitle>
                     {mode == "new"
                        ? t("gov_it.invoice_drafts.invoice_draft_create")
                        : mode == "edit"
                        ? t("gov_it.invoice_drafts.invoice_draft_update")
                        : t("gov_it.invoice_drafts.invoice_draft_send")}
                  </AlertTitle>
                  {message}
               </Alert>
            </Box>
         )}

         <CreateInvoiceDraftForm
            source={source}
            mode={mode}
            invoiceType={mode == "new" ? undefined : invoiceType}
            invoiceFormat={invoiceFormat}
            invoiceBlockSent={
               mode == "new" ? false : invoice?.marking === "draft-blocked"
            }
            isLoading={isLoading || isFetching}
            handleSave={handleSave}
            handleValidate={mode == "send" ? handleValidate : undefined}
            isValidForm={mode == "send" ? isValidForm : true}
            handleClose={handleClose}
            formatsEnabled={mode == "new" ? ["json", "xml"] : [invoiceFormat]}
         />
      </div>
   )
}

export default TabPanelNewInvoiceDraft
