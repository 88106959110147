import {
   Box,
   CircularProgress,
   DialogContent,
   DialogTitle,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useGetDocumentTypesQuery } from "services/peppol/documentTypes"
import {
   PeppolLegalEntity,
   UpdateLegalEntityBody,
   useGetSMPRecordsQuery,
   useUpdateLegalEntityMutation,
   useUpdateSMPMutation,
} from "services/peppol/legalEntities"
import { useGetProcessesQuery } from "services/peppol/processes"
import { DialogId, DialogState } from "store/slices/dialogs"
import * as Yup from "yup"
import EditLegalEntityForm, { FormValues } from "../EditLegalEntityForm"

const toFormValues = (legalEntity: PeppolLegalEntity) =>
   Object.entries(legalEntity).reduce((acc, [key, value]) => {
      if (
         [
            "@id",
            "@type",
            "receivedDocumentNotificationEmails",
            "updatedAt",
         ].includes(key)
      ) {
         return acc
      }

      return { ...acc, [key]: value ?? "" }
   }, {} as FormValues)

const toLegalEntity = (formValues: FormValues) =>
   Object.entries(formValues).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value !== "" ? value : null }),
      {} as UpdateLegalEntityBody
   )

const DIALOG_ID: DialogId = "peppol_edit_legal_entity"

const validationSchema = Yup.object({
   registeredName: Yup.string().required(),
   identifierScheme: Yup.string().required(),
   identifierValue: Yup.string().required(),
   capabilities: Yup.array().of(
      Yup.object({
         documentType: Yup.string().required(),
         process: Yup.string().required(),
      }).required()
   ),
}).required()

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, ...legalEntity }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updateLegalEntity] = useUpdateLegalEntityMutation()
   const [updateSMP] = useUpdateSMPMutation()
   const { data: smpRecords, isError: isSMPError } = useGetSMPRecordsQuery({
      uuid: legalEntity.uuid,
   })
   const { data: documentTypes, isError: isDocumentTypeError } =
      useGetDocumentTypesQuery({})
   const { data: processes, isError: isProcessesError } = useGetProcessesQuery(
      {}
   )

   const isError = isSMPError || isDocumentTypeError || isProcessesError

   if (isError) {
      return (
         <Box p={3}>
            <Alert severity="error">
               <AlertTitle>{t("global.messages.generic_error")}</AlertTitle>
            </Alert>
         </Box>
      )
   }

   if (smpRecords == null || documentTypes == null || processes == null) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <CircularProgress size={40} color="inherit" />
         </Box>
      )
   }

   const initialValues: FormValues = {
      ...toFormValues(legalEntity),
      capabilities: smpRecords.capabilities.map(
         ({ documentType, process }) => ({
            documentType,
            process,
         })
      ),
   }

   const handleSubmit = async ({
      smpEnabled,
      capabilities,
      ...values
   }: FormValues) => {
      if (documentTypes == null || processes == null) {
         return
      }

      const legalEntityResponse = await updateLegalEntity({
         uuid: legalEntity.uuid,
         ...toLegalEntity(values),
      })

      if ("error" in legalEntityResponse) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      const smpCapabilities = capabilities!.map(
         ({ documentType, process }) => ({
            documentType,
            documentTypeScheme: documentTypes["hydra:member"].find(
               ({ value }) => value === documentType
            )!.scheme,
            process,
            processScheme: processes["hydra:member"].find(
               ({ value }) => value === process
            )!.scheme,
         })
      )

      const smpResponse = await updateSMP({
         uuid: legalEntity.uuid,
         enabled: smpEnabled!,
         capabilities: smpCapabilities,
      })

      if ("error" in smpResponse) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(
         t("peppol.legal_entities.messages.legal_entity_updated"),
         { variant: "success" }
      )
      handleClose()
   }

   return (
      <>
         <DialogTitle>
            {t("peppol.legal_entities.edit_legal_entity_dialog_title", {
               uuid: legalEntity.uuid,
            })}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditLegalEntityForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                     type="update"
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const EditLegalEntityDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default EditLegalEntityDialog
