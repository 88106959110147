import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store"
import { changeActiveContext, selectActiveContext } from "store/app"
import { selectContexts, selectIsUserStripe } from "store/auth"
import Page from "views/common/components/Page"
import {
   contextExistInNavBarContextItems,
   getOneContextExistInNavBarContextItems,
} from "views/layout/NavBarContextItems"

const NoServices = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const contexts = useAppSelector(selectContexts)
   const activeContext = useAppSelector(selectActiveContext)
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const contextExistsInNavBar =
      getOneContextExistInNavBarContextItems(contexts)
   const activeContextExistsInNavBar = activeContext
      ? contextExistInNavBarContextItems(activeContext)
      : true

   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))

   useEffect(() => {
      if (isStripeUser) {
         dispatch(changeActiveContext("it.api.acubeapi.com"))
         navigate("/customer-invoice")
         return
      }
      if (contextExistsInNavBar && activeContextExistsInNavBar) {
         dispatch(changeActiveContext(null))
         navigate("/dashboard")
         return
      }
   }, [dispatch, navigate, contextExistsInNavBar, activeContextExistsInNavBar])

   return (
      <Page className={classes.root} title={t("Preservation.documents.title")}>
         <Container
            maxWidth={false}
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: "100vh",
            }}
         >
            <h1
               style={{
                  fontSize: "3rem",
                  fontWeight: 700,
                  color: "#555",
               }}
            >
               {t("global.no_services")}
            </h1>
         </Container>
      </Page>
   )
}

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

export default NoServices
