import { Box, Button, CircularProgress } from "@material-ui/core"
import { blobToBase64 } from "helper/encodings"
import { useSnackbar } from "notistack"
import { ComponentProps, useRef } from "react"
import { useTranslation } from "react-i18next"
import {
   useLazyGetInvoiceUpoQuery,
   useLazyGetProtectedInvoiceUpoQuery,
} from "services/gov-pl/invoices"

type Props = {
   invoiceUuid: string
   protectedInvoiceCode?: string
   buttonSize: ComponentProps<typeof Button>["size"]
}

const DownloadUpo = ({
   invoiceUuid,
   protectedInvoiceCode,
   buttonSize,
}: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const ref = useRef<HTMLAnchorElement | null>(null)

   const [getInvoiceUpo, { isFetching: isFetchingInvoice }] =
      useLazyGetInvoiceUpoQuery()
   const [getProtectedInvoiceUpo, { isFetching: isFetchingProtectedInvoice }] =
      useLazyGetProtectedInvoiceUpoQuery()

   const isFetching = isFetchingInvoice || isFetchingProtectedInvoice

   const handleDownload = async () => {
      const commonParams = {
         uuid: invoiceUuid!,
      }
      const response =
         protectedInvoiceCode != null
            ? await getProtectedInvoiceUpo({
                 ...commonParams,
                 code: protectedInvoiceCode,
              })
            : await getInvoiceUpo(commonParams)

      if ("error" in response || ref.current == null) {
         enqueueSnackbar(t("pl.invoice.error.generic"), { variant: "error" })
         return
      }

      const data = await blobToBase64(new Blob([response.data!]))
      ref.current.href = `data:application/octet-stream;charset=utf-16le;base64,${data}`
      ref.current.click()
   }

   return (
      <>
         <a
            ref={ref}
            href="#"
            download="invoice.xml"
            style={{ display: "none" }}
         >
            {t("pl.public_invoice.download_upo")}
         </a>

         <Button
            color="primary"
            disabled={isFetching}
            size={buttonSize}
            type="button"
            variant="contained"
            onClick={handleDownload}
         >
            {isFetching ? (
               <>
                  <Box display="flex" mr={1}>
                     <CircularProgress size={24} color="inherit" />
                  </Box>
                  {t("pl.public_invoice.download_upo_loading")}
               </>
            ) : (
               t("pl.public_invoice.download_upo")
            )}
         </Button>
      </>
   )
}

export default DownloadUpo
