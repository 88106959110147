import { HydraResponse } from "services/types"
import { peppolApi, PeppolPaginationParams } from ".."

export type PeppolProcess = {
   scheme: string
   value: string
   deprecated: boolean
}

type GetProcessesResponse = HydraResponse<PeppolProcess>
type GetProcessesParams = PeppolPaginationParams

const peppolProcessesApi = peppolApi.injectEndpoints({
   endpoints: (builder) => ({
      getProcesses: builder.query<GetProcessesResponse, GetProcessesParams>({
         query: () => ({
            url: "/processes",
            headers: {
               accept: "application/ld+json",
            },
         }),
         providesTags: ["PEPPOL_PROCESSES"],
      }),
   }),
})

export const { useGetProcessesQuery } = peppolProcessesApi
