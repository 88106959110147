import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { USERS_ME } from "../../../../endpoints"

export async function setProfile(token, values) {
   let headers = new Headers()
   headers.append("Content-Type", "application/json")
   headers.append("Accept", "application/json")
   headers.append("Authorization", "Bearer " + token)
   Object.entries(getImpersonatedUserHeaders()).forEach(([name, value]) => {
      headers.append(name, value)
   })

   const requestInit = {
      headers,
      method: "PUT",
      body: JSON.stringify(values),
   }

   const response = await fetch(
      process.env.REACT_APP_COMMON_BASE_URL + USERS_ME,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
