import { Box, CircularProgress, Typography } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { useGetMeQuery } from "services/commonApi"
import ACubeLogo from "views/common/components/Logo"

const Logo = () => {
   const { data, isFetching } = useGetMeQuery()
   const logoUrl = data?.logo_url

   if (isFetching && logoUrl == null) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <CircularProgress size={24} color="inherit" />
         </Box>
      )
   }

   if (logoUrl == null) {
      return (
         <RouterLink to="/">
            <ACubeLogo />
         </RouterLink>
      )
   }

   return (
      <Box display="flex" alignItems="center">
         <img alt="Logo" src={logoUrl} height={27} />

         <Box color="grey.900" display="flex" flexDirection="column" ml={3}>
            <Typography variant="body2">Powered by</Typography>
            <RouterLink to="/">
               <ACubeLogo height={16} />
            </RouterLink>
         </Box>
      </Box>
   )
}

export default Logo
