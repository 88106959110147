import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import CodeIcon from "@material-ui/icons/Code"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   GovITInvoiceTransfer,
   useLazyDownloadInvoiceTransferFileQuery,
} from "services/gov-it"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"
import { download } from "../../../../../helper/download"

type Props = {
   invoiceTransfer: GovITInvoiceTransfer
}

const RowCTAs = ({ invoiceTransfer }: Props) => {
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation()
   const [getInvoiceTransferFile] = useLazyDownloadInvoiceTransferFileQuery()

   const handleDownloadXML = async (id: string) => {
      const response = await getInvoiceTransferFile({
         uuid: id,
      })
      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }
      if (response.data) {
         download(response.data, `${id}.xml`)
      }
   }

   return (
      <ButtonGroup>
         <Tooltip title={t("gov_it.invoice_transfers.ctas.details")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "gov_it_invoice_transfers",
                        data: invoiceTransfer,
                     })
                  )
               }}
            >
               <Description />
            </IconButton>
         </Tooltip>
         <Tooltip title="Invoice XML">
            <IconButton
               onClick={() => {
                  handleDownloadXML(invoiceTransfer.uuid)
               }}
            >
               <CodeIcon />
            </IconButton>
         </Tooltip>
      </ButtonGroup>
   )
}

export default RowCTAs
