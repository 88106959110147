import {
   Box,
   Card,
   CardContent,
   colors,
   makeStyles,
   Typography,
   useTheme,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { Pie } from "react-chartjs-2"
//import { formatPrice } from "services/prices"

import { formatPrice } from "helper/numberFormater"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
   text_percentage: {
      fontSize: "14px",
   },
}))

const COLOR_SENT = colors.deepOrange[400]
const COLOR_RECEIVED = colors.teal[400]

const InvoicesCost = ({
   className,
   invoicesSentCost,
   invoicesReceivedCost,
}) => {
   const classes = useStyles()
   const theme = useTheme()
   const { t, i18n } = useTranslation()

   const totalInvoicesCost = invoicesSentCost + invoicesReceivedCost

   const data = {
      datasets: [
         {
            data: [invoicesSentCost, invoicesReceivedCost],
            backgroundColor: [COLOR_SENT, COLOR_RECEIVED],
            borderWidth: 1,
            borderColor: theme.palette.text.white,
            hoverBorderColor: theme.palette.text.white,
         },
      ],
      labels: [
         t("statistics.text.sent_invoices_cost"),
         t("statistics.text.received_invoices_cost"),
      ],
   }

   const options = {
      animation: false,
      cutoutPercentage: 80,
      layout: { padding: 0 },
      legend: {
         display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
         backgroundColor: theme.palette.background.default,
         bodyFontColor: theme.palette.text.secondary,
         borderColor: theme.palette.divider,
         borderWidth: 2,
         enabled: true,
         footerFontColor: theme.palette.text.secondary,
         intersect: false,
         mode: "index",
         titleFontColor: theme.palette.text.primary,
      },
   }

   const details = [
      {
         title: t("statistics.text.sent_invoices_cost"),
         value: invoicesSentCost,
         color: COLOR_SENT,
      },
      {
         title: t("statistics.text.received_invoices_cost"),
         value: invoicesReceivedCost,
         color: COLOR_RECEIVED,
      },
      {
         title: t("statistics.text.total_cost"),
         value: invoicesReceivedCost + invoicesSentCost,
         color: colors.primary,
         hidePercentage: true,
      },
   ]

   return (
      <Card className={className}>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("statistics.title.invoices_cost")}
            </Typography>
            <Box mt={2} height={300} position="relative">
               <Pie data={data} options={options} />
            </Box>
            <Box
               display="flex"
               justifyContent="center"
               mt={2}
               flexDirection={{ xs: "column", sm: "row" }}
            >
               {details.map(({ color, title, value, hidePercentage }) => (
                  <Box key={title} p={1} textAlign="center">
                     <Typography color="textPrimary" variant="body1">
                        {title}
                     </Typography>
                     <Typography style={{ color }} variant="h2">
                        {formatPrice(i18n.language, value)}
                     </Typography>
                     {!hidePercentage && (
                        <Typography
                           style={{ color }}
                           paragraph
                           className={classes.text_percentage}
                        >
                           {((value / totalInvoicesCost) * 100).toFixed(2)}%
                        </Typography>
                     )}
                  </Box>
               ))}
            </Box>
         </CardContent>
      </Card>
   )
}

InvoicesCost.propTypes = {
   className: PropTypes.string,
   invoicesSentCost: PropTypes.number,
   invoicesReceivedCost: PropTypes.number,
}

InvoicesCost.defaultProps = {
   invoicesSentCost: 0,
   invoicesReceivedCost: 0,
}

export default InvoicesCost
