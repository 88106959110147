import { Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import TableFilters from "./Filters"
import InvoiceDetailsDialog from "./InvoiceDetailsDialog"
import InvoicesTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

type Props = {
   direction: "incoming" | "outgoing"
}

const PlInvoices = ({ direction }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <Page className={classes.root} title={t("Menu.invoices")}>
            <Container maxWidth={false}>
               <TableFilters direction={direction} />

               <InvoicesTable direction={direction} />
            </Container>
         </Page>

         <InvoiceDetailsDialog />
      </>
   )
}

export default PlInvoices
