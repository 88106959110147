import { base64ToUTF8 } from "helper/encodings"
import { useEffect, useRef } from "react"
import "./external.css"

type Props = {
   html: string
}

const InvoiceContent = ({ html }: Props) => {
   const contentRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      if (contentRef.current == null) {
         return
      }

      const content = contentRef.current
      content.innerHTML = ""

      const template = document.createElement("template")
      template.innerHTML = base64ToUTF8(html)

      const invoiceContent = template.content.querySelector(".deklaracja")!
      content.appendChild(invoiceContent)
   }, [html, contentRef])

   return <div id="invoice-landpage" ref={contentRef} />
}

export default InvoiceContent
