import { Box, Container, makeStyles, Paper } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import {
   useGetInvoiceQuery,
   useGetIsFixableInvoiceQuery,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppSelector } from "store"
import { selectHasContext, selectHasRole } from "store/auth"
import { TableName } from "store/tablesState"
import Page from "views/common/components/Page"
import CommonInvoiceForm from "views/common/InvoiceForm/CommonInvoiceForm"
import ErrorCodeGuard from "./ErrorCodeGuard"
import GovITInvoiceFixErrorMessage from "./ErrorMessage"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const table: TableName = "customer.invoices"
const GovITInvoiceFix = () => {
   const navigate = useNavigate()
   const classes = useStyles()
   const { t } = useTranslation()
   const { invoiceUuid } = useParams()
   const hasRoleToFixInvoice = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_FIX_INVOICE",
      })
   )
   const hasContextToFixInvoice = useAppSelector((state) =>
      selectHasContext(state, "stripe.acubeapi.com")
   )
   const { data } = useGetInvoiceQuery({ uuid: invoiceUuid! })
   const { data: invoiceIsFixableData } = useGetIsFixableInvoiceQuery({
      uuid: invoiceUuid!,
   })

   const simplified = data?.transmission_format === "FSM10"

   const isAllowedToFixInvoice =
      (hasRoleToFixInvoice || hasContextToFixInvoice) &&
      invoiceIsFixableData?.is_fixable

   useEffect(() => {
      if (invoiceIsFixableData && !isAllowedToFixInvoice) {
         navigate("/customer-invoice")
      }
   }, [isAllowedToFixInvoice, navigate])

   return (
      <Page className={classes.root} title={t("gov_it.invoice_fix.title")}>
         <Container maxWidth={false}>
            <Paper>
               <Box p={3}>
                  <ErrorCodeGuard>
                     <GovITInvoiceFixErrorMessage />
                     {simplified ? (
                        <CommonInvoiceForm
                           project="invoice_fix"
                           formType={"simplified"}
                           table={table}
                           mode="fix"
                           invoice={data}
                           isFixInvoice={true}
                           invoiceToFixUuid={invoiceUuid!}
                        />
                     ) : (
                        <CommonInvoiceForm
                           project="invoice_fix"
                           formType={"simple"}
                           table={table}
                           mode="fix"
                           invoice={data as GovITInvoice | null}
                           isFixInvoice={true}
                           invoiceToFixUuid={invoiceUuid!}
                        />
                     )}
                  </ErrorCodeGuard>
               </Box>
            </Paper>
         </Container>
      </Page>
   )
}

export default GovITInvoiceFix
