import {
   Box,
   Card,
   CardContent,
   colors,
   makeStyles,
   Typography,
   useTheme,
} from "@material-ui/core"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles(() => ({
   text_percentage: {
      fontSize: "14px",
   },
}))

const COLOR_SENT = colors.red[400]
const COLOR_RECEIVED = colors.cyan[400]
// const COLOR_EXTRA_SDI = colors.blue[400]

const InvoicesMonthCount = ({ className, monthStats }) => {
   const classes = useStyles()
   const theme = useTheme()
   const { t } = useTranslation()

   const data = {
      datasets: [
         {
            label: t("statistics.text.sent_invoices"),
            data: monthStats.map((month) => month.customer_invoices.count),
            backgroundColor: COLOR_SENT,
         },
         {
            label: t("statistics.text.received_invoices"),
            data: monthStats.map((month) => month.supplier_invoice.count),
            backgroundColor: COLOR_RECEIVED,
         },
         /*{
            label: t("statistics.text.supplier_extra_sdi_invoices"),
            data: monthStats.map((month) => month.supplier_extra_sdi_invoices.count),
            backgroundColor: COLOR_EXTRA_SDI,
         }*/
      ],
      labels: [
         "January",
         "February",
         "March",
         "April",
         "May",
         "June",
         "July",
         "August",
         "September",
         "October",
         "November",
         "December",
      ],
   }

   const options = {
      animation: false,
      cutoutPercentage: 80,
      layout: { padding: 0 },
      legend: {
         display: true,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
         backgroundColor: theme.palette.background.default,
         bodyFontColor: theme.palette.text.secondary,
         borderColor: theme.palette.divider,
         borderWidth: 2,
         enabled: true,
         footerFontColor: theme.palette.text.secondary,
         intersect: false,
         mode: "index",
         titleFontColor: theme.palette.text.primary,
      },
   }

   return (
      <Card className={clsx(classes.root, className)}>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("statistics.title.total_invoices_month")}
            </Typography>
            <Box
               display="flex"
               justifyContent="space-around"
               mt={2}
               flexDirection={"column"}
            >
               <Box mt={2} height={300} position="relative">
                  <Bar options={options} data={data} />
               </Box>
            </Box>
         </CardContent>
      </Card>
   )
}

InvoicesMonthCount.propTypes = {
   className: PropTypes.string,
   monthStats: PropTypes.array,
}

InvoicesMonthCount.defaultProps = {
   monthStats: [],
}

export default InvoicesMonthCount
