import { Button, ListItem, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { setItem } from "helper/storage"
import { NavLink as RouterLink } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store"
import { changeCurrentNavItem, selectCurrentNavItem } from "store/app"

const useStyles = makeStyles((theme) => ({
   item: {
      color: theme.palette.background.default,
      display: "flex",
      paddingTop: 0,
      paddingBottom: 0,
   },
   button: {
      color: theme.palette.background.default,
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: "flex-start",
      letterSpacing: 0,
      padding: "10px 8px",
      textTransform: "none",
      width: "100%",
   },
   icon: {
      color: theme.palette.background.default,
      marginRight: theme.spacing(1),
   },
   title: {
      marginRight: "auto",
   },
   active: {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(0, 0, 0)",
      "& $title": {
         fontWeight: theme.typography.fontWeightBold,
      },
      "& $icon": {
         color: theme.palette.primary.main,
      },
   },
}))

const NavItem = ({ className, href, external, icon: Icon, title, ...rest }) => {
   const classes = useStyles()
   const currentNavItem = useAppSelector((state) => selectCurrentNavItem(state))
   const isActive = currentNavItem === href
   const dispatch = useAppDispatch()

   const handleChanges = () => {
      setItem("current_nav_item", href)
      dispatch(changeCurrentNavItem(href))
   }

   return (
      <ListItem
         className={clsx(classes.item, className, isActive && classes.active)}
         disableGutters
         {...rest}
      >
         <Button
            size="small"
            className={classes.button}
            component={external ? "button" : RouterLink}
            to={external ? "" : href}
            onClick={() => (external ? window.open(href) : handleChanges())}
         >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
         </Button>
      </ListItem>
   )
}

export default NavItem
