import { createBrowserRouter, Navigate } from "react-router-dom"
import ApiConfigurations from "views/ApiConfiguration"
import CustomErrorBoundary from "views/common/CustomErrorBoundary"
import DownloadInvoice from "views/gov-it/DownloadInvoice"
import GovITInvoiceDrafts from "views/gov-it/InvoiceDrafts"
import GovITInvoiceFix from "views/gov-it/InvoiceFix"
import GovITInvoiceTransfers from "views/gov-it/InvoiceTransfers"
import Login from "views/Login"
import NoServices from "views/NoServices"
import BusinessRegistry from "views/openbanking/BusinessRegistry"
import OpenbankingPayments from "views/openbanking/Payments"
import OpenbankingTransactions from "views/openbanking/Transactions"
import OpenbankingWebhooks from "views/openbanking/Webhooks"
import PeppolLegalEntities from "views/Peppol/LegalEntities"
import InvoicesDemo from "views/pl/DemoInvoices"
import GovPLInvoice from "views/pl/Invoice"
import PlInvoices from "views/pl/Invoices"
import LegalEntities from "views/pl/LegalEntities"
import Onboard from "views/pl/Onboard"
import GovPLPublicInvoice from "views/pl/PublicInvoice"
import GovPLWebhooks from "views/pl/Webhooks"
import PreservationDocuments from "views/Preservation/Documents"
import RequireAuth from "views/RequireAuth"
import ResetPassword from "views/ResetPassword"
import PreSaleActions from "views/settings/PreSaleActions"
import SubAccountsPage from "views/settings/SubAccounts"
import SideEffectsManager from "views/SideEffectsManager"
import App from "./App"
import {
   TYPE_RETURN,
   TYPE_SALE,
   TYPE_VOID,
} from "./services/gov-it/smartReceipt/type"
import BusinessRegistryConfigurations from "./views/BusinessRegistryConfigurations"
import Dashboard from "./views/Dashboard"
import Expenses from "./views/Expenses"
import CustomerSupplierInvoice from "./views/gov-it/CustomerSupplierInvoice"
import SmartReceipt from "./views/gov-it/SmartReceipt"
import DashboardLayout from "./views/layout/DashboardLayout"
import CreditNotes from "./views/Peppol/CreditNotes"
import DespatchAdvices from "./views/Peppol/DespatchAdvices"
import Invoices from "./views/Peppol/Invoices"
import OrderResponses from "./views/Peppol/OrderResponses"
import Orders from "./views/Peppol/Orders"
import Webhooks from "./views/Peppol/Webhooks"
import Profile from "./views/Profile"

const routes = [
   {
      path: "/",
      element: <App />,
      children: [
         {
            element: <SideEffectsManager />,
            children: [
               // Public routes
               {
                  path: "/login",
                  element: <Login />,
               },
               {
                  path: "/reset-password",
                  element: <ResetPassword />,
               },
               {
                  path: "/onboarding/:legalEntityUuid/:hash",
                  element: <Onboard />,
               },
               {
                  path: "/gov-pl/public-invoices/:invoiceUuid",
                  element: <GovPLPublicInvoice />,
               },

               // Private routes
               {
                  element: <RequireAuth />,
                  children: [
                     {
                        path: "/pl/invoices/demo",
                        element: <InvoicesDemo />,
                     },
                     {
                        path: "/gov-pl/invoices/:invoiceUuid",
                        element: <GovPLInvoice />,
                     },
                     {
                        path: "/",
                        element: <DashboardLayout />,
                        children: [
                           {
                              path: "*",
                              element: <CustomErrorBoundary />,
                           },
                           {
                              path: "/",
                              exact: true,
                              element: <Navigate to="/dashboard" />,
                           },
                           {
                              path: "/no-services",
                              element: <NoServices />,
                           },
                           {
                              path: "dashboard",
                              location: "/dashboard",
                              element: <Dashboard />,
                           },
                           {
                              path: "profile",
                              element: <Profile />,
                           },
                           // settings routes
                           {
                              path: "settings",
                              children: [
                                 {
                                    path: "pre-sale-actions",
                                    element: <PreSaleActions />,
                                 },
                                 {
                                    path: "sub-accounts",
                                    element: <SubAccountsPage />,
                                 },
                              ],
                           },
                           {
                              path: "api-configurations",
                              element: <ApiConfigurations />,
                           },
                           {
                              path: "/invoice-transfers",
                              element: <GovITInvoiceTransfers />,
                           },
                           {
                              path: "/invoice-drafts",
                              element: <GovITInvoiceDrafts />,
                           },
                           {
                              path: "business-registry-configurations",
                              element: <BusinessRegistryConfigurations />,
                           },
                           {
                              path: "customer-invoice",
                              element: (
                                 <CustomerSupplierInvoice
                                    type={"customer-invoice"}
                                 />
                              ),
                           },
                           {
                              path: "smart-receipts-return",
                              element: <SmartReceipt type={TYPE_RETURN} />,
                           },
                           {
                              path: "smart-receipts-void",
                              element: <SmartReceipt type={TYPE_VOID} />,
                           },
                           {
                              path: "smart-receipts-sale",
                              element: <SmartReceipt type={TYPE_SALE} />,
                           },
                           {
                              path: "customer-invoice/:uuid/download-pdf",
                              element: <DownloadInvoice fileType="pdf" />,
                           },
                           {
                              path: "customer-invoice/:uuid/download-xml",
                              element: <DownloadInvoice fileType="xml" />,
                           },
                           {
                              path: "customer-invoice/:invoiceUuid/fix",
                              element: <GovITInvoiceFix />,
                           },
                           {
                              path: "supplier-invoice",
                              element: (
                                 <CustomerSupplierInvoice
                                    type={"supplier-invoice"}
                                 />
                              ),
                           },
                           {
                              path: "incoming-orders",
                              element: (
                                 <Orders direction="incoming" context="/it" />
                              ),
                           },
                           {
                              path: "outgoing-orders",
                              element: (
                                 <Orders direction="outgoing" context="/it" />
                              ),
                           },
                           {
                              path: "incoming-order-responses",
                              element: (
                                 <OrderResponses
                                    direction="incoming"
                                    context="/it"
                                 />
                              ),
                           },
                           {
                              path: "outgoing-order-responses",
                              element: (
                                 <OrderResponses
                                    direction="outgoing"
                                    context="/it"
                                 />
                              ),
                           },
                           {
                              path: "preservation-documents",
                              element: <PreservationDocuments />,
                           },
                           {
                              path: "expenses",
                              element: <Expenses />,
                           },

                           // GOV PL
                           {
                              path: "/gov-pl/webhooks",
                              element: <GovPLWebhooks />,
                           },
                           {
                              path: "/gov-pl/legal-entities",
                              element: <LegalEntities />,
                           },
                           {
                              path: "/gov-pl/incoming-invoices",
                              element: <PlInvoices direction="incoming" />,
                           },
                           {
                              path: "/gov-pl/outgoing-invoices",
                              element: <PlInvoices direction="outgoing" />,
                           },

                           // Open Banking
                           {
                              path: "/openbanking/webhooks",
                              element: <OpenbankingWebhooks />,
                           },
                           {
                              path: "/openbanking/business-registry",
                              element: <BusinessRegistry />,
                           },
                           {
                              path: "/openbanking/transactions",
                              element: <OpenbankingTransactions />,
                           },
                           {
                              path: "/openbanking/payments",
                              element: <OpenbankingPayments />,
                           },

                           // PEPPOL
                           {
                              path: "peppol",
                              children: [
                                 {
                                    path: "legal-entities",
                                    element: <PeppolLegalEntities />,
                                 },
                                 {
                                    path: "incoming-invoices",
                                    element: <Invoices direction="incoming" />,
                                 },
                                 {
                                    path: "outgoing-invoices",
                                    element: <Invoices direction="outgoing" />,
                                 },
                                 {
                                    path: "incoming-credit-notes",
                                    element: (
                                       <CreditNotes direction="incoming" />
                                    ),
                                 },
                                 {
                                    path: "outgoing-credit-notes",
                                    element: (
                                       <CreditNotes direction="outgoing" />
                                    ),
                                 },
                                 {
                                    path: "incoming-orders",
                                    element: <Orders direction="incoming" />,
                                 },
                                 {
                                    path: "outgoing-orders",
                                    element: <Orders direction="outgoing" />,
                                 },
                                 {
                                    path: "incoming-order-responses",
                                    element: (
                                       <OrderResponses direction="incoming" />
                                    ),
                                 },
                                 {
                                    path: "outgoing-order-responses",
                                    element: (
                                       <OrderResponses direction="outgoing" />
                                    ),
                                 },
                                 {
                                    path: "incoming-despatch-advices",
                                    element: (
                                       <DespatchAdvices direction="incoming" />
                                    ),
                                 },
                                 {
                                    path: "outgoing-despatch-advices",
                                    element: (
                                       <DespatchAdvices direction="outgoing" />
                                    ),
                                 },
                                 {
                                    path: "webhooks",
                                    element: <Webhooks />,
                                 },
                              ],
                           },
                        ],
                     },
                  ],
               },
            ],
         },
      ],
   },
]
export const router = createBrowserRouter(routes)
