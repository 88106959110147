import {
   Box,
   FormControl,
   FormLabel,
   makeStyles,
   Typography,
} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { FormikErrors, FormikValues } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

type Props = {
   name: string
   label: string
   defaultValueNull?: boolean
   invoiceRef:
      | React.RefObject<HTMLInputElement>
      | ((element: any) => React.RefObject<HTMLInputElement>)
   accept?: string
   handleFileChosen: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: string,
      setFieldValue: (
         field: string,
         value: any,
         shouldValidate?: boolean | undefined
      ) => Promise<void | FormikErrors<FormikValues>>
   ) => void
   getFieldMeta: (field: string) => {
      value?: any
      error?: string
   }
   resetFileField: (fieldName: string) => void
   setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
   ) => Promise<void | FormikErrors<FormikValues>>
}
const CustomInputFileField = ({
   name,
   label,
   defaultValueNull,
   accept,
   invoiceRef,
   handleFileChosen,
   getFieldMeta,
   resetFileField,
   setFieldValue,
}: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()
   return (
      <FormControl
         component="fieldset"
         className={classes.formControl}
         style={{ width: "100%" }}
      >
         <FormLabel component="legend" className={classes.formLabel}>
            {label}
         </FormLabel>
         <TextField
            inputRef={invoiceRef}
            type="file"
            id={name}
            name={name}
            inputProps={{
               ...(accept && { accept }),
            }}
            onChange={(e) => handleFileChosen(e, name, setFieldValue)}
            helperText={getFieldMeta(name).error}
            error={getFieldMeta(name).error !== undefined}
            fullWidth
         />

         {getFieldMeta(name).value && (
            <Box
               display="flex"
               flexDirection="row"
               alignItems="center"
               justifyContent="flex-end"
               mt={1}
            >
               <Typography
                  variant="body2"
                  style={{
                     cursor: "pointer",
                     color: "red",
                     textDecoration: "underline",
                  }}
                  onClick={() => {
                     resetFileField(name)
                     setFieldValue(name, defaultValueNull ? null : "")
                  }}
               >
                  {t("global.Remove file")}
               </Typography>
            </Box>
         )}
      </FormControl>
   )
}

export default CustomInputFileField
