import { IconButton, Tooltip } from "@material-ui/core"
import Description from "@material-ui/icons/Description"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   fiscalId: string
}

const SubscriptionDetailsCTA = ({ fiscalId }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const openSubscriptionDetailsDialog = async () => {
      dispatch(
         openDialog({
            id: "openbanking_subscription_details",
            data: { fiscalId },
         })
      )
   }

   return (
      <Tooltip
         title={t("openbanking.business_registry.subscription_details_cta")}
      >
         <IconButton onClick={openSubscriptionDetailsDialog}>
            <Description />
         </IconButton>
      </Tooltip>
   )
}

export default SubscriptionDetailsCTA
