import { ButtonGroup, Link, Paper, Typography } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import { useEffect } from "react"
import {
   PeppolLegalEntity,
   useGetLegalEntitiesQuery,
} from "services/peppol/legalEntities"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateTotalItems,
} from "store/tablesState"
import LabelYesNo from "views/common/LabelYesNo"
import DeleteLegalEntityCTA from "./DeleteLegalEntityCTA"
import EditLegalEntityCTA from "./EditLegalEntityCTA"

const table: TableName = "peppol.legal_entities"

const columns: TableColumn<PeppolLegalEntity>[] = [
   {
      rowKey: "registeredName",
      configurable: true,
      labelTranslationId: "peppol.legal_entities.table_head.registered_name",
      format: (value) => value,
   },
   {
      rowKey: "identifierValue",
      configurable: true,
      labelTranslationId: "peppol.legal_entities.table_head.identifier_value",
      format: (value) =>
         typeof value === "string" ? (
            <Typography variant="body1">
               <Link
                  href={`https://directory.peppol.eu/public/locale-en_US/menuitem-search?q=${encodeURIComponent(
                     value
                  )}`}
                  target="_blank"
               >
                  {value}
               </Link>
            </Typography>
         ) : null,
   },
   {
      rowKey: "createdAt",
      configurable: true,
      labelTranslationId: "peppol.legal_entities.table_head.created_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },
   {
      rowKey: "updatedAt",
      configurable: true,
      labelTranslationId: "peppol.legal_entities.table_head.updated_at",
      format: (value) =>
         typeof value === "string" && value !== ""
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },
   {
      rowKey: "smpEnabled",
      configurable: true,
      labelTranslationId: "peppol.legal_entities.table_head.smp_enabled",
      format: (value) => <LabelYesNo value={value} />,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => (
         <ButtonGroup>
            <EditLegalEntityCTA legalEntity={row} />
            <DeleteLegalEntityCTA uuid={row.uuid} />
         </ButtonGroup>
      ),
   },
]

const PeppolLegalEntitiesTable = () => {
   const dispatch = useAppDispatch()

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const { data, isError, isFetching } = useGetLegalEntitiesQuery({
      itemsPerPage,
      page: currentPage,
      identifierValue: filters.identifierValue,
      registeredName: filters.registeredName,
      ...(filters.sortBy !== ""
         ? { [`sortBy[${filters.sortBy}]`]: filters.sortType }
         : {}),
   })

   const items = data?.["hydra:member"]
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columns}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            withPagination
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default PeppolLegalEntitiesTable
