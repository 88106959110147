import {
   Box,
   Button,
   Container,
   FormHelperText,
   Grid,
   makeStyles,
   TextField,
   Typography,
} from "@material-ui/core"
import { Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useNavigate, useSearchParams } from "react-router-dom"
import { useLoginMutation } from "services/commonApi/authCommonApi"
import { useAppDispatch } from "store"
import { login } from "store/auth"
import Logo from "views/common/components/Logo"
import Page from "views/common/components/Page"
import LanguagesMenu from "views/common/LanguagesMenu"
import * as Yup from "yup"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: "white",
      height: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const validationSchema = Yup.object().shape({
   email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
   password: Yup.string().max(255).required("Password is required"),
})

const initialValues = {
   email: "",
   password: "",
}

type FormValues = typeof initialValues

const Login = () => {
   const classes = useStyles()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { t } = useTranslation()
   const [error, setError] = useState(false)
   const [searchParams] = useSearchParams()
   const [loginRequest] = useLoginMutation()

   const redirectTo = searchParams.get("redirect_to")

   const redirectUrl =
      redirectTo != null && redirectTo !== ""
         ? `${decodeURIComponent(redirectTo)}`
         : "/"

   const handleSubmit = async (values: FormValues) => {
      setError(false)

      const response = await loginRequest(values)

      if ("error" in response) {
         setError(true)
         return
      }

      const { token } = response.data
      await dispatch(login(token)).unwrap()
      navigate(redirectUrl)
   }

   return (
      <Page className={classes.root} title="Login">
         <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
         >
            <Container
               maxWidth="xs"
               style={{ border: "1px solid #c0c0c0", padding: "20px" }}
            >
               <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                     <Logo />

                     <Box mb={3}>
                        <Typography
                           color="textSecondary"
                           gutterBottom
                           variant="body2"
                        >
                           {t("Login.Accedi inserendo le tue credenziali")}
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                     <LanguagesMenu />
                  </Grid>
               </Grid>

               {error && (
                  <Box mb={3}>
                     <Typography color="error" variant="body2">
                        {t("Login.Credenziali non valide")}
                     </Typography>
                  </Box>
               )}

               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                  }) => (
                     <form onSubmit={handleSubmit}>
                        <TextField
                           error={Boolean(touched.email && errors.email)}
                           fullWidth
                           helperText={touched.email && errors.email}
                           label={t("Login.fields.email")}
                           margin="normal"
                           name="email"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="email"
                           value={values.email}
                        />

                        <TextField
                           error={Boolean(touched.password && errors.password)}
                           fullWidth
                           helperText={touched.password && errors.password}
                           label={t("Login.fields.password")}
                           margin="normal"
                           name="password"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="password"
                           value={values.password}
                        />

                        <FormHelperText style={{ textAlign: "right" }}>
                           <NavLink
                              to="/reset-password"
                              style={{ color: "#54BACB" }}
                           >
                              {t("Login.reset_password")}
                           </NavLink>
                        </FormHelperText>

                        <Box my={2}>
                           <Button
                              color="primary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                           >
                              {isSubmitting
                                 ? t("Login.Connessione in corso")
                                 : t("Login.Accedi")}
                           </Button>
                        </Box>
                     </form>
                  )}
               </Formik>
            </Container>
         </Box>
      </Page>
   )
}

export default Login
