import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { peppolApi, PeppolPaginationParams } from ".."

export type PeppolLegalEntity = {
   uuid: string
   registeredName: string
   country?: string | null
   address?: string | null
   city?: string | null
   stateOrProvince?: string | null
   zipCode?: string | null
   identifierScheme: string
   identifierValue: string
   receivedDocumentNotificationEmails?: string[] | null
   smpEnabled: boolean
   createdAt: string
   updatedAt: string
}

type PeppolLegalEntitySMP = {
   enabled: boolean
   capabilities: {
      documentTypeScheme: string
      documentType: string
      processScheme: string
      process: string
   }[]
}

type GetLegalEntitiesParams = PeppolPaginationParams & {
   identifierValue?: string | string[]
   registeredName?: string | string[]
   "sortBy[createdAt]"?: "asc" | "desc"
   "sortBy[registeredName]"?: "asc" | "desc"
   "sortBy[identifierScheme]"?: "asc" | "desc"
   "sortBy[identifierValue]"?: "asc" | "desc"
}

type CreateLegalEntityResponse = PeppolLegalEntity
export type CreateLegalEntityBody = Omit<
   PeppolLegalEntity,
   | "uuid"
   | "receivedDocumentNotificationEmails"
   | "smpEnabled"
   | "createdAt"
   | "updatedAt"
> & {
   receivedDocumentNotificationEmails: string
}

type UpdateLegalEntityResponse = PeppolLegalEntity
export type UpdateLegalEntityBody = Omit<
   PeppolLegalEntity,
   | "uuid"
   | "receivedDocumentNotificationEmails"
   | "smpEnabled"
   | "createdAt"
   | "updatedAt"
> & {
   receivedDocumentNotificationEmails: string
}

type GetSMPRecordsResponse = PeppolLegalEntitySMP

type EnableSMPResponse = PeppolLegalEntitySMP
type EnableSMPBody = PeppolLegalEntitySMP

type GetLegalEntitiesResponse = HydraResponse<PeppolLegalEntity>

const peppolLegalEntitiesApi = peppolApi.injectEndpoints({
   endpoints: (builder) => ({
      getLegalEntities: builder.query<
         GetLegalEntitiesResponse,
         GetLegalEntitiesParams
      >({
         query: (params) => ({
            url: `/legal-entities${buildSearchParams(params)}`,
            headers: {
               accept: "application/ld+json",
            },
         }),
         providesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),

      createLegalEntity: builder.mutation<
         CreateLegalEntityResponse,
         CreateLegalEntityBody
      >({
         query: ({ ...body }) => ({
            url: "/legal-entities",
            method: "POST",
            body,
         }),
         invalidatesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),

      updateLegalEntity: builder.mutation<
         UpdateLegalEntityResponse,
         UpdateLegalEntityBody & { uuid: string }
      >({
         query: (params) => ({
            url: `/legal-entities/${params.uuid}`,
            method: "PUT",
            body: params,
         }),
         invalidatesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),

      deleteLegalEntity: builder.mutation<void, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/legal-entities/${uuid}`,
            method: "DELETE",
         }),
         invalidatesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),

      getSMPRecords: builder.query<GetSMPRecordsResponse, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/legal-entities/${uuid}/smp`,
            headers: {
               accept: "application/ld+json",
            },
         }),
         providesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),

      updateSMP: builder.mutation<
         EnableSMPResponse,
         EnableSMPBody & { uuid: string }
      >({
         query: (params) => ({
            url: `/legal-entities/${params.uuid}/smp`,
            method: "PUT",
            body: params,
         }),
         invalidatesTags: ["PEPPOL_LEGAL_ENTITIES"],
      }),
   }),
})

export const {
   useGetLegalEntitiesQuery,
   useCreateLegalEntityMutation,
   useUpdateLegalEntityMutation,
   useDeleteLegalEntityMutation,
   useGetSMPRecordsQuery,
   useLazyGetSMPRecordsQuery,
   useUpdateSMPMutation,
} = peppolLegalEntitiesApi
