import { govPlApi } from ".."

export type DemoInvoice = {
   attachments: {
      filename: string
      filesize: number
      uuid: string
   }[]
   error: null
   createdAt: string
   invoiceCreatorNip: string
   invoiceDate: string
   invoiceNumber: string
   invoiceRecipientNip: string
   invoiceType: string
   legalEntityUUID: string
   recipientName: string | null
   senderName: string
   status: string
   direction: "outgoing" | "incoming"
   uuid: string
}

type GetDemoInvoicesStatusResponse = DemoInvoice[]

type GetDemoInvoiceStatusResponse = {
   message: string | null
   status: "valid" | "confirmed"
   upo: string | null
}

type SendInvoiceResponse = DemoInvoice

type GetLambdaInfoResponse = {
   name: string
   type: string
   startup_info: string
   expected_run_time: string
}[]

const demoInvoicesApi = govPlApi.injectEndpoints({
   endpoints: (builder) => ({
      generateDemoInvoices: builder.mutation<
         void,
         { nip1: string; nip2: string; invoiceCount: number }
      >({
         query: (body) => ({
            method: "POST",
            url: "/invoices/v2/generate",
            body,
         }),
         invalidatesTags: ["DEMO_INVOCES"],
      }),

      getDemoInvoicesStatus: builder.query<
         GetDemoInvoicesStatusResponse,
         { nip1: string }
      >({
         query: ({ nip1 }) => ({
            url: `/invoices/demo-statuses?order_filter=DESC&nip1=${nip1}`,
         }),
         providesTags: ["DEMO_INVOCES"],
      }),

      getDemoInvoiceStatus: builder.query<
         GetDemoInvoiceStatusResponse,
         { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/status`,
         }),
      }),

      resetDemoInvoices: builder.mutation<void, void>({
         query: () => ({
            method: "DELETE",
            url: "/invoices/demo-statuses/restart",
         }),
         invalidatesTags: ["DEMO_INVOCES"],
      }),

      sendDemoInvoice: builder.mutation<SendInvoiceResponse, string>({
         query: (body) => ({
            method: "POST",
            url: "/invoices/v1/attachments",
            headers: {
               "Content-Type": "application/xml",
            },
            body,
         }),
      }),

      archiveSession: builder.mutation<unknown, { nip1: number }>({
         query: (body) => ({
            method: "POST",
            url: "/demo-statuses/session-archive-emulation",
            body,
         }),
      }),

      getLambdaInfo: builder.query<GetLambdaInfoResponse, void>({
         query: (body) => ({
            url: "/infrastructure/info",
         }),
      }),
   }),
})

export const {
   useGenerateDemoInvoicesMutation,
   useGetDemoInvoicesStatusQuery,
   useGetDemoInvoiceStatusQuery,
   useResetDemoInvoicesMutation,
   useSendDemoInvoiceMutation,
   useArchiveSessionMutation,
   useGetLambdaInfoQuery,
} = demoInvoicesApi
