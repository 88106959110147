import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { format, sub } from "date-fns"
import {
   PreservationDocumentStatus,
   PreservationDocumentType,
} from "services/preservation"

import { ApiConfigurationTableConfigurableColumns } from "views/ApiConfiguration/Table"
import { BusinessRegistryConfigurationsTableConfigurableColumns } from "views/BusinessRegistryConfigurations/Table"
import { CustomerSupplierInvoiceTableConfigurableColumns } from "views/gov-it/CustomerSupplierInvoice/Table"
import { DraftCheckedStateType } from "views/gov-it/InvoiceDrafts/SenderOrDeleterInvoicesDialog"
import { InvoiceDraftTableConfigurableColumns } from "views/gov-it/InvoiceDrafts/Table"
import { GovItInvoiceTransfersTableConfigurableColumns } from "views/gov-it/InvoiceTransfers/Table"
import { GovPLLegalEntitiesTableConfigurableColumns } from "views/pl/LegalEntities/Table"
import { PreservationDocumentsTableConfigurableColumns } from "views/Preservation/Documents/Table"
import type { RootState } from ".."
import { SmartReceiptTableConfigurableColumns } from "../../views/gov-it/SmartReceipt/components/Table"
import { listenerMiddleware } from "../listener"
import {
   persistTableColumns,
   persistTableFilters,
   readLastCheckedColumns,
   readTableColumns,
   readTableFilters,
   readTableRefresh,
} from "./utils"

const DEFAULT_ITEMS_PER_PAGE = 15

type BaseData = {
   /** currently selected columns */
   columns: string[]
   checkedColumns: string[]
   lastCheckedColumns?: any
   [key: string]: any
}

type TableState<Data extends BaseData, Filters, FiltersData> = {
   itemsPerPage: number
   totalItems: number
   /** current page, starting from 1 */
   currentPage: number
   /** refetch variable for specific table type */
   refetch?: number
   data: Data
   filters: Filters
   filtersData: FiltersData
}

export type TablesState = {
   [key in "pl.incoming_invoices" | "pl.outgoing_invoices"]: TableState<
      { columns: []; checkedColumns: string[] },
      {
         status: string
         createdAfter: string | null
         createdBefore: string | null
         invoiceDateAfter: string | null
         invoiceDateBefore: string | null
         invoiceCreatorNip: string
         invoiceRecipientNip: string
         legalEntityUuid: string
         invoiceType: string
      },
      {}
   >
} & {
   "pl.webhooks": TableState<{ columns: []; checkedColumns: string[] }, {}, {}>
   "pl.legal_entities": TableState<
      {
         columns: GovPLLegalEntitiesTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {},
      {}
   >
   "peppol.legal_entities": TableState<
      { columns: []; checkedColumns: string[] },
      {
         identifierValue: string
         registeredName: string
         sortBy: string
         sortType: string
      },
      {}
   >
   "gov_it.invoice_transfers": TableState<
      {
         columns: GovItInvoiceTransfersTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         existsViolations: boolean | null
         createdStrictlyAfter: string
         createdBefore: string
         createdAt: string
         originalFileName: string
      },
      {}
   >
   "openbanking.webhooks": TableState<
      { columns: []; checkedColumns: string[] },
      {},
      {}
   >
   "openbanking.business_registries": TableState<
      {
         columns: []
         expandedRows: string[]
         hideInactiveAccounts: boolean
         checkedColumns: string[]
      },
      {},
      {}
   >
   "openbanking.transactions": TableState<
      {
         columns: []
         checkedColumns: string[]
         fiscalId: string
      },
      {
         category: string[]
         accountUuid: string[]
         madeAfter: string
         madeBefore: string
      },
      {}
   >
   "openbanking.payments": TableState<
      {
         columns: []
         checkedColumns: string[]
         fiscalId: string
      },
      {
         accountUuid: string[]
         system: string[]
         madeAfter: string
         madeBefore: string
      },
      {}
   >
   "common.sub_accounts": TableState<
      { columns: []; checkedColumns: string[] },
      {
         email: string
         fiscal_id: string
         enabled: null
      },
      {}
   >
   "preservation.documents": TableState<
      {
         columns: PreservationDocumentsTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         documentType: PreservationDocumentType | null
         status: PreservationDocumentStatus | null
         createdAfter: string | null
         createdStrictlyAfter: string | null
         createdBefore: string | null
         createdStrictlyBefore: string | null
      },
      {}
   >
   "customer.invoices": TableState<
      {
         columns: CustomerSupplierInvoiceTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         uuid: string
         createdAt: {
            after: string
            before: string
         }
         signed: string
         downloaded: string
         toPa: string
         rejectedToFix: boolean
         marking: string[]
         invoiceDate: {
            after: string | null
            before: string | null
         }
         invoiceNumber: string
         documentType: string
         recipient: {
            businessName: string | null
            fiscalId: string | null
         }
         sender: {
            businessName: string | null
            fiscalId: string | null
         }
         stamp: string
      },
      {}
   >
   "supplier.invoices": TableState<
      {
         columns: CustomerSupplierInvoiceTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         uuid: string
         createdAt: {
            after: string
            before: string
         }
         signed: string
         downloaded: string
         toPa: string
         rejectedToFix: boolean
         marking: string[]
         invoiceDate: {
            after: string | null
            before: string | null
         }
         invoiceNumber: string
         documentType: string
         recipient: {
            businessName: string | null
            fiscalId: string | null
         }
         sender: {
            businessName: string | null
            fiscalId: string | null
         }
         stamp: string
      },
      {}
   >
   "invoice.drafts": TableState<
      {
         columns: InvoiceDraftTableConfigurableColumns[]
         checkedColumns: string[]
         lastCheckedColumns: {
            title: string
            state: DraftCheckedStateType
         } | null
      },
      {
         uuid: string
         createdAt: {
            after: string
            before: string
         }
         recipient: {
            businessName: string | null
            fiscalId: string | null
         }
         sender: {
            businessName: string | null
            fiscalId: string | null
         }
         invoiceDate: {
            after: string | null
            before: string | null
         }
         invoiceNumber: string
         documentType: string
         marking: string[]
      },
      {}
   >
   "api.configurations": TableState<
      {
         columns: ApiConfigurationTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         "business_registry_configurations.fiscal_id": string[]
         targetUrl: string
      },
      {}
   >
   business_registry_configurations: TableState<
      {
         columns: BusinessRegistryConfigurationsTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         fiscalId: string
         email: string
         name: string
         supplierInvoiceEnabled: boolean | null
         applySignature: boolean | null
         applyLegalStorage: boolean | null
         legalStorageActive: boolean | null
      },
      {}
   >
} & {
   [key in
      | "smart_receipt.return"
      | "smart_receipt.sale"
      | "smart_receipt.void"]: TableState<
      {
         columns: SmartReceiptTableConfigurableColumns[]
         checkedColumns: string[]
      },
      {
         id: string
         businessRegistryConfigurationsFiscalId: string[]
         documentNumber: string
         status: string[]
         createdAt: {
            after: string
            before: string
         }
         documentDate: {
            after: string | null
            before: string | null
         }
      },
      {
         businessRegistryConfigurationsFiscalIds: string[]
      }
   >
}

export type TableName = keyof TablesState

const paginationInitialState = {
   itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
   totalItems: 0,
   currentPage: 1,
}

const plInvoicesFiltersInitialState = {
   status: "",
   createdAfter: null,
   createdBefore: null,
   invoiceDateAfter: null,
   invoiceDateBefore: null,
   invoiceCreatorNip: "",
   invoiceRecipientNip: "",
   legalEntityUuid: "",
   invoiceType: "",
}

const govItInvoiceInitialState = (
   table: "customer.invoices" | "supplier.invoices" | "invoice.drafts",
   reset = false
) => ({
   ...paginationInitialState,
   itemsPerPage: 30,
   filters: {
      uuid: "",
      createdAt: {
         after: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
         before: format(new Date(), "yyyy-MM-dd"),
      },
      marking: [],
      invoiceDate: {
         after: null,
         before: null,
      },
      invoiceNumber: "",
      documentType: "",
      recipient: {
         businessName: "",
         fiscalId: "",
      },
      sender: {
         businessName: "",
         fiscalId: "",
      },
      ...(table !== "invoice.drafts" && {
         downloaded: "",
         toPa: "",
         rejectedToFix: false,
         signed: "",
         stamp: "",
      }),
      ...(reset ? {} : readTableFilters(table) ?? {}),
   },
   filtersData: {},
})

const smartReceiptInitialState = (
   table: "smart_receipt.return" | "smart_receipt.sale" | "smart_receipt.void",
   reset = false
) => ({
   ...paginationInitialState,
   itemsPerPage: 30,
   data: {
      columns: readTableColumns(table) ?? [
         "status",
         "fiscal_id",
         "created_at",
         "total_amount",
         "transaction_id",
         "document_date",
         "document_number",
      ],
      checkedColumns: [],
   },
   filters: {
      id: "",
      businessRegistryConfigurationsFiscalId: [],
      documentNumber: "",
      status: [],
      createdAt: {
         after: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
         before: format(new Date(), "yyyy-MM-dd"),
      },
      documentDate: {
         after: null,
         before: null,
      },
      ...(reset ? {} : readTableFilters(table) ?? {}),
   },
   filtersData: {
      businessRegistryConfigurationsFiscalIds: [],
   },
})

export const tablesInitialState: TablesState = {
   "peppol.legal_entities": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: {
         identifierValue: "",
         registeredName: "",
         sortBy: "",
         sortType: "asc",
      },
      filtersData: {},
   },
   "gov_it.invoice_transfers": {
      ...paginationInitialState,
      itemsPerPage: 30,
      data: {
         columns: readTableColumns("gov_it.invoice_transfers") ?? [
            "original_file_name",
            "invoice_number",
            "invoice_date",
            "violations",
            "sent_at",
            "marking",
         ],
         checkedColumns: [],
      },
      filters: {
         createdStrictlyAfter: format(
            sub(new Date(), { days: 30 }),
            "yyyy-MM-dd"
         ),
         createdBefore: format(new Date(), "yyyy-MM-dd"),
         createdAt: "",
         existsViolations: null,
         originalFileName: "",
         ...(readTableFilters("gov_it.invoice_transfers") ?? {}),
      },
      filtersData: {},
   },
   "pl.webhooks": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: {},
      filtersData: {},
   },
   "pl.legal_entities": {
      ...paginationInitialState,
      data: {
         columns: ["nip", "email", "name", "incomingInvoiceEnabled", "status"],
         checkedColumns: [],
      },
      filters: {},
      filtersData: {},
   },
   "pl.incoming_invoices": {
      ...paginationInitialState,
      // @TODO: invoices endpoint currently doesn't allow to configure this
      itemsPerPage: 30,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: plInvoicesFiltersInitialState,
      filtersData: {},
   },
   "pl.outgoing_invoices": {
      ...paginationInitialState,
      // @TODO: invoices endpoint currently doesn't allow to configure this
      itemsPerPage: 30,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: plInvoicesFiltersInitialState,
      filtersData: {},
   },
   "openbanking.webhooks": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: {},
      filtersData: {},
   },
   "openbanking.business_registries": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
         expandedRows: [],
         hideInactiveAccounts: false,
      },
      filters: {},
      filtersData: {},
   },
   "openbanking.transactions": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
         fiscalId: "",
      },
      filters: {
         category: [],
         accountUuid: [],
         madeAfter: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
         madeBefore: format(new Date(), "yyyy-MM-dd"),
      },
      filtersData: {},
   },
   "openbanking.payments": {
      ...paginationInitialState,
      data: {
         columns: [],
         checkedColumns: [],
         fiscalId: "",
      },
      filters: {
         accountUuid: [],
         system: [],
         madeAfter: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
         madeBefore: format(new Date(), "yyyy-MM-dd"),
      },
      filtersData: {},
   },
   "common.sub_accounts": {
      ...paginationInitialState,
      itemsPerPage: 30,
      data: {
         columns: [],
         checkedColumns: [],
      },
      filters: {
         email: "",
         fiscal_id: "",
         enabled: null,
      },
      filtersData: {},
   },
   "preservation.documents": {
      ...paginationInitialState,
      itemsPerPage: 30,
      data: {
         columns: readTableColumns("preservation.documents") ?? [
            "original_filename",
            "status",
            "created_at",
         ],
         checkedColumns: [],
      },
      filters: {
         documentType: null,
         status: null,
         createdAfter: null,
         createdStrictlyAfter: null,
         createdBefore: null,
         createdStrictlyBefore: null,
      },
      filtersData: {},
   },
   "customer.invoices": {
      data: {
         columns: readTableColumns("customer.invoices") ?? [
            "created_at",
            "senderFormatted",
            "recipientFormatted",
            "recipientVatNumberFormatted",
            "invoiceDate",
            "invoiceNumber",
            "invoiceTotal",
            "marking",
            "downloaded",
         ],
         checkedColumns: [],
      },
      ...govItInvoiceInitialState("customer.invoices"),
      filters: {
         ...govItInvoiceInitialState("customer.invoices").filters,
         ...(readTableFilters("customer.invoices") ?? {}),
      },
      refetch: readTableRefresh("customer.invoices"),
   },
   "supplier.invoices": {
      data: {
         columns: readTableColumns("supplier.invoices") ?? [
            "created_at",
            "senderFormatted",
            "recipientFormatted",
            "senderVatNumberFormatted",
            "invoiceDate",
            "invoiceNumber",
            "invoiceTotal",
            "marking",
            "downloaded",
         ],
         checkedColumns: [],
      },
      ...govItInvoiceInitialState("supplier.invoices"),
      refetch: readTableRefresh("supplier.invoices"),
   },
   "invoice.drafts": {
      data: {
         columns: readTableColumns("invoice.drafts") ?? [
            "created_at",
            "senderFormatted",
            "recipientFormatted",
            "invoiceDate",
            "invoiceNumber",
            "invoiceTotal",
            "document_type",
            "marking",
         ],
         checkedColumns: [],
         lastCheckedColumns: readLastCheckedColumns("invoice.drafts"),
      },
      ...govItInvoiceInitialState("invoice.drafts"),
      refetch: readTableRefresh("invoice.drafts"),
   },
   "api.configurations": {
      ...paginationInitialState,
      data: {
         columns: readTableColumns("api.configurations") ?? [
            "uuid",
            "event",
            "target_url",
            "business_registry_configurations",
         ],
         checkedColumns: [],
      },
      filters: {
         "business_registry_configurations.fiscal_id": [],
         targetUrl: "",
      },
      filtersData: {},
   },
   business_registry_configurations: {
      ...paginationInitialState,
      itemsPerPage: 30,
      data: {
         columns: readTableColumns("business_registry_configurations") ?? [
            "fiscal_id",
            "email",
            "name",
            "supplier_invoice",
            "signature",
            "apply_legal_storage",
         ],
         checkedColumns: [],
      },
      filters: {
         fiscalId: "",
         email: "",
         name: "",
         supplierInvoiceEnabled: null,
         applySignature: null,
         applyLegalStorage: null,
         legalStorageActive: null,
      },
      filtersData: {},
   },
   "smart_receipt.return": {
      ...smartReceiptInitialState("smart_receipt.return"),
      refetch: readTableRefresh("smart_receipt.return"),
   },
   "smart_receipt.sale": {
      ...smartReceiptInitialState("smart_receipt.sale"),
      refetch: readTableRefresh("smart_receipt.sale"),
   },
   "smart_receipt.void": {
      ...smartReceiptInitialState("smart_receipt.void"),
      refetch: readTableRefresh("smart_receipt.void"),
   },
}

const TableFiltersToPersist = [
   "customer.invoices",
   "supplier.invoices",
   "invoice.drafts",
   "gov_it.invoice_transfers",
   "smart_receipt.return",
   "smart_receipt.sale",
   "smart_receipt.void",
] as TableName[]

const tablesSlice = createSlice({
   name: "tablesState",
   initialState: tablesInitialState,
   reducers: {
      changePage: (
         state,
         { payload }: PayloadAction<{ table: TableName; page: number }>
      ) => {
         const { table, page } = payload
         state[table].currentPage = page
      },

      updateTotalItems: (
         state,
         { payload }: PayloadAction<{ table: TableName; totalItems: number }>
      ) => {
         const { table, totalItems } = payload
         state[table].totalItems = totalItems
      },

      resetPagination: (
         state,
         { payload }: PayloadAction<{ table: TableName }>
      ) => {
         const { table } = payload

         Object.entries(paginationInitialState).forEach(([key, value]) => {
            state[table][key as keyof typeof paginationInitialState] = value
         })
      },

      updateData: (
         state,
         {
            payload,
         }: PayloadAction<{
            table: TableName
            param: keyof TablesState[TableName]["data"]
            value: string | string[] | boolean | object
         }>
      ) => {
         const { table, param, value } = payload
         ;(state[table].data[param] as any) = value
      },

      updateFilter: (
         state,
         {
            payload,
         }: PayloadAction<{
            table: TableName
            filter: keyof TablesState[TableName]["filters"]
            value: string | number | Record<string, string | null> | null
         }>
      ) => {
         const { table, filter, value } = payload
         ;(state[table].filters[filter] as any) = value
         if (TableFiltersToPersist.includes(table)) {
            persistTableFilters(table, state[table].filters)
         }
      },

      updateFilterData: (
         state,
         {
            payload,
         }: PayloadAction<{
            table: TableName
            filter: keyof TablesState[TableName]["filtersData"]
            value:
               | string
               | string[]
               | boolean
               | Record<string, any>
               | number
               | null
         }>
      ) => {
         const { table, filter, value } = payload
         ;(state[table].filtersData as any)[filter] = value
      },

      updateRefetch: (
         state,
         {
            payload,
         }: PayloadAction<{
            table: TableName
         }>
      ) => {
         const { table } = payload
         state[table].refetch = readTableRefresh(table)
      },

      resetFilters: (
         state,
         { payload }: PayloadAction<{ table: TableName }>
      ) => {
         const { table } = payload
         persistTableFilters(table, null)
         if (
            table === "customer.invoices" ||
            table === "supplier.invoices" ||
            table === "invoice.drafts"
         ) {
            state[table].filters = {
               ...govItInvoiceInitialState(table, true).filters,
            }
            return
         } else if (
            table === "smart_receipt.return" ||
            table === "smart_receipt.sale" ||
            table === "smart_receipt.void"
         ) {
            state[table].filters = {
               ...smartReceiptInitialState(table, true).filters,
            }
            return
         }
         state[table].filters = tablesInitialState[table].filters
      },
   },
})

export const { name, reducer } = tablesSlice

export const { changePage, updateTotalItems, resetPagination, resetFilters } =
   tablesSlice.actions

const baseUpdateRefetch = tablesSlice.actions.updateRefetch
export const updateRefetch: <T extends TableName>(_: {
   table: T
}) => ReturnType<typeof baseUpdateRefetch> = baseUpdateRefetch as any

const baseUpdateFilter = tablesSlice.actions.updateFilter
const baseUpdateFilterData = tablesSlice.actions.updateFilterData
export const updateFilter: <T extends TableName>(_: {
   table: T
   filter: keyof TablesState[T]["filters"]
   value: string | string[] | boolean | Record<string, any> | number | null
}) => ReturnType<typeof baseUpdateFilter> = baseUpdateFilter as any

export const updateFilterData: <T extends TableName>(_: {
   table: T
   filter: keyof TablesState[T]["filtersData"]
   value: string | string[] | boolean | Record<string, any> | null
}) => ReturnType<typeof baseUpdateFilterData> = baseUpdateFilterData as any

const baseUpdateData = tablesSlice.actions.updateData
export const updateData: <T extends TableName>(_: {
   table: T
   param: keyof TablesState[T]["data"]
   value: string | string[] | boolean | object
}) => ReturnType<typeof baseUpdateFilter> = baseUpdateData as any

export const selectTables = ({ tablesState }: RootState) => tablesState

export const selectTable = <T extends TableName>(
   { tablesState }: RootState,
   { table }: { table: T }
) => tablesState[table]

export const selectItemsPerPage = (
   state: RootState,
   params: { table: TableName }
) => selectTable(state, params).itemsPerPage

export const selectRefetch = (state: RootState, params: { table: TableName }) =>
   selectTable(state, params).refetch

export const selectTotalItems = (
   state: RootState,
   params: { table: TableName }
) => selectTable(state, params).totalItems

export const selectCurrentPage = (
   state: RootState,
   params: { table: TableName }
) => selectTable(state, params).currentPage

export const selectTableFilters = <T extends TableName>(
   state: RootState,
   params: { table: T }
) => selectTable(state, params).filters as TablesState[T]["filters"]

export const selectTableFiltersData = <T extends TableName>(
   state: RootState,
   params: { table: T }
) => selectTable(state, params).filtersData as TablesState[T]["filtersData"]

export const selectTableData = <T extends TableName>(
   state: RootState,
   params: { table: T }
) => selectTable(state, params).data as TablesState[T]["data"]

export const selectTableColumns = <T extends TableName>(
   state: RootState,
   params: { table: T }
) => selectTableData(state, params).columns as TablesState[T]["data"]["columns"]

export const selectTableCheckedColumns = <T extends TableName>(
   state: RootState,
   params: { table: T }
) =>
   selectTableData(state, params)
      .checkedColumns as TablesState[T]["data"]["checkedColumns"]

export const selectTableLastCheckedColumns = <T extends TableName>(
   state: RootState,
   params: { table: T }
) => {
   const tableData = selectTableData(state, params)

   if ("lastCheckedColumns" in tableData) {
      return tableData.lastCheckedColumns as "lastCheckedColumns" extends keyof TablesState[T]["data"]
         ? TablesState[T]["data"]["lastCheckedColumns"]
         : null
   }
   return null
}

export const selectAllTablesNameWithFilters = (state: RootState) =>
   Object.entries(state.tablesState)
      .filter(([, table]) => Object.keys(table.filters).length > 0)
      .map(([table]) => table as TableName)

listenerMiddleware.startListening({
   actionCreator: baseUpdateData,
   effect: ({ payload }) => {
      const { table, param, value } = payload
      if (param === "columns" && Array.isArray(value)) {
         persistTableColumns(table, value)
      }
   },
})
