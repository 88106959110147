import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

import WebhookCreationForm from "./WebhookCreationForm"

export default function WebhookCreationDialog(props) {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Dialog
         fullWidth={true}
         maxWidth="sm"
         open={props.isOpen}
         onClose={props.handleClose}
         aria-labelledby="api-configuration-dialog-title"
         aria-describedby="api-configuration-dialog-description"
         className={classes.container}
      >
         <DialogTitle id="api-configuration-dialog-title">
            {t("Integrazione.Integrazione")}
         </DialogTitle>

         <DialogContent>
            <DialogContentText>
               <WebhookCreationForm
                  handleClose={props.handleClose}
                  handlePostWebhook={props.handlePostWebhook}
               />
            </DialogContentText>
         </DialogContent>
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
}))
