import { buildSearchParams } from "helper/buildSearchParams"
import { govPlApi, GovPLPaginationParams } from ".."

export type GovPLWebhook = {
   uuid: string
   legalEntityUUID: string
   legalEntityName: string
   webhookType:
      | "legal-entity-on-boarding-activated"
      | "legal-entity-on-boarding-deactivated"
      | "legal-entity-session-manager"
      | "legal-entity-invoice-sender"
      | "legal-entity-invoice-receiver"
      | "legal-entity-invoice-upo"
      | "legal-entity-invoice-sync"
   webhookUrl: string
}

type GetWebhooksParams = GovPLPaginationParams

const govPlWebhooksApi = govPlApi.injectEndpoints({
   endpoints: (builder) => ({
      getWebhooks: builder.query<GovPLWebhook[], GetWebhooksParams>({
         query: (params) => ({
            url: `/webhooks${buildSearchParams(params)}`,
         }),
         providesTags: ["WEBHOOKS"],
      }),
   }),
})

export const { useGetWebhooksQuery } = govPlWebhooksApi
