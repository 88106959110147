import {
   Box,
   Card,
   CardContent,
   Divider,
   Grid,
   IconButton,
   makeStyles,
   Typography,
} from "@material-ui/core"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import PropTypes from "prop-types"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { getMonthNameFromNumber } from "services/dates"
//import { formatPrice } from "services/prices"

import { formatPrice } from "helper/numberFormater"
import useCarousel from "./useCarousel"

const useStyles = makeStyles((theme) => ({
   ConsumptionsMonths: {
      position: "relative",
   },
   cardContent: {
      "&:last-child": {
         paddingBottom: 0,
      },
   },
   consumptionsMonths: {
      flexWrap: "nowrap",
   },
   emptyConsumption: {
      marginTop: 28,
      marginBottom: 28,
   },
   currentMonthAmount: {
      marginRight: theme.spacing(1),
   },
   month: {
      flexShrink: 0,
      textAlign: "center",
   },
   month__name: {
      fontSize: "14px",
      color: theme.palette.text.secondary,
   },
   month__amount: {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "600",
   },
}))

const ConsumptionsMonths = ({ monthlyConsumption }) => {
   const classes = useStyles()
   const { i18n } = useTranslation()

   const carouselWrapper = useRef()
   const carouselNext = useRef()
   const carouselPrev = useRef()

   const [onPrev, onNext, isPrevEnabled, isNextEnabled] = useCarousel(
      carouselWrapper,
      carouselPrev,
      carouselNext
   )

   return (
      <div className={classes.ConsumptionsMonths}>
         <Grid
            ref={carouselWrapper}
            container
            direction="row"
            style={{ transform: "translateX(0%)" }}
            className={classes.consumptionsMonths}
         >
            {monthlyConsumption.map(({ month, year, total }) => (
               <Grid
                  key={month}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  xl={3}
                  className={classes.month}
               >
                  <Box my={2}>
                     <Typography className={classes.month__name}>
                        {getMonthNameFromNumber(month, i18n.language)} {year}
                     </Typography>
                     <Typography className={classes.month__amount}>
                        {formatPrice(i18n.language, total)}
                     </Typography>
                  </Box>
               </Grid>
            ))}
         </Grid>
         <Box
            ref={carouselNext}
            position="absolute"
            top={0}
            right="-10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={2}
            height="100%"
         >
            <IconButton onClick={onNext} disabled={!isNextEnabled}>
               <ArrowRightIcon />
            </IconButton>
         </Box>
         <Box
            ref={carouselPrev}
            position="absolute"
            top={0}
            left="-10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={2}
            height="100%"
         >
            <IconButton onClick={onPrev} disabled={!isPrevEnabled}>
               <ArrowLeftIcon />
            </IconButton>
         </Box>
      </div>
   )
}

const Consumptions = ({ monthlyConsumption }) => {
   const classes = useStyles()
   const { t, i18n } = useTranslation()
   const emptyConsumption = monthlyConsumption.every(
      (m) => parseFloat(m.total) === 0
   )

   return (
      <Card>
         <CardContent className={classes.cardContent}>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("Dashboard.title.consumption")}
            </Typography>
            {monthlyConsumption.length > 0 && (
               <Box display="flex" alignItems="center" my={2}>
                  <Typography
                     variant="h3"
                     className={classes.currentMonthAmount}
                  >
                     {formatPrice(i18n.language, monthlyConsumption[0].total)}
                  </Typography>
                  <Typography color="textSecondary" variant="h5">
                     {t("Dashboard.text.current_month")}
                  </Typography>
               </Box>
            )}
            <Divider />
            {!emptyConsumption && monthlyConsumption.length > 1 && (
               <ConsumptionsMonths
                  monthlyConsumption={monthlyConsumption.slice(1)}
               />
            )}
            {emptyConsumption && (
               <Box
                  className={classes.emptyConsumption}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
               >
                  <Typography color="primary" variant="h5">
                     {t("Dashboard.text.no_consumption")}
                  </Typography>
               </Box>
            )}
         </CardContent>
      </Card>
   )
}

Consumptions.propTypes = {
   monthlyConsumption: PropTypes.array.isRequired,
}

export default Consumptions
