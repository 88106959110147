import { Box, CircularProgress } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { AuthFlowStatus } from "services/gov-pl/legalEntities"
import { useGetOnboardingLegalEntityQuery } from "services/gov-pl/onboard"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasOnboardExpired, setExpiration } from "store/slices/onboard"
import Stepper from "./Stepper"
import OnboardSteps from "./Steps"

const Content = () => {
   const { t, i18n } = useTranslation()
   const dispatch = useAppDispatch()
   const { legalEntityUuid, hash } = useParams()

   const { data, isLoading } = useGetOnboardingLegalEntityQuery({
      uuid: legalEntityUuid!,
      hash: hash!,
   })
   const authFlowStatus = data?.authFlowStatus
   const authFlowExpiresAt = data?.authFlowDeadline
   const authFlowUuid = data?.authFlowUUID
   const nip = data?.nip

   const onboardExpired = useAppSelector(selectHasOnboardExpired)
   const canAccessOnboard =
      authFlowStatus != null &&
      [
         AuthFlowStatus.NEW,
         AuthFlowStatus.IN_PROGRESS,
         AuthFlowStatus.USED,
         AuthFlowStatus.COMPLETED,
      ].includes(authFlowStatus)

   useEffect(() => {
      i18n.changeLanguage("pl")
   }, [i18n])

   useEffect(() => {
      if (authFlowExpiresAt == null) {
         return
      }

      const promise = dispatch(setExpiration(authFlowExpiresAt))

      return () => {
         promise.abort()
      }
   }, [dispatch, authFlowExpiresAt])

   if (isLoading) {
      return (
         <Box display="flex" justifyContent="center" color="grey.600">
            <CircularProgress size={48} color="inherit" />
         </Box>
      )
   }

   if (onboardExpired) {
      // @TODO: change copy
      return <Box>Session expired</Box>
   }

   if (canAccessOnboard) {
      return (
         <OnboardSteps
            authFlowStatus={authFlowStatus}
            authFlowUuid={authFlowUuid}
            nip={String(nip!)}
         />
      )
   }

   return <Box m={3}>{t("onboard.error.generic")}</Box>
}

const OnboardMode1 = () => {
   return (
      <>
         <Stepper />
         <Content />
      </>
   )
}

export default OnboardMode1
