import { Box, Typography } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import { FormikHelpers } from "formik"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
   useAddNewSubAccountToBusinessRegistryConfigurationMutation,
   useCreateBusinessRegistryConfigurationMutation,
   useEditBusinessRegistryConfigurationMutation,
   useGetBusinessRegistryConfigurationQuery,
   useLazyListSubAccountConnectedToBusinessRegistryConfigurationQuery,
   useListSubAccountConnectedToBusinessRegistryConfigurationQuery,
   useRemoveSubAccountFromBusinessRegistryConfigurationMutation,
} from "services/gov-it/businessRegistryConfigurations"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasPid, selectHasRole } from "store/auth"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import BusinessRegistryConfigurationsForm, {
   FormValues,
} from "views/BusinessRegistryConfigurations/Dialogs/Form"
import Loader from "views/common/Loader"
import Statistics from "views/common/Statistics"

const table: TableName = "api.configurations"
const DIALOG_ID = "business.registry.configurations"

type ViolationType = {
   propertyPath: string
   message: string
}

type Props = {
   handleClose: () => void
   fiscal_id: string | null
   mode?: "stats"
}

const arraysAreIdentical = (arr1: string | any[], arr2: string | any[]) => {
   if (arr1.length !== arr2.length) {
      return false
   }
   for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
         return false
      }
   }
   return true
}

function Content({ handleClose, fiscal_id, mode }: Props) {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const dispatch = useAppDispatch()
   const [dataSubAccount, setDataSubAccount] = useState<{ email: string }[]>([])

   const allowedToManageSubAccounts = useAppSelector(
      (state) =>
         selectHasRole(state, {
            context: "it.api.acubeapi.com",
            role: "ROLE_SUB_ACCOUNT_MANAGER",
         }) && !selectHasPid(state)
   )

   const [progress, setProgress] = useState<{
      connect: {
         success: string[] | null
         error: string[] | null
      } | null
      disconnect: {
         success: string[] | null
         error: string[] | null
      } | null
   }>({ connect: null, disconnect: null })

   const { data, isLoading } = useGetBusinessRegistryConfigurationQuery(
      { fiscal_id: fiscal_id! },
      { skip: !fiscal_id }
   )

   const { data: dataSubAccountFetch, isLoading: isLoadingSubAccount } =
      useListSubAccountConnectedToBusinessRegistryConfigurationQuery(
         { id: fiscal_id! },
         { skip: !fiscal_id }
      )

   const getDataAccount = async () => {
      const response = await getSubAccounts({
         id: fiscal_id!,
         refresh: Math.random().toString(),
      })

      if ("error" in response) {
         setDataSubAccount([])
         return
      }

      if ("data" in response) {
         setDataSubAccount(response.data ?? [])
      }
   }

   const [getSubAccounts, { isLoading: isLoadingSubAccounts }] =
      useLazyListSubAccountConnectedToBusinessRegistryConfigurationQuery()

   const [editBusinessRegistryConfiguration, { isLoading: isUpdating }] =
      useEditBusinessRegistryConfigurationMutation()

   const [createBusinessRegistryConfiguration, { isLoading: isCreating }] =
      useCreateBusinessRegistryConfigurationMutation()

   const [addNewSubAccountToBusinessRegistryConfiguration] =
      useAddNewSubAccountToBusinessRegistryConfigurationMutation()

   const [removeSubAccountFromBusinessRegistryConfiguration] =
      useRemoveSubAccountFromBusinessRegistryConfigurationMutation()

   const connectSubAccount = async (email: string) => {
      try {
         const response = await addNewSubAccountToBusinessRegistryConfiguration(
            {
               id: fiscal_id!,
               email,
               password: null,
            }
         )

         return !("error" in response)
      } catch (error) {
         enqueueSnackbar(
            t("BusinessRegistry.connectSubAccountSuccess", { email }),
            {
               variant: "error",
            }
         )
         return false
      }
   }

   const disconnectSubAccount = async (email: string) => {
      try {
         const response =
            await removeSubAccountFromBusinessRegistryConfiguration({
               id: fiscal_id!,
               email,
            })

         return !("error" in response)
      } catch (error) {
         enqueueSnackbar(
            t("BusinessRegistry.disconnectSubAccountError", { email }),
            {
               variant: "error",
            }
         )
         return false
      }
   }

   const manageSubAccounts = async (subAccounts: string[]) => {
      const isEmptyDataSubAccounts =
         !dataSubAccount || dataSubAccount.length === 0

      const subAccountsToDelete = isEmptyDataSubAccounts
         ? []
         : dataSubAccount
              .map((a) => a.email)
              .filter((a) => !subAccounts.includes(a))

      const subAccountsToAdd = isEmptyDataSubAccounts
         ? subAccounts
         : subAccounts.filter(
              (a) => !dataSubAccount.map((a) => a.email).includes(a)
           )

      let connectSuccess = []
      let connectError = []
      if (
         !arraysAreIdentical(
            subAccountsToAdd,
            dataSubAccount?.map((a) => a.email) || []
         )
      ) {
         // connect new subAccounts
         if (subAccountsToAdd.length > 0) {
            for (const email of subAccountsToAdd) {
               const isDone = await connectSubAccount(email)
               if (isDone) {
                  connectSuccess.push(email)
               } else {
                  connectError.push(email)
               }
            }
         }
      }

      let disconnectSuccess = []
      let disconnectError = []
      // disconnect subAccounts
      if (subAccountsToDelete.length > 0) {
         for (const email of subAccountsToDelete) {
            const isDone = await disconnectSubAccount(email)
            if (isDone) {
               disconnectSuccess.push(email)
            } else {
               disconnectError.push(email)
            }
         }
      }

      setProgress({
         connect: {
            success: connectSuccess,
            error: connectError,
         },
         disconnect: {
            success: disconnectSuccess,
            error: disconnectError,
         },
      })

      await getDataAccount()
   }

   const handleSaveBusinessRegistryConfiguration = async (
      uuid: string | null,
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      setErrors(null)
      setMessage(null)

      if (!values.fiscal_id) {
         setErrors([
            { propertyPath: "Fiscal ID", message: "This field is required" },
         ])
         return
      }
      let response
      let apiConfigurations = values.api_configurations
         .map((a) => a.value)
         .filter((a) => a)

      const subAccounts = values.sub_accounts
         .map((a) => a.value)
         .filter((a) => a)

      if (uuid) {
         response = await editBusinessRegistryConfiguration({
            ...values,
            fiscal_id: uuid,
            api_configurations: apiConfigurations as string[],
         })
      } else {
         response = await createBusinessRegistryConfiguration({
            ...values,
            api_configurations: apiConfigurations as string[],
         })
      }

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            const violations = (response.error.data as any)
               ?.violations as ViolationType[]
            setErrors(violations)
            violations.forEach((v) => setFieldError(v.propertyPath, v.message))
            enqueueSnackbar(t("Integrazione.Validation Error"), {
               variant: "error",
            })
            return
         }

         if ("hydra:description" in (response.error.data as any)) {
            const description = (response.error.data as any)[
               "hydra:description"
            ]
            setErrors([{ propertyPath: "", message: description }])
            return
         }
      }

      if ("data" in response) {
         const evento = response.data
         if (uuid) {
            setMessage(`${t("Integrazione.Aggiornato")} `)
            enqueueSnackbar(`${t("Integrazione.Aggiornato")}`, {
               variant: "success",
            })
         } else {
            setMessage(
               `${t("Integrazione.Salvato")} - UUID: ${evento?.fiscal_id}`
            )
            enqueueSnackbar(
               `${t("Integrazione.Salvato")} - UUID: ${evento?.fiscal_id}`,
               { variant: "success" }
            )
         }

         if (allowedToManageSubAccounts) {
            await manageSubAccounts(subAccounts)
         }

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const businessRegistryConfiguration = data

   useEffect(() => {
      if (dataSubAccountFetch) {
         setDataSubAccount(dataSubAccountFetch)
      }
   }, [dataSubAccountFetch])

   if (isLoadingSubAccounts || isLoading || isLoadingSubAccount) {
      return <Loader />
   }

   return (
      <>
         {mode === "stats" && fiscal_id ? (
            <DialogContent>
               <Box mt={2} mb={2}>
                  <Statistics fiscalId={fiscal_id} />
               </Box>
            </DialogContent>
         ) : (
            <>
               <DialogTitle id="business-configuration-dialog-title">
                  {"Business Registry"}{" "}
                  {businessRegistryConfiguration &&
                  businessRegistryConfiguration.fiscal_id
                     ? businessRegistryConfiguration.fiscal_id
                     : null}
               </DialogTitle>

               <DialogContent>
                  {errors && errors.length > 0 && (
                     <Box mt={2} mb={2}>
                        <Alert
                           severity="error"
                           onClose={() => {
                              setErrors([])
                           }}
                        >
                           <AlertTitle>
                              {t("Integrazione.Validation Error")}
                           </AlertTitle>
                           {errors.map(function (error) {
                              return (
                                 <p>
                                    <strong>{error.propertyPath}</strong>{" "}
                                    {error.message}
                                 </p>
                              )
                           })}
                        </Alert>
                     </Box>
                  )}

                  {message && (
                     <Box mt={2} mb={2}>
                        <Alert
                           severity="success"
                           style={{
                              margin: 2,
                           }}
                           onClose={() => {
                              setMessage(null)
                           }}
                        >
                           <AlertTitle>{t("global.success")}</AlertTitle>
                           {message}
                        </Alert>
                     </Box>
                  )}

                  {progress.connect && (
                     <Box mt={2} mb={2}>
                        {progress.connect.success &&
                           progress.connect.success.length > 0 && (
                              <Alert
                                 severity="success"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       connect: {
                                          error:
                                             progress.connect?.error || null,
                                          success: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.success")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.connectSubAccountsSuccess"
                                    )}
                                 </Typography>{" "}
                                 <br />
                                 <ul>
                                    {progress.connect?.success?.map((email) => (
                                       <li>{email}</li>
                                    ))}
                                 </ul>
                              </Alert>
                           )}

                        {progress.connect.error &&
                           progress.connect.error.length > 0 && (
                              <Alert
                                 severity="error"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       connect: {
                                          success:
                                             progress.connect?.success || null,
                                          error: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.error")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.connectSubAccountsError"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.connect?.error?.map((email) => (
                                       <li>{email}</li>
                                    ))}
                                 </ul>
                              </Alert>
                           )}
                     </Box>
                  )}

                  {progress.disconnect && (
                     <Box mt={2} mb={2}>
                        {progress.disconnect.success &&
                           progress.disconnect.success.length > 0 && (
                              <Alert
                                 severity="success"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       disconnect: {
                                          error:
                                             progress.disconnect?.error || null,
                                          success: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.success")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.disconnectSubAccountsSuccess"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.disconnect?.success?.map(
                                       (email) => (
                                          <li>{email}</li>
                                       )
                                    )}
                                 </ul>
                              </Alert>
                           )}

                        {progress.disconnect.error &&
                           progress.disconnect.error.length > 0 && (
                              <Alert
                                 severity="error"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       disconnect: {
                                          success:
                                             progress.disconnect?.success ||
                                             null,
                                          error: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.error")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.disconnectSubAccountsError"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.disconnect?.error?.map(
                                       (email) => (
                                          <li>{email}</li>
                                       )
                                    )}
                                 </ul>
                              </Alert>
                           )}
                     </Box>
                  )}

                  <BusinessRegistryConfigurationsForm
                     handleClose={handleClose}
                     isLoading={
                        isUpdating ||
                        isLoading ||
                        isCreating ||
                        isLoadingSubAccounts
                     }
                     handleSaveBusinessRegistryConfiguration={
                        handleSaveBusinessRegistryConfiguration
                     }
                     businessRegistryConfiguration={
                        businessRegistryConfiguration
                     }
                     allowedToManageSubAccounts={allowedToManageSubAccounts}
                     subAccounts={dataSubAccountFetch}
                  />
               </DialogContent>
            </>
         )}
      </>
   )
}

const BusinessRegistryConfigurationsDialogs = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="xl" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default BusinessRegistryConfigurationsDialogs
