import { INVOICES_STATS } from "../../../endpoints"
import serializeQuery from "../serializer/serializeQuery"
import { get } from "./index"

async function getInvoicesStats(year, queryParams) {
   const queryString = serializeQuery(queryParams)

   let endpoint = INVOICES_STATS + `/${year}`
   if (queryString) {
      endpoint += "?" + queryString
   }

   return await get(endpoint)
}

export default getInvoicesStats
