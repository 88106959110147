import {
   createAsyncThunk,
   createSelector,
   createSlice,
   PayloadAction,
} from "@reduxjs/toolkit"
import { RootState } from "store"

export const setExpiration = createAsyncThunk(
   "onboard/setExpiration",
   (date: string) =>
      new Promise((resolve) => {
         setTimeout(resolve, new Date(date).valueOf() - new Date().valueOf())
      })
)

export type OnboardMode = "mode_1" | "mode_2"
export type OnboardMode1Step = "download" | "sign" | "upload" | "end"
export type OnboardMode2Step = "token" | "end"

type State = {
   expiresAt: string | null
   expired: boolean | null
   currentMode: OnboardMode | null
   mode1CurrentStep: OnboardMode1Step | null
   mode2CurrentStep: OnboardMode2Step
   activationFailed: boolean
}

const initialState: State = {
   expiresAt: null,
   expired: null,
   currentMode: null,
   mode1CurrentStep: null,
   mode2CurrentStep: "token",
   activationFailed: false,
}

export const onboardSlice = createSlice({
   name: "onboard",
   initialState,
   reducers: {
      setOnboardCurrentMode: (
         state,
         { payload }: PayloadAction<OnboardMode>
      ) => {
         state.currentMode = payload
      },

      setOnboardMode1CurrentStep: (
         state,
         { payload }: PayloadAction<OnboardMode1Step | null>
      ) => {
         state.mode1CurrentStep = payload
      },

      setOnboardMode2CurrentStep: (
         state,
         { payload }: PayloadAction<OnboardMode2Step>
      ) => {
         state.mode2CurrentStep = payload
      },

      setActivationFailed: (state, { payload }: PayloadAction<boolean>) => {
         state.activationFailed = payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(setExpiration.pending, (state, { meta }) => {
            const date = meta.arg

            state.expiresAt = date
            state.expired = new Date(date) < new Date()
         })
         .addCase(setExpiration.fulfilled, (state) => {
            state.expired = true
         })
   },
})

export const {
   setOnboardCurrentMode,
   setOnboardMode1CurrentStep,
   setOnboardMode2CurrentStep,
   setActivationFailed,
} = onboardSlice.actions

const selectOnboard = ({ onboard }: RootState) => onboard

export const selectOnboardExpiresAt = createSelector(
   selectOnboard,
   ({ expiresAt }) => expiresAt
)

export const selectHasOnboardExpired = createSelector(
   selectOnboard,
   ({ expired }) => expired
)

export const selectOnboardCurrentMode = (state: RootState) =>
   selectOnboard(state).currentMode

export const selectOnboardMode1CurrentStep = createSelector(
   selectOnboard,
   ({ mode1CurrentStep }) => mode1CurrentStep
)

export const selectOnboardMode2CurrentStep = createSelector(
   selectOnboard,
   ({ mode2CurrentStep }) => mode2CurrentStep
)

export const selectActivationFailed = createSelector(
   selectOnboard,
   ({ activationFailed }) => activationFailed
)

export default onboardSlice.reducer
