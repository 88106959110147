import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { govItApi, GovITPaginationParams } from ".."
import {
   CreateReturnItemsInSmartReceipt,
   CreateSmartReceiptBody,
   SmartReceipt,
   SmartReceiptDetails,
} from "./type"

export type GetSmartReceiptParams = {
   id?: string
   "business_registry_configurations.fiscal_id"?: string[]
   document_number?: string
   status?: string[]
   type?: string
   "created_at[before]"?: string
   "created_at[after]"?: string
   "document_date[before]"?: string
   "document_date[after]"?: string
} & Omit<GovITPaginationParams, "type">

export type GetSmartReceiptResponse = HydraResponse<SmartReceipt>

const smartReceipt = govItApi.injectEndpoints({
   endpoints: (builder) => ({
      getSmartReceipts: builder.query<
         GetSmartReceiptResponse,
         GetSmartReceiptParams
      >({
         query: ({ id, ...params }) => ({
            url: `/receipts${id ? `/${id}` : ""}${buildSearchParams({
               ...params,
               refresh: new Date().getTime(),
            })}`,
         }),
         providesTags: ["SMART_RECEIPTS"],
      }),

      getSmartReceipt: builder.query<SmartReceipt, { id: string }>({
         query: ({ id }) => ({
            url: `/receipts/${id}`,
         }),
      }),

      getDetailOrPDFSmartReceipt: builder.query<
         SmartReceiptDetails,
         { id: string; accept?: "application/pdf" }
      >({
         query: ({ id, accept }) => ({
            url: `receipts/${id}/details`,
            ...(accept && { headers: { Accept: accept } }),
            responseHandler: async (response) => {
               if (accept === "application/pdf") {
                  return await response.blob()
               }
               return response.json()
            },
         }),
         providesTags: ["SMART_RECEIPT_DETAIL_PDF", "SMART_RECEIPTS"],
      }),

      deleteSmartReceipt: builder.mutation<void, { id: string }>({
         query: ({ id }) => ({
            url: `/receipts/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["SMART_RECEIPTS"],
      }),

      createSmartReceipt: builder.mutation<
         SmartReceipt,
         { data: CreateSmartReceiptBody }
      >({
         query: ({ data }) => ({
            url: `/receipts`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["SMART_RECEIPTS"],
      }),

      returnItemsToSmartReceipt: builder.mutation<
         SmartReceipt,
         { id: string; data: CreateReturnItemsInSmartReceipt }
      >({
         query: ({ id, data }) => ({
            url: `/receipts/${id}/return`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["SMART_RECEIPTS"],
      }),
   }),
})

export const {
   useGetSmartReceiptsQuery,
   useGetSmartReceiptQuery,
   useGetDetailOrPDFSmartReceiptQuery,
   useDeleteSmartReceiptMutation,
   useCreateSmartReceiptMutation,
   useReturnItemsToSmartReceiptMutation,
   useLazyGetDetailOrPDFSmartReceiptQuery,
} = smartReceipt
