import { Box, DialogTitle, Typography } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import BaseDialog from "components/BaseDialog"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
   useDeleteDraftInvoiceMutation,
   useSendDraftInvoiceMutation,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import {
   selectTableLastCheckedColumns,
   TableName,
   updateData,
   updateRefetch,
} from "store/tablesState"
import ProgressBar from "views/common/ProgressBar"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import { makeStyles } from "@material-ui/core/styles"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import ErrorIcon from "@material-ui/icons/Error"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import WarningIcon from "@material-ui/icons/Warning"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useGetMeQuery } from "services/commonApi"
import { openDialog } from "store/slices/dialogs"
import {
   persistLastCheckedColumns,
   persistTableRefresh,
} from "store/tablesState/utils"

type ContentProps = {
   handleClose: () => void
   invoices: GovITInvoice[]
} & (
   | {
        mode: "view"
     }
   | {
        uuids: string[]
        mode: "delete" | "send"
     }
)

export type ViolationType = {
   propertyPath: string
   message: string
}

export type DraftCheckedStateType = {
   id: string
   states: SingleDraftCheckedStateType[]
}

export type SingleDraftCheckedStateType = {
   uuid: string
   invoice: GovITInvoice | undefined
} & (
   | {
        status: "success"
     }
   | {
        status: "undefined"
        message: string
     }
   | {
        status: "error"
        validationError?: boolean
        draftBlocked?: boolean
        violations: {
           propertyPath: string
           message: string
        }[]
     }
)
const table: TableName = "invoice.drafts"
const Content = (props: ContentProps) => {
   const { t } = useTranslation()

   const { handleClose, mode, invoices } = props
   const uuids = props.mode === "view" ? [] : props.uuids

   const dispatch = useAppDispatch()
   const classes = useStyles()
   const { enqueueSnackbar } = useSnackbar()

   const lastCheckedColumns = useAppSelector((state) =>
      selectTableLastCheckedColumns(state, { table })
   )

   const [sendDraftInvoice] = useSendDraftInvoiceMutation()
   const [deleteDraftInvoice] = useDeleteDraftInvoiceMutation()
   const { data: userData } = useGetMeQuery()

   const [progress, setProgress] = useState<number>(0)
   const [isProcessing, setIsProcessing] = useState<boolean>(true)
   const [sendDraftState, setSendDraftState] = useState<DraftCheckedStateType>({
      id: btoa(userData?.email ?? ""),
      states: [],
   })

   const sendAllDraftInvoicesChecked = async () => {
      if (uuids.length === 0) {
         enqueueSnackbar(t("global.nothing_selected"), { variant: "warning" })
         return
      }
      setIsProcessing(true)
      setProgress(0)
      for (const uuid of uuids) {
         await sendSingleDraftInvoice(uuid)
         setProgress((prev) => prev + 1)
      }
      setIsProcessing(false)
   }

   const deleteAllDraftInvoicesChecked = async () => {
      if (uuids.length === 0) {
         enqueueSnackbar(t("global.nothing_selected"), { variant: "warning" })
         return
      }
      setIsProcessing(true)
      setProgress(0)

      for (const uuid of uuids) {
         await deleteSingleDraftInvoice(uuid)
         setProgress((prev) => prev + 1)
      }
      setIsProcessing(false)
   }

   const sendSingleDraftInvoice = async (uuid: string) => {
      const invoice = getDraftInvoice(uuid)
      if (!invoice) {
         // add state in array with status undefined
         setSendDraftState((prev) => ({
            ...prev,
            states: [
               ...prev.states,
               {
                  uuid,
                  invoice: invoice,
                  status: "undefined",
                  message: t("gov_it.invoice_drafts.invoice_not_found"),
               },
            ],
         }))
         return
      }

      const response = await sendDraftInvoice({
         uuid: uuid as string,
         type:
            invoice?.transmission_format === "FSM10" ? "simplified" : "simple",
      })

      handleError(response, uuid, invoice)
   }

   const deleteSingleDraftInvoice = async (uuid: string) => {
      const invoice = getDraftInvoice(uuid)
      if (!invoice) {
         // add state in array with status undefined
         setSendDraftState((prev) => ({
            ...prev,
            states: [
               ...prev.states,
               {
                  uuid,
                  invoice,
                  status: "undefined",
                  message: t("gov_it.invoice_drafts.invoice_not_found"),
               },
            ],
         }))
         return
      }
      const response = await deleteDraftInvoice({
         uuid: invoice.uuid,
         type:
            invoice.transmission_format === "FSM10" ? "simplified" : "simple",
      })

      handleError(response, uuid, invoice)
   }

   const handleError = (
      response: any,
      uuid: string,
      invoice?: GovITInvoice
   ) => {
      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         const is400 = response.error.status === 400
         const is409 = response.error.status === 409

         setSendDraftState((prev) => ({
            ...prev,
            states: [
               ...prev.states,
               {
                  uuid,
                  invoice,
                  status: "error",
                  validationError: is400,
                  draftBlocked: is409,
                  violations:
                     data?.violations ?? !(is400 || is409)
                        ? [
                             {
                                propertyPath: "",
                                message: t("global.messages.generic_error"),
                             },
                          ]
                        : [],
               },
            ],
         }))
         return
      }

      if (mode === "send" && "data" in response) {
         setSendDraftState((prev) => ({
            ...prev,
            states: [...prev.states, { uuid, invoice, status: "success" }],
         }))
         return
      }
      if (mode === "delete") {
         setSendDraftState((prev) => ({
            ...prev,
            states: [...prev.states, { uuid, invoice, status: "success" }],
         }))
         return
      }
   }

   const updateTable = () => {
      const failedUuids = sendDraftState.states
         .filter((draft) => draft.status !== "success")
         .map((draft) => draft.uuid)

      persistTableRefresh(table)
      dispatch(
         updateRefetch({
            table,
         })
      )

      dispatch(
         updateData({
            table: table,
            param: "checkedColumns",
            value: failedUuids,
         })
      )

      if (mode === "send") {
         dispatch(
            updateData({
               table: table,
               param: "lastCheckedColumns",
               value: {
                  title: t("gov_it.invoice_drafts.sent_n_invoices", {
                     n: uuids.length,
                  }),
                  state: sendDraftState,
               },
            })
         )
         persistLastCheckedColumns(table, {
            title: t("gov_it.invoice_drafts.sent_n_invoices", {
               n: uuids.length,
            }),
            state: sendDraftState,
         })
      }
   }

   const getDraftInvoice = (uuid: string) => {
      return invoices.find((invoice) => invoice.uuid === uuid)
   }

   useEffect(() => {
      if (!isProcessing && mode !== "view") {
         updateTable()
      }
   }, [isProcessing])

   useEffect(() => {
      if (mode === "send") sendAllDraftInvoicesChecked()
      else if (mode === "delete") deleteAllDraftInvoicesChecked()
   }, [])

   const errorTypes = {
      validationError: {
         title: "global.validation_error",
         message: "global.validation_error_message",
      },
      draftBlocked: {
         title: "global.draft_blocked_error",
         message: "global.draft_blocked_error_message",
      },
      error: {
         title: "global.error",
         message: "global.messages.generic_error",
      },
   }

   const getErrorType = (key: keyof typeof errorTypes) =>
      errorTypes[key] ?? errorTypes.error

   return (
      <>
         <DialogTitle>
            <Typography variant="h3">
               {mode === "view"
                  ? lastCheckedColumns?.title
                  : mode === "send"
                  ? t("gov_it.invoice_drafts.sent_n_invoices", {
                       n: uuids.length,
                    })
                  : t("gov_it.invoice_drafts.delete_n_invoices", {
                       n: uuids.length,
                    })}
            </Typography>
         </DialogTitle>
         <DialogContent>
            <Typography
               variant="h5"
               style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
               }}
            >
               {t("gov_it.invoice_drafts.progressing_invoices", {
                  index:
                     mode === "view"
                        ? lastCheckedColumns?.state.states.length
                        : progress,
                  total:
                     mode === "view"
                        ? lastCheckedColumns?.state.states.length
                        : uuids.length,
               })}
            </Typography>

            <ProgressBar
               progress={
                  mode === "view" ? 100 : (progress / uuids.length) * 100
               }
            />

            <Box mb={4} />

            {(mode == "view"
               ? lastCheckedColumns?.state ?? null
               : sendDraftState
            )?.states.map((draft, index) => {
               const invoice = getDraftInvoice(draft.uuid)
               return (
                  <Accordion
                     key={index}
                     style={{
                        marginBottom: "8px",
                     }}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                     >
                        <Box
                           display="flex"
                           justifyContent="flex-start"
                           width="100%"
                        >
                           <Typography
                              style={{
                                 marginRight: "8px",
                              }}
                           >
                              {draft.status === "success" && (
                                 <DoneAllIcon style={{ color: "green" }} />
                              )}
                              {draft.status === "error" && (
                                 <ErrorIcon style={{ color: "red" }} />
                              )}
                              {draft.status === "undefined" && (
                                 <WarningIcon style={{ color: "orange" }} />
                              )}
                           </Typography>
                           {invoice && mode !== "view" && (
                              <>
                                 <Typography
                                    style={{
                                       marginRight: "8px",
                                    }}
                                 >
                                    <u>{t("CustomerInvoices.InvoiceNumber")}</u>
                                    {": "}
                                    <strong>{invoice.invoiceNumber}</strong>
                                 </Typography>

                                 <Typography>
                                    <u>{t("CustomerInvoices.InvoiceData")}</u>
                                    {": "}
                                    <strong>{invoice.invoiceDate}</strong>
                                 </Typography>
                              </>
                           )}

                           {mode === "view" && (
                              <>
                                 <Typography
                                    style={{
                                       marginRight: "8px",
                                    }}
                                 >
                                    <u>{t("CustomerInvoices.InvoiceNumber")}</u>
                                    {": "}
                                    <strong>
                                       {draft.invoice?.invoiceNumber}
                                    </strong>
                                 </Typography>

                                 <Typography>
                                    <u>{t("CustomerInvoices.InvoiceData")}</u>
                                    {": "}
                                    <strong>
                                       {draft.invoice?.invoiceDate}
                                    </strong>
                                 </Typography>
                              </>
                           )}
                        </Box>
                     </AccordionSummary>

                     {invoice && (
                        <AccordionDetails>
                           <Typography>
                              <u>uuid</u>
                              {": "}
                              <strong>{draft.uuid}</strong>
                           </Typography>
                        </AccordionDetails>
                     )}

                     {!invoice &&
                        mode == "view" &&
                        draft.status !== "success" && (
                           <AccordionDetails>
                              <Box width={"100%"}>
                                 <Alert severity="warning">
                                    <AlertTitle>
                                       {t(
                                          "gov_it.invoice_drafts.invoice_was_deleted"
                                       )}
                                    </AlertTitle>
                                 </Alert>
                              </Box>
                           </AccordionDetails>
                        )}

                     {draft.status === "error" && (
                        <AccordionDetails>
                           <Box mb={3} width={"100%"}>
                              <Alert severity="error">
                                 <AlertTitle>
                                    {draft.validationError
                                       ? t(
                                            getErrorType("validationError")
                                               .title
                                         )
                                       : draft.draftBlocked
                                       ? t(getErrorType("draftBlocked").title)
                                       : t(getErrorType("error").title)}
                                 </AlertTitle>

                                 {draft.validationError && (
                                    <Typography
                                       variant={"body2"}
                                       style={{
                                          fontWeight: "normal",
                                       }}
                                    >
                                       {t(
                                          getErrorType("validationError")
                                             .message
                                       )}
                                    </Typography>
                                 )}

                                 {draft.draftBlocked && (
                                    <Typography
                                       variant={"body2"}
                                       style={{
                                          fontWeight: "normal",
                                       }}
                                    >
                                       {t(getErrorType("draftBlocked").message)}
                                    </Typography>
                                 )}

                                 <ul>
                                    {draft.violations.map((error, index) => {
                                       return (
                                          <li key={index}>
                                             {error.propertyPath && (
                                                <Typography
                                                   variant="subtitle2"
                                                   style={{
                                                      fontWeight: "bold",
                                                   }}
                                                   gutterBottom
                                                >
                                                   {error.propertyPath}
                                                </Typography>
                                             )}
                                             <Typography
                                                variant={
                                                   error.message
                                                      ? "body2"
                                                      : "subtitle2"
                                                }
                                                style={{
                                                   fontWeight: error.message
                                                      ? "normal"
                                                      : "bold",
                                                }}
                                             >
                                                {error.message}
                                             </Typography>
                                          </li>
                                       )
                                    })}
                                 </ul>
                              </Alert>

                              {invoice && (
                                 <Box
                                    width="100%"
                                    style={{
                                       display: "flex",
                                       justifyContent: "flex-end",
                                       marginTop: "10px",
                                    }}
                                 >
                                    <Button
                                       onClick={() => {
                                          dispatch(
                                             openDialog({
                                                id: "invoice.drafts.form",
                                                data: {
                                                   invoiceUuid: draft.uuid,
                                                   mode: "edit",
                                                },
                                             })
                                          )
                                       }}
                                       color="primary"
                                       variant="contained"
                                    >
                                       {t("global.fix")}
                                    </Button>
                                 </Box>
                              )}
                           </Box>
                        </AccordionDetails>
                     )}

                     {draft.status === "undefined" && (
                        <AccordionDetails>
                           <Typography>{draft.message}</Typography>
                        </AccordionDetails>
                     )}

                     {draft.status === "success" && (
                        <AccordionDetails>
                           <Typography>
                              {mode === "delete"
                                 ? t(
                                      "gov_it.invoice_drafts.invoice_draft_delete"
                                   )
                                 : t("gov_it.invoice_drafts.invoice_sent")}
                           </Typography>
                        </AccordionDetails>
                     )}
                  </Accordion>
               )
            })}
         </DialogContent>

         <DialogActions className={classes.buttons}>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const SenderOrDeleterInvoicesDialog = () => {
   return (
      <BaseDialog id="invoice.drafts.sentBlockInvoices" maxWidth="md" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default SenderOrDeleterInvoicesDialog

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
