import {
   ButtonGroup,
   CircularProgress,
   Divider,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import AssessmentIcon from "@material-ui/icons/Assessment"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import { CUSTOMER_INVOICE_TYPE, SUPPLIER_INVOICE_TYPE } from "config"
import { download } from "helper/download"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import {
   useLazyGetInvoicesReportQuery,
   useLazyGetInvoicesZipQuery,
} from "services/gov-it"
import { useAppSelector } from "store"
import {
   selectCurrentPage,
   selectItemsPerPage,
   selectTableFilters,
} from "store/tablesState"
import { getInvoiceQueryParams } from "../../helpers"

type DownloadZipCTAProps = {
   table: "customer.invoices" | "supplier.invoices"
}
const DownloadZipCTA = ({ table }: DownloadZipCTAProps) => {
   const { enqueueSnackbar } = useSnackbar()
   const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(
      null
   )
   const isMenuOpen = Boolean(anchorEl)
   const { t } = useTranslation()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const [getInvoicesReport, { isFetching: isDownloadingReport }] =
      useLazyGetInvoicesReportQuery()
   const [
      getInvoicesZipXMl,
      { isFetching: isDownloadingZipXMl, isError: isErrorXml, error: errorXml },
   ] = useLazyGetInvoicesZipQuery()
   const [
      getInvoicesZipPdf,
      { isFetching: isDownloadingZipPdf, isError: isErrorPdf, error: errorPdf },
   ] = useLazyGetInvoicesZipQuery()

   const invoiceType =
      table === "customer.invoices"
         ? CUSTOMER_INVOICE_TYPE
         : SUPPLIER_INVOICE_TYPE

   const handleButtonGroupMenuOpen = (event: any) =>
      setAnchorEl(event.currentTarget)

   const handleButtonGroupMenuClose = () => setAnchorEl(null)

   const handleButtonDownloadCSVReport = async () => {
      const response = await getInvoicesReport({
         ...getInvoiceQueryParams({
            filters,
            type: invoiceType,
            itemsPerPage: 5000,
         }),
      })

      if (response.data) {
         download(response.data, "report_" + currentPage + ".csv")
      }
   }

   const handleDownloadZip = async (withPdf: boolean) => {
      let response
      if (withPdf) {
         response = await getInvoicesZipPdf({
            ...getInvoiceQueryParams({
               filters,
               type: invoiceType,
               itemsPerPage,
               currentPage,
            }),
            printPdf: "true",
         })

         if (isErrorPdf) {
            enqueueSnackbar(errorPdf, { variant: "error" })
            return
         }
      } else {
         response = await getInvoicesZipXMl({
            ...getInvoiceQueryParams({
               filters,
               type: invoiceType,
               itemsPerPage,
               currentPage,
            }),
            printPdf: "false",
         })

         if (isErrorXml) {
            enqueueSnackbar(errorXml, { variant: "error" })
            return
         }
      }

      if (response.data) {
         const prefix =
            invoiceType === CUSTOMER_INVOICE_TYPE
               ? "customer_invoices_"
               : "supplier_invoices_"
         download(response.data, prefix + currentPage + ".zip")
      }
   }

   return (
      <>
         <ButtonGroup>
            <Button
               style={{ width: "max-content" }}
               disabled={isDownloadingZipXMl && isDownloadingZipPdf}
               aria-label="download zip"
               aria-controls="download-zip-menu"
               aria-haspopup="true"
               color="inherit"
               onClick={handleButtonGroupMenuOpen}
               startIcon={
                  isDownloadingZipPdf || isDownloadingZipXMl ? (
                     <CircularProgress size={20} />
                  ) : (
                     <CloudDownloadIcon />
                  )
               }
               component="button"
            >
               {isDownloadingZipXMl || isDownloadingZipPdf
                  ? t("global.downloading")
                  : t("global.download_zip")}
            </Button>
            <Button
               style={{ width: "max-content" }}
               title={"Download up to 5000 records in CSV format"}
               disabled={isDownloadingReport}
               aria-label="download CSV report"
               aria-controls="download-csv-report"
               color="inherit"
               onClick={handleButtonDownloadCSVReport}
               startIcon={
                  isDownloadingReport ? (
                     <CircularProgress size={20} />
                  ) : (
                     <AssessmentIcon />
                  )
               }
               component="button"
            >
               {isDownloadingReport
                  ? t("global.downloading")
                  : t("global.download_report")}
            </Button>
         </ButtonGroup>

         <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id="download-zip-menu"
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleButtonGroupMenuClose}
         >
            <MenuItem
               key={"download_zip_xml"}
               disabled={isDownloadingZipXMl}
               onClick={() => {
                  handleDownloadZip(false)
                  handleButtonGroupMenuClose()
               }}
            >
               <ListItemIcon>
                  {isDownloadingZipXMl ? (
                     <CircularProgress size={20} />
                  ) : (
                     <CloudDownloadIcon />
                  )}
               </ListItemIcon>
               <ListItemText
                  primary={
                     isDownloadingZipXMl
                        ? t("CustomerInvoices.Downloading_ZIP_XML")
                        : t("global.download_zip_xml")
                  }
               />
            </MenuItem>

            <Divider />

            <MenuItem
               disabled={isDownloadingZipPdf}
               key={"download_zip_xml_pdf"}
               onClick={() => {
                  handleDownloadZip(true)
                  handleButtonGroupMenuClose()
               }}
            >
               <ListItemIcon>
                  {isDownloadingZipPdf ? (
                     <CircularProgress size={20} />
                  ) : (
                     <CloudDownloadIcon />
                  )}
               </ListItemIcon>
               <ListItemText
                  primary={
                     isDownloadingZipPdf
                        ? t("CustomerInvoices.Downloading_ZIP_PDF")
                        : t("global.download_zip_pdf")
                  }
               />
            </MenuItem>
         </Menu>
      </>
   )
}

export default DownloadZipCTA
