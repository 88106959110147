import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import { GovITInvoicePreservationDocument, Notification } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppSelector } from "store"
import { selectHasPid, selectHasRole } from "store/auth"
import { TableName } from "store/tablesState"
import TabPanelActions from "views/common/InvoiceDetails/tab/TabPanelActions"
import TabPanelInfo from "views/common/InvoiceDetails/tab/TabPanelInfo"
import TabPanelJson from "views/common/InvoiceDetails/tab/TabPanelJson"
import TabPanelPreservation from "views/common/InvoiceDetails/tab/TabPanelPreservation"
import TabPanelPreview from "views/common/InvoiceDetails/tab/TabPanelPreview"
import TabPanelXml from "views/common/InvoiceDetails/tab/TabPanelXml"
import TabPanelAttachments from "./tab/TabPanelAttachments"

const tabNameToIndex = {
   info: 0,
   attachments: 1,
   preservations: 2,
   json: 3,
   xml: 4,
   preview: 5,
   actions: 6,
}
type IndexToTabNameType = (typeof tabNameToIndex)[keyof typeof tabNameToIndex]
const TabsAllowedForPidRoleConfig = ["actions"]

type Props = {
   mode:
      | "info"
      | "attachments"
      | "preservations"
      | "json"
      | "xml"
      | "preview"
      | "actions"
   invoice: GovITInvoice
   isAllowedToFixInvoice?: boolean
   notifications: Notification[] | null
   table: TableName
   preservedDocument: GovITInvoicePreservationDocument | null
}
export default function InvoiceDialogTabs({
   mode,
   invoice,
   notifications,
   table,
   preservedDocument,
   isAllowedToFixInvoice,
}: Props) {
   const classes = useStyles()
   const userHasPid = useAppSelector((state) => selectHasPid(state))
   const hasRoleConfig = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_CONFIG",
      })
   )
   const [value, setValue] = React.useState<IndexToTabNameType>(
      !userHasPid && hasRoleConfig
         ? tabNameToIndex[mode in tabNameToIndex ? mode : "info"]
         : TabsAllowedForPidRoleConfig.includes(mode)
         ? tabNameToIndex.info
         : tabNameToIndex[mode in tabNameToIndex ? mode : "info"]
   )

   const handleChange = (_event: any, newValue: number) => {
      setValue(newValue)
   }

   const a11yProps = (index: number) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
         >
            <Tab label="info" {...a11yProps(tabNameToIndex.info)} />
            <Tab
               label="attachments"
               {...a11yProps(tabNameToIndex.attachments)}
            />
            <Tab
               label="preservation"
               {...a11yProps(tabNameToIndex.preservations)}
            />
            <Tab label="json" {...a11yProps(tabNameToIndex.json)} />
            <Tab label="xml" {...a11yProps(tabNameToIndex.xml)} />
            <Tab label="preview" {...a11yProps(tabNameToIndex.preview)} />
            {!userHasPid && hasRoleConfig && (
               <Tab label="actions" {...a11yProps(tabNameToIndex.actions)} />
            )}
         </Tabs>

         <TabPanelInfo
            invoice={invoice}
            isAllowedToFixInvoice={isAllowedToFixInvoice}
            value={value}
            index={tabNameToIndex.info}
            notifications={notifications}
         />
         <TabPanelAttachments
            invoice={invoice}
            value={value}
            index={tabNameToIndex.attachments}
         />
         <TabPanelPreservation
            invoice={invoice}
            value={value}
            index={tabNameToIndex.preservations}
            table={table}
            preservedDocument={preservedDocument}
         />
         <TabPanelJson
            invoice={invoice}
            value={value}
            index={tabNameToIndex.json}
         />
         <TabPanelXml
            invoice={invoice}
            value={value}
            index={tabNameToIndex.xml}
         />
         <TabPanelPreview
            invoice={invoice}
            value={value}
            index={tabNameToIndex.preview}
         />
         {!userHasPid && hasRoleConfig && (
            <TabPanelActions
               table={table}
               invoice={invoice}
               value={value}
               index={tabNameToIndex.actions}
               preservedDocument={preservedDocument}
            />
         )}
      </Paper>
   )
}

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})
