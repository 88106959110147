import { Box } from "@material-ui/core"
import { TableName } from "store/tablesState"
import CheckedActionsButtons from "../../../components/ActionsCheckedFooterTableButtons/components/CheckedActionsButtons"
import DownloadZipCTA from "./DownloadZipCTA"

type ActionsType = {
   uuid: string | null
   checkedColumns: string[]
   table: TableName
}
const ActionsTableCTAs = ({ uuid, checkedColumns, table }: ActionsType) => {
   return uuid || uuid === "" ? (
      <Box
         style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
         }}
      >
         <DownloadZipCTA
            table={table as "customer.invoices" | "supplier.invoices"}
         />
         {checkedColumns.length > 0 && <CheckedActionsButtons table={table} />}
      </Box>
   ) : null
}

export default ActionsTableCTAs
