import {
   Box,
   Button,
   DialogContent,
   DialogTitle,
   makeStyles,
   Typography,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { DialogId, DialogState } from "store/slices/dialogs"

const DIALOG_ID: DialogId = "pl_send_invoice_demo_error"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ title, detail, handleClose }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <DialogTitle>
            <Typography variant="h1">{title}</Typography>
         </DialogTitle>

         <DialogContent className={classes.content}>
            <Typography variant="h4">{detail}</Typography>

            <Box display="flex" justifyContent="center" mt={3}>
               <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleClose}
               >
                  {t("global.ok")}
               </Button>
            </Box>
         </DialogContent>
      </>
   )
}

const SingleInvoiceErrorDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {({ ...dialogData }) => <Content {...dialogData} />}
   </BaseDialog>
)

export default SingleInvoiceErrorDialog
