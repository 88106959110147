import { Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import { openDialog } from "store/slices/dialogs"

const ActionsButtons = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const isAllowedToUploadFile = useAppSelector(
      (state) =>
         (selectHasRole(state, {
            context: "it.api.acubeapi.com",
            role: "ROLE_ADMIN",
         }) ||
            selectHasRole(state, {
               context: "it.api.acubeapi.com",
               role: "ROLE_TRANSFER",
            })) &&
         (selectHasRole(state, {
            context: "transfer.acubeapi.com",
            role: "ROLE_ADMIN",
         }) ||
            selectHasRole(state, {
               context: "transfer.acubeapi.com",
               role: "ROLE_API",
            }))
   )

   return (
      <Grid
         container
         style={{
            marginBottom: "10px",
         }}
      >
         <Grid
            item
            xs={12}
            md={6}
            style={{
               padding: "5px",
            }}
         >
            {isAllowedToUploadFile && (
               <Button
                  onClick={() => {
                     dispatch(
                        openDialog({
                           id: "gov_it_invoice_transfers.upload",
                        })
                     )
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
               >
                  {t("transfer.upload")}
               </Button>
            )}
         </Grid>
         <Grid item xs={false} md={6} />
      </Grid>
   )
}

export default ActionsButtons
