import DialogContent from "@material-ui/core/DialogContent"
import BaseDialog from "components/BaseDialog"
import CreateInvoiceTabs from "./CreateInvoiceTabs"

type ContentProps = {
   handleClose: () => void
}
export type ViolationType = {
   propertyPath: string
   message: string
}
const Content = ({ handleClose }: ContentProps) => {
   return (
      <>
         <DialogContent>
            <CreateInvoiceTabs handleClose={handleClose} />
         </DialogContent>
      </>
   )
}

const CreateInvoiceDialog = () => {
   return (
      <BaseDialog id="customer.invoices.new" maxWidth="xl" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default CreateInvoiceDialog
