import Box from "@material-ui/core/Box"

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import { Marking } from "../../Marking"

import { useTranslation } from "react-i18next"

function OrderResponses(props) {
   const responses = props.responses
   const { t } = useTranslation()

   return (
      <TableContainer component={Paper}>
         <Table aria-label="simple table">
            <TableHead>
               <TableRow>
                  <TableCell>{t("OrderResponses.Number")}</TableCell>
                  <TableCell>{t("OrderResponses.Date")}</TableCell>
                  <TableCell align="right">
                     {t("OrderResponses.Document Type")}
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {responses.map((orderResponse) => (
                  <TableRow key={orderResponse.uuid}>
                     <TableCell component="th" scope="row">
                        {orderResponse.number}
                     </TableCell>
                     <TableCell>{orderResponse.date}</TableCell>
                     <TableCell align="right">
                        <Marking
                           marking={
                              orderResponse.processInformation?.documentType
                           }
                        />
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   )
}

export default function TabResponses(props) {
   const { value, index, responses } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && responses.length > 0 && (
            <Box p={3}>
               <OrderResponses responses={responses} />
            </Box>
         )}
      </div>
   )
}
