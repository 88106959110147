import { Box, Button, TextField, Typography } from "@material-ui/core"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useCreateTokenMutation } from "services/gov-pl/onboard"
import { useAppDispatch } from "store"
import { setOnboardMode2CurrentStep } from "store/slices/onboard"
import * as Yup from "yup"

const name: keyof FormValues = "authorizationToken"

type FormValues = typeof initialValues

const initialValues = {
   authorizationToken: "",
}

const validationSchema = Yup.object().shape({
   authorizationToken: Yup.string().required(),
})

const TokenStep = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const { legalEntityUuid } = useParams()
   const [createToken] = useCreateTokenMutation()

   const handleTokenSubmit = async ({ authorizationToken }: FormValues) => {
      const response = await createToken({
         uuid: legalEntityUuid!,
         authorizationToken,
         autoSelect: true,
      })

      if ("error" in response) {
         enqueueSnackbar(t("onboard.error.generic"), { variant: "error" })
         return
      }

      dispatch(setOnboardMode2CurrentStep("end"))
   }

   return (
      <Box px={2} py={3}>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleTokenSubmit}
         >
            {({
               values,
               errors,
               touched,
               handleBlur,
               handleChange,
               handleSubmit,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="column" gridGap={16}>
                     <Typography variant="body1">
                        {t("onboard.mode_2.body_1")}
                     </Typography>

                     <TextField
                        error={touched[name] != null && errors[name] != null}
                        fullWidth
                        helperText={errors[name]}
                        label={t("onboard.mode_2.token_field")}
                        margin="none"
                        name={name}
                        value={values[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                     />

                     <Box textAlign="right">
                        <Button
                           color="primary"
                           size="large"
                           type="submit"
                           variant="contained"
                        >
                           {t("onboard.mode_2.cta")}
                        </Button>
                     </Box>
                  </Box>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default TokenStep
