import { IconButton, Tooltip } from "@material-ui/core"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import { selectTableData, TableName, updateData } from "store/tablesState"

const table: TableName = "openbanking.business_registries"

type Props = {
   fiscalId: string
}

const AccountsCTA = ({ fiscalId }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const { expandedRows } = useAppSelector((state) =>
      selectTableData(state, { table })
   )

   const isExpanded = expandedRows.includes(fiscalId)

   const toggleExpand = () => {
      const updatedExpandedRows = isExpanded
         ? expandedRows.filter((id) => id !== fiscalId)
         : [...expandedRows, fiscalId]
      dispatch(
         updateData({
            table,
            param: "expandedRows",
            value: updatedExpandedRows,
         })
      )
   }

   return (
      <Tooltip title={t("openbanking.business_registry.accounts_cta")}>
         <IconButton
            onClick={toggleExpand}
            style={{
               transform: isExpanded ? "rotate(90deg)" : "rotate(0)",
               transition: "transform 200ms ease",
            }}
         >
            <KeyboardArrowRightIcon />
         </IconButton>
      </Tooltip>
   )
}

export default AccountsCTA
