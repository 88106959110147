import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useSendAccessCodeMutation } from "services/gov-pl/invoices"

export const fixedCacheKey = "send-access-code"

const SendAccessCodeCTA = () => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const { invoiceUuid } = useParams()

   const [sendAccessCode, { isLoading }] = useSendAccessCodeMutation({
      fixedCacheKey,
   })

   const handleSendAccessCode = async () => {
      const response = await sendAccessCode({
         uuid: invoiceUuid!,
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }
   }

   return (
      <>
         <Typography variant="body1" style={{ textAlign: "center" }}>
            {t("pl.public_invoice.request_code_text")}
         </Typography>

         <Box display="flex" justifyContent="center" mt={2}>
            <Button
               color="primary"
               disabled={isLoading}
               size="large"
               type="button"
               variant="contained"
               onClick={handleSendAccessCode}
            >
               {isLoading ? (
                  <Box display="flex" mr={1}>
                     <CircularProgress size={24} color="inherit" />
                  </Box>
               ) : (
                  t("pl.public_invoice.request_code_cta")
               )}
            </Button>
         </Box>
      </>
   )
}

export default SendAccessCodeCTA
