import { ButtonGroup, Tooltip } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { format, parseISO } from "date-fns"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { download } from "../../../../helper/download"
import { getReceiptPdf } from "../../../../services/client/request/getReceiptPdf"
import CustomTable from "../../CustomTable"
import Label from "../../Label"
import { LabelOutcome } from "../../LabelOutcome"

function ExpenseReceipts(props) {
   const pagination = 30
   const token = useAppSelector(selectAuthToken)
   const [page, setPage] = React.useState(1)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const { t } = useTranslation()

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleDownloadPDF = async (expenseId, protocolId, fileName) => {
      setIsLoading(true)
      setError(false)

      try {
         const response = await getReceiptPdf(token, expenseId, protocolId)
         download(response, fileName)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   return (
      <CustomTable
         columns={columns}
         rows={props.receipts}
         page={page}
         pagination={pagination}
         handleChangePage={handleChangePage}
         handleDownloadPDF={handleDownloadPDF}
      />
   )
}

export default function TabPanelReceipts(props) {
   const { value, index, receipts } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               <ExpenseReceipts receipts={receipts} />
            </Box>
         )}
      </div>
   )
}

const columns = [
   {
      id: "protocol",
      label: "ExpenseReceipts.Protocol",
      numeric: false,
      disablePadding: false,
   },
   {
      id: "date",
      label: "ExpenseReceipts.Date",
      numeric: false,
      disablePadding: true,
      format: (value) => {
         return format(parseISO(value), "dd MMM yyyy HH:mm:ss")
      },
   },
   {
      id: "description",
      label: "ExpenseReceipts.Description",
      numeric: false,
      disablePadding: true,
   },
   {
      id: "outcome",
      label: "ExpenseReceipts.Outcome",
      numeric: false,
      disablePadding: false,
      format: (value) => {
         return <LabelOutcome value={value} />
      },
   },
   {
      id: "operation_type",
      label: "ExpenseReceipts.Operation Type",
      numeric: false,
      disablePadding: true,
      format: (value) => {
         return (
            <>
               <Label color={"primary"}>{value}</Label>
            </>
         )
      },
   },
   {
      empty: true,
      align: "right",
      format: (value, props, item) => {
         return (
            <ButtonGroup>
               {item.protocol && (
                  <Tooltip title="Receipt PDF">
                     <IconButton
                        onClick={() => {
                           props.handleDownloadPDF(
                              item.expense_id,
                              item.protocol,
                              item.protocol + ".pdf"
                           )
                        }}
                     >
                        <PictureAsPdfIcon />
                     </IconButton>
                  </Tooltip>
               )}
            </ButtonGroup>
         )
      },
   },
]
