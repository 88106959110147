import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
   SmartReceipt,
   SmartReceiptDetails,
} from "services/gov-it/smartReceipt/type"
import { SmartReceiptDetailsMode } from "store/slices/dialogs"
import TabPanelInfo from "./tabs/TabPanelInfo"
import TabPanelReceiptDetails from "./tabs/TabPanelReceiptDetails"

type SmartReceiptDetailsTabsProps = {
   item?: SmartReceipt
   mode?: SmartReceiptDetailsMode
   itemDetails?: SmartReceiptDetails
}

type TabNameToIndexType = {
   info: number
   receipt_details: number
}
const tabNameToIndex: TabNameToIndexType = {
   info: 0,
   receipt_details: 1,
}

type IndexToTabNameType = (typeof tabNameToIndex)[keyof typeof tabNameToIndex]

const SmartReceiptDetailsTabs = ({
   item,
   mode,
   itemDetails,
}: SmartReceiptDetailsTabsProps) => {
   const classes = useStyles()
   const { t } = useTranslation()

   const [value, setValue] = useState<IndexToTabNameType>(
      mode && mode in tabNameToIndex
         ? tabNameToIndex[mode]
         : tabNameToIndex["info"]
   )

   const handleChange = (_event: any, newValue: number) => {
      setValue(newValue)
   }

   const a11yProps = (index: number) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
         >
            <Tab label="info" {...a11yProps(tabNameToIndex.info)} />
            {itemDetails && (
               <Tab
                  label={t("smartReceipt.receipt_details")}
                  {...a11yProps(tabNameToIndex.receipt_details)}
               />
            )}
         </Tabs>

         {item && (
            <TabPanelInfo
               item={item}
               value={value}
               index={tabNameToIndex.info}
            />
         )}

         {itemDetails && (
            <TabPanelReceiptDetails
               item={itemDetails}
               value={value}
               index={tabNameToIndex.receipt_details}
            />
         )}
      </Paper>
   )
}

export default SmartReceiptDetailsTabs

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})
