import { Grid, makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import { Formik } from "formik"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { Text } from "../formik/Text"

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

function ChangePasswordForm(props, { className, ...rest }) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values,
      { resetForm, setErrors, setSubmitting }
   ) => {
      await props.handleSave(values)
   }

   const validationSchema = Yup.object().shape({
      password: Yup.string().min(8).required("Password is required"),
      password_confirm: Yup.string().oneOf(
         [Yup.ref("password"), null],
         "Passwords must match"
      ),
   })

   return (
      <Formik
         initialValues={{
            password: null,
            password_confirm: null,
         }}
         // validationSchema={validationSchema}
         onSubmit={handleSubmit}
      >
         {({ handleSubmit }) => (
            <form
               onSubmit={handleSubmit}
               noValidate
               className={clsx(classes.root, className)}
            >
               <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                     <Text
                        name={"password"}
                        type={"password"}
                        label={t("User.Password")}
                     />
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <Text
                        name={"password_confirm"}
                        type={"password"}
                        label={t("User.Conferma Password")}
                     />
                  </Grid>
               </Grid>

               <div className={classes.buttons}>
                  <Button
                     type="submit"
                     color="primary"
                     variant="contained"
                     disabled={props.isLoading}
                  >
                     {t("Default.Conferma")}
                  </Button>
                  <Button
                     onClick={props.handleClose}
                     color="default"
                     variant="contained"
                  >
                     {t("Default.Annulla")}
                  </Button>
               </div>
            </form>
         )}
      </Formik>
   )
}

ChangePasswordForm.propTypes = {
   className: PropTypes.string,
}

export default ChangePasswordForm
