import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import React from "react"
import { router } from "router"
import { PreSaleAction, SubAccount } from "services/commonApi"
import { GovITInvoiceTransfer } from "services/gov-it"
import { SmartReceipt } from "services/gov-it/smartReceipt/type"
import { GovITInvoice } from "services/gov-it/types"
import { Invoice as PlInvoice } from "services/gov-pl/invoices"
import { GovPLLegalEntity } from "services/gov-pl/legalEntities"
import { OpenbankingAccount } from "services/openbankingApi/accounts"
import { OpenbankingBusinessRegistry } from "services/openbankingApi/businessRegistry"
import { OpenbankingWebhook } from "services/openbankingApi/webhooks"
import { PeppolLegalEntity } from "services/peppol/legalEntities"
import { PreservationDocument } from "services/preservation"
import { RootState } from "store"
import { listenerMiddleware } from "store/listener"

let currentDialogURLParams: string[] | null = null
export type InvoiceDraftsFormMode = "new" | "edit" | "send"
export type SmartReceiptDetailsMode = "info" | "receipt_details"

export type DialogState =
   | {
        id: "confirmation_dialog"
        data: {
           message: string
        }
     }
   | {
        id: "impersonate_user"
        data?: null
     }
   | {
        id: "settings_new_pre_sale_action"
        data?: null
     }
   | {
        id: "settings_edit_pre_sale_action"
        data: PreSaleAction
     }
   | {
        id: "peppol_new_legal_entity"
        data?: null
     }
   | {
        id: "peppol_edit_legal_entity"
        data: PeppolLegalEntity
     }
   | {
        id: "openbanking_new_business_registry"
        data?: null
     }
   | {
        id: "openbanking_edit_business_registry"
        data: OpenbankingBusinessRegistry
     }
   | {
        id: "openbanking_connect_success"
        data: {
           connectUrl: string
        }
     }
   | {
        id: "openbanking_account_details"
        data: OpenbankingAccount
     }
   | {
        id: "openbanking_receive_payment"
        data: {
           accountUuid: string
        }
     }
   | {
        id: "openbanking_new_webhook"
        data?: null
     }
   | {
        id: "openbanking_edit_webhook"
        data: OpenbankingWebhook
     }
   | {
        id: "openbanking_subscription_details"
        data?: {
           fiscalId: string
        }
     }
   | {
        id: "gov_it_invoice_transfers"
        data: GovITInvoiceTransfer
     }
   | {
        id: "gov_it_invoice_transfers.upload"
        data?: null
     }
   | {
        id: "gov_it_invoice_transfer_violations_details"
        data: {
           violations: string
        }
     }
   | {
        id: "gov_it_invoices_recover"
        data: {
           fiscalId: string
        }
     }
   | {
        id: "pl_send_invoice_demo_error"
        data: {
           title: string
           detail: string
        }
     }
   | {
        id: "pl_invoice_details"
        data: PlInvoice
     }
   | {
        id: "gov_pl_new_legal_entity"
        data?: null
     }
   | {
        id: "gov_pl_edit_legal_entity"
        data: GovPLLegalEntity
     }
   | {
        id: "gov_pl_legal_entity_details"
        data: GovPLLegalEntity
     }
   | {
        id: "legal_entity_onboard_init_success"
        data: {
           url: string
        }
     }
   | {
        id: "gov_it_invoices_details"
        data: {
           invoiceUuid: string
           mode:
              | "info"
              | "attachments"
              | "preservations"
              | "json"
              | "xml"
              | "preview"
              | "actions"
        }
     }
   | {
        id: "common_sub_account.edit"
        data: {
           subaccount: SubAccount
        }
     }
   | {
        id: "common_sub_account.new"
        data?: null
     }
   | {
        id: "common_sub_account.change_password"
        data: {
           email: string
        }
     }
   | {
        id: "preservation.document_details"
        data: {
           item: PreservationDocument
        }
     }
   | {
        id: "customer.invoices.new"
        data?: null
     }
   | {
        id: "customer.invoices.import_invoice"
        data?: null
     }
   | {
        id: "supplier.invoices.import_invoice"
        data?: null
     }
   | {
        id: "invoice.drafts.form"
        data: {
           invoiceUuid?: string
           mode: InvoiceDraftsFormMode
        }
     }
   | {
        id: "alert.modal"
        data: {
           title: string
           message: string
           children: React.ReactNode
           type: "error" | "warning" | "info" | "success"
        }
     }
   | {
        id: "api.configuration.form"
        data: {
           uuid: string
        }
     }
   | {
        id: "invoice.drafts.sentBlockInvoices"
        data: {
           invoices: GovITInvoice[]
        } & (
           | {
                mode: "view"
             }
           | {
                uuids: string[]
                mode: "delete" | "send"
             }
        )
     }
   | {
        id: "business.registry.configurations"
        data: {
           fiscal_id: string | null
           mode?: "stats"
        }
     }
   | {
        id: "smartReceipt.form"
        data:
           | {
                mode: "new"
             }
           | {
                mode: "return"
                item: SmartReceipt
             }
     }
   | {
        id: "smartReceipt.details"
        data: {
           uuid: string
           mode: SmartReceiptDetailsMode
        }
     }
   | {
        id: "invoiceSentCreditNote"
        data: {
           invoiceUuid: string
        }
     }
   | {
        id: "govItSetCredentialsBusinessRegistryConfiguration"
        data: {
           id: string
        }
     }
   | {
        id: "govItUpdateSettingsRelatesToSmartReceipt"
        data: {
           id: string
        }
     }
   | {
        id: "settingsAccountConnectBusinessRegistry"
        data: {
           email: string
        }
     }

export type DialogId = DialogState["id"]

type State = {
   activeDialog: DialogState | null
}

const initialState: State = {
   activeDialog: null,
}

export const dialogsSlice = createSlice({
   name: "dialogs",
   initialState,
   reducers: {
      openDialog: (
         state,
         {
            payload,
         }: PayloadAction<Exclude<DialogState, { id: "confirmation_dialog" }>>
      ) => {
         state.activeDialog = payload
      },

      closeDialog: (state) => {
         state.activeDialog = null
      },

      confirm: () => {},

      cancel: () => {},
   },
   extraReducers: (builder) => {
      builder.addCase(openConfirmationDialog.pending, (state, { meta }) => {
         state.activeDialog = {
            id: "confirmation_dialog",
            data: {
               message: meta.arg.message,
            },
         }
      })
   },
})

export const { name, reducer } = dialogsSlice
export const { openDialog, closeDialog, confirm, cancel } = dialogsSlice.actions

const selectDialogs = ({ dialogs }: RootState) => dialogs

export const selectIsDialogOpen = (state: RootState, id: DialogId) =>
   selectDialogs(state).activeDialog?.id === id

export const selectActiveDialog = (state: RootState) =>
   selectDialogs(state).activeDialog

// Append the dialog data to URL to allow to open dialogs as permalinks,
// e.g. `?dialogId=gov_id_invoice_details&invoiceUuid=123`
const ALLOWED_DIALOGS = ["gov_it_invoices_details", "smartReceipt.details"]

listenerMiddleware.startListening({
   actionCreator: openDialog,
   effect: ({ payload }) => {
      // Only handle this dialog, need to check if can be extended to all dialogs
      if (!ALLOWED_DIALOGS.includes(payload.id)) {
         return
      }

      if (currentDialogURLParams != null) {
         return
      }

      // Save for later removal
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      currentDialogURLParams = Object.keys(payload.data ?? {})

      const searchParams = new URLSearchParams(router.state.location.search)
      searchParams.set("dialogId", payload.id)

      for (const [key, value] of Object.entries(payload.data ?? {})) {
         if (value) {
            searchParams.set(
               key,
               typeof value === "number" || typeof value === "string"
                  ? value.toString()
                  : JSON.stringify(value)
            )
         }
      }

      router.navigate(`?${searchParams.toString()}`, { replace: true })
   },
})

// Remove dialog data from URL
listenerMiddleware.startListening({
   actionCreator: closeDialog,
   effect: () => {
      if (currentDialogURLParams == null) {
         return
      }

      const searchParams = new URLSearchParams(router.state.location.search)
      searchParams.delete("dialogId")

      for (const param of currentDialogURLParams) {
         searchParams.delete(param)
      }

      const queryString = searchParams.toString()

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      currentDialogURLParams = null

      router.navigate(queryString !== "" ? `?${queryString}` : "")
   },
})

export const openConfirmationDialog = createAsyncThunk(
   "openConfirmationDialog",
   (_: Extract<DialogState, { id: "confirmation_dialog" }>["data"]) =>
      new Promise<boolean>((resolve) => {
         const confirmEffect = () => {
            resolve(true)

            listenerMiddleware.stopListening({
               actionCreator: cancel,
               effect: cancelEffect,
            })
            listenerMiddleware.stopListening({
               actionCreator: confirm,
               effect: confirmEffect,
            })
         }
         const cancelEffect = () => {
            resolve(false)

            listenerMiddleware.stopListening({
               actionCreator: cancel,
               effect: cancelEffect,
            })
            listenerMiddleware.stopListening({
               actionCreator: confirm,
               effect: confirmEffect,
            })
         }

         listenerMiddleware.startListening({
            actionCreator: confirm,
            effect: confirmEffect,
         })

         listenerMiddleware.startListening({
            actionCreator: cancel,
            effect: cancelEffect,
         })
      })
)
