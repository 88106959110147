import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import moment from "moment/moment"
import { useEffect } from "react"
import {
   PreservationDocument,
   PreservationDocumentStatus,
   PreservationDocumentType,
   useGetPreservationDocumentsQuery,
} from "services/preservation"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import { Status } from "views/Preservation/Documents/components/Status"
import RowCTAs from "views/Preservation/Documents/Table/RowCTAs"

const table: TableName = "preservation.documents"
export type PreservationDocumentsTableConfigurableColumns = NonNullable<
   (typeof columnsDefinition)[number]["rowKey"]
>

const PreservationDocumentsTable = () => {
   const dispatch = useAppDispatch()
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const { data, isFetching, isError } = useGetPreservationDocumentsQuery(
      {
         documentType: filters.documentType as PreservationDocumentType,
         ...(filters.status === null ? {} : { status: filters.status }),
         ...(filters.createdAfter === null
            ? {}
            : { "created_at[after]": filters.createdAfter }),
         ...(filters.createdBefore === null
            ? {}
            : { "created_at[before]": filters.createdBefore }),
         ...(filters.createdStrictlyAfter === null
            ? {}
            : { "created_at[strictly_after]": filters.createdStrictlyAfter }),
         ...(filters.createdStrictlyBefore === null
            ? {}
            : { "created_at[strictly_before]": filters.createdStrictlyBefore }),
         ...(itemsPerPage === null ? {} : { itemsPerPage }),
         ...(currentPage === null ? {} : { page: currentPage }),
      },
      { skip: filters.documentType == null }
   )

   const items =
      filters.documentType != null ? data?.["hydra:member"] : undefined
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="Preservation.documents.table.no_data"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
         />
      </Paper>
   )
}

export default PreservationDocumentsTable

const columnsDefinition: TableColumn<PreservationDocument>[] = [
   {
      rowKey: "uuid",
      labelTranslationId: "Preservation.documents.table.uuid",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "preserved_object_id",
      labelTranslationId: "Preservation.documents.table.preserved_object_id",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "error_message",
      labelTranslationId: "Preservation.documents.table.error_message",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "created_at",
      labelTranslationId: "Preservation.documents.table.created_at",
      format: (value) => moment(value as string).format("DD MMM YYYY HH:mm:ss"),
      configurable: false,
   },
   {
      rowKey: "updated_at",
      labelTranslationId: "Preservation.documents.table.updated_at",
      format: (value) =>
         value ? moment(value as string).format("DD MMM YYYY HH:mm:ss") : "",
      configurable: true,
   },
   {
      rowKey: "original_filename",
      labelTranslationId: "Preservation.documents.table.original_filename",
      format: (value) => value,
      configurable: false,
   },
   {
      rowKey: "status",
      labelTranslationId: "Preservation.documents.table.status",
      format: (value) => (
         <Status status={value as PreservationDocumentStatus} />
      ),
      configurable: false,
   },
   {
      rowKey: null,
      labelTranslationId: null,
      format: (value, item) => <RowCTAs item={item} />,
      configurable: false,
   },
]
