import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useTranslation } from "react-i18next"

export function CustomTableHead(props) {
   const { t } = useTranslation()

   const createSortHandler = (property) => (event) =>
      props.onRequestSort(event, property)

   return (
      <TableHead>
         <TableRow className={props.classes.head}>
            {props.columns.map((column) => {
               return column.empty === true ? (
                  <TableCell key={"table-head-cell-" + column.id} />
               ) : (
                  <TableCell
                     key={"table-head-cell-" + column.id}
                     align={"left"}
                     padding={column.disablePadding ? "none" : "default"}
                     sortDirection={
                        props.orderBy === column.id ? props.order : false
                     }
                  >
                     {t(column.label)}

                     {/* <TableSortLabel
                  active={props.orderBy === column.id}
                  direction={props.orderBy === column.id ? props.order : 'asc'}
                  onClick={createSortHandler(column.id)}>
                  {t(column.label)}
                </TableSortLabel> */}
                  </TableCell>
               )
            })}
         </TableRow>
      </TableHead>
   )
}
