import { IconButton, makeStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles"
import ErrorIcon from "@material-ui/icons/Error"
import { ClassNameMap } from "@material-ui/styles"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

//@ts-ignore
const useStyles = makeStyles((theme) => ({
   root: {
      alignItems: "center",
      borderRadius: 2,
      cursor: "default",
      display: "inline-flex",
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 15,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
      whiteSpace: "nowrap",
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.08),
   },
}))

type Props =
   | {
        isFilter: false
        violations: string | null
     }
   | {
        isFilter: true
        withViolations: boolean | null
     }
const ViolationMarking = (props: Props) => {
   const classes: ClassNameMap<any> = useStyles()
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const getColor = () => {
      switch (props?.isFilter) {
         case true:
            if (props?.withViolations === null) return
            return props?.withViolations ? "error" : "success"
         case false:
            return props?.violations === null ? "success" : null
      }
   }

   const getContent = () => {
      switch (props?.isFilter) {
         case true:
            if (props?.withViolations === null) return <></>
            return props?.withViolations
               ? t("gov_it.invoice_transfers.filters.with_violations")
               : t("gov_it.invoice_transfers.filters.without_violations")
         case false:
            return props?.violations === null ? (
               t("global.ok")
            ) : (
               <IconButton
                  style={{
                     padding: 0,
                     margin: 0,
                  }}
                  onClick={() => {
                     dispatch(
                        openDialog({
                           id: "gov_it_invoice_transfer_violations_details",
                           data: {
                              violations: props?.violations as string,
                           },
                        })
                     )
                  }}
               >
                  <ErrorIcon color="error" />
               </IconButton>
            )
      }
   }

   const color = getColor()

   return (
      <span
         className={clsx(classes.root, {
            ...(color ? { [classes[color]]: color } : {}),
         })}
      >
         {getContent()}
      </span>
   )
}

export default ViolationMarking
