import { Divider, Grid, Paper } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { format, parseISO } from "date-fns"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { postInvoiceNotify } from "services/client/request/postInvoiceNotify"
import {
   GovITInvoicePreservationDocument,
   useLazyGetInvoicePreservationDocumentQuery,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { selectRefetch, TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import InvoicePreservedDocumentButton, {
   BoxAlert,
} from "views/common/InvoiceDetails/invoicePreservedDocumentButton"
import LabelYesNo from "views/common/LabelYesNo"
import ConfirmationDialog from "../../ConfirmationDialog"

type InvoiceActionsProps = {
   invoice: GovITInvoice
   table: TableName
   preservedDocument: GovITInvoicePreservationDocument | null
}
function InvoiceActions({
   invoice,
   table,
   preservedDocument,
}: InvoiceActionsProps) {
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const { enqueueSnackbar } = useSnackbar()
   const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] =
      useState(false)
   const [confirmationType, setConfirmationType] = React.useState<
      string | null
   >(null)

   const [getPreservedDocument, { error }] =
      useLazyGetInvoicePreservationDocumentQuery()
   const [open, setOpen] = useState(false)
   const dispatch = useAppDispatch()
   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const handlePreservedDocument = async () => {
      const response = await getPreservedDocument({
         invoiceUuid: invoice.uuid,
         ...(refresh && { refresh }),
      })

      if ("error" in response) {
         enqueueSnackbar(error, { variant: "error" })
         return
      }

      if ("data" in response && response.data) {
         persistTableRefresh(table)
         setOpen(true)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   function handleInvoiceConfirmationOpen() {
      setConfirmationType("invoice")
      setConfirmationDialogIsOpen(true)
   }

   function handleNotificationConfirmationOpen() {
      setConfirmationType("notification")
      setConfirmationDialogIsOpen(true)
   }

   function handleConfirmation() {
      postInvoiceNotify(
         token,
         invoice.uuid,
         confirmationType === "notification"
      )
         .then(function () {
            enqueueSnackbar("Message sent successfully", { variant: "success" })
         })
         .catch(function () {
            enqueueSnackbar("Error sending message", { variant: "error" })
         })

      setConfirmationDialogIsOpen(false)
   }

   function handleConfirmationCancel() {
      setConfirmationDialogIsOpen(false)
   }

   return (
      <Paper>
         <Box p={2}>
            <Typography variant="h4">
               {t("InvoiceDetail.Integration actions")}
            </Typography>
            <Typography variant="subtitle1">
               {t("InvoiceDetail.Send data to your configured webhooks")}
            </Typography>
            <Typography variant="body1">
               {t("InvoiceDetail.Downloaded")}{" "}
               <LabelYesNo value={invoice?.downloaded} />
            </Typography>
            {invoice?.downloaded && (
               <Typography variant="body1">
                  {t("InvoiceDetail.Downloaded at")}{" "}
                  {invoice?.downloaded_at &&
                     format(
                        parseISO(invoice?.downloaded_at),
                        "dd MMM yyyy HH:mm"
                     )}
               </Typography>
            )}
         </Box>
         <Box p={2}>
            <Grid
               container
               style={{
                  gap: "1rem",
                  flexDirection: "column",
               }}
            >
               <Box>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={handleInvoiceConfirmationOpen}
                  >
                     {t("InvoiceDetail.Invoice to webhooks")}
                  </Button>
               </Box>
               {invoice.notifications?.length > 0 && (
                  <Box>
                     <Divider orientation="vertical" />
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNotificationConfirmationOpen}
                     >
                        {t("InvoiceDetail.Notifications to webhooks")} (
                        {invoice.notifications?.length})
                     </Button>
                  </Box>
               )}
               {open && (
                  <BoxAlert
                     type={"success"}
                     title={t(
                        "InvoiceDetail.Invoice to preservation succeeded"
                     )}
                     open={open}
                     setOpen={setOpen}
                  />
               )}
               {!preservedDocument && (
                  <InvoicePreservedDocumentButton
                     uuid={invoice.uuid}
                     handlePreservedDocument={handlePreservedDocument}
                  />
               )}
            </Grid>
         </Box>
         <ConfirmationDialog
            open={confirmationDialogIsOpen}
            onConfirm={handleConfirmation}
            onCancel={handleConfirmationCancel}
            title={t("InvoiceDetail.Action Webhooks Confirmation Title")}
            text={t("InvoiceDetail.Action Webhooks Confirmation Text")}
         />
      </Paper>
   )
}

type TabPanelActionsProps = {
   value: number
   index: number
   invoice: GovITInvoice
   table: TableName
   preservedDocument: GovITInvoicePreservationDocument | null
}
export default function TabPanelActions({
   value,
   index,
   invoice,
   table,
   preservedDocument,
}: TabPanelActionsProps) {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && invoice?.uuid && (
            <Box p={3}>
               <InvoiceActions
                  invoice={invoice}
                  table={table}
                  preservedDocument={preservedDocument}
               />
            </Box>
         )}
      </div>
   )
}
