import { Box, ButtonGroup, Container, makeStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import CodeIcon from "@material-ui/icons/Code"
import DescriptionIcon from "@material-ui/icons/Description"
import { format, parseISO, sub } from "date-fns"
import { isEqual } from "lodash"
import { useSnackbar } from "notistack"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { download } from "../../../helper/download"
import { formatInvoices } from "../../../services/client/formatter/invoicesFormatter"
import {
   getInvoice,
   getInvoices,
   getInvoiceXML,
} from "../../../services/client/request/peppol/invoices"
import Page from "../../common/components/Page"
import CustomTable from "../../common/CustomTable"
import InvoicesFilters from "../../common/InvoicesFilters"
import Loader from "../../common/Loader"
import { Marking } from "../../common/Marking"
import PeppolInvoiceDetails from "../../common/PeppolInvoiceDetails"
import PeppolMessageComponent from "../../common/PeppolMessage"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const today = format(new Date(), "yyyy-MM-dd") + " 23:59:59"
const prevMonth =
   format(sub(new Date(), { months: 1 }), "yyyy-MM-dd") + " 00:00:00"

const Invoices = (props) => {
   const initialFiltersState = {
      createdAt: {
         after: prevMonth,
         before: today,
      },
      direction: props.direction,
      senderName: "",
      recipientName: "",
      senderId: "",
      recipientId: "",
      uuid: "",
      documentDate: {
         after: null,
         before: null,
      },
      documentNumber: "",
   }

   const classes = useStyles()
   const pagination = 30
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const [page, setPage] = React.useState(1)
   const [filters, setFilters] = React.useState(initialFiltersState)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)

   // Handling the context with a prop. Maybe in the future this could be refactored by fetching the context from the store?
   const context = props.context

   // Handles invoice details dialog
   const [openOrderUuid, setOpenOrderUuid] = React.useState(null)

   const [rows, setRows] = React.useState({
      count: 0,
      first: null,
      last: null,
      next: null,
      previous: null,
      items: [],
   })

   const columns = [
      {
         empty: true,
         id: "empty",
         format: (value, props, item) => {
            return <PeppolMessageComponent peppolMessage={item.peppolMessage} />
         },
      },
      {
         id: "createdAt",
         label: "Invoices.Created at",
         numeric: false,
         disablePadding: true,
         format: (value) => {
            return format(parseISO(value), "dd MMM yyyy HH:mm:ss")
         },
      },
      {
         id: "senderFormatted",
         label: "Invoices.Sender",
         numeric: false,
         disablePadding: true,
      },
      {
         id: "recipientFormatted",
         label: "Invoices.Recipient",
         numeric: false,
         disablePadding: true,
      },
      {
         id: "date",
         label: "Invoices.Date",
         numeric: false,
         disablePadding: true,
      },
      {
         id: "number",
         label: "Invoices.Number",
         numeric: false,
         disablePadding: true,
      },
      { id: "invoiceDetails", label: "", numeric: false, disablePadding: true },
      {
         empty: true,
         align: "right",
         id: "actions",
         format: (value, props, item) => {
            return (
               <ButtonGroup>
                  <Tooltip title="Invoice XML">
                     <IconButton
                        onClick={() => {
                           props.handleDownloadXML(
                              item.uuid,
                              `${item.number}.xml`
                           )
                        }}
                     >
                        <CodeIcon />
                     </IconButton>
                  </Tooltip>

                  <Tooltip title="Invoice Details">
                     <IconButton
                        onClick={() => {
                           props.setOpenOrderUuid(item.uuid)
                        }}
                     >
                        <DescriptionIcon />
                     </IconButton>
                  </Tooltip>
               </ButtonGroup>
            )
         },
      },
   ]

   // If context is it, add the order status
   if (context === "/it") {
      columns.splice(6, 0, {
         id: "processInformation",
         label: "Invoices.Document Type",
         numeric: false,
         disablePadding: true,
         format: (value, props, item) => {
            return value ? <Marking marking={value.documentType} /> : null
         },
      })
   }

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleDownloadXML = async (id, fileName) => {
      setIsLoading(true)
      setError(false)

      try {
         const response = await getInvoiceXML(token, id, context)
         download(response, fileName)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   const applyFilters = (values) => {
      const shouldResetFilters = Object.keys(values).length === 0
      if (shouldResetFilters) {
         setFilters(initialFiltersState)
      } else {
         // append values to current filters
         setFilters((filters) => ({ ...filters, ...values }))
      }
      setPage(1)
   }

   useEffect(() => {
      // Reset filters when page is changed > direction is changed
      applyFilters({})
      applyFilters({ direction: props.direction })
   }, [props.direction])

   useEffect(() => {
      const { downloaded, ...rest } = filters
      const searchParams = () => ({
         ...rest,
         ...(filters.downloaded !== null && {
            downloaded: filters.downloaded ? "true" : "false",
         }),
         page: page,
      })

      async function fetchData(filters) {
         setIsLoading(true)
         setError(false)

         let orders

         try {
            if (filters.uuid) {
               orders = await getInvoice(token, filters.uuid, context)
               orders = [orders]
            } else {
               orders = await getInvoices(token, searchParams(), context)
            }

            setRows(formatInvoices(orders))
         } catch (e) {
            setError(e.message)
            enqueueSnackbar(e.message, { variant: "error" })
         }

         setIsLoading(false)
      }

      fetchData(filters)
   }, [page, filters, token, enqueueSnackbar])

   const hasFiltersSelected = useMemo(() => {
      return !isEqual(initialFiltersState, filters)
   }, [filters])

   return (
      <Page
         className={classes.root}
         key={props.direction}
         title={
            props.direction === "outgoing"
               ? t("Menu.Outgoing Invoices")
               : t("Menu.Incoming Invoices")
         }
      >
         {isLoading ? (
            <Loader />
         ) : (
            <Container maxWidth={false}>
               <InvoicesFilters
                  expandFilters={hasFiltersSelected}
                  filtersValues={filters}
                  applyFilters={applyFilters}
               />

               {error ? (
                  <></>
               ) : (
                  <Box mt={3}>
                     <CustomTable
                        columns={columns}
                        rows={rows}
                        page={page}
                        pagination={pagination}
                        handleChangePage={handleChangePage}
                        handleDownloadXML={handleDownloadXML}
                        // @TODO: this has to be refactored.. it's referenced only on the columns below!
                        setOpenOrderUuid={setOpenOrderUuid}
                     />
                  </Box>
               )}

               <PeppolInvoiceDetails
                  handleClose={() => setOpenOrderUuid(null)}
                  orderUuid={openOrderUuid}
                  isOpen={openOrderUuid !== null}
                  context={context}
               />
            </Container>
         )}
      </Page>
   )
}

export default Invoices
