import { getImpersonatedUserHeaders } from "helper/impersonateUser"

const API_BASE_URL = process.env.REACT_APP_BASE_URL

export function getQueryString(options) {
   const qs = new URLSearchParams()

   if (options.queryString) {
      Object.keys(options.queryString).forEach((key) =>
         qs.append(key, options.queryString[key])
      )
   }

   return qs.toString()
}

export default async function call(
   endpoint,
   options = {},
   baseUrl = undefined
) {
   const token = localStorage.getItem("token")

   const qs = getQueryString(options)

   const defaultOptions = {
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   // get full response with pagination links and total items
   if (options.hydraResponse) {
      delete defaultOptions.headers.Accept
      defaultOptions.headers["Accept"] = "application/ld+json"
      delete defaultOptions.headers["Content-Type"]
   }

   if (qs) {
      endpoint += `?${qs}`
   }

   const response = await fetch(
      (baseUrl !== undefined ? baseUrl : API_BASE_URL) + endpoint,
      {
         ...defaultOptions,
         ...options.fetchAPIoptions,
      }
   )

   if (response.ok) {
      return await response.json()
   }

   if (response.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("payload")
   }

   return Promise.reject(await response.text())
}

export async function get(endpoint, options = {}, baseUrl) {
   return await call(
      endpoint,
      {
         method: "GET",
         ...options,
      },
      baseUrl
   )
}

export async function post(endpoint, body = {}, options = {}) {
   return await call(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
      ...options,
   })
}
