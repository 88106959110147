import { Button } from "@material-ui/core"
import { cleanedJsonObject } from "helper/cleanedJsonObject"
import _ from "lodash"
import { useSnackbar } from "notistack"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import {
   CreateInvoiceSimplifiedParams,
   useCreateDraftInvoiceMutation,
   useCreateInvoiceMutation,
   useSendDraftInvoiceMutation,
   useUpdateDraftInvoiceMutation,
   useValidateInvoiceSimplifiedMutation,
   ValidateInvoiceEntity,
} from "services/gov-it"
import { useAppDispatch } from "store"
import { closeDialog, openDialog } from "store/slices/dialogs"
import { updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import {
   buildCedentePrestatoreInvoicePayloadSimplified,
   buildGeneralDataSection,
   FieldGroupByName,
   FieldGroupByNameKey,
   getCommonFieldsGrouped,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import {
   camelToSnakeCase,
   getViolationPropertyPath,
   mapPropertyPathToFormField,
   parseResult,
   setViolationsResponse,
   validationSchemaForFixInvoice,
   violationsRegex,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/ValidationHelpers"
import { useGetMeQuery } from "../../../../../services/commonApi"
import BodyForms from "../components/BodyForms"
import { emptyInvoiceFieldsSimplified } from "../components/emptyFiledForm"
import { SubFormProps } from "../index"

type FormValues = CreateInvoiceSimplifiedParams
type ViolationType = {
   propertyPath: string
   message: string
   propertyContent?: string
}
type HelperType = {
   setError: any
   getValues: any
   setValue: any
}

const GovITInvoiceSimplifiedForm = (props: SubFormProps) => {
   const {
      invoice: data,
      mode,
      table,
      project,
      modeDraft,
      blockSendDraft,
   } = props
   const isFixInvoice = "isFixInvoice" in props ? props.isFixInvoice : false
   const invoiceToFixUuid =
      "invoiceToFixUuid" in props ? props.invoiceToFixUuid : null

   const isCreditNoteCreated =
      "isCreditNoteCreated" in props ? props.isCreditNoteCreated : false

   const [createDraftInvoice] = useCreateDraftInvoiceMutation()
   const [updateDraftInvoice] = useUpdateDraftInvoiceMutation()
   const [sendDraftInvoice] = useSendDraftInvoiceMutation()

   const { t } = useTranslation()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const genericError = {
      propertyPath: "Generic",
      message:
         "An error occurred, Please field all required fields correctly and try again",
   } as ViolationType

   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const [createInvoice] = useCreateInvoiceMutation()
   const [validateInvoice, { isLoading }] =
      useValidateInvoiceSimplifiedMutation()

   const { data: userData } = useGetMeQuery()

   const { enqueueSnackbar } = useSnackbar()
   const [isValidForm, setIsValidForm] = React.useState(false)

   const [datiBeniServizi, setDatiBeniServizi] = React.useState<
      FieldGroupByName<"simplified">
   >({
      key: "dati_beni_servizi",
      name: "fattura_elettronica_body[0].dati_beni_servizi",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_beni_servizi",
      sections:
         data?.parsedPayloadSimplified?.fattura_elettronica_body[0].dati_beni_servizi?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simplified",
                  "dati_beni_servizi",
                  `dati_beni_servizi_${index}`,
                  index
               )
         ) ?? [
            buildGeneralDataSection(
               "simplified",
               "dati_beni_servizi",
               "dati_beni_servizi_0",
               0
            ),
         ],
      initialExist: true,
   })

   const updateGroupSection = (
      sectionKey: FieldGroupByNameKey<"simplified">,
      action?: "remove" | "add",
      index?: number,
      parentIndex?: number
   ) => {
      if (action == null) action = "add"

      switch (sectionKey) {
         case "dati_beni_servizi":
            switch (action) {
               case "add":
                  setDatiBeniServizi((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simplified",
                           sectionKey,
                           `${sectionKey}_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiBeniServizi((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         default:
            break
      }
   }

   const handleValidate = async (
      values: FormValues,
      setError: any,
      getValues: any
   ) => {
      setErrors([])
      setMessage(null)
      values = cleanedJsonObject(values)

      const response = await validateInvoice(values)

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         if ("violations" in (response.error.data as any)) {
            const violations = (response.error.data as ValidateInvoiceEntity)
               ?.violations

            setViolationsResponse(data, setError)

            violations?.forEach(
               ({ propertyPath, propertyContent, message }) => {
                  const propertyPaths = getViolationPropertyPath(
                     propertyPath,
                     propertyContent,
                     getValues,
                     sections
                  )
                  propertyPaths.forEach((path) => {
                     setError(mapPropertyPathToFormField(path), {
                        type: "validation Error",
                        message,
                     })
                  })
               }
            )
            //traverseAndHandleErrors(values, violations, setError)
            setErrors((response.error.data as any).violations)
            setIsValidForm(false)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })
            return
         }

         if ("detail" in (response.error.data as any)) {
            if (response.error.status === 400) {
               const violations: ViolationType[] = []
               violationsRegex.forEach(({ regex, propertyNames }) => {
                  let temps = parseResult(
                     ((response.error as any).data as any).detail,
                     regex,
                     propertyNames
                  )
                  if (temps) {
                     violations.push(temps as ViolationType)
                  }
               })

               if (violations.length > 0) {
                  violations?.forEach(
                     ({ propertyPath, propertyContent, message }) => {
                        const propertyPaths = getViolationPropertyPath(
                           camelToSnakeCase(propertyPath),
                           propertyContent ?? null,
                           getValues,
                           sections
                        )

                        propertyPaths.forEach((path) => {
                           setError(mapPropertyPathToFormField(path), {
                              type: "validation Error",
                              message,
                           })
                        })
                     }
                  )
                  //traverseAndHandleErrors(values, violations, setError)
                  setErrors(violations)
                  setIsValidForm(false)
                  enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
                     variant: "error",
                  })
               }

               return
            }

            setErrors(
               response.error.status !== 500
                  ? [
                       {
                          propertyPath: "generic",
                          message: (response.error.data as any)
                             .detail as string,
                       },
                    ]
                  : [genericError as ViolationType]
            )

            enqueueSnackbar(
               response.error.status !== 500
                  ? (response.error.data as any).detail
                  : genericError.message,
               {
                  variant: "error",
               }
            )
            return
         }
         setErrors([genericError as ViolationType])

         return
      }

      if ("data" in response) {
         setIsValidForm(true)
         enqueueSnackbar(t("gov_it.invoice_fix.invoice_valid"), {
            variant: "success",
         })
         setErrors([])
         setMessage(t("gov_it.invoice_fix.invoice_valid"))
         return
      }
   }

   const compareValuesWithInitialValues = (values: object) => {
      return _.isEqual(values, initialValues)
   }

   const handleSubmit = async (values: FormValues, helpers: HelperType) => {
      setErrors([])
      setMessage(null)
      const isFormValuesEqualToInitialValues =
         compareValuesWithInitialValues(values)

      switch (project) {
         case "invoice_draft":
            await handleSubmitInvoiceDraft(values, helpers)
            break
         case "invoice_sent":
            if (isFormValuesEqualToInitialValues) {
               enqueueSnackbar(t("gov_it.invoice_fix.same_invoice_send"), {
                  variant: "error",
               })
               return
            }
            await handleSubmitInvoiceSent(values, helpers)
            break
         case "invoice_fix":
            if (isFormValuesEqualToInitialValues) {
               enqueueSnackbar(t("gov_it.invoice_fix.same_invoice_send"), {
                  variant: "error",
               })
               return
            }
            await handleSubmitInvoiceFix(values, helpers)
            break
      }
   }

   const handleSubmitInvoiceDraft = async (
      values: FormValues,
      helpers: HelperType
   ) => {
      if (!modeDraft) {
         return null
      }
      const isFormValuesEqualToInitialValues =
         compareValuesWithInitialValues(values)

      values = cleanedJsonObject(values)
      switch (modeDraft) {
         case "send":
            if (isFormValuesEqualToInitialValues) {
               return await sendDraftInvoiceValues(helpers)
            }
            return await createOrUpdateDraftInvoiceValues(
               values,
               helpers,
               "edit"
            ).then(async () => {
               return await sendDraftInvoiceValues(helpers)
            })
         case "edit":
         case "new":
            return await createOrUpdateDraftInvoiceValues(values, helpers)
      }
   }

   const handleSubmitInvoiceFix = async (
      values: FormValues,
      { setError }: HelperType
   ) => {
      if (data == null) {
         return null
      }

      setMessage(null)

      values = cleanedJsonObject(values)

      const response = await createInvoice({
         type: "simplified",
         body: values,
         ...(isFixInvoice &&
            invoiceToFixUuid && {
               invoiceToFixUuid,
               isFixInvoice,
            }),
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         setViolationsResponse(data, setError)

         return
      }

      if ("data" in response) {
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         const { uuid } = response.data

         navigate("/customer-invoice")

         dispatch(
            openDialog({
               id: "gov_it_invoices_details",
               data: { invoiceUuid: uuid, mode: "info" },
            })
         )
      }
   }

   const handleSubmitInvoiceSent = async (
      values: FormValues,
      { setError }: HelperType
   ) => {
      setMessage(null)
      values = cleanedJsonObject(values)

      const response = await createInvoice({
         type: "simplified",
         body: values,
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         setViolationsResponse(data, setError)

         return
      }

      if ("data" in response) {
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         const { uuid } = response.data

         navigate("/customer-invoice")

         dispatch(
            openDialog({
               id: "gov_it_invoices_details",
               data: { invoiceUuid: uuid, mode: "info" },
            })
         )
      }
   }

   const createOrUpdateDraftInvoiceValues = async (
      values: FormValues,
      { setError }: HelperType,
      modeInternal?: "new" | "edit"
   ) => {
      if (!modeDraft) return

      if (modeInternal == null && modeDraft === "send") return
      if (modeInternal == null && modeDraft !== "send") modeInternal = modeDraft

      setMessage(null)
      setErrors([])

      values = cleanedJsonObject(values)
      let response
      if (modeInternal == "new") {
         response = await createDraftInvoice({
            body: values,
            format: "json",
            type: "simplified",
            blockInvoiceSent: blockSendDraft === true,
         })
      } else if (modeInternal === "edit") {
         response = await updateDraftInvoice({
            body: values,
            format: "json",
            uuid: data?.uuid as string,
            type: "simplified",
            blockInvoiceSent: blockSendDraft === true,
         })
      } else {
         return
      }

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)

            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })

            if (modeInternal === "edit") {
               const data = response.error.data as {
                  violations?: { propertyPath: string; message: string }[]
               } | null
               setViolationsResponse(data, setError)
            }
            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors([
               {
                  propertyPath: "generic",
                  message: (response.error.data as any).detail as string,
               },
            ])
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }
         setErrors([genericError as ViolationType])
         return
      }

      if ("data" in response) {
         if (modeInternal === "new") {
            const { uuid } = response.data
            setMessage(
               `${t(
                  "gov_it.invoice_drafts.invoice_created"
               )} - Document UUID ${uuid}`
            )
         } else {
            setMessage(`${t("gov_it.invoice_drafts.invoice_draft_update")}`)
         }

         enqueueSnackbar(
            modeInternal === "new"
               ? t("gov_it.invoice_drafts.invoice_created")
               : t("gov_it.invoice_drafts.invoice_draft_update"),
            {
               variant: "success",
            }
         )

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const sendDraftInvoiceValues = async ({ setError }: HelperType) => {
      setMessage(null)
      if (blockSendDraft) {
         setErrors([
            {
               propertyPath: "",
               message: t(
                  "gov_it.invoice_drafts.invoice_draft_send_error_blocked"
               ),
            },
         ])
         enqueueSnackbar(
            t("gov_it.invoice_drafts.invoice_draft_send_error_blocked"),
            {
               variant: "error",
            }
         )

         return
      }

      const response = await sendDraftInvoice({
         uuid: data?.uuid as string,
         type: "simplified",
      })

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })

            const data = response.error.data as {
               violations?: { propertyPath: string; message: string }[]
            } | null
            setViolationsResponse(data, setError)

            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors([
               {
                  propertyPath: "Generic",
                  message: (response.error.data as any).detail as string,
               },
            ])
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }
         return
      }

      if ("data" in response) {
         setMessage(`${t("gov_it.invoice_drafts.invoice_draft_send")}`)

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         setIsValidForm(false)
         const { uuid } = response.data
         dispatch(closeDialog())
         dispatch(
            openDialog({
               id: "alert.modal",
               data: {
                  title: t("gov_it.invoice_drafts.invoice_draft_send"),
                  message: `${t(
                     "gov_it.invoice_drafts.invoice_draft_send"
                  )} - Document UUID ${uuid}`,
                  type: "success",
                  children: (
                     <Button
                        color={"primary"}
                        variant="contained"
                        size="medium"
                        onClick={() => {
                           persistTableRefresh("customer.invoices")
                           dispatch(
                              updateRefetch({
                                 table: "customer.invoices",
                              })
                           )
                           navigate(`/customer-invoice`)
                           dispatch(
                              openDialog({
                                 id: "gov_it_invoices_details",
                                 data: {
                                    invoiceUuid: uuid,
                                    mode: "info",
                                 },
                              })
                           )
                        }}
                     >
                        {t("Invoices.go_to_invoice")}
                     </Button>
                  ),
               },
            })
         )
      }
   }
   const formFieldsGrouped = getCommonFieldsGrouped("simplified")

   const sections1 = [...formFieldsGrouped]

   const sectionsVariable: FieldGroupByName<"simplified">[] = [datiBeniServizi]

   const sections = [...formFieldsGrouped, ...datiBeniServizi.sections]

   const getInitialValues = useCallback(() => {
      return mode === "create"
         ? emptyInvoiceFieldsSimplified({
              cedente_prestatore:
                 buildCedentePrestatoreInvoicePayloadSimplified(
                    userData ?? null
                 ),
           })
         : isCreditNoteCreated
         ? ({
              ...data?.parsedPayloadSimplified,
              fattura_elettronica_body: [
                 {
                    ...data?.parsedPayloadSimplified
                       ?.fattura_elettronica_body[0],
                    dati_generali: {
                       ...data?.parsedPayloadSimplified
                          ?.fattura_elettronica_body[0].dati_generali,
                       dati_generali_documento: {
                          ...data?.parsedPayloadSimplified
                             ?.fattura_elettronica_body[0].dati_generali
                             .dati_generali_documento,
                          tipo_documento: "TD08",
                       },
                    },
                 },
              ],
           } as FormValues)
         : (data?.parsedPayloadSimplified as FormValues)
   }, [data?.parsedPayloadSimplified, isCreditNoteCreated, mode])

   const initialValues = getInitialValues()

   return (
      <BodyForms
         project={project}
         modeDraft={modeDraft}
         sections1={sections1}
         sectionsVariable={sectionsVariable}
         sections2={[]}
         validationSchema={validationSchemaForFixInvoice}
         initialValues={initialValues}
         handleSubmit={handleSubmit}
         handleValidate={handleValidate}
         updateGroupSection={updateGroupSection}
         setIsValidForm={setIsValidForm}
         isLoading={isLoading}
         isValidForm={isValidForm}
         type="simplified"
         errors={errors}
         message={message}
         disableFields={
            isCreditNoteCreated
               ? [
                    "fattura_elettronica_body[0].dati_generali.dati_generali_documento.tipo_documento",
                 ]
               : []
         }
         isCreditNoteCreated={isCreditNoteCreated}
         resetErrors={() => setErrors([])}
         resetMessage={() => setMessage(null)}
      />
   )
}

export default GovITInvoiceSimplifiedForm
