import { Box, Switch, Tooltip, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import { selectTableData, TableName, updateData } from "store/tablesState"

const table: TableName = "openbanking.business_registries"

const HideInactiveAccountsToggle = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { hideInactiveAccounts } = useAppSelector((state) =>
      selectTableData(state, { table })
   )

   const toggleInactiveAccounts = async () => {
      dispatch(
         updateData({
            table,
            param: "hideInactiveAccounts",
            value: !hideInactiveAccounts,
         })
      )
   }

   return (
      <Box display="flex" alignItems="center">
         <Typography variant="body1">
            {t("openbanking.business_registry.hide_inactive_accounts")}
         </Typography>

         <Tooltip
            title={t("openbanking.business_registry.hide_inactive_accounts")}
         >
            <Switch
               checked={hideInactiveAccounts}
               onChange={toggleInactiveAccounts}
               color="primary"
            />
         </Tooltip>
      </Box>
   )
}

export default HideInactiveAccountsToggle
