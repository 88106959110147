import {
   Badge,
   Box,
   createStyles,
   DialogActions,
   Grid,
   makeStyles,
   Paper,
   Theme,
   withStyles,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import {
   Field,
   Formik,
   FormikErrors,
   FormikHelpers,
   FormikValues,
} from "formik"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { useImportSupplierInvoiceMutation } from "../../../../../services/gov-it"
import { useAppDispatch } from "../../../../../store"
import { updateRefetch } from "../../../../../store/tablesState"
import { persistTableRefresh } from "../../../../../store/tablesState/utils"
import CustomInputFileField from "../Components/CustomInputFileField"

type Error = {
   propertyPath: string
   message: string
}
export type FormValues = {
   invoice: string
   metadata: string
   invoice_file_name: string
   sdi_id: string
}

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

const schema = Yup.object().shape({
   invoice: Yup.string().required("Please upload invoice file"),
   metadata: Yup.string().nullable(),
   invoice_file_name: Yup.string()
      .nullable()
      .when("metadata", {
         is: (val) => val == "" && val == null,
         then: Yup.string().required(
            "This field is required when there is not metadata"
         ),
      }),
   sdi_id: Yup.string()
      .nullable()
      .when("metadata", {
         is: (val) => val == "" && val == null,
         then: Yup.string().required(
            "This field is required when there is not is not metadata"
         ),
      }),
})

type ImportInvoiceFormProps = {
   handleClose: () => void
   setErrors: (errors: Error[]) => void
   setMessage: (message: string | null) => void
   table: "supplier.invoices" | "customer.invoices"
}
const ImportSupplierInvoiceForm = ({
   handleClose,
   setErrors,
   setMessage,
   table,
}: ImportInvoiceFormProps) => {
   const { t } = useTranslation()
   const [importSupplierInvoice, { isLoading }] =
      useImportSupplierInvoiceMutation()

   const dispatch = useAppDispatch()

   const fileToBase64 = (file: File) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            const base64String = (reader.result as string)
               .replace("data:", "")
               .replace(/^.+,/, "")

            resolve(base64String)
         }
         reader.onerror = (error) => reject(error)
      })
   }

   const handleFileChosen = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
      setFieldValue: (
         field: string,
         value: any,
         shouldValidate?: boolean
      ) => Promise<void | FormikErrors<FormikValues>>
   ) => {
      const file = e.target && "files" in e.target ? e.target.files?.[0] : null
      if (file) {
         const base64 = await fileToBase64(file).then((result) => result)
         setFieldValue(field, base64)
         return
      }
      setFieldValue(field, "")
   }

   const initialValues: FormValues = {
      invoice: "",
      metadata: "",
      invoice_file_name: "",
      sdi_id: "",
   }
   const classes = useStyles()
   const invoiceRef = useRef<HTMLInputElement>(null)
   const metadataRef = useRef<HTMLInputElement>(null)
   const genericError: Error = {
      propertyPath: "generic",
      message: "An error occurred",
   }
   const resetFileField = (field: string) => {
      if (field === "invoice" && invoiceRef.current) {
         invoiceRef.current.value = ""
      }
      if (field === "metadata" && metadataRef.current) {
         metadataRef.current.value = ""
      }
   }

   const validationValues = (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      let isValid = true
      if (values.invoice === null) {
         setFieldError("invoice", "Please upload invoice file")
         isValid = false
      }
      if (values.invoice_file_name === "" && values.metadata === "") {
         setFieldError("invoice_file_name", "This field is required")
         isValid = false
      }
      if (values.sdi_id === "" && values.metadata === "") {
         setFieldError("sdi_id", "This field is required")
         isValid = false
      }

      return isValid
   }

   const handleSupplierInvoicesSave = async (
      values: FormValues,
      formikHelpers: FormikHelpers<FormValues>
   ) => {
      setErrors([])
      setMessage(null)
      const { setFieldError } = formikHelpers
      if (!validationValues(values, formikHelpers)) {
         return
      }

      const response = await importSupplierInvoice({
         invoice: values.invoice as string,
         ...(values.invoice_file_name !== "" && {
            invoice_file_name: values.invoice_file_name,
         }),
         ...(values.sdi_id !== "" && { sdi_id: values.sdi_id }),
         ...(values.metadata !== "" && { metadata: values.metadata }),
      })

      if ("error" in response && "data" in response.error) {
         // @ts-ignore
         if ("violations" in response.error.data) {
            const violations = response.error.data.violations as Error[]
            setErrors(violations)
            violations.map((violation) => {
               if (violation.propertyPath.startsWith("notifications")) {
                  const index = violation.propertyPath.split(".")[1]
                  setFieldError(
                     `notifications.${index}.${
                        violation.propertyPath.split(".")[2]
                     }`,
                     violation.message
                  )
               } else {
                  setFieldError(violation.propertyPath, violation.message)
               }
            })

            return
         }
         setErrors([genericError])
         return
      }

      if ("data" in response) {
         setMessage(
            `Your invoice has been import successfully ${response.data.uuid}`
         )
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
         return
      }
   }
   return (
      <Formik
         initialValues={initialValues}
         onSubmit={handleSupplierInvoicesSave}
         validationSchema={schema}
      >
         {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            getFieldMeta,
         }) => (
            <form onSubmit={handleSubmit} noValidate>
               <Grid container spacing={3}>
                  <Grid item md={6} xs={12} key={"invoice"}>
                     <Paper style={{ width: "100%" }}>
                        <Box p={2.5} style={{ width: "100%" }}>
                           <CustomInputFileField
                              name={"invoice"}
                              accept=".xml,.p7m"
                              label={t(
                                 "SupplierInvoices.ImportInvoice.Upload file invoice"
                              )}
                              invoiceRef={invoiceRef}
                              handleFileChosen={handleFileChosen}
                              getFieldMeta={getFieldMeta}
                              resetFileField={resetFileField}
                              setFieldValue={setFieldValue}
                           />
                        </Box>
                     </Paper>
                  </Grid>
                  <Grid item md={6} xs={12} key={"metadata"}>
                     <Paper style={{ width: "100%" }}>
                        <Box p={2.5} style={{ width: "100%" }}>
                           <CustomInputFileField
                              name={"metadata"}
                              label={t(
                                 "SupplierInvoices.ImportInvoice.Upload file metadata"
                              )}
                              accept=".xml"
                              invoiceRef={metadataRef}
                              handleFileChosen={handleFileChosen}
                              getFieldMeta={getFieldMeta}
                              resetFileField={resetFileField}
                              setFieldValue={setFieldValue}
                           />
                        </Box>
                     </Paper>
                  </Grid>
                  <Grid item xs={12} key={"invoice-info"}>
                     <Paper style={{ width: "100%" }}>
                        <Grid
                           item
                           xs={12}
                           style={{ padding: 10 }}
                           container
                           spacing={3}
                        >
                           <Grid item xs={12} md={6}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t(
                                    "SupplierInvoices.ImportInvoice.invoice_file_name"
                                 )}
                                 name="invoice_file_name"
                                 id="invoice_file_name"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 helperText={
                                    getFieldMeta("invoice_file_name").error
                                 }
                                 error={
                                    getFieldMeta("invoice_file_name").error !==
                                    undefined
                                 }
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t(
                                    "SupplierInvoices.ImportInvoice.sdi_id"
                                 )}
                                 name="sdi_id"
                                 id="sdi_id"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 helperText={getFieldMeta("sdi_id").error}
                                 error={
                                    getFieldMeta("sdi_id").error !== undefined
                                 }
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                     </Paper>
                  </Grid>
               </Grid>
               <DialogActions>
                  <div className={classes.buttons}>
                     <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isLoading}
                     >
                        {t("Default.Invia")}
                     </Button>
                     <Button
                        onClick={handleClose}
                        color="default"
                        variant="contained"
                     >
                        {t("Default.Annulla")}
                     </Button>
                  </div>
               </DialogActions>
            </form>
         )}
      </Formik>
   )
}

const StyledBadge = withStyles((theme: Theme) =>
   createStyles({
      badge: {
         right: 0,
         top: 3,
         border: `2px solid ${theme.palette.background.paper}`,
      },
   })
)(Badge)

export default ImportSupplierInvoiceForm
