import { fade, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import {
   PreservationDocumentStatus,
   PRESERVATION_DOCUMENT_STATUS,
} from "services/preservation"

//@ts-ignore
const useStyles = makeStyles((theme) => ({
   root: {
      alignItems: "center",
      borderRadius: 2,
      cursor: "default",
      display: "inline-flex",
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 15,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
      whiteSpace: "nowrap",
   },
   primary: {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.08),
   },
   secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.secondary.main, 0.08),
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: fade(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: fade(theme.palette.success.main, 0.08),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: fade(theme.palette.warning.main, 0.08),
   },
}))

type Props = {
   status: PreservationDocumentStatus | null
}
export const Status = ({ status }: Props) => {
   const classes = useStyles()

   if (!status) {
      return <></>
   }

   const getColor = (status: PreservationDocumentStatus) => {
      switch (status) {
         case "sent":
         case "sent_metadata":
            return "warning"
         case "stored":
            return "success"
         default:
            return "primary"
      }
   }

   const color = getColor(status)

   const content =
      PRESERVATION_DOCUMENT_STATUS.find((item) => item.value == status)
         ?.label ?? status
   return (
      <span
         className={
            //@ts-ignore
            clsx(classes.root, {
               //@ts-ignore
               [classes[color]]: color,
            })
         }
      >
         {content}
      </span>
   )
}
