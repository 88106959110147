export function download(response, fileName) {
   if (!response) {
      return
   }

   const a = document.createElement("a")
   const blob = new Blob([response], { type: "octet/stream" })
   const url = window.URL.createObjectURL(blob)

   a.href = url
   a.download = fileName
   a.style = "display: none"
   document.body.appendChild(a)
   a.click()

   window.URL.revokeObjectURL(url)
}
