import {
   Box,
   Button,
   FormControl,
   FormHelperText,
   Grid,
   InputLabel,
   MenuItem,
   Select,
} from "@material-ui/core"
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import {
   OpenbankingWebhook,
   OpenbankingWebhookAuthenticationType,
   OpenbankingWebhookEvent,
} from "services/openbankingApi/webhooks"

export type FormValues = {
   event: string
   targetUrl: string
   authenticationType: string
   authenticationKey: string
   authenticationToken: string
}

export const mapFormValuesToOpenbankingWebhook = (values: FormValues) =>
   Object.entries(values).reduce(
      (acc, [key, value]) => ({
         ...acc,
         [key]: value === "" ? null : value,
      }),
      {} as Omit<OpenbankingWebhook, "uuid">
   )

export const mapOpenbankingWebhookToFormValues = (
   webhook: OpenbankingWebhook
) =>
   Object.entries(webhook)
      .filter(([key]) => key !== "uuid")
      .reduce(
         (acc, [key, value]) => ({
            ...acc,
            [key]: value === null ? "" : value,
         }),
         {} as FormValues
      )

type WebhookField = { name: keyof FormValues; labelTranslationId: string } & (
   | { type: "textfield" }
   | { type: "select"; options: string[] }
)

const fields: WebhookField[] = [
   {
      name: "event",
      labelTranslationId: "openbanking.webhooks.edit_webhook_form.field_event",
      type: "select",
      options: [...Object.values(OpenbankingWebhookEvent)],
   },
   {
      name: "targetUrl",
      labelTranslationId:
         "openbanking.webhooks.edit_webhook_form.field_target_url",
      type: "textfield",
   },
   {
      name: "authenticationType",
      labelTranslationId:
         "openbanking.webhooks.edit_webhook_form.field_authentication_type",
      type: "select",
      options: ["", ...Object.values(OpenbankingWebhookAuthenticationType)],
   },
   {
      name: "authenticationKey",
      labelTranslationId:
         "openbanking.webhooks.edit_webhook_form.field_authentication_key",
      type: "textfield",
   },
   {
      name: "authenticationToken",
      labelTranslationId:
         "openbanking.webhooks.edit_webhook_form.field_authentication_token",
      type: "textfield",
   },
]

type Props = {
   onCancel: VoidFunction
   formikProps: FormikProps<FormValues>
}

const Form = ({ onCancel, formikProps }: Props) => {
   const { t } = useTranslation()

   const {
      handleSubmit,
      touched,
      errors,
      values,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={3}>
            {fields.map(({ name, labelTranslationId, ...field }) => (
               <Grid key={name} item md={12} xs={12}>
                  {field.type === "select" && (
                     <FormControl
                        fullWidth
                        error={touched[name] != null && errors[name] != null}
                     >
                        <InputLabel>{t(labelTranslationId)}</InputLabel>
                        <Select
                           value={values[name]}
                           name={name}
                           onChange={handleChange}
                           label="Age"
                        >
                           {field.options.map((option) => (
                              <MenuItem
                                 key={option}
                                 value={option === "" ? "None" : option}
                              >
                                 {option === "" ? "None" : option}
                              </MenuItem>
                           ))}
                        </Select>
                        {touched[name] != null && errors[name] != null && (
                           <FormHelperText>{errors[name]}</FormHelperText>
                        )}
                     </FormControl>
                  )}

                  {field.type === "textfield" && (
                     <Field
                        component={TextField}
                        type="text"
                        label={t(labelTranslationId)}
                        name={name}
                        helperText={touched[name] != null && errors[name]}
                        error={touched[name] != null && errors[name] != null}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                     />
                  )}
               </Grid>
            ))}
         </Grid>

         <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={8}
            mt={2}
            mb={1}
         >
            <Button color="default" variant="contained" onClick={onCancel}>
               {t("openbanking.webhooks.edit_webhook_form.cancel_cta")}
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {t("openbanking.webhooks.edit_webhook_form.save_cta")}
            </Button>
         </Box>
      </form>
   )
}

export default Form
