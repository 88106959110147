import { alpha, makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import clsx from "clsx"

//@ts-ignore
const useStyles = makeStyles((theme) => ({
   root: {
      fontFamily: theme.typography.fontFamily,
      alignItems: "center",
      borderRadius: 2,
      display: "inline-flex",
      flexGrow: 0,
      whiteSpace: "nowrap",
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 20,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
      margin: theme.spacing(0.5),
   },
   primary: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
   },
   secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.08),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.08),
   },
}))

type NotificationButtonProps = {
   children?: React.ReactNode
   className?: string
   style?: React.CSSProperties
   color?: "primary" | "secondary" | "error" | "warning" | "success"
   notificationType: string
   onClick?: () => void
}

const NotificationButton = ({
   className = "",
   onClick,
   children,
   style,
   notificationType,
   ...rest
}: NotificationButtonProps) => {
   const classes = useStyles()

   let color = "secondary"
   switch (notificationType) {
      case "RC":
         color = "success"
         break
      case "MC":
         color = "warning"
         break
      case "NS":
         color = "error"
         break
   }

   return (
      <Button
         className={clsx(
            // @ts-ignore
            classes.root,
            {
               // @ts-ignore
               [classes[color]]: color,
            },
            className
         )}
         {...rest}
         type="submit"
         size="small"
         // @ts-ignore
         color={color}
         variant="contained"
         onClick={onClick}
      >
         {notificationType}
      </Button>
   )
}
export default NotificationButton
