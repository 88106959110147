import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import CreateLegalEntityDialog from "./CreateLegalEntityDialog"
import LegalEntityDetailsDialog from "./DetailsDialog"
import EditLegalEntityDialog from "./EditLegalEntityDialog"
import NewEntryCTA from "./NewEntryCTA"
import OnboardInitSuccessDialog from "./OnboardInitSuccessDialog"
import LegalEntitiesTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const LegalEntities = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <Page className={classes.root} title={t("Menu.gov_pl.legal_entities")}>
            <Container maxWidth={false}>
               <Box mb={3}>
                  <NewEntryCTA />
               </Box>

               <LegalEntitiesTable />
            </Container>
         </Page>

         <OnboardInitSuccessDialog />
         <CreateLegalEntityDialog />
         <EditLegalEntityDialog />
         <LegalEntityDetailsDialog />
      </>
   )
}

export default LegalEntities
