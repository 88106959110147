import { DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useUpdatePasswordSubAccountMutation } from "services/commonApi"
import { DialogState } from "store/slices/dialogs"
import SubAccountForm, {
   FormValues,
} from "views/settings/SubAccounts/SubAccountsDialog/SubAccountForm"
import * as Yup from "yup"

const DIALOG_ID = "common_sub_account.change_password"
type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const initialValues = {
   password: "",
} as FormValues

const validationSchema = Yup.object().shape({
   password: Yup.string().required(),
})
const Content = ({ email, handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updatePassword] = useUpdatePasswordSubAccountMutation()

   const handleSubmit = async (values: FormValues) => {
      const response = await updatePassword({
         email: email,
         password: values.password,
      })

      if ("data" in response) {
         enqueueSnackbar(t("common_subAccounts.update_success"), {
            variant: "success",
         })
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }
   return (
      <>
         <DialogTitle>
            {t("common_subAccounts.form.change_password")}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <SubAccountForm
                     formikProps={formikProps}
                     handleClose={handleClose}
                     type="updatePassword"
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}
const UpdateSubAccountPasswordDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default UpdateSubAccountPasswordDialog
