import { Box, CircularProgress, Container, Typography } from "@material-ui/core"
import { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useGetOnboardingLegalEntityQuery } from "services/gov-pl/onboard"
import Countdown from "./Countdown"
import Languages from "./Languages"

type Props = {
   children: ReactElement | ReactElement[]
}

const LayoutWrapper = ({ children }: Props) => {
   const { t } = useTranslation()
   const { legalEntityUuid, hash } = useParams()

   const { data, isLoading } = useGetOnboardingLegalEntityQuery({
      uuid: legalEntityUuid!,
      hash: hash!,
   })
   const customer = data?.customer

   return (
      <Box
         minHeight="100vh"
         display="flex"
         flexDirection="column"
         bgcolor="background.white"
      >
         <Box bgcolor="red.700" color="text.white" px={2} py={4}>
            <Container maxWidth="md">
               <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
               >
                  <Box>
                     <Box mb={2}>
                        <Typography variant="h1">
                           {t("onboard.header.title")}
                        </Typography>
                     </Box>

                     {isLoading && (
                        <Box color="text.white">
                           <CircularProgress size={16} color="inherit" />
                        </Box>
                     )}
                     {!isLoading && (
                        <Typography variant="body1">
                           {t("onboard.header.subtitle", {
                              customer: data?.customer.customerName,
                           })}
                        </Typography>
                     )}
                  </Box>

                  <Countdown />
               </Box>
            </Container>
         </Box>

         <Box flexGrow={1}>
            <Container maxWidth="md">
               <Box paddingY={2}>
                  <Languages />

                  {children}
               </Box>
            </Container>
         </Box>

         <Box py={3} borderTop={1} borderColor="grey.500">
            <Container maxWidth="md">
               <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
               >
                  <Box>
                     {isLoading && (
                        <Box color="grey.500">
                           <CircularProgress size={24} color="inherit" />
                        </Box>
                     )}
                     {!isLoading && (
                        <Typography variant="body1">
                           {customer?.customerName}
                           <br />
                           {[
                              customer?.addressLine,
                              customer?.city,
                              customer?.countryCode,
                           ]
                              .filter(Boolean)
                              .join(", ")}
                           <br />
                           NIP: {customer?.nip}
                        </Typography>
                     )}
                  </Box>
                  <a href="https://acubeapi.com/">
                     <img alt="Logo" src="/logo.svg" height={27} />
                  </a>
               </Box>
            </Container>
         </Box>
      </Box>
   )
}

export default LayoutWrapper
