import { HydraResponse } from "services/types"
import { openbankingApi, OpenbankingPaginationParams } from ".."

export type OpenbankingBusinessRegistry = {
   fiscalId: string
   email: string
   businessName: string
   enabled: boolean
   emailAlerts: boolean
}

type GetBusinessRegistriesParams = OpenbankingPaginationParams

type CreateBusinessRegistryEntryBody = Omit<
   OpenbankingBusinessRegistry,
   "enabled"
>

type UpdateBusinessRegistryBody = Partial<
   Omit<OpenbankingBusinessRegistry, "fiscalId" | "email">
>

const openbankingBusinessRegistryApi = openbankingApi.injectEndpoints({
   endpoints: (builder) => ({
      getBusinessRegistries: builder.query<
         HydraResponse<OpenbankingBusinessRegistry>,
         GetBusinessRegistriesParams
      >({
         query: (params) => ({
            url: "/business-registry",
            params,
            headers: {
               Accept: "application/ld+json",
            },
         }),
         providesTags: ["BUSINESS_REGISTRY"],
      }),

      getBusinessRegistry: builder.query<
         OpenbankingBusinessRegistry,
         { fiscalId: string }
      >({
         query: ({ fiscalId }) => ({
            url: `/business-registry/${fiscalId}`,
         }),
      }),

      createBusinessRegistry: builder.mutation<
         OpenbankingBusinessRegistry,
         CreateBusinessRegistryEntryBody
      >({
         query: ({ ...body }) => ({
            url: "/business-registry",
            method: "POST",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY"],
      }),

      updateBusinessRegistry: builder.mutation<
         OpenbankingBusinessRegistry,
         UpdateBusinessRegistryBody & { fiscalId: string }
      >({
         query: ({ fiscalId, ...body }) => ({
            url: `/business-registry/${fiscalId}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY"],
      }),

      deleteBusinessRegistry: builder.mutation<void, { fiscalId: string }>({
         query: ({ fiscalId }) => ({
            url: `/business-registry/${fiscalId}`,
            method: "DELETE",
         }),
         invalidatesTags: ["BUSINESS_REGISTRY"],
      }),
   }),
})

export const {
   useGetBusinessRegistriesQuery,
   useGetBusinessRegistryQuery,
   useCreateBusinessRegistryMutation,
   useUpdateBusinessRegistryMutation,
   useDeleteBusinessRegistryMutation,
} = openbankingBusinessRegistryApi
