import {
   Box,
   Button,
   DialogContent,
   DialogTitle,
   IconButton,
   Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { format, sub } from "date-fns"
import { Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DialogId, DialogState } from "store/slices/dialogs"
import DatePicker from "views/common/DatePicker"
import * as Yup from "yup"
import RecoverInvoices from "./RecoverInvoices"

const DIALOG_ID: DialogId = "gov_it_invoices_recover"

export type FormValues = {
   from: string
   to: string
}

const validationSchema = Yup.object().shape({})

const defaultRange = {
   from: format(sub(new Date(), { days: 90 }), "yyy-MM-dd"),
   to: format(new Date(), "yyyy-MM-dd"),
}

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, fiscalId }: Props) => {
   const { t } = useTranslation()
   const [range, setRange] = useState(defaultRange)

   const initialValues = defaultRange

   const updateRange = async (values: FormValues) => {
      setRange(values)
   }

   return (
      <>
         <DialogTitle>
            <Box
               display="flex"
               justifyContent="space-between"
               alignItems="center"
            >
               <Typography variant="h5">
                  {t(
                     "gov_it.business_registry_configurations.recover_invoices_modal.title"
                  )}
               </Typography>

               <IconButton onClick={handleClose}>
                  <CloseIcon />
               </IconButton>
            </Box>
         </DialogTitle>

         <DialogContent>
            <RecoverInvoices fiscalId={fiscalId} {...range} />

            <Formik
               initialValues={initialValues}
               onSubmit={updateRange}
               validationSchema={validationSchema}
            >
               {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                     <Box
                        display="flex"
                        gridGap={16}
                        mt={6}
                        alignItems="center"
                     >
                        <DatePicker
                           name="from"
                           label={
                              "gov_it.business_registry_configurations.recover_invoices_modal.field_from"
                           }
                           helperText=""
                           fullWidth
                        />

                        <DatePicker
                           name="to"
                           label={
                              "gov_it.business_registry_configurations.recover_invoices_modal.field_to"
                           }
                           helperText=""
                           fullWidth
                        />
                     </Box>

                     <Box textAlign="center" mt={1} mb={1}>
                        <Button
                           type="submit"
                           color="primary"
                           variant="contained"
                           style={{ width: "max-content" }}
                        >
                           {t(
                              "gov_it.business_registry_configurations.recover_invoices_modal.update_range_cta"
                           )}
                        </Button>
                     </Box>
                  </form>
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const RecoverInvoicesDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default RecoverInvoicesDialog
