import { Box, Button, Typography } from "@material-ui/core"
import { Trans, useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import {
   OnboardMode,
   selectOnboardCurrentMode,
   setOnboardCurrentMode,
} from "store/slices/onboard"
import LayoutWrapper from "./LayoutWrapper"
import OnboardMode1 from "./Mode1"
import AuthGuard from "./Mode2/AuthGuard"
import OnboardMode2Steps from "./Mode2/Steps"

const Content = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const currentMode = useAppSelector(selectOnboardCurrentMode)

   const selectMode = (mode: OnboardMode) => {
      dispatch(setOnboardCurrentMode(mode))
   }

   if (currentMode === "mode_1") {
      return <OnboardMode1 />
   }

   if (currentMode === "mode_2") {
      return (
         <AuthGuard>
            <OnboardMode2Steps />
         </AuthGuard>
      )
   }

   return (
      <Box marginBottom={6}>
         <Box marginBottom={2}>
            <Typography variant="body1">{t("onboard.main.body_1")}</Typography>
         </Box>

         <Box marginBottom={2}>
            <Typography variant="body1">{t("onboard.main.body_2")}</Typography>
         </Box>

         <Box marginBottom={4}>
            <ol
               style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
               }}
            >
               <li>
                  <Typography variant="body1">
                     <Trans i18nKey="onboard.main.body_3" />
                  </Typography>
                  <Box marginTop={1}>
                     <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => selectMode("mode_1")}
                     >
                        {t("onboard.main.mode_1_cta")}
                     </Button>
                  </Box>
               </li>

               <li>
                  <Typography variant="body1">
                     <Trans i18nKey="onboard.main.body_4" />
                  </Typography>
                  <Box marginTop={1}>
                     <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => selectMode("mode_2")}
                     >
                        {t("onboard.main.mode_2_cta")}
                     </Button>
                  </Box>
               </li>
            </ol>
         </Box>

         <Box display="flex" justifyContent="center" gridGap={24}></Box>
      </Box>
   )
}

const Onboard = () => {
   return (
      <LayoutWrapper>
         <Content />
      </LayoutWrapper>
   )
}

export default Onboard
