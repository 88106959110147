import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import formatExpense from "../../../services/client/formatter/formatExpense"
import formatReceipts from "../../../services/client/formatter/formatReceipts"
import { getExpense } from "../../../services/client/request/getExpense"
import { getReceipts } from "../../../services/client/request/getReceipts"
import Loader from "../Loader"
import ExpenseDialogTabs from "./ExpenseDialogTabs"

export default function ExpenseDialog(props) {
   const classes = useStyles()
   const token = useAppSelector(selectAuthToken)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [expense, setExpense] = React.useState({})
   const [receipts, setReceipts] = React.useState({})
   const [isLoading, setIsLoading] = React.useState(false)

   async function fetchData(expenseId) {
      setIsLoading(true)
      setError(false)

      try {
         setExpense(formatExpense(await getExpense(token, expenseId)))
         setReceipts(formatReceipts(await getReceipts(token, expenseId)))
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      if (props.expenseId) {
         fetchData(props.expenseId)
         return
      }

      setExpense({})
      setReceipts({})
   }, [props.expenseId])

   return (
      <Dialog
         fullWidth={true}
         maxWidth="lg"
         open={props.isOpen}
         onClose={props.handleClose}
         aria-labelledby="expense-dialog-title"
         aria-describedby="expense-dialog-description"
         className={classes.container}
      >
         {isLoading ? (
            <Loader />
         ) : (
            <DialogContent>
               {error ? (
                  <></>
               ) : (
                  <ExpenseDialogTabs expense={expense} receipts={receipts} />
               )}
            </DialogContent>
         )}

         <DialogActions className={classes.buttons}>
            <Button
               onClick={props.handleClose}
               color="default"
               variant="contained"
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
