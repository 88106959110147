import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import Page from "views/common/components/Page"
import InvoiceTransferUploadDialog from "views/gov-it/InvoiceTransfers/UploadDialog"
import InvoiceTransferViolationDetailsDialog from "views/gov-it/InvoiceTransfers/ViolationsDialog"
import InvoiceTransferDetailsDialog from "./DetailsDialog"
import TableFilters from "./Filters"
import GovITInvoiceTransfersTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const GovITInvoiceTransfers = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()
   const hasRoleSupplierOnly = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_INVOICING_SUPPLIER_ONLY",
      })
   )
   useEffect(() => {
      if (hasRoleSupplierOnly) {
         navigate("/")
      }
   }, [hasRoleSupplierOnly, navigate])
   return (
      <>
         <Page
            className={classes.root}
            title={t("gov_it.invoice_transfers.title")}
         >
            <Container maxWidth={false}>
               <TableFilters />

               <GovITInvoiceTransfersTable />
            </Container>
         </Page>

         <InvoiceTransferDetailsDialog />
         <InvoiceTransferViolationDetailsDialog />
         <InvoiceTransferUploadDialog />
      </>
   )
}

export default GovITInvoiceTransfers
