import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

const NewEntryCTA = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const handleOpenDialog = () => {
      dispatch(openDialog({ id: "gov_pl_new_legal_entity" }))
   }

   return (
      <Button color="primary" variant="contained" onClick={handleOpenDialog}>
         {t("legal_entities.new_entry_cta")}
      </Button>
   )
}

export default NewEntryCTA
