import { useSnackbar } from "notistack"
import React from "react"
import { Notification } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import NotificationButton from "views/common/Notification/NotificationButton"
import NotificationsDialog from "views/common/Notification/NotificationsDialog"
import AlertModal from "../AlertModal"

type Props = {
   notifications: Notification[]
   invoice: GovITInvoice
}
export function Notifications({ invoice, notifications }: Props) {
   const { enqueueSnackbar } = useSnackbar()
   const [isOpenNotificationDetailsDialog, setOpenNotificationDetailsDialog] =
      React.useState(false)
   const [invoiceUuid, setInvoiceUuid] = React.useState<string | null>(null)
   const [notificationType, setNotificationType] = React.useState<
      string | null
   >(null)

   const handleOpenNotificationDetailsDialog = (type: string) => {
      setNotificationType(type)
      setInvoiceUuid(invoice.uuid)
      setOpenNotificationDetailsDialog(true)
   }

   const handleCloseNotificationDetailsDialog = () => {
      setOpenNotificationDetailsDialog(false)
      setInvoiceUuid(null)
   }

   return (
      <>
         {notifications.length > 0
            ? notifications.map((notification, index) => {
                 return (
                    <NotificationButton
                       key={index}
                       notificationType={notification.type}
                       onClick={() => {
                          handleOpenNotificationDetailsDialog(notification.type)
                       }}
                    />
                 )
              })
            : null}

         <NotificationsDialog
            isOpen={isOpenNotificationDetailsDialog}
            handleClose={handleCloseNotificationDetailsDialog}
            invoiceUuid={invoiceUuid}
            notificationType={notificationType}
         />
         <AlertModal />
      </>
   )
}
