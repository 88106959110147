import { colors } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import shadows from "./shadows"
import typography from "./typography"

const theme = createTheme({
   palette: {
      background: {
         default: "#F4F6F8",
         white: colors.common.white,
         dark: "#2F353A",
         red: "#C62828",
      },
      grey: {
         ...colors.grey,
      },
      green: {
         ...colors.green,
      },
      red: {
         ...colors.red,
      },
      primary: {
         main: "#54BACB",
         contrastText: "#FFFFFF",
      },
      secondary: {
         main: "#8AEDFE",
         contrastText: "#2F353A",
      },
      white: {
         white: colors.common.white,
      },
      dark: {
         main: "#2F353A",
      },
      text: {
         primary: colors.blueGrey[900],
         secondary: colors.blueGrey[600],
         white: colors.common.white,
      },
   },
   shadows,
   typography,
   props: {
      MuiFormControl: {
         variant: "outlined",
         size: "small",
         margin: "dense",
      },
      MuiInputLabel: {
         variant: "outlined",
         shrink: true,
      },
      MuiSelect: {
         variant: "outlined",
         size: "small",
      },
      MuiTextField: {
         variant: "outlined",
         size: "small",
      },
      MuiButton: {
         size: "small",
      },
      MuiButtonGroup: {
         size: "small",
         variant: "outlined",
         disableRipple: true,
         disableFocusRipple: true,
      },
      MuiInputBase: {
         margin: "dense",
      },
      MuiListItem: {
         dense: true,
      },
   },
   overrides: {
      MuiInputLabel: {
         outlined: {
            backgroundColor: "white",
            paddingLeft: 4,
            paddingRight: 4,
         },
      },
   },
})

export default theme
