import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { download } from "helper/download"
import { useSnackbar } from "notistack"
import {
   useGetInvoiceFileQuery,
   useLazyGetInvoiceFileQuery,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"

type Props = {
   value: number
   index: number
   invoice: GovITInvoice | null
}
export default function TabPanelPreview({ value, index, invoice }: Props) {
   const { enqueueSnackbar } = useSnackbar()
   const [getInvoiceFile, { error }] = useLazyGetInvoiceFileQuery()
   const { data, isLoading } = useGetInvoiceFileQuery({
      uuid: invoice?.uuid!,
      accept: "text/html",
   })

   const invoiceHtml = data ?? ""

   const handleDownloadPDF = async (id: string, fileName: string) => {
      const response = await getInvoiceFile({
         uuid: id,
         accept: "application/pdf",
      })

      if ("error" in response) {
         enqueueSnackbar(error, {
            variant: "error",
         })
         return
      }

      if (response.data) {
         download(response.data, fileName)
      }
   }

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && invoice?.uuid && (
            <Box p={5}>
               {invoice.sdi_file_id && (
                  <Box mb={3}>
                     <Button
                        size={"small"}
                        onClick={() => {
                           handleDownloadPDF(
                              invoice.uuid,
                              invoice.sdi_file_name + ".pdf"
                           )
                        }}
                     >
                        Download <PictureAsPdfIcon />
                     </Button>
                  </Box>
               )}
               <div dangerouslySetInnerHTML={{ __html: invoiceHtml }}></div>
            </Box>
         )}
      </div>
   )
}
