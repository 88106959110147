import { cleanedJsonObject } from "helper/cleanedJsonObject"
import _ from "lodash"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import {
   CreateInvoiceParams,
   useCreateDraftInvoiceMutation,
   useCreateInvoiceMutation,
   useSendDraftInvoiceMutation,
   useUpdateDraftInvoiceMutation,
   useValidateInvoiceMutation,
   ValidateInvoiceEntity,
} from "services/gov-it"
import { useAppDispatch } from "store"
import { closeDialog, openDialog } from "store/slices/dialogs"
import { updateRefetch } from "store/tablesState"

import BodyForms from "views/common/InvoiceForm/CommonInvoiceForm/components/BodyForms"
import {
   buildCedentePrestatoreInvoicePayload,
   buildGeneralDataSection,
   buildSubSectionDatiPagamento,
   FieldGroup,
   FieldGroupByName,
   FieldGroupByNameKey,
   getCommonFieldsGrouped,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import {
   camelToSnakeCase,
   getViolationPropertyPath,
   mapPropertyPathToFormField,
   parseResult,
   setViolationsResponse,
   validationSchemaForFixInvoice,
   violationsRegex,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/ValidationHelpers"

import { Button } from "@material-ui/core"
import { persistTableRefresh } from "store/tablesState/utils"
import { SubFormProps } from "views/common/InvoiceForm/CommonInvoiceForm"
import { emptyInvoiceFields } from "views/common/InvoiceForm/CommonInvoiceForm/components/emptyFiledForm"
import { useGetMeQuery } from "../../../../../services/commonApi"

type FormValues = CreateInvoiceParams
type ViolationType = {
   propertyPath: string
   message: string
   propertyContent?: string
}
type HelperType = {
   setError: any
   getValues: any
   setValue: any
}

const GovITInvoiceSimpleForm = (props: SubFormProps) => {
   const {
      invoice: data,
      mode,
      table,
      project,
      modeDraft,
      blockSendDraft,
   } = props

   const isFixInvoice = "isFixInvoice" in props ? props.isFixInvoice : false
   const invoiceToFixUuid =
      "invoiceToFixUuid" in props ? props.invoiceToFixUuid : null

   const isCreditNoteCreated =
      "isCreditNoteCreated" in props ? props.isCreditNoteCreated : false

   const [createDraftInvoice] = useCreateDraftInvoiceMutation()
   const [updateDraftInvoice] = useUpdateDraftInvoiceMutation()
   const [sendDraftInvoice] = useSendDraftInvoiceMutation()

   const { data: userData } = useGetMeQuery()

   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const [createInvoice] = useCreateInvoiceMutation()
   const [validateInvoice, { isLoading }] = useValidateInvoiceMutation()
   const { enqueueSnackbar } = useSnackbar()
   const [isValidForm, setIsValidForm] = React.useState(false)
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const genericError = {
      propertyPath: "Generic",
      message:
         "An error occurred, Please field all required fields correctly and try again",
   } as ViolationType

   const [datiDdt, setDatiDdt] = React.useState<FieldGroupByName<"simple">>({
      key: "dati_ddt",
      name: "fattura_elettronica_body[0].dati_generali.dati_ddt",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_ddt",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_ddt?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_ddt",
                  `dati_ddt_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_ddt != null,
   })

   const [linesDetail, setLinesDetail] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dettaglio_linee",
      name: "fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee",
      sectionTranslationId: "gov_it.invoice_fix.sections.dettaglio_linea",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dettaglio_linee",
                  `dettaglio_linee_${index}`,
                  index
               )
         ) ?? [
            buildGeneralDataSection(
               "simple",
               "dettaglio_linee",
               `dettaglio_linee_0`,
               0
            ),
         ],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_beni_servizi
            .dettaglio_linee != null,
   })

   const [datiRiepilogo, setDatiRiepilogo] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_riepilogo",
      name: "fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_riepilogo",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_riepilogo",
                  `dati_riepilogo_${index}`,
                  index
               )
         ) ?? [
            buildGeneralDataSection(
               "simple",
               "dati_riepilogo",
               `dati_riepilogo_0`,
               0
            ),
         ],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_beni_servizi
            .dati_riepilogo != null,
   })

   const [datiContratto, setDatiContratto] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_contratto",
      name: "fattura_elettronica_body[0].dati_generali.dati_contratto",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_contratto",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_contratto?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_contratto",
                  `dati_contratto_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_contratto != null,
   })

   const [datiConvenzione, setDatiConvenzione] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_convenzione",
      name: "fattura_elettronica_body[0].dati_generali.dati_convenzione",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_convenzione",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_convenzione?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_convenzione",
                  `dati_convenzione_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_convenzione != null,
   })

   const [datiSal, setDatiSal] = React.useState<FieldGroupByName<"simple">>({
      key: "dati_sal",
      name: "fattura_elettronica_body[0].dati_generali.dati_sal",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_sal",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_sal?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_sal",
                  `dati_sal_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_sal != null,
   })

   const [datiRicezione, setDatiRicezione] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_ricezione",
      name: "fattura_elettronica_body[0].dati_generali.dati_ricezione",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_ricezione",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_ricezione?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_ricezione",
                  `dati_ricezione_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_ricezione != null,
   })

   const [datiFattureCollegate, setDatiFattureCollegate] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_fatture_collegate",
      name: "fattura_elettronica_body[0].dati_generali.dati_fatture_collegate",
      sectionTranslationId:
         "gov_it.invoice_fix.sections.dati_fatture_collegate",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_fatture_collegate?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_fatture_collegate",
                  `dati_fatture_collegate_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_fatture_collegate != null,
   })

   const [datiOrdineAcquisto, setDatiOrdineAcquisto] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_ordine_acquisto",
      name: "fattura_elettronica_body[0].dati_generali.dati_ordine_acquisto",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_ordine_acquisto",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali.dati_ordine_acquisto?.map(
            (_, index) =>
               buildGeneralDataSection(
                  "simple",
                  "dati_ordine_acquisto",
                  `dati_ordine_acquisto_${index}`,
                  index
               )
         ) ?? [],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_generali
            .dati_ordine_acquisto != null,
   })

   const [datiPagamento, setDatiPagamento] = React.useState<
      FieldGroupByName<"simple">
   >({
      key: "dati_pagamento",
      name: "fattura_elettronica_body[0].dati_pagamento",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_pagamento",
      sections:
         data?.parsedPayload?.fattura_elettronica_body[0].dati_pagamento?.map(
            (item, index) => {
               let section: FieldGroup = buildGeneralDataSection(
                  "simple",
                  "dati_pagamento",
                  `dati_pagamento_${index}`,
                  index
               )

               item.dettaglio_pagamento?.map((_, childIndex) => {
                  section.children?.push(
                     buildSubSectionDatiPagamento(
                        "dettaglio_pagamento",
                        childIndex,
                        index
                     )
                  )
               })

               return section
            }
         ) ?? [
            {
               ...buildGeneralDataSection(
                  "simple",
                  "dati_pagamento",
                  `dati_pagamento_0`,
                  0
               ),
               children: [
                  buildSubSectionDatiPagamento("dettaglio_pagamento", 0, 0),
               ],
            },
         ],
      childrenListNames: [
         {
            key: "dettaglio_pagamento",
            sectionTranslationId:
               "gov_it.invoice_fix.sections.dettaglio_pagamento",
         },
      ],
      initialExist:
         mode === "create" ||
         project === "invoice_draft" ||
         data?.parsedPayload?.fattura_elettronica_body[0].dati_pagamento !=
            null,
   })

   const updateGroupSection = (
      sectionKey: FieldGroupByNameKey<"simple">,
      action?: "remove" | "add",
      index?: number,
      parentIndex?: number
   ) => {
      if (action == null) action = "add"

      switch (sectionKey) {
         case "dati_contratto":
            switch (action) {
               case "add":
                  setDatiContratto((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           sectionKey,
                           `${sectionKey}_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiContratto((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_ddt":
            switch (action) {
               case "add":
                  setDatiDdt((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_ddt",
                           `dati_ddt_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiDdt((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_convenzione":
            switch (action) {
               case "add":
                  setDatiConvenzione((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_convenzione",
                           `dati_convenzione_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiConvenzione((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_sal":
            switch (action) {
               case "add":
                  setDatiSal((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_sal",
                           `dati_sal_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiSal((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_ricezione":
            switch (action) {
               case "add":
                  setDatiRicezione((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_ricezione",
                           `dati_ricezione_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiRicezione((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_fatture_collegate":
            switch (action) {
               case "add":
                  setDatiFattureCollegate((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_fatture_collegate",
                           `dati_fatture_collegate_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiFattureCollegate((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_ordine_acquisto":
            switch (action) {
               case "add":
                  setDatiOrdineAcquisto((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_ordine_acquisto",
                           `dati_ordine_acquisto_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiOrdineAcquisto((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_pagamento":
            switch (action) {
               case "add":
                  setDatiPagamento((prevState) => {
                     let sections = [...prevState.sections]
                     let section = buildGeneralDataSection(
                        "simple",
                        "dati_pagamento",
                        `dati_pagamento_${sections.length}`,
                        sections.length
                     )
                     section.children = []
                     section.children.push(
                        buildSubSectionDatiPagamento(
                           "dettaglio_pagamento",
                           0,
                           sections.length
                        )
                     )

                     return {
                        ...prevState,
                        sections: [...sections, section],
                     }
                  })
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiPagamento((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dettaglio_pagamento":
            switch (action) {
               case "add":
                  if (parentIndex == null) {
                     return
                  }
                  setDatiPagamento((prevState) => {
                     const sections = [...prevState.sections]
                     sections[parentIndex]?.children?.push(
                        buildSubSectionDatiPagamento(
                           `dettaglio_pagamento`,
                           sections[parentIndex]?.children?.length ?? 0,
                           parentIndex!
                        )
                     )
                     return {
                        ...prevState,
                        sections,
                     }
                  })
                  break
               case "remove":
                  if (parentIndex == null || index == null) {
                     return
                  }
                  setDatiPagamento((prevState) => {
                     const sections = [...prevState.sections]
                     sections[parentIndex!].children = sections[
                        parentIndex!
                     ]?.children?.filter((_, i) => i !== index)
                     return {
                        ...prevState,
                        sections,
                     }
                  })
                  break
               default:
                  break
            }
            break
         case "dettaglio_linee":
            switch (action) {
               case "add":
                  setLinesDetail((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dettaglio_linee",
                           `dettaglio_linee_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setLinesDetail((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         case "dati_riepilogo":
            switch (action) {
               case "add":
                  setDatiRiepilogo((prevState) => ({
                     ...prevState,
                     sections: [
                        ...prevState.sections,
                        buildGeneralDataSection(
                           "simple",
                           "dati_riepilogo",
                           `dati_riepilogo_${prevState.sections.length}`,
                           prevState.sections.length
                        ),
                     ],
                  }))
                  break
               case "remove":
                  if (index == null) {
                     return
                  }
                  setDatiRiepilogo((prevState) => ({
                     ...prevState,
                     sections: prevState.sections.filter((_, i) => i !== index),
                  }))
                  break
               default:
                  break
            }
            break
         default:
            break
      }
   }

   const compareValuesWithInitialValues = (values: object) => {
      return _.isEqual(values, initialValues)
   }

   const handleSubmit = async (values: FormValues, helpers: HelperType) => {
      setErrors([])
      setMessage(null)
      const isFormValuesEqualToInitialValues =
         compareValuesWithInitialValues(values)
      switch (project) {
         case "invoice_draft":
            await handleSubmitInvoiceDraft(values, helpers)
            break
         case "invoice_sent":
            if (isFormValuesEqualToInitialValues) {
               enqueueSnackbar(t("gov_it.invoice_fix.same_invoice_send"), {
                  variant: "error",
               })
               return
            }
            await handleSubmitInvoiceSent(values, helpers)
            break
         case "invoice_fix":
            if (isFormValuesEqualToInitialValues) {
               enqueueSnackbar(t("gov_it.invoice_fix.same_invoice_send"), {
                  variant: "error",
               })
               return
            }
            await handleSubmitInvoiceFix(values, helpers)
            break
      }
   }

   const handleSubmitInvoiceDraft = async (
      values: FormValues,
      helpers: HelperType
   ) => {
      if (modeDraft == null) {
         return
      }
      const isFormValuesEqualToInitialValues =
         compareValuesWithInitialValues(values)

      values = cleanedJsonObject(values)
      switch (modeDraft) {
         case "send":
            if (isFormValuesEqualToInitialValues) {
               return await sendDraftInvoiceValues(helpers)
            }
            return await createOrUpdateDraftInvoiceValues(
               values,
               helpers,
               "edit"
            ).then(async () => {
               return await sendDraftInvoiceValues(helpers)
            })
         case "edit":
         case "new":
            return await createOrUpdateDraftInvoiceValues(values, helpers)
      }
   }

   const handleSubmitInvoiceSent = async (
      values: FormValues,
      { setError }: HelperType
   ) => {
      setMessage(null)
      values = cleanedJsonObject(values)

      const response = await createInvoice({
         type: "simple",
         body: values,
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         setViolationsResponse(data, setError)

         return
      }

      if ("data" in response) {
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         const { uuid } = response.data

         navigate("/customer-invoice")

         dispatch(
            openDialog({
               id: "gov_it_invoices_details",
               data: { invoiceUuid: uuid, mode: "info" },
            })
         )
      }
   }

   const handleSubmitInvoiceFix = async (
      values: FormValues,
      { setError }: HelperType
   ) => {
      if (data == null) {
         return null
      }
      setMessage(null)
      values = cleanedJsonObject(values)

      const response = await createInvoice({
         type: "simple",
         body: values,
         ...(isFixInvoice &&
            invoiceToFixUuid && {
               invoiceToFixUuid,
               isFixInvoice,
            }),
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         setViolationsResponse(data, setError)

         return
      }

      if ("data" in response) {
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         const { uuid } = response.data

         navigate("/customer-invoice")

         dispatch(
            openDialog({
               id: "gov_it_invoices_details",
               data: { invoiceUuid: uuid, mode: "info" },
            })
         )
      }
   }

   const createOrUpdateDraftInvoiceValues = async (
      values: FormValues,
      { setError }: HelperType,
      modeInternal?: "new" | "edit"
   ) => {
      if (modeInternal == null && modeDraft === "send") return
      if (modeInternal == null && modeDraft !== "send") modeInternal = modeDraft

      setMessage(null)
      setErrors([])

      values = cleanedJsonObject(values)
      let response
      if (modeInternal === "new") {
         response = await createDraftInvoice({
            body: values,
            format: "json",
            type: "simple",
            blockInvoiceSent: blockSendDraft === true,
         })
      } else if (modeInternal === "edit") {
         response = await updateDraftInvoice({
            body: values,
            format: "json",
            uuid: data?.uuid as string,
            type: "simple",
            blockInvoiceSent: blockSendDraft === true,
         })
      } else {
         return
      }

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })

            const data = response.error.data as {
               violations?: { propertyPath: string; message: string }[]
            } | null
            setViolationsResponse(data, setError)

            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors([
               {
                  propertyPath: "generic",
                  message: (response.error.data as any).detail as string,
               },
            ])
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }
         setErrors([genericError as ViolationType])
         return
      }

      if ("data" in response) {
         if (modeInternal === "new") {
            const { uuid } = response.data
            setMessage(
               `${t(
                  "gov_it.invoice_drafts.invoice_created"
               )} - Document UUID ${uuid}`
            )
         } else {
            setMessage(`${t("gov_it.invoice_drafts.invoice_draft_update")}`)
         }

         enqueueSnackbar(
            modeInternal === "new"
               ? t("gov_it.invoice_drafts.invoice_created")
               : t("gov_it.invoice_drafts.invoice_draft_update"),
            {
               variant: "success",
            }
         )

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const sendDraftInvoiceValues = async ({ setError }: HelperType) => {
      setMessage(null)
      if (blockSendDraft) {
         setErrors([
            {
               propertyPath: "",
               message: t(
                  "gov_it.invoice_drafts.invoice_draft_send_error_blocked"
               ),
            },
         ])
         enqueueSnackbar(
            t("gov_it.invoice_drafts.invoice_draft_send_error_blocked"),
            {
               variant: "error",
            }
         )

         return
      }

      const response = await sendDraftInvoice({
         uuid: data?.uuid as string,
         type: "simple",
      })

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })

            const data = response.error.data as {
               violations?: { propertyPath: string; message: string }[]
            } | null
            setViolationsResponse(data, setError)

            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors([
               {
                  propertyPath: "Generic",
                  message: (response.error.data as any).detail as string,
               },
            ])
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }

         return
      }

      if ("data" in response) {
         setMessage(`${t("gov_it.invoice_drafts.invoice_draft_send")}`)

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         setIsValidForm(false)
         const { uuid } = response.data
         dispatch(closeDialog())
         dispatch(
            openDialog({
               id: "alert.modal",
               data: {
                  title: t("gov_it.invoice_drafts.invoice_draft_send"),
                  message: `${t(
                     "gov_it.invoice_drafts.invoice_draft_send"
                  )} - Document UUID ${uuid}`,
                  type: "success",
                  children: (
                     <Button
                        color={"primary"}
                        variant="contained"
                        size="medium"
                        onClick={() => {
                           persistTableRefresh("customer.invoices")
                           dispatch(
                              updateRefetch({
                                 table: "customer.invoices",
                              })
                           )
                           navigate(`/customer-invoice`)
                           dispatch(
                              openDialog({
                                 id: "gov_it_invoices_details",
                                 data: {
                                    invoiceUuid: uuid,
                                    mode: "info",
                                 },
                              })
                           )
                        }}
                     >
                        {t("Invoices.go_to_invoice")}
                     </Button>
                  ),
               },
            })
         )
      }
   }

   const handleValidate = async (
      values: FormValues,
      setError: any,
      getValues: any
   ) => {
      setErrors([])
      setMessage(null)
      values = cleanedJsonObject(values)

      const response = await validateInvoice(values)

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         if ("violations" in (response.error.data as any)) {
            const violations = (response.error.data as ValidateInvoiceEntity)
               ?.violations

            setViolationsResponse(data, setError)

            violations?.forEach(
               ({ propertyPath, propertyContent, message }) => {
                  const propertyPaths = getViolationPropertyPath(
                     mapPropertyPathToFormField(camelToSnakeCase(propertyPath)),
                     propertyContent,
                     getValues,
                     sections
                  )
                  propertyPaths.forEach((path) => {
                     setError(mapPropertyPathToFormField(path), {
                        type: "validation Error",
                        message,
                     })
                  })
               }
            )

            //traverseAndHandleErrors(values, violations, setFieldError)
            setErrors((response.error.data as any).violations)
            setIsValidForm(false)
            enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
               variant: "error",
            })
            return
         }

         if ("detail" in (response.error.data as any)) {
            if (response.error.status === 400) {
               const violations: ViolationType[] = []
               violationsRegex.forEach(({ regex, propertyNames }) => {
                  let temps = parseResult(
                     ((response.error as any).data as any).detail,
                     regex,
                     propertyNames
                  )
                  if (temps) {
                     violations.push(temps as ViolationType)
                  }
               })

               if (violations.length > 0) {
                  violations?.forEach(
                     ({ propertyPath, propertyContent, message }) => {
                        const propertyPaths = getViolationPropertyPath(
                           camelToSnakeCase(propertyPath),
                           propertyContent ?? null,
                           getValues,
                           sections
                        )

                        propertyPaths.forEach((path) => {
                           setError(mapPropertyPathToFormField(path), {
                              type: "validation Error",
                              message,
                           })
                        })
                     }
                  )
                  //traverseAndHandleErrors(values, violations, setError)
                  setErrors(violations)
                  setIsValidForm(false)
                  enqueueSnackbar(t("gov_it.invoice_fix.invoice_not_valid"), {
                     variant: "error",
                  })
               }

               return
            }

            setErrors(
               response.error.status !== 500
                  ? [
                       {
                          propertyPath: "generic",
                          message: (response.error.data as any)
                             .detail as string,
                       },
                    ]
                  : [genericError as ViolationType]
            )

            enqueueSnackbar(
               response.error.status !== 500
                  ? (response.error.data as any).detail
                  : genericError.message,
               {
                  variant: "error",
               }
            )
            return
         }
         setErrors([genericError as ViolationType])

         return
      }

      if ("data" in response) {
         setIsValidForm(true)
         enqueueSnackbar(t("gov_it.invoice_fix.invoice_valid"), {
            variant: "success",
         })
         setErrors([])
         setMessage(t("gov_it.invoice_fix.invoice_valid"))
         return
      }
   }

   const indexAtWhichAppendLinesDetails =
      getCommonFieldsGrouped("simple").findIndex(
         ({ sectionTranslationId }) =>
            sectionTranslationId ===
            "gov_it.invoice_fix.sections.dati_generali_documento"
      ) + 1

   const formFieldsGrouped = getCommonFieldsGrouped("simple")
   const sections1 = [
      ...formFieldsGrouped.slice(0, indexAtWhichAppendLinesDetails),
   ]

   const sectionsVariable: FieldGroupByName<"simple">[] = [
      linesDetail,
      datiDdt,
      datiOrdineAcquisto,
      datiContratto,
      datiConvenzione,
      datiRicezione,
      datiFattureCollegate,
      datiSal,
      datiRiepilogo,
      datiPagamento,
   ]
   const sections2 = [
      ...formFieldsGrouped.slice(indexAtWhichAppendLinesDetails),
   ]
   const sections = [
      ...formFieldsGrouped.slice(0, indexAtWhichAppendLinesDetails),
      ...linesDetail.sections,
      ...datiDdt.sections,
      ...datiOrdineAcquisto.sections,
      ...datiContratto.sections,
      ...datiConvenzione.sections,
      ...datiRicezione.sections,
      ...datiFattureCollegate.sections,
      ...datiSal.sections,
      ...datiRiepilogo.sections,
      ...formFieldsGrouped.slice(indexAtWhichAppendLinesDetails),
      ...datiPagamento.sections,
   ]

   const getInitialValues = () => {
      return mode === "create"
         ? emptyInvoiceFields({
              cedente_prestatore: buildCedentePrestatoreInvoicePayload(
                 userData ?? null
              ),
           })
         : isCreditNoteCreated
         ? ({
              ...data?.parsedPayload,
              fattura_elettronica_body: [
                 {
                    ...data?.parsedPayload?.fattura_elettronica_body[0],
                    dati_generali: {
                       ...data?.parsedPayload?.fattura_elettronica_body[0]
                          .dati_generali,
                       dati_generali_documento: {
                          ...data?.parsedPayload?.fattura_elettronica_body[0]
                             .dati_generali.dati_generali_documento,
                          tipo_documento: "TD04",
                       },
                    },
                 },
              ],
           } as FormValues)
         : (data?.parsedPayload as FormValues)
   }

   const initialValues = getInitialValues()

   return (
      <BodyForms
         project={project}
         modeDraft={modeDraft}
         sections1={sections1}
         sectionsVariable={sectionsVariable}
         sections2={sections2}
         validationSchema={validationSchemaForFixInvoice}
         isCreditNoteCreated={isCreditNoteCreated}
         disableFields={
            isCreditNoteCreated
               ? [
                    "fattura_elettronica_body[0].dati_generali.dati_generali_documento.tipo_documento",
                 ]
               : []
         }
         initialValues={initialValues}
         handleSubmit={handleSubmit}
         handleValidate={handleValidate}
         updateGroupSection={updateGroupSection}
         setIsValidForm={setIsValidForm}
         isLoading={isLoading}
         isValidForm={isValidForm}
         type="simple"
         errors={errors}
         message={message}
         resetErrors={() => setErrors([])}
         resetMessage={() => setMessage(null)}
      />
   )
}

export default GovITInvoiceSimpleForm
