import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import { format, parseISO } from "date-fns"
import { download } from "helper/download"
import { bytesToKB } from "helper/formatBytes"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
   GovITInvoicePreservationDocument,
   useLazyGetInvoicePreservationDocumentQuery,
   useLazyGetPreservationDocumentReceiptQuery,
} from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import { selectRefetch, TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import InvoicePreservedDocumentButton, {
   BoxAlert,
} from "views/common/InvoiceDetails/invoicePreservedDocumentButton"
import PreservedDocumentStatus from "views/common/PreservedDocumentStatus"

type Props = {
   invoice: GovITInvoice
   table: TableName
   preservedDocument: GovITInvoicePreservationDocument | null
}
const InvoicePreservation = ({ invoice, table, preservedDocument }: Props) => {
   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation()
   const [getPreservedDocumentReceipt, { error }] =
      useLazyGetPreservationDocumentReceiptQuery()
   const [getPreservedDocument] = useLazyGetInvoicePreservationDocumentQuery()
   const [open, setOpen] = useState(false)
   const dispatch = useAppDispatch()
   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const handlePreservedDocument = async () => {
      const response = await getPreservedDocument({
         invoiceUuid: invoice.uuid,
         ...(refresh && { refresh }),
      })

      if ("error" in response && response.error && "data" in response.error) {
         enqueueSnackbar((response.error.data as any)?.title, {
            variant: "error",
         })
         return
      }

      if ("data" in response && response.data) {
         persistTableRefresh(table)
         setOpen(true)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const downloadReceipt = async () => {
      const response = await getPreservedDocumentReceipt({
         uuid: preservedDocument?.uuid as string,
      })

      if ("error" in response) {
         enqueueSnackbar(error, { variant: "error" })
         return
      }
      download(response.data, preservedDocument?.uuid + ".xml")
   }

   if (preservedDocument == null) {
      return (
         <>
            <Typography variant="body1">
               {t("InvoicePreservation.not_available")}
            </Typography>

            <Box mt={2}>
               <InvoicePreservedDocumentButton
                  uuid={invoice.uuid}
                  handlePreservedDocument={handlePreservedDocument}
               />
            </Box>
         </>
      )
   }

   return (
      <>
         {open && (
            <BoxAlert
               type={"success"}
               title={t("InvoiceDetail.Invoice to preservation succeeded")}
               open={open}
               setOpen={setOpen}
            />
         )}

         <List>
            <ListItem divider={true}>
               <ListItemText primary="UUID" />
               <ListItemSecondaryAction>
                  <ListItemText primary={preservedDocument?.uuid} />
               </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider={true}>
               <ListItemText primary={t("InvoicePreservation.created_at")} />
               <ListItemSecondaryAction>
                  <ListItemText
                     primary={
                        preservedDocument?.created_at &&
                        format(
                           parseISO(preservedDocument?.created_at),
                           "dd MMM yyyy HH:mm"
                        )
                     }
                  />
               </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider={true}>
               <ListItemText primary={t("InvoicePreservation.status")} />
               <ListItemSecondaryAction>
                  <Box display="flex">
                     {preservedDocument?.status === "stored" && (
                        <Button
                           variant="contained"
                           color="primary"
                           onClick={downloadReceipt}
                        >
                           {t("InvoicePreservation.download_receipt")}
                        </Button>
                     )}
                     <ListItemText
                        primary={
                           <PreservedDocumentStatus
                              value={preservedDocument?.status}
                           />
                        }
                     />
                  </Box>
               </ListItemSecondaryAction>
            </ListItem>

            {/* Error message, it is set if status === 'error' */}
            {preservedDocument?.message && (
               <ListItem divider={true}>
                  <ListItemText primary={t("InvoicePreservation.message")} />
                  <ListItemSecondaryAction>
                     <ListItemText primary={preservedDocument.message} />
                  </ListItemSecondaryAction>
               </ListItem>
            )}

            <ListItem divider={true}>
               <ListItemText
                  primary={t("InvoicePreservation.receipt_received_at")}
               />
               <ListItemSecondaryAction>
                  <ListItemText
                     primary={
                        preservedDocument?.receipt_received_at &&
                        format(
                           parseISO(preservedDocument?.receipt_received_at),
                           "dd MMM yyyy HH:mm"
                        )
                     }
                  />
               </ListItemSecondaryAction>
            </ListItem>

            {preservedDocument?.weight && (
               <ListItem divider={true}>
                  <ListItemText primary={t("InvoicePreservation.weight")} />
                  <ListItemSecondaryAction>
                     <ListItemText
                        primary={bytesToKB(preservedDocument.weight)}
                     />
                  </ListItemSecondaryAction>
               </ListItem>
            )}
         </List>
      </>
   )
}

type TabPanelPreservationProps = {
   value: number
   index: number
   invoice: GovITInvoice
   table: TableName
   preservedDocument: GovITInvoicePreservationDocument | null
}
export default function TabPanelPreservation({
   value,
   index,
   invoice,
   table,
   preservedDocument,
}: TabPanelPreservationProps) {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && invoice?.uuid && (
            <Box p={3}>
               <InvoicePreservation
                  invoice={invoice}
                  table={table}
                  preservedDocument={preservedDocument}
               />
            </Box>
         )}
      </div>
   )
}
