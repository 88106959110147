import getParameter from "../request/getParameter"

export default function formatWebhooks(response, page, rowsPerPage) {
   const items = response["hydra:member"].map((row) => {
      return {
         id: row["@id"],
         uuid: row.uuid,
         event: row.event,
         url: row.url,
         createdAt: row.createdAt,
      }
   })

   const view = response["hydra:view"]

   return {
      count: response["hydra:totalItems"],
      first: view ? getParameter(view["hydra:first"], "page") : null,
      last: view ? getParameter(view["hydra:last"], "page") : null,
      next: view ? getParameter(view["hydra:next"], "page") : null,
      previous: view ? getParameter(view["hydra:previous"], "page") : null,
      items: items,
   }
}
