import { DialogContent, DialogTitle, makeStyles } from "@material-ui/core"
import BaseDialog from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { SmartReceipt } from "services/gov-it/smartReceipt/type"
import { DialogId } from "store/slices/dialogs"
import { TableName } from "store/tablesState"
import CreateSmartReceiptForm from "./CreateSmartReceiptForm"
import ReturnItemsSmartReceiptForm from "./ReturnItemsSmartReceiptForm"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID: DialogId = "smartReceipt.form"

type ContentProps =
   | { mode: "new"; handleClose: VoidFunction; table: TableName }
   | {
        mode: "return"
        item: SmartReceipt
        handleClose: VoidFunction
        table: TableName
     }

const Content = (props: ContentProps) => {
   const { t } = useTranslation()
   const classes = useStyles()

   const content = () => {
      switch (props.mode) {
         case "new":
            return <CreateSmartReceiptForm table={props.table} />
         case "return":
            return (
               <ReturnItemsSmartReceiptForm
                  item={props.item}
                  table={props.table}
               />
            )
      }
   }

   const title = () => {
      switch (props.mode) {
         case "new":
            return t("smartReceipt.create_dialog_title")
         case "return":
            return t("smartReceipt.return_dialog_title")
      }
   }

   return (
      <>
         <DialogTitle>{title()}</DialogTitle>

         <DialogContent className={classes.content}>{content()}</DialogContent>
      </>
   )
}

type CreateSmartReceiptDialogProps = {
   table: TableName
}
const CreateSmartReceiptDialog = ({ table }: CreateSmartReceiptDialogProps) => (
   <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
      {(data) => <Content {...data} table={table} />}
   </BaseDialog>
)

export default CreateSmartReceiptDialog
