import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import EditPreSaleActionDialog from "./EditPreSaleActionDialog"
import Items from "./Items"
import NewPreSaleActionCTA from "./NewPreSaleActionCTA"
import NewPreSaleActionDialog from "./NewPreSaleActionDialog"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const PreSaleActionsPage = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <Page
            className={classes.root}
            title={t("settings.pre_sale_actions.title")}
         >
            <Container maxWidth={false}>
               <Box mb={3}>
                  <NewPreSaleActionCTA />
               </Box>

               <Items />
            </Container>
         </Page>

         <NewPreSaleActionDialog />
         <EditPreSaleActionDialog />
      </>
   )
}

export default PreSaleActionsPage
