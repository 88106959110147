import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { PreSaleAction } from "services/commonApi"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   preSaleAction: PreSaleAction
}

const EditPreSaleActionCTA = ({ preSaleAction }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const handleOpenDialog = () => {
      dispatch(
         openDialog({
            id: "settings_edit_pre_sale_action",
            data: preSaleAction,
         })
      )
   }

   return (
      <Button color="primary" variant="contained" onClick={handleOpenDialog}>
         {t("settings.pre_sale_actions.edit_pre_sale_action_cta")}
      </Button>
   )
}

export default EditPreSaleActionCTA
