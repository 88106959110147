import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableFilters,
   updateFilter,
} from "store/tablesState"
import DatePicker from "views/common/DatePicker"
import type { FormValues, TableFilter } from "./utils"
import {
   mapFormValuesToTableFilters,
   mapTableFiltersToFormValues,
} from "./utils"

const invoiceTypes = ["VAT", "KOR", "ZAL", "ROZ", "UPR", "KOR_ZAL", "KOR_ROZ"]

const statuses = [
   "valid",
   "sent",
   "delivered",
   "confirmed",
   "received",
   "rejected",
   "error",
   "unknown",
] as const

type Props = {
   direction: "incoming" | "outgoing"
}

const TableFilters = ({ direction }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const table = `pl.${direction}_invoices` as const

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )
   const initialValues = mapTableFiltersToFormValues(table, filters)

   const handleFilter = (values: FormValues) => {
      const updatedFilters = mapFormValuesToTableFilters(table, values)

      batch(() => {
         Object.entries(updatedFilters).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]

            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   const mappedStatuses = statuses
      .filter((status) => direction !== "incoming" || status !== "sent")
      .filter((status) => direction !== "outgoing" || status !== "received")

   return (
      <Box mb={4}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleFilter}
            enableReinitialize
         >
            {({
               values,
               errors,
               touched,
               handleBlur,
               handleChange,
               handleSubmit,
               resetForm,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Accordion elevation={1}>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${direction}_invoices_content`}
                        id={`${direction}_invoices_filters`}
                     >
                        <Typography variant="body1">
                           {t("Default.Filtra i risultati")}
                        </Typography>
                     </AccordionSummary>

                     <AccordionDetails>
                        <Box display="flex" flexDirection="column" flexGrow={1}>
                           <Box display="flex" gridGap={16}>
                              <DatePicker
                                 value={values.createdAfter}
                                 name="createdAfter"
                                 label="pl.invoices.filters.created_after"
                                 helperText={errors.createdAfter}
                                 onBlur={handleBlur}
                                 autoSelectEndOfTheDay
                                 fullWidth
                              />

                              <DatePicker
                                 name="createdBefore"
                                 label="pl.invoices.filters.created_before"
                                 helperText={errors.createdBefore}
                                 onBlur={handleBlur}
                                 autoSelectEndOfTheDay
                                 fullWidth
                              />
                           </Box>

                           <Box display="flex" gridGap={16} mt={2}>
                              <DatePicker
                                 name="invoiceDateAfter"
                                 label="pl.invoices.filters.invoice_date_after"
                                 helperText={errors.invoiceDateAfter}
                                 onBlur={handleBlur}
                                 autoSelectEndOfTheDay
                                 fullWidth
                              />

                              <DatePicker
                                 name="invoiceDateBefore"
                                 label="pl.invoices.filters.invoice_date_before"
                                 helperText={errors.invoiceDateBefore}
                                 onBlur={handleBlur}
                                 autoSelectEndOfTheDay
                                 fullWidth
                              />
                           </Box>

                           <Box display="flex" gridGap={16} mt={2}>
                              <TextField
                                 value={values.invoiceRecipientNip}
                                 error={
                                    touched.invoiceRecipientNip != null &&
                                    errors.invoiceRecipientNip != null
                                 }
                                 fullWidth
                                 helperText={errors.invoiceRecipientNip}
                                 label={t(
                                    "pl.invoices.filters.invoice_recipient_nip"
                                 )}
                                 name="invoiceRecipientNip"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 type="text"
                              />

                              <TextField
                                 value={values.invoiceCreatorNip}
                                 error={
                                    touched.invoiceCreatorNip != null &&
                                    errors.invoiceCreatorNip != null
                                 }
                                 fullWidth
                                 helperText={errors.invoiceCreatorNip}
                                 label={t(
                                    "pl.invoices.filters.invoice_creator_nip"
                                 )}
                                 name="invoiceCreatorNip"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 type="text"
                              />

                              <TextField
                                 value={values.legalEntityUuid}
                                 error={
                                    touched.legalEntityUuid != null &&
                                    errors.legalEntityUuid != null
                                 }
                                 fullWidth
                                 helperText={errors.legalEntityUuid}
                                 label={t(
                                    "pl.invoices.filters.legal_entity_uuid"
                                 )}
                                 name="legalEntityUuid"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 type="text"
                              />
                           </Box>

                           <Box display="flex" gridGap={16} mt={2}>
                              <FormControl fullWidth>
                                 <InputLabel>
                                    {t("pl.invoices.filters.invoice_type")}
                                 </InputLabel>

                                 <Select
                                    value={values.invoiceType}
                                    name="invoiceType"
                                    onChange={handleChange}
                                    label="Invoice Type"
                                 >
                                    {invoiceTypes.map((value) => (
                                       <MenuItem key={value} value={value}>
                                          {value}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>

                              <FormControl fullWidth>
                                 <InputLabel>
                                    {t("pl.invoices.filters.status")}
                                 </InputLabel>

                                 <Select
                                    value={values.status}
                                    name="status"
                                    onChange={handleChange}
                                    label="Status"
                                 >
                                    {mappedStatuses.map((value) => (
                                       <MenuItem key={value} value={value}>
                                          {value}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>
                           </Box>

                           <Box display="flex" justifyContent="flex-end" mt={2}>
                              <Box mr={2}>
                                 <Button
                                    onClick={() => {
                                       dispatch(resetFilters({ table }))
                                       resetForm()
                                    }}
                                    variant="contained"
                                 >
                                    {t("Default.Cancella")}
                                 </Button>
                              </Box>
                              <Button
                                 type="submit"
                                 color="primary"
                                 variant="contained"
                              >
                                 {t("Default.Cerca")}
                              </Button>
                           </Box>
                        </Box>
                     </AccordionDetails>
                  </Accordion>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default TableFilters
