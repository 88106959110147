import { useEffect, useState } from "react"

const useApiOnMount = (apiCall, ...apiParams) => {
   const [response, setResponse] = useState()
   const [error, setError] = useState()

   useEffect(() => {
      ;(async () => {
         try {
            const res = await apiCall(...apiParams)
            setResponse(res)
         } catch (e) {
            setError(e.message)
         }
      })()
   }, [])

   return [response, error]
}

export default useApiOnMount
