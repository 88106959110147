import { IconButton, Tooltip } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { useTranslation } from "react-i18next"
import { OpenbankingBusinessRegistry } from "services/openbankingApi/businessRegistry"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   businessRegistry: OpenbankingBusinessRegistry
}

const EditBusinessRegistryCTA = ({ businessRegistry }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const openEditBusinessRegistryDialog = async () => {
      dispatch(
         openDialog({
            id: "openbanking_edit_business_registry",
            data: businessRegistry,
         })
      )
   }

   return (
      <Tooltip
         title={t("openbanking.business_registry.edit_business_registry_cta")}
      >
         <IconButton onClick={openEditBusinessRegistryDialog}>
            <EditIcon />
         </IconButton>
      </Tooltip>
   )
}

export default EditBusinessRegistryCTA
