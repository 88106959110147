import PropTypes from "prop-types"
import { HorizontalBar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import {
   Box,
   Card,
   CardContent,
   colors,
   Grid,
   Typography,
} from "@material-ui/core"
import { numberFormatter } from "helper/numberFormater"

const NOTIFICATIONS_COLOR = {
   AT: colors.pink[300],
   DT: colors.cyan[300],
   MC: colors.amber[300],
   NE: colors.green[300],
   NS: colors.purple[300],
   RC: colors.deepOrange[300],
}

function GetDetails(props) {
   const { i18n } = useTranslation()
   return (
      <Box
         p={2}
         display="flex"
         flexDirection={{
            xs: "row",
            md: "column",
            xl: "row",
         }}
         justifyContent="space-between"
         flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
         {props.details.map(({ title, color, value }) => (
            <Box key={title} textAlign="center">
               <Typography color="textPrimary" variant="body1">
                  {title}
               </Typography>
               <Typography style={{ color }} variant="h3">
                  {numberFormatter(i18n.language, value)}
               </Typography>
            </Box>
         ))}
      </Box>
   )
}

GetDetails.propTypes = {
   details: PropTypes.any,
   callbackfn: PropTypes.func,
}
const SdiNotifications = ({ className, data }) => {
   const { t, i18n } = useTranslation()

   const totalNotifications = Object.keys(data)
      .map((d) => data[d])
      .reduce((acc, val) => acc + val, 0)

   const barData = {
      datasets: [
         {
            data: [data.at, data.dt, data.mc, data.ne, data.ns, data.rc],
            label: "Count ",
            backgroundColor: [
               NOTIFICATIONS_COLOR.AT,
               NOTIFICATIONS_COLOR.DT,
               NOTIFICATIONS_COLOR.MC,
               NOTIFICATIONS_COLOR.NE,
               NOTIFICATIONS_COLOR.NS,
               NOTIFICATIONS_COLOR.RC,
            ],
            barThickness: 10,
            borderWidth: 0,
         },
      ],
      labels: ["AT", "DT", "MC", "NE", "NS", "RC"],
   }

   const options = {
      animation: false,
      cornerRadius: 20,
      layout: { padding: 0 },
      legend: { display: false },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
         yAxes: [
            {
               beginAtZero: true,
            },
         ],
      },
   }

   const details = [
      {
         title: "AT",
         value: data.at,
         color: NOTIFICATIONS_COLOR.AT,
      },
      {
         title: "DT",
         value: data.dt,
         color: NOTIFICATIONS_COLOR.DT,
      },
      {
         title: "MC",
         value: data.mc,
         color: NOTIFICATIONS_COLOR.MC,
      },
      {
         title: "NE",
         value: data.ne,
         color: NOTIFICATIONS_COLOR.NE,
      },
      {
         title: "NS",
         value: data.ns,
         color: NOTIFICATIONS_COLOR.NS,
      },
      {
         title: "RC",
         value: data.rc,
         color: NOTIFICATIONS_COLOR.RC,
      },
   ]

   return (
      <Card className={className}>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("statistics.title.sdi_notifications")}
            </Typography>
            <Grid container direction="row" alignItems="center" spacing={2}>
               <Grid item xs={12} md={8}>
                  <Box height={300} position="relative" flexGrow={2}>
                     <HorizontalBar data={barData} options={options} />
                  </Box>
               </Grid>
               <Grid item xs={12} md={4}>
                  <Box p={2} textAlign="center">
                     <Typography color="textPrimary" variant="body1">
                        {t("statistics.text.notifications_received")}
                     </Typography>
                     <Typography variant="h2">
                        {numberFormatter(i18n.language, totalNotifications)}
                     </Typography>
                  </Box>

                  <GetDetails details={details.slice(0, 3)} />

                  <GetDetails details={details.slice(3)} />
               </Grid>
            </Grid>
         </CardContent>
      </Card>
   )
}

SdiNotifications.propTypes = {
   className: PropTypes.string,
   data: PropTypes.object,
}

SdiNotifications.defaultProps = {
   data: {
      at: 0,
      dt: 0,
      mc: 0,
      ne: 0,
      ns: 0,
      rc: 0,
   },
}

export default SdiNotifications
