import getParameter from "../request/getParameter"

export const formatInvoices = (invoicesAPIResponse) => {
   // The latter case handles the single invoice, passed in as an array with one element
   const invoices = invoicesAPIResponse["hydra:member"]
      ? invoicesAPIResponse["hydra:member"]
      : invoicesAPIResponse
   const formattedInvoices = invoices.map((invoice) => {
      return formatInvoice(invoice)
   })

   const view = invoicesAPIResponse["hydra:view"]

   return {
      count:
         invoicesAPIResponse["hydra:totalItems"] !== undefined
            ? invoicesAPIResponse["hydra:totalItems"]
            : 1,
      first:
         view && view["hydra:first"]
            ? parseInt(getParameter(view["hydra:first"], "page"))
            : null,
      last:
         view && view["hydra:last"]
            ? parseInt(getParameter(view["hydra:last"], "page"))
            : null,
      next:
         view && view["hydra:next"]
            ? parseInt(getParameter(view["hydra:next"], "page"))
            : null,
      previous:
         view && view["hydra:previous"]
            ? parseInt(getParameter(view["hydra:previous"], "page"))
            : null,
      items: formattedInvoices,
   }
}

export const formatInvoice = (invoice) => {
   return {
      ...invoice,
      senderFormatted: invoice.sender.name || "",
      recipientFormatted: invoice.recipient.name || "",
   }
}
