export const documentTypes = [
   { code: "TD01", label: "fattura" },
   { code: "TD02", label: "acconto/anticipo su fattura" },
   { code: "TD03", label: "acconto/anticipo su parcella" },
   { code: "TD04", label: "nota di credito" },
   { code: "TD05", label: "nota di debito" },
   { code: "TD06", label: "parcella" },
   { code: "TD16", label: "integrazione fattura reverse charge interno" },
   {
      code: "TD17",
      label: "integrazione/autofattura per acquisto servizi dall'estero",
   },
   { code: "TD18", label: "integrazione per acquisto di beni intracomunitari" },
   {
      code: "TD19",
      label: "integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72",
   },
   {
      code: "TD20",
      label: "autofattura per regolarizzazione e integrazione delle fatture (ex art.6 c.8 e 9-bis d.lgs. 471/97 o art.46 c.5 D.L. 331/93)",
   },
   { code: "TD21", label: "autofattura per splafonamento" },
   { code: "TD22", label: "estrazione beni da Deposito IVA" },
   {
      code: "TD23",
      label: "estrazione beni da Deposito IVA con versamento dell'IVA",
   },
   {
      code: "TD24",
      label: "fattura differita di cui all'art. 21, comma 4, terzo periodo lett. a) DPR 633/72",
   },
   {
      code: "TD25",
      label: "fattura differita di cui all'art. 21, comma 4, terzo periodo lett. b) DPR 633/72",
   },
   {
      code: "TD26",
      label: "cessione di beni ammortizzabili e per passaggi interni (ex art.36 DPR 633/72)",
   },
   {
      code: "TD27",
      label: "fattura per autoconsumo o per cessioni gratuite senza rivalsa",
   },
   { code: "TD28", label: "acquisti da San Marino con IVA (fattura cartacea)" },
]
export const DateInputFields = ["createdAt", "invoiceDate"]

export const CustomerSupplierEmptyValues: FormValues<
   "customer.invoices" | "supplier.invoices"
> = {
   createdAt: "",
   invoiceDate: "",
   uuid: "",
   downloaded: "",
   toPa: "",
   rejectedToFix: false,
   marking: [],
   signed: "",
   stamp: "",
   invoiceNumber: "",
   documentType: "",
   recipient: {
      businessName: "",
      fiscalId: "",
   },
   sender: {
      businessName: "",
      fiscalId: "",
   },
}

const InvoiceDraftsEmptyValues: FormValues<"invoice.drafts"> = {
   createdAt: "",
   invoiceDate: "",
   uuid: "",
   marking: [],
   invoiceNumber: "",
   documentType: "",
   recipient: {
      businessName: "",
      fiscalId: "",
   },
   sender: {
      businessName: "",
      fiscalId: "",
   },
}

export type CustomerSupplierFormValues = {
   createdAt: string
   recipient: {
      businessName: string | null
      fiscalId: string | null
   }
   sender: {
      businessName: string | null
      fiscalId: string | null
   }
   uuid: string
   signed: string
   stamp: string
   downloaded: string
   toPa: string
   rejectedToFix: false
   marking: string[]
   invoiceDate: string
   invoiceNumber: string
   documentType: string
}

type InvoiceDraftsFormValues = {
   createdAt: string
   recipient: {
      businessName: string | null
      fiscalId: string | null
   }
   sender: {
      businessName: string | null
      fiscalId: string | null
   }
   uuid: string
   invoiceDate: string
   invoiceNumber: string
   documentType: string
   marking: string[]
}

export type FormFilterType =
   | "customer.invoices"
   | "supplier.invoices"
   | "invoice.drafts"

export type FormValues<T extends FormFilterType> = T extends
   | "customer.invoices"
   | "supplier.invoices"
   ? CustomerSupplierFormValues
   : InvoiceDraftsFormValues

export const getEmptyValues = <T extends FormFilterType>(type: T) => {
   switch (type) {
      case "customer.invoices":
      case "supplier.invoices":
         return CustomerSupplierEmptyValues as FormValues<T>
      case "invoice.drafts":
         return InvoiceDraftsEmptyValues as FormValues<T>
   }
}
