export const blobToBase64 = (blob: Blob): Promise<string | null> =>
   new Promise((resolve) => {
      const reader = new FileReader()

      const errorHandler = () => {
         resolve(null)
         reader.removeEventListener("error", errorHandler)
      }

      const loadHandler = () => {
         const content = (reader.result as string).replace(/^data:(.*,)?/, "")
         resolve(content)
         reader.removeEventListener("load", loadHandler)
      }

      reader.addEventListener("error", errorHandler)
      reader.addEventListener("load", loadHandler)

      reader.readAsDataURL(blob)
   })

export const base64ToUTF8 = (base64: string) =>
   decodeURIComponent(
      atob(base64)
         .split("")
         .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
         .join("")
   )
