import { ThemeProvider } from "@material-ui/styles"
import ConfirmationDialog from "components/ConfirmationDialog"
import { SnackbarProvider } from "notistack"
import { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { Outlet } from "react-router"
import store from "./store"
import "./style.css"
import theme from "./theme/theme"
import GlobalStyles from "./views/common/components/GlobalStyles"

const weAreInDev = process.env.NODE_ENV === "development"
const shouldLoadMocks =
   weAreInDev && process.env.REACT_APP_ENABLE_MOCKS === "true"

function App() {
   const [mockLoadingCompleted, setMockLoadingCompleted] = useState(
      !shouldLoadMocks
   )

   useEffect(() => {
      ;(async () => {
         if (!mockLoadingCompleted) {
            const { worker } = await import("./mocks/index")
            await worker.start()
         }

         setMockLoadingCompleted(true)
      })()
   }, [mockLoadingCompleted])

   if (!mockLoadingCompleted) {
      return null
   }

   return (
      <Provider store={store}>
         <ThemeProvider theme={theme}>
            <GlobalStyles />

            <SnackbarProvider maxSnack={3}>
               <Outlet />
            </SnackbarProvider>

            <ConfirmationDialog />
         </ThemeProvider>
      </Provider>
   )
}

export default App
