import { zodResolver } from "@hookform/resolvers/zod"
import {
   Box,
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   TextField,
   Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import { useSnackbar } from "notistack"
import { useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   useGetSettingRelatesToSmartReceiptQuery,
   useUpdateSettingsRelatesToSmartReceiptMutation,
} from "services/gov-it/businessRegistryConfigurations"
import { DialogId } from "store/slices/dialogs"
import { z } from "zod"
import Loader from "../../common/Loader"

const schema = z.object({
   phone_number: z.string().nullable().optional(),
   receipts_alert_recipients: z
      .array(
         z.object({
            email: z.string().email("Invalid email"),
         })
      )
      .nonempty("At least one email is required"),
})

type FormValues = z.infer<typeof schema>

const DIALOG_ID: DialogId = "govItUpdateSettingsRelatesToSmartReceipt"

type Props = {
   handleClose: () => void
   id: string
}

function ProcessForm({
   defaultValues,
   id,
   handleClose,
}: {
   defaultValues: FormValues
   id: string
   handleClose: () => void
}) {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [updateSettingsRelatesToSmartReceipt] =
      useUpdateSettingsRelatesToSmartReceiptMutation()

   const {
      handleSubmit,
      register,
      control,
      setError,
      formState: { errors, isSubmitting },
   } = useForm<FormValues>({
      defaultValues,
      resolver: zodResolver(schema),
   })

   const { fields, append, remove } = useFieldArray<FormValues>({
      name: "receipts_alert_recipients",
      control,
   })

   const handleUpdateSettingsRelatesToSmartReceipt = handleSubmit(
      async (values: FormValues) => {
         const body = values.receipts_alert_recipients.map((item) => item.email)

         const response = await updateSettingsRelatesToSmartReceipt({
            id,
            receipts_alert_recipients: body,
            phone_number: values.phone_number ?? null,
         })

         if ("error" in response && "data" in response.error) {
            if ("violations" in (response.error.data as any)) {
               ;(response.error?.data as any)?.violations.forEach(
                  (error: any) => {
                     setError(error.propertyPath, {
                        type: "manual",
                        message: `${error.message} ${
                           error.propertyPath === "phone_number"
                              ? t(
                                   "gov_it.business_registry_configurations.setCredentials.fields.phone_number_international_helper"
                                )
                              : ""
                        }`,
                     })
                  }
               )
               return
            }
            setError("root", {
               type: "manual",
               message:
                  (response.error.data as any)?.detail ??
                  t("Default.generic_error"),
            })
            return
         }
         handleClose()
         enqueueSnackbar(t("global.save_success"), { variant: "success" })
      }
   )

   return (
      <>
         <DialogTitle>
            <Typography variant="h5">
               {t(
                  "gov_it.business_registry_configurations.updateSettingsRelatesToSmartReceipt.title"
               )}
            </Typography>
         </DialogTitle>

         <DialogContent
            style={{
               minHeight: 250,
               scrollbarWidth: "thin",
               scrollbarColor: "transparent",
            }}
         >
            <Grid container spacing={3}>
               {!!errors?.root?.message && (
                  <Grid item xs={12}>
                     <Alert severity="error">{errors.root.message}</Alert>
                  </Grid>
               )}

               {(!!errors?.receipts_alert_recipients?.message ||
                  !!errors?.receipts_alert_recipients?.root?.message) && (
                  <Grid item xs={12}>
                     <Alert severity="error">
                        {errors?.receipts_alert_recipients?.message ||
                           errors?.receipts_alert_recipients?.root?.message}
                     </Alert>
                  </Grid>
               )}

               <Grid item xs={12}>
                  <TextField
                     {...register("phone_number")}
                     disabled={isSubmitting}
                     type="tel"
                     label={t(
                        "gov_it.business_registry_configurations.setCredentials.fields.phone_number"
                     )}
                     name="phone_number"
                     error={!!errors.phone_number}
                     helperText={
                        errors.phone_number?.message ??
                        t(
                           "gov_it.business_registry_configurations.setCredentials.fields.phone_number_international_helper"
                        )
                     }
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12}>
                  <Typography variant="h6">
                     {t(
                        "gov_it.business_registry_configurations.setCredentials.fields.receipts_alert_recipients"
                     )}
                  </Typography>
                  <Box
                     display="flex"
                     flexDirection="column"
                     gridColumnGap={6}
                     gridRowGap={3}
                     sx={{
                        width: "100%",
                        border: "1px solid #e0e0e0",
                        padding: 10,
                        borderRadius: 4,
                     }}
                  >
                     {fields.map((field, index) => (
                        <Box
                           key={field.id}
                           sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr auto",
                              gridColumnGap: 6,
                              alignItems: "center",
                           }}
                        >
                           <TextField
                              {...register(
                                 `receipts_alert_recipients.${index}.email` as const
                              )}
                              disabled={isSubmitting}
                              type="email"
                              label={`${index + 1}. Email`}
                              name={
                                 `receipts_alert_recipients.${index}.email` as const
                              }
                              placeholder={`foo${index + 1}@bar.com`}
                              error={
                                 !!errors?.receipts_alert_recipients?.[index]
                                    ?.email?.message
                              }
                              helperText={
                                 errors?.receipts_alert_recipients?.[index]
                                    ?.email?.message
                              }
                              fullWidth
                           />

                           <Box>
                              <Button
                                 onClick={() => remove(index)}
                                 variant="contained"
                                 color="default"
                              >
                                 {t("global.remove")}
                              </Button>
                           </Box>
                        </Box>
                     ))}
                     <Box
                        display="flex"
                        justifyContent="flex-start"
                        gridColumnGap={6}
                        gridRowGap={3}
                     >
                        <Button
                           onClick={() => append({ email: "" })}
                           variant="contained"
                           color="primary"
                        >
                           {t("global.add")}
                        </Button>
                     </Box>
                  </Box>
               </Grid>
            </Grid>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} variant="contained" color="default">
               {t("global.cancel")}
            </Button>
            <Button
               variant="contained"
               onClick={handleUpdateSettingsRelatesToSmartReceipt}
               color="primary"
               autoFocus
            >
               {t("global.save")}
            </Button>
         </DialogActions>
      </>
   )
}

function Content({ handleClose, id }: Props) {
   const { data, isLoading } = useGetSettingRelatesToSmartReceiptQuery(
      {
         id,
      },
      { skip: !id }
   )

   if (isLoading) return <Loader />

   const defaultValues: FormValues = data
      ? ({
           phone_number: data.phone_number,
           receipts_alert_recipients: data.receipts_alert_recipients.length
              ? data.receipts_alert_recipients.map((item) => ({
                   email: item,
                }))
              : [{ email: "" }],
        } as FormValues)
      : {
           phone_number: null,
           receipts_alert_recipients: [{ email: "" }],
        }

   return (
      <ProcessForm
         defaultValues={defaultValues}
         id={id}
         handleClose={handleClose}
      />
   )
}

const UpdateSettingsRelateToSmartReceitDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="sm" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default UpdateSettingsRelateToSmartReceitDialog
