import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   accountUuid: string
}

const ReceivePaymentCTA = ({ accountUuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const openReceivePaymentDialog = () => {
      dispatch(
         openDialog({
            id: "openbanking_receive_payment",
            data: { accountUuid },
         })
      )
   }

   return (
      <Button
         color="primary"
         variant="contained"
         onClick={openReceivePaymentDialog}
      >
         {t("openbanking.business_registry.receive_payment_cta")}
      </Button>
   )
}

export default ReceivePaymentCTA
