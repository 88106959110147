import { govPlApi } from ".."

export enum LegalEntityStatus {
   // Legal entity added
   PENDING = 100,
   // Legal entity onboard completed (auth token has been activated at KSEF)
   ACTIVE = 200,
   // Legal entity blocked
   BLOCKED = 300,
}

export enum AuthFlowStatus {
   // Onboard initialized
   NEW = 50,
   // Onboard started
   IN_PROGRESS = 100,
   // Signed XML file uploaded
   USED = 150,
   COMPLETED = 200,
   ERROR = 250,
}

export type GovPLLegalEntity = {
   uuid: string
   status: LegalEntityStatus
   name: string
   nip: string
   email: string
   url: string | null
   addressLine1: string | null
   addressLine2: string | null
   postcode: string | null
   province: string | null
   city: string
   countryIso2: "PL"
   incomingInvoiceEnabled: boolean
   createdAt: string
   updatedAt: string
   authFlowUUID: string | null
   authFlowStatus: AuthFlowStatus | null
   authFlowDeadline: string | null
   authFlowCreatedAt: string | null
   authFlowUpdatedAt: string | null
   authFlowIsValid: boolean | null
   authFlowUrl?: string
   lastSyncAt: string
   lastReceiveAt: string
}

type GetLegalEntityResponse = GovPLLegalEntity

type GetLegalEntitiesResponse = GovPLLegalEntity[]

type CreateLegalEntityResponse = GovPLLegalEntity

type CreateLegalEntityBody = {
   nip: string
   name: string
   countryIso2: string
   email: string
   addressLine1: string
   addressLine2: string
   postcode: string
   city: string
}

type UpdateLegalEntityResponse = GovPLLegalEntity

type UpdateLegalEntityBody = Omit<CreateLegalEntityBody, "countryIso2">

type DisableLegalEntityResponse = GovPLLegalEntity

const legalEntitiesApi = govPlApi.injectEndpoints({
   endpoints: (builder) => ({
      getLegalEntity: builder.query<GetLegalEntityResponse, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/legal-entities/${uuid}`,
         }),
         providesTags: ["LEGAL_ENTITIES"],
      }),

      getLegalEntities: builder.query<GetLegalEntitiesResponse, undefined>({
         query: () => ({
            url: "/legal-entities",
         }),
         providesTags: ["LEGAL_ENTITIES"],
      }),

      createLegalEntity: builder.mutation<
         CreateLegalEntityResponse,
         CreateLegalEntityBody
      >({
         query: (body) => ({
            url: "/legal-entities",
            method: "POST",
            body,
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
      }),

      updateLegalEntity: builder.mutation<
         UpdateLegalEntityResponse,
         UpdateLegalEntityBody & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/legal-entities/${uuid}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
      }),

      disableLegalEntity: builder.mutation<
         DisableLegalEntityResponse,
         { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/legal-entities/${uuid}/block`,
            method: "POST",
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
      }),
   }),
})

export const {
   useGetLegalEntityQuery,
   useGetLegalEntitiesQuery,
   useCreateLegalEntityMutation,
   useUpdateLegalEntityMutation,
   useDisableLegalEntityMutation,
} = legalEntitiesApi
