import {
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   InputAdornment,
   InputLabel,
   OutlinedInput,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import { useTranslation } from "react-i18next"
import { SubAccount } from "services/commonApi"

export type FormValues = {
   email: string
   fiscal_id: string
   fullname: string
   password: string
   enabled: boolean
}

export const mapSubAccountToFormValues = (subaccount: SubAccount) => {
   return Object.entries(subaccount)
      .filter(
         ([key]) =>
            key !== "created_at" &&
            key !== "updated_at" &&
            key !== "@id" &&
            key !== "@type"
      )
      .reduce(
         (acc, [key, value]) => ({
            ...acc,
            [key]: value === null ? "" : value,
         }),
         {} as FormValues
      )
}

type SubAccountField = {
   name: keyof FormValues
   labelTranslationId: string
} & ({ type: "textfield" } | { type: "checkbox" } | { type: "password" })

const fields: SubAccountField[] = [
   {
      name: "email",
      labelTranslationId: "common_subAccounts.form.email",
      type: "textfield",
   },
   {
      name: "password",
      labelTranslationId: "common_subAccounts.form.password",
      type: "password",
   },
   {
      name: "fiscal_id",
      labelTranslationId: "common_subAccounts.form.fiscal_id",
      type: "textfield",
   },
   {
      name: "fullname",
      labelTranslationId: "common_subAccounts.form.fullname",
      type: "textfield",
   },
   {
      name: "enabled",
      labelTranslationId: "common_subAccounts.enabled",
      type: "checkbox",
   },
]

type Props = {
   handleClose: VoidFunction
   formikProps: FormikProps<FormValues>
   type: "new" | "edit" | "updatePassword"
}
const SubAccountForm = ({ handleClose, formikProps, type }: Props) => {
   const { t } = useTranslation()
   const {
      handleSubmit,
      touched,
      errors,
      values,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   const [showPassword, setShowPassword] = React.useState(false)
   const handleClickShowPassword = () => {
      setShowPassword((oldShowPassword) => !oldShowPassword)
   }

   const buttonName = () => {
      switch (type) {
         case "updatePassword":
            return t("common_subAccounts.form.change_password")
         case "edit":
            return t("common_subAccounts.form.update")
         case "new":
            return t("common_subAccounts.form.create")
      }
   }

   const getFieldsForType = () => {
      switch (type) {
         case "updatePassword":
            return fields.filter((field) => field.name === "password")
         case "edit":
            return fields.filter(
               (field) => field.name === "fullname" || field.name === "enabled"
            )
         case "new":
            return fields
      }
   }
   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={3}>
            {getFieldsForType().map(
               ({ name, labelTranslationId, ...field }) => (
                  <Grid key={name} item md={type == "new" ? 6 : 12} xs={12}>
                     {field.type === "checkbox" && (
                        <FormControlLabel
                           control={
                              <Checkbox
                                 name={name}
                                 checked={values[name] as boolean}
                                 onChange={handleChange}
                                 color="primary"
                              />
                           }
                           label={t(labelTranslationId)}
                        />
                     )}

                     {field.type === "password" && (
                        <FormControl
                           fullWidth
                           error={touched[name] != null && errors[name] != null}
                        >
                           <InputLabel htmlFor={name}>
                              {t(labelTranslationId)}
                           </InputLabel>
                           <OutlinedInput
                              id={name}
                              name={name}
                              type={showPassword ? "text" : "password"}
                              value={values[name]}
                              onChange={handleChange}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={handleClickShowPassword}
                                       edge="end"
                                    >
                                       {showPassword ? (
                                          <Visibility />
                                       ) : (
                                          <VisibilityOff />
                                       )}
                                    </IconButton>
                                 </InputAdornment>
                              }
                           />
                           {touched[name] != null && errors[name] != null && (
                              <FormHelperText>{errors[name]}</FormHelperText>
                           )}
                        </FormControl>
                     )}

                     {field.type === "textfield" && (
                        <Field
                           component={TextField}
                           type="text"
                           label={t(labelTranslationId)}
                           name={name}
                           helperText={touched[name] != null && errors[name]}
                           error={touched[name] != null && errors[name] != null}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           fullWidth
                        />
                     )}
                  </Grid>
               )
            )}
         </Grid>

         <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={8}
            mt={2}
            mb={1}
         >
            <Button color="default" variant="contained" onClick={handleClose}>
               Cancel
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {buttonName()}
            </Button>
         </Box>
      </form>
   )
}

export default SubAccountForm
