import {
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   makeStyles,
   Typography,
} from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { format, parseISO } from "date-fns"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DialogId, DialogState } from "store/slices/dialogs"
import { Marking } from "views/common/Marking"
import ViolationMarking from "../component/ViolationMarking"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID: DialogId = "gov_it_invoice_transfers"

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({
   uuid,
   supplier_name,
   supplier_fiscal_id,
   customer_name,
   customer_fiscal_id,
   invoice_number,
   invoice_date,
   invoice_type,
   invoice_net_amount,
   invoice_vat_amount,
   invoice_tot_amount,
   original_file_name,
   reference,
   created_at,
   violations,
   sent_at,
   sdi_id,
   sdi_file_name,
   invoice_uuid,
   marking,
   notice,
   handleClose,
}: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const [violationsShow, setViolationsShow] = useState(true)
   const violationsJson = violations
      ? (JSON.parse(violations) as { propertyPath: string; message: string }[])
      : null
   return (
      <>
         <DialogTitle>
            {t("gov_it.invoice_transfers.details_dialog_title")}
         </DialogTitle>

         <DialogContent className={classes.content}>
            <List>
               <ListItem divider>
                  <ListItemText primary="UUID" />
                  <ListItemSecondaryAction>
                     <ListItemText primary={uuid} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.supplier_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={supplier_name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.supplier_fiscal_id"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={supplier_fiscal_id} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.customer_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={customer_name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.customer_fiscal_id"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={customer_fiscal_id} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_number"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_number} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_date"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_date} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_type"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_type} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_net_amount"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_net_amount} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_vat_amount"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_vat_amount} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_tot_amount"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_tot_amount} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.original_file_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={original_file_name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.reference"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={reference} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.created_at"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={created_at} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("gov_it.invoice_transfers.table_head.sent_at")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText
                        primary={
                           typeof sent_at === "string"
                              ? format(
                                   parseISO(sent_at),
                                   "dd MMM yyyy HH:mm:ss"
                                )
                              : null
                        }
                     />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("gov_it.invoice_transfers.table_head.sdi_id")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={sdi_id} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.sdi_file_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={sdi_file_name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "gov_it.invoice_transfers.table_head.invoice_uuid"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoice_uuid} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("gov_it.invoice_transfers.table_head.marking")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={<Marking marking={marking} />} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem>
                  <ListItemText
                     primary={t("gov_it.invoice_transfers.table_head.notice")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={notice} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem
                  style={{
                     width: "100%",
                     padding: "0",
                     margin: "0",
                  }}
               >
                  <Accordion
                     expanded={violationsShow}
                     onClick={() => setViolationsShow(!violationsShow)}
                     style={{
                        width: "100%",
                     }}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                     >
                        <Typography
                           style={{
                              fontWeight: 400,
                           }}
                        >
                           {t("gov_it.invoice_transfers.table_head.violations")}
                        </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        {violationsJson ? (
                           <List
                              style={{
                                 width: "100%",
                                 padding: "0",
                                 margin: "0",
                              }}
                           >
                              {violationsJson.map(
                                 ({ propertyPath, message }, index) => (
                                    <ListItem key={index} divider>
                                       <ListItemText
                                          primary={`PropertyPath:  ${propertyPath}`}
                                          secondary={`Message:  ${message}`}
                                       />
                                    </ListItem>
                                 )
                              )}
                           </List>
                        ) : (
                           <ViolationMarking
                              isFilter={false}
                              violations={null}
                           />
                        )}
                     </AccordionDetails>
                  </Accordion>
               </ListItem>
            </List>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const InvoiceTransferDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default InvoiceTransferDetailsDialog
