import { Paper } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
// import { format, parseISO } from "date-fns";
import Table, { TableColumn } from "components/Table"
import { useSnackbar } from "notistack"
import { GovPLWebhook, useGetWebhooksQuery } from "services/gov-pl/webhooks"
// import { useAppDispatch, useAppSelector } from "store";
// import {
//   changePage,
//   selectCurrentPage,
//   selectItemsPerPage,
//   selectTableFilters,
//   selectTotalItems,
//   TablesState,
//   updateTotalItems,
// } from "store/tablesState";
import Label from "views/common/Label"

const columns: TableColumn<GovPLWebhook>[] = [
   {
      rowKey: "legalEntityName",
      configurable: true,
      labelTranslationId: "pl.webhooks.table_head.legal_entity_name",
      format: (value) => value,
   },
   {
      rowKey: "webhookType",
      configurable: true,
      labelTranslationId: "pl.webhooks.table_head.webhook_type",
      format: (value) => <Label color="primary">{value}</Label>,
   },
   {
      rowKey: "webhookUrl",
      configurable: true,
      labelTranslationId: "pl.webhooks.table_head.webhook_url",
      format: (value) => value,
   },
]

const GovPLWebhooksTable = () => {
   // const dispatch = useAppDispatch();
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   // const table = `pl.webhooks`;

   // const itemsPerPage = useAppSelector((state) =>
   //   selectItemsPerPage(state, { table })
   // );
   // const totalItems = useAppSelector((state) =>
   //   selectTotalItems(state, { table })
   // );
   // const currentPage = useAppSelector((state) =>
   //   selectCurrentPage(state, { table })
   // );
   const { data, isError, isFetching } = useGetWebhooksQuery({
      // itemsPerPage,
      // page: currentPage,
   })

   useEffect(() => {
      if (isError) {
         enqueueSnackbar(t("Default.generic_error"), { variant: "error" })
      }
   }, [enqueueSnackbar, t, isError])

   // useEffect(() => {
   //   const totalItems = data?.["hydra:totalItems"];

   //   if (totalItems != null) {
   //     dispatch(updateTotalItems({ table, totalItems }));
   //   }
   // }, [dispatch, data, table]);

   return (
      <Paper>
         <Table
            columnsDefinition={columns}
            rows={data}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="pl.invoices.messages.no_invoices"
            withPagination={false}
            // rowsPerPage={itemsPerPage}
            // totalRows={totalItems}
            // currentPage={currentPage}
            // onPageChange={(page) => {
            //   dispatch(changePage({ table, page }));
            // }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default GovPLWebhooksTable
