import { useAppSelector } from "store"
import { selectOnboardMode2CurrentStep } from "store/slices/onboard"
import End from "./End"
import TokenStep from "./Token"

const OnboardMode2Steps = () => {
   const currentStep = useAppSelector(selectOnboardMode2CurrentStep)

   switch (currentStep) {
      case "end":
         return <End />
      case "token":
      default:
         return <TokenStep />
   }
}

export default OnboardMode2Steps
