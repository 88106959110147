import {
   Box,
   Button,
   Container,
   Grid,
   makeStyles,
   TextField,
   Typography,
} from "@material-ui/core"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { useAskResetPasswordMutation } from "services/commonApi/authCommonApi"
import Logo from "views/common/components/Logo"
import Page from "views/common/components/Page"
import LanguagesMenu from "views/common/LanguagesMenu"
import * as Yup from "yup"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: "white",
      height: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const validationSchema = Yup.object().shape({
   email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
})

const initialValues = {
   email: "",
}

type FormValues = typeof initialValues

const ResetPassword = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const [emailSentTo, setEmailSentTo] = useState<string | null>(null)
   const { enqueueSnackbar } = useSnackbar()
   const [askResetPassword] = useAskResetPasswordMutation()

   const handleSubmit = async (values: FormValues) => {
      const response = await askResetPassword(values)

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      setEmailSentTo(values.email)
   }

   return (
      <Page className={classes.root} title={t("reset_password.title")}>
         <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
         >
            <Container
               maxWidth="xs"
               style={{ border: "1px solid #c0c0c0", padding: "20px" }}
            >
               <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                     <Logo />
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                     <LanguagesMenu />
                  </Grid>
               </Grid>

               {emailSentTo != null && (
                  <>
                     <Box mt={2} mb={3}>
                        <Typography
                           color="textSecondary"
                           variant="body2"
                           style={{ textAlign: "center" }}
                        >
                           <Trans
                              i18nKey="reset_password.email_sent_body"
                              values={{ email: emailSentTo }}
                           />
                        </Typography>
                     </Box>

                     <Box mt={2} mb={3}>
                        <Typography
                           variant="body2"
                           style={{ textAlign: "center" }}
                        >
                           <NavLink to="/login" style={{ color: "#54BACB" }}>
                              {t("reset_password.back_to_login")}
                           </NavLink>
                        </Typography>
                     </Box>
                  </>
               )}

               {emailSentTo == null && (
                  <>
                     <Box mt={2} mb={3}>
                        <Typography color="textSecondary" variant="body2">
                           {t("reset_password.body")}
                        </Typography>
                     </Box>

                     <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                     >
                        {({
                           errors,
                           handleBlur,
                           handleChange,
                           handleSubmit,
                           isSubmitting,
                           touched,
                           values,
                        }) => (
                           <form onSubmit={handleSubmit}>
                              <TextField
                                 error={Boolean(touched.email && errors.email)}
                                 fullWidth
                                 helperText={touched.email && errors.email}
                                 label={t("reset_password.field_email")}
                                 margin="normal"
                                 name="email"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 type="email"
                                 value={values.email}
                              />

                              <Box my={2}>
                                 <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                 >
                                    {t("reset_password.cta")}
                                 </Button>
                              </Box>
                           </form>
                        )}
                     </Formik>
                  </>
               )}
            </Container>
         </Box>
      </Page>
   )
}

export default ResetPassword
