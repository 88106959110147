import { parseToken } from "../auth"
import { TableName, TablesState } from "./index"

export const readTableColumns = (tableName: TableName) => {
   try {
      return JSON.parse(localStorage.getItem(tableName)!)
   } catch (error) {
      return null
   }
}

export const readTableFilters = (tableName: TableName) => {
   try {
      const filters = JSON.parse(localStorage.getItem(`${tableName}-filters`)!)
      if (filters) {
         if (
            "marking" in filters &&
            (tableName === "customer.invoices" ||
               tableName === "supplier.invoices" ||
               tableName === "invoice.drafts") &&
            !Array.isArray(filters.marking)
         ) {
            filters.marking = []
            localStorage.setItem(
               `${tableName}-filters`,
               JSON.stringify(filters)
            )
         }
         return filters
      }
   } catch (error) {
      return null
   }
}

//TODO: find why this function is not working properly when i use it in my readTableFilters function
/*
const BooleanTypeToIgnoreInCheck = [
   "downloaded",
   "signed",
   "toPa"
]

const checkIfFiltersAreValid = (tableName: TableName, filters: any) => {
   const filterKeys = Object.keys(filters);
   const tableStateKeys = Object.keys(tablesInitialState[tableName].filters);
   const keyAreValid  = filterKeys.every(key => tableStateKeys.includes(key));
   if (!keyAreValid) return false;

   let isValid = true
   Object.entries(filters).forEach(([key, value]) => {
      //@ts-ignore
      const validValuesType =typeof tablesInitialState[tableName].filters[key];

      if (!BooleanTypeToIgnoreInCheck.includes(key) && typeof value !== validValuesType) {
         isValid = false
         return
      }
   })
   return keyAreValid && isValid
}
*/

export const readTableRefresh = (tableName: TableName) => {
   try {
      return JSON.parse(localStorage.getItem(`${tableName}-refetch`)!)
   } catch (error) {
      return null
   }
}

export const readLastCheckedColumns = <T extends TableName>(tableName: T) => {
   try {
      const lastColumns = JSON.parse(
         localStorage.getItem(`${tableName}-lastCheckedColumns`)!
      ) as any
      const token = localStorage.getItem("token")

      if (!token || !lastColumns) {
         return null
      }

      const parsedToken = parseToken(token!)

      if (
         lastColumns &&
         "state" in lastColumns &&
         "id" in lastColumns.state &&
         atob(lastColumns.state.id) === parsedToken?.username
      ) {
         return lastColumns as "lastCheckedColumns" extends keyof TablesState[T]["data"]
            ? TablesState[T]["data"]["lastCheckedColumns"]
            : null
      }

      return null
   } catch (error) {
      return null
   }
}

export const persistTableRefresh = (tableName: TableName, reset = false) => {
   if (reset) {
      localStorage.removeItem(`${tableName}-refetch`)
   } else {
      const TimeInMs = new Date().getTime()
      localStorage.setItem(`${tableName}-refetch`, JSON.stringify(TimeInMs))
   }
}

export const persistTableColumns = (
   tableName: TableName,
   columns: string[]
) => {
   localStorage.setItem(tableName, JSON.stringify(columns))
}

export const persistLastCheckedColumns = <T extends TableName>(
   tableName: T,
   lastCheckedColumns: "lastCheckedColumns" extends keyof TablesState[T]["data"]
      ? TablesState[T]["data"]["lastCheckedColumns"]
      : null
) => {
   if (lastCheckedColumns) {
      localStorage.setItem(
         `${tableName}-lastCheckedColumns`,
         JSON.stringify(lastCheckedColumns)
      )
   } else {
      localStorage.removeItem(`${tableName}-lastCheckedColumns`)
   }
}

export const persistTableFilters = (tableName: TableName, filters: any) => {
   if (filters) {
      localStorage.setItem(`${tableName}-filters`, JSON.stringify(filters))
   } else {
      localStorage.removeItem(`${tableName}-filters`)
   }
}
