import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionIcon from "@material-ui/icons/Description"
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteSmartReceiptMutation } from "services/gov-it/smartReceipt"
import {
   SmartReceipt,
   STATUS_READY,
   TableSmartReceiptKeyType,
} from "services/gov-it/smartReceipt/type"
import { useAppDispatch } from "store"
import {
   closeDialog,
   openConfirmationDialog,
   openDialog,
} from "store/slices/dialogs"
import { updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"

type Props = {
   item: SmartReceipt
   table: TableSmartReceiptKeyType
}

const RowCTAs = ({ item, table }: Props) => {
   const { enqueueSnackbar } = useSnackbar()
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const [deleteSmartReceipt, { isLoading }] = useDeleteSmartReceiptMutation()

   const handleDeleteSmartReceipt = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("smartReceipt.void_confirmation_message"),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await deleteSmartReceipt({ id: item.uuid })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("smartReceipt.delete_error"), {
            variant: "error",
         })
      } else {
         enqueueSnackbar(t("smartReceipt.delete_success"), {
            variant: "success",
         })
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
      }
   }

   const buttons = []

   if (item.status === STATUS_READY && table === "smart_receipt.sale") {
      buttons.push(
         <Tooltip title="void receipt" key="void-receipt">
            <IconButton disabled={isLoading} onClick={handleDeleteSmartReceipt}>
               <DeleteIcon />
            </IconButton>
         </Tooltip>,
         <Tooltip title="return item(s)" key="retrun-receipt">
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "smartReceipt.form",
                        data: {
                           mode: "return",
                           item,
                        },
                     })
                  )
               }}
            >
               <KeyboardReturnIcon />
            </IconButton>
         </Tooltip>
      )
   }

   buttons.push(
      <Tooltip title="Smart Receipt Details" key="smart-receipt-details">
         <IconButton
            onClick={() => {
               dispatch(
                  openDialog({
                     id: "smartReceipt.details",
                     data: {
                        uuid: item.uuid,
                        mode: "info",
                     },
                  })
               )
            }}
         >
            <DescriptionIcon />
         </IconButton>
      </Tooltip>
   )

   return <ButtonGroup>{buttons}</ButtonGroup>
}

export default RowCTAs
