import {
   Box,
   Button,
   FormControl,
   Grid,
   Select,
   TextField,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { useEffect, useState } from "react"
import { Controller, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Field as FieldFormProps } from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import {
   findKeyValue,
   formatInvoiceNumber,
   formatTextFieldValue,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/ValidationHelpers"
import LabelYesNo from "views/common/LabelYesNo"

type CustomFieldProps = {
   field: FieldFormProps
   register: any
   getValues: any
   control: any
   setError: any
   setValue: any
   watch: any
   errorsForm: any
   customName?: string
   disableFields?: string[]
}

const CustomField = ({
   field,
   register,
   setError,
   control,
   getValues,
   watch,
   setValue,
   errorsForm,
   customName,
   disableFields,
}: CustomFieldProps) => {
   const { t } = useTranslation()
   const { name: fieldName, labelTranslationId } = field

   const name = customName ?? fieldName

   const nameParts = name.split(".")
   const nameInput = nameParts[nameParts.length - 1]

   const handleChangeTextField = (
      setFieldValue: any,
      name: string,
      value: any
   ) => {
      if (value === "") {
         setFieldValue(name, null)
         return
      }
      const numberValue = Number(value.replace(",", "."))
      if (field.format === "number" && !isNaN(numberValue)) {
         setValue(name, formatInvoiceNumber(numberValue, nameInput))
         return
      }
      if (field.format === "uppercase") {
         setValue(name, value.toUpperCase())
         return
      }
      setValue(name, value)
   }

   const handleChangeDate = (setValue: any, name: string, value: any) => {
      setValue(name, value === "" ? null : value)
   }

   const handleChangeSelect = (setValue: any, name: string, value: any) => {
      if (value === "") {
         setValue(name, null)
         return
      }
      setValue(name, value)
   }

   const dependencies = field.dependsOn ?? []
   const [stopValidation, setStopValidation] = useState(false)

   const disabledValidation = () => {
      if (dependencies.length === 0) {
         return
      }
      setStopValidation(false)
      dependencies.forEach((dependency) => {
         const value = getValues(dependency.field)
         if (value !== dependency.value) {
            setStopValidation(true)
         }
      })
   }

   useEffect(() => {
      if (field.type === "arrayTextfield") {
         if (getValues(name)?.length === 0) {
            setValue(name, null)
         }
      }
   }, [field.type, getValues, name, setValue])

   const getErrorForName = () => {
      return findKeyValue(errorsForm, name) as any
   }

   if (field.type === "arrayTextfield") {
      return (
         <CustomTextFieldArray
            field={field}
            errorsForm={errorsForm}
            getValues={getValues}
            name={name}
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            labelTranslationId={labelTranslationId}
            control={control}
            handleChangeTextField={handleChangeTextField}
            disableFields={disableFields}
         />
      )
   }

   if (field.type === "arrayObject") {
      return (
         <CustomObjectArray
            errorsForm={errorsForm}
            getValues={getValues}
            name={name}
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            labelTranslationId={labelTranslationId}
            control={control}
            field={field}
            disableFields={disableFields}
         />
      )
   }

   const isDisabled = disableFields?.includes(name)

   return (
      <Grid
         key={name}
         item
         xs={field.width?.xs ?? 12}
         sm={field.width?.sm ?? undefined}
         md={field.width?.md ?? 6}
         lg={field.width?.lg ?? field.width?.md ?? 6}
      >
         {field.type === "textfield" && (
            <>
               <Controller
                  name={name}
                  control={control}
                  render={({ field: fieldInternal }) => (
                     <TextField
                        style={{
                           backgroundColor: "white",
                           borderBottomRightRadius: "5px !important",
                           borderBottomLeftRadius: "5px !important",
                        }}
                        onFocus={() => {
                           disabledValidation()
                        }}
                        type="text"
                        label={t(labelTranslationId)}
                        fullWidth
                        inputProps={
                           stopValidation ? undefined : field.inputProps
                        }
                        variant="outlined"
                        {...fieldInternal}
                        onBlur={(e) =>
                           handleChangeTextField(setValue, name, e.target.value)
                        }
                        defaultValue={() => {
                           if (!getValues(name) || getValues(name) === "") {
                              setValue(name, null)
                              return null
                           }
                           return formatTextFieldValue(
                              getValues(name),
                              field.format,
                              nameInput
                           )
                        }}
                        helperText={getErrorForName()?.message}
                        error={getErrorForName() != null}
                        disabled={isDisabled || field.disabled}
                     />
                  )}
               />
            </>
         )}

         {field.type === "date" && (
            <Controller
               name={name}
               control={control}
               render={({ field }) => (
                  <TextField
                     style={{
                        backgroundColor: "white",
                        borderBottomRightRadius: "5px !important",
                        borderBottomLeftRadius: "5px !important",
                     }}
                     type="date"
                     label={t(labelTranslationId)}
                     fullWidth
                     variant="outlined"
                     {...field}
                     onBlur={(e) =>
                        handleChangeDate(setValue, name, e.target.value)
                     }
                     defaultValue={() => {
                        if (!getValues(name) || getValues(name) === "") {
                           setValue(name, null)
                           return null
                        }
                        return getValues(name)
                     }}
                     helperText={errorsForm[name]?.message}
                     error={errorsForm[name] != null}
                     disabled={isDisabled || field.disabled}
                  />
               )}
            />
         )}

         {field.type === "select" && (
            <FormControl
               fullWidth
               variant="outlined"
               disabled={isDisabled || field.disabled}
            >
               <InputLabel htmlFor={name}>{t(labelTranslationId)}</InputLabel>
               <Select
                  style={{
                     backgroundColor: "white",
                     borderBottomRightRadius: "5px !important",
                     borderBottomLeftRadius: "5px !important",
                  }}
                  helperText={errorsForm[name]?.message}
                  error={errorsForm[name] != null}
                  inputProps={{
                     name: name,
                     id: name,
                  }}
                  fullWidth
                  {...register(name)}
                  disabled={isDisabled}
                  onBlur={(e) => {
                     handleChangeSelect(setValue, name, e.target.value)
                  }}
                  defaultValue={() => {
                     if (
                        (!getValues(name) || getValues(name)?.trim() === "") &&
                        field.default?.value
                     ) {
                        setValue(name, field.default.value)
                        return field.default.value
                     }
                     return getValues(name)
                  }}
               >
                  {!field.required && (
                     <MenuItem value={undefined}>
                        <LabelYesNo value={null} />
                     </MenuItem>
                  )}
                  {field.options.map((option) => (
                     <MenuItem
                        key={option.value}
                        value={option.value}
                        title={option.label}
                     >
                        {option.label}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         )}
      </Grid>
   )
}

export default CustomField

type CustomTextFieldArrayProps = {
   field: FieldFormProps
   getValues: any
   name: string
   register: any
   control: any
   setValue: any
   setError: any
   watch: any
   errorsForm: any
   labelTranslationId: string
   handleChangeTextField: any
   disableFields?: string[]
}
const CustomTextFieldArray = ({
   field: fieldMaster,
   getValues,
   name,
   register,
   control,
   setValue,
   setError,
   watch,
   labelTranslationId,
   handleChangeTextField,
   errorsForm,
   disableFields,
}: CustomTextFieldArrayProps) => {
   const { fields, append, remove } = useFieldArray({
      control,
      name,
   })
   const { t } = useTranslation()
   const getErrorForName = (name: string, index: number) => {
      return findKeyValue(errorsForm, `${name}[${index}]`) as any
   }
   const nameParts = name.split(".")
   const nameInput = nameParts[nameParts.length - 1]

   const isDisabled = disableFields?.some((x) => x.startsWith(name))

   return (
      <Box width="100%" my={1}>
         <Grid container>
            {fields.map((field, index) => (
               <Grid
                  item
                  xs={12}
                  key={field.id}
                  style={{
                     backgroundColor:
                        index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "white",
                     padding: "0.5rem",
                     borderRadius: "5px",
                     border: "1px solid #ccc",
                     marginBottom: "0.5rem",
                  }}
               >
                  <Controller
                     control={control}
                     name={`${name}[${index}]`}
                     render={({ field: fieldInternal }) => (
                        <TextField
                           style={{
                              backgroundColor: "white",
                           }}
                           inputProps={fieldMaster.inputProps}
                           type="text"
                           label={`${t(labelTranslationId)} ${index + 1}`}
                           fullWidth
                           variant="outlined"
                           {...fieldInternal}
                           onBlur={(e) => {
                              handleChangeTextField(
                                 setValue,
                                 `${name}[${index}]`,
                                 e.target.value
                              )
                           }}
                           defaultValue={() => {
                              if (!getValues(name) || getValues(name) === "") {
                                 setValue(name, null)
                                 return null
                              }
                              return formatTextFieldValue(
                                 getValues(`${name}[${index}]`).value,
                                 fieldMaster.format,
                                 nameInput
                              )
                           }}
                           helperText={getErrorForName(name, index)?.message}
                           error={getErrorForName(name, index) != null}
                           disabled={isDisabled || fieldMaster.disabled}
                        />
                     )}
                  />

                  <Button
                     variant="text"
                     size="medium"
                     type="button"
                     onClick={() => remove(index)}
                     style={{
                        marginTop: "-5px",
                        color: "rgb(172,2,2)",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                        outline: "none",
                        padding: 0,
                     }}
                  >
                     {t("global.remove")}
                  </Button>
               </Grid>
            ))}
         </Grid>
         <Button
            color="primary"
            variant="contained"
            size="small"
            type="button"
            onClick={() => append(" ")}
            style={{
               marginTop: "10px",
               fontWeight: "bold",
            }}
         >
            {t("global.add")} {t(labelTranslationId)}
         </Button>
      </Box>
   )
}

type CustomObjectArrayProps = {
   getValues: any
   name: string
   register: any
   control: any
   setValue: any
   setError: any
   watch: any
   errorsForm: any
   labelTranslationId: string
   field: FieldFormProps
   disableFields?: string[]
}
const CustomObjectArray = ({
   getValues,
   name,
   register,
   control,
   setValue,
   setError,
   watch,
   labelTranslationId,
   field: fieldMaster,
   errorsForm,
   disableFields,
}: CustomObjectArrayProps) => {
   const { fields, append, remove } = useFieldArray({
      control,
      name,
   })
   const { t } = useTranslation()

   return (
      <Box width="100%" my={1}>
         <Grid container>
            {fields.map((field, index) => (
               <Grid
                  item
                  xs={12}
                  key={field.id}
                  style={{
                     backgroundColor:
                        index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "white",
                     padding: "0.5rem",
                     borderRadius: "5px",
                     border: "1px solid #ccc",
                     marginBottom: "0.5rem",
                  }}
               >
                  <Grid container spacing={1}>
                     {fieldMaster.type === "arrayObject" &&
                        fieldMaster.children.map((subField) => (
                           <CustomField
                              field={subField}
                              register={register}
                              setError={setError}
                              getValues={getValues}
                              setValue={setValue}
                              customName={`${name}[${index}].${subField.name}`}
                              control={control}
                              watch={watch}
                              errorsForm={errorsForm}
                              disableFields={disableFields}
                           />
                        ))}
                  </Grid>
                  <Button
                     variant="text"
                     size="medium"
                     type="button"
                     onClick={() => remove(index)}
                     style={{
                        marginTop: "-5px",
                        color: "rgb(172,2,2)",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                        outline: "none",
                        padding: 0,
                     }}
                  >
                     {t("global.remove")}
                  </Button>
               </Grid>
            ))}
         </Grid>
         <Button
            color="primary"
            variant="contained"
            size="small"
            type="button"
            onClick={() => {
               if (fieldMaster.type === "arrayObject") {
                  const newObject: any = {}
                  fieldMaster.children.forEach((subField) => {
                     newObject[subField.name] = null
                  })

                  append(newObject)
               }
            }}
            style={{
               marginTop: "10px",
               fontWeight: "bold",
            }}
         >
            {t("global.add")} {t(labelTranslationId)}
         </Button>
      </Box>
   )
}
