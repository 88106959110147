export const cleanedJsonObject = (jsonObject) => {
   const tmp = JSON.parse(
      JSON.stringify(jsonObject, (key, value) => {
         if (
            value === null ||
            value === undefined ||
            value === "" ||
            (Array.isArray(value) && value.length === 0)
         ) {
            return undefined
         }
         return value
      })
   )
   deleteEmpty(tmp)
   return tmp
}

const deleteEmpty = (obj) => {
   Object.keys(obj).forEach((key) => {
      if (
         typeof obj[key] === "object" &&
         (Object.keys(obj[key]).length === 0 || obj[key] === null)
      )
         delete obj[key]
      else if (Array.isArray(obj[key]) && obj[key].length === 0) delete obj[key]
      else if (Array.isArray(obj[key])) {
         obj[key].forEach((item) => {
            if (typeof item === "object") {
               deleteEmpty(item)
            }
            if (Object.keys(item).length === 0) {
               obj[key].splice(obj[key].indexOf(item), 1)
            }
         })
      } else if (obj[key] && typeof obj[key] === "object") deleteEmpty(obj[key])
      else if (obj[key] === undefined || obj[key] === null) delete obj[key]
   })
   return obj
}
