import Box from "@material-ui/core/Box"
import XMLViewer from "react-xml-viewer"

export default function TabPanelXml(props) {
   const { value, index, order } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               {order !== false && <XMLViewer xml={order} indentSize={4} />}
            </Box>
         )}
      </div>
   )
}
