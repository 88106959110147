import {
   createAsyncThunk,
   createSelector,
   createSlice,
   PayloadAction,
} from "@reduxjs/toolkit"
import { getItem } from "helper/storage"
import { ApplicationContext } from "store/auth"
import { getOneContextExistInNavBarContextItems } from "views/layout/NavBarContextItems"
import type { RootState } from ".."

export const initContexts = createAsyncThunk(
   "app/initContexts",
   (contexts: ApplicationContext[]) => {
      const activeContext = (getItem("active_context") ??
         contexts.find((context) => context === "it.api.acubeapi.com") ??
         getOneContextExistInNavBarContextItems(contexts)) as ApplicationContext
      return { contexts, activeContext }
   }
)

export const initCurrentNavItem = createAsyncThunk(
   "app/initCurrentNavItem",
   () => {
      return getItem("current_nav_item") ?? "/dashboard"
   }
)

type State = {
   activeContext: ApplicationContext | null
   contexts: {
      id: ApplicationContext
      isEnabled: boolean
   }[]
   currentNavItem: string
}

const initialState: State = {
   activeContext: "it.api.acubeapi.com",
   contexts: [
      {
         id: "it.api.acubeapi.com",
         isEnabled: false,
      },
      {
         id: "peppol.api.acubeapi.com",
         isEnabled: false,
      },
      {
         id: "pl.api.acubeapi.com",
         isEnabled: false,
      },
      {
         id: "ob.api.acubeapi.com",
         isEnabled: false,
      },
   ],
   currentNavItem: "/dashboard",
}

export const appSlice = createSlice({
   name: "app",
   initialState,
   reducers: {
      changeActiveContext: (
         state,
         { payload }: PayloadAction<ApplicationContext | null>
      ) => {
         state.activeContext = payload
      },
      changeCurrentNavItem: (state, { payload }: PayloadAction<string>) => {
         state.currentNavItem = payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(initContexts.fulfilled, (state, { payload }) => {
            state.activeContext = payload.activeContext
            state.contexts = state.contexts.map((context) => ({
               ...context,
               isEnabled: payload.contexts.includes(context.id),
            }))
         })
         .addCase(initCurrentNavItem.fulfilled, (state, { payload }) => {
            state.currentNavItem = payload
         })
   },
})

export const { changeActiveContext, changeCurrentNavItem } = appSlice.actions

const selectApp = ({ app }: RootState) => app

export const selectEnabledContexts = createSelector(selectApp, ({ contexts }) =>
   contexts.filter((context) => context.isEnabled)
)

export const selectActiveContext = createSelector(
   selectApp,
   ({ activeContext }) => activeContext
)

export const selectCurrentNavItem = createSelector(
   selectApp,
   ({ currentNavItem }) => currentNavItem
)

export default appSlice.reducer
