import { Box, IconButton, Tooltip } from "@material-ui/core"
import DescriptionIcon from "@material-ui/icons/Description"
import EditIcon from "@material-ui/icons/Edit"
import { useTranslation } from "react-i18next"
import { GovPLLegalEntity } from "services/gov-pl/legalEntities"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   legalEntity: GovPLLegalEntity
}

const RowCTAs = ({ legalEntity }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   return (
      <Box display="flex" gridGap={-4}>
         <Tooltip title={t("legal_entities.edit_legal_entity")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "gov_pl_edit_legal_entity",
                        data: legalEntity,
                     })
                  )
               }}
            >
               <EditIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("legal_entities.legal_entity_details")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "gov_pl_legal_entity_details",
                        data: legalEntity,
                     })
                  )
               }}
            >
               <DescriptionIcon />
            </IconButton>
         </Tooltip>
      </Box>
   )
}

export default RowCTAs
