import { makeStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

// @ts-ignore
const useStyles = makeStyles((theme) => ({
   root: {
      fontFamily: theme.typography.fontFamily,
      alignItems: "center",
      borderRadius: 2,
      display: "inline-flex",
      flexGrow: 0,
      whiteSpace: "nowrap",
      cursor: "default",
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 20,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
   },
   primary: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
   },
   secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.08),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.08),
   },
}))

type LabelYesNoProps = {
   children?: React.ReactNode
   className?: string
   style?: React.CSSProperties
   color?: "primary" | "secondary" | "error" | "warning" | "success"
   value: any
}

const LabelYesNo = ({ ...props }: LabelYesNoProps) => {
   const classes = useStyles()
   const { t } = useTranslation()

   let color, label

   if (props.value === null || props.value === undefined) {
      color = ""
      label = ""
   }

   if (props.value === true) {
      color = "success"
      label = t("Default.Si")
   }
   if (props.value === false) {
      color = "error"
      label = t("Default.No")
   }

   return (
      <span
         className={
            // @ts-ignore
            clsx(classes["root"], {
               // @ts-ignore
               [classes[color]]: color,
            })
         }
      >
         {label}
      </span>
   )
}

export default LabelYesNo
