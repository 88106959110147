import { useSendAccessCodeMutation } from "services/gov-pl/invoices"
import ActivateAccessCodeForm from "./ActivateAccessCodeForm"
import SendAccessCodeCTA, { fixedCacheKey } from "./SendAccessCodeCTA"

type Props = {
   onSuccessfulVerification: (path: string) => void
}

const AccessCodeCheck = ({ onSuccessfulVerification }: Props) => {
   const [, { isUninitialized, isLoading, isError }] =
      useSendAccessCodeMutation({ fixedCacheKey })
   const accessCodeSent = !isUninitialized && !isLoading && !isError

   if (accessCodeSent) {
      return (
         <ActivateAccessCodeForm
            onSuccessfulSubmit={(path) => onSuccessfulVerification(path)}
         />
      )
   }

   return <SendAccessCodeCTA />
}

export default AccessCodeCheck
