import { DialogContent, DialogTitle, makeStyles } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useUpdateLegalEntityMutation } from "services/gov-pl/legalEntities"
import { DialogId, DialogState } from "store/slices/dialogs"
import * as Yup from "yup"
import CreateLegalEntityForm, { FormValues } from "../EditLegalEntityForm"

const DIALOG_ID: DialogId = "gov_pl_edit_legal_entity"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const validationSchema = Yup.object().shape({
   email: Yup.string().email().max(255).required(),
   name: Yup.string().max(255).required(),
   nip: Yup.string().max(255).required(),
   addressLine1: Yup.string().max(255).required(),
   addressLine2: Yup.string().max(255).required(),
   postcode: Yup.string().max(255).required(),
   city: Yup.string().max(255).required(),
})

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, ...legalEntity }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updateLegalEntity] = useUpdateLegalEntityMutation()

   const initialValues = legalEntity as FormValues

   const handleSubmit = async (values: FormValues) => {
      const response = await updateLegalEntity({
         ...values,
         uuid: legalEntity.uuid,
      })

      if ("data" in response) {
         enqueueSnackbar(t("legal_entities.messages.edit_successful"), {
            variant: "success",
         })
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>
            {t("legal_entities.create_legal_entity.title")}
         </DialogTitle>

         <DialogContent className={classes.content}>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <CreateLegalEntityForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const EditLegalEntityDialog = () => (
   <BaseDialog id={DIALOG_ID}>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default EditLegalEntityDialog
