import {
   Box,
   Grid,
   makeStyles,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React from "react"
import { useTranslation } from "react-i18next"
import { displayLabeledRows } from "../../helper/displayLabeledRows"
import getComparator from "../../helper/getComparator"
import tableSort from "../../helper/tableSort"
import { CustomTableHead } from "./CustomTableHead"
import { TablePaginationActions } from "./TablePaginationActions"

const useStyles = makeStyles((theme) => ({
   icons: {
      display: "inline",
   },
   root: {
      width: "100%",
   },
   container: {
      overflowX: "initial",
   },
   head: {
      padding: "6px 0",
   },
}))

export default function CustomTable(props) {
   const classes = useStyles()
   const { t } = useTranslation()
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState(props.orderByProperty)

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc"
      setOrder(isAsc ? "desc" : "asc")
      setOrderBy(property)
   }

   const getFrom = () => (props.page - 1) * props.pagination + 1

   const getTo = (count) =>
      props.page * props.pagination > count
         ? count
         : props.page * props.pagination

   return (
      <Paper className={classes.root}>
         <TableContainer className={classes.container}>
            <Table size="small" stickyHeader>
               <CustomTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.count}
                  columns={props.columns}
                  handleZipArchive={props.handleZipArchive}
                  pagination={props.pagination}
                  page={props.page}
               />

               <TableBody>
                  {props.rows.items.length < 1 && (
                     <TableRow key="empty-row">
                        <TableCell
                           scope="row"
                           align="center"
                           colSpan={props.columns.length}
                        >
                           <Alert severity="info">No records found</Alert>
                        </TableCell>
                     </TableRow>
                  )}

                  {tableSort(
                     props.rows.items,
                     getComparator(order, orderBy)
                  ).map((item, index) => {
                     return (
                        <TableRow
                           hover
                           tabIndex={-1}
                           key={item[props.orderByProperty]}
                        >
                           {props.columns.map((column) => {
                              if (
                                 column.empty &&
                                 !column.hasOwnProperty("format")
                              ) {
                                 return null
                              }

                              const value = item[column.id]
                              return (
                                 <TableCell
                                    key={"table-row-cell-" + index + column.id}
                                    component="th"
                                    id={column.id}
                                    scope="row"
                                    align={column.align}
                                    padding={
                                       column.disablePadding
                                          ? "none"
                                          : "default"
                                    }
                                 >
                                    {column.hasOwnProperty("format")
                                       ? column.format(value, props, item, t)
                                       : value}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>

         <Grid container alignItems="center">
            <Grid item sm={6} xs={12}>
               <Box m={2}>{props.bottomButtonGroup}</Box>
            </Grid>

            <Grid item sm={6} xs={12}>
               <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={props.rows.count}
                  page={
                     props.page > 0 && props.rows.count <= 30 ? 1 : props.page
                  }
                  onPageChange={props.handleChangePage}
                  labelDisplayedRows={displayLabeledRows(getFrom, getTo, props)}
                  ActionsComponent={() => TablePaginationActions(props)}
                  rowsPerPage={props.pagination}
               />
            </Grid>
         </Grid>
      </Paper>
   )
}
