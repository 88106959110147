import {
   AppBar,
   Box,
   Hidden,
   IconButton,
   makeStyles,
   Toolbar,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import PropTypes from "prop-types"
import ImpersonateUserDialog from "../common/ImpersonateUserDialog"
import LanguagesMenu from "../common/LanguagesMenu"
import ProfileMenu from "../common/ProfileMenu"
import Logo from "./Logo"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.white,
   },
   button: {
      color: theme.palette.dark.main,
   },
   menu: {
      color: theme.palette.dark.main,
   },
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
   const classes = useStyles()

   return (
      <>
         <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
         >
            <Toolbar>
               <Logo />
               <Box flexGrow={1} />

               <ProfileMenu />
               <LanguagesMenu />

               <Hidden lgUp>
                  <IconButton color="inherit" onClick={onMobileNavOpen}>
                     <MenuIcon className={classes.menu} />
                  </IconButton>
               </Hidden>
            </Toolbar>
         </AppBar>

         <ImpersonateUserDialog />
      </>
   )
}

TopBar.propTypes = {
   className: PropTypes.string,
   onMobileNavOpen: PropTypes.func,
}

export default TopBar

// export default function PrimarySearchAppBar() {
//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

//   const isMenuOpen = Boolean(anchorEl);
//   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

//   const handleMobileMenuClose = () => {
//     setMobileMoreAnchorEl(null);
//   };

//   const handleMobileMenuOpen = (event) => {
//     setMobileMoreAnchorEl(event.currentTarget);
//   };

//   return (
//     <div className={classes.grow}>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             className={classes.menuButton}
//             color="inherit"
//             aria-label="open drawer"
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography className={classes.title} variant="h6" noWrap>
//             Material-UI
//           </Typography>
//           <div className={classes.search}>
//             <div className={classes.searchIcon}>
//               <SearchIcon />
//             </div>
//             <InputBase
//               placeholder="Search…"
//               classes={{
//                 root: classes.inputRoot,
//                 input: classes.inputInput,
//               }}
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </div>
//           <div className={classes.grow} />
//           <div className={classes.sectionDesktop}>
//             <IconButton aria-label="show 4 new mails" color="inherit">
//               <Badge badgeContent={4} color="secondary">
//                 <MailIcon />
//               </Badge>
//             </IconButton>
//             <IconButton aria-label="show 17 new notifications" color="inherit">
//               <Badge badgeContent={17} color="secondary">
//                 <NotificationsIcon />
//               </Badge>
//             </IconButton>
//             <IconButton
//               edge="end"
//               aria-label="account of current user"
//               aria-controls={menuId}
//               aria-haspopup="true"
//               onClick={handleProfileMenuOpen}
//               color="inherit"
//             >
//               <AccountCircle />
//             </IconButton>
//           </div>
//           <div className={classes.sectionMobile}>
//             <IconButton
//               aria-label="show more"
//               aria-controls={mobileMenuId}
//               aria-haspopup="true"
//               onClick={handleMobileMenuOpen}
//               color="inherit"
//             >
//               <MoreIcon />
//             </IconButton>
//           </div>
//         </Toolbar>
//       </AppBar>
//       {renderMobileMenu}
//       {renderMenu}
//     </div>
//   );
// }
