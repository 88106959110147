import { Box, IconButton } from "@material-ui/core"
// @ts-expect-error
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"

const languages = [
   {
      code: "pl",
      countryCode: "PL",
      title: "Polski",
   },
   {
      code: "en",
      countryCode: "GB",
      title: "English",
   },
]

const Languages = () => {
   const { i18n } = useTranslation()

   const handleChangeLanguage = (languageCode: string) => {
      i18n.changeLanguage(languageCode)
   }

   return (
      <Box display="flex" justifyContent="flex-end" marginBottom={10}>
         {languages.map(({ code, countryCode, title }) => (
            <Box key={code} marginLeft={2} title={title}>
               <IconButton
                  color="default"
                  onClick={() => handleChangeLanguage(code)}
                  disabled={i18n.language === code}
               >
                  <ReactCountryFlag
                     countryCode={countryCode}
                     svg
                     style={{
                        width: "1em",
                        height: "auto",
                        border: "1px solid #777",
                        boxShadow: "0 0 3px rgba(0 0 0 / 50%)",
                        opacity: i18n.language === code ? "0.5" : "1",
                     }}
                  />
               </IconButton>
            </Box>
         ))}
      </Box>
   )
}

export default Languages
