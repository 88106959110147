import { Button } from "@material-ui/core"
import React from "react"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { setPassword } from "../../../services/client/request/common/setPassword"
import ChangePasswordDialog from "./ChangePasswordDialog"

export function ChangePasswordButton(props) {
   const token = useAppSelector(selectAuthToken)
   const [error, setError] = React.useState(false)
   const [isLoading, setIsLoading] = React.useState(false)
   const [isDialogOpen, setIsDialogOpen] = React.useState(false)

   const handleOpenDialog = () => {
      setIsDialogOpen(true)
   }

   const handleCloseDialog = () => {
      setIsDialogOpen(false)
   }

   const handleSave = async (values) => {
      setIsLoading(true)
      setError(false)

      try {
         await setPassword(token, values)
         handleCloseDialog()
      } catch (e) {
         setError(e.message)
      }

      setIsLoading(false)
   }

   return (
      <>
         <Button onClick={handleOpenDialog} color="primary" variant="contained">
            {props.text}
         </Button>

         {isDialogOpen && (
            <ChangePasswordDialog
               isOpen={isDialogOpen}
               isLoading={isLoading}
               error={error}
               handleClose={handleCloseDialog}
               handleSave={handleSave}
            />
         )}
      </>
   )
}
