import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { govPlApi, GovPLPaginationParams } from ".."

type AcceptHeader =
   | "application/xml"
   | "application/json"
   | "application/pdf"
   | "text/html"

export type Invoice = {
   uuid: string
   acquisition: string | null
   attachments: {
      filename: string
      filesize: number
      uuid: string
   }[]
   direction: "outgoing"
   error: null
   hasUpo: boolean
   ksefReferenceNumber: string
   legalEntityUUID: string
   createdAt: string
   invoiceDate: string
   invoiceNumber: string
   senderName: string
   recipientName: string
   invoiceCreatorNip: string
   invoiceRecipientNip: string
   invoiceType: string
   invoiceVersion: string
   status: "valid" | "sent" | "delivered" | "confirmed" | "error"
}

type GetInvoicesParams = GovPLPaginationParams & {
   direction: "incoming" | "outgoing"
   invoiceStatus?: string
   "createdAt[after]"?: string | null
   "createdAt[before]"?: string | null
   "invoiceDate[after]"?: string | null
   "invoiceDate[before]"?: string | null
   invoiceCreatorNip?: string
   invoiceRecipientNip?: string
   legalEntityUuid?: string
   invoiceType?: string
}

type GetInvoicesResponse = HydraResponse<Invoice>

type GetInvoiceResponse =
   | Invoice
   | {
        html: string
        attachments: string[]
        additional: {}[]
     }
   | Blob
   | string

type GetProtectedInvoiceResponse =
   | Invoice
   | {
        html: string
        attachments: {}[]
        additional: {}[]
     }
   | Blob
   | string

type GetInvoiceAttachmentResponse = string

type GetInvoiceUpoResponse = string

type SendAccessCodeResponse = {
   email: string
}

type ActivateAccessCodeResponse = {
   url: string
   uuid: string
   access_code: string
}

type ActivateAccessCodeParams = {
   accessCode: string
}

const invoicesApi = govPlApi.injectEndpoints({
   endpoints: (builder) => ({
      getInvoices: builder.query<GetInvoicesResponse, GetInvoicesParams>({
         query: (params) => ({
            url: `/invoices${buildSearchParams(params)}`,
         }),
      }),

      getInvoice: builder.query<
         GetInvoiceResponse,
         { accept: AcceptHeader; uuid: string }
      >({
         query: ({ accept, uuid }) => ({
            url: `/invoices/${uuid}${
               accept === "application/xml" ? "?rawContent=true" : ""
            }`,
            headers: {
               accept,
            },
            responseHandler: (response) => {
               if (["application/xml"].includes(accept)) {
                  return response.text()
               }

               if (accept === "application/pdf") {
                  return response.blob()
               }

               return response.json()
            },
         }),
      }),

      getProtectedInvoice: builder.query<
         GetProtectedInvoiceResponse,
         { accept: AcceptHeader; uuid: string; code: string }
      >({
         query: ({ accept, uuid, code }) => ({
            url: `/invoices/${uuid}/code/${code}`,
            headers: {
               accept,
            },
            responseHandler: (response) => {
               if (["application/xml"].includes(accept)) {
                  return response.text()
               }

               if (accept === "application/pdf") {
                  return response.blob()
               }

               return response.json()
            },
         }),
         extraOptions: {
            requireAuth: false,
         },
      }),

      getInvoiceUpo: builder.query<GetInvoiceUpoResponse, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/upo`,
            headers: {
               accept: "application/xml",
            },
            responseHandler: (response) => response.text(),
         }),
      }),

      getProtectedInvoiceUpo: builder.query<
         GetInvoiceUpoResponse,
         { code: string; uuid: string }
      >({
         query: ({ code, uuid }) => ({
            url: `/invoices/${uuid}/code/${code}/upo`,
            headers: {
               accept: "application/xml",
            },
            responseHandler: (response) => response.text(),
         }),
      }),

      getProtectedInvoiceAttachment: builder.query<
         GetInvoiceAttachmentResponse,
         { invoiceUuid: string; attachmentUuid: string; code: string }
      >({
         query: ({ invoiceUuid, attachmentUuid, code }) => ({
            url: `/invoices/${invoiceUuid}/attachments/download/${attachmentUuid}/${code}`,
            headers: {
               accept: "text/plain",
            },
            responseHandler: (response) => response.text(),
         }),
      }),

      getInvoiceAttachment: builder.query<
         GetInvoiceAttachmentResponse,
         { invoiceUuid: string; attachmentUuid: string }
      >({
         query: ({ invoiceUuid, attachmentUuid }) => ({
            url: `/invoices/${invoiceUuid}/attachments/${attachmentUuid}`,
            headers: {
               accept: "text/plain",
            },
            responseHandler: (response) => response.text(),
         }),
      }),

      sendAccessCode: builder.mutation<
         SendAccessCodeResponse,
         { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/send-access-code`,
            method: "POST",
            body: {},
            headers: {
               "content-type": "application/json",
            },
         }),
         extraOptions: {
            requireAuth: false,
         },
      }),

      activateAccessCode: builder.mutation<
         ActivateAccessCodeResponse,
         ActivateAccessCodeParams & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/invoices/${uuid}/activate-access-code`,
            method: "POST",
            body,
            headers: {
               "content-type": "application/json",
            },
         }),
         extraOptions: {
            requireAuth: false,
         },
      }),
   }),
})

export const {
   useLazyGetInvoiceAttachmentQuery,
   useLazyGetProtectedInvoiceAttachmentQuery,
   useGetInvoicesQuery,
   useGetInvoiceQuery,
   useLazyGetInvoiceQuery,
   useLazyGetInvoiceUpoQuery,
   useLazyGetProtectedInvoiceUpoQuery,
   useGetProtectedInvoiceQuery,
   useLazyGetProtectedInvoiceQuery,
   useSendAccessCodeMutation,
   useActivateAccessCodeMutation,
} = invoicesApi
