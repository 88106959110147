import getParameter from "../request/getParameter"

export const formatOrders = (ordersResponse) => {
   // The latter case handles the single order, passed in as an array with one element
   const orders = ordersResponse["hydra:member"]
      ? ordersResponse["hydra:member"]
      : ordersResponse
   const formattedOrders = orders.map((order) => {
      return formatOrder(order)
   })

   const view = ordersResponse["hydra:view"]

   return {
      count:
         ordersResponse["hydra:totalItems"] !== undefined
            ? ordersResponse["hydra:totalItems"]
            : 1,
      first:
         view && view["hydra:first"]
            ? parseInt(getParameter(view["hydra:first"], "page"))
            : null,
      last:
         view && view["hydra:last"]
            ? parseInt(getParameter(view["hydra:last"], "page"))
            : null,
      next:
         view && view["hydra:next"]
            ? parseInt(getParameter(view["hydra:next"], "page"))
            : null,
      previous:
         view && view["hydra:previous"]
            ? parseInt(getParameter(view["hydra:previous"], "page"))
            : null,
      items: formattedOrders,
   }
}

export const formatOrder = (order) => {
   return {
      ...order,
      senderFormatted: order.sender.name || "",
      recipientFormatted: order.recipient.name || "",
   }
}
