import { download } from "helper/download"
import { useNavigate, useParams } from "react-router"
import { useGetInvoiceFileQuery } from "services/gov-it"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type props = {
   fileType: "pdf" | "xml"
}
export const useDownloadInvoiceHook = ({ fileType }: props) => {
   const { uuid } = useParams()
   const navigate = useNavigate()
   const dispatch = useAppDispatch()

   const { data, isLoading, error } = useGetInvoiceFileQuery({
      uuid: uuid!,
      accept: fileType === "pdf" ? "application/pdf" : "application/xml",
   })

   if (!isLoading && !error && data) {
      download(data, uuid + "." + fileType)

      navigate("/customer-invoice")

      dispatch(
         openDialog({
            id: "gov_it_invoices_details",
            data: { invoiceUuid: uuid!, mode: "info" },
         })
      )
   }

   return { isLoading, error }
}
