import { TextField } from "@material-ui/core"
import { format } from "date-fns"
import Litepicker from "litepicker"
import "litepicker/dist/plugins/ranges"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

export const dateInputDelimiter = " - "

type Props = {
   label: string
   name: string
   onChange: (value: string) => void
   onBlur?: (e: any) => void
   value: string
   disabled?: boolean
}

export const DateInput = ({
   label,
   name,
   onChange,
   value,
   disabled,
   onBlur,
}: Props) => {
   const ref = useRef(null)
   const [, setPicker] = useState<Litepicker | null>(null)
   const { t } = useTranslation()
   useEffect(() => {
      if (ref.current != null) {
         const picker = new Litepicker({
            element: ref.current,
            delimiter: dateInputDelimiter,
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            dropdowns: {
               months: true,
               years: true,
               minYear: 1990,
               maxYear: null,
            },
            plugins: ["ranges"],
         })

         picker.on("selected", (startDate, endDate) => {
            const start = format(new Date(startDate.dateInstance), "yyyy-MM-dd")
            const end = format(new Date(endDate.dateInstance), "yyyy-MM-dd")

            onChange(`${start}${dateInputDelimiter}${end}`)
         })

         setPicker(picker)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ref])

   return (
      <TextField
         type="text"
         placeholder={t("global.selectDateRange")}
         inputRef={ref}
         InputProps={{ autoComplete: "off", readOnly: true, disabled }}
         label={label}
         onBlur={onBlur}
         name={name}
         value={value}
         fullWidth
      />
   )
}
