import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { USERS_ME_ACCEPT_ONLY_VERIFIED_INVOICES } from "../../../endpoints"

export async function setSupplierInvoiceSettings(token, value) {
   let headers = new Headers()
   headers.append("Content-Type", "application/json")
   headers.append("Accept", "application/json")
   headers.append("Authorization", "Bearer " + token)
   Object.entries(getImpersonatedUserHeaders()).forEach(([name, value]) => {
      headers.append(name, value)
   })

   const body = {
      accept_only_verified_supplier_invoices: value,
   }

   const requestInit = {
      headers,
      method: "PUT",
      body: JSON.stringify(body),
   }

   const response = await fetch(
      process.env.REACT_APP_BASE_URL + USERS_ME_ACCEPT_ONLY_VERIFIED_INVOICES,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
