const KEY_PREFIX = "cache_invalidation__"
const DEFAULT_TTL = 60 * 60 * 1000 // milliseconds

const buildKey = (id) => KEY_PREFIX + id

export const getCacheInvalidationTimestamp = (endpointId) => {
   const key = buildKey(endpointId)

   const timestamp = Number(localStorage.getItem(key))

   if (timestamp + DEFAULT_TTL <= Date.now()) {
      localStorage.removeItem(key)

      return null
   }

   return timestamp
}

export const updateCacheInvalidationTimestamp = (endpointId) => {
   const key = buildKey(endpointId)
   const timestamp = Date.now()

   try {
      localStorage.setItem(key, timestamp)

      return timestamp
   } catch (error) {
      return null
   }
}
