import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
   changeActiveContext,
   selectActiveContext,
   selectEnabledContexts,
} from "store/app"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
   },
   mainText: {
      color: theme.palette.text.white,
   },
}))

const contextToTranslationId = {
   "it.api.acubeapi.com": "Context.it",
   "peppol.api.acubeapi.com": "Context.peppol",
   "pl.api.acubeapi.com": "Context.pl",
   "ob.api.acubeapi.com": "Context.ob",
}

export default function ListMenu() {
   const { t } = useTranslation()
   const classes = useStyles()
   const [anchorEl, setAnchorEl] = React.useState(null)
   const enabledContexts = useSelector(selectEnabledContexts)
   const activeContext = useSelector(selectActiveContext)
   const dispatch = useDispatch()

   const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget)
   }

   const handleMenuItemClick = (context) => {
      // @TODO: move in "changeActiveContext" action
      localStorage.setItem("active_context", context)

      dispatch(changeActiveContext(context))
      setAnchorEl(null)
   }

   const handleClose = () => {
      setAnchorEl(null)
   }

   return (
      <div className={classes.root}>
         <List component="nav" disablePadding={true}>
            <ListItem
               button
               aria-haspopup="true"
               aria-controls="lock-menu"
               onClick={handleClickListItem}
            >
               <ListItemText
                  className={classes.mainText}
                  secondary={t("Menu.Contesto Attivo")}
                  primary={
                     <Typography variant="subtitle1">
                        {t(contextToTranslationId[activeContext])}
                     </Typography>
                  }
               />
            </ListItem>
         </List>

         <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            {enabledContexts.map(({ id }) => (
               <MenuItem
                  key={id}
                  disabled={id === activeContext}
                  onClick={() => handleMenuItemClick(id)}
               >
                  {t(contextToTranslationId[id])}
               </MenuItem>
            ))}
         </Menu>
      </div>
   )
}
