import { Box, Button, CircularProgress } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useGenerateDemoInvoicesMutation } from "services/gov-pl/demoInvoices"
import type { FormValues } from ".."

type Props = {
   formValues: FormValues
   setFormErrors: (errors: FormValues) => void
}

const GenerateCta = ({ formValues, setFormErrors }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [generateDemoInvoices, { isLoading }] =
      useGenerateDemoInvoicesMutation()

   const handleGenerate = async () => {
      const { nip1, nip2, count } = formValues

      const errors = {
         nip1: nip1 === "" ? t("invoices_demo.error.required_field") : "",
         nip2: nip2 === "" ? t("invoices_demo.error.required_field") : "",
         count: count === "" ? t("invoices_demo.error.required_field") : "",
      }
      setFormErrors(errors)

      const hasErrors = Object.values(errors).some((error) => error !== "")

      if (hasErrors) {
         return
      }

      const response = await generateDemoInvoices({
         nip1,
         nip2,
         invoiceCount: Number(count),
      })

      if ("error" in response) {
         enqueueSnackbar(t("invoices_demo.error.generic"), { variant: "error" })
         return
      }

      if ("data" in response) {
         enqueueSnackbar(t("invoices_demo.batch_invoices.success.generate"), {
            variant: "success",
         })
         return
      }
   }

   return (
      <Button
         color="primary"
         disabled={isLoading}
         fullWidth
         size="large"
         type="button"
         variant="contained"
         onClick={handleGenerate}
      >
         {isLoading ? (
            <>
               <Box display="flex" mr={1}>
                  <CircularProgress size={24} color="inherit" />
               </Box>
               {t("invoices_demo.batch_invoices.generate_cta_loading")}
            </>
         ) : (
            t("invoices_demo.batch_invoices.generate_cta")
         )}
      </Button>
   )
}

export default GenerateCta
