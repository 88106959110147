import {
   Box,
   Button,
   Checkbox,
   FormControlLabel,
   Grid,
   makeStyles,
   Select,
} from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionActions from "@material-ui/core/AccordionActions"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import { DateInput, dateInputDelimiter } from "components/DateInput"
import {
   CUSTOMER_INVOICE_STATES,
   DRAFT_INVOICE_STATES,
   SUPPLIER_INVOICE_STATES,
} from "config"
import { Field, Formik, FormikHelpers } from "formik"
import { TextField } from "formik-material-ui"
import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { formatDate } from "services/dates"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"
import { readTableFilters } from "store/tablesState/utils"
import { InvoiceType } from "views/gov-it/CustomerSupplierInvoice"
import IosSwitch from "../IosSwith"
import LabelYesNo from "../LabelYesNo"
import { Marking } from "../Marking"
import {
   DateInputFields,
   documentTypes,
   FormFilterType,
   FormValues,
   getEmptyValues,
} from "./utils"

type InvoiceFilterFormType = {
   table: TableName
   filterType: FormFilterType
   actions?: React.ReactNode
   type?: InvoiceType
}

const useStyles = makeStyles((theme) => ({
   root: {},
   button: {
      marginBottom: 12,
      marginRight: 12,
   },
}))

const InvoiceFilterForm = ({
   table,
   filterType,
   actions,
   type,
}: InvoiceFilterFormType) => {
   const emptyValues = getEmptyValues<typeof filterType>(filterType)
   const [rejectedToFix, setRejectedToFix] = React.useState(false)

   const dispatch = useAppDispatch()

   const classes = useStyles()
   const { t } = useTranslation()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   ) as TablesState[typeof filterType]["filters"]

   const after = filters.createdAt.after || filters.invoiceDate.after

   const before = filters.createdAt.before || filters.invoiceDate.before

   const filterChanged = () => {
      const temps = readTableFilters(table)
      if (!temps) return false
      return !_.isEqual(readTableFilters(table), {
         ...emptyValues,
         createdAt: {
            after: filters.createdAt.after,
            before: filters.createdAt.before,
         },
         invoiceDate: {
            after: filters.invoiceDate.after,
            before: filters.invoiceDate.before,
         },
      })
   }

   const handleReset = (
      setValues: FormikHelpers<FormValues<typeof filterType>>["setValues"]
   ) => {
      dispatch(changePage({ table, page: 1 }))
      dispatch(resetFilters({ table }))
      const values = {
         ...emptyValues,
         createdAt:
            createdAt.after && createdAt.before
               ? `${createdAt.after}${dateInputDelimiter}${createdAt.before}`
               : "",
         invoiceDate:
            invoiceDate.after && invoiceDate.before
               ? `${invoiceDate.after}${dateInputDelimiter}${invoiceDate.before}`
               : "",
      } as FormValues<typeof filterType>
      setValues(values)
      setRejectedToFix(false)
      handleFilter(values)
      setExpanded(false)
   }

   const handleFilter = (values: FormValues<typeof filterType>) => {
      dispatch(changePage({ table, page: 1 }))
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as keyof TablesState[typeof table]["filters"]
            const filterChanged = value !== filters[filter]

            if (DateInputFields.includes(filter)) {
               const [after, before] = (value as string).split(
                  dateInputDelimiter
               )

               let filterChangedDate =
                  after !== undefined &&
                  after !== "" &&
                  before !== undefined &&
                  before !== ""

               if (filter === "createdAt") {
                  filterChangedDate =
                     filterChangedDate &&
                     (after !== filters.createdAt.after ||
                        before !== filters.createdAt.before)
               } else if (filter === "invoiceDate") {
                  filterChangedDate =
                     filterChangedDate &&
                     (after !== filters.invoiceDate.after ||
                        before !== filters.invoiceDate.before)
               }

               if (filterChangedDate) {
                  dispatch(
                     updateFilter({ table, filter, value: { after, before } })
                  )
               }
            }

            if (filterChanged && !DateInputFields.includes(filter)) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   const { createdAt, invoiceDate, ...filterValues } = filters

   const getCurrentFormValues = () => {
      return {
         ...filterValues,
         createdAt:
            createdAt.after && createdAt.before
               ? `${createdAt.after}${dateInputDelimiter}${createdAt.before}`
               : "",
         invoiceDate:
            invoiceDate.after && invoiceDate.before
               ? `${invoiceDate.after}${dateInputDelimiter}${invoiceDate.before}`
               : "",
      }
   }
   const initialValues = getCurrentFormValues()

   const [expanded, setExpanded] = React.useState(filterChanged())

   const getStateInvoices = () => {
      switch (table) {
         case "customer.invoices":
            return CUSTOMER_INVOICE_STATES
         case "supplier.invoices":
            return SUPPLIER_INVOICE_STATES
         case "invoice.drafts":
            return DRAFT_INVOICE_STATES
         default:
            return []
      }
   }

   let handleSetvaluesFn:
      | FormikHelpers<FormValues<typeof filterType>>["setValues"]
      | null

   useEffect(() => {
      if (handleSetvaluesFn) {
         handleSetvaluesFn(getCurrentFormValues())
         setExpanded(filterChanged())
      }
   }, [type])

   return (
      <Box mb={4}>
         <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({
               values,
               handleBlur,
               handleChange,
               handleSubmit,
               resetForm,
               setValues,
               setFieldValue,
            }) => {
               handleSetvaluesFn = setValues
               return (
                  <form
                     onSubmit={handleSubmit}
                     noValidate
                     className={clsx(classes.root)}
                  >
                     <Accordion
                        elevation={1}
                        expanded={expanded}
                        onChange={(_, expanded) => setExpanded(expanded)}
                     >
                        <Grid container alignItems={"center"}>
                           {actions && (
                              <Grid item xs={12} sm={3}>
                                 {actions}
                              </Grid>
                           )}
                           <Grid item xs>
                              <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls="panel1c-content"
                                 id="panel1c-header"
                              >
                                 <div>
                                    <Typography>
                                       {t("Default.Filtra i risultati")}{" "}
                                       {after &&
                                          before &&
                                          t("InvoiceFilters.dateRange", {
                                             from: formatDate(after),
                                             to: formatDate(before),
                                          })}
                                    </Typography>
                                 </div>
                              </AccordionSummary>
                           </Grid>
                        </Grid>

                        <AccordionDetails>
                           <Grid container spacing={3}>
                              <Grid item md={4} xs={12}>
                                 <DateInput
                                    label={t("InvoiceFilters.createdAt[after]")}
                                    name="createdAt"
                                    onChange={handleChange("createdAt")}
                                    onBlur={handleBlur}
                                    value={values.createdAt}
                                    disabled={values.uuid !== ""}
                                 />
                              </Grid>
                              {filterType !== "invoice.drafts" && (
                                 <>
                                    {"toPa" in values && (
                                       <Grid item md={2} xs={12}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor="toPa">
                                                {t("InvoiceFilters.toPa")}
                                             </InputLabel>
                                             <Select
                                                disabled={values.uuid !== ""}
                                                name="toPa"
                                                onChange={(e) => {
                                                   if (
                                                      e.target.value ===
                                                      undefined
                                                   ) {
                                                      e.target.value = ""
                                                   }
                                                   handleChange(e)
                                                }}
                                                value={values.toPa}
                                                onBlur={handleBlur}
                                                fullWidth
                                             >
                                                <MenuItem value={undefined}>
                                                   <LabelYesNo value={null} />
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                   <LabelYesNo value={true} />
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                   <LabelYesNo value={false} />
                                                </MenuItem>
                                             </Select>
                                          </FormControl>
                                       </Grid>
                                    )}
                                    {"signed" in values && (
                                       <Grid item md={2} xs={12}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor="signed">
                                                {t("InvoiceFilters.signed")}
                                             </InputLabel>
                                             <Select
                                                disabled={values.uuid !== ""}
                                                name="signed"
                                                onBlur={handleBlur}
                                                value={values.signed}
                                                onChange={(e) => {
                                                   if (
                                                      e.target.value ===
                                                      undefined
                                                   ) {
                                                      e.target.value = ""
                                                   }
                                                   handleChange(e)
                                                }}
                                                fullWidth
                                             >
                                                <MenuItem value={undefined}>
                                                   <LabelYesNo value={null} />
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                   <LabelYesNo value={true} />
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                   <LabelYesNo value={false} />
                                                </MenuItem>
                                             </Select>
                                          </FormControl>
                                       </Grid>
                                    )}
                                    {"downloaded" in values && (
                                       <Grid item md={2} xs={12}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor="downloaded">
                                                {t("InvoiceFilters.downloaded")}
                                             </InputLabel>
                                             <Select
                                                disabled={values.uuid !== ""}
                                                name="downloaded"
                                                onBlur={handleBlur}
                                                value={values.downloaded}
                                                onChange={(e) => {
                                                   if (
                                                      e.target.value ===
                                                      undefined
                                                   ) {
                                                      e.target.value = ""
                                                   }
                                                   handleChange(e)
                                                }}
                                                fullWidth
                                             >
                                                <MenuItem value={undefined}>
                                                   <LabelYesNo value={null} />
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                   <LabelYesNo value={true} />
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                   <LabelYesNo value={false} />
                                                </MenuItem>
                                             </Select>
                                          </FormControl>
                                       </Grid>
                                    )}
                                    {"stamp" in values && (
                                       <Grid item md={2} xs={12}>
                                          <FormControl fullWidth>
                                             <InputLabel htmlFor="stamp">
                                                {t("InvoiceFilters.stamp")}
                                             </InputLabel>
                                             <Select
                                                disabled={values.uuid !== ""}
                                                name="stamp"
                                                onChange={(e) => {
                                                   if (
                                                      e.target.value ===
                                                      undefined
                                                   ) {
                                                      e.target.value = ""
                                                   }
                                                   handleChange(e)
                                                }}
                                                value={values.stamp}
                                                onBlur={handleBlur}
                                                fullWidth
                                             >
                                                <MenuItem value={undefined}>
                                                   <LabelYesNo value={null} />
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                   <LabelYesNo value={true} />
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                   <LabelYesNo value={false} />
                                                </MenuItem>
                                             </Select>
                                          </FormControl>
                                       </Grid>
                                    )}
                                 </>
                              )}

                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="marking">
                                       {t("InvoiceFilters.marking")}
                                    </InputLabel>
                                    <Select
                                       disabled={
                                          values.uuid !== "" || rejectedToFix
                                       }
                                       name="marking"
                                       multiple
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (
                                             (
                                                e.target.value as string[]
                                             ).includes("None")
                                          ) {
                                             setFieldValue("marking", [])
                                             return
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.marking}
                                       renderValue={(selected) => {
                                          const tmps =
                                             (selected as string[]).length > 3
                                                ? (selected as string[]).slice(
                                                     -3
                                                  )
                                                : (selected as string[])
                                          return (
                                             <Box
                                                display="flex"
                                                flexWrap="wrap"
                                             >
                                                {(selected as string[]).length >
                                                   3 && "..."}{" "}
                                                {tmps.map((marking) => (
                                                   <Marking
                                                      key={marking}
                                                      marking={marking}
                                                   />
                                                ))}
                                             </Box>
                                          )
                                       }}
                                       fullWidth
                                    >
                                       <MenuItem value={"None"}>
                                          <Marking marking={"None"} />
                                       </MenuItem>
                                       {getStateInvoices().map((state) => (
                                          <MenuItem key={state} value={state}>
                                             <Checkbox
                                                checked={
                                                   values.marking.indexOf(
                                                      state
                                                   ) > -1
                                                }
                                             />
                                             <Marking marking={state} />
                                          </MenuItem>
                                       ))}
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Field
                                    component={TextField}
                                    disabled={values.uuid !== ""}
                                    type="text"
                                    label={t("InvoiceFilters.sender")}
                                    name="sender.fiscalId"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Field
                                    component={TextField}
                                    disabled={values.uuid !== ""}
                                    type="text"
                                    label={t(
                                       "InvoiceFilters.sender_business_name"
                                    )}
                                    name="sender.businessName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Field
                                    component={TextField}
                                    disabled={values.uuid !== ""}
                                    type="text"
                                    label={t("InvoiceFilters.recipient")}
                                    name="recipient.fiscalId"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Field
                                    component={TextField}
                                    disabled={values.uuid !== ""}
                                    type="text"
                                    label={t(
                                       "InvoiceFilters.recipient_business_name"
                                    )}
                                    name="recipient.businessName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>

                              <Grid item md={3} xs={12}>
                                 <DateInput
                                    disabled={values.uuid !== ""}
                                    label={t("InvoiceFilters.invoiceDate")}
                                    name="invoiceDate"
                                    onChange={handleChange("invoiceDate")}
                                    onBlur={handleBlur}
                                    value={values.invoiceDate}
                                 />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Tooltip
                                    title={t(
                                       "InvoiceFilters.invoiceNumberSuggestion"
                                    )}
                                    placement="bottom"
                                 >
                                    <div>
                                       <Field
                                          component={TextField}
                                          disabled={
                                             values.invoiceDate === "" ||
                                             values.uuid !== ""
                                          }
                                          type="text"
                                          label={t(
                                             "InvoiceFilters.invoiceNumber"
                                          )}
                                          name="invoiceNumber"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          fullWidth
                                       />
                                    </div>
                                 </Tooltip>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <FormControl fullWidth>
                                    <InputLabel htmlFor="documentType">
                                       {t("InvoiceFilters.document_type")}
                                    </InputLabel>
                                    <Select
                                       disabled={values.uuid !== ""}
                                       name="documentType"
                                       onBlur={handleBlur}
                                       onChange={(e) => {
                                          if (e.target.value === undefined) {
                                             e.target.value = null
                                          }
                                          handleChange(e)
                                       }}
                                       value={values.documentType}
                                       fullWidth
                                    >
                                       <MenuItem value={undefined}>
                                          <Marking marking={"None"} />
                                       </MenuItem>
                                       {documentTypes.map(({ code, label }) => (
                                          <MenuItem
                                             key={code}
                                             value={code}
                                             title={label}
                                          >
                                             {code}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                 <Field
                                    component={TextField}
                                    type="text"
                                    label={t("InvoiceFilters.uuid")}
                                    name="uuid"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                 />
                              </Grid>

                              {/*  add first this grid at then end **/}
                              {filterType === "customer.invoices" &&
                                 "rejectedToFix" in values && (
                                    <Grid item md={3} xs={12}>
                                       <FormControlLabel
                                          name="rejectedToFix"
                                          value="bottom"
                                          control={
                                             <IosSwitch
                                                disabled={values.uuid !== ""}
                                                checked={rejectedToFix}
                                                name="rejectedToFix"
                                                onChange={(e) => {
                                                   setRejectedToFix(
                                                      e.target.checked
                                                   )
                                                   setFieldValue(
                                                      "marking",
                                                      []
                                                   ).then(() => {
                                                      handleChange(e)
                                                   })
                                                }}
                                             />
                                          }
                                          label={t(
                                             "InvoiceFilters.rejectedToBeFix"
                                          )}
                                       />
                                    </Grid>
                                 )}
                           </Grid>
                        </AccordionDetails>

                        <AccordionActions>
                           <Button
                              className={classes.button}
                              onClick={() => {
                                 resetForm()
                                 handleReset(setValues)
                              }}
                              variant="contained"
                           >
                              {t("Default.Cancella")}
                           </Button>
                           <Button
                              className={classes.button}
                              type="submit"
                              color="primary"
                              variant="contained"
                           >
                              {t("Default.Cerca")}
                           </Button>
                        </AccordionActions>
                     </Accordion>
                  </form>
               )
            }}
         </Formik>
      </Box>
   )
}

export default InvoiceFilterForm
export { DateInputFields, documentTypes, getEmptyValues } from "./utils"
export type { FormFilterType, FormValues } from "./utils"
