import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { SubAccount, useGetSubAccountsQuery } from "services/commonApi"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableFilters,
   selectTotalItems,
   updateTotalItems,
} from "store/tablesState"
import LabelBoolean from "views/common/LabelBoolean"
import RowCTAs from "views/settings/SubAccounts/SubAccountsTable/RowCTAs"

const table = `common.sub_accounts`

const SubAccountsTable = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const { data, isError, isFetching } = useGetSubAccountsQuery({
      page: currentPage,
      ...filters,
   })

   useEffect(() => {
      const totalItems = data?.["hydra:totalItems"]

      if (totalItems != null) {
         dispatch(updateTotalItems({ table, totalItems }))
      }
   }, [dispatch, data])

   return (
      <Paper>
         <Table
            size="small"
            columnsDefinition={columns}
            rows={data?.["hydra:member"]}
            rowKeyId="email"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="common_subAccounts.no_entry"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default SubAccountsTable

const columns: TableColumn<SubAccount>[] = [
   {
      rowKey: "email",
      configurable: true,
      labelTranslationId: "common_subAccounts.form.email",
      format: (value) => value,
   },
   {
      rowKey: "fiscal_id",
      configurable: true,
      labelTranslationId: "common_subAccounts.form.fiscal_id",
      format: (value) => value,
   },
   {
      rowKey: "fullname",
      configurable: true,
      labelTranslationId: "common_subAccounts.form.fullname",
      format: (value) => value,
   },
   {
      rowKey: "enabled",
      configurable: true,
      labelTranslationId: "common_subAccounts.enabled",
      format: (value) =>
         typeof value === "boolean" ? <LabelBoolean value={value} /> : null,
   },
   {
      rowKey: "created_at",
      configurable: true,
      labelTranslationId: "common_subAccounts.created_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },

   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => <RowCTAs row={row} />,
   },
]
