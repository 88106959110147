import { Box } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { WarningTypography } from "../WarningTypography"
import ChangePasswordForm from "./ChangePasswordForm"

export default function ChangePasswordDialog(props) {
   const { t } = useTranslation()
   const classes = useStyles()

   return (
      <Dialog
         fullWidth={true}
         maxWidth="sm"
         open={props.isOpen}
         onClose={props.handleClose}
         aria-labelledby="change-password-dialog-title"
         aria-describedby="change-password-dialog-description"
         className={classes.container}
      >
         <DialogTitle id="change-password-dialog-title">
            {t("User.Modifica Password")}
         </DialogTitle>

         <DialogContent>
            <WarningTypography>
               {t(
                  "User.Modificando questa password verra modificata anche la password di interrogazione alle API"
               )}
            </WarningTypography>

            {props.error && (
               <Box mt={2} mb={2}>
                  <Alert severity="error">{props.error}</Alert>
               </Box>
            )}

            <ChangePasswordForm
               handleClose={props.handleClose}
               isLoading={props.isLoading}
               handleSave={props.handleSave}
            />
         </DialogContent>
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
}))
