import { Box, Button, Link, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { setOnboardMode1CurrentStep } from "store/slices/onboard"

const SignSignatureFile = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const handleGoToNextStep = () => {
      dispatch(setOnboardMode1CurrentStep("upload"))
   }

   return (
      <>
         <Box textAlign="center">
            <Typography variant="h2">
               {t("onboard.steps.sign.title")}
            </Typography>
         </Box>

         <Box marginTop={6} marginBottom={6}>
            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.sign.body_1")}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.sign.body_2")}
                  <Link
                     href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER&xadesPdf=true"
                     target="_blank"
                  >
                     https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER&xadesPdf=true
                  </Link>
               </Typography>
            </Box>
         </Box>

         <Box textAlign="right">
            <Button
               color="primary"
               variant="contained"
               size="large"
               onClick={handleGoToNextStep}
            >
               {t("onboard.steps.sign.next_cta")}
            </Button>
         </Box>
      </>
   )
}

export default SignSignatureFile
