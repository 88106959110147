const IMPERSONATED_USER_KEY = "impersonated_user"

export const saveImpersonatedUser = (username) => {
   localStorage.setItem(IMPERSONATED_USER_KEY, username)
}

export const clearImpersonatedUser = () => {
   localStorage.removeItem(IMPERSONATED_USER_KEY)
}

export const getImpersonatedUser = () => {
   return localStorage.getItem(IMPERSONATED_USER_KEY)
}

export const getImpersonatedUserHeaders = () => {
   const impersonatedUser = getImpersonatedUser()

   if (impersonatedUser !== null) {
      return { "X-Switch-User": impersonatedUser }
   }

   return {}
}
