import { Button, CircularProgress, Tooltip } from "@material-ui/core"
import { AppEnv } from "config"
import { useSnackbar } from "notistack"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { useConnectMutation } from "services/openbankingApi"
import { useGetAccountsQuery } from "services/openbankingApi/accounts"
import { useAppDispatch, useAppSelector } from "store"
import { selectUsername } from "store/auth"
import { openDialog } from "store/slices/dialogs"

type WrapperButtonProps = PropsWithChildren<{
   canNotConnectInfiniteAccountInSandbox: boolean
   limit: number
}>
const WrapperButton = ({
   canNotConnectInfiniteAccountInSandbox,
   limit,
   children,
}: WrapperButtonProps) => {
   const { t } = useTranslation()
   if (canNotConnectInfiniteAccountInSandbox) {
      return (
         <Tooltip
            title={t("openbanking.business_registry.connect_cta_sanbox_limit", {
               limit,
            })}
            placement="top"
            arrow
         >
            <span>{children}</span>
         </Tooltip>
      )
   }
   return <>{children}</>
}

type Props = {
   fiscalId: string
}

const ConnectCTA = ({ fiscalId }: Props) => {
   const APP_ENV: AppEnv = process.env.REACT_APP_ENV as AppEnv
   const username = useAppSelector(selectUsername)

   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [triggerConnect, { isLoading }] = useConnectMutation()

   const MaxSanboxAccount = 5

   const { data } = useGetAccountsQuery({ fiscalId })

   const totalAccounts = data?.["hydra:totalItems"] ?? 0
   const canNotConnectInfiniteAccountInSandbox =
      APP_ENV === "sandbox"
         ? username !== "sandbox@acubeapi.com" &&
           totalAccounts >= MaxSanboxAccount
         : false

   const connect = async () => {
      const response = await triggerConnect({
         fiscalId,
         locale: null,
         country: null,
      })

      if ("data" in response) {
         const { connectUrl } = response.data

         dispatch(
            openDialog({
               id: "openbanking_connect_success",
               data: { connectUrl },
            })
         )

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <WrapperButton
         canNotConnectInfiniteAccountInSandbox={
            canNotConnectInfiniteAccountInSandbox
         }
         limit={MaxSanboxAccount}
      >
         <Button
            disabled={isLoading || canNotConnectInfiniteAccountInSandbox}
            color="primary"
            variant="contained"
            onClick={connect}
         >
            {!isLoading && t("openbanking.business_registry.connect_cta")}
            {isLoading && <CircularProgress color="inherit" size={22} />}
         </Button>
      </WrapperButton>
   )
}

export default ConnectCTA
