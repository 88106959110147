import getParameter from "../request/getParameter"
import formatExpense from "./formatExpense"

export default function formatExpenses(response) {
   const items = response["hydra:member"].map((row) => {
      return formatExpense(row)
   })

   const view = response["hydra:view"]

   return {
      count: response["hydra:totalItems"],
      first:
         view && view["hydra:first"]
            ? parseInt(getParameter(view["hydra:first"], "page"))
            : null,
      last:
         view && view["hydra:last"]
            ? parseInt(getParameter(view["hydra:last"], "page"))
            : null,
      next:
         view && view["hydra:next"]
            ? parseInt(getParameter(view["hydra:next"], "page"))
            : null,
      previous:
         view && view["hydra:previous"]
            ? parseInt(getParameter(view["hydra:previous"], "page"))
            : null,
      items: items,
   }
}
