import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { setInvoicesDemoNip1 } from "store/slices/invoicesDemo"
import type { FormValues } from ".."

type Props = {
   formValues: FormValues
   setFormErrors: (errors: FormValues) => void
}

const StartCta = ({ formValues, setFormErrors }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const handleStart = async () => {
      const { nip1 } = formValues

      const errors = {
         nip1: nip1 === "" ? t("invoices_demo.error.required_field") : "",
         nip2: "",
         count: "",
      }
      setFormErrors(errors)

      const hasErrors = Object.values(errors).some((error) => error !== "")

      if (hasErrors) {
         return
      }

      dispatch(setInvoicesDemoNip1(nip1))
   }

   return (
      <Button
         color="primary"
         fullWidth
         size="large"
         type="button"
         variant="contained"
         onClick={handleStart}
      >
         {t("invoices_demo.batch_invoices.start_cta")}
      </Button>
   )
}

export default StartCta
