import { formatReceipt } from "./formatReceipt"

export default function formatReceipts(response) {
   let receipts = response
      .map((row) => formatReceipt(row))
      .filter((receipt) => receipt.protocol !== null)

   return {
      count: receipts.length,
      first: null,
      last: null,
      next: null,
      previous: null,
      items: receipts,
   }
}
