import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from ".."

export type PeppolPaginationParams = {
   page?: number
   itemsPerPage?: number
}

export const peppolApi = createApi({
   tagTypes: [
      "PEPPOL_LEGAL_ENTITIES",
      "PEPPOL_DOCUMENT_TYPES",
      "PEPPOL_PROCESSES",
   ],
   reducerPath: "peppolApi",
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_PEPPOL_BASE_URL as string,
   }),
   endpoints: () => ({}),
})
