import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { WEBHOOKS } from "../../../../endpoints"
import serializeQuery from "../../serializer/serializeQuery"

export async function getWebhooks(token, searchParams, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   let queryString = serializeQuery(searchParams || "")

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${WEBHOOKS}?${queryString}`,
      requestInit
   )

   if (!response.ok) {
      return
   }

   const contentType = response.headers.get("Content-Type")

   if ("application/ld+json; charset=utf-8" === contentType) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function getWebhook(token, webhookId, context = "") {
   const requestInit = {
      method: "GET",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${WEBHOOKS}/${webhookId}`,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}

export async function deleteWebhook(token, webhookId, context = "") {
   const requestInit = {
      method: "DELETE",
      headers: {
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${WEBHOOKS}/${webhookId}`,
      requestInit
   )

   if (response.ok) {
      return response
   }

   let text = await response.text()
   throw new Error(text)
}

// body: { event: "outgoing-document" | "incoming-document", url: urlString }
export async function postWebhook(token, body, context = "") {
   const requestInit = {
      method: "POST",
      headers: {
         Authorization: "Bearer " + token,
         Accept: "application/json",
         "Content-Type": "application/json",
         ...getImpersonatedUserHeaders(),
      },
      body: JSON.stringify(body),
   }

   const response = await fetch(
      `${process.env.REACT_APP_PEPPOL_BASE_URL}${context}${WEBHOOKS}`,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
