import {
   Box,
   Button,
   CircularProgress,
   DialogActions,
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Typography,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { format, parseISO } from "date-fns"
import { useTranslation } from "react-i18next"
import { useGetSubscriptionsQuery } from "services/commonApi"
import { DialogId, DialogState } from "store/slices/dialogs"
import Label from "views/common/Label"

const DIALOG_ID: DialogId = "openbanking_subscription_details"

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, fiscalId }: Props) => {
   const { t } = useTranslation()

   const {
      data = [],
      isFetching,
      isError,
   } = useGetSubscriptionsQuery({
      project_codename: "ob.api.acubeapi.com",
      fiscal_id: fiscalId,
      active: 1,
   })

   const details = data[0]

   return (
      <>
         <DialogTitle>
            {t(
               "openbanking.business_registry.subscription_details_dialog_title"
            )}
         </DialogTitle>

         <DialogContent>
            {isFetching && (
               <Box display="flex" justifyContent="center" color="grey.500">
                  <CircularProgress size={40} color="inherit" />
               </Box>
            )}

            {!isFetching && isError && (
               <Box display="flex" justifyContent="center">
                  <Typography variant="body1">
                     {t("global.messages.generic_error")}
                  </Typography>
               </Box>
            )}

            {!isFetching && details == null && (
               <Box display="flex" justifyContent="center">
                  <Typography variant="body1">
                     {t(
                        "openbanking.business_registry.subscription_details_dialog.no_subscription_details"
                     )}
                  </Typography>
               </Box>
            )}

            {!isFetching && details != null && (
               <List>
                  {details.fiscal_id != null && (
                     <ListItem divider>
                        <ListItemText
                           primary={t(
                              "openbanking.business_registry.subscription_details_dialog.fiscal_id"
                           )}
                        />
                        <ListItemSecondaryAction>
                           <ListItemText primary={details.fiscal_id} />
                        </ListItemSecondaryAction>
                     </ListItem>
                  )}

                  <ListItem divider>
                     <ListItemText
                        primary={t(
                           "openbanking.business_registry.subscription_details_dialog.active"
                        )}
                     />
                     <ListItemSecondaryAction>
                        <Label color={details.active ? "success" : "error"}>
                           {details.active ? "Active" : "Inactive"}
                        </Label>
                     </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem divider>
                     <ListItemText
                        primary={t(
                           "openbanking.business_registry.subscription_details_dialog.starts_at"
                        )}
                     />
                     <ListItemSecondaryAction>
                        <ListItemText
                           primary={format(
                              parseISO(details.period_starts_at),
                              "dd MMM yyyy HH:mm:ss"
                           )}
                        />
                     </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem divider>
                     <ListItemText
                        primary={t(
                           "openbanking.business_registry.subscription_details_dialog.ends_at"
                        )}
                     />
                     <ListItemSecondaryAction>
                        <ListItemText
                           primary={format(
                              parseISO(details.period_ends_at),
                              "dd MMM yyyy HH:mm:ss"
                           )}
                        />
                     </ListItemSecondaryAction>
                  </ListItem>

                  {details.auto_renew != null && (
                     <ListItem divider>
                        <ListItemText
                           primary={t(
                              "openbanking.business_registry.subscription_details_dialog.auto_renew"
                           )}
                        />
                        <ListItemSecondaryAction>
                           <Label
                              color={details.auto_renew ? "success" : "error"}
                           >
                              {details.auto_renew ? "Yes" : "No"}
                           </Label>
                        </ListItemSecondaryAction>
                     </ListItem>
                  )}

                  {details.auto_renew_at != null && (
                     <ListItem divider>
                        <ListItemText
                           primary={t(
                              "openbanking.business_registry.subscription_details_dialog.auto_renew_at"
                           )}
                        />
                        <ListItemSecondaryAction>
                           <ListItemText
                              primary={format(
                                 parseISO(details.auto_renew_at),
                                 "dd MMM yyyy HH:mm:ss"
                              )}
                           />
                        </ListItemSecondaryAction>
                     </ListItem>
                  )}
               </List>
            )}
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const SubscriptionDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default SubscriptionDetailsDialog
