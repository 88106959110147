import {
   Box,
   Button,
   CircularProgress,
   FormLabel,
   TextField,
   Typography,
} from "@material-ui/core"
import { blobToBase64 } from "helper/encodings"
import { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { govPlApi } from "services/gov-pl"
import { useActivateLegalEntityMutation } from "services/gov-pl/onboard"
import { useAppDispatch } from "store"
import {
   setActivationFailed,
   setOnboardMode1CurrentStep,
} from "store/slices/onboard"

type Props = {
   authFlowUuid: string
   nip: string
}

const UploadSignatureFile = ({ authFlowUuid, nip }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { legalEntityUuid, hash } = useParams()

   const [signedFileContent, setSignedFileContent] = useState<string | null>(
      null
   )

   const [activateLegalEntity, { isLoading: isActivating }] =
      useActivateLegalEntityMutation()

   const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      const file = files != null ? files[0] : null

      if (file == null) {
         setSignedFileContent(null)
         return
      }

      const content = await blobToBase64(file)
      setSignedFileContent(content)
   }

   const handleActivate = async () => {
      const response = await activateLegalEntity({
         uuid: legalEntityUuid!,
         hash: hash!,
         body: {
            type: "onip",
            identifier: nip,
            signedFile: signedFileContent!,
         },
      })

      dispatch(setOnboardMode1CurrentStep("end"))

      if ("error" in response) {
         dispatch(setActivationFailed(true))
         return
      }

      if ("data" in response) {
         dispatch(govPlApi.util.invalidateTags(["LEGAL_ENTITIES"]))
         dispatch(setActivationFailed(false))
         return
      }
   }

   return (
      <>
         <Box textAlign="center">
            <Typography variant="h2">
               {t("onboard.steps.sign.title")}
            </Typography>
         </Box>

         <Box marginTop={6} marginBottom={4}>
            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.upload.body_1")}
               </Typography>
            </Box>
         </Box>

         <FormLabel htmlFor="upload">
            {t("onboard.steps.upload.label")}
         </FormLabel>

         <TextField
            type="file"
            id="upload"
            onChange={handleSelectFile}
            fullWidth
         />

         <Box marginTop={4}>
            <Button
               disabled={isActivating || signedFileContent == null}
               color="primary"
               variant="contained"
               size="large"
               onClick={handleActivate}
            >
               {!isActivating && t("onboard.steps.upload.upload_cta")}
               {isActivating && <CircularProgress color="inherit" size={22} />}
            </Button>
         </Box>
      </>
   )
}

export default UploadSignatureFile
