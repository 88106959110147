import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { useGetAccountsQuery } from "services/openbankingApi/accounts"
import { useAppSelector } from "store"
import { selectTableData } from "store/tablesState"
import { FormValues } from ".."

// Use a high value to retrieve all the accounts with a single request.
const ITEMS_PER_PAGE = 100

const name = "accountUuid"

const AccountsSelect = () => {
   const { t } = useTranslation()

   const { fiscalId } = useAppSelector((state) =>
      selectTableData(state, { table: "openbanking.transactions" })
   )

   const { data } = useGetAccountsQuery({
      fiscalId,
      itemsPerPage: ITEMS_PER_PAGE,
   })
   const accounts = data?.["hydra:member"] ?? []

   const { values, handleChange } = useFormikContext<FormValues>()

   return (
      <FormControl fullWidth>
         <InputLabel>
            {t("openbanking.transactions.filters.accounts")}
         </InputLabel>

         <Select
            multiple
            value={values[name]}
            name={name}
            onChange={handleChange}
            label="Age"
         >
            {accounts.map(({ uuid, name }) => (
               <MenuItem key={uuid} value={uuid}>
                  {name}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   )
}

export default AccountsSelect
