import { govItApi } from ".."

type GetRejectedInvociesCountResponse = {
   count: number
   pending: number
}

type GetRejectedInvociesCountParams = {
   from_date: string
   to_date: string
}

type RecoverRejectedInvoicesResponse = {
   count: number
   uuid: string
}

type RecoverRejectedInvoicesBody = {
   from_date: string
   to_date: string
}

const govItRejectedInvoicesApi = govItApi.injectEndpoints({
   endpoints: (builder) => ({
      getRejectedInvoicesCount: builder.query<
         GetRejectedInvociesCountResponse,
         GetRejectedInvociesCountParams & { fiscalId: string }
      >({
         query: ({ fiscalId, ...params }) => ({
            url: `/rejected-invoices/${fiscalId}/count`,
            params,
         }),
         providesTags: ["REJECTED_INVOICES_COUNT"],
      }),

      recoverRejectedInvoices: builder.mutation<
         RecoverRejectedInvoicesResponse,
         RecoverRejectedInvoicesBody & { fiscalId: string }
      >({
         query: ({ fiscalId, ...body }) => ({
            url: `/rejected-invoices/${fiscalId}/recover`,
            method: "POST",
            body,
         }),
         invalidatesTags: ["REJECTED_INVOICES_COUNT"],
      }),
   }),
})

export const {
   useGetRejectedInvoicesCountQuery,
   useRecoverRejectedInvoicesMutation,
} = govItRejectedInvoicesApi
