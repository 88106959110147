import { Typography } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import BaseDialog from "components/BaseDialog"
import { useGetDraftInvoiceQuery } from "services/gov-it"
import { useAppSelector } from "store"
import { InvoiceDraftsFormMode } from "store/slices/dialogs"
import { selectRefetch, TableName } from "store/tablesState"
import Loader from "views/common/Loader"
import CreateInvoiceDraftsTabs from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog/CreateInvoiceTabs"

type ContentProps = {
   handleClose: () => void
   invoiceUuid?: string
   mode: InvoiceDraftsFormMode
}
export type ViolationType = {
   propertyPath: string
   message: string
}

const table: TableName = "invoice.drafts"
const Content = ({ handleClose, invoiceUuid, mode }: ContentProps) => {
   const refresh = useAppSelector((state) => selectRefetch(state, { table }))
   const {
      data: invoiceData,
      isLoading,
      error,
   } = useGetDraftInvoiceQuery(
      {
         uuid: invoiceUuid as string,
         ...(refresh && { refresh }),
      },
      {
         skip: !invoiceUuid,
      }
   )

   return (
      <>
         {isLoading ? (
            <Loader />
         ) : error ? (
            <>
               <Typography
                  variant="h5"
                  style={{
                     textAlign: "center",
                  }}
                  component="h2"
               >
                  something went wrong
               </Typography>
            </>
         ) : (
            <DialogContent>
               <CreateInvoiceDraftsTabs
                  handleClose={handleClose}
                  invoice={invoiceData}
                  mode={mode}
               />
            </DialogContent>
         )}
      </>
   )
}

const CreateInvoiceDraftsDialog = () => {
   return (
      <BaseDialog id="invoice.drafts.form" maxWidth="xl" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default CreateInvoiceDraftsDialog
