import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import {
   getInvoice,
   getInvoiceSourceJSON,
   getInvoiceXML,
} from "../../../services/client/request/peppol/invoices"
import Loader from "../Loader"
import PeppolInvoiceDetailsTabs from "./PeppolInvoiceDetailsTabs"

export default function InvoiceDetails(props) {
   const classes = useStyles()
   const token = useAppSelector(selectAuthToken)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [order, setOrder] = React.useState({})
   const [JSONorder, setJSONOrder] = React.useState({})
   const [XMLorder, setXMLOrder] = React.useState({})
   const [isLoading, setIsLoading] = React.useState(false)
   const context = props.context

   async function fetchData(orderUuid) {
      setIsLoading(true)
      setError(false)

      try {
         await Promise.all([
            getInvoice(token, orderUuid, context),
            getInvoiceSourceJSON(token, orderUuid, context),
            getInvoiceXML(token, orderUuid, context),
         ]).then((values) => {
            setOrder(values[0])
            setJSONOrder(values[1])
            setXMLOrder(values[2])
         })
         // const order = await getOrder(token, orderUuid)
         // setOrder(order)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      if (props.orderUuid) {
         fetchData(props.orderUuid)
         return
      }

      setOrder({})
   }, [props.orderUuid])

   return (
      <Dialog
         fullWidth={true}
         maxWidth="lg"
         open={props.isOpen}
         onClose={props.handleClose}
         className={classes.container}
      >
         {isLoading ? (
            <Loader />
         ) : (
            <DialogContent>
               {error ? (
                  <></>
               ) : (
                  <PeppolInvoiceDetailsTabs
                     order={order}
                     JSONorder={JSONorder}
                     XMLorder={XMLorder}
                  />
               )}
            </DialogContent>
         )}

         <DialogActions className={classes.buttons}>
            <Button
               onClick={props.handleClose}
               color="default"
               variant="contained"
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
