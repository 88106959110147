import { Grid } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

export default function DeleteDialog(props) {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Dialog
         fullWidth={true}
         maxWidth="xs"
         open={props.isOpen}
         onClose={props.handleClose}
         aria-labelledby="delete-dialog-title"
         aria-describedby="delete-dialog-description"
         className={classes.container}
      >
         <DialogTitle id="delete-dialog-title" style={{ textAlign: "center" }}>
            {t("Default.Sei sicuro di voler eliminare questo elemento?")}
         </DialogTitle>

         <DialogContent>
            <DialogContentText>
               <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                     <Grid container justify="center" spacing={2}>
                        <Grid item>
                           <Button
                              onClick={props.handleConfirm}
                              color="primary"
                              variant="contained"
                           >
                              {t("Default.Conferma")}
                           </Button>
                        </Grid>

                        <Grid item>
                           <Button
                              onClick={props.handleClose}
                              color="default"
                              variant="contained"
                           >
                              {t("Default.Annulla")}
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </DialogContentText>
         </DialogContent>
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
}))
