import {
   Card,
   CardContent,
   CardHeader,
   Divider,
   FormControlLabel,
   makeStyles,
   Switch,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { getSupplierInvoiceSettings } from "../../../services/client/request/getSupplierInvoiceSettings"
import { setSupplierInvoiceSettings } from "../../../services/client/request/setSupplierInvoiceSettings"
import LabelBoolean from "../LabelBoolean"
import Loader from "../Loader"
import { WarningTypography } from "../WarningTypography"

const useStyles = makeStyles((theme) => ({
   list: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
   },
}))

const SupplierInvoiceSettings = (props, { className, ...rest }) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const [
      acceptOnlyVerifiedSupplierInvoices,
      setAcceptOnlyVerifiedSupplierInvoices,
   ] = React.useState()
   const [isInit, setIsInit] = React.useState()

   const toggleAcceptOnlyVerifiedSupplierInvoices = async () => {
      setIsLoading(true)
      let newState = !acceptOnlyVerifiedSupplierInvoices

      await setSupplierInvoiceSettings(token, newState)

      setAcceptOnlyVerifiedSupplierInvoices(newState)
      setIsLoading(false)
   }

   async function fetchData() {
      setIsLoading(true)
      setError(false)

      try {
         const response = await getSupplierInvoiceSettings(token)

         setAcceptOnlyVerifiedSupplierInvoices(
            response.accept_only_verified_supplier_invoices
         )
         setIsInit(true)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      fetchData()
   }, [])

   return (
      <>
         <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
               title={t("BusinessRegistry.Impostazioni fatture passive")}
            />

            <Divider />

            {isLoading ? (
               <Loader />
            ) : (
               <CardContent>
                  <Box display="flex" p={1} style={{ width: "auto" }}>
                     <Box p={1} width="100%">
                        <Typography>
                           {t(
                              "BusinessRegistry.Accetta solo fatture passive per le partite iva e codici fiscali configurati"
                           )}
                        </Typography>
                        <WarningTypography>
                           {t(
                              "BusinessRegistry.ATTENZIONE attivando questa funzionalità riceverai fatture passive solo per le partite iva e i codici fiscali configurati con il flag 'Supplier invoice enabled' settato a true"
                           )}
                        </WarningTypography>
                     </Box>

                     {error ? (
                        <></>
                     ) : (
                        <Box p={1} flexShrink={1}>
                           {isInit && (
                              <FormControlLabel
                                 value="bottom"
                                 control={
                                    <Switch
                                       disabled={isLoading}
                                       checked={
                                          acceptOnlyVerifiedSupplierInvoices
                                       }
                                       onChange={
                                          toggleAcceptOnlyVerifiedSupplierInvoices
                                       }
                                       color="primary"
                                       name="acceptOnlyVerifiedSupplierInvoices"
                                       inputProps={{
                                          "aria-label": "primary checkbox",
                                       }}
                                    />
                                 }
                                 label={
                                    <LabelBoolean
                                       value={
                                          acceptOnlyVerifiedSupplierInvoices
                                             ? "true"
                                             : false
                                       }
                                    />
                                 }
                                 labelPlacement="bottom"
                              />
                           )}
                        </Box>
                     )}
                  </Box>
               </CardContent>
            )}
         </Card>
      </>
   )
}

export default SupplierInvoiceSettings
