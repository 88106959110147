import { DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useCreateWebhookMutation } from "services/openbankingApi/webhooks"
import * as Yup from "yup"
import EditWebhookForm, {
   FormValues,
   mapFormValuesToOpenbankingWebhook,
} from "../EditWebhookForm"

const DIALOG_ID = "openbanking_new_webhook"

const initialValues = {
   event: "",
   targetUrl: "",
   authenticationType: "",
   authenticationKey: "",
   authenticationToken: "",
}

const validationSchema = Yup.object().shape({
   event: Yup.string().required(),
   targetUrl: Yup.string().required(),
})

type Props = BaseDialogActions

const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [createWebhook] = useCreateWebhookMutation()

   const handleSubmit = async (values: FormValues) => {
      const response = await createWebhook(
         mapFormValuesToOpenbankingWebhook(values)
      )

      if ("data" in response) {
         enqueueSnackbar(
            t("openbanking.webhooks.messages.new_webhook_created"),
            {
               variant: "success",
            }
         )
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>
            {t("openbanking.webhooks.new_webhook_dialog_title")}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditWebhookForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const NewWebhookDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default NewWebhookDialog
