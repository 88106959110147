import formatRequest from "./formatRequest"

export default function formatRequests(requests) {
   return requests
      .map((row) => {
         return formatRequest(row)
      })
      .filter(function (requestFormatted) {
         return requestFormatted.protocol_id !== null
      })
}
