import { Grid, makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import clsx from "clsx"
import { Field, Formik } from "formik"
import { Select, TextField } from "formik-material-ui"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

function WebhookCreationForm(props, { className, ...rest }) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values,
      { resetForm, setErrors, setSubmitting }
   ) => {
      await props.handlePostWebhook(values)
   }

   return (
      <Formik initialValues={{ event: null, url: "" }} onSubmit={handleSubmit}>
         {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            resetForm,
         }) => (
            <form
               onSubmit={handleSubmit}
               noValidate
               className={clsx(classes.root, className)}
            >
               <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                     <FormControl fullWidth>
                        <InputLabel>{t("Integrazione.Evento")}</InputLabel>
                        <Field
                           component={Select}
                           name="event"
                           error={Boolean(touched.event && errors.event)}
                           helperText={touched.event && errors.event}
                           onBlur={handleBlur}
                        >
                           <MenuItem value={null}>None</MenuItem>
                           <MenuItem value={"outgoing-document"}>
                              [outgoing-document]{" "}
                              {t("Integrazione.OutgoingDocument")}
                           </MenuItem>
                           <MenuItem value={"incoming-document"}>
                              [incoming-document]{" "}
                              {t("Integrazione.IncomingDocument")}
                           </MenuItem>
                        </Field>
                     </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <Field
                        component={TextField}
                        type="url"
                        label={t("Integrazione.Url")}
                        name="url"
                        error={Boolean(touched.url && errors.url)}
                        helperText={touched.url && errors.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                     />
                  </Grid>
               </Grid>

               <div className={classes.buttons}>
                  <Button
                     type="submit"
                     color="primary"
                     variant="contained"
                     disabled={props.isLoading}
                  >
                     {t("Default.Salva")}
                  </Button>
                  <Button
                     onClick={props.handleClose}
                     color="default"
                     variant="contained"
                  >
                     {t("Default.Annulla")}
                  </Button>
               </div>
            </form>
         )}
      </Formik>
   )
}

WebhookCreationForm.propTypes = {
   className: PropTypes.string,
}

export default WebhookCreationForm
