import { CUSTOMER_INVOICE_TYPE, SUPPLIER_INVOICE_TYPE } from "config"
import formatInvoice from "services/client/formatter/formatInvoice"
import { GetInvoicesParams, GetInvoicesResponse } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { UserRole } from "store/auth"
import { TableName, TablesState } from "store/tablesState"
import { InvoiceType } from "./index"

type GetInvoiceQueryParamsType<
   T extends "customer.invoices" | "supplier.invoices"
> = {
   filters: TablesState[T]["filters"]
   itemsPerPage?: number | null
   currentPage?: number | null
   refresh?: number
   type: 0 | 1
   invoiceDataOrder?: "asc" | "desc" | null
   invoiceNumberOrder?: "asc" | "desc" | null
}
export const getInvoiceQueryParams = <
   T extends "customer.invoices" | "supplier.invoices"
>({
   filters,
   itemsPerPage,
   currentPage,
   refresh,
   type,
   invoiceDataOrder,
   invoiceNumberOrder,
}: GetInvoiceQueryParamsType<T>): GetInvoicesParams => {
   if (filters.uuid) {
      return {
         uuid: filters.uuid?.trim(),
      }
   }

   const { sender, recipient, rejectedToFix, ...rest } = filters

   return {
      ...rest,
      createdAt: {
         after: `${rest.createdAt.after} 00:00:00`,
         before: `${rest.createdAt.before} 23:59:59`,
      },
      ...(rest.invoiceDate.after &&
         rest.invoiceDate.before && {
            invoiceDate: {
               after: `${rest.invoiceDate.after} 00:00:00`,
               before: `${rest.invoiceDate.before} 23:59:59`,
            },
         }),
      ...(sender.fiscalId && { sender: sender.fiscalId }),
      ...(recipient.fiscalId && { recipient: recipient.fiscalId }),
      ...(sender.businessName && {
         "sender.business_name": sender.businessName,
      }),
      ...(recipient.businessName && {
         "recipient.business_name": recipient.businessName,
      }),
      ...(rejectedToFix && { rejectedToFix: 1 }),
      ...(invoiceDataOrder !== null && {
         "order[invoiceDate]": invoiceDataOrder,
      }),
      ...(invoiceNumberOrder !== null && {
         "order[invoiceNumber]": invoiceNumberOrder,
      }),
      type,
      ...(itemsPerPage !== null && { itemsPerPage }),
      ...(currentPage !== null && { page: currentPage }),
      ...(refresh !== null && { refresh }),
   }
}

type CustomerSupplierInvoiceTableConfigurationsType = {
   table: "customer.invoices" | "supplier.invoices"
   type: 0 | 1
}
export const configurations = (
   type: InvoiceType
): CustomerSupplierInvoiceTableConfigurationsType => {
   if (type === "customer-invoice") {
      return {
         table: "customer.invoices",
         type: CUSTOMER_INVOICE_TYPE,
      }
   } else {
      return {
         table: "supplier.invoices",
         type: SUPPLIER_INVOICE_TYPE,
      }
   }
}

export const getFormattedInvoices = (data: GetInvoicesResponse | undefined) => {
   if (!data) {
      return undefined
   }
   return "hydra:member" in data
      ? data["hydra:member"]?.map((row: GovITInvoice) => {
           return formatInvoice(row) as GovITInvoice
        })
      : [formatInvoice(data) as GovITInvoice]
}

type InvoiceConfigurations = {
   table: TableName
   role: UserRole
   title: string
}
export const getInvoiceConfigurations = (
   type: InvoiceType
): InvoiceConfigurations => {
   if (type === "customer-invoice") {
      return {
         table: "customer.invoices",
         role: "ROLE_INVOICING_SUPPLIER_ONLY",
         title: "Menu.Fatture Attive",
      }
   } else {
      return {
         table: "supplier.invoices",
         role: "ROLE_INVOICING_CUSTOMER_ONLY",
         title: "Menu.Fatture Passive",
      }
   }
}

export const isSimplifiedInvoice = (invoice: GovITInvoice) => {
   return invoice.transmission_format === "FSM10"
}
