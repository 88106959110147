import { Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useAppSelector } from "store"
import { selectOnboardExpiresAt } from "store/slices/onboard"

const msToMinutesSeconds = (ms: number) => {
   const seconds = Math.floor(ms / 1000)
   const minutes = Math.floor(seconds / 60)
   const formattedSeconds = String(seconds % 60).padStart(2, "0")
   const formattedMinutes = String(minutes).padStart(2, "0")

   return `${formattedMinutes}:${formattedSeconds}`
}

const Countdown = () => {
   const onboardExpiresAt = useAppSelector(selectOnboardExpiresAt)
   const [timeoutMs, setTimeoutMs] = useState<number | null>(null)

   useEffect(() => {
      if (onboardExpiresAt == null) {
         return
      }

      const intervalId = setInterval(() => {
         setTimeoutMs((value) =>
            value != null
               ? value - 1000
               : new Date(onboardExpiresAt).valueOf() - new Date().valueOf()
         )
      }, 1000)

      return () => {
         window.clearInterval(intervalId)
      }
   }, [onboardExpiresAt])

   if (timeoutMs != null && timeoutMs > 0) {
      return (
         <Typography variant="h2">{msToMinutesSeconds(timeoutMs)}</Typography>
      )
   }

   return null
}

export default Countdown
