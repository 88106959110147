import { Button } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   LegalEntityStatus,
   useDisableLegalEntityMutation,
} from "services/gov-pl/legalEntities"

type Props = {
   uuid: string
   status: LegalEntityStatus
}

const DisableLegalEntityCTA = ({ uuid, status }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [disableLegalEntity, { isLoading }] = useDisableLegalEntityMutation()

   const handleDisable = async () => {
      const response = await disableLegalEntity({ uuid })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(t("legal_entities.messages.disable_successful"), {
         variant: "success",
      })
   }

   return (
      <Button
         color="primary"
         variant="contained"
         onClick={handleDisable}
         disabled={isLoading}
      >
         {t(
            status !== LegalEntityStatus.BLOCKED
               ? "legal_entities.disable_legal_entity_cta"
               : "legal_entities.enable_legal_entity_cta"
         )}
      </Button>
   )
}

export default DisableLegalEntityCTA
