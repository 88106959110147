import { GovITInvoice } from "services/gov-it/types"
import { InvoiceDraftsFormMode } from "store/slices/dialogs"
import { TableName } from "store/tablesState"
import { FormInvoiceType } from "./components/configFields"
import GovITInvoiceSimpleForm from "./FormSimple"
import GovITInvoiceSimplifiedForm from "./FormSimplified"

export type CommonInvoiceTypeMode = "create" | "edit" | "fix"
export type ProjectTypeForCommonInvoiceForm =
   | "invoice_fix"
   | "invoice_draft"
   | "invoice_sent"

export type SubFormProps = {
   invoice: GovITInvoice | null
   table: TableName
   project: ProjectTypeForCommonInvoiceForm
   blockSendDraft?: boolean
   modeDraft?: InvoiceDraftsFormMode
} & (
   | { mode?: Omit<CommonInvoiceTypeMode, "fix"> }
   | {
        mode?: Omit<CommonInvoiceTypeMode, "create" | "edit">
        isFixInvoice: boolean
        invoiceToFixUuid: string
        isCreditNoteCreated?: boolean
     }
)

export type CommonInvoiceFormProps = SubFormProps & {
   formType: FormInvoiceType
}

const CommonInvoiceForm = (props: CommonInvoiceFormProps) => {
   const {
      invoice,
      blockSendDraft: blockSend,
      mode,
      formType,
      table,
      project,
      modeDraft,
   } = props
   const blockSendDraft = blockSend ? blockSend : false
   const isFixInvoice = "isFixInvoice" in props ? props.isFixInvoice : false
   const invoiceToFixUuid =
      "invoiceToFixUuid" in props ? props.invoiceToFixUuid : ""
   const isCreditNoteCreated =
      "isCreditNoteCreated" in props ? props.isCreditNoteCreated : false

   if (formType === "simple") {
      return (
         <GovITInvoiceSimpleForm
            modeDraft={modeDraft}
            project={project}
            invoice={invoice}
            isFixInvoice={isFixInvoice}
            invoiceToFixUuid={invoiceToFixUuid}
            isCreditNoteCreated={isCreditNoteCreated}
            mode={mode}
            table={table}
            blockSendDraft={blockSendDraft}
         />
      )
   } else {
      return (
         <GovITInvoiceSimplifiedForm
            modeDraft={modeDraft}
            project={project}
            invoice={invoice}
            isFixInvoice={isFixInvoice}
            invoiceToFixUuid={invoiceToFixUuid}
            isCreditNoteCreated={isCreditNoteCreated}
            mode={mode}
            table={table}
            blockSendDraft={blockSendDraft}
         />
      )
   }
}

export default CommonInvoiceForm
