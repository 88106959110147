import { Paper, Tab, Tabs } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useTranslation } from "react-i18next"
import { GovITInvoice } from "services/gov-it/types"
import { InvoiceDraftsFormMode } from "store/slices/dialogs"
import TabPanelFromEditorInvoiceDraft from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog/CreateInvoiceTabs/TabPanelFromEditorInvoiceDraft"
import TabPanelNewInvoiceDraft from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog/CreateInvoiceTabs/TabPanelNewInvoiceDraft"

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})

const tabNameToIndex = {
   sourceInvoiceDraft: 1,
   formEditorInvoiceDraft: 0,
}
type IndexToTabNameType = (typeof tabNameToIndex)[keyof typeof tabNameToIndex]

type CreateInvoiceTabsProps = {
   handleClose: () => void
   invoice?: GovITInvoice
   mode: InvoiceDraftsFormMode
}
const CreateInvoiceDraftsTabs = ({
   handleClose,
   invoice,
   mode,
}: CreateInvoiceTabsProps) => {
   const classes = useStyles()
   const [value, setValue] = React.useState<IndexToTabNameType>(0)
   const a11yProps = (index: IndexToTabNameType) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })
   const { t } = useTranslation()

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{
               position: "sticky",
               top: "-20px",
               backgroundColor: "white",
               zIndex: 20,
            }}
         >
            <Tab
               label={
                  mode === "new"
                     ? t("Invoices.create_invoice")
                     : mode === "edit"
                     ? t("Invoices.edit_invoice")
                     : t("Invoices.send_invoice")
               }
               {...a11yProps(tabNameToIndex.formEditorInvoiceDraft)}
            />

            <Tab
               label={
                  mode === "new"
                     ? t("Invoices.create_invoice_source")
                     : mode == "edit"
                     ? t("Invoices.edit_invoice_source")
                     : t("Invoices.send_invoice_source")
               }
               {...a11yProps(
                  invoice &&
                     (invoice?.parsedPayload ||
                        invoice?.parsedPayloadSimplified)
                     ? tabNameToIndex.sourceInvoiceDraft
                     : tabNameToIndex.formEditorInvoiceDraft
               )}
            />
         </Tabs>

         <TabPanelFromEditorInvoiceDraft
            mode={mode}
            invoice={invoice}
            value={value}
            index={tabNameToIndex.formEditorInvoiceDraft}
            handleClose={handleClose}
         />

         <TabPanelNewInvoiceDraft
            value={value}
            mode={mode}
            invoice={invoice}
            index={tabNameToIndex.sourceInvoiceDraft}
            handleClose={handleClose}
         />
      </Paper>
   )
}

export default CreateInvoiceDraftsTabs
