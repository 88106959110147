import {
   Box,
   Button,
   ButtonGroup,
   Checkbox,
   CircularProgress,
   createStyles,
   FormControlLabel,
   Grid,
   lighten,
   makeStyles,
   Menu,
   MenuItem,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableProps,
   TableRow,
   Theme,
   Toolbar,
   Tooltip,
   Typography,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined"
import clsx from "clsx"
import {
   ChangeEvent,
   Key,
   MouseEvent,
   ReactElement,
   ReactNode,
   useEffect,
   useState,
} from "react"
import { Columns } from "react-feather"
import { useTranslation } from "react-i18next"
import TablePaginationActions from "./PaginationActions"
// add random rowkey on Table component when rowKey is null and labelTranslationId is null

export type TableColumn<Row extends Record<string, any>> = {
   /** Key used to extract the value from the row */
   rowKey: keyof Row | null
   labelTranslationId: string | null
   format: (value: Row[keyof Row] | null, row: Row) => ReactNode
   /**
    * Whether the column can be selected/deselected by the user (e.g.
    * columns with CTAs should not be allowed to be removed).
    */
   configurable: boolean
}

type Props<Row extends Record<string, any>> = Pick<TableProps, "size"> & {
   columnsDefinition: TableColumn<Row>[]
   rows: Row[] | undefined
   // Key of "rows" to use as row identifier
   rowKeyId: keyof Row
   isFetching: boolean
   isError: boolean
   errorTranslationId?: string
   emptyStateTranslationId?: string
   BottomButtonActions?: ReactElement | null
   buttonActions?: ReactElement | null
} & (
      | { withPagination: false }
      | {
           withPagination: true
           rowsPerPage: number
           totalRows: number
           currentPage: number
           onPageChange: (page: number) => void
        }
   ) &
   (
      | { withConfigurableColumns: false }
      | {
           withConfigurableColumns: true
           selectedColumns: (keyof Row)[]
           onSelectedColumnsChange: (newColumns: (keyof Row)[]) => void
        }
   ) &
   (
      | { withChecked?: false }
      | {
           withChecked?: true
           checkedColumns: string[]
           onCheckedColumnsChange: (newChecked: string[]) => void
           checkedButtonActions?: ReactElement
        }
   ) &
   (
      | { withOrders?: false }
      | {
           withOrders?: true
           ordersConfig: {
              column: keyof Row
              funcOrder: () => void
              labelTranslationId: string
              direction: "asc" | "desc" | null
           }[]
        }
   )

const BaseTable = <Row extends Record<string, any>>(props: Props<Row>) => {
   const {
      size,
      columnsDefinition,
      rows,
      rowKeyId,
      isFetching,
      isError,
      errorTranslationId = "global.messages.generic_error",
      emptyStateTranslationId = "global.messages.no_results",
      BottomButtonActions = null,
      buttonActions = null,
   } = props

   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

   const { t } = useTranslation()
   const classes = useStyles()
   const [checked, setChecked] = useState<string[]>(
      props.withChecked ? props.checkedColumns : []
   )

   const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
      if (props.withChecked) {
         if (event.target.checked) {
            const newCheckeds = rows?.map((n) => n[rowKeyId]) ?? []
            setChecked(newCheckeds)
            props.onCheckedColumnsChange(newCheckeds)
            return
         }
         setChecked([])
         props.onCheckedColumnsChange([])
      }
   }

   const handleSingleSelectClick = (
      event: MouseEvent<unknown>,
      name: string
   ) => {
      if (props.withChecked) {
         const currentIndex = checked.indexOf(name)
         const newChecked = [...checked]

         if (currentIndex === -1) {
            newChecked.push(name)
         } else {
            newChecked.splice(currentIndex, 1)
         }

         setChecked(newChecked)
         props.onCheckedColumnsChange(newChecked)
      }
   }

   const isChecked = (name: string) => checked.indexOf(name) !== -1
   const isCheckedAll = rows?.length === checked.length

   const columns = props.withConfigurableColumns
      ? columnsDefinition.filter(
           ({ configurable, rowKey }) =>
              !configurable || props.selectedColumns.includes(rowKey!)
        )
      : columnsDefinition

   const colspan = columns.length

   const getKey = (rowKey: string, labelTranslationId: string) => {
      if (rowKey == null && labelTranslationId == null) {
         return window.crypto.randomUUID() as Key
      }
      return (rowKey ?? labelTranslationId) as Key
   }

   const numSelected = checked.length
   const rowCount = rows?.length ?? 0

   useEffect(() => {
      if (props.withChecked) {
         setChecked(props.checkedColumns)
      }
   }, [props.withChecked && props.checkedColumns, props.withChecked])

   return (
      <>
         <Box
            style={{
               display: "flex",
               justifyContent: buttonActions ? "space-between" : "flex-end",
               alignItems: "center",
            }}
            p={2}
         >
            {buttonActions && buttonActions}
            {props.withOrders && (
               <Box
                  textAlign="right"
                  style={{
                     marginRight: props.withConfigurableColumns ? "4px" : 0,
                     display: "flex",
                     justifyItems: "center",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     variant="h6"
                     style={{
                        marginRight: "10px",
                        width: "max-content",
                        textTransform: "uppercase",
                     }}
                     color="textSecondary"
                  >
                     {t("global.sort_by")}
                     {"  :"}
                  </Typography>
                  <ButtonGroup>
                     {props.ordersConfig.map(
                        ({
                           column,
                           funcOrder,
                           labelTranslationId,
                           direction,
                        }) => (
                           <Button
                              key={column as string}
                              style={{ width: "max-content" }}
                              color={
                                 direction === null
                                    ? "default"
                                    : direction === "asc"
                                    ? "primary"
                                    : "secondary"
                              }
                              variant={
                                 direction === null ? "outlined" : "contained"
                              }
                              onClick={funcOrder}
                              startIcon={
                                 direction === null ? (
                                    <RemoveOutlinedIcon />
                                 ) : direction === "asc" ? (
                                    <KeyboardArrowUpIcon />
                                 ) : (
                                    <KeyboardArrowDownIcon />
                                 )
                              }
                           >
                              {t(labelTranslationId)}
                           </Button>
                        )
                     )}
                  </ButtonGroup>
               </Box>
            )}
            {props.withConfigurableColumns && (
               <Box textAlign="right">
                  <ButtonGroup>
                     <Tooltip title={t("global.configure_columns_info")}>
                        <Button
                           style={{ width: "max-content" }}
                           color="default"
                           onClick={(event) => {
                              setAnchorEl(event.currentTarget)
                           }}
                           startIcon={<Columns />}
                        >
                           {t("global.configure_columns_cta")}
                        </Button>
                     </Tooltip>
                  </ButtonGroup>

                  <Menu
                     PaperProps={{
                        style: {
                           maxHeight: "400px",
                        },
                     }}
                     anchorEl={anchorEl}
                     keepMounted
                     open={Boolean(anchorEl)}
                     onClose={() => {
                        setAnchorEl(null)
                     }}
                     getContentAnchorEl={null}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                     }}
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                     }}
                  >
                     {columnsDefinition.map(
                        ({ configurable, rowKey, labelTranslationId }) =>
                           labelTranslationId && (
                              <MenuItem
                                 key={getKey(
                                    rowKey as string,
                                    labelTranslationId as string
                                 )}
                                 style={{ paddingTop: 0, paddingBottom: 0 }}
                              >
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          checked={
                                             !configurable ||
                                             props.selectedColumns.includes(
                                                rowKey!
                                             )
                                          }
                                          disabled={!configurable}
                                          onChange={(event) => {
                                             const isAddingColumn =
                                                event.target.checked
                                             const updatedColumns =
                                                isAddingColumn
                                                   ? [
                                                        ...props.selectedColumns,
                                                        rowKey!,
                                                     ]
                                                   : props.selectedColumns.filter(
                                                        (column) =>
                                                           column !== rowKey
                                                     )

                                             props.onSelectedColumnsChange(
                                                updatedColumns
                                             )
                                          }}
                                          name="emailAlerts"
                                          color="primary"
                                       />
                                    }
                                    label={t(labelTranslationId!)}
                                 />
                              </MenuItem>
                           )
                     )}
                  </Menu>
               </Box>
            )}
         </Box>

         {props.withChecked && checked.length > 0 ? (
            <EnhancedTableToolbar
               numSelected={checked.length}
               buttonActions={props.checkedButtonActions}
            />
         ) : (
            <Box
               style={{
                  minHeight: "64px",
               }}
            />
         )}
         <TableContainer className={classes.table}>
            <Table size={size} stickyHeader>
               <TableHead>
                  <TableRow>
                     {props.withChecked && (
                        <TableCell padding="checkbox">
                           <Checkbox
                              indeterminate={
                                 numSelected > 0 && numSelected < rowCount
                              }
                              checked={rowCount > 0 && numSelected === rowCount}
                              onChange={handleSelectAllClick}
                              inputProps={{ "aria-label": "select all" }}
                           />
                        </TableCell>
                     )}
                     {columns.map(({ labelTranslationId, rowKey }) => (
                        <TableCell
                           key={getKey(
                              rowKey as string,
                              labelTranslationId as string
                           )}
                        >
                           {labelTranslationId != null && t(labelTranslationId)}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {/* @TODO: extract component */}
                  {(() => {
                     if (isFetching) {
                        return (
                           <TableRow>
                              <TableCell colSpan={colspan}>
                                 <Box
                                    display="flex"
                                    justifyContent="center"
                                    color="grey.500"
                                 >
                                    <CircularProgress
                                       size={40}
                                       color="inherit"
                                    />
                                 </Box>
                              </TableCell>
                           </TableRow>
                        )
                     }

                     if (isError) {
                        return (
                           <TableRow>
                              <TableCell
                                 colSpan={colspan}
                                 style={{ textAlign: "center" }}
                              >
                                 {t(errorTranslationId)}
                              </TableCell>
                           </TableRow>
                        )
                     }

                     if (rows == null || rows.length === 0) {
                        return (
                           <TableRow>
                              <TableCell
                                 colSpan={colspan}
                                 style={{ textAlign: "center" }}
                              >
                                 {t(emptyStateTranslationId)}
                              </TableCell>
                           </TableRow>
                        )
                     }

                     return (
                        <>
                           {rows.map((row) => {
                              const isItemSelected = isChecked(
                                 row[rowKeyId] as string as string
                              )
                              const labelId = `enhanced-table-checkbox-${
                                 row[rowKeyId] as string
                              }`
                              return (
                                 <TableRow
                                    key={row[rowKeyId]}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected || isCheckedAll}
                                    hover
                                 >
                                    {props.withChecked && (
                                       <TableCell padding="checkbox">
                                          <Checkbox
                                             onClick={(event) =>
                                                handleSingleSelectClick(
                                                   event,
                                                   row[rowKeyId] as string
                                                )
                                             }
                                             checked={
                                                isItemSelected || isCheckedAll
                                             }
                                             inputProps={{
                                                "aria-labelledby": labelId,
                                             }}
                                          />
                                       </TableCell>
                                    )}
                                    {columns.map(
                                       ({
                                          rowKey,
                                          labelTranslationId,
                                          format,
                                       }) => (
                                          <TableCell
                                             key={getKey(
                                                rowKey as string,
                                                labelTranslationId as string
                                             )}
                                          >
                                             {format(
                                                rowKey != null
                                                   ? row[rowKey]
                                                   : null,
                                                row
                                             )}
                                          </TableCell>
                                       )
                                    )}
                                 </TableRow>
                              )
                           })}
                        </>
                     )
                  })()}
               </TableBody>
            </Table>
         </TableContainer>

         {(BottomButtonActions != null || props.withPagination) && (
            <Grid container alignItems="center">
               <Grid item sm={6} xs={12}>
                  <Box m={2}>{BottomButtonActions}</Box>
               </Grid>

               <Grid item sm={6} xs={12}>
                  {props.withPagination && (
                     <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={props.totalRows}
                        page={props.currentPage - 1}
                        onPageChange={(_event, page) => {
                           props.onPageChange(page)
                        }}
                        labelDisplayedRows={({ from, to, count }) =>
                           `${from}-${to} of ${count}, page ${
                              props.currentPage
                           } of ${
                              count !== 0
                                 ? Math.ceil(count / props.rowsPerPage)
                                 : 1
                           }`
                        }
                        ActionsComponent={TablePaginationActions}
                        rowsPerPage={props.rowsPerPage}
                     />
                  )}
               </Grid>
            </Grid>
         )}
      </>
   )
}

export default BaseTable

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: "100%",
      },
      table: {
         overflowX: "auto",
      },
      visuallyHidden: {
         border: 0,
         clip: "rect(0 0 0 0)",
         height: 1,
         margin: -1,
         overflow: "hidden",
         padding: 0,
         position: "absolute",
         top: 20,
         width: 1,
      },
   })
)

const useToolbarStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(1),
      },
      highlight: {
         color: theme.palette.primary.main,
         backgroundColor: lighten(theme.palette.primary.light, 0.9),
      },
      title: {
         flex: "1 1 100%",
      },
   })
)

interface EnhancedTableToolbarProps {
   numSelected: number
   buttonActions?: ReactElement
   deleteButtonActions?: ReactElement
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
   const classes = useToolbarStyles()
   const { numSelected } = props

   return (
      <Toolbar
         className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
         })}
      >
         <Box
            style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "flex-start",
            }}
         >
            {<Box mr={2}>{props.buttonActions}</Box>}

            <Typography
               className={classes.title}
               color="inherit"
               variant="subtitle1"
               style={{
                  minWidth: "max-content",
               }}
            >
               {numSelected} selected
            </Typography>
         </Box>
      </Toolbar>
   )
}
