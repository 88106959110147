import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import LastPageIcon from "@material-ui/icons/LastPage"

export function TablePaginationActions(props) {
   const classes = useStyles()

   const handleFirstPageButtonClick = (event) => {
      props.handleChangePage(event, props.rows.first)
   }

   const handleBackButtonClick = (event) => {
      props.handleChangePage(event, props.rows.previous)
   }

   const handleNextButtonClick = (event) => {
      props.handleChangePage(event, props.rows.next)
   }

   const handleLastPageButtonClick = (event) => {
      props.handleChangePage(event, props.rows.last)
   }

   return (
      <div className={classes.root}>
         <IconButton
            key="pagination-action-first-page"
            onClick={handleFirstPageButtonClick}
            disabled={props.page <= 1}
            aria-label="first page"
         >
            <FirstPageIcon />
         </IconButton>
         <IconButton
            key="pagination-action-prev-page"
            onClick={handleBackButtonClick}
            disabled={props.page <= 1}
            aria-label="previous page"
         >
            <KeyboardArrowLeft />
         </IconButton>
         <IconButton
            key="pagination-action-next-page"
            onClick={handleNextButtonClick}
            disabled={props.page >= props.rows.last}
            aria-label="next page"
         >
            <KeyboardArrowRight />
         </IconButton>
         <IconButton
            key="pagination-action-last-page"
            onClick={handleLastPageButtonClick}
            disabled={props.page >= props.rows.last}
            aria-label="last page"
         >
            <LastPageIcon />
         </IconButton>
      </div>
   )
}

const useStyles = makeStyles((theme) => ({
   root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
   },
}))
