import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { getProfile } from "../../services/client/request/common/getProfile"
import Loader from "../common/Loader"
import ProfileForm from "./ProfileForm"

export default function ProfileDialog(props) {
   const classes = useStyles()
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const [isInit, setIsInit] = React.useState(!props.isOpen)
   const initialState = {
      email: null,
      fullname: null,
      invoicing_vat_number: null,
      invoicing_fiscal_id: null,
      invoicing_address: null,
      invoicing_city: null,
      invoicing_province: null,
      invoicing_cap: null,
      invoicing_name: null,
      statistics_scope: null,
   }
   const [profile, setProfile] = React.useState(initialState)

   async function fetchData() {
      setIsLoading(true)
      setError(false)

      try {
         let response = await getProfile()

         setProfile(response)
         setIsInit(true)
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      if (props.isOpen) {
         fetchData()
      }
   }, [props.isOpen])

   return (
      <Dialog
         fullWidth={true}
         maxWidth="sm"
         open={props.isOpen}
         onClose={props.handleClose}
         aria-labelledby="profile-dialog-title"
         aria-describedby="profile-dialog-description"
         className={classes.container}
      >
         <DialogTitle id="profile-dialog-title">Profile</DialogTitle>

         {isLoading ? (
            <Loader />
         ) : (
            <DialogContent>
               {error ? (
                  <></>
               ) : (
                  isInit && (
                     <ProfileForm
                        handleClose={props.handleClose}
                        isLoading={props.isLoading}
                        handleSave={props.handleSave}
                        profile={profile}
                     />
                  )
               )}
            </DialogContent>
         )}
      </Dialog>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   root: {
      minWidth: 275,
   },
}))
