import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useTranslation } from "react-i18next"

import { Card, CardActions, CardContent } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import MessageIcon from "@material-ui/icons/Message"
import SmsIcon from "@material-ui/icons/Sms"
import SmsFailedIcon from "@material-ui/icons/SmsFailed"

export default function PeppolMessage(props) {
   const { t } = useTranslation()
   const classes = useStyles()
   const peppolMessage = props.peppolMessage
   const [isDialogOpen, setDialogStatus] = React.useState(false)

   const handleIconClick = (peppolMessage) => {
      if (peppolMessage !== null) {
         setDialogStatus(true)
      }
   }

   return (
      <>
         <IconButton onClick={() => handleIconClick(peppolMessage)}>
            {peppolMessage === null || peppolMessage?.success === null ? (
               <SmsIcon color="disabled" />
            ) : peppolMessage?.success === true ? (
               <MessageIcon className={classes.success} />
            ) : (
               <SmsFailedIcon color="error" />
            )}
         </IconButton>
         <Dialog
            fullWidth
            maxWidth="sm"
            open={isDialogOpen}
            onClose={() => setDialogStatus(false)}
            className={classes.container}
         >
            <DialogTitle>Peppol Message</DialogTitle>

            {!peppolMessage ? (
               <></>
            ) : (
               <DialogContent>
                  <DialogContentText>
                     <Card>
                        <CardContent>
                           <List>
                              <ListItem>
                                 <ListItemText
                                    primary={t("Orders.PeppolMessage.uuid")}
                                 />
                                 <ListItemSecondaryAction>
                                    <ListItemText
                                       primary={peppolMessage.uuid}
                                    />
                                 </ListItemSecondaryAction>
                              </ListItem>

                              <ListItem>
                                 <ListItemText
                                    primary={t(
                                       "Orders.PeppolMessage.createdAt"
                                    )}
                                 />
                                 <ListItemSecondaryAction>
                                    <ListItemText
                                       primary={peppolMessage.createdAt || "-"}
                                    />
                                 </ListItemSecondaryAction>
                              </ListItem>

                              <ListItem>
                                 <ListItemText
                                    primary={t(
                                       "Orders.PeppolMessage.requestSentAt"
                                    )}
                                 />
                                 <ListItemSecondaryAction>
                                    <ListItemText
                                       primary={
                                          peppolMessage.requestSentAt || "-"
                                       }
                                    />
                                 </ListItemSecondaryAction>
                              </ListItem>

                              <ListItem>
                                 <ListItemText
                                    primary={t(
                                       "Orders.PeppolMessage.responseReceivedAt"
                                    )}
                                 />
                                 <ListItemSecondaryAction>
                                    <ListItemText
                                       primary={
                                          peppolMessage.responseReceivedAt ||
                                          "-"
                                       }
                                    />
                                 </ListItemSecondaryAction>
                              </ListItem>

                              <ListItem>
                                 <ListItemText
                                    primary={t("Orders.PeppolMessage.status")}
                                 />
                                 <ListItemSecondaryAction>
                                    <ListItemText
                                       primary={
                                          peppolMessage.success === null
                                             ? t(
                                                  "Orders.PeppolMessage.statusCodes.notSent"
                                               )
                                             : peppolMessage.success === true
                                             ? t(
                                                  "Orders.PeppolMessage.statusCodes.successfullSent"
                                               )
                                             : t(
                                                  "Orders.PeppolMessage.statusCodes.errorSent"
                                               )
                                       }
                                    />
                                    {/* {peppolMessage.success === null
                          ? (<SmsIcon color="disabled" />)
                          : peppolMessage.success === true
                            ? (<MessageIcon className={classes.success} />)
                            : (<SmsFailedIcon color="error" />)
                        } */}
                                 </ListItemSecondaryAction>
                              </ListItem>

                              {peppolMessage.errorCode && (
                                 <ListItem>
                                    <ListItemText
                                       primary={t(
                                          "Orders.PeppolMessage.errorCode"
                                       )}
                                    />
                                    <ListItemSecondaryAction>
                                       <ListItemText
                                          primary={
                                             peppolMessage.errorCode || "-"
                                          }
                                       />
                                    </ListItemSecondaryAction>
                                 </ListItem>
                              )}
                           </List>
                        </CardContent>
                        <CardActions>
                           <Button
                              onClick={() => setDialogStatus(false)}
                              color="default"
                              variant="contained"
                           >
                              {t("Default.Chiudi")}
                           </Button>
                        </CardActions>
                     </Card>
                  </DialogContentText>
               </DialogContent>
            )}
         </Dialog>
      </>
   )
}

const useStyles = makeStyles((theme) => ({
   container: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   success: {
      color: theme.palette.success.main,
   },
}))
