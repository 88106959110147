import { Box, Paper, Tab, Tabs } from "@material-ui/core"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import BatchInvoices from "../BatchInvoices"
import SingleInvoice from "../SingleInvoice"

const TAB_ID_PREFIX = "tab-"
const PANEL_ID_PREFIX = "tabpanel-"

const a11yProps = (index: number) => ({
   id: `${TAB_ID_PREFIX}${index}`,
   "aria-controls": `${PANEL_ID_PREFIX}${index}`,
})

type TabPanelProps = {
   children: ReactNode
   value: number
   index: number
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
   <div
      role="tabpanel"
      hidden={value !== index}
      id={`${PANEL_ID_PREFIX}${index}`}
      aria-labelledby={`${TAB_ID_PREFIX}${index}`}
   >
      {value === index && children}
   </div>
)

const tabs = [
   {
      translationId: "invoices_demo.tabs.batch_invoices",
      panel: <BatchInvoices />,
   },
   {
      translationId: "invoices_demo.tabs.single_invoice",
      panel: <SingleInvoice />,
   },
]

const DemoInvoicesTabs = () => {
   const { t } = useTranslation()
   const [value, setValue] = useState(1)

   const handleChange = (_event: unknown, newValue: number) => {
      setValue(newValue)
   }

   return (
      <Paper>
         <Tabs value={value} onChange={handleChange}>
            {tabs.map(({ translationId }, index) => (
               <Tab
                  key={translationId}
                  label={t(translationId)}
                  {...a11yProps(index)}
               />
            ))}
         </Tabs>

         <Box px={4} py={6}>
            {tabs.map(({ translationId, panel }, index) => (
               <TabPanel key={translationId} value={value} index={index}>
                  {panel}
               </TabPanel>
            ))}
         </Box>
      </Paper>
   )
}

export default DemoInvoicesTabs
