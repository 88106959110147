import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import { TableName } from "store/tablesState"
import AlertModal from "views/common/AlertModal"
import Page from "views/common/components/Page"
import InvoiceDialog from "views/common/InvoiceDetails/InvoiceDialog"
import InvoiceFilterForm from "views/common/InvoiceFilterForm"
import CreateInvoiceDraftsDialog from "views/gov-it/InvoiceDrafts/CreateInvoiceDraftsDialog"
import SenderOrDeleterInvoicesDialog from "views/gov-it/InvoiceDrafts/SenderOrDeleterInvoicesDialog"
import DraftsInvoicesTable from "views/gov-it/InvoiceDrafts/Table"

const table: TableName = "invoice.drafts"
const GovITInvoiceDrafts = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()

   const hasRoleInvoicing = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_INVOICING",
      })
   )
   const hasRoleInvoiceDrafts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_INVOICING_DRAFT",
      })
   )
   const hasRoleSupplierOnly = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_INVOICING_SUPPLIER_ONLY",
      })
   )

   const isAllowedToManageDraftInvoice =
      hasRoleInvoicing || hasRoleInvoiceDrafts

   useEffect(() => {
      if (hasRoleSupplierOnly) {
         navigate("/")
      }
   }, [hasRoleSupplierOnly, navigate])

   useEffect(() => {
      if (!isAllowedToManageDraftInvoice) {
         navigate("/customer-invoice")
      }
   }, [isAllowedToManageDraftInvoice, navigate])
   return (
      <>
         <Page
            className={classes.root}
            title={t("gov_it.invoice_drafts.title")}
         >
            <Container maxWidth={false}>
               <InvoiceFilterForm table={table} filterType={table} />
               <DraftsInvoicesTable />
            </Container>

            <InvoiceDialog table={"invoice.drafts"} />
            <CreateInvoiceDraftsDialog />
            <AlertModal />
            <SenderOrDeleterInvoicesDialog />
         </Page>
      </>
   )
}

export default GovITInvoiceDrafts

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))
