import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   FormControl,
   FormHelperText,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"

const table: TableName = "peppol.legal_entities"

type TableFilters = TablesState[typeof table]["filters"]
export type TableFilter = keyof TableFilters

export type FormValues = {
   identifierValue: string
   registeredName: string
   sortBy: string
   sortType: string
}

type FilterField = { name: keyof FormValues; labelTranslationId: string } & (
   | { type: "textfield" }
   | { type: "select"; options: string[] }
)

const fields: FilterField[] = [
   {
      name: "identifierValue",
      labelTranslationId: "peppol.legal_entities.filters.identifier_value",
      type: "textfield",
   },
   {
      name: "registeredName",
      labelTranslationId: "peppol.legal_entities.filters.registered_name",
      type: "textfield",
   },
   {
      name: "sortBy",
      labelTranslationId: "peppol.legal_entities.filters.sort_by",
      type: "select",
      options: [
         "",
         "createdAt",
         "registeredName",
         "identifierScheme",
         "identifierValue",
      ],
   },
   {
      name: "sortType",
      labelTranslationId: "peppol.legal_entities.filters.sort_type",
      type: "select",
      options: ["asc", "desc"],
   },
]

const LegalEntitiesFilters = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const initialValues = filters

   const handleReset = () => {
      dispatch(resetFilters({ table }))
   }

   const handleFiltersChange = (values: FormValues) => {
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]

            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   return (
      <Formik
         initialValues={initialValues}
         onSubmit={handleFiltersChange}
         enableReinitialize
      >
         {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            errors,
         }) => (
            <form onSubmit={handleSubmit}>
               <Accordion elevation={1}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="peppol_legal_entities_content"
                     id="peppol_legal_entities_filters"
                  >
                     <Typography variant="body1">
                        {t("Default.Filtra i risultati")}
                     </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                     <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Grid container spacing={3}>
                           {fields.map(
                              ({ name, labelTranslationId, ...field }) => (
                                 <Grid key={name} item md={6} xs={12}>
                                    {field.type === "textfield" && (
                                       <Field
                                          component={TextField}
                                          type="text"
                                          label={t(labelTranslationId)}
                                          name={name}
                                          helperText={
                                             touched[name] != null &&
                                             errors[name]
                                          }
                                          error={
                                             touched[name] != null &&
                                             errors[name] != null
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          fullWidth
                                       />
                                    )}

                                    {field.type === "select" && (
                                       <FormControl
                                          fullWidth
                                          error={
                                             touched[name] != null &&
                                             errors[name] != null
                                          }
                                          disabled={
                                             name === "sortType" &&
                                             values.sortBy === ""
                                          }
                                       >
                                          <InputLabel>
                                             {t(labelTranslationId)}
                                          </InputLabel>
                                          <Select
                                             value={values[name]}
                                             name={name}
                                             onChange={handleChange}
                                             label="Age"
                                          >
                                             {field.options.map((option) => (
                                                <MenuItem
                                                   key={option}
                                                   value={option}
                                                >
                                                   {option === "" ? (
                                                      <span>&nbsp;</span>
                                                   ) : (
                                                      option
                                                   )}
                                                </MenuItem>
                                             ))}
                                          </Select>
                                          {touched[name] != null &&
                                             errors[name] != null && (
                                                <FormHelperText>
                                                   {errors[name]}
                                                </FormHelperText>
                                             )}
                                       </FormControl>
                                    )}
                                 </Grid>
                              )
                           )}
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" mt={2}>
                           <Box mr={2}>
                              <Button onClick={handleReset} variant="contained">
                                 {t("global.cancel")}
                              </Button>
                           </Box>
                           <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                           >
                              {t("Default.Cerca")}
                           </Button>
                        </Box>
                     </Box>
                  </AccordionDetails>
               </Accordion>
            </form>
         )}
      </Formik>
   )
}

export default LegalEntitiesFilters
