import { Box, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

const NewSubAccount = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const handleNewSubAccount = () => {
      dispatch(
         openDialog({
            id: "common_sub_account.new",
         })
      )
   }
   return (
      <Box
         sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            mb: 4,
         }}
      >
         <Button
            color="primary"
            variant="contained"
            onClick={handleNewSubAccount}
         >
            {t("common_subAccounts.new")}
         </Button>
      </Box>
   )
}

export default NewSubAccount
