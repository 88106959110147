import {
   Box,
   Button,
   Checkbox,
   FormControlLabel,
   FormHelperText,
   Grid,
} from "@material-ui/core"
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { OpenbankingBusinessRegistry } from "services/openbankingApi/businessRegistry"

export const mapOpenbankingBusinessRegistryToFormValues = (
   businessRegistry: OpenbankingBusinessRegistry
) =>
   Object.entries(businessRegistry)
      .filter(([key]) => key !== "enabled")
      .reduce(
         (acc, [key, value]) => ({ ...acc, [key]: value }),
         {} as FormValues
      )

export type FormValues = {
   fiscalId: string
   email: string
   businessName: string
   emailAlerts: boolean
}

const createFields = [
   {
      name: "fiscalId",
      labelTranslationId:
         "openbanking.business_registry.edit_business_registry_form.field_fiscal_id",
   },
   {
      name: "email",
      labelTranslationId:
         "openbanking.business_registry.edit_business_registry_form.field_email",
   },
   {
      name: "businessName",
      labelTranslationId:
         "openbanking.business_registry.edit_business_registry_form.field_business_name",
   },
] as const

const editFields = createFields.filter(({ name }) =>
   ["businessName"].includes(name)
)

type Props = {
   onCancel: VoidFunction
   formikProps: FormikProps<FormValues>
   isCreationForm: boolean
}

const Form = ({ onCancel, formikProps, isCreationForm }: Props) => {
   const { t } = useTranslation()
   const filteredFields = isCreationForm ? createFields : editFields

   const {
      handleSubmit,
      values,
      touched,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={3}>
            {filteredFields.map(({ name, labelTranslationId }) => (
               <Grid key={name} item md={12} xs={12}>
                  <Field
                     component={TextField}
                     type="text"
                     label={t(labelTranslationId)}
                     name={name}
                     helperText={touched[name] != null && errors[name]}
                     error={touched[name] != null && errors[name] != null}
                     onBlur={handleBlur}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
            ))}

            <Grid item md={12} xs={12}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={values.emailAlerts}
                        onChange={handleChange}
                        name="emailAlerts"
                        color="primary"
                     />
                  }
                  label={t(
                     "openbanking.business_registry.edit_business_registry_form.field_email_alerts"
                  )}
               />
               <FormHelperText>
                  {t(
                     "openbanking.business_registry.edit_business_registry_form.field_email_alerts_helper"
                  )}
               </FormHelperText>
            </Grid>
         </Grid>

         <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={8}
            mt={2}
            mb={1}
         >
            <Button color="default" variant="contained" onClick={onCancel}>
               {t(
                  "openbanking.business_registry.edit_business_registry_form.cancel_cta"
               )}
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {t(
                  "openbanking.business_registry.edit_business_registry_form.save_cta"
               )}
            </Button>
         </Box>
      </form>
   )
}

export default Form
