import { Box, Typography } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import {
   OnboardMode1Step,
   selectOnboardMode1CurrentStep,
} from "store/slices/onboard"

type StepProps = {
   completed: boolean
   current: boolean
   label: string
   index: number
}

const Step = ({ completed, current, label, index }: StepProps) => {
   const bgColor = current || completed ? "red.700" : "grey.500"
   const color = current || completed ? "grey.900" : "grey.500"

   return (
      <Box
         position="relative"
         width={24}
         height={24}
         display="flex"
         alignItems="center"
         justifyContent="center"
         marginTop="-11px"
         borderRadius={12}
         bgcolor={bgColor}
         color="text.white"
      >
         {completed && <CheckIcon fontSize="small" color="inherit" />}
         {!completed && <Typography variant="body2">{index}</Typography>}
         <Box
            position="absolute"
            top="-30px"
            left={0}
            width={150}
            marginLeft="-63px"
            color={color}
            textAlign="center"
         >
            <Typography variant="body1">{label}</Typography>
         </Box>
      </Box>
   )
}

const steps = new Map<OnboardMode1Step, string>([
   ["download", "onboard.stepper.step1"],
   ["sign", "onboard.stepper.step2"],
   ["upload", "onboard.stepper.step3"],
   ["end", "onboard.stepper.step4"],
])

const Stepper = () => {
   const { t } = useTranslation()

   const currentStep = useAppSelector(selectOnboardMode1CurrentStep)
   const stepsEntries = Array.from(steps)
   const currentStepIndex = stepsEntries.findIndex(([id]) => id === currentStep)

   if (currentStepIndex === -1) {
      return null
   }

   return (
      <Box
         position="relative"
         height={2}
         display="flex"
         justifyContent="space-between"
         marginTop={3}
         marginBottom={8}
         bgcolor="grey.300"
      >
         <Box
            position="absolute"
            height={2}
            width={`${currentStepIndex * (100 / (stepsEntries.length - 1))}%`}
            bgcolor="red.700"
         />

         {stepsEntries.map(([step, translationId], index) => (
            <Step
               key={translationId}
               completed={index < currentStepIndex}
               current={index === currentStepIndex}
               label={t(translationId)}
               index={index + 1}
            />
         ))}
      </Box>
   )
}

export default Stepper
