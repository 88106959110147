import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { EXPENSES, RECEIPTS } from "../../../endpoints"

export async function getReceipts(token, id) {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/ld+json",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      process.env.REACT_APP_BASE_URL + EXPENSES + "/" + id + RECEIPTS,
      requestInit
   )

   if (response.ok) {
      return await response.json()
   }

   let text = await response.text()
   throw new Error(text)
}
