import { Box, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import {
   GetInvoiceNotificationsResponse,
   useGetInvoiceNotificationsQuery,
} from "services/gov-it"
import { getLastNSNotification } from "../utils"

export type DisplayNotificationErrorType = {
   descrizione: string
   suggerimento?: string
}

export const DisplayNotificationErrors = ({
   errors,
}: {
   errors: DisplayNotificationErrorType[]
}) => {
   const { t } = useTranslation()
   return (
      <Box mb={3}>
         <Alert severity="error">
            <AlertTitle>{t("gov_it.invoice_fix.validation_errors")}</AlertTitle>

            <ul>
               {errors.map((error, index) => {
                  return (
                     <li key={index}>
                        {error.suggerimento && (
                           <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bold" }}
                              gutterBottom
                           >
                              {error.suggerimento}
                           </Typography>
                        )}
                        <Typography
                           variant={error.suggerimento ? "body2" : "subtitle2"}
                           style={{
                              fontWeight: error.suggerimento
                                 ? "normal"
                                 : "bold",
                           }}
                        >
                           {error.descrizione}
                        </Typography>
                     </li>
                  )
               })}
            </ul>
         </Alert>
      </Box>
   )
}

export function getErrorsForNSNotification(
   nSNotification: GetInvoiceNotificationsResponse[number]
) {
   return nSNotification.message?.lista_errori?.reduce<
      DisplayNotificationErrorType[]
   >((acc, { Errore }) => {
      if (Array.isArray(Errore)) {
         return [
            ...acc,
            ...Errore.map(({ Codice, Descrizione, Suggerimento }) => ({
               descrizione: Descrizione,
               suggerimento: Suggerimento,
            })),
         ]
      }
      return [
         ...acc,
         {
            // @ts-ignore
            descrizione: Errore?.Descrizione,
            // @ts-ignore
            suggerimento: Errore?.Suggerimento,
         },
      ]
   }, [])
}

const GovITInvoiceFixErrorMessage = () => {
   const { invoiceUuid } = useParams()
   const { data: notifications } = useGetInvoiceNotificationsQuery({
      uuid: invoiceUuid!,
   })

   if (notifications == null) {
      return null
   }

   const lastNSNotification = getLastNSNotification(notifications)

   const errors = getErrorsForNSNotification(lastNSNotification)

   return <DisplayNotificationErrors errors={errors} />
}

export default GovITInvoiceFixErrorMessage
