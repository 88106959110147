import clsx from "clsx"
import PropTypes from "prop-types"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import {
   Box,
   Card,
   CardContent,
   colors,
   makeStyles,
   Typography,
   useTheme,
} from "@material-ui/core"
import { numberFormatter } from "helper/numberFormater"

const useStyles = makeStyles(() => ({
   text_percentage: {
      fontSize: "14px",
   },
}))

const COLOR_SENT = colors.red[400]
const COLOR_RECEIVED = colors.cyan[400]

const GetDetails = ({ detailOnLeft, details, classes, totalInvoices }) => {
   const { i18n } = useTranslation()
   return (
      <Box
         display="flex"
         justifyContent="center"
         mt={1}
         flexDirection={{
            xs: "column",
            sm: "row",
            xl: detailOnLeft ? "column" : "row",
         }}
      >
         {details.map(({ color, title, value, hidePercentage }) => (
            <Box key={title} p={1} textAlign="center">
               <Typography color="textPrimary" variant="body1">
                  {title}
               </Typography>
               <Typography style={{ color }} variant="h2">
                  {numberFormatter(i18n.language, value)}
               </Typography>
               {!hidePercentage && (
                  <Typography
                     style={{ color }}
                     paragraph
                     className={classes.text_percentage}
                  >
                     {((value / totalInvoices) * 100).toFixed(2)}%
                  </Typography>
               )}
            </Box>
         ))}
      </Box>
   )
}

const InvoicesCount = ({
   className,
   invoicesSent,
   invoicesReceived,
   detailOnLeft,
}) => {
   const classes = useStyles()
   const theme = useTheme()
   const { t } = useTranslation()

   const totalInvoices = invoicesSent + invoicesReceived

   const data = {
      datasets: [
         {
            data: [invoicesSent, invoicesReceived],
            backgroundColor: [COLOR_SENT, COLOR_RECEIVED],
            borderWidth: 1,
            borderColor: theme.palette.text.white,
            hoverBorderColor: theme.palette.text.white,
         },
      ],
      labels: [
         t("statistics.text.sent_invoices"),
         t("statistics.text.received_invoices"),
      ],
   }

   const options = {
      animation: false,
      cutoutPercentage: 80,
      layout: { padding: 0 },
      legend: {
         display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
         backgroundColor: theme.palette.background.default,
         bodyFontColor: theme.palette.text.secondary,
         borderColor: theme.palette.divider,
         borderWidth: 2,
         enabled: true,
         footerFontColor: theme.palette.text.secondary,
         intersect: false,
         mode: "index",
         titleFontColor: theme.palette.text.primary,
      },
   }

   const details = [
      {
         title: t("statistics.text.sent_invoices"),
         value: invoicesSent,
         color: COLOR_SENT,
      },
      {
         title: t("statistics.text.received_invoices"),
         value: invoicesReceived,
         color: COLOR_RECEIVED,
      },
      {
         title: t("statistics.text.total_invoices"),
         value: invoicesReceived + invoicesSent,
         color: colors.primary,
         hidePercentage: true,
      },
   ]

   return (
      <Card className={clsx(classes.root, className)}>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("statistics.title.total_invoices")}
            </Typography>
            <Box
               display="flex"
               justifyContent="space-around"
               mt={1}
               flexDirection={
                  detailOnLeft ? { xs: "column-reverse", xl: "row" } : "column"
               }
            >
               {detailOnLeft && (
                  <GetDetails
                     detailOnLeft={true}
                     details={details}
                     classes={classes}
                     totalInvoices={totalInvoices}
                  />
               )}
               <Box mt={2} height={300} position="relative">
                  <Doughnut data={data} options={options} />
               </Box>
               {!detailOnLeft && (
                  <GetDetails
                     detailOnLeft={false}
                     details={details}
                     classes={classes}
                     totalInvoices={totalInvoices}
                  />
               )}
            </Box>
         </CardContent>
      </Card>
   )
}

InvoicesCount.propTypes = {
   className: PropTypes.string,
   invoicesSent: PropTypes.number,
   invoicesReceived: PropTypes.number,
   detailOnLeft: PropTypes.bool,
}

InvoicesCount.defaultProps = {
   invoicesSent: 0,
   invoicesReceived: 0,
   detailOnLeft: false,
}

export default InvoicesCount
