import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { useEffect } from "react"
import { useGetApiConfigurationsQuery } from "services/gov-it/apiConfigurations"
import { ApiConfiguration } from "services/gov-it/apiConfigurations/types"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectRefetch,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import ActionsButtons from "views/ApiConfiguration/ActionsButtons"
import { RowCTAs } from "views/ApiConfiguration/Table/RowCTAs"
import Label from "views/common/Label"

const table: TableName = "api.configurations"
export type ApiConfigurationTableConfigurableColumns = NonNullable<
   (typeof columnsDefinition)[number]["rowKey"]
>

const ApiConfigurationTable = () => {
   const dispatch = useAppDispatch()
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const { data, isFetching, isError } = useGetApiConfigurationsQuery({
      ...filters,
      ...(filters["business_registry_configurations.fiscal_id"] && {
         "business_registry_configurations.fiscal_id":
            filters["business_registry_configurations.fiscal_id"],
      }),
      ...(filters.targetUrl && { targetUrl: filters.targetUrl }),
      ...(itemsPerPage === null ? {} : { itemsPerPage }),
      ...(currentPage === null ? {} : { page: currentPage }),
      ...(refresh === null ? {} : { refresh }),
   })

   const items = data
      ? "hydra:member" in data
         ? (data["hydra:member"] as ApiConfiguration[])
         : ([data] as ApiConfiguration[])
      : undefined

   const fetchedTotalItems =
      data && "hydra:totalItems" in data ? data["hydra:totalItems"] : null

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            buttonActions={<ActionsButtons />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="Integrazione.no_data"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
         />
      </Paper>
   )
}

const columnsDefinition: TableColumn<ApiConfiguration>[] = [
   {
      rowKey: "uuid",
      labelTranslationId: "Integrazione.uuid",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "event",
      labelTranslationId: "Integrazione.Evento",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "target_url",
      labelTranslationId: "Integrazione.Url",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "business_registry_configurations",
      labelTranslationId: "Integrazione.FiscalId",
      configurable: true,
      format: (values) => {
         return (
            <>
               {(values as unknown as any[])?.map((value) => (
                  <Label color={"primary"}>{value.fiscal_id}</Label>
               ))}
            </>
         )
      },
   },
   {
      rowKey: "authentication_key",
      labelTranslationId: "Integrazione.Authentication key",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "authentication_type",
      labelTranslationId: "Integrazione.Authentication type",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: "authentication_token",
      labelTranslationId: "Integrazione.Authentication token",
      configurable: true,
      format: (value) => value,
   },
   {
      rowKey: null,
      labelTranslationId: null,
      configurable: false,
      format: (value, item) => <RowCTAs item={item} />,
   },
]

export default ApiConfigurationTable
