import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import ReactJson from "react-json-view"
import { GovITInvoice } from "services/gov-it/types"

type Props = {
   value: number
   index: number
   invoice: GovITInvoice
}
export default function TabPanelJson({ value, index, invoice }: Props) {
   const { t } = useTranslation()
   let JSONPayload = ""
   try {
      JSONPayload = JSON.parse(invoice.payload)
   } catch (error) {}

   const formatPayload = (invoice: any) => {
      if (!invoice || !invoice?.payload) {
         return invoice
      }

      let payloadRaw = invoice.payload

      try {
         invoice.payload = JSON.parse(payloadRaw)
      } catch (error) {
         console.error(error)
      }

      return invoice
   }

   if (!JSONPayload) {
      return (
         <Typography variant="body1">{t("global.no_json_payload")}</Typography>
      )
   }

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               <ReactJson
                  src={formatPayload(JSONPayload)}
                  name={false}
                  enableClipboard={function (copy) {
                     navigator.clipboard
                        .writeText(JSON.stringify(copy.src, null, 4))
                        .then()
                  }}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  iconStyle="triangle"
               />
            </Box>
         )}
      </div>
   )
}
