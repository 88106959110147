import { Box, IconButton, TablePagination } from "@material-ui/core"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import LastPageIcon from "@material-ui/icons/LastPage"
import { ComponentProps } from "react"

type Props = Required<
   Pick<
      ComponentProps<typeof TablePagination>,
      "page" | "rowsPerPage" | "count" | "onPageChange"
   >
>

const TablePaginationActions = ({
   page,
   rowsPerPage,
   count,
   onPageChange,
}: Props) => {
   const firstPage = 1
   const lastPage = Math.ceil(count / rowsPerPage) - 1

   return (
      <Box display="flex" ml={2}>
         <IconButton
            key="pagination-action-first-page"
            onClick={(event) => onPageChange(event, 1)}
            disabled={page < firstPage}
            aria-label="first page"
         >
            <FirstPageIcon />
         </IconButton>

         <IconButton
            key="pagination-action-prev-page"
            onClick={(event) => onPageChange(event, page)}
            disabled={page < firstPage}
            aria-label="previous page"
         >
            <KeyboardArrowLeft />
         </IconButton>

         <IconButton
            key="pagination-action-next-page"
            onClick={(event) => onPageChange(event, page + 2)}
            disabled={page >= lastPage}
            aria-label="next page"
         >
            <KeyboardArrowRight />
         </IconButton>

         <IconButton
            key="pagination-action-last-page"
            onClick={(event) => onPageChange(event, lastPage + 1)}
            disabled={page >= lastPage}
            aria-label="last page"
         >
            <LastPageIcon />
         </IconButton>
      </Box>
   )
}

export default TablePaginationActions
