import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useGetPreSaleActionsQuery } from "services/commonApi"
import Item from "./Item"

const Items = () => {
   const { t } = useTranslation()
   const { isFetching, isError, data } = useGetPreSaleActionsQuery()

   const items = data?.["hydra:member"]

   if (isFetching) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <CircularProgress size={40} color="inherit" />
         </Box>
      )
   }

   if (isError) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <Typography variant="body1">
               {t("global.messages.generic_error")}
            </Typography>
         </Box>
      )
   }

   if (items == null || items.length === 0) {
      return (
         <Box display="flex" justifyContent="center" color="grey.500">
            <Typography variant="body1">
               {t("global.messages.no_results")}
            </Typography>
         </Box>
      )
   }

   return (
      <Grid container spacing={3}>
         {items.map((item) => (
            <Grid key={item.uuid} item sm={12} md={6}>
               <Item preSaleAction={item} />
            </Grid>
         ))}
      </Grid>
   )
}

export default Items
