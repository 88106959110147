import { IconButton, Tooltip } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import DeleteIcon from "@material-ui/icons/Delete"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteWebhookMutation } from "services/openbankingApi/webhooks"
import { useAppDispatch } from "store"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"

type Props = {
   webhookUuid: string
}

const DeleteWebhookCTA = ({ webhookUuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [deleteWebhook] = useDeleteWebhookMutation()

   const handleDeleteWebhook = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("openbanking.webhooks.delete_webhook_confirmation"),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await deleteWebhook({ uuid: webhookUuid })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(t("openbanking.webhooks.messages.webhook_deleted"), {
         variant: "success",
      })
   }

   return (
      <Tooltip title={t("openbanking.webhooks.delete_webhook_cta")}>
         <IconButton style={{ color: red[600] }} onClick={handleDeleteWebhook}>
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   )
}

export default DeleteWebhookCTA
