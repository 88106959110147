import { Button, ButtonGroup, CircularProgress } from "@material-ui/core"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import { useSnackbar } from "notistack"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useLazyGetInvoiceTransfersQuery } from "services/gov-it"
import { useAppSelector } from "store"
import { selectTableFilters, TableName } from "store/tablesState"

const table: TableName = `gov_it.invoice_transfers`

const DownloadCsvCTA = () => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const ref = useRef<HTMLAnchorElement | null>(null)

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const [getInvoiceTransfersCsv, { isFetching }] =
      useLazyGetInvoiceTransfersQuery()

   const { existsViolations, ...filtersValues } = filters
   const handleDownloadCsv = async () => {
      const response = await getInvoiceTransfersCsv({
         accept: "text/csv",
         itemsPerPage: 1000,
         "created_at[strictly_after]": `${filters.createdStrictlyAfter} 00:00:00`,
         "created_at[before]": `${filters.createdBefore} 23:59:59`,
         original_file_name: filters.originalFileName,
         ...(filters.existsViolations === null
            ? {}
            : { "exists[violations]": filters.existsViolations }),
      })

      if ("error" in response || ref.current == null) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      ref.current.href = `data:text/csv;charset=utf-8,${response.data}`
      ref.current.click()
   }

   return (
      <>
         <a
            ref={ref}
            href="#"
            download="invoice_transfers.csv"
            style={{ display: "none" }}
         >
            {t("global.download_csv")}
         </a>

         <ButtonGroup>
            <Button
               disabled={isFetching}
               onClick={handleDownloadCsv}
               startIcon={
                  isFetching ? (
                     <CircularProgress size={20} />
                  ) : (
                     <CloudDownloadIcon />
                  )
               }
            >
               {isFetching ? t("global.downloading") : t("global.download_csv")}
            </Button>
         </ButtonGroup>
      </>
   )
}

export default DownloadCsvCTA
