import { Box, Button, CircularProgress, TextField } from "@material-ui/core"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useSendDemoInvoiceMutation } from "services/gov-pl/demoInvoices"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"
import * as Yup from "yup"
import ArchiveSession from "./ArchiveSession"
import SingleInvoiceErrorDialog from "./ErrorDialog"

const initialValues = { content: "" }

type FormValues = typeof initialValues

const validationSchema = Yup.object().shape({
   content: Yup.string().required(),
})

const SingleInvoice = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [sendInvoice, { data, isLoading }] = useSendDemoInvoiceMutation()

   const handleSendInvoice = async (values: FormValues) => {
      const response = await sendInvoice(values.content)

      if ("error" in response && "data" in response.error) {
         const { title, detail } = response.error.data as {
            title: string
            detail: string
         }
         dispatch(
            openDialog({
               id: "pl_send_invoice_demo_error",
               data: { title, detail },
            })
         )
         enqueueSnackbar(t("invoices_demo.error.generic"), { variant: "error" })
         return
      }

      if ("data" in response) {
         enqueueSnackbar(t("invoices_demo.single_invoice.success.send"), {
            variant: "success",
         })
         return
      }
   }

   return (
      <>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSendInvoice}
         >
            {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
               <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="column">
                     <TextField
                        label={t("invoices_demo.single_invoice.fields.content")}
                        error={
                           touched.content != null && errors.content != null
                        }
                        helperText={errors.content}
                        multiline
                        rows={12}
                        name="content"
                        onChange={handleChange}
                        onBlur={handleBlur}
                     />

                     <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                           disabled={isLoading}
                           color="primary"
                           size="large"
                           type="submit"
                           variant="contained"
                        >
                           {isLoading ? (
                              <>
                                 <Box display="flex" mr={1}>
                                    <CircularProgress
                                       size={24}
                                       color="inherit"
                                    />
                                 </Box>
                                 {t(
                                    "invoices_demo.single_invoice.send_cta_loading"
                                 )}
                              </>
                           ) : (
                              t("invoices_demo.single_invoice.send_cta")
                           )}
                        </Button>
                     </Box>
                  </Box>
               </form>
            )}
         </Formik>

         <ArchiveSession invoice={data} />

         <SingleInvoiceErrorDialog />
      </>
   )
}

export default SingleInvoice
