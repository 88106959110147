import { Box } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import React from "react"
import { useTranslation } from "react-i18next"
import { useCreateCustomerInvoiceMutation } from "services/gov-it"
import { useAppDispatch } from "store"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { ViolationType } from "../.."
import CreateInvoiceForm from "../../CreateInvoiceForm"

type TabPanelSendNewInvoiceProps = {
   value: number
   index: number
   handleClose: () => void
}

const table: TableName = "customer.invoices"
const TabPanelSendNewInvoice = ({
   value,
   index,
   handleClose,
}: TabPanelSendNewInvoiceProps) => {
   const { t } = useTranslation()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const genericError = {
      propertyPath: "generic",
      message: "An error occurred",
   } as ViolationType

   const [createCustomerInvoice, { isLoading, error, isError }] =
      useCreateCustomerInvoiceMutation()

   const dispatch = useAppDispatch()

   const handleSave = async (
      value: string,
      format: "json" | "xml",
      type: "simple" | "simplified"
   ) => {
      setMessage(null)

      const response = await createCustomerInvoice({
         //@ts-ignore
         body: value,
         format,
         type,
      })

      if ("error" in response && "data" in response.error) {
         if ("violations" in (response.error.data as any)) {
            setErrors((response.error.data as any).violations)
            return
         }
         setErrors([genericError as ViolationType])
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
         return
      }

      if ("data" in response) {
         const { uuid } = response.data
         setMessage(
            `${t(
               "CustomerInvoices.CreateInvoice.invoice_sent"
            )} - Document UUID ${uuid}`
         )
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
         style={{
            padding: 10,
         }}
      >
         {errors && errors.length > 0 && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="error"
                  onClose={() => {
                     setErrors([])
                  }}
               >
                  <AlertTitle>
                     {t("CustomerInvoices.CreateInvoice.Validation Error")}
                  </AlertTitle>
                  {errors.map(function (error) {
                     return (
                        <p>
                           <strong>{error.propertyPath}</strong> {error.message}
                        </p>
                     )
                  })}
               </Alert>
            </Box>
         )}

         {message && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="success"
                  style={{
                     margin: 2,
                  }}
                  onClose={() => {
                     setMessage(null)
                  }}
               >
                  <AlertTitle>
                     {t("CustomerInvoices.CreateInvoice.Invoice sent")}
                  </AlertTitle>
                  {message}
               </Alert>
            </Box>
         )}

         <CreateInvoiceForm
            isLoading={isLoading}
            handleSave={handleSave}
            handleClose={handleClose}
         />
      </div>
   )
}

export default TabPanelSendNewInvoice
