import {
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   makeStyles,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { DialogId, DialogState } from "store/slices/dialogs"
import LabelBoolean from "views/common/LabelBoolean"
import OnboardStatus from "../OnboardStatus"
import Status from "../Status"

const DIALOG_ID: DialogId = "gov_pl_legal_entity_details"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({
   handleClose,
   uuid,
   nip,
   name,
   email,
   createdAt,
   updatedAt,
   addressLine1,
   addressLine2,
   postcode,
   city,
   province,
   countryIso2,
   incomingInvoiceEnabled,
   authFlowStatus,
   authFlowIsValid,
   status,
}: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <DialogTitle>{t("legal_entities.details_dialog.title")}</DialogTitle>

         <DialogContent className={classes.content}>
            <List>
               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.uuid")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={uuid} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.nip")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={nip} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.name")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.email")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={email} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.created_at")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={createdAt} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.updated_at")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={updatedAt} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.address_line1")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={addressLine1} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.address_line2")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={addressLine2} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.postcode")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={postcode} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.city")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={city} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.province")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={province} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.country")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={countryIso2} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "legal_entities.details_dialog.incoming_invoice_enabled"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <LabelBoolean value={incomingInvoiceEnabled} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.onboard_status")}
                  />
                  <ListItemSecondaryAction>
                     <OnboardStatus
                        authFlowStatus={authFlowStatus}
                        authFlowIsValid={authFlowIsValid}
                        legalEntityStatus={status}
                     />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("legal_entities.details_dialog.status")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={incomingInvoiceEnabled} />
                     <Status legalEntityStatus={status} />
                  </ListItemSecondaryAction>
               </ListItem>
            </List>
         </DialogContent>
      </>
   )
}

const LegalEntityDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default LegalEntityDetailsDialog
