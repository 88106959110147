import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import ConnectSuccessDialog from "./ConnectSuccessDialog"
import EditBusinessRegistryDialog from "./EditBusinessRegistryDialog"
import HideInactiveAccountsToggle from "./HideInactiveAccountsToggle"
import NewBusinessRegistryCTA from "./NewBusinessRegistryCTA"
import NewBusinessRegistryDialog from "./NewBusinessRegistryDialog"
import SubscriptionDetailsDialog from "./SubscriptionDetailsDialog"
import BusinessRegistryTable from "./Table"
import AccountDetailsDialog from "./Table/AccountDetailsDialog"
import ReceivePaymentDialog from "./Table/ReceivePaymentDialog"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const BusinessRegistry = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <Page
            className={classes.root}
            title={t("openbanking.business_registry.title")}
         >
            <Container maxWidth={false}>
               <Box mb={3} display="flex" justifyContent="space-between">
                  <NewBusinessRegistryCTA />
                  <HideInactiveAccountsToggle />
               </Box>

               <BusinessRegistryTable />
            </Container>
         </Page>

         <SubscriptionDetailsDialog />
         <NewBusinessRegistryDialog />
         <EditBusinessRegistryDialog />
         <ConnectSuccessDialog />
         <AccountDetailsDialog />
         <ReceivePaymentDialog />
      </>
   )
}

export default BusinessRegistry
