export default function serializeQuery(params, prefix) {
   let query = Object.keys(params).map((key) => {
      let value = params[key]

      if (value === null || value === undefined || value === "") {
         return null
      }

      if (typeof value === "boolean") {
         value = value ? 1 : 0
      }

      if (params.constructor === Array) key = `${prefix}[]`
      else if (params.constructor === Object)
         key = prefix ? `${prefix}[${key}]` : key

      if (typeof value === "object") return serializeQuery(value, key)

      return `${key}=${value}`
   })

   query = query.filter((x) => x !== null && x !== undefined && x !== "")

   return [].concat.apply([], query).join("&")
}
