export function getLastRequest(requests) {
   let lastRequest = requests[0]

   requests.forEach(function (request) {
      if (
         new Date(lastRequest.requested_at).getTime() <
         new Date(request.requested_at).getTime()
      ) {
         lastRequest = request
      }
   })

   return lastRequest
}
