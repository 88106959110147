import { Box, CircularProgress, TableCell, TableRow } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useGetDemoInvoicesStatusQuery } from "services/gov-pl/demoInvoices"
import { useAppSelector } from "store"
import { selectInvoicesDemoNip1 } from "store/slices/invoicesDemo"
import Label from "views/common/Label"

const InvoicesDemoTableEntries = () => {
   const { t } = useTranslation()
   const nip1 = useAppSelector(selectInvoicesDemoNip1)

   const { data, error, isLoading } = useGetDemoInvoicesStatusQuery(
      { nip1: nip1! },
      {
         skip: nip1 == null,
         pollingInterval: 10000,
      }
   )

   if (isLoading) {
      return (
         <TableRow>
            <TableCell colSpan={6}>
               <Box display="flex" justifyContent="center" color="grey.500">
                  <CircularProgress size={48} color="inherit" />
               </Box>
            </TableCell>
         </TableRow>
      )
   }

   if (error != null) {
      return (
         <TableRow>
            <TableCell colSpan={6}>
               {t("invoices_demo.error.generic")}
            </TableCell>
         </TableRow>
      )
   }

   if (data == null || data.length === 0) {
      return (
         <TableRow>
            <TableCell colSpan={6}>
               {t("invoices_demo.batch_invoices.no_entries")}
            </TableCell>
         </TableRow>
      )
   }

   return (
      <>
         {data.map(
            (
               {
                  uuid,
                  invoiceCreatorNip,
                  invoiceRecipientNip,
                  invoiceNumber,
                  direction,
                  status,
               },
               index
            ) => (
               <TableRow key={uuid} hover>
                  <TableCell>{index + 1}</TableCell>

                  <TableCell>{invoiceCreatorNip}</TableCell>

                  <TableCell>{invoiceRecipientNip}</TableCell>

                  <TableCell>{invoiceNumber}</TableCell>

                  <TableCell>
                     <Label
                        color={direction === "outgoing" ? "primary" : "success"}
                     >
                        {direction}
                     </Label>
                  </TableCell>

                  <TableCell>
                     <Label color="primary">{status}</Label>
                  </TableCell>
               </TableRow>
            )
         )}
      </>
   )
}

export default InvoicesDemoTableEntries
