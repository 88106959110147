import {
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Typography,
} from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { formatPrice } from "helper/numberFormater"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import { SmartReceipt } from "services/gov-it/smartReceipt/type"
import { Marking } from "views/common/Marking"

const arrayOfSameElement = [
   "voided_receipt",
   "returned_receipt",
   "voiding_receipt",
   "return_receipts",
] as const

type GenerateSectionType = {
   keyValue: (typeof arrayOfSameElement)[number]
   divider?: boolean
   item: SmartReceipt
}

const GenerateSection = ({ keyValue, divider, item }: GenerateSectionType) => {
   const { t } = useTranslation()

   const getItem = (keyValue?: (typeof arrayOfSameElement)[number]) => {
      if (keyValue && keyValue !== "return_receipts")
         return {
            uuid: item[keyValue].uuid,
            type: item[keyValue].type,
            status: item[keyValue].status,
            fiscal_id: item[keyValue].fiscal_id,
            created_at: item[keyValue].created_at,
            total_amount: item[keyValue].total_amount,
            transaction_id: item[keyValue].transaction_id,
            document_number: item[keyValue].document_number,
            document_date: item[keyValue].document_date,
         } as GetItemType["item"]

      return {
         uuid: item.uuid,
         type: item.type,
         status: item.status,
         fiscal_id: item.fiscal_id,
         created_at: item.created_at,
         total_amount: item.total_amount,
         transaction_id: item.transaction_id,
         document_number: item.document_number,
         document_date: item.document_date,
      } as GetItemType["item"]
   }

   if (
      arrayOfSameElement.includes(keyValue) &&
      keyValue in item &&
      item[keyValue as keyof typeof item] !== null
   ) {
      return (
         <ListItem
            divider={divider ?? true}
            style={{
               width: "100%",
               padding: "0",
               margin: "0",
            }}
         >
            <Accordion
               style={{
                  width: "100%",
               }}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel1a-content${keyValue}`}
                  id={`panel1a-header${keyValue}`}
               >
                  <Typography
                     style={{
                        fontWeight: 400,
                     }}
                  >
                     {t("smartReceipt." + keyValue)}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  {keyValue === "return_receipts" ? (
                     item[keyValue].map((_, index: number) => (
                        <List
                           key={index}
                           style={{
                              width: "100%",
                              padding: "0",
                              margin: "0",
                           }}
                        >
                           <GetItem item={getItem()} />
                        </List>
                     ))
                  ) : (
                     <List
                        style={{
                           width: "100%",
                           padding: "0",
                           margin: "0",
                        }}
                     >
                        <GetItem item={getItem(keyValue)} />
                     </List>
                  )}
               </AccordionDetails>
            </Accordion>
         </ListItem>
      )
   }
   return null
}

type GetItemType = {
   item: {
      uuid: string
      type: string
      status: string
      fiscal_id: string
      created_at: string
      total_amount: number
      transaction_id: string
      document_number: string
      document_date: string
   } | null
}
const GetItem = ({ item }: GetItemType) => {
   const { t, i18n } = useTranslation()
   if (!item) return null
   return (
      <>
         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.id")} />
            <ListItemSecondaryAction>
               <ListItemText primary={item?.uuid ? item.uuid : null} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.type")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={item?.type ? <Marking marking={item.type} /> : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.status")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     item?.status ? <Marking marking={item.status} /> : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.fiscalId")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={item?.fiscal_id ? item.fiscal_id : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.Creata il")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     item?.created_at
                        ? moment(item.created_at as string).format(
                             "DD MMM YYYY HH:mm:ss"
                          )
                        : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.total_amount")} />
            <ListItemSecondaryAction>
               <ListItemText
                  style={{
                     color: item?.total_amount ? "green" : "red",
                     fontWeight: "bold",
                  }}
                  primary={
                     item?.total_amount
                        ? formatPrice(
                             i18n.language,
                             item.total_amount,
                             undefined,
                             true
                          )
                        : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.transaction_id")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={item?.transaction_id ? item.transaction_id : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.document_number")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={item?.document_number ? item.document_number : null}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("smartReceipt.document_date")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={
                     item?.document_date
                        ? moment(item.document_date as string).format(
                             "DD MMM YYYY HH:mm:ss"
                          )
                        : null
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>
      </>
   )
}

type TabPanelInfoProps = {
   item: SmartReceipt
   value?: number
   index?: number
}
const TabPanelInfo = ({ item, value, index }: TabPanelInfoProps) => {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         <List
            style={{
               width: "100%",
               padding: "0",
               margin: "0",
            }}
         >
            <GetItem
               item={
                  {
                     uuid: item.uuid,
                     type: item.type,
                     status: item.status,
                     fiscal_id: item.fiscal_id,
                     created_at: item.created_at,
                     total_amount: item.total_amount,
                     transaction_id: item.transaction_id,
                     document_number: item.document_number,
                     document_date: item.document_date,
                  } as GetItemType["item"]
               }
            />

            {item.voided_receipt && (
               <GenerateSection keyValue={"voided_receipt"} item={item} />
            )}

            {item.returned_receipt && (
               <GenerateSection keyValue={"returned_receipt"} item={item} />
            )}

            {item.voiding_receipt && (
               <GenerateSection keyValue={"voiding_receipt"} item={item} />
            )}

            {item.return_receipts && item.return_receipts.length > 0 && (
               <GenerateSection
                  keyValue={"return_receipts"}
                  item={item}
                  divider={false}
               />
            )}
         </List>
      </div>
   )
}

export default TabPanelInfo
