import { Box } from "@material-ui/core"
import { GovITInvoice } from "services/gov-it/types"
import CommonInvoiceForm from "views/common/InvoiceForm/CommonInvoiceForm"

type CreditNoteFormsProps = {
   handleClose: () => void
   invoice: GovITInvoice
}

const CreditNoteForms = ({ handleClose, invoice }: CreditNoteFormsProps) => {
   const simplified = invoice?.transmission_format === "FSM10"
   const table = "customer.invoices"
   return (
      <Box p={3}>
         {simplified ? (
            <CommonInvoiceForm
               project="invoice_sent"
               formType={"simplified"}
               table={table}
               mode="edit"
               invoice={invoice}
               isCreditNoteCreated={true}
            />
         ) : (
            <CommonInvoiceForm
               project="invoice_sent"
               formType={"simple"}
               table={table}
               mode="edit"
               invoice={invoice}
               isCreditNoteCreated={true}
            />
         )}
      </Box>
   )
}

export default CreditNoteForms
