import {
   Box,
   Button,
   DialogActions,
   DialogContent,
   Typography,
} from "@material-ui/core"
import BaseDialog from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { useGetInvoiceQuery } from "services/gov-it"
import Loader from "views/common/Loader"
import CreditNoteForms from "./CreditNoteForms"

type ContentProps = {
   handleClose: () => void
   invoiceUuid: string
}
const Content = ({ handleClose, invoiceUuid }: ContentProps) => {
   const { data, isLoading } = useGetInvoiceQuery({ uuid: invoiceUuid! })
   const { t } = useTranslation()

   if (isLoading) {
      return <Loader />
   }
   return (
      <>
         <DialogContent>
            {data ? (
               <CreditNoteForms handleClose={handleClose} invoice={data} />
            ) : (
               <Box
                  style={{
                     display: "flex",
                     justifyItems: "center",
                     alignItems: "center",
                  }}
               >
                  <Typography component={"h4"}>
                     {t("invoices.not_found")}
                  </Typography>
               </Box>
            )}
         </DialogContent>

         {!data && (
            <DialogActions>
               <Button
                  onClick={handleClose}
                  color="default"
                  variant="contained"
               >
                  Close
               </Button>
            </DialogActions>
         )}
      </>
   )
}

const CreateCreditNotesDialog = () => {
   return (
      <BaseDialog id="invoiceSentCreditNote" maxWidth="xl" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default CreateCreditNotesDialog
