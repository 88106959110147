type QueryParams = Partial<{
   [key: string]: number | string | string[] | null | undefined | QueryParams
}>

export const buildSearchParams = (
   params: QueryParams | null | undefined | void
) => {
   if (params == null) {
      return ""
   }

   const searchParams = Object.entries(params).reduce((acc, [key, value]) => {
      const separator = acc !== "" ? "&" : ""

      if (
         (typeof value === "string" && value !== "") ||
         typeof value === "number"
      ) {
         return `${acc}${separator}${key}=${encodeURIComponent(value)}`
      }

      // Format array values as "key[]=value1&key[]=value2"
      if (Array.isArray(value) && value.length > 0) {
         return `${acc}${separator}${value
            .map((element) => `${key}[]=${encodeURIComponent(element)}`)
            .join("&")}`
      }

      // Format object values as "key[subkey]=value"
      if (typeof value === "object" && value !== null) {
         return `${acc}${separator}${Object.entries(value)
            .filter(([subkey, subvalue]) => subvalue !== null)
            .map(([subkey, subvalue]) => `${key}[${subkey}]=${subvalue}`)
            .join("&")}`
      }

      return acc
   }, "")

   return searchParams !== "" ? `?${searchParams}` : ""
}
