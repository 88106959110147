import {
   Box,
   Card,
   CardContent,
   colors,
   LinearProgress,
   makeStyles,
   Typography,
} from "@material-ui/core"
import { formatPrice } from "helper/numberFormater"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { formatDate } from "services/dates"
//import { formatPrice } from "services/prices"

const useStyles = makeStyles((theme) => ({
   remainAmount: {
      marginRight: theme.spacing(1),
      color: colors.blueGrey[900],
   },
}))

const PreSales = ({
   totalCredit,
   availableCredit,
   creditPurchasedDate,
   invoicesReceived,
   invoicesSent,
}) => {
   const classes = useStyles()
   const { t, i18n } = useTranslation()

   const percentage =
      (parseFloat(availableCredit) / parseFloat(totalCredit)) * 100

   return (
      <Card>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("Dashboard.title.credit")}
            </Typography>
            <Box display="flex" alignItems="center" mt={3}>
               <Typography variant="h2" className={classes.remainAmount}>
                  {formatPrice(i18n.language, availableCredit)}
               </Typography>
               <Typography color="textSecondary" variant="h5">
                  {t("Dashboard.text.of")}{" "}
                  {formatPrice(i18n.language, totalCredit)}
               </Typography>
            </Box>
            <Box mt={1} mb={3}>
               <LinearProgress
                  value={percentage}
                  variant="determinate"
                  style={{ height: 10 }}
               />
            </Box>
            <Box>
               <Typography color="textSecondary" variant="caption">
                  {t("Dashboard.text.bought")} {formatDate(creditPurchasedDate)}{" "}
                  {t("statistics.text.sent_invoices")} {invoicesSent}{" "}
                  {t("statistics.text.received_invoices")} {invoicesReceived}
               </Typography>
            </Box>
         </CardContent>
      </Card>
   )
}

PreSales.propTypes = {
   totalCredit: PropTypes.string.isRequired,
   availableCredit: PropTypes.string.isRequired,
}

export default PreSales
