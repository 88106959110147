import {
   GetSmartReceiptParams,
   GetSmartReceiptResponse,
} from "services/gov-it/smartReceipt"
import {
   TableSmartReceiptKeyType,
   TypeSmartReceipt,
   TYPE_RETURN,
   TYPE_SALE,
   TYPE_VOID,
} from "services/gov-it/smartReceipt/type"
import { TablesState } from "store/tablesState"

type GetSmartReceiptParamsProps<T extends TableSmartReceiptKeyType> = {
   filters: TablesState[T]["filters"]
   itemsPerPage?: number | null
   currentPage?: number | null
   refresh?: number
   type: TypeSmartReceipt
}

export const getSmartReceiptQueryParams = <T extends TableSmartReceiptKeyType>({
   filters,
   itemsPerPage,
   currentPage,
   refresh,
   type,
}: GetSmartReceiptParamsProps<T>): GetSmartReceiptParams => {
   if (filters.id !== null && filters.id !== "") {
      return {
         id: filters.id,
      }
   }

   const { createdAt, documentDate, documentNumber, ...filterRest } = filters

   return {
      ...filterRest,
      ...(documentNumber !== null && documentNumber !== ""
         ? { document_number: documentNumber.trim() }
         : {}),
      ...(filterRest.businessRegistryConfigurationsFiscalId &&
      filterRest.businessRegistryConfigurationsFiscalId.length > 0
         ? {
              "business_registry_configuration.fiscal_id":
                 filterRest.businessRegistryConfigurationsFiscalId,
           }
         : {}),
      ...(createdAt &&
      createdAt.after &&
      createdAt.before !== null &&
      createdAt.before !== ""
         ? {
              "created_at[after]": `${createdAt.after} 00:00:00`,
           }
         : {}),
      ...(createdAt &&
      createdAt.before &&
      createdAt.after !== null &&
      createdAt.after !== ""
         ? {
              "created_at[before]": `${createdAt.before} 23:59:59`,
           }
         : {}),
      ...(documentDate &&
      documentDate.after &&
      documentDate.before !== null &&
      documentDate.before !== ""
         ? {
              "document_date[after]": `${documentDate.after} 00:00:00`,
           }
         : {}),
      ...(documentDate &&
      documentDate.before &&
      documentDate.after !== null &&
      documentDate.after !== ""
         ? {
              "document_date[before]": `${documentDate.before} 23:59:59`,
           }
         : {}),
      ...(itemsPerPage === null ? {} : { itemsPerPage }),
      ...(currentPage === null ? {} : { page: currentPage }),
      ...(refresh === null ? {} : { refresh }),
      type,
   }
}

export const getFormattedSmartReceipt = (
   data: GetSmartReceiptResponse | undefined
) => {
   if (!data) {
      return undefined
   }
   return "hydra:member" in data ? data["hydra:member"] : [data]
}

export type ConfigType = {
   title: string
   table: TableSmartReceiptKeyType
}

export const getConfig = (type: TypeSmartReceipt): ConfigType => {
   switch (type) {
      case TYPE_SALE:
         return {
            title: "Menu.smart_receipt.sale",
            table: "smart_receipt.sale",
         }
      case TYPE_RETURN:
         return {
            title: "Menu.smart_receipt.return",
            table: "smart_receipt.return",
         }
      case TYPE_VOID:
      default:
         return {
            title: "Menu.smart_receipt.void",
            table: "smart_receipt.void",
         }
   }
}
