import { getImpersonatedUserHeaders } from "helper/impersonateUser"
import { EXPENSES, RECEIPT } from "../../../endpoints"

export async function getReceiptPdf(token, expenseId, protocolId) {
   const requestInit = {
      method: "GET",
      headers: {
         Accept: "application/pdf",
         Authorization: "Bearer " + token,
         ...getImpersonatedUserHeaders(),
      },
   }

   const response = await fetch(
      process.env.REACT_APP_BASE_URL +
         EXPENSES +
         "/" +
         expenseId +
         RECEIPT +
         "/" +
         protocolId,
      requestInit
   )

   if (response.ok) {
      return await response.blob()
   }

   let text = await response.text()
   throw new Error(text)
}
