import { useSnackbar } from "notistack"
import { ReactNode, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import {
   useGetInvoiceNotificationsQuery,
   useGetInvoiceQuery,
} from "services/gov-it"
import Loader from "views/common/Loader"
import { getLastNSNotification } from "../utils"

export const fixableErrorCodes = ["002", "003", "004"]

export const isFixableErrorCode = (code: string) => {
   return fixableErrorCodes.some((prefix) => code?.startsWith(prefix))
}

type Props = {
   children: ReactNode
}

const ErrorCodeGuard = ({ children }: Props) => {
   const { invoiceUuid } = useParams()
   const navigate = useNavigate()
   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation()

   const { data: invoice, isLoading: isLoadingInvoice } = useGetInvoiceQuery({
      uuid: invoiceUuid!,
   })
   const {
      data: notifications,
      isLoading: isLoadingNotifications,
      error,
   } = useGetInvoiceNotificationsQuery({ uuid: invoiceUuid! })

   const isLoading = isLoadingInvoice || isLoadingNotifications
   const isRejectedInvoice = invoice == null || invoice.marking === "rejected"

   const isFixableError =
      notifications == null ||
      getLastNSNotification(notifications)?.message?.lista_errori?.some(
         ({ Errore }) => {
            if (Array.isArray(Errore)) {
               return Errore.some(({ Codice }) => isFixableErrorCode(Codice))
            }
            //@ts-ignore
            return isFixableErrorCode(Errore?.Codice)
         }
      )

   const shouldRedirect = !isRejectedInvoice || !isFixableError

   useEffect(() => {
      if (shouldRedirect) {
         navigate("/customer-invoice")
      }
   }, [shouldRedirect, navigate])

   // redirect if error or status 404
   useEffect(() => {
      if (error) {
         enqueueSnackbar(t("CustomerInvoices.error_fetch_notification"), {
            variant: "error",
         })
         navigate("/customer-invoice")
      }
   }, [error, navigate])

   return <>{isLoading ? <Loader /> : children}</>
}

export default ErrorCodeGuard
