import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import {
   GovPLLegalEntity,
   LegalEntityStatus,
   useGetLegalEntitiesQuery,
} from "services/gov-pl/legalEntities"
import LabelBoolean from "views/common/LabelBoolean"
import DisableLegalEntityCTA from "../DisableLegalEntityCTA"
import InitOnboardCTA from "../InitOnboardCTA"
import OnboardStatus from "../OnboardStatus"
import RowCTAs from "../RowCTAs"
import Status from "../Status"
// import { useAppDispatch, useAppSelector } from "store"
// import { selectTableColumns, updateData } from "store/tablesState"

const columnsDefinition: TableColumn<GovPLLegalEntity>[] = [
   {
      rowKey: "nip",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.nip",
      format: (value) => value,
   },
   {
      rowKey: "email",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.email",
      format: (value) => value,
   },
   {
      rowKey: "name",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.name",
      format: (value) => value,
   },
   {
      rowKey: "createdAt",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.created_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "yyyy-MM-dd")
            : null,
   },
   {
      rowKey: "incomingInvoiceEnabled",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.supplier_invoice",
      format: (value) => <LabelBoolean value={value as boolean} />,
   },
   {
      rowKey: "status",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.status",
      format: (value) => (
         <Status legalEntityStatus={value as LegalEntityStatus} />
      ),
   },
   {
      rowKey: null,
      configurable: true,
      labelTranslationId: "legal_entities.table_head.onboard_status",
      format: (_value, row) => (
         <OnboardStatus
            authFlowStatus={row.authFlowStatus}
            authFlowIsValid={row.authFlowIsValid}
            legalEntityStatus={row.status}
         />
      ),
   },
   {
      rowKey: "lastSyncAt",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.invoice_last_sync_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm")
            : null,
   },
   {
      rowKey: "lastReceiveAt",
      configurable: true,
      labelTranslationId: "legal_entities.table_head.invoice_last_receive_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm")
            : null,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => (
         <DisableLegalEntityCTA uuid={row.uuid} status={row.status} />
      ),
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => (
         <InitOnboardCTA
            uuid={row.uuid}
            status={row.status}
            authFlowStatus={row.authFlowStatus}
         />
      ),
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => <RowCTAs legalEntity={row} />,
   },
]

export type GovPLLegalEntitiesTableConfigurableColumns = NonNullable<
   (typeof columnsDefinition)[number]["rowKey"]
>

const LegalEntitiesTable = () => {
   // const dispatch = useAppDispatch()

   // const selectedColumns = useAppSelector((state) =>
   //    selectTableColumns(state, { table: "pl.legal_entities" })
   // )

   const { data, isError, isFetching } = useGetLegalEntitiesQuery(undefined)

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={data}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="pl.legal_entities.messages.no_legal_entities"
            withPagination={false}
            withConfigurableColumns={false}
            // selectedColumns={selectedColumns}
            // onSelectedColumnsChange={(updatedColumns) => {
            //    dispatch(
            //       updateData({
            //          table: "pl.legal_entities",
            //          param: "columns",
            //          value: updatedColumns,
            //       })
            //    )
            // }}
         />
      </Paper>
   )
}

export default LegalEntitiesTable
