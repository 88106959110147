import { useEffect, useRef, useState } from "react"

const useCarousel = (wrapperEl, nextEl, prevEl) => {
   const [isPrevEnabled, setPrevEnable] = useState(false)
   const [isNextEnabled, setNextEnable] = useState(false)
   const [index, setIndex] = useState(0)

   const itemEl = useRef()
   const numberOfItems = useRef()
   const numberOfVisibleItems = useRef()

   useEffect(() => {
      itemEl.current = wrapperEl.current.querySelector(":first-child")
      numberOfItems.current = wrapperEl.current.childNodes.length
      numberOfVisibleItems.current = Math.round(
         wrapperEl.current.offsetWidth / itemEl.current.offsetWidth
      )
   }, [wrapperEl])

   useEffect(() => {
      const itemWidth = itemEl.current.offsetWidth
      wrapperEl.current.style.transition = "transform 500ms ease-in-out"
      wrapperEl.current.style.transform = `translateX(-${itemWidth * index}px)`

      setPrevEnable(index > 0)
      setNextEnable(
         index + numberOfVisibleItems.current < numberOfItems.current
      )
   }, [index, wrapperEl])

   const onNext = () => isNextEnabled && setIndex(index + 1)
   const onPrev = () => isPrevEnabled && setIndex(index - 1)

   return [onPrev, onNext, isPrevEnabled, isNextEnabled]
}

export default useCarousel
