import { Paper } from "@material-ui/core"
import moment from "moment/moment"
import { useEffect, useState } from "react"
import { Notification, useGetInvoicesQuery } from "services/gov-it"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectRefetch,
   selectTableCheckedColumns,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import { InvoiceType } from ".."
import Table, { TableColumn } from "../../../../components/Table"
import {
   GovITInvoice,
   InvoicePayload,
   InvoiceSimplifiedPayload,
} from "../../../../services/gov-it/types"
import LabelYesNo from "../../../common/LabelYesNo"
import { Marking } from "../../../common/Marking"
import { Notifications } from "../../../common/Notification/Notifications"
import ActionsCheckedFooterTableButtons from "../../components/ActionsCheckedFooterTableButtons"
import ActionsButtons from "../ActionsButtons"
import {
   configurations,
   getFormattedInvoices,
   getInvoiceQueryParams,
   isSimplifiedInvoice,
} from "../helpers"
import ActionsTableCTAs from "./ActionsTableCTAs"
import RowCTA from "./RowCTA"

type CustomerSupplierInvoiceTableProps = {
   type: InvoiceType
}

export type CustomerSupplierInvoiceTableConfigurableColumns = NonNullable<
   ReturnType<typeof columnsDefinition>[number]["rowKey"]
>

const CustomerSupplierInvoiceTable = ({
   type,
}: CustomerSupplierInvoiceTableProps) => {
   const { table, type: invoiceType } = configurations(type)
   const dispatch = useAppDispatch()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const [invoiceDataOrder, setInvoiceDataOrder] = useState<
      "asc" | "desc" | null
   >(null)
   const [invoiceNumberOrder, setInvoiceNumberOrder] = useState<
      "asc" | "desc" | null
   >(null)

   const funcOrder = (currentOrder: "asc" | "desc" | null) => {
      switch (currentOrder) {
         case "asc":
            return "desc"
         case "desc":
            return null
         default:
            return "asc"
      }
   }

   const { data, isFetching, isError } = useGetInvoicesQuery({
      ...getInvoiceQueryParams({
         filters,
         itemsPerPage,
         currentPage,
         refresh,
         type: invoiceType,
         invoiceDataOrder,
         invoiceNumberOrder,
      }),
   })

   const items = getFormattedInvoices(data)

   const fetchedTotalItems =
      data && "hydra:totalItems" in data ? data["hydra:totalItems"] : null

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition(type)}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            BottomButtonActions={
               <ActionsTableCTAs
                  uuid={filters.uuid}
                  checkedColumns={checkedColumns}
                  table={table}
               />
            }
            buttonActions={<ActionsButtons type={type} />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="CustomerInvoices.no_data"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            checkedColumns={checkedColumns}
            withChecked={true}
            onCheckedColumnsChange={(checked) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "checkedColumns",
                     value: checked,
                  })
               )
            }}
            checkedButtonActions={
               <ActionsCheckedFooterTableButtons table={table} />
            }
            withOrders={true}
            ordersConfig={[
               {
                  column: "invoiceDate",
                  labelTranslationId: "CustomerInvoices.InvoiceData",
                  funcOrder: () => {
                     setInvoiceDataOrder((currentOrder) => {
                        return funcOrder(currentOrder)
                     })
                  },
                  direction: invoiceDataOrder,
               },
               {
                  column: "invoiceNumber",
                  labelTranslationId: "CustomerInvoices.InvoiceNumber",
                  funcOrder: () => {
                     setInvoiceNumberOrder((currentOrder) => {
                        return funcOrder(currentOrder)
                     })
                  },
                  direction: invoiceNumberOrder,
               },
            ]}
         />
      </Paper>
   )
}

export default CustomerSupplierInvoiceTable

const columnsDefinition = (type: InvoiceType): TableColumn<GovITInvoice>[] => {
   const isCustomerInvoice = type === "customer-invoice"
   return [
      ...((isCustomerInvoice
         ? [
              {
                 rowKey: null,
                 labelTranslationId: null,
                 configurable: false,
                 format: (value, item) =>
                    item.notifications.length > 0 ? (
                       <Notifications
                          notifications={item.notifications as Notification[]}
                          invoice={item}
                       />
                    ) : null,
              },
           ]
         : []) as TableColumn<GovITInvoice>[]),
      {
         rowKey: "created_at",
         labelTranslationId: "CustomerInvoices.Creata il",
         format: (value) =>
            moment(value as string).format("DD MMM YYYY HH:mm:ss"),
         configurable: true,
      },
      {
         rowKey: "senderFormatted",
         labelTranslationId: "CustomerInvoices.Mittente",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "recipientFormatted",
         labelTranslationId: "CustomerInvoices.Destinatario",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "senderVatNumberFormatted",
         labelTranslationId: "CustomerInvoices.sender_vat_number",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "recipientVatNumberFormatted",
         labelTranslationId: "CustomerInvoices.recipient_vat_number",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "invoiceDate",
         labelTranslationId: "CustomerInvoices.InvoiceData",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "invoiceNumber",
         labelTranslationId: "CustomerInvoices.InvoiceNumber",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "invoiceTotal",
         labelTranslationId: "CustomerInvoices.invoice_total",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "marking",
         labelTranslationId: "CustomerInvoices.Marking",
         format: (value) => {
            return <Marking marking={value} />
         },
         configurable: true,
      },
      {
         rowKey: "document_type",
         labelTranslationId: "CustomerInvoices.document_type",
         format: (value) => value,
         configurable: true,
      },
      {
         rowKey: "downloaded",
         labelTranslationId: "CustomerInvoices.Downloaded",
         format: (value) => <LabelYesNo value={value} />,
         configurable: true,
      },
      {
         rowKey: null,
         labelTranslationId: "CustomerInvoices.bollo_virtuale",
         format: (_, item) => {
            const isSimplified = isSimplifiedInvoice(item)
            const payload = JSON.parse(item.payload)
            let value: boolean
            if (isSimplified) {
               value =
                  (payload as InvoiceSimplifiedPayload)
                     .fattura_elettronica_body[0].dati_generali
                     .dati_generali_documento.bollo_virtuale === "SI"
            } else {
               value =
                  (payload as InvoicePayload).fattura_elettronica_body[0]
                     .dati_generali.dati_generali_documento.dati_bollo
                     ?.bollo_virtuale === "SI"
            }
            return <LabelYesNo value={value} />
         },
         configurable: true,
      },
      {
         rowKey: null,
         labelTranslationId: null,
         format: (value, item) => <RowCTA item={item} type={type} />,
         configurable: false,
      },
   ]
}
