import {
   Accordion,
   AccordionDetails,
   Box,
   Button,
   Grid,
   makeStyles,
} from "@material-ui/core"
import AccordionActions from "@material-ui/core/AccordionActions"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Field, Formik } from "formik"
import { Select } from "formik-material-ui"
import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import {
   PreservationDocumentStatus,
   PreservationDocumentType,
   PRESERVATION_DOCUMENT_STATUS,
   PRESERVATION_DOCUMENT_TYPES,
} from "services/preservation"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableFilters,
   TableName,
   TablesState,
   updateFilter,
} from "store/tablesState"
import DatePicker from "views/common/DatePicker"
import LabelYesNo from "views/common/LabelYesNo"
import { Status } from "views/Preservation/Documents/components/Status"

const table: TableName = "preservation.documents"

type TableFilters = TablesState[typeof table]["filters"]
export type TableFilter = keyof TableFilters

type FormValues = {
   documentType: PreservationDocumentType | null
   status: PreservationDocumentStatus | null
   createdAfter: string | null
   createdStrictlyAfter: string | null
   createdBefore: string | null
   createdStrictlyBefore: string | null
}

const useStyles = makeStyles((theme) => ({
   root: {},
   button: {
      marginBottom: 12,
      marginRight: 12,
   },
}))
const PreservationDocumentsFilters = () => {
   const classes = useStyles()
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )
   const initialValues = filters

   const handleReset = () => {
      dispatch(resetFilters({ table }))
   }
   const filtersIsChanged = (values: FormValues) => {
      return Object.entries(values).some(([key, value]) => {
         const filter = key as TableFilter
         return value !== filters[filter]
      })
   }

   const handleFilter = (values: FormValues) => {
      batch(() => {
         Object.entries(values).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]

            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   return (
      <Box mb={4}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleFilter}
            enableReinitialize
         >
            {({ handleBlur, handleSubmit, handleChange, values }) => (
               <form onSubmit={handleSubmit}>
                  <Accordion elevation={1} defaultExpanded={true}>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                     >
                        <Typography>
                           {t("Default.Filtra i risultati")}{" "}
                        </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Grid container spacing={3}>
                           <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                 <InputLabel htmlFor="documentType">
                                    {t(
                                       "Preservation.documents.filters.document_type"
                                    )}
                                 </InputLabel>
                                 <Field
                                    id="documentType"
                                    component={Select}
                                    name="documentType"
                                    onBlur={handleBlur}
                                    onChange={(e: ChangeEvent<any>) => {
                                       if (e.target.value === undefined) {
                                          e.target.value = null
                                       }
                                       handleChange(e)
                                    }}
                                    fullWidth
                                 >
                                    <MenuItem value={undefined}>
                                       <LabelYesNo value={null} />
                                    </MenuItem>
                                    {PRESERVATION_DOCUMENT_TYPES.map(
                                       ({ label, value }) => (
                                          <MenuItem key={value} value={value}>
                                             {label}
                                          </MenuItem>
                                       )
                                    )}
                                 </Field>
                              </FormControl>
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                 <InputLabel htmlFor="status">
                                    {t("Preservation.documents.filters.status")}
                                 </InputLabel>
                                 <Field
                                    disabled={values.documentType === null}
                                    id="status"
                                    component={Select}
                                    name="status"
                                    onBlur={handleBlur}
                                    onChange={(e: ChangeEvent<any>) => {
                                       if (e.target.value === undefined) {
                                          e.target.value = null
                                       }
                                       handleChange(e)
                                    }}
                                    fullWidth
                                 >
                                    <MenuItem value={undefined}>
                                       <LabelYesNo value={null} />
                                    </MenuItem>
                                    {PRESERVATION_DOCUMENT_STATUS.map(
                                       ({ value }) => (
                                          <MenuItem key={value} value={value}>
                                             <Status status={value} />
                                          </MenuItem>
                                       )
                                    )}
                                 </Field>
                              </FormControl>
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <DatePicker
                                 disabled={values.documentType === null}
                                 id="createdAfter"
                                 name="createdAfter"
                                 label={t(
                                    "Preservation.documents.filters.created_at[after]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                                 helperText=""
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>
                     <AccordionDetails>
                        <Grid container spacing={3}>
                           <Grid item md={4} xs={12}>
                              <DatePicker
                                 disabled={values.documentType === null}
                                 id="createdBefore"
                                 name="createdBefore"
                                 label={t(
                                    "Preservation.documents.filters.created_at[before]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                                 helperText=""
                              />
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <DatePicker
                                 disabled={values.documentType === null}
                                 id="createdStrictlyAfter"
                                 name="createdStrictlyAfter"
                                 label={t(
                                    "Preservation.documents.filters.created_at[strictly_after]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                                 helperText=""
                              />
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <DatePicker
                                 disabled={values.documentType === null}
                                 id="createdStrictlyBefore"
                                 name="createdStrictlyBefore"
                                 label={t(
                                    "Preservation.documents.filters.created_at[strictly_before]"
                                 )}
                                 helperText=""
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>
                     <AccordionActions>
                        <Button
                           className={classes.button}
                           onClick={handleReset}
                           variant="contained"
                        >
                           {t("Default.Cancella")}
                        </Button>
                        <Button
                           disabled={
                              values.documentType === null ||
                              !filtersIsChanged(values)
                           }
                           className={classes.button}
                           type="submit"
                           color="primary"
                           variant="contained"
                        >
                           {t("Default.Cerca")}
                        </Button>
                     </AccordionActions>
                  </Accordion>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default PreservationDocumentsFilters
