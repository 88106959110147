import { zodResolver } from "@hookform/resolvers/zod"
import { Icon } from "@iconify/react"
import {
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   TextField,
   Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   useHandleInteractiveSetCredentialsBusinessRegistryConfigurationMutation,
   useSetCredentialsBusinessRegistryConfigurationMutation,
} from "services/gov-it/businessRegistryConfigurations"
import { DialogId } from "store/slices/dialogs"
import { z } from "zod"

const schema = z.object({
   codice_fiscale: z.string().min(1, "codice fiscale is required"),
   password: z.string().min(1, "password is required"),
   pin: z.string().min(1, "pin is required"),
})

type FormValues = z.infer<typeof schema>

const DIALOG_ID: DialogId = "govItSetCredentialsBusinessRegistryConfiguration"

type Props = {
   handleClose: () => void
   id: string
}
function Content({ handleClose, id }: Props) {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = useState(false)

   const [setCredentials] =
      useSetCredentialsBusinessRegistryConfigurationMutation()

   const [handleInteractiveSetCredentialsBusinessRegistryConfiguration] =
      useHandleInteractiveSetCredentialsBusinessRegistryConfigurationMutation()

   const handleInteractiveSetCredentials = async () => {
      setIsLoading(true)
      const response =
         await handleInteractiveSetCredentialsBusinessRegistryConfiguration({
            id,
         })
      setIsLoading(false)

      if ("error" in response) {
         enqueueSnackbar(
            t(
               "gov_it.business_registry_configurations.changeCredentials.error"
            ),
            { variant: "error" }
         )
         return
      }

      if ("data" in response && "url" in response?.data) {
         window.open(response.data.url, "_blank")
      }
   }

   const {
      handleSubmit,
      register,
      setError,
      formState: { errors, isSubmitting },
   } = useForm<FormValues>({
      defaultValues: {
         codice_fiscale: "",
         password: "",
         pin: "",
      },
      resolver: zodResolver(schema),
   })

   const handleSetCredentials = handleSubmit(async (values: FormValues) => {
      const response = await setCredentials({
         id,
         ...values,
      })

      if ("error" in response && "data" in response.error) {
         setError("root", {
            type: "manual",
            message: t("global.invalid_credentials"),
         })
         return
      }
      handleClose()
      enqueueSnackbar(t("global.save_success"), { variant: "success" })
   })
   return (
      <>
         <DialogTitle>
            <Typography variant="h5">
               {t(
                  "gov_it.business_registry_configurations.setCredentials.title"
               )}
            </Typography>
         </DialogTitle>

         <DialogContent
            style={{
               minHeight: 250,
               scrollbarWidth: "thin",
               scrollbarColor: "transparent",
            }}
         >
            <Grid container spacing={3}>
               <Grid item xs={12} container spacing={3}>
                  {!!errors?.root?.message && (
                     <Grid item xs={12}>
                        <Alert severity="error">{errors.root.message}</Alert>
                     </Grid>
                  )}
                  <Grid item xs={12}>
                     <TextField
                        {...register("codice_fiscale")}
                        disabled={isSubmitting}
                        type="text"
                        label={t(
                           "gov_it.business_registry_configurations.setCredentials.fields.codice_fiscale"
                        )}
                        name="codice_fiscale"
                        error={!!errors?.codice_fiscale?.message}
                        helperText={errors?.codice_fiscale?.message}
                        fullWidth
                     />
                  </Grid>

                  <Grid item xs={12}>
                     <TextField
                        {...register("password")}
                        disabled={isSubmitting}
                        type="password"
                        label={t(
                           "gov_it.business_registry_configurations.setCredentials.fields.password"
                        )}
                        name="password"
                        error={!!errors?.password?.message}
                        helperText={errors?.password?.message}
                        fullWidth
                     />
                  </Grid>

                  <Grid item xs={12}>
                     <TextField
                        {...register("pin")}
                        disabled={isSubmitting}
                        type="password"
                        label={t(
                           "gov_it.business_registry_configurations.setCredentials.fields.pin"
                        )}
                        name="pin"
                        error={!!errors?.pin?.message}
                        helperText={errors?.pin?.message}
                        fullWidth
                     />
                  </Grid>
               </Grid>
               <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12}>
                     <Typography
                        variant="body2"
                        component={"p"}
                        color="textSecondary"
                        style={{ marginTop: 16 }}
                     >
                        {t(
                           "gov_it.business_registry_configurations.changeCredentials.description"
                        )}
                     </Typography>
                  </Grid>

                  <Grid item xs={12}>
                     <Button
                        variant="outlined"
                        onClick={async () => {
                           await handleInteractiveSetCredentials()
                        }}
                        disabled={isLoading}
                     >
                        <Icon
                           icon="ph:password-duotone"
                           width={24}
                           height={24}
                        />
                        <Typography
                           variant="body2"
                           color="textSecondary"
                           style={{ marginLeft: 8 }}
                        >
                           {t(
                              "gov_it.business_registry_configurations.changeCredentials.title"
                           )}
                        </Typography>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} variant="contained" color="default">
               {t("global.cancel")}
            </Button>
            <Button
               variant="contained"
               onClick={handleSetCredentials}
               color="primary"
               autoFocus
            >
               {t("global.save")}
            </Button>
         </DialogActions>
      </>
   )
}

const SetCredentialsDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="sm" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default SetCredentialsDialog
