import {
   Box,
   Button,
   ButtonGroup,
   Container,
   makeStyles,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import formatWebhooks from "../../../services/client/formatter/formatWebhooks"
import {
   deleteWebhook,
   getWebhooks,
   postWebhook,
} from "../../../services/client/request/peppol/webhooks"
import Page from "../../common/components/Page"
import CustomTable from "../../common/CustomTable"
import { DeleteButton } from "../../common/DeleteItem/DeleteButton"
import Loader from "../../common/Loader"
import WebhookCreationDialog from "./WebhookCreationDialog"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const ApiConfiguration = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const pagination = 30
   const token = useAppSelector(selectAuthToken)
   const [page, setPage] = React.useState(1)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const [isDialogOpen, setIsDialogOpen] = React.useState(false)
   const [rows, setRows] = React.useState({
      count: 0,
      first: null,
      last: null,
      next: null,
      previous: null,
      items: [],
   })

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleOpenDialog = (id) => {
      setIsDialogOpen(true)
   }

   const handleCloseDialog = () => {
      setIsDialogOpen(false)
   }

   const handleDeleteApiConfiguration = async (id) => {
      setIsLoading(true)
      setError(false)

      try {
         await deleteWebhook(token, id)
         fetchData()
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   const handlePostWebhook = async (values) => {
      setIsLoading(true)
      setError(false)

      try {
         await postWebhook(token, values)
         handleCloseDialog()
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      await fetchData()
      setIsLoading(false)
   }

   async function fetchData() {
      setIsLoading(true)
      setError(false)

      try {
         const results = await getWebhooks(token)
         setRows(formatWebhooks(results))
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   useEffect(() => {
      fetchData()
   }, [page])

   return (
      <Page className={classes.root} title={t("Menu.Configurazioni")}>
         {isLoading ? (
            <Loader />
         ) : (
            <Container maxWidth={false}>
               <Box display="flex" justifyContent="flex-end">
                  <Button
                     color="primary"
                     variant="contained"
                     onClick={() => {
                        handleOpenDialog()
                     }}
                  >
                     {t("Integrazione.NewWebhook")}
                  </Button>
               </Box>

               {error ? (
                  <></>
               ) : (
                  <Box mt={3}>
                     <CustomTable
                        columns={columns}
                        rows={rows}
                        page={page}
                        pagination={pagination}
                        handleChangePage={handleChangePage}
                        handleOpenDialog={handleOpenDialog}
                        handleDeleteApiConfiguration={
                           handleDeleteApiConfiguration
                        }
                     />
                  </Box>
               )}

               {isDialogOpen && (
                  <WebhookCreationDialog
                     isOpen={isDialogOpen}
                     isLoading={isLoading}
                     handleClose={handleCloseDialog}
                     handlePostWebhook={handlePostWebhook}
                  />
               )}
            </Container>
         )}
      </Page>
   )
}

const columns = [
   {
      id: "uuid",
      label: "Integrazione.uuid",
      disablePadding: false,
      minWidth: 310,
   },
   { id: "event", label: "Integrazione.Evento", disablePadding: true },
   { id: "url", label: "Integrazione.Url", disablePadding: true },
   { id: "createdAt", label: "Integrazione.CreatedAt", disablePadding: true },
   {
      empty: true,
      align: "right",
      format: (value, props, item, t) => {
         return (
            <ButtonGroup>
               <DeleteButton
                  title={t("Integrazione.Cancella")}
                  handleDelete={() =>
                     props.handleDeleteApiConfiguration(item.uuid)
                  }
               />
            </ButtonGroup>
         )
      },
   },
]

export default ApiConfiguration
