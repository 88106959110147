import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import { useEffect } from "react"
import {
   OpenbankingPayment,
   useGetPaymentsQuery,
} from "services/openbankingApi/payments"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   resetPagination,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableData,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateTotalItems,
} from "store/tablesState"
import Label from "views/common/Label"

const table: TableName = "openbanking.payments"

const columns: TableColumn<OpenbankingPayment>[] = [
   {
      rowKey: "createdAt",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.created_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },
   {
      rowKey: "direction",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.direction",
      format: (value) => <Label color="primary">{value}</Label>,
   },
   {
      rowKey: "system",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.system",
      format: (value) => value,
   },
   {
      rowKey: "amount",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.amount",
      format: (value) => value,
   },
   {
      rowKey: "currencyCode",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.currency",
      format: (value) => value,
   },
   {
      rowKey: "status",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.status",
      format: (value) => <Label color="primary">{value}</Label>,
   },
   {
      rowKey: "description",
      configurable: true,
      labelTranslationId: "openbanking.payments.table_head.description",
      format: (value) => value,
   },
]

const PaymentsTable = () => {
   const dispatch = useAppDispatch()

   const { fiscalId } = useAppSelector((state) =>
      selectTableData(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const { data, isError, isFetching } = useGetPaymentsQuery({
      itemsPerPage,
      page: currentPage,
      fiscalId,
      system: filters.system,
      "account.uuid": filters.accountUuid,
      "madeOn[after]": filters.madeAfter,
      "madeOn[before]": filters.madeBefore,
   })

   const items = data?.["hydra:member"]
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (isError) {
         dispatch(resetPagination({ table }))
      }
   }, [dispatch, isError])

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columns}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="openbanking.payments.messages.no_payments"
            withPagination
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default PaymentsTable
