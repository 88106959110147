type KeyName = "token" | "active_context" | "current_nav_item"

export const getItem = (key: KeyName) => localStorage.getItem(key)

export const setItem = (key: KeyName, value: string) => {
   try {
      localStorage.setItem(key, value)
   } catch (error) {}
}

export const removeItem = (key: KeyName) => localStorage.removeItem(key)
