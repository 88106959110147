import {
   Box,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   makeStyles,
   Paper,
   Radio,
   RadioGroup,
} from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import CommonInvoiceForm, {
   CommonInvoiceFormProps,
   ProjectTypeForCommonInvoiceForm,
} from "views/common/InvoiceForm/CommonInvoiceForm"
import { FormInvoiceType } from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

type SwitchCommonInvoiceFormProps = CommonInvoiceFormProps & {
   showRadioToChooseFormType: boolean
   showBlockButtonCheckBoxForDraft?: boolean
   project: ProjectTypeForCommonInvoiceForm
}
const SwitchCommonInvoiceForm = (props: SwitchCommonInvoiceFormProps) => {
   const { invoice, mode, formType, table, project, modeDraft } = props
   const isFixInvoice = "isFixInvoice" in props ? props.isFixInvoice : false
   const invoiceToFixUuid =
      "invoiceToFixUuid" in props ? props.invoiceToFixUuid : ""

   const classes = useStyles()
   const { t } = useTranslation()
   const [type, setType] = React.useState<FormInvoiceType>(formType)
   const [blockSend, setBlockSend] = React.useState(
      modeDraft && invoice
         ? modeDraft === "new"
            ? false
            : invoice?.marking == "draft-blocked"
         : false
   )

   const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
      setType(event.target.value as FormInvoiceType)
   }

   const handleChangeBlockInvoiceSent = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setBlockSend(event.target.checked)
   }

   return (
      <Grid container spacing={3}>
         {props.showRadioToChooseFormType && (
            <Grid item sm={4} xs={12} key={"create-invoice-type"}>
               <Paper>
                  <Box p={2}>
                     <FormControl
                        component="fieldset"
                        className={classes.formControl}
                     >
                        <FormLabel
                           component="legend"
                           className={classes.formLabel}
                        >
                           {t("CustomerInvoices.CreateInvoice.Invoice type")}
                        </FormLabel>
                        <RadioGroup
                           row
                           aria-label="invoice type"
                           name="type"
                           value={type}
                           onChange={handleChangeType}
                        >
                           <FormControlLabel
                              value="simple"
                              control={<Radio size="small" color="primary" />}
                              label={t(
                                 "CustomerInvoices.CreateInvoice.Fattura elettronica italiana"
                              )}
                           />
                           <FormControlLabel
                              value="simplified"
                              control={<Radio size="small" color="primary" />}
                              label={t(
                                 "CustomerInvoices.CreateInvoice.Fattura elettronica italiana semplificata"
                              )}
                           />
                        </RadioGroup>
                     </FormControl>
                  </Box>
               </Paper>
            </Grid>
         )}
         {props.showBlockButtonCheckBoxForDraft && (
            <Grid item xs={12} sm={3} key={"create-invoice-draft-checkbox"}>
               <Paper
                  style={{
                     height: "100%",
                  }}
               >
                  <Box>
                     <FormControl
                        component="fieldset"
                        className={classes.formControl}
                     >
                        <FormLabel
                           component="legend"
                           className={classes.formLabel}
                        >
                           {t(
                              "CustomerInvoices.CreateInvoice.block_invoice_sent"
                           )}
                           <Checkbox
                              checked={blockSend}
                              onChange={handleChangeBlockInvoiceSent}
                              name="blockInvoiceSent"
                              color="primary"
                           />
                        </FormLabel>
                     </FormControl>
                  </Box>
               </Paper>
            </Grid>
         )}
         <Grid item xs={12} key={"create-invoice-textarea"}>
            <CommonInvoiceForm
               modeDraft={modeDraft}
               project={project}
               table={table}
               formType={type}
               mode={mode}
               invoice={invoice}
               isFixInvoice={isFixInvoice}
               invoiceToFixUuid={invoiceToFixUuid}
               blockSendDraft={blockSend}
            />
         </Grid>
      </Grid>
   )
}

export default SwitchCommonInvoiceForm
