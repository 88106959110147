import { Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import GovPLWebhooksTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const GovPLWebhooks = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Page className={classes.root} title={t("pl.webhooks.title")}>
         <Container maxWidth={false}>
            <GovPLWebhooksTable />
         </Container>
      </Page>
   )
}

export default GovPLWebhooks
