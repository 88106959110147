import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { openbankingApi, OpenbankingPaginationParams } from ".."

export type OpenbankingPayment = {
   uuid: string
   direction: "inbound" | "outbound"
   status: string
   system: "sepa" | "sepa_instant" | "swift"
   amount: string
   currencyCode: string
   description: string
   endToEndId: string
   createdAt: string
   debtorProviderName: string | null
   account: {
      uuid: string
      name: string
      nature: string
      providerName: string
   }
   extra: {
      paymentAttributes: {
         arrayCopy: unknown
         flags: number
         iterator: unknown
         iteratorClass: string
      }
      stages: {
         name: string
         createdAt: string
      }[]
   }
   transaction: {
      transactionId: string
      madeOn: string
      amount: string
      currencyCode: string
      description: string
      createdAt: string
   }
}

export type OpenbankingCategory = {
   category: string
}

type GetPaymentsResponse = HydraResponse<OpenbankingPayment>

type GetPaymentsParams = OpenbankingPaginationParams & {
   system?: string[]
   "account.uuid"?: string[]
   "madeOn[after]"?: string
   "madeOn[before]"?: string
}

type ReceivePaymentBody = {
   amount: string
   currencyCode: string
   description: string
   accountUuid: string
   email?: string
}

type ReceivePaymentResponse = {
   uuid: string
   connectUrl: string
}

const openbankingTransactionsApi = openbankingApi.injectEndpoints({
   endpoints: (builder) => ({
      getPayments: builder.query<
         GetPaymentsResponse,
         GetPaymentsParams & { fiscalId: string }
      >({
         query: ({ fiscalId, ...params }) => ({
            url: `/business-registry/${fiscalId}/payments${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
            headers: {
               Accept: "application/ld+json",
            },
         }),
         providesTags: ["PAYMENTS"],
      }),

      receiveSepaPayment: builder.mutation<
         ReceivePaymentResponse,
         ReceivePaymentBody
      >({
         query: ({ ...body }) => ({
            url: "/payments/receive/sepa",
            method: "POST",
            body,
         }),
      }),

      receiveSepaInstantPayment: builder.mutation<
         ReceivePaymentResponse,
         ReceivePaymentBody
      >({
         query: ({ ...body }) => ({
            url: "/payments/receive/sepa-instant",
            method: "POST",
            body,
         }),
      }),
   }),
})

export const {
   useGetPaymentsQuery,
   useReceiveSepaPaymentMutation,
   useReceiveSepaInstantPaymentMutation,
} = openbankingTransactionsApi
