import { Checkbox } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React from "react"

type OptionType = { name: string; label?: string | null }

function debounce<T extends (...args: any[]) => any>(
   func: T,
   wait: number,
   immediate: boolean = false
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
   let timeout: ReturnType<typeof setTimeout> | null = null

   return function (...args: Parameters<T>): Promise<ReturnType<T>> {
      //@ts-ignore
      const context = this
      return new Promise((resolve) => {
         const later = () => {
            timeout = null
            if (!immediate) resolve(func.apply(context, args))
         }

         const callNow = immediate && !timeout
         if (timeout) clearTimeout(timeout)
         timeout = setTimeout(later, wait)

         if (callNow) resolve(func.apply(context, args))
      })
   }
}

function removeDuplicateOptions(arr: OptionType[]): OptionType[] {
   const uniqueNames = new Map(arr.map((item) => [item.name, item.label]))
   return Array.from(uniqueNames, ([name, label]) => ({ name, label }))
}

type Props = {
   label: string
   initialOptions: OptionType[]
   handleUpdateOptions: (value: string) => Promise<string[]>
   name: string
   loading: boolean
   value: any
   resetForce?: boolean
   disabledResetForce?: () => void
   error?: boolean
   helperText?: string
   required?: boolean
   disabled?: boolean
   placeholder?: string
} & (
   | {
        multiple: true
        handleChange: (value: OptionType[]) => void
     }
   | {
        multiple?: false
        handleChange: (value: OptionType) => void
     }
)
const SelectAutoComplete = ({
   label,
   handleUpdateOptions,
   name,
   multiple,
   handleChange,
   loading,
   initialOptions,
   value,
   resetForce,
   disabledResetForce,
   error,
   helperText,
   required,
   disabled,
   placeholder,
}: Props) => {
   const [open, setOpen] = React.useState(false)
   const [localOptions, setLocalOptions] = React.useState<OptionType[]>([])
   const loadingOptions = open && localOptions.length === 0
   const [selectedOptions, setSelectedOptions] = React.useState<
      OptionType | OptionType[] | null
   >(value)

   const debouncedUpdateOptions = debounce(handleUpdateOptions, 500, false)

   const handleInputChange = async (event: any, value: string) => {
      if (
         localOptions.some(
            (option) => option.name.startsWith(value) || option.name === value
         )
      )
         return

      const res = await debouncedUpdateOptions(value)

      if (res.length === 0) return

      setLocalOptions((prevState) => [
         ...res.map((item) => ({ name: item })),
         ...prevState,
      ])
   }

   const handleLocalOnChange = (
      event: any,
      value: OptionType | OptionType[] | null
   ) => {
      if (!value) {
         return
      } else if (multiple) {
         const result = value as OptionType[]
         handleChange(result.map((item) => item))
         setSelectedOptions(result)
      } else {
         handleChange((value as OptionType) ?? null)
         setSelectedOptions(value ?? null)
      }
   }

   React.useEffect(() => {
      let active = true

      if (!loadingOptions) {
         return undefined
      }

      ;(async () => {
         if (active) {
            setLocalOptions([...initialOptions])
         }
      })()

      return () => {
         active = false
      }
   }, [initialOptions, loadingOptions, localOptions])

   React.useEffect(() => {
      if (!open) {
         setLocalOptions([])
      }
   }, [open])

   React.useEffect(() => {
      if (value) {
         handleLocalOnChange(null, value)
      }
   }, [])

   React.useEffect(() => {
      if (resetForce) {
         if (multiple) setSelectedOptions([])
         else setSelectedOptions(null)
         if (disabledResetForce) {
            disabledResetForce()
         }
      }
   }, [disabledResetForce, resetForce])

   return (
      <Autocomplete
         id={name}
         style={{ width: "100%" }}
         open={open}
         onOpen={() => {
            setOpen(true)
         }}
         onClose={() => {
            setOpen(false)
         }}
         multiple={multiple}
         disabled={disabled}
         disableCloseOnSelect
         onChange={handleLocalOnChange}
         disableClearable={required}
         //filterSelectedOptions
         onInputChange={handleInputChange}
         getOptionSelected={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.label ?? option.name}
         options={removeDuplicateOptions(localOptions)}
         loading={loading}
         value={selectedOptions}
         renderOption={(option, { selected }) => (
            <React.Fragment>
               {multiple && (
                  <Checkbox
                     icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                     checkedIcon={<CheckBoxIcon fontSize="small" />}
                     style={{ marginRight: 8 }}
                     checked={selected}
                  />
               )}
               {option.label ?? option.name}
            </React.Fragment>
         )}
         renderInput={(params) => (
            <TextField
               {...params}
               label={label}
               variant="outlined"
               placeholder={placeholder ?? label}
               error={error}
               helperText={helperText}
               InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {loading ? (
                           <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}

export default SelectAutoComplete
