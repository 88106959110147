import { SmartReceiptStatusType } from "services/gov-it/smartReceipt/type"

export type SmartReceiptFormValues = {
   id: string
   businessRegistryConfigurationsFiscalId: string[]
   documentNumber: string
   status: SmartReceiptStatusType[]
   createdAt: string
   documentDate: string
}

export const SmartReceiptEmptyValues: SmartReceiptFormValues = {
   id: "",
   documentNumber: "",
   status: [],
   createdAt: "",
   documentDate: "",
   businessRegistryConfigurationsFiscalId: [],
}
