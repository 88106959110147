import {
   CedentePrestatoreInvoiceSimplifiedPayload,
   CedentePrestratoreInvoicePayload,
   InvoicePayload,
   InvoiceSimplifiedPayload,
} from "services/gov-it/types"

type EmptyInvoiceFieldsProps = {
   cedente_prestatore?: CedentePrestratoreInvoicePayload
}
export const emptyInvoiceFields = ({
   cedente_prestatore,
}: EmptyInvoiceFieldsProps): InvoicePayload => ({
   fattura_elettronica_header: {
      dati_trasmissione: {
         id_trasmittente: null,
         progressivo_invio: null,
         formato_trasmissione: null,
         codice_destinatario: "",
         contatti_trasmittente: null,
         pec_destinatario: null,
      },
      cedente_prestatore: cedente_prestatore ?? {
         dati_anagrafici: {
            id_fiscale_iva: {
               id_paese: "",
               id_codice: "",
            },
            codice_fiscale: null,
            anagrafica: {
               denominazione: "",
               nome: null,
               cognome: null,
               titolo: null,
               cod_eori: null,
            },
            albo_professionale: null,
            provincia_albo: null,
            numero_iscrizione_albo: null,
            data_iscrizione_albo: null,
            regime_fiscale: "",
         },
         sede: {
            indirizzo: "",
            numero_civico: "",
            cap: "",
            comune: "",
            provincia: "",
            nazione: "",
         },
         stabile_organizzazione: null,
         iscrizione_rea: {
            ufficio: "",
            numero_rea: "",
            capitale_sociale: null,
            socio_unico: null,
            stato_liquidazione: "",
         },
         contatti: {
            telefono: "",
            fax: null,
            email: null,
         },
         riferimento_amministrazione: "",
      },
      rappresentante_fiscale: null,
      cessionario_committente: {
         dati_anagrafici: {
            id_fiscale_iva: null,
            codice_fiscale: "",
            anagrafica: {
               denominazione: "",
               nome: null,
               cognome: null,
               titolo: null,
               cod_eori: null,
            },
         },
         sede: {
            indirizzo: "",
            numero_civico: null,
            cap: "",
            comune: "",
            provincia: "",
            nazione: "",
         },
         stabile_organizzazione: null,
         rappresentante_fiscale: null,
      },
      terzo_intermediario_o_soggetto_emittente: null,
      soggetto_emittente: null,
   },
   fattura_elettronica_body: [
      {
         dati_generali: {
            dati_generali_documento: {
               tipo_documento: "TD01",
               divisa: "",
               data: new Date().toISOString().split("T")[0],
               numero: "",
               dati_ritenuta: null,
               dati_bollo: null,
               dati_cassa_previdenziale: null,
               sconto_maggiorazione: null,
               importo_totale_documento: "",
               arrotondamento: null,
               causale: null,
               art73: null,
            },
            dati_ordine_acquisto: null,
            dati_contratto: null,
            dati_convenzione: null,
            dati_ricezione: null,
            dati_fatture_collegate: null,
            dati_sal: null,
            dati_ddt: null,
            dati_trasporto: null,
            fattura_principale: null,
         },
         dati_beni_servizi: {
            dettaglio_linee: [
               {
                  numero_linea: 1,
                  tipo_cessione_prestazione: null,
                  codice_articolo: null,
                  descrizione: "",
                  quantita: "",
                  unita_misura: null,
                  data_inizio_periodo: null,
                  data_fine_periodo: null,
                  prezzo_unitario: "",
                  sconto_maggiorazione: null,
                  prezzo_totale: "",
                  aliquota_iva: "",
                  ritenuta: null,
                  natura: null,
                  riferimento_amministrazione: null,
                  altri_dati_gestionali: null,
               },
            ],
            dati_riepilogo: [
               {
                  aliquota_iva: "",
                  natura: null,
                  spese_accessorie: null,
                  arrotondamento: null,
                  imponibile_importo: "",
                  imposta: "",
                  esigibilita_iva: null,
                  riferimento_normativo: null,
               },
            ],
         },
         dati_veicoli: null,
         dati_pagamento: [
            {
               condizioni_pagamento: "TP02",
               dettaglio_pagamento: [
                  {
                     beneficiario: null,
                     modalita_pagamento: "MP18",
                     data_riferimento_termini_pagamento: null,
                     giorni_termini_pagamento: null,
                     data_scadenza_pagamento: null,
                     importo_pagamento: "",
                     cod_ufficio_postale: null,
                     cognome_quietanzante: null,
                     nome_quietanzante: null,
                     cf_quietanzante: null,
                     titolo_quietanzante: null,
                     istituto_finanziario: null,
                     iban: null,
                     abi: null,
                     cab: null,
                     bic: null,
                     sconto_pagamento_anticipato: null,
                     data_limite_pagamento_anticipato: null,
                     penalita_pagamenti_ritardati: null,
                     data_decorrenza_penale: null,
                     codice_pagamento: null,
                  },
               ],
            },
         ],
         allegati: null,
      },
   ],
})

/*
export const emptyInvoiceFields: InvoicePayload = {
   fattura_elettronica_header: {
      dati_trasmissione: {
         id_trasmittente: {
            id_paese: null,
            id_codice: null,
         },
         progressivo_invio: null,
         formato_trasmissione: null,
         codice_destinatario: "0000000",
         contatti_trasmittente: null,
         pec_destinatario: null,
      },
      cedente_prestatore: {
         dati_anagrafici: {
            id_fiscale_iva: {
               id_paese: "IT",
               id_codice: " 07614180961",
            },
            codice_fiscale: null,
            anagrafica: {
               denominazione: "Westwing srl",
               nome: null,
               cognome: null,
               titolo: null,
               cod_eori: null,
            },
            albo_professionale: null,
            provincia_albo: null,
            numero_iscrizione_albo: null,
            data_iscrizione_albo: null,
            regime_fiscale: "RF01",
         },
         sede: {
            indirizzo: "Viale Certosa 2 ",
            numero_civico: null,
            cap: "20155",
            comune: "Milano",
            provincia: "MI",
            nazione: "IT",
         },
         stabile_organizzazione: null,
         iscrizione_rea: null,
         contatti: null,
         riferimento_amministrazione: null,
      },
      rappresentante_fiscale: null,
      cessionario_committente: {
         dati_anagrafici: {
            id_fiscale_iva: null,
            codice_fiscale: "PNTRMR82S67C129H",
            anagrafica: {
               denominazione: null,
               nome: "Rosamaria Benedetta",
               cognome: "Ponticorvo",
               titolo: null,
               cod_eori: null,
            },
         },
         sede: {
            indirizzo: "Via Filippo Turati 6",
            numero_civico: null,
            cap: "20121",
            comune: "Milano",
            provincia: "MI",
            nazione: "IT",
         },
         stabile_organizzazione: null,
         rappresentante_fiscale: null,
      },
      terzo_intermediario_o_soggetto_emittente: null,
      soggetto_emittente: null,
   },
   fattura_elettronica_body: [
      {
         dati_generali: {
            dati_generali_documento: {
               tipo_documento: "TD26",
               divisa: "EUR",
               data: "2024-04-26",
               numero: "24D-00053",
               dati_ritenuta: null,
               dati_bollo: null,
               dati_cassa_previdenziale: null,
               sconto_maggiorazione: null,
               importo_totale_documento: "4421.60",
               arrotondamento: null,
               causale: null,
               art73: null,
            },
            dati_ordine_acquisto: null,
            dati_contratto: null,
            dati_convenzione: null,
            dati_ricezione: null,
            dati_fatture_collegate: null,
            dati_sal: null,
            dati_ddt: null,
            dati_trasporto: null,
            fattura_principale: null,
         },
         dati_beni_servizi: {
            dettaglio_linee: [
               {
                  numero_linea: 1,
                  tipo_cessione_prestazione: null,
                  codice_articolo: null,
                  descrizione: "AUTO AZIENDALE AUDI",
                  quantita: "1.00",
                  unita_misura: null,
                  data_inizio_periodo: null,
                  data_fine_periodo: null,
                  prezzo_unitario: "3624.26",
                  sconto_maggiorazione: null,
                  prezzo_totale: "3624.26",
                  aliquota_iva: "22.00",
                  ritenuta: null,
                  natura: null,
                  riferimento_amministrazione: null,
                  altri_dati_gestionali: null,
               },
            ],
            dati_riepilogo: [
               {
                  aliquota_iva: "22.00",
                  natura: null,
                  spese_accessorie: null,
                  arrotondamento: null,
                  imponibile_importo: "3624.26",
                  imposta: "797.34",
                  esigibilita_iva: null,
                  riferimento_normativo: null,
               },
            ],
         },
         dati_veicoli: null,
         dati_pagamento: [
            {
               condizioni_pagamento: "TP02",
               dettaglio_pagamento: [
                  {
                     beneficiario: null,
                     modalita_pagamento: "MP05",
                     data_riferimento_termini_pagamento: null,
                     giorni_termini_pagamento: null,
                     data_scadenza_pagamento: null,
                     importo_pagamento: "4421.60",
                     cod_ufficio_postale: null,
                     cognome_quietanzante: null,
                     nome_quietanzante: null,
                     cf_quietanzante: null,
                     titolo_quietanzante: null,
                     istituto_finanziario: null,
                     iban: null,
                     abi: null,
                     cab: null,
                     bic: null,
                     sconto_pagamento_anticipato: null,
                     data_limite_pagamento_anticipato: null,
                     penalita_pagamenti_ritardati: null,
                     data_decorrenza_penale: null,
                     codice_pagamento: null,
                  },
               ],
            },
         ],
         allegati: null,
      },
   ],
}
*/

type EmptyInvoiceFieldsSimplifiedProps = {
   cedente_prestatore?: CedentePrestatoreInvoiceSimplifiedPayload
}
export const emptyInvoiceFieldsSimplified = ({
   cedente_prestatore,
}: EmptyInvoiceFieldsSimplifiedProps): InvoiceSimplifiedPayload => ({
   fattura_elettronica_header: {
      dati_trasmissione: {
         id_trasmittente: {
            id_paese: "",
            id_codice: "",
         },
         progressivo_invio: "",
         formato_trasmissione: "",
         codice_destinatario: "",
         pec_destinatario: null,
      },
      cedente_prestatore: cedente_prestatore ?? {
         id_fiscale_iva: {
            id_paese: "",
            id_codice: "",
         },
         codice_fiscale: "",
         denominazione: null,
         nome: "",
         cognome: "",
         sede: {
            indirizzo: "",
            numero_civico: null,
            cap: "",
            comune: "",
            provincia: "",
            nazione: "",
         },
         stabile_organizzazione: null,
         rappresentante_fiscale: null,
         iscrizione_rea: null,
         regime_fiscale: "",
      },
      cessionario_committente: {
         identificativi_fiscali: {
            id_fiscale_iva: {
               id_paese: "",
               id_codice: "",
            },
            codice_fiscale: null,
         },
         altri_dati_identificativi: {
            denominazione: "",
            nome: null,
            cognome: null,
            sede: {
               indirizzo: "",
               numero_civico: "",
               cap: "",
               comune: "",
               provincia: "",
               nazione: "",
            },
            stabile_organizzazione: null,
            rappresentante_fiscale: null,
         },
      },
      soggetto_emittente: null,
   },
   fattura_elettronica_body: [
      {
         dati_generali: {
            dati_generali_documento: {
               tipo_documento: "",
               divisa: "",
               data: "",
               numero: "",
               bollo_virtuale: null,
            },
            dati_fattura_rettificata: null,
         },
         dati_beni_servizi: [
            {
               descrizione: "",
               importo: "",
               dati_iva: {
                  imposta: "",
                  aliquota: "",
               },
               natura: "",
               riferimento_normativo: null,
            },
         ],
         allegati: null,
      },
   ],
})

/*
export const emptyInvoiceFieldsSimplified: InvoiceSimplifiedPayload = {
   fattura_elettronica_header: {
      dati_trasmissione: {
         id_trasmittente: {
            id_paese: "IT",
            id_codice: "CCCNNN80D07L407I",
         },
         progressivo_invio: "36",
         formato_trasmissione: "FPA12",
         codice_destinatario: "0000000",
         pec_destinatario: null,
      },
      cedente_prestatore: {
         id_fiscale_iva: {
            id_paese: "IT",
            id_codice: "01754930384",
         },
         codice_fiscale: "CCCNNN80D07L407I",
         denominazione: null,
         nome: "ANTONINO",
         cognome: "CACCAMO",
         sede: {
            indirizzo: "VIA RAVENNA 402/B",
            numero_civico: null,
            cap: "44124",
            comune: "FERRARA",
            provincia: "FE",
            nazione: "IT",
         },
         stabile_organizzazione: null,
         rappresentante_fiscale: null,
         iscrizione_rea: null,
         regime_fiscale: "RF01",
      },
      cessionario_committente: {
         identificativi_fiscali: {
            id_fiscale_iva: {
               id_paese: "IT",
               id_codice: "01754930384",
            },
            codice_fiscale: null,
         },
         altri_dati_identificativi: {
            denominazione: "A-Cube s.r.l.",
            nome: null,
            cognome: null,
            sede: {
               indirizzo: "Viale San Martino 57",
               numero_civico: null,
               cap: "20862",
               comune: "Arcore",
               provincia: "MB",
               nazione: "IT",
            },
            stabile_organizzazione: null,
            rappresentante_fiscale: null,
         },
      },
      soggetto_emittente: null,
   },
   fattura_elettronica_body: [
      {
         dati_generali: {
            dati_generali_documento: {
               tipo_documento: "TD07",
               divisa: "EUR",
               data: "2020-06-26",
               numero: "13",
               bollo_virtuale: null,
            },
            dati_fattura_rettificata: null,
         },
         dati_beni_servizi: [
            {
               descrizione: "test",
               importo: "100.00",
               dati_iva: {
                  imposta: null,
                  aliquota: "22.00",
               },
               natura: null,
               riferimento_normativo: null,
            },
         ],
         allegati: null,
      },
   ],
}
*/
