import { createApi } from "@reduxjs/toolkit/query/react"
import { buildSearchParams } from "helper/buildSearchParams"
import { HydraResponse } from "services/types"
import { ApplicationContext } from "store/auth"
import { baseQuery } from ".."

export type User = {
   email: string
   fullname: string | null
   invoicing_address: string | null
   invoicing_cap: string | null
   invoicing_city: string | null
   invoicing_country: string | null
   invoicing_fiscal_id: string | null
   invoicing_name: string | null
   invoicing_province: string | null
   invoicing_sdi_pec: string | null
   invoicing_sdi_recipient_code: string | null
   invoicing_vat_number: string | null
   logo_url: string | null
}

export type GetMeResponse = User

type GetSubscriptionsResponse = {
   uuid: string
   project_codename: ApplicationContext
   deleted: boolean
   fiscal_id?: string
   active: boolean
   period_starts_at: string
   period_ends_at: string
   auto_renew?: boolean
   auto_renew_at?: string
   limit?: number
   count?: number
}[]

type GetSubscriptionsParams = {
   project_codename?: ApplicationContext
   fiscal_id?: string
   active?: 0 | 1
}

type PreSale = {
   uuid: string
   created_at: string
   valid_until: string
   current_credit_available: string
   current_credit_purchased: string
   total_credit_purchased: string
   invoices_received: number
   invoices_sent: number
}

export type PreSaleAction = {
   uuid: string
   created_at: string
   updated_at: string
   threshold: number
   action_type: "alert_mail"
   target: string
   enabled: boolean
   running: boolean
   pre_sale_uuid: string
}

export type SubAccount = {
   email: string
   fiscal_id: string
   fullname: string
   enabled: boolean
   created_at: string
   updated_at: string
}

type CommonSubAccountPaginationParams = {
   page?: number
}

type GetSubAccountsParams = CommonSubAccountPaginationParams &
   Partial<{
      email: string
      fiscal_id: string
      enabled: null
   }>

type GetPreSalesResponse = HydraResponse<PreSale>

type GetSubAccountsResponse = HydraResponse<SubAccount>

type CreateSubAccountActionParams = Pick<
   SubAccount,
   "email" | "fiscal_id" | "fullname" | "enabled"
> & { password: string }

type CreateSubAccountActionResponse = SubAccount

export type UpdateSubAccountActionParams = Partial<
   Pick<SubAccount, "fullname" | "enabled">
>

export type UpdateSubAccountPasswordParams = {
   password: string
}

type UpdateSubAccountResponse = SubAccount

type GetPreSaleActionsResponse = HydraResponse<PreSaleAction>

type CreatePreSaleActionResponse = PreSaleAction
type CreatePreSaleActionParams = Pick<
   PreSaleAction,
   "threshold" | "action_type" | "target" | "enabled" | "pre_sale_uuid"
>

type UpdatePreSaleActionResponse = PreSaleAction
type UpdatePreSaleActionParams = Pick<
   PreSaleAction,
   "threshold" | "action_type" | "target" | "enabled" | "pre_sale_uuid"
>

export const commonApi = createApi({
   tagTypes: ["PRE_SALE_ACTIONS", "SUB_ACCOUNTS", "RECIPIENT_CODE"],
   reducerPath: "commonApi",
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_COMMON_BASE_URL as string,
   }),
   endpoints: (builder) => ({
      getMe: builder.query<GetMeResponse, void>({
         query: () => ({
            url: "/users/me",
         }),
      }),

      getSubscriptions: builder.query<
         GetSubscriptionsResponse,
         GetSubscriptionsParams
      >({
         query: ({ ...params }) => ({
            url: "/subscriptions",
            params,
         }),
      }),

      getPreSales: builder.query<GetPreSalesResponse, void>({
         query: () => ({
            url: "/pre-sales",
         }),
      }),

      getPreSaleActions: builder.query<GetPreSaleActionsResponse, void>({
         query: () => ({
            url: "/pre-sale-actions",
         }),
         providesTags: ["PRE_SALE_ACTIONS"],
      }),

      createPreSaleAction: builder.mutation<
         CreatePreSaleActionResponse,
         CreatePreSaleActionParams
      >({
         query: ({ ...body }) => ({
            url: "/pre-sale-actions",
            method: "POST",
            body,
         }),
         invalidatesTags: ["PRE_SALE_ACTIONS"],
      }),

      updatePreSaleAction: builder.mutation<
         UpdatePreSaleActionResponse,
         UpdatePreSaleActionParams & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/pre-sale-actions/${uuid}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["PRE_SALE_ACTIONS"],
      }),

      deletePreSaleAction: builder.mutation<void, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/pre-sale-actions/${uuid}`,
            method: "DELETE",
         }),
         invalidatesTags: ["PRE_SALE_ACTIONS"],
      }),

      getSubAccounts: builder.query<
         GetSubAccountsResponse,
         GetSubAccountsParams
      >({
         query: (params) => ({
            url: `/users/me/accounts${buildSearchParams({
               ...params,
               refresh: Math.random().toString(),
            })}`,
            headers: {
               accept: "application/ld+json",
            },
         }),
         providesTags: ["SUB_ACCOUNTS"],
      }),

      getSubAccount: builder.query<SubAccount, { email: string }>({
         query: ({ email }) => ({
            url: `users/me/accounts/${email}`,
         }),
      }),

      createSubAccount: builder.mutation<
         CreateSubAccountActionResponse,
         CreateSubAccountActionParams
      >({
         query: ({ ...body }) => ({
            url: "/users/me/accounts",
            method: "POST",
            body,
         }),
         invalidatesTags: ["SUB_ACCOUNTS"],
      }),

      updateSubAccount: builder.mutation<
         UpdateSubAccountResponse,
         UpdateSubAccountActionParams & { email: string }
      >({
         query: ({ email, ...body }) => ({
            url: `/users/me/accounts/${email}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["SUB_ACCOUNTS"],
      }),

      updatePasswordSubAccount: builder.mutation<
         UpdateSubAccountResponse,
         UpdateSubAccountPasswordParams & { email: string }
      >({
         query: ({ email, ...body }) => ({
            url: `/users/me/accounts/${email}/change-password`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["SUB_ACCOUNTS"],
      }),

      deleteSubAccount: builder.mutation<void, { email: string }>({
         query: ({ email }) => ({
            url: `/users/me/accounts/${email}`,
            method: "DELETE",
         }),
         invalidatesTags: ["SUB_ACCOUNTS"],
      }),
   }),
})

export const {
   useGetMeQuery,
   useGetSubscriptionsQuery,
   useGetPreSalesQuery,
   useLazyGetPreSalesQuery,
   useGetPreSaleActionsQuery,
   useCreatePreSaleActionMutation,
   useUpdatePreSaleActionMutation,
   useDeletePreSaleActionMutation,
   useGetSubAccountsQuery,
   useLazyGetSubAccountsQuery,
   useGetSubAccountQuery,
   useCreateSubAccountMutation,
   useUpdateSubAccountMutation,
   useUpdatePasswordSubAccountMutation,
   useDeleteSubAccountMutation,
} = commonApi
