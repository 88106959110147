export const numberFormatter = (locality, number) => {
   const formatter = new Intl.NumberFormat(locality, {
      notation: "compact",
      compactDisplay: "short",
   })

   return formatter.format(number)
}

export const formatPrice = (
   locality,
   price,
   currency = "EUR",
   setDigits = false
) => {
   if (!price) return ""

   const options = {
      style: "currency",
      currency: currency,
      notation: "standard",
   }

   if (setDigits) {
      const [integerPart, decimalPart] = price.toString().split(".")
      const fractionDigits = decimalPart ? decimalPart.length : 0
      options.minimumFractionDigits = fractionDigits
      options.maximumFractionDigits = fractionDigits
   }

   const formatter = new Intl.NumberFormat(locality, options)

   return formatter.format(price)
}
