import { createApi } from "@reduxjs/toolkit/query/react"
import {
   INVOICES,
   INVOICES_DRAFT,
   INVOICES_SIMPLIFIED,
   INVOICES_SIMPLIFIED_DRAFT,
   NOTIFICATIONS,
   USERS_ME_RECIPIENT_CODE,
} from "endpoints"
import { buildSearchParams } from "helper/buildSearchParams"
import { blobToBase64 } from "helper/encodings"
import { HydraResponse } from "services/types"
import { baseQuery } from ".."
import {
   ErrorListItem,
   GovITInvoice,
   InvoicePayload,
   InvoiceSimplifiedPayload,
} from "./types"

export type GovITPaginationParams = {
   page?: number
   itemsPerPage?: number
   type?: 0 | 1
   refetch?: number
}

type GetInvoiceResponse = GovITInvoice

export type GetInvoicesResponse = HydraResponse<GovITInvoice>

export type GetInvoicesParams = {
   uuid?: string
   createdAt?: {
      after: string
      before: string
   }
   sender?: string
   recipient?: string
   signed?: string
   downloaded?: string
   toPa?: string
   stamp?: string
   rejectedToFix?: 0 | 1
   marking?: string[]
   invoiceDate?: {
      after: string | null
      before: string | null
   }
   invoiceNumber?: string
   documentType?: string
} & GovITPaginationParams

type CreateInvoiceResponse = {
   uuid: string
}

type ValidateInvoiceResponse = {
   violations:
      | {
           propertyPath: string
           propertyContent: string | null
           message: string
        }[]
      | null
}

type ValidateInvoiceSimplifiedResponse = ValidateInvoiceResponse

export type ValidateInvoiceEntity = ValidateInvoiceResponse

export type CreateInvoiceParams = InvoicePayload

export type CreateInvoiceSimplifiedParams = InvoiceSimplifiedPayload

export type ValidateInvoiceParams = InvoicePayload

export type ValidateInvoiceSimplifiedParams = InvoiceSimplifiedPayload

type GovITInvoiceNotification = GovITInvoice["notifications"][number]

export type GetInvoiceNotificationsResponse = (Omit<
   GovITInvoiceNotification,
   "message"
> & {
   message: Omit<GovITInvoiceNotification["message"], "lista_errori"> & {
      lista_errori: ErrorListItem[]
   }
})[]

export type GovITInvoicePreservationDocument = NonNullable<
   GovITInvoice["preserved_document"]
>
type GetInvoicePreservationDocumentResponse = GovITInvoicePreservationDocument

export type Notification = GetInvoiceNotificationsResponse[number]

export type GovITInvoiceTransfer = {
   uuid: string
   supplier_name: string | null
   supplier_fiscal_id: string | null
   customer_name: string | null
   customer_fiscal_id: string | null
   invoice_number: string | null
   invoice_date: string | null
   invoice_type: string | null
   invoice_net_amount: number | null
   invoice_vat_amount: number | null
   invoice_tot_amount: number | null
   original_file_name: string
   reference: string
   created_at: string
   violations: string | null
   sent_at: string | null
   sdi_id: string | null
   sdi_file_name: string | null
   invoice_uuid: string | null
   marking: string | null
   notice: string | null
}

type GetInvoiceTransfersResponse = HydraResponse<GovITInvoiceTransfer>

type GetInvoiceTransfersParams = GovITPaginationParams & {
   accept:
      | "application/json"
      | "text/csv"
      | "application/zip"
      | "application/ld+json"
   xPrintPdf?: boolean
   "created_at[strictly_after]"?: string
   "created_at[before]"?: string
   original_file_name?: string
   "exists[violations]"?: boolean | null
}

type ImportInvoiceCustomerResponse = {
   uuid: string
}

type ImportInvoiceCustomerParams = {
   invoice: string
   notifications?: {
      [key in "RC" | "MC" | "NS" | "DT" | "NE" | "AT" | "EC"]?: string
   }
   invoice_file_name?: string
   sdi_id?: string
}

type ImportInvoiceSupplierResponse = {
   uuid: string[]
}

type ImportInvoiceSupplierParams = {
   invoice: string
   metadata?: string
   invoice_file_name?: string
   sdi_id?: string
}

export type FormatType = "json" | "xml"
export type InvoiceType = "simple" | "simplified"

type InvoicePayloadType = {
   simple: InvoicePayload
   simplified: InvoiceSimplifiedPayload
}

type CreateOrUpdateInvoiceDraftParams<T extends InvoiceType> = {
   type: T
   format: FormatType
   body: InvoicePayloadType[T]
   blockInvoiceSent: boolean
}

type CreateInvoiceAllTypeParams<T extends InvoiceType> = {
   type: T
   body: InvoicePayloadType[T]
}

type GetIsFixableInvoiceResponse = {
   uuid: string
   is_fixable: boolean
   fixed_by_uuid: string | null
   fix_root_uuid: string | null
}

type SetDownloadedFlagInvoiceParams = {
   uuids: string[]
   downloaded: boolean
}

type SetDownloadedFlagInvoiceResponse = void

export const govItApi = createApi({
   reducerPath: "govItApi",
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL as string,
   }),
   refetchOnMountOrArgChange: true,
   refetchOnReconnect: true,
   refetchOnFocus: true,
   keepUnusedDataFor: 30,
   tagTypes: [
      "INVOICES",
      "INVOICE_NOTIFICATIONS",
      "INVOICE_TRANSFERS",
      "REJECTED_INVOICES_COUNT",
      "INVOICES_DRAFT",
      "API_CONFIGURATIONS",
      "BUSINESS_REGISTRY_CONFIGURATIONS",
      "RECIPIENT_CODE",
      "SMART_RECEIPTS",
      "SMART_RECEIPT_DETAIL_PDF",
      "BUSINESS_REGISTRY_CONFIGURATIONS_SUB_ACCOUNTS",
   ],
   endpoints: (builder) => ({
      getUserRecipientCode: builder.query<{ recipient_code: string }, void>({
         query: () => ({
            url: `${USERS_ME_RECIPIENT_CODE}`,
         }),
         providesTags: ["RECIPIENT_CODE"],
      }),

      getInvoice: builder.query<
         GetInvoiceResponse,
         {
            uuid: string
            refresh?: number
         }
      >({
         query: ({ uuid, ...params }) => ({
            url: `/invoices/${uuid}${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
            headers: {
               Accept: "application/json",
            },
         }),
         transformResponse: (response: GovITInvoice) => {
            const isSimplified = response.transmission_format === "FSM10"
            try {
               return {
                  ...response,
                  ...(isSimplified
                     ? { parsedPayloadSimplified: JSON.parse(response.payload) }
                     : { parsedPayload: JSON.parse(response.payload) }),
               }
            } catch {
               return {
                  ...response,
                  ...(isSimplified
                     ? { parsedPayloadSimplified: null }
                     : { parsedPayload: null }),
               }
            }
         },
         providesTags: ["INVOICES"],
      }),

      getDraftInvoice: builder.query<
         GetInvoiceResponse,
         {
            uuid: string
            refresh?: number
         }
      >({
         query: ({ uuid, ...params }) => ({
            url: `${INVOICES_DRAFT}/${uuid}${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
            headers: {
               Accept: "application/json",
            },
         }),
         transformResponse: (response: GovITInvoice) => {
            const isSimplified = response.transmission_format === "FSM10"
            try {
               return {
                  ...response,
                  ...(isSimplified
                     ? {
                          parsedPayloadSimplified: JSON.parse(response.payload),
                          parsedPayload: null,
                       }
                     : {
                          parsedPayload: JSON.parse(response.payload),
                          parsedPayloadSimplified: null,
                       }),
               }
            } catch {
               return {
                  ...response,
                  ...(isSimplified
                     ? { parsedSimplifiedPayload: null }
                     : { parsedPayload: null }),
               }
            }
         },
         providesTags: ["INVOICES_DRAFT"],
      }),

      getDraftInvoices: builder.query<GetInvoicesResponse, GetInvoicesParams>({
         query: ({ uuid, ...params }) => ({
            url: `${INVOICES_DRAFT}${uuid ? `/${uuid}` : ""}${buildSearchParams(
               {
                  ...params,
                  ...(params.refetch
                     ? { refetch: params.refetch * Math.random() }
                     : { refetch: Math.random() * 100 }),
               }
            )}`,
         }),
         providesTags: ["INVOICES_DRAFT"],
      }),

      getInvoices: builder.query<GetInvoicesResponse, GetInvoicesParams>({
         query: ({ uuid, ...params }) => ({
            url: `/invoices${uuid ? `/${uuid}` : ""}${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
         }),
         providesTags: ["INVOICES"],
      }),

      getInvoiceFile: builder.query<
         string | Blob,
         {
            uuid: string
            accept: "application/pdf" | "application/xml" | "text/html"
         }
      >({
         query: ({ uuid, accept }) => ({
            url: `/invoices/${uuid}`,
            headers: {
               Accept: accept,
               ...(accept === "text/html" && { "X-PrintTheme": "acube" }),
            },
            responseHandler: async (response) => {
               if (accept === "application/pdf") {
                  return await response.blob()
               }
               return response.text()
            },
         }),
      }),

      getPreservationDocumentReceipt: builder.query<
         string,
         {
            uuid: string
         }
      >({
         query: ({ uuid }) => ({
            url: `/preserved-documents/${uuid}/receipt`,
            headers: {
               Accept: "application/xml",
            },
            responseHandler: async (response) => {
               return response.text()
            },
         }),
      }),

      getInvoicesZip: builder.query<
         Blob,
         GetInvoicesParams & { printPdf: "true" | "false" }
      >({
         query: (params) => ({
            url: `/invoices${buildSearchParams(params as Record<string, any>)}`,
            headers: {
               Accept: "application/zip",
               ...(params.printPdf === "true" && { "X-Print-Pdf": "true" }),
            },
            responseHandler: async (response) => {
               return await response.blob()
            },
         }),
      }),

      getInvoicesReport: builder.query<Blob, GetInvoicesParams>({
         query: (params) => ({
            url: `/invoices/report${buildSearchParams(
               params as Record<string, any>
            )}`,
            headers: {
               Accept: "text/csv",
            },
            responseHandler: async (response) => {
               return await response.blob()
            },
         }),
      }),

      getIsFixableInvoice: builder.query<
         GetIsFixableInvoiceResponse,
         {
            uuid: string
         }
      >({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/fix-info?refresh=${new Date().getTime()}`,
            headers: {
               Accept: "application/json",
               //"Cache-Control": "no-store",
            },
         }),
         keepUnusedDataFor: 0,
      }),

      createCustomerInvoice: builder.mutation<
         CreateInvoiceResponse,
         {
            body: InvoicePayload
            format: "json" | "xml"
            type: "simple" | "simplified"
         }
      >({
         query: ({ body, format, type }) => ({
            url: `${type === "simple" ? INVOICES : INVOICES_SIMPLIFIED}`,
            method: "POST",
            headers: {
               Accept: "application/json",
               "Content-type": `application/${format}`,
               "X-SendAsync": "true",
            },
            body,
         }),
         invalidatesTags: ["INVOICES", "INVOICE_NOTIFICATIONS"],
      }),

      createDraftInvoice: builder.mutation<
         CreateInvoiceResponse,
         CreateOrUpdateInvoiceDraftParams<"simple" | "simplified">
      >({
         query: ({ body, format, type, blockInvoiceSent }) => ({
            url: `${
               type === "simple" ? INVOICES_DRAFT : INVOICES_SIMPLIFIED_DRAFT
            }`,
            method: "POST",
            headers: {
               Accept: "application/json",
               "Content-type": `application/${format}`,
               ...(blockInvoiceSent && { "X-Draft-Blocked": "true" }),
            },
            body,
         }),
         invalidatesTags: ["INVOICES_DRAFT"],
      }),

      updateDraftInvoice: builder.mutation<
         CreateInvoiceResponse,
         CreateOrUpdateInvoiceDraftParams<"simple" | "simplified"> & {
            uuid: string
         }
      >({
         query: ({ uuid, body, format, type, blockInvoiceSent }) => ({
            url: `${
               type === "simple" ? INVOICES_DRAFT : INVOICES_SIMPLIFIED_DRAFT
            }/${uuid}`,
            method: "PUT",
            headers: {
               Accept: "application/json",
               "Content-type": `application/${format}`,
               ...(blockInvoiceSent && { "X-Draft-Blocked": "true" }),
            },
            body,
         }),
         invalidatesTags: ["INVOICES_DRAFT"],
      }),

      sendDraftInvoice: builder.mutation<
         CreateInvoiceResponse,
         {
            uuid: string
            type: "simple" | "simplified"
         }
      >({
         query: ({ uuid, type }) => ({
            url: `${
               type === "simple" ? INVOICES_DRAFT : INVOICES_SIMPLIFIED_DRAFT
            }/${uuid}/send`,
            method: "POST",
         }),
         invalidatesTags: ["INVOICES_DRAFT"],
      }),

      deleteDraftInvoice: builder.mutation<
         void,
         {
            uuid: string
            type: "simple" | "simplified"
         }
      >({
         query: ({ uuid, type }) => ({
            url: `${
               type === "simple" ? INVOICES_DRAFT : INVOICES_SIMPLIFIED_DRAFT
            }/${uuid}`,
            method: "DELETE",
         }),
         invalidatesTags: ["INVOICES_DRAFT"],
      }),

      createInvoice: builder.mutation<
         CreateInvoiceResponse,
         CreateInvoiceAllTypeParams<"simple" | "simplified"> & {
            isFixInvoice?: boolean
            invoiceToFixUuid?: string
         }
      >({
         query: (body) => ({
            url: `${body.type === "simple" ? INVOICES : INVOICES_SIMPLIFIED}`,
            method: "POST",
            headers: {
               "content-type": "application/json",
               ...(body.isFixInvoice && {
                  "X-Fix-Uuid": body.invoiceToFixUuid,
               }),
            },
            body: body.body,
         }),
         invalidatesTags: ["INVOICES", "INVOICE_NOTIFICATIONS"],
      }),

      validateInvoice: builder.mutation<
         ValidateInvoiceResponse,
         ValidateInvoiceParams
      >({
         query: (body) => ({
            url: "/invoices/validate",
            method: "POST",
            headers: {
               "content-type": "application/json",
               accept: "application/json",
            },
            body,
         }),
      }),

      validateInvoiceSimplified: builder.mutation<
         ValidateInvoiceSimplifiedResponse,
         ValidateInvoiceSimplifiedParams
      >({
         query: (body) => ({
            url: "/invoices/simplified/validate",
            method: "POST",
            headers: {
               "content-type": "application/json",
               accept: "application/json",
            },
            body,
         }),
      }),

      getInvoiceNotifications: builder.query<
         GetInvoiceNotificationsResponse,
         { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/notifications?refresh=${new Date().getTime()}`,
            headers: {
               Accept: "application/json",
               //"Cache-Control": "no-store",
            },
         }),
         // Normalize `lista_errori` to always be an array
         transformResponse: (response: GovITInvoiceNotification[]) =>
            response.map((notification) => ({
               ...notification,
               message: {
                  ...notification.message,
                  lista_errori: !Array.isArray(
                     notification.message.lista_errori
                  )
                     ? [notification.message.lista_errori]
                     : notification.message.lista_errori,
               },
            })),
         providesTags: ["INVOICE_NOTIFICATIONS"],
      }),

      getNotificationXml: builder.query<string, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `${NOTIFICATIONS}/${uuid}`,
            headers: {
               Accept: "application/xml",
            },
            responseHandler: async (response) => {
               return response.text()
            },
         }),
      }),

      getInvoiceTransfers: builder.query<
         GetInvoiceTransfersResponse,
         GetInvoiceTransfersParams
      >({
         query: ({ accept, xPrintPdf, ...params }) => ({
            url: `/invoice-transfers${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}&fixed=false`,
            headers: {
               Accept: accept,
               ...(xPrintPdf && { "X-PrintPdf": xPrintPdf.toString() }),
            },
            responseHandler: (response) => {
               if (accept === "text/csv") {
                  return response.text()
               }

               if (accept === "application/zip") {
                  return response.blob()
               }

               return response.json()
            },
         }),
      }),

      getInvoiceAttachment: builder.query<
         string,
         { invoiceUuid: string; index: number }
      >({
         query: ({ invoiceUuid, index }) => ({
            url: `/invoices/${invoiceUuid}/attachments/${index}`,
            headers: {
               Accept: "application/pdf",
            },
            responseHandler: async (response) => {
               return blobToBase64(await response.blob())
            },
         }),
      }),

      getInvoicePreservationDocument: builder.query<
         GetInvoicePreservationDocumentResponse,
         { invoiceUuid: string; refresh?: number }
      >({
         query: ({ invoiceUuid, ...params }) => ({
            url: `/invoices/${invoiceUuid}/preserved-document${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
            headers: {
               Accept: "application/json",
            },
         }),
      }),

      sentInvoiceToPreservation: builder.mutation<
         { uuid: string },
         { invoiceUuid: string }
      >({
         query: ({ invoiceUuid }) => ({
            url: `/invoices/${invoiceUuid}/preserve`,
            method: "POST",
         }),
         invalidatesTags: ["INVOICES"],
      }),

      importCustomerInvoice: builder.mutation<
         ImportInvoiceCustomerResponse,
         ImportInvoiceCustomerParams
      >({
         query: (body) => ({
            url: `/customer-invoice-imports`,
            method: "POST",
            headers: {
               "content-type": "application/json",
            },
            body,
         }),
      }),

      importSupplierInvoice: builder.mutation<
         ImportInvoiceSupplierResponse,
         ImportInvoiceSupplierParams
      >({
         query: (body) => ({
            url: `/supplier-invoice-imports`,
            method: "POST",
            headers: {
               "content-type": "application/json",
            },
            body,
         }),
      }),

      setDownloadedFlagInvoice: builder.mutation<
         SetDownloadedFlagInvoiceResponse,
         SetDownloadedFlagInvoiceParams
      >({
         query: (body) => ({
            url: `/invoices/downloaded`,
            method: "POST",
            headers: {
               "content-type": "application/json",
            },
            body,
         }),
         invalidatesTags: ["INVOICES"],
      }),

      setArchiveRejectedInvoice: builder.mutation<void, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/invoices/${uuid}/archive-rejected`,
            method: "PUT",
         }),
         invalidatesTags: ["INVOICES"],
      }),

      downloadInvoiceTransferFile: builder.query<Blob, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/invoice-transfers/${uuid}/download`,
            responseHandler: async (response) => {
               return response.blob()
            },
         }),
      }),
   }),
})

export const {
   useGetUserRecipientCodeQuery,
   useGetInvoiceQuery,
   useGetDraftInvoiceQuery,
   useGetDraftInvoicesQuery,
   useGetInvoicesQuery,
   useCreateCustomerInvoiceMutation,
   useCreateDraftInvoiceMutation,
   useDeleteDraftInvoiceMutation,
   useSendDraftInvoiceMutation,
   useUpdateDraftInvoiceMutation,
   useLazyGetInvoiceFileQuery,
   useGetIsFixableInvoiceQuery,
   useGetInvoiceFileQuery,
   useLazyGetInvoicePreservationDocumentQuery,
   useGetInvoicePreservationDocumentQuery,
   useLazyGetInvoicesZipQuery,
   useLazyGetInvoicesReportQuery,
   useCreateInvoiceMutation,
   useGetInvoiceNotificationsQuery,
   useGetInvoiceTransfersQuery,
   useLazyGetInvoiceTransfersQuery,
   useGetInvoiceAttachmentQuery,
   useLazyGetInvoiceAttachmentQuery,
   useValidateInvoiceMutation,
   useValidateInvoiceSimplifiedMutation,
   useSentInvoiceToPreservationMutation,
   useGetPreservationDocumentReceiptQuery,
   useLazyGetPreservationDocumentReceiptQuery,
   useLazyGetNotificationXmlQuery,
   useImportCustomerInvoiceMutation,
   useImportSupplierInvoiceMutation,
   useSetDownloadedFlagInvoiceMutation,
   useSetArchiveRejectedInvoiceMutation,
   useLazyDownloadInvoiceTransferFileQuery,
} = govItApi
