import { Box } from "@material-ui/core"
import { GovITInvoice } from "services/gov-it/types"
import CheckedActionsButtons from "views/gov-it/InvoiceDrafts/Buttons/ActionsCheckedFooterTableButtons/components/CheckedActionsButtons"
import DeletedCheckedActionsButton from "views/gov-it/InvoiceDrafts/Buttons/ActionsCheckedFooterTableButtons/components/DeleteCheckedActionsButton"

type ActionsCheckedButtonsProps = {
   invoices: GovITInvoice[] | undefined
}
const ActionsCheckedFooterTableButtons = ({
   invoices,
}: ActionsCheckedButtonsProps) => {
   return (
      <Box
         style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
         }}
      >
         <CheckedActionsButtons invoices={invoices} />
         <DeletedCheckedActionsButton invoices={invoices} />
      </Box>
   )
}

export default ActionsCheckedFooterTableButtons
