import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getItem, removeItem, setItem } from "helper/storage"
import { initContexts } from "store/app"
import type { RootState } from ".."

export type ApplicationContext =
   | "common.api.acubeapi.com"
   | "edocway.api.acubeapi.com"
   | "it.api.acubeapi.com"
   | "nsofacile.it"
   | "peppol.api.acubeapi.com"
   | "pl.api.acubeapi.com"
   | "ob.api.acubeapi.com"
   | "preservation.api.acubeapi.com"
   | "stripe.acubeapi.com"
   | "transfer.acubeapi.com"

export type UserRole =
   | "ROLE_USER"
   | "ROLE_PRESERVATION"
   | "ROLE_ADMIN"
   | "ROLE_ALLOWED_TO_SWITCH"
   | "ROLE_SUB_ACCOUNT_MANAGER"
   | "ROLE_FIX_INVOICE"
   | "ROLE_READER"
   | "ROLE_WRITER"
   | "ROLE_INVOICING_RECOVER"
   | "ROLE_CONFIG"
   | "ROLE_CONFIG_BRC"
   | "ROLE_INVOICING_DRAFT"
   | "ROLE_INVOICING"
   | "ROLE_NO_COSTS"
   | "ROLE_INVOICING_CUSTOMER_ONLY"
   | "ROLE_INVOICING_SUPPLIER_ONLY"
   | "ROLE_SHOW_STATS"
   | "ROLE_TRANSFER"
   | "ROLE_API"
   | "ROLE_CREATE_INVOICE"
   | "ROLE_RECEIPTS"

type Token = {
   iat: number
   exp: number
   uid: number
   username: string
   roles: {
      [key in ApplicationContext]: UserRole[]
   }
   // Parent id, if exists the user is a sub account
   pid: string | null
}

export const parseToken = (token: string) => {
   try {
      const tokenPayload = token.split(".")[1]
      const parsedToken = JSON.parse(atob(tokenPayload)) as Token

      return parsedToken
   } catch (error) {
      return null
   }
}

const isTokenExpired = ({ exp }: Token) => exp < Date.now() / 1000

export const login = createAsyncThunk(
   "auth/login",
   (token: string, { dispatch }) => {
      const parsedToken = parseToken(token)

      if (parsedToken == null || isTokenExpired(parsedToken)) {
         removeItem("token")
         return null
      }

      setItem("token", token)
      dispatch(
         initContexts(Object.keys(parsedToken.roles) as ApplicationContext[])
      )

      return { rawToken: token, token: parsedToken }
   }
)

export const tryLoginWithExistingSession = createAsyncThunk(
   "auth/tryLoginWithExistingSession",
   (_, { dispatch }) => {
      const token = getItem("token")

      if (token == null) {
         return
      }

      dispatch(login(token))
   }
)

export const logout = createAsyncThunk("auth/logout", () => {
   removeItem("token")
   // @TODO: remove other persisted states
   // localStorage.removeItem("active_context");
})

type State = {
   token: string | null
   data: Pick<Token, "username" | "roles" | "pid"> | null
}

const initialState: State = {
   token: null,
   data: null,
}

const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(login.fulfilled, (state, { payload }) => {
            if (payload == null) {
               return
            }

            const { username, roles, pid } = payload.token

            state.token = payload.rawToken
            state.data = { username, roles, pid }
         })
         .addCase(logout.fulfilled, (state) => ({
            ...initialState,
         }))
   },
})

export const { reducer } = authSlice

export const selectAuthToken = ({ auth }: RootState) => auth.token

export const selectUsername = ({ auth }: RootState) => auth.data?.username

export const selectIsAuthenticated = ({ auth }: RootState) => {
   const { token } = auth

   if (token == null) {
      return false
   }

   const parsedToken = parseToken(token)

   return parsedToken != null && !isTokenExpired(parsedToken)
}

export const selectHasRole = (
   { auth }: RootState,
   { context, role }: { context: ApplicationContext; role: UserRole }
) => {
   const rolesForContext = auth.data?.roles[context] ?? []

   return rolesForContext.includes(role)
}

export const selectHasContext = (
   { auth }: RootState,
   context: ApplicationContext
) => {
   if (auth.data == null) {
      return false
   }
   const { roles } = auth.data

   return roles[context] != null
}

export const selectRolesForContext = (
   { auth }: RootState,
   context: ApplicationContext
) => {
   return auth.data?.roles[context] ?? []
}

export const selectContexts = ({ auth }: RootState) =>
   Object.keys(auth.data?.roles ?? {}) as ApplicationContext[]

export const selectHasPid = ({ auth }: RootState) => {
   if (auth.data == null) {
      return false
   }

   const { pid } = auth.data
   return pid != null
}

export const selectIsUserStripe = ({ auth }: RootState) => {
   if (auth.data == null) {
      return false
   }

   const { roles } = auth.data
   return (
      roles["stripe.acubeapi.com"] != null &&
      roles["stripe.acubeapi.com"].includes("ROLE_WRITER")
   )
}
