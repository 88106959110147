import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableFilters,
   updateFilter,
} from "store/tablesState"
import LabelYesNo from "views/common/LabelYesNo"
import {
   FormValues,
   mapFormValuesToTableFilters,
   mapTableFiltersToFormValues,
   TableFilter,
} from "./utils"

const table = `common.sub_accounts`

const TableFilters = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const initialValues = mapTableFiltersToFormValues(table, filters)

   const handleFilter = (values: FormValues) => {
      const updatedFilters = mapFormValuesToTableFilters(table, values)

      batch(() => {
         Object.entries(updatedFilters).forEach(([key, value]) => {
            const filter = key as TableFilter
            const filterChanged = value !== filters[filter]
            if (filterChanged) {
               dispatch(updateFilter({ table, filter, value }))
            }
         })
      })
   }

   return (
      <Box mb={4}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleFilter}
            enableReinitialize
         >
            {({
               values,
               errors,
               touched,
               handleBlur,
               handleChange,
               handleSubmit,
               resetForm,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Accordion elevation={1}>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                     >
                        <Typography variant="body1">
                           {t("Default.Filtra i risultati")}
                        </Typography>
                     </AccordionSummary>

                     <AccordionDetails>
                        <Box display="flex" flexDirection="column" flexGrow={1}>
                           <Box display="flex" gridGap={16} mt={2}>
                              <TextField
                                 value={values.email}
                                 error={
                                    touched.email != null &&
                                    errors.email != null
                                 }
                                 fullWidth
                                 helperText={errors.email}
                                 label={t("common_subAccounts.form.email")}
                                 name="email"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 type="email"
                              />

                              <TextField
                                 value={values.fiscal_id}
                                 error={
                                    touched.fiscal_id != null &&
                                    errors.fiscal_id != null
                                 }
                                 fullWidth
                                 helperText={errors.fiscal_id}
                                 label={t("common_subAccounts.form.fiscal_id")}
                                 name="fiscal_id"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 type="text"
                              />

                              <FormControl fullWidth>
                                 <InputLabel>
                                    {t("common_subAccounts.enabled")}
                                 </InputLabel>

                                 <Select
                                    value={values.enabled}
                                    name="enabled"
                                    onChange={handleChange}
                                    label={t("common_subAccounts.enabled")}
                                 >
                                    <MenuItem value={undefined}>
                                       <LabelYesNo value={null} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                       <LabelYesNo value={true} />
                                    </MenuItem>
                                    <MenuItem value={0}>
                                       <LabelYesNo value={false} />
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           </Box>

                           <Box display="flex" justifyContent="flex-end" mt={2}>
                              <Box mr={2}>
                                 <Button
                                    onClick={() => {
                                       dispatch(resetFilters({ table }))
                                       resetForm()
                                    }}
                                    variant="contained"
                                 >
                                    {t("Default.Cancella")}
                                 </Button>
                              </Box>
                              <Button
                                 type="submit"
                                 color="primary"
                                 variant="contained"
                              >
                                 {t("Default.Cerca")}
                              </Button>
                           </Box>
                        </Box>
                     </AccordionDetails>
                  </Accordion>
               </form>
            )}
         </Formik>
      </Box>
   )
}

export default TableFilters
