import { Box, DialogContent, Link, Typography } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { HelpCircle } from "react-feather"
import { useTranslation } from "react-i18next"
import {
   PreSaleAction,
   useUpdatePreSaleActionMutation,
} from "services/commonApi"
import { DialogId, DialogState } from "store/slices/dialogs"
import * as Yup from "yup"
import EditPreSaleActionForm, { FormValues } from "../EditPreSaleActionForm"

const mapPreSaleActionToFormValues = (preSaleAction: PreSaleAction) =>
   Object.entries(preSaleAction)
      .filter(([key]) =>
         ["threshold", "action_type", "target", "enabled"].includes(key)
      )
      .reduce(
         (acc, [key, value]) => ({ ...acc, [key]: value }),
         {} as FormValues
      )

const DIALOG_ID: DialogId = "settings_edit_pre_sale_action"

const validationSchema = Yup.object().shape({
   threshold: Yup.number().integer().positive().required(),
   target: Yup.string().email().required(),
})

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, ...preSaleAction }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updatePreSaleAction] = useUpdatePreSaleActionMutation()

   const initialValues = mapPreSaleActionToFormValues(preSaleAction)

   const handleSubmit = async (values: FormValues) => {
      const response = await updatePreSaleAction({
         uuid: preSaleAction.uuid,
         pre_sale_uuid: preSaleAction.pre_sale_uuid,
         ...values,
      })

      if ("data" in response) {
         enqueueSnackbar(
            t("settings.pre_sale_actions.messages.pre_sale_action_updated"),
            { variant: "success" }
         )
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={2}
         >
            <Typography variant="subtitle2">
               {t(
                  "settings.pre_sale_actions.edit_pre_sale_action_dialog_title"
               )}
            </Typography>
            <Link
               href="https://docs.acubeapi.com/documentation/common/presale-actions/"
               target="_blank"
               title="Documentation"
            >
               <HelpCircle />
            </Link>
         </Box>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditPreSaleActionForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const EditPreSaleActionDialog = () => (
   <BaseDialog id={DIALOG_ID}>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default EditPreSaleActionDialog
