import { GovITInvoice } from "services/gov-it/types"
import { InvoiceDraftsFormMode } from "store/slices/dialogs"
import { TableName } from "store/tablesState"
import SwitchCommonInvoiceForm from "views/common/InvoiceForm/SwitchCommonInvoiceForm"

type TabPanelSendNewInvoiceProps = {
   value: number
   index: number
   handleClose: () => void
   mode: InvoiceDraftsFormMode
   invoice?: GovITInvoice
}

const table: TableName = "invoice.drafts"
const TabPanelFromEditorInvoiceDraft = ({
   value,
   index,
   invoice,
   mode,
}: TabPanelSendNewInvoiceProps) => {
   const simplified = invoice?.transmission_format === "FSM10"

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
         style={{
            padding: 10,
         }}
      >
         {mode === "new" ? (
            <SwitchCommonInvoiceForm
               showRadioToChooseFormType={true}
               showBlockButtonCheckBoxForDraft={true}
               project="invoice_draft"
               modeDraft={"new"}
               table={table}
               formType={"simple"}
               mode="create"
               invoice={null}
            />
         ) : simplified ? (
            <SwitchCommonInvoiceForm
               showRadioToChooseFormType={false}
               showBlockButtonCheckBoxForDraft={true}
               project="invoice_draft"
               table={table}
               formType={"simplified"}
               modeDraft={mode}
               invoice={invoice as GovITInvoice | null}
            />
         ) : (
            <SwitchCommonInvoiceForm
               showRadioToChooseFormType={false}
               showBlockButtonCheckBoxForDraft={true}
               project="invoice_draft"
               table={table}
               formType={"simple"}
               modeDraft={mode}
               invoice={invoice as GovITInvoice | null}
            />
         )}
      </div>
   )
}

export default TabPanelFromEditorInvoiceDraft
