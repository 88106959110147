import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store"
import { openDialog, selectIsDialogOpen } from "store/slices/dialogs"

export const useOpenDialogFromURL = () => {
   const [searchParams] = useSearchParams()
   const dispatch = useAppDispatch()

   const dialogId = searchParams.get("dialogId")
   const isDialogAlreadyOpen = useAppSelector((state) =>
      // @ts-expect-error
      selectIsDialogOpen(state, dialogId)
   )

   useEffect(() => {
      if (isDialogAlreadyOpen || dialogId == null) {
         return
      }

      // This will also retrieve query params not part of dialog data but it's ok,
      // only the data of interest will be used by the dialogs
      const queryParams = Array.from(searchParams.entries()).filter(
         ([key]) => key !== "dialogId"
      )
      const dialogData = queryParams.reduce(
         (acc, [key, value]) => ({
            ...acc,
            [key]: value,
         }),
         {} as Record<string, string>
      )

      dispatch(
         // @ts-expect-error
         openDialog({ id: dialogId, data: dialogData })
      )
   }, [dispatch, dialogId, isDialogAlreadyOpen, searchParams])
}
