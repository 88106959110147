import { Grid, makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import { Formik } from "formik"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { Text } from "../common/formik/Text"

function ProfileForm(props, { className, ...rest }) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values,
      { resetForm, setErrors, setSubmitting }
   ) => {
      await props.handleSave(values)
   }

   const validationSchema = Yup.object().shape({
      // invoicing_vat_number: Yup.string().min(7).required('VAT is required'),
      // invoicing_fiscal_id: Yup.string().min(7).required('FiscalId is required'),
   })

   const fields = [
      "email",
      "fullname",
      "invoicing_vat_number",
      "invoicing_fiscal_id",
      "invoicing_address",
      "invoicing_city",
      "invoicing_province",
      "invoicing_cap",
      "invoicing_name",
   ]

   return (
      <Formik
         initialValues={props.profile}
         onSubmit={handleSubmit}
         validationSchema={validationSchema}
      >
         {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            resetForm,
         }) => (
            <form
               onSubmit={handleSubmit}
               noValidate
               className={clsx(classes.root, className)}
            >
               <Grid container spacing={3}>
                  {fields.map((field, index) => {
                     return (
                        <Grid
                           item
                           md={12}
                           xs={12}
                           key={"profile-form-field-" + index}
                        >
                           <Text
                              disabled={
                                 field === "email" ||
                                 field === "invoicing_vat_number" ||
                                 field === "invoicing_fiscal_id"
                              }
                              name={field}
                              label={t("User.fields." + field)}
                           />
                        </Grid>
                     )
                  })}
               </Grid>

               <div className={classes.buttons}>
                  <Button
                     type="submit"
                     color="primary"
                     variant="contained"
                     disabled={props.isLoading}
                  >
                     {t("Default.Salva")}
                  </Button>
                  <Button
                     onClick={props.handleClose}
                     color="default"
                     variant="contained"
                  >
                     {t("Default.Annulla")}
                  </Button>
               </div>
            </form>
         )}
      </Formik>
   )
}

ProfileForm.propTypes = {
   className: PropTypes.string,
}

export default ProfileForm

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
