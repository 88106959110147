import Label from "./Label"

const PreservedDocumentStatus = ({ value }) => {
   switch (value) {
      case "to_be_stored":
         return <Label color="primary">{value}</Label>
      case "sent":
         return <Label color="warning">{value}</Label>
      case "stored":
         return <Label color="success">{value}</Label>
      case "error":
      default:
         return <Label color="error">{value}</Label>
   }
}

export default PreservedDocumentStatus
