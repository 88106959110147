import { Grid, TablePagination } from "@material-ui/core"
import TablePaginationActions from "components/Table/PaginationActions"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTotalItems,
} from "store/tablesState"

const table = "openbanking.business_registries"

const BusinessRegistryTablePagination = () => {
   const dispatch = useAppDispatch()

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   return (
      <Grid container alignItems="center">
         <Grid item xs={12}>
            <TablePagination
               rowsPerPageOptions={[]}
               component="div"
               count={totalItems}
               page={currentPage - 1}
               onPageChange={(_event, page) => {
                  dispatch(changePage({ table, page }))
               }}
               labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count}, page ${currentPage} of ${
                     count !== 0 ? Math.ceil(count / itemsPerPage) : 1
                  }`
               }
               ActionsComponent={TablePaginationActions}
               rowsPerPage={itemsPerPage}
            />
         </Grid>
      </Grid>
   )
}

export default BusinessRegistryTablePagination
