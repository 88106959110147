import { TableName, tablesInitialState, TablesState } from "store/tablesState"

type Table = Extract<TableName, "common.sub_accounts">
type TableFilters = TablesState[Table]["filters"]
export type TableFilter = keyof TableFilters

export type FormValues = {
   email: string
   fiscal_id: string
   enabled: boolean
}
export const mapFormValuesToTableFilters = (
   table: Table,
   formValues: FormValues
): TableFilters =>
   Object.entries(formValues).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value }
   }, tablesInitialState[table].filters)

export const mapTableFiltersToFormValues = (
   table: Table,
   tableFilters: TableFilters
): FormValues =>
   Object.entries(tableFilters).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value }
   }, {} as FormValues)
