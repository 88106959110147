import {
   Badge,
   Box,
   createStyles,
   DialogActions,
   FormControl,
   FormHelperText,
   FormLabel,
   Grid,
   makeStyles,
   Paper,
   Select,
   Theme,
   Typography,
   withStyles,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import {
   Field,
   FieldArray,
   Formik,
   FormikErrors,
   FormikHelpers,
   FormikValues,
} from "formik"
import React, { RefObject, useRef } from "react"
import { useTranslation } from "react-i18next"
import LabelYesNo from "views/common/LabelYesNo"
import * as Yup from "yup"
import { useImportCustomerInvoiceMutation } from "../../../../../services/gov-it"
import { useAppDispatch } from "../../../../../store"
import { updateRefetch } from "../../../../../store/tablesState"
import { persistTableRefresh } from "../../../../../store/tablesState/utils"
import CustomInputFileField from "../Components/CustomInputFileField"

type Error = {
   propertyPath: string
   message: string
}

export type FormValues = {
   invoice: string
   notifications: {
      key: "RC" | "MC" | "NS" | "DT" | "NE" | "AT" | "EC" | null
      value: string | null
   }[]
   invoice_file_name: string
   sdi_id: string
}

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))

const schema = Yup.object().shape({
   invoice: Yup.string().required("Please upload invoice file"),
   notifications: Yup.array().of(
      Yup.object()
         .shape({
            key: Yup.string().nullable(),
            value: Yup.string()
               .nullable()
               .when("key", {
                  is: (val) => val !== null && val !== undefined,
                  then: Yup.string().required(
                     "Please upload notification file"
                  ),
               }),
         })
         .test({
            name: "notification-file",
            message: "Please upload notification file",
            test: (value) =>
               !(
                  value.key !== null &&
                  value.key !== undefined &&
                  value.value === null
               ),
         })
   ),
   invoice_file_name: Yup.string()
      .nullable()
      .when("notifications", {
         is: (val) =>
            val.length > 0 &&
            !val.some(
               (notification: FormValues["notifications"][number]) =>
                  notification.key !== null &&
                  notification.key !== undefined &&
                  notification.value !== null
            ),
         then: Yup.string().required(
            "This field is required when there is not any notification"
         ),
      }),
   sdi_id: Yup.string()
      .nullable()
      .when("notifications", {
         is: (val) =>
            val.length > 0 &&
            !val.some(
               (notification: FormValues["notifications"][number]) =>
                  notification.key !== null &&
                  notification.key !== undefined &&
                  notification.value !== null
            ),
         then: Yup.string().required(
            "This field is required when there is not any notification"
         ),
      }),
})
const NOTIFICATION_TYPE = ["RC", "MC", "NS", "DT", "NE", "AT", "EC"]

type ImportInvoiceFormProps = {
   handleClose: () => void
   setErrors: (errors: Error[]) => void
   setMessage: (message: string | null) => void
   table: "supplier.invoices" | "customer.invoices"
}
const ImportInvoiceForm = ({
   handleClose,
   setErrors,
   setMessage,
   table,
}: ImportInvoiceFormProps) => {
   const { t } = useTranslation()
   const fileToBase64 = (file: File) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            const base64String = (reader.result as string)
               .replace("data:", "")
               .replace(/^.+,/, "")

            resolve(base64String)
         }
         reader.onerror = (error) => reject(error)
      })
   }

   const handleFileChosen = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
      setFieldValue: (
         field: string,
         value: any,
         shouldValidate?: boolean
      ) => Promise<void | FormikErrors<FormikValues>>
   ) => {
      const file = e.target && "files" in e.target ? e.target.files?.[0] : null
      if (file) {
         const base64 = await fileToBase64(file).then((result) => result)
         setFieldValue(field, base64)
         return
      }
      setFieldValue(field, field === "invoice" ? "" : null)
   }

   const genericError: Error = {
      propertyPath: "generic",
      message: "An error occurred",
   }
   const dispatch = useAppDispatch()

   const [importCustomerInvoice, { isLoading }] =
      useImportCustomerInvoiceMutation()

   const validationValues = (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      let isValid = true
      if (values.invoice === null) {
         setFieldError("invoice", "Please upload invoice file")
         isValid = false
      }

      if (values.invoice_file_name === "" && values.sdi_id === "") {
         values.notifications.map((notification, index) => {
            if (notification.value == null) {
               setFieldError(
                  `notifications.${index}.value`,
                  "Please upload notification file"
               )
               isValid = false
            }
            if (notification.key == null) {
               setFieldError(
                  `notifications.${index}.key`,
                  "Please select notification type"
               )
               isValid = false
            }
         })
      }
      return isValid
   }

   const formatNotifications = (values: FormValues) => {
      return values.notifications
         .filter(
            (notification) =>
               notification.key !== null &&
               notification.key !== undefined &&
               notification.value !== null &&
               notification.value !== undefined
         )
         .reduce((a, b) => {
            return Object.assign(a, {
               [b.key as string]: b.value,
            })
         }, {} as { [key in "RC" | "MC" | "NS" | "DT" | "NE" | "AT" | "EC"]?: string })
   }

   const handleCustomerInvoicesSave = async (
      values: FormValues,
      formikHelpers: FormikHelpers<FormValues>
   ) => {
      setErrors([])
      setMessage(null)
      const { setFieldError } = formikHelpers
      if (!validationValues(values, formikHelpers)) {
         return
      }

      const notifications = formatNotifications(values)

      const response = await importCustomerInvoice({
         invoice: values.invoice as string,
         ...(values.invoice_file_name !== "" && {
            invoice_file_name: values.invoice_file_name,
         }),
         ...(values.sdi_id !== "" && { sdi_id: values.sdi_id }),
         ...(notifications &&
            Object.entries(notifications).length > 0 && {
               notifications: notifications,
            }),
      })

      if ("error" in response && "data" in response.error) {
         // @ts-ignore
         if ("violations" in response.error.data) {
            const violations = response.error.data.violations as Error[]
            setErrors(violations)
            violations.map((violation) => {
               if (violation.propertyPath.startsWith("notifications")) {
                  const index = violation.propertyPath.split(".")[1]
                  setFieldError(
                     `notifications.${index}.${
                        violation.propertyPath.split(".")[2]
                     }`,
                     violation.message
                  )
               } else {
                  setFieldError(violation.propertyPath, violation.message)
               }
            })

            return
         }
         setErrors([genericError])
         return
      }

      if ("data" in response) {
         setMessage(
            `Your invoice has been import successfully ${response.data.uuid}`
         )
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
         return
      }
   }

   const initialValues: FormValues = {
      invoice: "",
      notifications: [
         {
            key: null,
            value: null,
         },
      ],
      invoice_file_name: "",
      sdi_id: "",
   }
   const classes = useStyles()
   const invoiceRef = useRef<HTMLInputElement>(null)

   const notificationRefs = useRef<HTMLInputElement[]>([])

   const resetFileField = (field: string) => {
      if (field === "invoice" && invoiceRef.current) {
         invoiceRef.current.value = ""
      }
      if (field.startsWith("notifications")) {
         const index = +field.split(".")[1] as number
         if (notificationRefs.current[index]) {
            notificationRefs.current[index].value = ""
         }
      }
   }
   return (
      <Formik
         initialValues={initialValues}
         onSubmit={handleCustomerInvoicesSave}
         validationSchema={schema}
         validateOnChange={false}
         validateOnBlur={false}
      >
         {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            getFieldMeta,
         }) => (
            <form onSubmit={handleSubmit} noValidate>
               <Grid container spacing={3}>
                  <Grid item md={6} xs={12} key={"invoice"}>
                     <Paper style={{ width: "100%" }}>
                        <Box p={2.5} style={{ width: "100%" }}>
                           <CustomInputFileField
                              name={"invoice"}
                              label={t(
                                 "CustomerInvoices.ImportInvoice.Upload file invoice"
                              )}
                              accept=".xml,.p7m"
                              invoiceRef={invoiceRef}
                              handleFileChosen={handleFileChosen}
                              getFieldMeta={getFieldMeta}
                              resetFileField={resetFileField}
                              setFieldValue={setFieldValue}
                           />
                        </Box>
                     </Paper>
                  </Grid>
                  <Grid item md={6} xs={12} key={"invoice-info"}>
                     <Paper style={{ width: "100%" }}>
                        <Grid item xs={12} style={{ padding: 10 }}>
                           <Grid item xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t(
                                    "CustomerInvoices.ImportInvoice.invoice_file_name"
                                 )}
                                 name="invoice_file_name"
                                 id="invoice_file_name"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 helperText={
                                    getFieldMeta("invoice_file_name").error
                                 }
                                 error={
                                    getFieldMeta("invoice_file_name").error !==
                                    undefined
                                 }
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t(
                                    "CustomerInvoices.ImportInvoice.sdi_id"
                                 )}
                                 name="sdi_id"
                                 id="sdi_id"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 helperText={getFieldMeta("sdi_id").error}
                                 error={
                                    getFieldMeta("sdi_id").error !== undefined
                                 }
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                     </Paper>
                  </Grid>
                  <Grid item xs={12} key={"notifications"}>
                     <FieldArray
                        validateOnChange={false}
                        name="notifications"
                        render={(arrayHelpers) => (
                           <Grid
                              container
                              spacing={3}
                              style={{
                                 width: "100%",
                              }}
                           >
                              <Grid item xs={12}>
                                 <Grid container spacing={3}>
                                    {(
                                       getFieldMeta("notifications")
                                          .value as FormValues["notifications"]
                                    )?.length > 0 &&
                                       (
                                          getFieldMeta("notifications")
                                             .value as FormValues["notifications"]
                                       ).map((_, index) => (
                                          <Grid item xs={12} md={6} key={index}>
                                             <StyledBadge
                                                badgeContent={
                                                   (
                                                      getFieldMeta(
                                                         "notifications"
                                                      )
                                                         .value as FormValues["notifications"]
                                                   )?.length > 1 && (
                                                      <RemoveCircleIcon
                                                         fontSize="medium"
                                                         style={{
                                                            cursor: "pointer",
                                                         }}
                                                         color="error"
                                                         onClick={() => {
                                                            resetFileField(
                                                               `notifications.${index}.value`
                                                            )
                                                            setFieldValue(
                                                               `notifications.${index}.value`,
                                                               null
                                                            )
                                                            arrayHelpers.remove(
                                                               index
                                                            )
                                                         }}
                                                      />
                                                   )
                                                }
                                                style={{
                                                   width: "100%",
                                                   position: "relative",
                                                }}
                                             >
                                                <Paper
                                                   style={{
                                                      width: "100%",
                                                      padding: 10,
                                                   }}
                                                >
                                                   <Grid
                                                      container
                                                      spacing={1}
                                                      key={index}
                                                   >
                                                      <Grid item xs={12}>
                                                         <Typography
                                                            variant="h6"
                                                            style={{
                                                               color: "black",
                                                               fontWeight:
                                                                  "bold",
                                                            }}
                                                         >
                                                            Notification -{" "}
                                                            {index + 1}.
                                                         </Typography>
                                                      </Grid>

                                                      <Grid item md={4} xs={12}>
                                                         <FormControl
                                                            fullWidth
                                                            component="fieldset"
                                                            className={
                                                               classes.formControl
                                                            }
                                                         >
                                                            <FormLabel
                                                               component="legend"
                                                               className={
                                                                  classes.formLabel
                                                               }
                                                               htmlFor={`notifications.${index}.key`}
                                                               style={{
                                                                  marginBottom: 6,
                                                               }}
                                                            >
                                                               {t(
                                                                  "CustomerInvoices.ImportInvoice.notification_type"
                                                               )}
                                                            </FormLabel>
                                                            <Select
                                                               id={`notifications.${index}.key`}
                                                               name={`notifications.${index}.key`}
                                                               value={
                                                                  getFieldMeta(
                                                                     `notifications.${index}.key`
                                                                  ).value
                                                               }
                                                               onBlur={
                                                                  handleBlur
                                                               }
                                                               onChange={(
                                                                  e
                                                               ) => {
                                                                  if (
                                                                     e.target
                                                                        .value ===
                                                                     undefined
                                                                  ) {
                                                                     e.target.value =
                                                                        null
                                                                  }
                                                                  handleChange(
                                                                     e
                                                                  )
                                                               }}
                                                               error={
                                                                  getFieldMeta(
                                                                     `notifications.${index}.key`
                                                                  ).error !==
                                                                  undefined
                                                               }
                                                               fullWidth
                                                            >
                                                               <MenuItem
                                                                  value={
                                                                     undefined
                                                                  }
                                                               >
                                                                  <LabelYesNo
                                                                     value={
                                                                        null
                                                                     }
                                                                  />
                                                               </MenuItem>
                                                               {NOTIFICATION_TYPE.map(
                                                                  (type) => (
                                                                     <MenuItem
                                                                        value={
                                                                           type
                                                                        }
                                                                     >
                                                                        {type}
                                                                     </MenuItem>
                                                                  )
                                                               )}
                                                            </Select>
                                                            {getFieldMeta(
                                                               `notifications.${index}.key`
                                                            ).error !==
                                                               undefined && (
                                                               <FormHelperText
                                                                  error={
                                                                     getFieldMeta(
                                                                        `notifications.${index}.key`
                                                                     ).error !==
                                                                     undefined
                                                                  }
                                                               >
                                                                  {
                                                                     getFieldMeta(
                                                                        `notifications.${index}.key`
                                                                     ).error
                                                                  }
                                                               </FormHelperText>
                                                            )}
                                                         </FormControl>
                                                      </Grid>
                                                      <Grid item md={7} xs={12}>
                                                         <CustomInputFileField
                                                            name={`notifications.${index}.value`}
                                                            label={t(
                                                               "CustomerInvoices.ImportInvoice.Upload file notifica"
                                                            )}
                                                            accept=".xml"
                                                            invoiceRef={(el) =>
                                                               (notificationRefs.current[
                                                                  index
                                                               ] =
                                                                  el) as RefObject<HTMLInputElement>
                                                            }
                                                            handleFileChosen={
                                                               handleFileChosen
                                                            }
                                                            getFieldMeta={
                                                               getFieldMeta
                                                            }
                                                            resetFileField={
                                                               resetFileField
                                                            }
                                                            setFieldValue={
                                                               setFieldValue
                                                            }
                                                         />
                                                      </Grid>
                                                   </Grid>
                                                </Paper>
                                             </StyledBadge>
                                          </Grid>
                                       ))}
                                 </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() =>
                                       arrayHelpers.push({
                                          key: null,
                                          value: null,
                                       })
                                    }
                                 >
                                    {t(
                                       "CustomerInvoices.ImportInvoice.Add notification"
                                    )}
                                 </Button>
                              </Grid>
                           </Grid>
                        )}
                     />
                  </Grid>
               </Grid>
               <DialogActions>
                  <div className={classes.buttons}>
                     <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isLoading}
                     >
                        {t("Default.Invia")}
                     </Button>
                     <Button
                        onClick={handleClose}
                        color="default"
                        variant="contained"
                     >
                        {t("Default.Annulla")}
                     </Button>
                  </div>
               </DialogActions>
            </form>
         )}
      </Formik>
   )
}

const StyledBadge = withStyles((theme: Theme) =>
   createStyles({
      badge: {
         right: 0,
         top: 3,
         border: `2px solid ${theme.palette.background.paper}`,
      },
   })
)(Badge)

export default ImportInvoiceForm
