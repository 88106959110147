import { GridSize, InputBaseComponentProps } from "@material-ui/core"
import {
   CreateSmartReceiptBody,
   CreateSmartReceiptIem,
} from "services/gov-it/smartReceipt/type"
import * as Yup from "yup"

export type SmartReceiptCreateFormValue = CreateSmartReceiptBody

export const SmartReceiptCreateFormInitialValues: SmartReceiptCreateFormValue =
   {
      fiscal_id: "",
      lottery_code: "",
      items: [
         {
            quantity: "1.00",
            description: "",
            unit_price: "0.00",
            vat_rate_code: "4",
            discount: null,
            complimentary: null,
         },
      ],
      invoice_issuing: null,
      services_uncollected_amount: null,
      goods_uncollected_amount: null,
      cash_payment_amount: null,
      electronic_payment_amount: null,
      ticket_restaurant_quantity: null,
      ticket_restaurant_payment_amount: null,
      discount: null,
      linked_receipt: null,
   }

export const SmartReceiptValidationSchema = Yup.object().shape({
   fiscal_id: Yup.string().required("This field is required"),
   lottery_code: Yup.string()
      .nullable()
      .test(
         "has-8-characters",
         "This field must have 8 characters",
         (value) => {
            if (!value) return true
            return value.length === 8
         }
      ),
   items: Yup.array().of(
      Yup.object().shape({
         quantity: Yup.string()
            .required("This field is required")
            .test(
               "is-decimal",
               "This field must be a decimal and have at most 2 decimal places",
               (value) => {
                  if (!value) return false
                  return value.match(/^\d+(\.\d{1,2})?$/) !== null
               }
            ),
         description: Yup.string().required("This field is required"),
         unit_price: Yup.string()
            .required("This field is required")
            .test(
               "is-decimal",
               "This field must be a decimal and have at most 8 decimal places",
               (value) => {
                  if (!value) return false
                  return value.match(/^\d+(\.\d{1,8})?$/) !== null
               }
            ),
         vat_rate_code: Yup.string().required("This field is required"),
         discount: Yup.string().nullable(),
         complimentary: Yup.boolean().nullable(),
      })
   ),
   invoice_issuing: Yup.boolean().nullable(),
   services_uncollected_amount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 2 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,2})?$/) !== null
         }
      ),
   goods_uncollected_amount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 2 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,2})?$/) !== null
         }
      ),
   cash_payment_amount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 2 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,2})?$/) !== null
         }
      ),
   electronic_payment_amount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 2 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,2})?$/) !== null
         }
      ),
   ticket_restaurant_quantity: Yup.string()
      .nullable()
      .test("is_integer", "This field must be an integer", (value) => {
         if (!value) return true
         return value.match(/^\d+$/) !== null
      }),
   ticket_restaurant_payment_amount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 2 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,2})?$/) !== null
         }
      ),
   discount: Yup.string()
      .nullable()
      .test(
         "is-decimal",
         "This field must be a decimal and have at most 8 decimal places",
         (value) => {
            if (!value) return true
            return value.match(/^\d+(\.\d{1,8})?$/) !== null
         }
      ),
   linked_receipt: Yup.string().nullable(),
})

export const getItemEmpty = () =>
   ({
      quantity: "1.00",
      description: "",
      unit_price: "0.00",
      vat_rate_code: "4",
      discount: null,
      complimentary: null,
   } as CreateSmartReceiptIem)

export type SectionType = {
   name: string
   label: string
   width?: {
      xs?: GridSize
      md: GridSize
   }
   inputProps?: InputBaseComponentProps
   required?: boolean
} & (
   | {
        type: "textField" | "number" | "checkbox" | "selectAsync"
     }
   | {
        type: "select"
        options?: {
           value: string
           label: string
        }[]
     }
) &
   (
      | {
           format?: "number"
           decimalPlace?: true
        }
      | { format?: "integer" }
      | {
           format?: "uppercase"
        }
   )

export const section1: SectionType[] = [
   {
      name: "fiscal_id",
      label: "smartReceipt.fiscalId",
      type: "selectAsync",
      format: "uppercase",
      required: true,
      width: { xs: 12, md: 6 },
   },
   {
      name: "lottery_code",
      label: "smartReceipt.lottery_code",
      type: "textField",
      width: { xs: 12, md: 6 },
   },
   {
      name: "invoice_issuing",
      label: "smartReceipt.invoice_issuing",
      type: "checkbox",
      width: { xs: 12, md: 12 },
   },
]

export const sectionItemsVariable: SectionType[] = [
   {
      name: "quantity",
      label: "smartReceipt.quantity",
      type: "textField",
      format: "number",
      required: true,
   },
   {
      name: "unit_price",
      label: "smartReceipt.unit_price",
      type: "textField",
      format: "number",
      decimalPlace: true,
      required: true,
   },
   {
      name: "vat_rate_code",
      label: "smartReceipt.vat_rate_code",
      type: "select",
      required: true,
      options: [
         { value: "4", label: "4" },
         { value: "5", label: "5" },
         { value: "10", label: "10" },
         { value: "22", label: "22" },
         { value: "2", label: "2" },
         { value: "6.4", label: "6.4" },
         { value: "7", label: "7" },
         { value: "7.3", label: "7.3" },
         { value: "7.5", label: "7.5" },
         { value: "7.65", label: "7.65" },
         { value: "7.95", label: "7.95" },
         { value: "8.3", label: "8.3" },
         { value: "8.5", label: "8.5" },
         { value: "8.8", label: "8.8" },
         { value: "9.5", label: "9.5" },
         { value: "12.3", label: "12.3" },
         { value: "N1", label: "N1" },
         { value: "N2", label: "N2" },
         { value: "N3", label: "N3" },
         { value: "N4", label: "N4" },
         { value: "N5", label: "N5" },
         { value: "N6", label: "N6" },
      ],
   },
   {
      name: "discount",
      label: "smartReceipt.discount",
      type: "textField",
      format: "number",
      decimalPlace: true,
   },
   {
      name: "complimentary",
      label: "smartReceipt.complimentary",
      type: "checkbox",
   },
   {
      name: "description",
      label: "smartReceipt.description",
      type: "textField",
      required: true,
      width: { xs: 12, md: 12 },
   },
]

export const section2: SectionType[] = [
   {
      name: "services_uncollected_amount",
      label: "smartReceipt.services_uncollected_amount",
      type: "textField",
      format: "number",

      width: { md: 4 },
   },
   {
      name: "goods_uncollected_amount",
      label: "smartReceipt.goods_uncollected_amount",
      type: "textField",
      format: "number",

      width: { md: 4 },
   },
   {
      name: "cash_payment_amount",
      label: "smartReceipt.cash_payment_amount",
      type: "textField",
      format: "number",

      width: { md: 4 },
   },
   {
      name: "electronic_payment_amount",
      label: "smartReceipt.electronic_payment_amount",
      type: "textField",
      format: "number",

      width: { md: 4 },
   },
   {
      name: "ticket_restaurant_payment_amount",
      label: "smartReceipt.ticket_restaurant_payment_amount",
      type: "textField",
      format: "number",
      decimalPlace: true,
      width: { md: 4 },
   },
   {
      name: "ticket_restaurant_quantity",
      label: "smartReceipt.ticket_restaurant_quantity",
      type: "number",
      format: "integer",
      width: { md: 4 },
   },
   {
      name: "discount",
      label: "smartReceipt.discount",
      type: "textField",
      format: "number",
      decimalPlace: true,
      width: { md: 3 },
   },
   {
      name: "linked_receipt",
      label: "smartReceipt.linked_receipt",
      type: "textField",
      width: { md: 9 },
   },
]
