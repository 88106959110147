import { DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useUpdateWebhookMutation } from "services/openbankingApi/webhooks"
import { DialogState } from "store/slices/dialogs"
import * as Yup from "yup"
import EditWebhookForm, {
   FormValues,
   mapFormValuesToOpenbankingWebhook,
   mapOpenbankingWebhookToFormValues,
} from "../EditWebhookForm"

const DIALOG_ID = "openbanking_edit_webhook"

const validationSchema = Yup.object().shape({
   event: Yup.string().required(),
   targetUrl: Yup.string().required(),
})

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, ...webhook }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updateWebhook] = useUpdateWebhookMutation()

   const initialValues = mapOpenbankingWebhookToFormValues(webhook)

   const handleSubmit = async (values: FormValues) => {
      const response = await updateWebhook({
         uuid: webhook.uuid,
         ...mapFormValuesToOpenbankingWebhook(values),
      })

      if ("data" in response) {
         enqueueSnackbar(t("openbanking.webhooks.messages.webhook_updated"), {
            variant: "success",
         })
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>
            {t("openbanking.webhooks.edit_webhook_dialog_title", {
               uuid: webhook.uuid,
            })}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditWebhookForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const EditWebhookDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default EditWebhookDialog
