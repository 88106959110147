import { createApi } from "@reduxjs/toolkit/query/react"
import { buildSearchParams } from "helper/buildSearchParams"
import { baseQuery } from ".."

export type ImportDraftFormValues = {
   content: string
   path: string
   project: string
}

export type ListDirParams = {
   bucket?: string
   prefix?: string
   project: "it"
}

export type ListDirResponse = {
   root_dir: string
   dirs: string[]
}

export const transferApi = createApi({
   reducerPath: "transferApi",
   tagTypes: ["dirs"],
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_TRANSFER_BASE_URL as string,
   }),
   endpoints: (builder) => ({
      importDraftInvoice: builder.mutation<void, ImportDraftFormValues>({
         query: ({ project, ...body }) => ({
            url: `${project}/upload`,
            method: "POST",
            headers: {
               "content-type": "application/json",
            },
            body,
         }),
      }),

      getListDirs: builder.query<ListDirResponse, ListDirParams>({
         query: ({ project, ...params }) => ({
            url: `${project}/dirs${buildSearchParams(
               params as Record<string, string | string[] | number>
            )}`,
         }),
         providesTags: ["dirs"],
      }),
   }),
})

export const {
   useImportDraftInvoiceMutation,
   useGetListDirsQuery,
   useLazyGetListDirsQuery,
} = transferApi
