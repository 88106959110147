// Old methods, commented out since there could be edge cases where the sender and recipient data
// is inconsistent (i.e. when they change name but retain PIVA or VAT)

// function getSender(row) {
//   try {
//     return row.sender ? (row.sender.business_name ? row.sender.business_name : row.sender.name + " " + row.sender.surname) : ""
//   } catch (e) {
//   }
// }

// function getRecipient(row) {
//   try {
//     return row.recipient ? (row.recipient.business_name ? row.recipient.business_name : row.recipient.name + " " + row.recipient.surname) : ""
//   } catch (e) {
//   }
// }

function isFSM(row) {
   return row?.transmission_format === "FSM10"
}

function getSender(row, invoicePayload) {
   try {
      if (isFSM(row)) {
         const registryData =
            invoicePayload.fattura_elettronica_header.cedente_prestatore
         return (
            registryData.denominazione ||
            `${registryData.nome} ${registryData.cognome}`
         )
      } else {
         const registryData =
            invoicePayload.fattura_elettronica_header.cedente_prestatore
               .dati_anagrafici.anagrafica
         return (
            registryData.denominazione ||
            `${registryData.nome} ${registryData.cognome}`
         )
      }
   } catch (e) {
      return " - "
   }
}

function getRecipient(row, invoicePayload) {
   try {
      if (isFSM(row)) {
         const registryData =
            invoicePayload.fattura_elettronica_header.cessionario_committente
         if (typeof registryData.altri_dati_identificativi !== "undefined") {
            return (
               registryData.altri_dati_identificativi.denominazione ||
               `${registryData.altri_dati_identificativi.nome} ${registryData.altri_dati_identificativi.cognome}`
            )
         } else {
            return (
               registryData.identificativi_fiscali.id_fiscale_iva.id_codice ||
               registryData.identificativi_fiscali.codice_fiscale
            )
         }
      } else {
         const registryData =
            invoicePayload.fattura_elettronica_header.cessionario_committente
               .dati_anagrafici.anagrafica
         return (
            registryData.denominazione ||
            `${registryData.nome} ${registryData.cognome}`
         )
      }
   } catch (e) {
      return " - "
   }
}

function getInvoiceDate(payload) {
   try {
      return payload?.fattura_elettronica_body["0"].dati_generali
         .dati_generali_documento.data
   } catch (e) {}
}

function getInvoiceNumber(payload) {
   try {
      return payload?.fattura_elettronica_body["0"].dati_generali
         .dati_generali_documento.numero
   } catch (e) {}
}

export default function formatInvoice(row) {
   let payload
   try {
      payload = JSON.parse(row.payload)
   } catch {
      payload = null
   }

   const recipient =
      payload?.fattura_elettronica_header?.cessionario_committente
   const recipientData = isFSM(row)
      ? recipient?.identificativi_fiscali
      : recipient?.dati_anagrafici

   const recipientVatNumberFormatted =
      recipientData?.id_fiscale_iva?.id_codice ??
      recipientData?.codice_fiscale ??
      row.recipient?.business_fiscal_code ??
      "-"

   const sender = payload?.fattura_elettronica_header?.cedente_prestatore
   const senderData = isFSM(row) ? sender : sender?.dati_anagrafici
   const senderVatNumberFormatted =
      senderData?.id_fiscale_iva?.id_codice ?? senderData?.codice_fiscale

   const servicesData = payload?.fattura_elettronica_body[0]?.dati_beni_servizi
   const invoiceTotal = servicesData
      ? ("dati_riepilogo" in servicesData
           ? servicesData?.dati_riepilogo?.map(
                ({ imposta, imponibile_importo }) => {
                   const impostaValue = imposta
                      ? Number(imposta.replace(",", "."))
                      : 0
                   const imponibileValue = imponibile_importo
                      ? Number(imponibile_importo.replace(",", "."))
                      : 0
                   return impostaValue + imponibileValue
                }
             )
           : servicesData?.map(({ importo, dati_iva }) => {
                const importoValue = importo
                   ? Number(importo.replace(",", "."))
                   : 0
                const datiIvaValue = dati_iva?.aliquota
                   ? Number(dati_iva.aliquota.replace(",", "."))
                   : 0
                return importoValue
             })
        )
           ?.reduce((acc, value) => acc + value, 0)
           ?.toFixed(2) ?? "--"
      : "--"

   return {
      ...row,
      invoiceTotal,
      senderFormatted: sender
         ? getSender(row, payload)
         : row.sender?.business_name ?? "-",
      senderVatNumberFormatted,
      recipientFormatted: recipient
         ? getRecipient(row, payload)
         : row.recipient?.business_name ?? "-",
      recipientVatNumberFormatted,
      invoiceDate: getInvoiceDate(payload),
      invoiceNumber: getInvoiceNumber(payload),
   }
}
