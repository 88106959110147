export function formatReceipt(row) {
   return {
      protocol: row.protocol,
      expense_id: row.expense_id,
      url: row.url,
      date: row.date,
      description: row.description,
      outcome: row.outcome,
      operation_type: row.operation_type,
   }
}
