import {
   Button,
   ButtonGroup,
   Divider,
   ListItemIcon,
   ListItemText,
   makeStyles,
   Menu,
   MenuItem,
} from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import FlagIcon from "@material-ui/icons/Flag"
import { CustomCircularProgress } from "components/CustomCircularProgress"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSetDownloadedFlagInvoiceMutation } from "services/gov-it"
import { useAppDispatch, useAppSelector } from "store"
import {
   selectTableCheckedColumns,
   TableName,
   updateData,
   updateRefetch,
} from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"

type CheckedActionsButtonsProps = {
   table: TableName
}

const CheckedActionsButtons = ({ table }: CheckedActionsButtonsProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   const classes = useStyles()
   const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(
      null
   )
   const isMenuOpen = Boolean(anchorEl)

   const [setDownloadedFlagInvoice, { isLoading, error }] =
      useSetDownloadedFlagInvoiceMutation()

   const onSubmit = async (downloaded: boolean) => {
      if (checkedColumns.length === 0) {
         enqueueSnackbar(t("global.nothing_selected"), { variant: "warning" })
         return
      }
      await setDownloadedFlagInvoice({
         uuids: checkedColumns,
         downloaded,
      })

      if (!error) {
         enqueueSnackbar(
            downloaded
               ? t("global.set_as_downloaded_success")
               : t("global.set_as_not_downloaded_success"),
            { variant: "success" }
         )
         updateTable()
      }
   }

   const handleButtonGroupMenuOpen = (event: any) => {
      setAnchorEl(event.currentTarget)
   }
   const handleButtonGroupMenuClose = () => {
      setAnchorEl(null)
   }
   const updateTable = () => {
      persistTableRefresh(table)
      dispatch(
         updateRefetch({
            table,
         })
      )

      dispatch(
         updateData({
            table: table,
            param: "checkedColumns",
            value: [],
         })
      )
   }

   return (
      <>
         <ButtonGroup fullWidth>
            <Tooltip title={t("global.set_as")}>
               <Button
                  style={{ width: "max-content" }}
                  disabled={isLoading}
                  aria-label="set as downloaded"
                  aria-controls="set-as-downloaded"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleButtonGroupMenuOpen}
                  className={classes.menuButton}
                  startIcon={
                     isLoading ? (
                        <CustomCircularProgress size={20} />
                     ) : (
                        <FlagIcon />
                     )
                  }
                  component="button"
               >
                  {isLoading ? t("global.set_loading") : t("global.set_as")}
               </Button>
            </Tooltip>
         </ButtonGroup>
         <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id="set-as-downloaded"
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleButtonGroupMenuClose}
         >
            <MenuItem
               key={"set_as_downloaded"}
               disabled={isLoading}
               onClick={() => {
                  onSubmit(true)
                  handleButtonGroupMenuClose()
               }}
            >
               <ListItemIcon>
                  {isLoading ? (
                     <CustomCircularProgress size={20} />
                  ) : (
                     <FlagIcon />
                  )}
               </ListItemIcon>
               <ListItemText
                  primary={
                     isLoading
                        ? t("global.set_loading")
                        : t("global.set_as_downloaded")
                  }
               />
            </MenuItem>

            <Divider />

            <MenuItem
               disabled={isLoading}
               key={"set_as_not_downloaded"}
               onClick={() => {
                  onSubmit(false)
                  handleButtonGroupMenuClose()
               }}
            >
               <ListItemIcon>
                  {isLoading ? (
                     <CustomCircularProgress size={20} />
                  ) : (
                     <FlagIcon />
                  )}
               </ListItemIcon>
               <ListItemText
                  primary={
                     isLoading
                        ? t("global.set_loading")
                        : t("global.set_as_not_downloaded")
                  }
               />
            </MenuItem>
         </Menu>
      </>
   )
}

export default CheckedActionsButtons

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))
