import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { formatPrice } from "helper/numberFormater"
import React from "react"
import { useTranslation } from "react-i18next"
import LabelYesNo from "../LabelYesNo"

const useRowStyles = makeStyles({
   root: {
      "& > *": {
         borderBottom: "unset",
      },
   },
})

export type FormatPriceType = "price" | "boolean" | "priceStrict"
type FieldsType<T> = {
   name: keyof T
   label: string
   format?: FormatPriceType
}

type TableWithAccordionProps<T> = {
   data: T[]
   primaryFields: FieldsType<T>[]
   secondaryFields: FieldsType<T>[]
   keyField: keyof T
}

type RowProps<T> = {
   row: T
   primaryFields: FieldsType<T>[]
   secondaryFields: FieldsType<T>[]
}
const Row = <T,>({ row, primaryFields, secondaryFields }: RowProps<T>) => {
   const [open, setOpen] = React.useState(false)
   const classes = useRowStyles()
   const { t, i18n } = useTranslation()

   const getValueFormat = (value: any, format?: FormatPriceType) => {
      switch (format) {
         case "price":
            return formatPrice(i18n.language, value, undefined, true)
         case "priceStrict":
            return formatPrice(i18n.language, value, undefined, false)
         case "boolean":
            return <LabelYesNo value={value} />
         default:
            return value
      }
   }

   const getColorFormat = (value: any, format?: FormatPriceType) => {
      switch (format) {
         case "price":
         case "priceStrict":
            return value < 0 ? "red" : value > 0 ? "green" : "black"
         default:
            return "black"
      }
   }

   const getFontWeightFormat = (value: any, format?: FormatPriceType) => {
      switch (format) {
         case "price":
         case "priceStrict":
            return "bold"
         default:
            return "normal"
      }
   }

   return (
      <React.Fragment>
         <TableRow className={classes.root}>
            <TableCell>
               <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
               >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
               </IconButton>
            </TableCell>
            {primaryFields.map((field, index) => (
               <TableCell
                  key={field.name as string}
                  component="th"
                  scope="row"
                  style={{
                     fontWeight: getFontWeightFormat(
                        row[field.name],
                        field.format
                     ),
                     color: getColorFormat(row[field.name], field.format),
                  }}
               >
                  {getValueFormat(row[field.name], field.format)}
               </TableCell>
            ))}
         </TableRow>
         <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
               <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                     <Typography variant="h5" gutterBottom component="div">
                        {t("smartReceipt.details")}
                     </Typography>
                     <Table aria-label="purchases">
                        <TableHead>
                           <TableRow>
                              {secondaryFields.map((field, index) => (
                                 <TableCell
                                    key={field.name as string}
                                    {...(index === 0
                                       ? {}
                                       : {
                                            align: "right",
                                         })}
                                 >
                                    {field.label}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              {secondaryFields.map((field, index) => (
                                 <TableCell
                                    key={field.name as string}
                                    {...(index === 0
                                       ? { component: "th", scope: "row" }
                                       : {
                                            align: "right",
                                         })}
                                    style={{
                                       fontWeight: getFontWeightFormat(
                                          row[field.name],
                                          field.format
                                       ),
                                       color: getColorFormat(
                                          row[field.name],
                                          field.format
                                       ),
                                    }}
                                 >
                                    {getValueFormat(
                                       row[field.name],
                                       field.format
                                    )}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableBody>
                     </Table>
                  </Box>
               </Collapse>
            </TableCell>
         </TableRow>
      </React.Fragment>
   )
}

const TableWithAccordion = <T,>({
   data,
   primaryFields,
   secondaryFields,
   keyField,
}: TableWithAccordionProps<T>) => {
   return (
      <TableContainer component={Paper}>
         <Table aria-label="collapsible table">
            <TableHead>
               <TableRow>
                  <TableCell />
                  {primaryFields.map((field) => (
                     <TableCell key={field.name as string}>
                        {field.label}
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {data.map((row) => (
                  <Row<typeof row>
                     key={row[keyField] as string}
                     row={row}
                     primaryFields={primaryFields}
                     secondaryFields={secondaryFields}
                  />
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   )
}

export default TableWithAccordion
