import { Button, Grid, makeStyles, Select } from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionActions from "@material-ui/core/AccordionActions"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { formatDate } from "services/dates"
import DatePicker from "views/common/DatePicker"
import LabelYesNo from "./LabelYesNo"

const useStyles = makeStyles((theme) => ({
   root: {},
   button: {
      marginBottom: 12,
      marginRight: 12,
   },
}))

function DespatchAdvicesFilters(props, { className, ...rest }) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values,
      { resetForm, setErrors, setSubmitting }
   ) => {
      values = {
         ...values,
         // type: props.type,
      }

      // if (values.uuid) {
      //   // props.findInvoice(values.uuid);
      //   return;
      // }

      await props.applyFilters(values)
   }

   return (
      <Formik initialValues={props.filtersValues} onSubmit={handleSubmit}>
         {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            touched,
            errors,
         }) => {
            return (
               <form
                  onSubmit={handleSubmit}
                  noValidate
                  className={clsx(classes.root, className)}
               >
                  <Accordion
                     elevation={1}
                     defaultExpanded={props.expandFilters}
                  >
                     <Grid container alignItems={"center"}>
                        {props.actions && (
                           <Grid item xs={12} sm={3}>
                              {props.actions}
                           </Grid>
                        )}
                        <Grid item xs>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1c-content"
                              id="panel1c-header"
                           >
                              <div className={classes.column}>
                                 <Typography
                                    className={classes.secondaryHeading}
                                 >
                                    {t("Default.Filtra i risultati")}{" "}
                                    {t("DespatchAdvices.Filters.dateRange", {
                                       from: formatDate(
                                          props.filtersValues.createdAt.after
                                       ),
                                       to: formatDate(
                                          props.filtersValues.createdAt.before
                                       ),
                                    })}
                                 </Typography>
                              </div>
                           </AccordionSummary>
                        </Grid>
                     </Grid>

                     <AccordionDetails className={classes.details}>
                        <Grid container spacing={3}>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 disabled={values.uuid}
                                 id="createdAt.after"
                                 name="createdAt.after"
                                 label={t(
                                    "DespatchAdvices.Filters.createdAt[after]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 autoSelectBeginningOfTheDay={true}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 disabled={values.uuid}
                                 id="createdAt.before"
                                 name="createdAt.before"
                                 label={t(
                                    "DespatchAdvices.Filters.createdAt[before]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 autoSelectEndOfTheDay={true}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={6} xs={12}>
                              <Field
                                 component={TextField}
                                 type="text"
                                 label={t("DespatchAdvices.Filters.uuid")}
                                 name="uuid"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>

                     <AccordionDetails className={classes.details}>
                        <Grid container spacing={3}>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 disabled={values.uuid}
                                 type="text"
                                 label={t("DespatchAdvices.Filters.senderName")}
                                 name="~senderName"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 disabled={values.uuid}
                                 type="text"
                                 label={t(
                                    "DespatchAdvices.Filters.recipientName"
                                 )}
                                 name="~recipientName"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 disabled={values.uuid}
                                 type="text"
                                 label={t("DespatchAdvices.Filters.senderId")}
                                 name="senderId"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Field
                                 component={TextField}
                                 disabled={values.uuid}
                                 type="text"
                                 label={t(
                                    "DespatchAdvices.Filters.recipientId"
                                 )}
                                 name="recipientId"
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                        </Grid>
                     </AccordionDetails>

                     <AccordionDetails className={classes.details}>
                        <Grid container spacing={3}>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 disabled={values.uuid}
                                 id="documentDate.after"
                                 name="documentDate.after"
                                 label={t(
                                    "DespatchAdvices.Filters.orderDate[after]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <DatePicker
                                 disabled={values.uuid}
                                 id="documentDate.before"
                                 name="documentDate.before"
                                 label={t(
                                    "DespatchAdvices.Filters.orderDate[before]"
                                 )}
                                 onBlur={handleBlur}
                                 onChange={handleChange}
                                 fullWidth={true}
                              />
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <Tooltip
                                 title={t(
                                    "DespatchAdvices.Filters.orderNumberSuggestion"
                                 )}
                                 placement="bottom"
                              >
                                 <div>
                                    <Field
                                       component={TextField}
                                       disabled={
                                          (values.documentDate.after === null &&
                                             values.documentDate.before ===
                                                null) ||
                                          values.uuid
                                       }
                                       type="text"
                                       label={t(
                                          "DespatchAdvices.Filters.orderNumber"
                                       )}
                                       name="documentNumber"
                                       onBlur={handleBlur}
                                       onChange={handleChange}
                                       fullWidth={true}
                                    />
                                 </div>
                              </Tooltip>
                           </Grid>
                           <Grid item md={3} xs={12}>
                              <FormControl fullWidth>
                                 <InputLabel htmlFor="downloaded">
                                    {t("InvoiceFilters.downloaded")}
                                 </InputLabel>
                                 <Select
                                    disabled={values.uuid !== ""}
                                    name="downloaded"
                                    onBlur={handleBlur}
                                    value={values.downloaded}
                                    onChange={(e) => {
                                       if (e.target.value === undefined) {
                                          e.target.value = null
                                       }
                                       handleChange(e)
                                    }}
                                    fullWidth
                                 >
                                    <MenuItem value={undefined}>
                                       <LabelYesNo value={null} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                       <LabelYesNo value={true} />
                                    </MenuItem>
                                    <MenuItem value={0}>
                                       <LabelYesNo value={false} />
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           </Grid>
                        </Grid>
                     </AccordionDetails>

                     <AccordionActions>
                        <Button
                           className={classes.button}
                           onClick={() => {
                              resetForm()
                              props.applyFilters({})
                           }}
                           variant="contained"
                        >
                           {t("Default.Cancella")}
                        </Button>
                        <Button
                           className={classes.button}
                           type="submit"
                           color="primary"
                           variant="contained"
                        >
                           {t("Default.Cerca")}
                        </Button>
                     </AccordionActions>
                  </Accordion>
               </form>
            )
         }}
      </Formik>
   )
}

export default DespatchAdvicesFilters
