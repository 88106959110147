import { fade, makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
   root: {
      borderRadius: 2,
      display: "inline-flex",
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1, 1),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: fade(theme.palette.warning.main, 0.08),
   },
}))

export const WarningTypography = ({ children }) => {
   const classes = useStyles()
   const color = "warning"

   return (
      <Typography
         className={clsx(classes.root, {
            [classes[color]]: color,
         })}
      >
         {children}
      </Typography>
   )
}
