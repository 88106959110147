import { useTranslation } from "react-i18next"
import Label from "./Label"

export const LabelOutcome = (props) => {
   const { t } = useTranslation()

   let color = "primary"
   let value = ""

   if (props.value === 0) {
      color = "success"
      value = t("LabelOutcome.SUCCESS")
   }
   if (props.value === 1) {
      color = "error"
      value = t("LabelOutcome.ERROR")
   }
   if (props.value === 2) {
      color = "warning"
      value = t("LabelOutcome.WARNING")
   }

   return <Label color={color}>{value}</Label>
}
