import { Box } from "@material-ui/core"
import { TableName } from "store/tablesState"
import CheckedActionsButtons from "views/gov-it/components/ActionsCheckedFooterTableButtons/components/CheckedActionsButtons"

type ActionsCheckedFooterTableButtonsType = {
   table: TableName
}
const ActionsCheckedFooterTableButtons = ({
   table,
}: ActionsCheckedFooterTableButtonsType) => {
   return (
      <Box
         style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
         }}
      >
         <CheckedActionsButtons table={table} />
      </Box>
   )
}

export default ActionsCheckedFooterTableButtons
