import {
   Box,
   Button,
   Card,
   CardContent,
   CardHeader,
   Divider,
   Grid,
   makeStyles,
} from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { useSnackbar } from "notistack"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectAuthToken } from "store/auth"
import { setProfile } from "../../services/client/request/common/setProfile"
import { ChangePasswordButton } from "../common/ChangePassword/ChangePasswordButton"
import ProfileDialog from "./ProfileDialog"

const useStyles = makeStyles((theme) => ({
   root: {},
}))

ChangePasswordButton.propTypes = {
   onClick: PropTypes.func,
   t: PropTypes.any,
}
const ProfileDetails = (props, { className, ...rest }) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const [error, setError] = React.useState(false)
   const { enqueueSnackbar } = useSnackbar()
   const [isLoading, setIsLoading] = React.useState(false)
   const [isOpenDialog, setOpenDialog] = React.useState(false)

   const handleOpenDialog = () => {
      setOpenDialog(true)
   }

   const handleCloseDialog = () => {
      setOpenDialog(false)
   }

   const handleSave = async (values) => {
      setIsLoading(true)
      setError(false)

      try {
         await setProfile(token, values)
         handleCloseDialog()
         props.incrementCounter()
      } catch (e) {
         setError(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      setIsLoading(false)
   }

   return (
      <Card>
         <CardHeader title={t("User.Il mio profilo")} />

         {error ? (
            <></>
         ) : (
            <CardContent>
               <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                     <List className={classes.root}>
                        <ListItem>
                           <ListItemText primary={t("User.fields.email")} />
                           <ListItemSecondaryAction>
                              <ListItemText primary={props.profile.email} />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText primary={t("User.fields.fullname")} />
                           <ListItemSecondaryAction>
                              <ListItemText primary={props.profile.fullname} />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_vat_number")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_vat_number}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_fiscal_id")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_fiscal_id}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_address")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_address}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_city")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_city}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_province")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_province}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_cap")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_cap}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.invoicing_name")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.invoicing_name}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                           <ListItemText
                              primary={t("User.fields.statistics_scope")}
                           />
                           <ListItemSecondaryAction>
                              <ListItemText
                                 primary={props.profile.statistics_scope}
                              />
                           </ListItemSecondaryAction>
                        </ListItem>
                     </List>
                  </Grid>
               </Grid>
            </CardContent>
         )}

         <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
               color="primary"
               variant="contained"
               onClick={handleOpenDialog}
            >
               {t("Default.Modifica")}
            </Button>
            <Divider orientation="vertical" />
            <ChangePasswordButton text={t("User.Modifica Password")} />
         </Box>

         {isOpenDialog && (
            <ProfileDialog
               isOpen={isOpenDialog}
               isLoading={isLoading}
               handleClose={handleCloseDialog}
               handleSave={handleSave}
            />
         )}
      </Card>
   )
}

ProfileDetails.propTypes = {
   className: PropTypes.string,
}

export default ProfileDetails
