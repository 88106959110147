import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "store"

type State = {
   nip1: string | null
}

const initialState: State = {
   nip1: null,
}

export const invoicesDemoSlice = createSlice({
   name: "ui",
   initialState,
   reducers: {
      setInvoicesDemoNip1: (state, { payload }: PayloadAction<string>) => {
         state.nip1 = payload
      },
   },
})

export const { setInvoicesDemoNip1 } = invoicesDemoSlice.actions

const selectInvoicesDemo = ({ invoicesDemo }: RootState) => invoicesDemo

export const selectInvoicesDemoNip1 = createSelector(
   selectInvoicesDemo,
   ({ nip1 }) => nip1
)

export default invoicesDemoSlice.reducer
