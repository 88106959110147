import { Box, TextField } from "@material-ui/core"
import { SyntheticEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import GenerateCta from "./GenerateCta"
import ResetCta from "./ResetCta"
import StartCta from "./StartCta"

const initialValues = { nip1: "", nip2: "", count: "" }
const initialErrors = { nip1: "", nip2: "", count: "" }

export type FormValues = typeof initialValues

const DemoInvoicesForm = () => {
   const { t } = useTranslation()
   const [formValues, setFormValues] = useState(initialValues)
   const [formErrors, setFormErrors] = useState(initialErrors)

   const handleFormValueChange = (
      name: keyof FormValues,
      event: SyntheticEvent
   ) => {
      const input = event.target as HTMLInputElement
      setFormValues((values) => ({ ...values, [name]: input.value }))
   }

   return (
      <>
         <Box display="flex" gridGap={8}>
            <TextField
               error={formErrors.nip1 !== ""}
               fullWidth
               helperText={formErrors.nip1}
               label={t("invoices_demo.batch_invoices.fields.nip1")}
               margin="normal"
               name="nip1"
               onChange={(event) => handleFormValueChange("nip1", event)}
               type="text"
            />

            <TextField
               error={formErrors.nip2 !== ""}
               fullWidth
               helperText={formErrors.nip2}
               label={t("invoices_demo.batch_invoices.fields.nip2")}
               margin="normal"
               name="nip2"
               onChange={(event) => handleFormValueChange("nip2", event)}
               type="text"
            />

            <TextField
               error={formErrors.count !== ""}
               fullWidth
               helperText={formErrors.count}
               label={t("invoices_demo.batch_invoices.fields.count")}
               margin="normal"
               name="count"
               onChange={(event) => handleFormValueChange("count", event)}
               type="text"
            />
         </Box>

         <Box width="60%" display="flex" mb={8} mt={1} mx="auto" gridGap={8}>
            <StartCta formValues={formValues} setFormErrors={setFormErrors} />

            <GenerateCta
               formValues={formValues}
               setFormErrors={setFormErrors}
            />

            <ResetCta />
         </Box>
      </>
   )
}

export default DemoInvoicesForm
