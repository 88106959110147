import { useCallback, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { commonApi } from "services/commonApi"
import { govItApi } from "services/gov-it"
import { govPlApi } from "services/gov-pl"
import { openbankingApi } from "services/openbankingApi"
import { peppolApi } from "services/peppol"
import { preservationApi } from "services/preservation"
import { useAppDispatch, useAppSelector } from "store"
import {
   changeActiveContext,
   changeCurrentNavItem,
   selectActiveContext,
   selectCurrentNavItem,
} from "store/app"
import {
   logout,
   selectContexts,
   selectHasPid,
   selectIsAuthenticated,
   tryLoginWithExistingSession,
} from "store/auth"
import { resetFilters, selectAllTablesNameWithFilters } from "store/tablesState"
import {
   persistTableFilters,
   persistTableRefresh,
} from "store/tablesState/utils"
import {
   contextExistInNavBarContextItems,
   getOneContextExistInNavBarContextItems,
} from "views/layout/NavBarContextItems"

function RequireAuth() {
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { pathname, search } = useLocation()
   const [loginAttempted, setLoginAttempted] = useState(false)

   const redirectTo = encodeURIComponent(`${pathname}${search}`)

   const userHasPid = useAppSelector((state) => selectHasPid(state))
   const isAuthenticated = useAppSelector(selectIsAuthenticated)
   const contexts = useAppSelector(selectContexts)
   const activeContext = useAppSelector(selectActiveContext)
   const currentNavItem = useAppSelector(selectCurrentNavItem)
   const allTables = useAppSelector(selectAllTablesNameWithFilters)

   const contextExistsInNavBar =
      getOneContextExistInNavBarContextItems(contexts)
   const activeContextExistsInNavBar = activeContext
      ? contextExistInNavBarContextItems(activeContext)
      : true

   const resetLocalStorages = () => {
      allTables.forEach((table) => {
         persistTableFilters(table, null)
         persistTableRefresh(table, true)
      })

      allTables.forEach((table) => {
         dispatch(
            resetFilters({
               table,
            })
         )
      })
   }

   const resetCachesApiStore = useCallback(() => {
      dispatch(govItApi.util.resetApiState())
      dispatch(commonApi.util.resetApiState())
      dispatch(peppolApi.util.resetApiState())
      dispatch(preservationApi.util.resetApiState())
      dispatch(govPlApi.util.resetApiState())
      dispatch(openbankingApi.util.resetApiState())
   }, [dispatch])

   //const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))

   useEffect(() => {
      ;(async () => {
         if (isAuthenticated) {
            return
         }

         await dispatch(tryLoginWithExistingSession()).unwrap()
         resetCachesApiStore()
         setLoginAttempted(true)
      })()
   }, [dispatch, isAuthenticated, resetCachesApiStore])

   useEffect(() => {
      if (loginAttempted && !isAuthenticated) {
         dispatch(logout())
         resetLocalStorages()
         resetCachesApiStore()
         navigate(`/login?redirect_to=${redirectTo}`)
      }
   }, [
      dispatch,
      navigate,
      pathname,
      loginAttempted,
      isAuthenticated,
      redirectTo,
   ])

   useEffect(() => {
      if (isAuthenticated) {
         if (
            !contextExistsInNavBar ||
            !activeContextExistsInNavBar
            //&& !isStripeUser
         ) {
            localStorage.removeItem("active_context")
            localStorage.removeItem("current_nav_item")
            dispatch(changeActiveContext(null))
            navigate("/no-services")
         } else {
            /* if (isStripeUser) {
               localStorage.setItem("active_context", "it.api.acubeapi.com")
               dispatch(changeActiveContext("it.api.acubeapi.com"))
            } else {*/
            const currentActiveContext =
               activeContext &&
               contexts.includes(activeContext) &&
               activeContextExistsInNavBar
                  ? activeContext
                  : getOneContextExistInNavBarContextItems(contexts)

            localStorage.setItem(
               "active_context",
               currentActiveContext as string
            )
            dispatch(changeActiveContext(currentActiveContext))
            //}
         }
      }
   }, [
      isAuthenticated,
      userHasPid,
      dispatch,
      navigate,
      contextExistsInNavBar,
      activeContextExistsInNavBar,
      activeContext,
      contexts,
   ])

   useEffect(() => {
      if (isAuthenticated) {
         if (currentNavItem !== pathname) {
            localStorage.setItem("current_nav_item", pathname)
            dispatch(changeCurrentNavItem(pathname))
         }
      }
   }, [isAuthenticated, dispatch, navigate, pathname, currentNavItem])

   /*useEffect(() => {
      if (isAuthenticated && isStripeUser) {
         if (
            !pathname.includes("customer-invoice") &&
            !pathname.includes("supplier-invoice")
         ) {
            navigate("/customer-invoice")
         }
      }
   }, [isAuthenticated, isStripeUser, pathname])*/

   if (isAuthenticated) {
      return <Outlet />
   }

   return null
}

export default RequireAuth
