import {
   FormControl,
   FormHelperText,
   InputLabel,
   Select,
} from "@material-ui/core"
import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"

type Props = {
   labelId: string
   options: {
      label: string
      value: string
   }[]
   name: string
   value: string
   touched?: boolean | undefined
   error?: string | undefined
   onChange: (e: ChangeEvent<any>) => void
}

const PeppolSelect = ({
   labelId,
   options,
   name,
   value,
   touched,
   error,
   onChange,
}: Props) => {
   const { t } = useTranslation()

   return (
      <FormControl fullWidth error={touched != null && error != null}>
         <InputLabel>{t(labelId)}</InputLabel>

         <Select
            native
            label={t(labelId)}
            value={value}
            name={name}
            onChange={onChange}
         >
            {options.map((option) => (
               <option key={option.value} value={option.value}>
                  {option.label}
               </option>
            ))}
         </Select>

         {touched != null && error != null && (
            <FormHelperText>{error}</FormHelperText>
         )}
      </FormControl>
   )
}

export default PeppolSelect
