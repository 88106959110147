import {
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   Link,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   makeStyles,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { format, parseISO } from "date-fns"
import { useTranslation } from "react-i18next"
import { DialogState } from "store/slices/dialogs"
import DownloadUpo from "views/pl/components/DownloadUpo"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID = "pl_invoice_details"

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({
   uuid,
   invoiceNumber,
   invoiceDate,
   invoiceType,
   invoiceVersion,
   direction,
   senderName,
   invoiceCreatorNip,
   recipientName,
   invoiceRecipientNip,
   status,
   ksefReferenceNumber,
   acquisition,
   hasUpo,
   handleClose,
}: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const publicInvoiceLink = `${window.location.origin}/gov-pl/public-invoices/${uuid}`

   return (
      <>
         <DialogTitle>
            {t("pl.invoices.invoice_details_dialog.title")}
         </DialogTitle>

         <DialogContent className={classes.content}>
            <List>
               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.public_invoce_url"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <Link href={publicInvoiceLink} target="_blank">
                        {publicInvoiceLink}
                     </Link>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.invoice_number"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceNumber} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.invoice_date"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceDate} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.invoice_type"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceType} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.invoice_version"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceVersion} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("pl.invoices.invoice_details_dialog.direction")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={direction} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("pl.invoices.invoice_details_dialog.from")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={senderName} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t("pl.invoices.invoice_details_dialog.nip")}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceCreatorNip} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.recipient_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={recipientName} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.recipient_nip"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={invoiceRecipientNip} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.invoice_status"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={status} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.ksef_reference_number"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={ksefReferenceNumber} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "pl.invoices.invoice_details_dialog.ksef_acquisition"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText
                        primary={
                           acquisition != null
                              ? format(
                                   parseISO(acquisition),
                                   "dd MMM yyyy HH:mm:ss"
                                )
                              : null
                        }
                     />
                  </ListItemSecondaryAction>
               </ListItem>

               {hasUpo && (
                  <ListItem divider>
                     <ListItemText
                        primary={t("pl.invoices.invoice_details_dialog.upo")}
                     />
                     <ListItemSecondaryAction>
                        <DownloadUpo invoiceUuid={uuid} buttonSize="small" />
                     </ListItemSecondaryAction>
                  </ListItem>
               )}
            </List>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const InvoiceDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default InvoiceDetailsDialog
