import { yupResolver } from "@hookform/resolvers/yup"
import {
   Box,
   Button,
   FormControl,
   Grid,
   Select,
   TextField,
   Typography,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { Alert, AlertTitle } from "@material-ui/lab"
import { cleanedJsonObject } from "helper/cleanedJsonObject"
import { useSnackbar } from "notistack"
import { useState } from "react"
import {
   Controller,
   SubmitHandler,
   useFieldArray,
   useForm,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   useGetDetailOrPDFSmartReceiptQuery,
   useReturnItemsToSmartReceiptMutation,
} from "services/gov-it/smartReceipt"
import { SmartReceipt } from "services/gov-it/smartReceipt/type"
import { useAppDispatch } from "store"
import { closeDialog } from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import {
   findKeyValue,
   transformToNumber,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/ValidationHelpers"
import {
   getReturnItemEmpty,
   ReturnItemsSmartReceiptFormInitialValues,
   ReturnItemsSmartReceiptFormValue,
   ReturnItemsSmartReceiptValidationSchema,
} from "./utils"

type keyValue = keyof ReturnItemsSmartReceiptFormValue
type ReturnItemsSmartReceiptFormProps = {
   item: SmartReceipt
   table: TableName
}
const ReturnItemsSmartReceiptForm = ({
   item,
   table,
}: ReturnItemsSmartReceiptFormProps) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [globalError, setGlobalError] = useState<string | null>(null)

   const [returnItemsSmartReceipt] = useReturnItemsToSmartReceiptMutation()

   const { data, isLoading } = useGetDetailOrPDFSmartReceiptQuery({
      id: item.uuid,
   })

   const {
      register,
      handleSubmit: handleSubmitForm,
      setError,
      control,
      setValue,
      getValues,
      formState: { errors: errorsForm, isValid, isSubmitting },
   } = useForm<ReturnItemsSmartReceiptFormValue>({
      defaultValues: ReturnItemsSmartReceiptFormInitialValues,
      resolver: yupResolver(ReturnItemsSmartReceiptValidationSchema) as any,
   })

   const { fields, append, remove } = useFieldArray({
      control,
      name: "items",
   })

   const handleClose = () => {
      dispatch(closeDialog())
   }

   function formatBodyPayload(data: ReturnItemsSmartReceiptFormValue) {
      let body = {
         items: [] as {
            id: string
            quantity: number
         }[],
      }

      body.items = data.items.map((item) => {
         return cleanedJsonObject({
            id: item.id,
            quantity: parseFloat(item.quantity),
         })
      })

      return body
   }

   const onSubmit: SubmitHandler<
      typeof ReturnItemsSmartReceiptFormInitialValues
   > = async (data) => {
      setGlobalError(null)
      const body = formatBodyPayload(data)

      const response = await returnItemsSmartReceipt({
         id: item.uuid,
         data: body as any,
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         if ("violations" in (response.error.data as any)) {
            data?.violations?.forEach((violation) => {
               if (violation.propertyPath === "") {
                  setGlobalError(violation.message)
                  return
               }
               if (violation.propertyPath.startsWith("items")) {
                  setError(violation.propertyPath as keyValue, {
                     type: "validation Error",
                     message: violation.message,
                  })
                  return
               }
               setError(violation.propertyPath as keyValue, {
                  type: "validation Error",
                  message: violation.message,
               })
            })
            return
         }

         setGlobalError(t("CustomerInvoices.CreateInvoice.Validation Error"))
         return
      }

      if ("data" in response) {
         enqueueSnackbar(t("global.operation_success"), {
            variant: "success",
         })
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
         dispatch(closeDialog())
      }
   }

   const getErrorForName = (name: string) => {
      return findKeyValue(errorsForm, name) as any
   }

   const handleChangeTextField = (name: keyValue, value: any) => {
      if (value === null || value === undefined) {
         setValue(name, value)
         return value
      }

      if (value === "") {
         //@ts-ignore
         setValue(name, "0.00")
         return "0.00"
      }

      const numberValue = Number(value.replace(",", "."))
      if (!isNaN(numberValue)) {
         const result = transformToNumber(numberValue?.toString())
         //@ts-ignore
         setValue(name, result)
         return result
      }

      setValue(name, value)
      return value
   }

   const options =
      data?.items.map((item) => ({
         value: item.id,
         label: `id: ${item.id} - ${t("smartReceipt.quantity")}: ${
            item.quantity
         } - ${t("gross_price")}: ${item.gross_price} - ${item.description}`,
      })) ?? []

   const isErrors =
      globalError !== null ||
      (Object.entries(errorsForm).length > 0 &&
         Object.entries(errorsForm as any).some(
            ([key, value]) => key.length === 0 && "message" in (value as any)
         ))
   return (
      <form onSubmit={handleSubmitForm(onSubmit)}>
         <Grid container>
            <Grid
               item
               xs={12}
               md={12}
               style={{
                  marginBottom: "10px",
               }}
            >
               {isErrors && (
                  <>
                     <Box mt={2} mb={2}>
                        <Alert severity="error">
                           <AlertTitle>
                              {t("smartReceipt.Errore_nella_creazione")}
                           </AlertTitle>
                           <p>{globalError}</p>

                           {Object.entries(errorsForm).length > 0 &&
                              Object.entries(errorsForm as any).map(
                                 ([key, value], idx) => {
                                    return (
                                       <Box
                                          style={{
                                             borderRadius: "5px",
                                          }}
                                          mt={idx > 1 ? 1 : 0}
                                          key={key}
                                       >
                                          {"message" in (value as any) &&
                                             key.length === 0 && (
                                                <p>
                                                   {key}:{" "}
                                                   {(value as any).message}
                                                </p>
                                             )}
                                       </Box>
                                    )
                                 }
                              )}
                        </Alert>
                     </Box>
                  </>
               )}
            </Grid>
            <Grid item container alignItems={"center"}>
               <Grid item xs={12} md={12} container spacing={1}>
                  <Grid container alignItems={"center"} item xs={12} md={12}>
                     {fields.map((item, index) => {
                        const nameId = `items[${index}].id`
                        const nameQuantity = `items[${index}].quantity`
                        return (
                           <Grid
                              item
                              xs={12}
                              md={12}
                              key={item.id}
                              style={{
                                 border: "1px solid #ccc",
                                 padding: "5px",
                                 marginBottom:
                                    fields.length - 1 === index ? 0 : "10px",
                                 marginTop: fields.length === 1 ? "10px" : 0,
                                 backgroundColor:
                                    index % 2 === 0 ? "#f9f9f9" : "#fff",
                              }}
                              container
                              spacing={3}
                           >
                              {errorsForm.items && errorsForm.items[index] && (
                                 <Grid item xs={12} md={12}>
                                    <Alert
                                       severity="error"
                                       style={{
                                          borderRadius: "5px",
                                       }}
                                    >
                                       {Object.entries(
                                          errorsForm.items[index] as any
                                       ).map(([key, value], idx) => {
                                          return (
                                             <Box
                                                style={{
                                                   borderRadius: "5px",
                                                }}
                                                mt={idx > 1 ? 1 : 0}
                                                key={key}
                                             >
                                                {key}:{" "}
                                                {(value as any).message ??
                                                   (value as any)?.map(
                                                      (
                                                         v:
                                                            | {
                                                                 [
                                                                    s: string
                                                                 ]: unknown
                                                              }
                                                            | ArrayLike<unknown>,
                                                         idx: number
                                                      ) => (
                                                         <Box>
                                                            - {idx + 1}:
                                                            {v &&
                                                               Object.entries(
                                                                  v
                                                               ).map(
                                                                  ([k, v]) => (
                                                                     <Box
                                                                        key={k}
                                                                     >
                                                                        {k}:{" "}
                                                                        {
                                                                           (
                                                                              v as any
                                                                           )
                                                                              .message
                                                                        }
                                                                     </Box>
                                                                  )
                                                               )}
                                                         </Box>
                                                      )
                                                   )}
                                             </Box>
                                          )
                                       })}
                                    </Alert>
                                 </Grid>
                              )}
                              <Grid item xs={12} md={12} alignItems="flex-end">
                                 <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                 >
                                    <Typography
                                       variant="h6"
                                       style={{
                                          color: "black",
                                          fontWeight: "bold",
                                       }}
                                    >
                                       {t("smartReceipt.item")} {index + 1}
                                    </Typography>

                                    {fields.length > 1 && (
                                       <Button
                                          onClick={() => remove(index)}
                                          variant="contained"
                                          style={{
                                             background: "#920000",
                                             color: "white",
                                             fontWeight: "bold",
                                          }}
                                       >
                                          {t("smartReceipt.Rimuovi_item")}
                                       </Button>
                                    )}
                                 </Box>
                              </Grid>
                              <Grid item xs={12} md={12} container spacing={3}>
                                 <Grid item xs={12} md={6}>
                                    <Controller
                                       name={nameId as keyValue}
                                       control={control}
                                       render={({ field }) => (
                                          <FormControl
                                             fullWidth
                                             variant="outlined"
                                          >
                                             <InputLabel htmlFor={nameId}>
                                                id
                                             </InputLabel>
                                             <Select
                                                disabled={options.length === 0}
                                                style={{
                                                   backgroundColor: "white",
                                                   borderBottomRightRadius:
                                                      "5px !important",
                                                   borderBottomLeftRadius:
                                                      "5px !important",
                                                }}
                                                inputProps={{
                                                   name: nameId,
                                                   id: nameId,
                                                }}
                                                fullWidth
                                                //@ts-ignore
                                                helperText={
                                                   getErrorForName(nameId)
                                                      ?.message
                                                }
                                                error={
                                                   getErrorForName(nameId) !=
                                                   null
                                                }
                                                {...register(
                                                   nameId as keyValue
                                                )}
                                                defaultValue={getValues(
                                                   nameId as keyValue
                                                )}
                                             >
                                                {options.map((option) => (
                                                   <MenuItem
                                                      key={option.value}
                                                      value={option.value}
                                                      title={option.label}
                                                   >
                                                      {option.label}
                                                   </MenuItem>
                                                ))}
                                             </Select>
                                          </FormControl>
                                       )}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <Controller
                                       name={nameQuantity as keyValue}
                                       control={control}
                                       render={({ field }) => (
                                          <TextField
                                             style={{
                                                backgroundColor: "white",
                                                borderBottomRightRadius:
                                                   "5px !important",
                                                borderBottomLeftRadius:
                                                   "5px !important",
                                             }}
                                             type={"text"}
                                             label={t("smartReceipt.quantity")}
                                             fullWidth
                                             variant="outlined"
                                             {...register(
                                                nameQuantity as keyValue
                                             )}
                                             onBlur={(e) => {
                                                handleChangeTextField(
                                                   nameQuantity as keyValue,
                                                   e.target.value
                                                )
                                             }}
                                             helperText={
                                                getErrorForName(nameQuantity)
                                                   ?.message
                                             }
                                             error={
                                                getErrorForName(nameQuantity) !=
                                                null
                                             }
                                          />
                                       )}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        )
                     })}
                  </Grid>
                  <Grid item xs={12} md={12} alignItems={"flex-start"}>
                     <Button
                        disabled={isSubmitting || options.length === 0}
                        onClick={() => append(getReturnItemEmpty())}
                        variant="contained"
                        color="primary"
                     >
                        {t("smartReceipt.Aggiungi_item")}
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Box
               width="100%"
               style={{
                  position: "sticky",
                  bottom: 0,
                  background: "#fff",
                  padding: "20px",
                  zIndex: 1000,
               }}
            >
               <Grid container justifyContent="center">
                  <Button
                     disabled={
                        isSubmitting ||
                        options.length === 0 ||
                        fields.length === 0
                     }
                     color={isSubmitting ? "default" : "primary"}
                     variant="contained"
                     size="medium"
                     type="submit"
                  >
                     {t("Default.Salva")}
                  </Button>

                  <Button
                     onClick={handleClose}
                     color="default"
                     variant="contained"
                     style={{ marginLeft: "10px" }}
                  >
                     {t("Default.Annulla")}
                  </Button>
               </Grid>
            </Box>
         </Grid>
      </form>
   )
}

export default ReturnItemsSmartReceiptForm
