import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import CodeIcon from "@material-ui/icons/Code"
import DescriptionIcon from "@material-ui/icons/Description"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   PreservationDocument,
   PreservationDocumentType,
   useLazyGetPreservationDocumentFileQuery,
   useLazyGetPreservationDocumentReceiptQuery,
} from "services/preservation"
import { useAppDispatch, useAppSelector } from "store"
import { openDialog } from "store/slices/dialogs"
import { selectTableFilters, TableName } from "store/tablesState"

type Props = {
   item: PreservationDocument
}

const table: TableName = "preservation.documents"

const RowCTAs = ({ item }: Props) => {
   const [getPreservationDocumentFile] =
      useLazyGetPreservationDocumentFileQuery()
   const [getPreservationDocumentReceipt] =
      useLazyGetPreservationDocumentReceiptQuery()

   const dispatch = useAppDispatch()
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation()

   const checkIfDocumentTypeIsSelected = () => {
      if (!filters.documentType) {
         enqueueSnackbar(t("Preservation.documents.table.no_data"), {
            variant: "info",
         })
         return false
      }
      return true
   }

   const handleDownloadReceipt = async (
      accept: "application/pdf" | "application/xml"
   ) => {
      if (!checkIfDocumentTypeIsSelected()) return

      const response = await getPreservationDocumentReceipt({
         uuid: item.uuid,
         documentType: filters.documentType as PreservationDocumentType,
         accept,
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      if (response.data) {
         const a = document.createElement("a")

         a.href =
            accept === "application/pdf"
               ? `data:application/pdf;base64,${response.data}`
               : `data:application/xml;charset=utf-8,${response.data}`
         a.download =
            accept === "application/pdf"
               ? item.uuid + ".pdf"
               : item.uuid + ".xml"
         a.style.display = "none"
         document.body.appendChild(a)
         a.click()

         document.body.removeChild(a)
         return
      }
   }

   const handleDownload = async () => {
      if (!checkIfDocumentTypeIsSelected()) return

      const response = await getPreservationDocumentFile({
         uuid: item.uuid,
         documentType: filters.documentType as PreservationDocumentType,
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }
      if (response.data) {
         const a = document.createElement("a")

         a.href = `data:application/octet-stream;base64,${response.data}`
         a.download = item.original_filename ?? item.uuid
         a.style.display = "none"
         document.body.appendChild(a)
         a.click()

         document.body.removeChild(a)
         return
      }
   }

   return (
      <ButtonGroup>
         {item.status === "stored" && (
            <>
               <Tooltip title="Download Receipt PDF">
                  <IconButton
                     aria-label="Download Receipt PDF"
                     onClick={() => {
                        handleDownloadReceipt("application/pdf")
                     }}
                  >
                     <PictureAsPdfIcon />
                  </IconButton>
               </Tooltip>

               <Tooltip title="Download Receipt XML">
                  <IconButton
                     aria-label="Download Receipt XML"
                     onClick={() => {
                        handleDownloadReceipt("application/xml")
                     }}
                  >
                     <CodeIcon />
                  </IconButton>
               </Tooltip>
            </>
         )}

         <Tooltip title="Download Original document">
            <IconButton
               aria-label="Download Original document"
               onClick={handleDownload}
            >
               <CloudDownloadIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title="Document Details">
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "preservation.document_details",
                        data: {
                           item,
                        },
                     })
                  )
               }}
            >
               <DescriptionIcon />
            </IconButton>
         </Tooltip>
      </ButtonGroup>
   )
}

export default RowCTAs
