import { DialogContent, DialogTitle, makeStyles } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { DialogId } from "store/slices/dialogs"
import UploadForm from "views/gov-it/InvoiceTransfers/UploadDialog/UploadForm"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID: DialogId = "gov_it_invoice_transfers.upload"

type Props = BaseDialogActions

const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()
   return (
      <>
         <DialogTitle>{t("transfer.ImportInvoice.upload_invoice")}</DialogTitle>

         <DialogContent className={classes.content}>
            <UploadForm handleClose={handleClose} />
         </DialogContent>
      </>
   )
}

const InvoiceTransferUploadDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default InvoiceTransferUploadDialog
