import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { blobToBase64 } from "helper/encodings"
import { useSnackbar } from "notistack"
import { useRef } from "react"
import { useParams } from "react-router"
import { useLazyGetProtectedInvoiceAttachmentQuery } from "services/gov-pl/invoices"

type Props = {
   attachments: {
      uuid: string
   }[]
   protectedInvoiceCode: string
}

const InvoiceAttachments = ({ attachments, protectedInvoiceCode }: Props) => {
   const { invoiceUuid } = useParams()

   if (attachments.length === 0) {
      return null
   }

   return (
      <>
         <Typography variant="body1">Attachments</Typography>

         <Box py={2} display="flex">
            {attachments.map(({ uuid }, index) => (
               <InvoiceAttachment
                  invoiceUuid={invoiceUuid!}
                  attachmentUuid={uuid}
                  protectedInvoiceCode={protectedInvoiceCode}
                  index={index}
               />
            ))}
         </Box>
      </>
   )
}

type InvoiceAttachmentProps = {
   invoiceUuid: string
   attachmentUuid: string
   protectedInvoiceCode: string
   index: number
}

const InvoiceAttachment = ({
   invoiceUuid,
   attachmentUuid,
   protectedInvoiceCode,
   index,
}: InvoiceAttachmentProps) => {
   const ref = useRef<any>(null)
   const { enqueueSnackbar } = useSnackbar()

   const [getInvoiceAttachment, { isFetching }] =
      useLazyGetProtectedInvoiceAttachmentQuery()

   const handleDownload = async () => {
      const response = await getInvoiceAttachment({
         invoiceUuid,
         attachmentUuid,
         code: protectedInvoiceCode,
      })

      if ("error" in response || ref.current == null) {
         enqueueSnackbar("An error occurred", {
            variant: "error",
         })
         return
      }

      const data = await blobToBase64(new Blob([response.data!]))
      ref.current.href = `data:text/plain;base64,${data}`
      ref.current.click()
   }

   return (
      <Box mr={2}>
         <a
            ref={ref}
            href="#"
            download="attachment.txt"
            style={{ display: "none" }}
         >
            Attachment {index}
         </a>

         <Button
            color="primary"
            disabled={isFetching}
            size="medium"
            type="button"
            variant="contained"
            onClick={handleDownload}
         >
            {isFetching ? (
               <Box display="flex" mr={1}>
                  <CircularProgress size={24} color="inherit" />
               </Box>
            ) : (
               <>Attachment {index + 1}</>
            )}
         </Button>
      </Box>
   )
}

export default InvoiceAttachments
