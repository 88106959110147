import { Box } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import React from "react"
import { useTranslation } from "react-i18next"
import ImportInvoiceForm from "./CustomerImportInvoiceForm"
import ImportSupplierInvoiceForm from "./SupplierImportInvoiceForm"

type Error = {
   propertyPath: string
   message: string
}

type ContentProps = BaseDialogActions & {
   table: "supplier.invoices" | "customer.invoices"
}
const Content = ({ handleClose, table }: ContentProps) => {
   const { t } = useTranslation()
   const [errors, setErrors] = React.useState<Error[]>([])
   const [message, setMessage] = React.useState<string | null>(null)

   return (
      <>
         <DialogTitle id="create-invoice-dialog-title">
            {t("CustomerInvoices.ImportInvoice.import_invoice")}
         </DialogTitle>

         <DialogContent>
            {errors.length > 0 && (
               <Box mt={2} mb={2}>
                  <Alert
                     severity="error"
                     onClose={() => {
                        setErrors([])
                     }}
                  >
                     <AlertTitle>
                        {t("global.invoice_imported_error")}
                     </AlertTitle>
                     {errors.map(function (error) {
                        return (
                           <p>
                              <strong>{error.propertyPath}</strong>{" "}
                              {error.message}
                           </p>
                        )
                     })}
                  </Alert>
               </Box>
            )}

            {message && (
               <Box mt={2} mb={2}>
                  <Alert
                     severity="success"
                     style={{
                        margin: 2,
                     }}
                     onClose={() => {
                        setMessage(null)
                     }}
                  >
                     <AlertTitle>
                        {t("global.invoice_imported_successfully")}
                     </AlertTitle>
                     {message}
                  </Alert>
               </Box>
            )}

            {table === "supplier.invoices" ? (
               <ImportSupplierInvoiceForm
                  handleClose={handleClose}
                  table={table}
                  setErrors={(errors) => setErrors(errors)}
                  setMessage={(message) => setMessage(message)}
               />
            ) : (
               <ImportInvoiceForm
                  table={table}
                  setErrors={(errors) => setErrors(errors)}
                  setMessage={(message) => setMessage(message)}
                  handleClose={handleClose}
               />
            )}
         </DialogContent>
      </>
   )
}
type ImportInvoiceDialogProps = {
   dialogId:
      | "supplier.invoices.import_invoice"
      | "customer.invoices.import_invoice"
}
const ImportInvoiceDialog = ({ dialogId }: ImportInvoiceDialogProps) => {
   const table =
      dialogId === "supplier.invoices.import_invoice"
         ? "supplier.invoices"
         : "customer.invoices"
   return (
      <BaseDialog id={dialogId} maxWidth="lg" fullWidth>
         {(data) => <Content {...data} table={table} />}
      </BaseDialog>
   )
}

export default ImportInvoiceDialog
