import { CreateReturnItemsInSmartReceipt } from "services/gov-it/smartReceipt/type"
import * as Yup from "yup"

export type ReturnItemsSmartReceiptFormValue = CreateReturnItemsInSmartReceipt

export const ReturnItemsSmartReceiptFormInitialValues: ReturnItemsSmartReceiptFormValue =
   {
      items: [
         {
            id: "",
            quantity: "1.00",
         },
      ],
   }

export const ReturnItemsSmartReceiptValidationSchema = Yup.object().shape({
   items: Yup.array().of(
      Yup.object().shape({
         id: Yup.string().required("This field is required"),
         quantity: Yup.string()
            .required("This field is required")
            .test(
               "is-decimal",
               "This field must be a decimal and have at most 2 decimal places",
               (value) => {
                  if (!value) return false
                  return value.match(/^\d+(\.\d{1,2})?$/) !== null
               }
            ),
      })
   ),
})

export const getReturnItemEmpty = () => ({
   id: "",
   quantity: "1.00",
})
