import { makeStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

// @ts-expect-error
const useStyles = makeStyles((theme) => ({
   root: {
      fontFamily: theme.typography.fontFamily,
      alignItems: "center",
      borderRadius: 2,
      display: "inline-flex",
      flexGrow: 0,
      whiteSpace: "nowrap",
      cursor: "default",
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 20,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.08),
   },
}))

type Props = {
   // @TODO: turn this into a pure boolean
   value: string | boolean
}

const LabelBoolean = ({ value }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   let color, label

   if (value === "true" || value === true) {
      color = "success"
      label = t("BusinessRegistry.Enabled")
   } else {
      color = "error"
      label = t("BusinessRegistry.Disabled")
   }

   return (
      // @ts-expect-error
      <span className={clsx(classes.root, { [classes[color]]: color })}>
         {label}
      </span>
   )
}

export default LabelBoolean
