import { IconButton, Tooltip } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import DeleteIcon from "@material-ui/icons/Delete"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteBusinessRegistryMutation } from "services/openbankingApi/businessRegistry"
import { useAppDispatch } from "store"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"

type Props = {
   fiscalId: string
}

const DeleteBusinessRegistryCTA = ({ fiscalId }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [deleteBusinessRegistry] = useDeleteBusinessRegistryMutation()

   const handleDelete = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t(
               "openbanking.business_registry.delete_business_registry_confirmation"
            ),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await deleteBusinessRegistry({ fiscalId })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(
         t("openbanking.business_registry.messages.business_registry_deleted"),
         { variant: "success" }
      )
   }

   return (
      <Tooltip
         title={t("openbanking.business_registry.delete_business_registry_cta")}
      >
         <IconButton style={{ color: red[600] }} onClick={handleDelete}>
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   )
}

export default DeleteBusinessRegistryCTA
