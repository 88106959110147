import {
   BaseQueryFn,
   FetchArgs,
   fetchBaseQuery,
   FetchBaseQueryError,
} from "@reduxjs/toolkit/query"
import { getImpersonatedUser } from "helper/impersonateUser"
import type { RootState } from "store"
import { logout, selectAuthToken } from "store/auth"

type ExtraOptions = {
   requireAuth?: boolean
}

export const baseQuery =
   ({
      baseUrl,
   }: {
      baseUrl: string
   }): BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      ExtraOptions
   > =>
   async (args, helpers, extraOptions = { requireAuth: true }) => {
      const { requireAuth } = extraOptions
      const token = selectAuthToken(helpers.getState() as RootState)
      const impersonatedUser = getImpersonatedUser()

      const response = await fetchBaseQuery({
         baseUrl,
         prepareHeaders: (headers) => {
            if (requireAuth && token != null) {
               headers.set("authorization", `Bearer ${token}`)
            }

            if (impersonatedUser != null) {
               headers.set("X-Switch-User", impersonatedUser)
            }

            return headers
         },
      })(args, helpers, extraOptions)

      // Auth token expired or invalid
      if (response.error != null && response.error.status === 401) {
         helpers.dispatch(logout())
      }

      return response
   }
