import { Box, Button, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import AnimatedCheckmark from "views/common/AnimatedCheckmark"

const Success = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   return (
      <>
         <Box
            display="flex"
            justifyContent="center"
            color="success.main"
            mb={6}
         >
            <AnimatedCheckmark size={64} />
         </Box>

         <Box textAlign="center">
            <Typography variant="h2">
               {t("onboard.steps.end.success.title")}
            </Typography>
         </Box>

         <Box marginTop={6} marginBottom={4} textAlign="center">
            <Typography variant="body1">
               {t("onboard.mode_2.token_submitted")}
            </Typography>
         </Box>

         <Box marginBottom={4} textAlign="center">
            <Button
               color="primary"
               variant="contained"
               size="large"
               onClick={() => {
                  navigate("/gov-pl/legal-entities")
               }}
            >
               {t("onboard.mode_2.back_to_legal_entities")}
            </Button>
         </Box>
      </>
   )
}

export default Success
