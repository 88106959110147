import { useDownloadInvoiceHook } from "hooks/useDownloadInvoiceHook"
import Loader from "views/common/Loader"
import { ErrorPage } from "views/gov-it/DownloadInvoice/ErrorPage"

type Props = {
   fileType: "pdf" | "xml"
}
const DownloadInvoice = ({ fileType }: Props) => {
   const { isLoading, error } = useDownloadInvoiceHook({
      fileType,
   })

   if (isLoading) {
      return <Loader />
   }

   if (error) {
      return <ErrorPage redirectUrl={"/customer-invoice"} />
   }

   return null
}

export default DownloadInvoice
