import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import { safeJsonParse } from "helper/safeJsonParse"
import { useSnackbar } from "notistack"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useLazyGetInvoiceAttachmentQuery } from "services/gov-it"

type DownloadAttachmentCTAProps = {
   invoiceUuid: string
   attachmentName: string
   index: number
}

const DownloadAttachmentCTA = ({
   invoiceUuid,
   attachmentName,
   index,
}: DownloadAttachmentCTAProps) => {
   const ref = useRef<HTMLAnchorElement | null>(null)
   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation()
   const [getAttachment] = useLazyGetInvoiceAttachmentQuery()

   const handleDownload = async () => {
      const response = await getAttachment({ invoiceUuid, index })

      if ("error" in response || ref.current == null) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      ref.current.href = `data:application/pdf;base64,${response.data}`
      ref.current.click()
   }

   return (
      <>
         <a
            ref={ref}
            href="#"
            download={attachmentName}
            style={{ display: "none" }}
         >
            {t("invoice_attachments.download_attachment")}
         </a>

         <Typography variant="body1">
            <Link href="#" onClick={handleDownload}>
               {t("invoice_attachments.download_attachment")}
            </Link>
         </Typography>
      </>
   )
}

type Attachment = {
   nome_attachment: string
}

type InvoiceAttachmentsProps = {
   invoiceUuid: string
   attachments: Attachment[]
}

function InvoiceAttachments({
   invoiceUuid,
   attachments,
}: InvoiceAttachmentsProps) {
   const { t } = useTranslation()

   if (attachments.length === 0) {
      return (
         <Typography variant="body1">
            {t("invoice_attachments.no_attachments")}
         </Typography>
      )
   }

   return (
      <List>
         {attachments.map(({ nome_attachment }, index) => (
            <ListItem key={nome_attachment} divider={true}>
               <ListItemText primary={nome_attachment} />
               <ListItemSecondaryAction>
                  <DownloadAttachmentCTA
                     invoiceUuid={invoiceUuid}
                     attachmentName={nome_attachment}
                     index={index}
                  />
               </ListItemSecondaryAction>
            </ListItem>
         ))}
      </List>
   )
}

type Props = {
   invoice: {
      uuid: string
      payload: string
   }
   index: number
   value: number
}

export default function TabPanelAttachments({ value, index, invoice }: Props) {
   const payload = safeJsonParse(invoice.payload)
   const attachments = payload?.fattura_elettronica_body[0]?.allegati ?? []

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               <InvoiceAttachments
                  invoiceUuid={invoice.uuid}
                  attachments={attachments}
               />
            </Box>
         )}
      </div>
   )
}
