import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useNavigate } from "react-router"
import { useAppDispatch } from "store"
import { TableName, updateData, updateFilter } from "store/tablesState"

const table: TableName = "openbanking.payments"

type Props = {
   fiscalId: string
   accountUuid?: string
}

const PaymentsCTA = ({ fiscalId, accountUuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()

   const goToPayments = () => {
      batch(() => {
         dispatch(
            updateData({
               table,
               param: "fiscalId",
               value: fiscalId,
            })
         )

         if (accountUuid != null) {
            dispatch(
               updateFilter({
                  table,
                  filter: "accountUuid",
                  value: [accountUuid],
               })
            )
         }
      })

      navigate("/openbanking/payments")
   }

   return (
      <Button color="primary" variant="contained" onClick={goToPayments}>
         {t("openbanking.business_registry.payments_cta")}
      </Button>
   )
}

export default PaymentsCTA
