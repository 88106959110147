import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionIcon from "@material-ui/icons/Description"
import EditIcon from "@material-ui/icons/Edit"
import SendIcon from "@material-ui/icons/Send"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteDraftInvoiceMutation } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch } from "store"
import {
   closeDialog,
   openConfirmationDialog,
   openDialog,
} from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"

type Props = {
   item: GovITInvoice
}

const table: TableName = "invoice.drafts"
const RowCTAs = ({ item }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [deleteDraftInvoice] = useDeleteDraftInvoiceMutation()

   const handleDelete = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("gov_it.invoice_drafts.delete_invoice_confirmation"),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      dispatch(closeDialog())

      const response = await deleteDraftInvoice({
         uuid: item.uuid,
         type: item.transmission_format == "FSM10" ? "simplified" : "simple",
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(t("gov_it.invoice_drafts.invoice_deleted"), {
         variant: "success",
      })

      persistTableRefresh(table)
      dispatch(
         updateRefetch({
            table,
         })
      )
   }

   return (
      <ButtonGroup>
         <Tooltip title={t("gov_it.invoice_drafts.delete_invoice")}>
            <IconButton onClick={handleDelete}>
               <DeleteIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("gov_it.invoice_drafts.update_invoice")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "invoice.drafts.form",
                        data: {
                           invoiceUuid: item.uuid,
                           mode: "edit",
                        },
                     })
                  )
               }}
            >
               <EditIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("gov_it.invoice_drafts.details_dialog_title")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "gov_it_invoices_details",
                        data: {
                           invoiceUuid: item.uuid,
                           mode: "info",
                        },
                     })
                  )
               }}
            >
               <DescriptionIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("gov_it.invoice_drafts.send_invoice")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "invoice.drafts.form",
                        data: {
                           invoiceUuid: item.uuid,
                           mode: "send",
                        },
                     })
                  )
               }}
            >
               <SendIcon />
            </IconButton>
         </Tooltip>
      </ButtonGroup>
   )
}

export default RowCTAs
