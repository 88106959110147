import {
   Button,
   DialogActions,
   DialogContent,
   IconButton,
   makeStyles,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DialogState } from "store/slices/dialogs"

const DIALOG_ID = "legal_entity_onboard_init_success"

const useStyles = makeStyles((theme) => ({
   close: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
   },
   content: {
      textAlign: "center",
      marginTop: theme.spacing(5),
   },
   link: {
      fontFamily: "monospace",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
   },
   actions: {
      justifyContent: "center",
      padding: theme.spacing(2),
   },
}))

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ url, handleClose }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const [linkCopied, setLinkCopied] = useState(false)

   const handleCopyUrl = async () => {
      await navigator.clipboard.writeText(url)
      setLinkCopied(true)
   }

   return (
      <>
         <DialogContent className={classes.content}>
            <IconButton className={classes.close} onClick={handleClose}>
               <CloseIcon />
            </IconButton>
            <p>{t("legal_entities.onboard_init_success.message")}</p>

            <p className={classes.link}>{url}</p>
         </DialogContent>

         <DialogActions className={classes.actions}>
            <Button
               disabled={linkCopied}
               color="primary"
               variant="contained"
               onClick={handleCopyUrl}
            >
               {linkCopied &&
                  t("legal_entities.onboard_init_success.link_copied")}
               {!linkCopied &&
                  t("legal_entities.onboard_init_success.copy_link")}
            </Button>
         </DialogActions>
      </>
   )
}

const OnboardInitSuccessDialog = () => (
   <BaseDialog id={DIALOG_ID}>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default OnboardInitSuccessDialog
