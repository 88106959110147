import { IconButton, Tooltip } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import DeleteIcon from "@material-ui/icons/Delete"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteLegalEntityMutation } from "services/peppol/legalEntities"
import { useAppDispatch } from "store"
import { closeDialog, openConfirmationDialog } from "store/slices/dialogs"

type Props = {
   uuid: string
}

const DeleteLegalEntityCTA = ({ uuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const [deleteLegalEntity] = useDeleteLegalEntityMutation()

   const handleDelete = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t(
               "peppol.legal_entities.delete_legal_entity_confirmation"
            ),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      const response = await deleteLegalEntity({ uuid })
      dispatch(closeDialog())

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(
         t("peppol.legal_entities.messages.legal_entity_deleted"),
         { variant: "success" }
      )
   }

   return (
      <Tooltip title={t("peppol.legal_entities.delete_legal_entity_cta")}>
         <IconButton style={{ color: red[600] }} onClick={handleDelete}>
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   )
}

export default DeleteLegalEntityCTA
