import { Container, Grid, makeStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { getProfile } from "../../services/client/request/common/getProfile"
import { getUserRecipientCode } from "../../services/client/request/getUserRecipientCode"
import Page from "../common/components/Page"
import Loader from "../common/Loader"
import CodiceDestinatario from "./CodiceDestinatario"
import ProfileDetails from "./ProfileDetails"

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const Profile = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const roles = useAppSelector((state) => state.auth.data.roles)
   const [isLoading, setIsLoading] = React.useState(false)
   const [errorConfigurazioneAlert, setErrorConfigurazioneAlert] =
      React.useState(false)
   const [errorProfile, setErrorProfile] = React.useState(false)
   const [counter, setCounter] = React.useState(0)
   const { enqueueSnackbar } = useSnackbar()
   let configurazioneAlertInitialState = {
      credit_alert_email: null,
      pre_sale_alert_threshold: null,
   }
   const [configurazioneAlert, setConfigurazioneAlert] = useState(
      configurazioneAlertInitialState
   )
   let profileInitialState = {
      email: null,
      fullname: null,
      invoicing_address: null,
      invoicing_cap: null,
      invoicing_city: null,
      invoicing_fiscal_id: null,
      invoicing_name: null,
      invoicing_province: null,
      invoicing_vat_number: null,
      sdi_recipient: { recipient_code: null },
      statistics_scope: null,
   }
   const [profile, setProfile] = useState(profileInitialState)

   let userRecipientCodeInitialState = {
      recipient_code: null,
   }
   const [userRecipientCode, setUserRecipientCode] = useState(
      userRecipientCodeInitialState
   )

   // @TODO counter state is used just to trigger a new render, please rewrite this logic
   const incrementCounter = () => {
      setCounter((s) => s + 1)
   }

   async function fetchData() {
      setIsLoading(true)
      setErrorProfile(false)
      setErrorConfigurazioneAlert(false)

      try {
         const profile = await getProfile()
         setProfile(profile)
         const userRecipientCode = await getUserRecipientCode()
         setUserRecipientCode(userRecipientCode)
      } catch (e) {
         setErrorProfile(e.message)
         enqueueSnackbar(e.message, { variant: "error" })
      }

      /*try {
      const configurazioneAlert = await getConfigurazioneAlert(token);
      setConfigurazioneAlert(configurazioneAlert);
    } catch (e) {
      setErrorConfigurazioneAlert(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }*/

      setIsLoading(false)
   }

   useEffect(() => {
      fetchData()
   }, [counter])

   return (
      <Page className={classes.root} title={t("Menu.Il mio profilo")}>
         {isLoading ? (
            <Loader />
         ) : (
            <Container maxWidth="lg">
               <Grid container spacing={3}>
                  <Grid item lg={8} md={12} xs={12}>
                     {errorProfile ? (
                        <></>
                     ) : (
                        <ProfileDetails
                           profile={profile}
                           incrementCounter={incrementCounter}
                        />
                     )}
                  </Grid>

                  {roles.hasOwnProperty("it.api.acubeapi.com") && (
                     <Grid item lg={4} md={12} xs={12}>
                        {errorProfile ? (
                           <></>
                        ) : (
                           <CodiceDestinatario
                              userRecipientCode={userRecipientCode}
                           />
                        )}
                     </Grid>
                  )}

                  {/* <Grid item lg={8} md={12} xs={12}>
              {errorConfigurazioneAlert ? (
                <></>
              ) : (
                <ConfigurazioneAlert
                  configurazioneAlert={configurazioneAlert}
                  incrementCounter={incrementCounter}
                />
              )}
            </Grid> */}
               </Grid>
            </Container>
         )}
      </Page>
   )
}

export default Profile
