import { Box, Button, Paper, TextField } from "@material-ui/core"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import {
   resetFilters,
   selectTableData,
   TableName,
   updateData,
} from "store/tablesState"
import * as Yup from "yup"

const table: TableName = "openbanking.transactions"
const name: keyof FormValues = "fiscalId"

type FormValues = {
   fiscalId: string
}

const validationSchema = Yup.object().shape({
   fiscalId: Yup.string().required(),
})

const TransactionsFilters = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const { fiscalId } = useAppSelector((state) =>
      selectTableData(state, { table })
   )

   const handleChangeFiscalId = ({ fiscalId }: FormValues) => {
      dispatch(resetFilters({ table }))

      dispatch(
         updateData({
            table,
            param: "fiscalId",
            value: fiscalId,
         })
      )
   }

   const initialValues: FormValues = { fiscalId }

   return (
      <Paper>
         <Box px={2} py={3}>
            <Formik
               initialValues={initialValues}
               validationSchema={validationSchema}
               onSubmit={handleChangeFiscalId}
            >
               {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
               }) => (
                  <form onSubmit={handleSubmit}>
                     <Box display="flex" gridGap={8}>
                        <TextField
                           error={touched[name] != null && errors[name] != null}
                           fullWidth
                           helperText={errors[name]}
                           label={t(
                              "openbanking.transactions.fiscal_id_form.field_fiscal_id"
                           )}
                           margin="none"
                           name={name}
                           value={values[name]}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           type="text"
                        />

                        <Box>
                           <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                           >
                              {t(
                                 "openbanking.transactions.fiscal_id_form.submit_cta"
                              )}
                           </Button>
                        </Box>
                     </Box>
                  </form>
               )}
            </Formik>
         </Box>
      </Paper>
   )
}

export default TransactionsFilters
