import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import ApiConfigurationDialog from "views/ApiConfiguration/ApiConfigurationDialog"
import ApiConfigurationTable from "views/ApiConfiguration/Table"
import Page from "../common/components/Page"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: 16,
      paddingTop: 16,
   },
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))

const ApiConfigurations = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()

   const isAllowedToSee = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_CONFIG",
      })
   )

   useEffect(() => {
      if (!isAllowedToSee) {
         navigate("/")
      }
   }, [isAllowedToSee, navigate])

   return (
      <>
         <Page className={classes.root} title={t("Menu.Configurazioni")}>
            <Container maxWidth={false}>
               <ApiConfigurationTable />
            </Container>
         </Page>

         <ApiConfigurationDialog />
      </>
   )
}

export default ApiConfigurations
