import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { selectTableData } from "store/tablesState"
import Page from "views/common/components/Page"
import TransactionsFilters from "./Filters"
import FiscalIdForm from "./FiscalIdForm"
import TransactionsTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const OpenbankingTransactions = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   const { fiscalId } = useAppSelector((state) =>
      selectTableData(state, { table: "openbanking.transactions" })
   )

   return (
      <Page
         className={classes.root}
         title={t("openbanking.transactions.title")}
      >
         <Container maxWidth={false}>
            <Box mb={6}>
               <FiscalIdForm />
            </Box>

            {fiscalId !== "" && (
               <>
                  <Box mb={4}>
                     <TransactionsFilters />
                  </Box>

                  <TransactionsTable />
               </>
            )}
         </Container>
      </Page>
   )
}

export default OpenbankingTransactions
