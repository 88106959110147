import { ValidateInvoiceEntity } from "services/gov-it"
import {
   Field as FieldFormProps,
   FieldGroup,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import * as Yup from "yup"

export const irregularNamesFields: {
   name: string
   value: string
}[] = [
   {
      name: "aliquota_iva",
      value: "aliquota_i_v_a",
   },
   {
      name: "id_fiscale_iva",
      value: "id_fiscale_i_v_a",
   },
   {
      name: "esigibilita_iva",
      value: "esigibilita_i_v_a",
   },
   {
      name: "dati_ddt",
      value: "dati_d_d_t",
   },
   {
      name: "numero_ddt",
      value: "numero_d_d_t",
   },
   {
      name: "iban",
      value: "_i_b_a_n",
   },
   {
      name: "bic",
      value: "_b_i_c",
   },
   {
      name: "cab",
      value: "_c_a_b",
   },
   {
      name: "abi",
      value: "_a_b_i",
   },
]

export const validationSchemaForFixInvoice = Yup.object().shape({
   fattura_elettronica_body: Yup.array().of(
      Yup.object().shape({
         dati_generali: Yup.object().shape({
            dati_generali_documento: Yup.object().shape({
               data: Yup.string().nullable().required("This field is required"),
            }),
         }),
      })
   ),
})

export function getViolationPropertyPath(
   input: string,
   input_content: string | null,
   getValues: (field: string) => any,
   sections: FieldGroup[]
): string[] {
   let propertyPath: string[] = []

   function handleSetError(fields: FieldFormProps[]) {
      fields.map(({ name, ...rest }) => {
         const tmp = name.split(".")
         const fieldName = tmp[tmp.length - 1]

         irregularNamesFields.forEach((irregularName) => {
            if (
               fieldName === irregularName.name &&
               input === irregularName.value
            ) {
               if (getValues(name)?.trim() === input_content?.trim()) {
                  propertyPath.push(name)
               }
            }
         })

         if (fieldName === input) {
            if (
               (typeof getValues(name) === "string" &&
                  getValues(name)?.trim() == input_content?.trim()) ||
               (getValues(name) == false && input_content == null) ||
               (!getValues(name) && input_content == null)
            ) {
               propertyPath.push(name)
            } else if (Array.isArray(getValues(name))) {
               getValues(name)?.map((item: any, index: number) => {
                  if (
                     (typeof item === "string" &&
                        item.trim() == input_content?.trim()) ||
                     (item == false && input_content == null) ||
                     (typeof item === "number" &&
                        item.toString() == input_content)
                  ) {
                     propertyPath.push(`${name}.${index}`)
                  }
               })
            }
         }

         if (
            rest.type == "arrayObject" &&
            rest.children.length > 0 &&
            Array.isArray(getValues(name))
         ) {
            let children: FieldFormProps[] = []
            getValues(name)?.map((item: any, index: number) => {
               rest.children.map((child) => {
                  children.push({
                     ...child,
                     name: `${name}[${index}].${child.name}`,
                  })
               })
            })
            handleSetError(children)
         }
      })
   }

   sections.map(({ fields, children }) => {
      handleSetError(fields)
      children?.map(({ fields }) => {
         handleSetError(fields)
      })
   })

   return propertyPath
}

export function traverseAndHandleErrors(
   values: any,
   violations: ValidateInvoiceEntity["violations"],
   setFieldError: (field: string, message: string | undefined) => void,
   currentPath: string = ""
) {
   // Se values non è un oggetto o un array, termina la ricorsione
   if (typeof values !== "object" || values === null) {
      return
   }

   // Itera su ogni chiave in values
   for (const key in values) {
      // Calcola il nuovo percorso
      const newPath = currentPath ? `${currentPath}.${key}` : key

      // Controlla se esiste una violazione per questo percorso
      const violation = violations?.find(
         (v) => v.propertyPath === key && v.propertyContent === values[key]
      )
      // Se esiste una violazione, chiama setFieldError
      if (violation) {
         setFieldError(newPath, violation.message)
      }

      // Continua la ricorsione sul valore corrente
      traverseAndHandleErrors(values[key], violations, setFieldError, newPath)
   }
}

export const camelToSnakeCase = (str: string) =>
   str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)

export const mapPropertyPathToFormField = (path: string) => {
   irregularNamesFields.forEach((irregularName) => {
      path = path.replace(irregularName.value, irregularName.name)
   })
   return path
}

export const setViolationsResponse = (
   data: { violations?: { propertyPath: string; message: string }[] } | null,
   setError: any
) => {
   const violations = data?.violations

   if (violations != null) {
      for (const { propertyPath, message } of violations) {
         let path = camelToSnakeCase(propertyPath)
         setError(mapPropertyPathToFormField(path), {
            type: "validation Error",
            message,
         })
      }
   }
}

const ARRAY_NAME_KEY_FORMAT_NUNBER = [
   "aliquota_iva",
   "imposta",
   "imponibile_importo",
   "importo",
   "aliquota",
   "importo_pagamento",
   "importo_bollo",
   "quantita",
]

const ARRAY_NAME_KEY_FORMAT_NUNBER_PARTICOLARE_MIN_2_MAX_8 = [
   "prezzo_totale",
   "prezzo_unitario",
   "importo_totale_documento",
]

export const transformToNumber = (value: string, decimalPlaces?: boolean) => {
   const numberValue = Number(value)
   if (isNaN(numberValue)) {
      return value
   }
   if (!decimalPlaces) {
      return numberValue.toFixed(2).toString()
   }
   const decimal = Math.min(
      Math.max(2, numberValue.toString().split(".")[1]?.length || 0),
      8
   )
   return numberValue.toFixed(decimal).toString()
}

export function formatInvoiceNumber(
   num: string | number | null,
   name?: string
) {
   if (num == null) return ""
   if (name && ARRAY_NAME_KEY_FORMAT_NUNBER.includes(name)) {
      return transformToNumber(num.toString())
   }
   if (
      name &&
      ARRAY_NAME_KEY_FORMAT_NUNBER_PARTICOLARE_MIN_2_MAX_8.includes(name)
   ) {
      return transformToNumber(num.toString(), true)
   }
}

export const formatTextFieldValue = (
   value: any,
   format?: "number" | "uppercase",
   name?: string
) => {
   if (value?.trim() === "" || value === null) {
      return null
   }
   if (format === "number" && !isNaN(Number(value))) {
      return formatInvoiceNumber(value, name)
   }
   if (format === "uppercase" && value) {
      return value.toUpperCase()
   }
   return value
}

export function formatInvoiceData(obj: any): any {
   // Se l'oggetto è null o non è un oggetto, restituisci il valore originale
   if (obj === null || typeof obj !== "object") {
      return obj
   }

   // Se l'oggetto è un array, mappa ogni elemento attraverso questa funzione
   if (Array.isArray(obj)) {
      return obj.map(formatInvoiceData)
   }

   // Altrimenti, mappa ogni chiave-valore attraverso questa funzione
   return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
         // Se il valore è una stringa che può essere convertita in un numero, formattalo
         // trim space from start and end of string
         if (typeof value === "string") {
            value = value.trim()
         }

         if (
            (ARRAY_NAME_KEY_FORMAT_NUNBER.includes(key) ||
               ARRAY_NAME_KEY_FORMAT_NUNBER_PARTICOLARE_MIN_2_MAX_8.includes(
                  key
               )) &&
            typeof value === "string" &&
            !isNaN(Number(value.replace(",", ".")))
         ) {
            return [key, formatInvoiceNumber(value.replace(",", "."), key)]
         }

         // Altrimenti, passa il valore attraverso questa funzione
         return [key, formatInvoiceData(value)]
      })
   )
}

type AnyObject = { [key: string]: any }

export function findKeyValue(
   rootObject: AnyObject,
   keyPath: string
): AnyObject | null {
   const keys = keyPath.split(".")
   let subObject: AnyObject = rootObject

   for (const key of keys) {
      const match = key.match(/(\w+)\[(\d+)\]/)
      if (match) {
         const arrayKey = match[1]
         const index = parseInt(match[2])
         if (subObject[arrayKey] && subObject[arrayKey][index]) {
            subObject = subObject[arrayKey][index]
         } else {
            return null
         }
      } else if (subObject[key]) {
         subObject = subObject[key]
      } else {
         return null
      }
   }

   return subObject
}

type violationRegex = {
   regex: RegExp
   propertyNames: string[]
}
export const violationsRegex: violationRegex[] = [
   {
      regex: /The type of the "(.*)" attribute must be "(.*)", "(.*)" given./,
      propertyNames: ["propertyPath", "message", "propertyContent"],
   },
]

type ParsedResult = {
   [key: string]: string | null
} | null

export function parseResult(
   result: string,
   regex: RegExp,
   propertyNames: string[]
): ParsedResult {
   const match = result.match(regex)

   if (match) {
      let parsedResult: NonNullable<ParsedResult> = {}
      propertyNames.forEach((propertyName, index) => {
         if (propertyName === "message") {
            parsedResult[propertyName] = result
         } else {
            if (match[index + 1] === "NULL") {
               parsedResult[propertyName] = null
            } else parsedResult[propertyName] = match[index + 1]
         }
      })
      return parsedResult
   }

   return null
}
