import { TableCell, TableRow } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import {
   OpenbankingAccount,
   useGetAccountsQuery,
} from "services/openbankingApi/accounts"
import { useAppSelector } from "store"
import { selectTableData, TableName } from "store/tablesState"
import PaymentsCTA from "../PaymentsCTA"
import TransactionsCTA from "../TransactionsCTA"
import EnableAccountToggle from "./EnableAccountToggle"
import ReceivePaymentCTA from "./ReceivePaymentCTA"
import RowCTAs from "./RowCTAs"

// Use a high value to retrieve all the accounts with a single request.
const ITEMS_PER_PAGE = 100

const table: TableName = "openbanking.business_registries"

const columns: TableColumn<OpenbankingAccount>[] = [
   {
      rowKey: "name",
      configurable: true,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.name",
      format: (value) => value,
   },
   {
      rowKey: "iban",
      configurable: true,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.iban",
      format: (value) => value,
   },
   {
      rowKey: "balance",
      configurable: true,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.balance",
      format: (value) => value,
   },
   {
      rowKey: "currencyCode",
      configurable: true,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.currency",
      format: (value) => value,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.payments",
      format: (value, row) =>
         row.enabled ? (
            <PaymentsCTA fiscalId={row.fiscalId} accountUuid={row.uuid} />
         ) : null,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.receive_payment",
      format: (value, row) =>
         row.enabled ? <ReceivePaymentCTA accountUuid={row.uuid} /> : null,
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.transactions",
      format: (value, row) =>
         row.enabled ? (
            <TransactionsCTA fiscalId={row.fiscalId} accountUuid={row.uuid} />
         ) : null,
   },
   {
      rowKey: "enabled",
      configurable: false,
      labelTranslationId:
         "openbanking.business_registry.accounts_table_head.enabled",
      format: (value, row) => (
         <EnableAccountToggle
            accountUuid={row.uuid}
            name={row.name}
            enabled={value as boolean}
         />
      ),
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_, row) => <RowCTAs account={row} />,
   },
]

type Props = {
   fiscalId: string
}

const AccountsTable = ({ fiscalId }: Props) => {
   const { expandedRows, hideInactiveAccounts } = useAppSelector((state) =>
      selectTableData(state, { table })
   )
   const isExpanded = expandedRows.includes(fiscalId)

   const { data, isFetching, isError } = useGetAccountsQuery(
      { fiscalId, itemsPerPage: ITEMS_PER_PAGE },
      { skip: !isExpanded }
   )

   const items =
      data != null
         ? data["hydra:member"]
              .filter(({ enabled }) => !hideInactiveAccounts || enabled)
              // Enabled accounts first
              .sort((a, b) => {
                 if (a.enabled === b.enabled) {
                    return 0
                 }

                 return a.enabled ? -1 : 1
              })
         : undefined

   if (!isExpanded) {
      return null
   }

   return (
      <TableRow>
         <TableCell
            colSpan={9}
            padding="normal"
            style={{ borderBottomWidth: "2px", paddingTop: 0 }}
         >
            <Table
               size="small"
               columnsDefinition={columns}
               rows={items}
               rowKeyId="uuid"
               isFetching={isFetching}
               isError={isError}
               emptyStateTranslationId="openbanking.business_registry.messages.no_connected_accounts"
               withPagination={false}
               withConfigurableColumns={false}
            />
         </TableCell>
      </TableRow>
   )
}

export default AccountsTable
