import { TableName, tablesInitialState, TablesState } from "store/tablesState"

type Table = Extract<TableName, "pl.incoming_invoices" | "pl.outgoing_invoices">
type TableFilters = TablesState[Table]["filters"]
export type TableFilter = keyof TableFilters

export type FormValues = {
   status: string
   createdAfter: string
   createdBefore: string
   invoiceDateAfter: string
   invoiceDateBefore: string
   invoiceCreatorNip: string
   invoiceRecipientNip: string
   legalEntityUuid: string
   invoiceType: string
}
export const mapFormValuesToTableFilters = (
   table: Table,
   formValues: FormValues
): TableFilters =>
   Object.entries(formValues).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value }
   }, tablesInitialState[table].filters)
export const mapTableFiltersToFormValues = (
   table: Table,
   tableFilters: TableFilters
): FormValues =>
   Object.entries(tableFilters).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value }
   }, {} as FormValues)
