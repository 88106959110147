import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { useTranslation } from "react-i18next"
import NotificationButton from "views/common/Notification/NotificationButton"

function OrderDetails(props) {
   const order = props.order
   const { t } = useTranslation()

   return (
      <List>
         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.uuid")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.uuid || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.direction")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.direction?.toUpperCase() || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.format")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.format || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.number")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.number || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.date")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.date || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.sender")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.sender?.name || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.recipient")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.recipient?.name || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.createdAt")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.createdAt || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.processType")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={order.processType?.toUpperCase() || "-"}
               />
            </ListItemSecondaryAction>
         </ListItem>

         {order.processInformation && (
            <ListItem divider={true}>
               <ListItemText primary={t("Orders.Details.orderStatus")} />
               <ListItemSecondaryAction>
                  <ListItemText
                     primary={
                        order.processInformation.orderStatus?.toUpperCase() ||
                        "-"
                     }
                  />
               </ListItemSecondaryAction>
            </ListItem>
         )}

         <ListItem divider={true}>
            <ListItemText primary={t("Orders.Details.Download XML")} />
            <ListItemSecondaryAction>
               <NotificationButton
                  notificationType={t("Orders.Details.Download XML")}
                  onClick={props.handleDownloadXML}
               />
            </ListItemSecondaryAction>
         </ListItem>
      </List>
   )
}

export default function TabPanelDocumentReference(props) {
   const { value, index, order, handleDownloadXML } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && order?.uuid && (
            <Box p={3}>
               <OrderDetails
                  order={order}
                  handleDownloadXML={handleDownloadXML}
               />
            </Box>
         )}
      </div>
   )
}
