import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router"
import { isRouteErrorResponse } from "react-router-dom"
import { ErrorPage } from "views/gov-it/DownloadInvoice/ErrorPage"

const CustomErrorBoundary = () => {
   const error = useRouteError()
   const { t } = useTranslation()

   // get the back url
   const backUrl = window.history.state?.backUrl || "/"

   if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
         return (
            <ErrorPage
               message={t("global.messages.404")}
               redirectUrl={backUrl}
            />
         )
      }
   }

   return (
      <ErrorPage
         message={t("global.messages.generic_error")}
         redirectUrl={backUrl}
      />
   )
}

export default CustomErrorBoundary
