import { Icon } from "@iconify/react"
import { red } from "@material-ui/core/colors"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import React from "react"
import DeleteDialog from "./DeleteDialog"

export function DeleteButton({ title, handleDelete, ...props }) {
   const [isDialogOpen, setIsDialogOpen] = React.useState(false)

   const deleteCallback = () => {
      setIsDialogOpen(true)
   }

   const handleCloseDialog = () => {
      setIsDialogOpen(false)
   }

   const handleConfirm = () => {
      handleDelete()
      setIsDialogOpen(false)
   }

   return (
      <>
         <Tooltip title={title} {...props}>
            <IconButton style={{ color: red[500] }} onClick={deleteCallback}>
               <Icon
                  icon="icon-park-solid:delete-five"
                  width={24}
                  height={24}
               />
            </IconButton>
         </Tooltip>

         <DeleteDialog
            isOpen={isDialogOpen}
            handleClose={handleCloseDialog}
            handleConfirm={handleConfirm}
         />
      </>
   )
}
