import { Box, CircularProgress, Typography } from "@material-ui/core"
import Collapse from "@material-ui/core/Collapse"
import {
   alpha,
   createStyles,
   makeStyles,
   Theme,
   withStyles,
} from "@material-ui/core/styles"
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import { TransitionProps } from "@material-ui/core/transitions"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem"
import TreeView from "@material-ui/lab/TreeView"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ListDirResponse } from "services/transfer"
import { DataTree, removePrefix } from "views/common/CustomTreeView/utils"

function MinusSquare(props: SvgIconProps) {
   return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
         {/* tslint:disable-next-line: max-line-length */}
         <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
   )
}

function PlusSquare(props: SvgIconProps) {
   return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
         {/* tslint:disable-next-line: max-line-length */}
         <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
   )
}

function CloseSquare(props: SvgIconProps) {
   return (
      <SvgIcon
         className="close"
         fontSize="inherit"
         style={{ width: 14, height: 14 }}
         {...props}
      >
         {/* tslint:disable-next-line: max-line-length */}
         <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
   )
}

function SingleComponent(props: TransitionProps) {
   return (
      <div>
         <Collapse {...props} />
      </div>
   )
}

const StyledTreeItem = withStyles((theme: Theme) =>
   createStyles({
      iconContainer: {
         "& .close": {
            opacity: 0.3,
         },
      },
      group: {
         marginLeft: 7,
         paddingLeft: 18,
         borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
   })
)((props: TreeItemProps & { isSelect?: boolean }) => (
   <TreeItem
      {...props}
      TransitionComponent={SingleComponent}
      label={
         props.isSelect ? (
            <Typography style={{ display: "flex", alignItems: "center" }}>
               {props.label}
               <DoneAllIcon style={{ marginLeft: "10px", color: "green" }} />
            </Typography>
         ) : (
            props.label
         )
      }
   />
))

const useStyles = makeStyles(
   createStyles({
      root: {
         height: "auto",
         flexGrow: 1,
         maxWidth: 400,
      },
   })
)

type CustomTreeViewInputProps = {
   handleChange: (value: string) => void
   label: string
   error?: string
   dataTree: DataTree | null
   fetchChildrenFn: any
}

export const CustomTreeViewLoading = () => {
   return (
      <Box
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            border: "2px solid rgb(0 0 0 / 17%)",
            borderRadius: "5px",
            padding: "20px",
         }}
      >
         <Box
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               width: "100%",
               height: "100%",
            }}
         >
            <CircularProgress />
         </Box>
      </Box>
   )
}

export default function CustomTreeViewInput({
   handleChange,
   label,
   error,
   dataTree,
   fetchChildrenFn,
}: CustomTreeViewInputProps) {
   const classes = useStyles()
   const [pathSelected, setPathSelected] = useState<{
      path: string
      id: string
   } | null>(null)
   const { t } = useTranslation()
   const [tree, setTree] = useState<DataTree | null>(dataTree)

   const [expandeds, setExpandeds] = React.useState<string[]>([
      ...(dataTree ? [dataTree.id] : []),
      ...(dataTree?.children ? dataTree.children.map((child) => child.id) : []),
   ])
   const handleNodeSelect = (
      event: React.ChangeEvent<Object>,
      nodeId: string
   ) => {
      if (!tree) {
         return
      }

      if (tree.id === nodeId) {
         setPathSelected({
            path: "/",
            id: nodeId,
         })
         handleChange("/")
         return
      }

      setPathSelected({
         path: tree.id + nodeId,
         id: nodeId,
      })
      handleChange(tree.id + nodeId)
   }

   const updateNodeInTree = (
      node: DataTree,
      nodeId: string,
      newChildren: DataTree[]
   ): DataTree => {
      if (node.id === nodeId) {
         return { ...node, children: newChildren }
      }
      return {
         ...node,
         children:
            node.children?.map((child) =>
               updateNodeInTree(child, nodeId, newChildren)
            ) ?? [],
      }
   }

   const addChildrenOnNode = async (node: DataTree) => {
      if (fetchChildrenFn) {
         const children = (await fetchChildrenFn(node)) as
            | ListDirResponse
            | undefined

         if (children) {
            setTree((prevTree) => {
               if (prevTree) {
                  return updateNodeInTree(
                     prevTree,
                     node.id,
                     children.dirs?.map((dir) => {
                        return {
                           id: dir,
                           name: removePrefix(node.id, dir),
                           children: [],
                        }
                     }) ?? []
                  )
               }
               return null
            })
            setExpandeds((oldExpandeds) => {
               return [...oldExpandeds, node.id]
            })
         }
      }
   }

   return (
      <Box>
         <Box
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "flex-start",
               justifyContent: "center",
               width: "100%",
               height: "100%",
               border: !error ? "2px solid rgb(0 0 0 / 17%)" : "2px solid red",
               borderRadius: "5px",
               padding: "20px",
            }}
         >
            {tree ? (
               <>
                  <Typography variant="body2" style={{ marginBottom: "10px" }}>
                     {label}:
                     <strong
                        style={{
                           marginLeft: "10px",
                           fontSize: "16px",
                           fontStyle: "italic",
                        }}
                     >
                        {pathSelected
                           ? pathSelected.path
                           : t("transfer.ImportInvoice.no_path_selected")}
                     </strong>
                  </Typography>

                  <TreeView
                     className={classes.root}
                     expanded={expandeds}
                     defaultCollapseIcon={<MinusSquare />}
                     defaultExpandIcon={<PlusSquare />}
                     defaultEndIcon={<CloseSquare />}
                     onNodeSelect={handleNodeSelect}
                  >
                     <StyledTreeItem
                        nodeId={tree.id}
                        label={"/"}
                        isSelect={pathSelected?.id === tree.id}
                     >
                        {tree.children?.map((node) => (
                           <DisplayStyledTreeItem
                              key={node.id}
                              node={node}
                              currentPath={pathSelected?.id}
                              fetchChildrenFn={addChildrenOnNode}
                           />
                        ))}
                     </StyledTreeItem>
                  </TreeView>
               </>
            ) : (
               <Box
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     width: "100%",
                     height: "100%",
                  }}
               >
                  <Typography variant="body2">
                     {t("transfer.ImportInvoice.no_data")}
                  </Typography>
               </Box>
            )}
         </Box>

         {error && (
            <Typography
               variant="caption"
               style={{ color: "red", marginTop: "10px" }}
            >
               {error}
            </Typography>
         )}
      </Box>
   )
}

type DisplayStyledTreeItemProps = {
   node: DataTree
   currentPath?: string
   fetchChildrenFn: (node: DataTree) => void
}
const DisplayStyledTreeItem = ({
   node,
   currentPath,
   fetchChildrenFn,
}: DisplayStyledTreeItemProps) => {
   return (
      <StyledTreeItem
         key={node.id}
         nodeId={node.id}
         label={node.name}
         isSelect={currentPath === node.id}
         onClick={() => {
            if (node.children.length === 0) {
               fetchChildrenFn(node)
            }
         }}
      >
         {node.children?.map((child) => (
            <StyledTreeItem
               key={child.id}
               nodeId={child.id}
               label={child.name}
               onClick={() => {
                  if (child.children.length === 0) {
                     fetchChildrenFn(child)
                  }
               }}
               isSelect={currentPath === child.id}
            >
               {child.children?.map((subChild) => (
                  <DisplayStyledTreeItem
                     key={subChild.id}
                     node={subChild}
                     currentPath={currentPath}
                     fetchChildrenFn={fetchChildrenFn}
                  />
               ))}
            </StyledTreeItem>
         ))}
      </StyledTreeItem>
   )
}
