import {
   makeStyles,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import BusinessRegistryTableEntries from "./Entries"
import BusinessRegistryTablePagination from "./Pagination"

const useStyles = makeStyles(() => ({
   table: {
      overflowX: "initial",
   },
}))

const columns = [
   { key: "accounts", translationId: null },
   { translationId: "openbanking.business_registry.table_head.fiscal_id" },
   { translationId: "openbanking.business_registry.table_head.email" },
   { translationId: "openbanking.business_registry.table_head.business_name" },
   { translationId: "openbanking.business_registry.table_head.payments" },
   { translationId: "openbanking.business_registry.table_head.transactions" },
   { translationId: "openbanking.business_registry.table_head.connect" },
   { translationId: "openbanking.business_registry.table_head.enabled" },
   { key: "actions", translationId: null },
]

const BusinessRegistryTable = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Paper>
         <TableContainer className={classes.table}>
            <Table size="medium" stickyHeader>
               <TableHead>
                  <TableRow>
                     {columns.map(({ key, translationId }) => (
                        <TableCell key={translationId ?? key}>
                           {translationId != null && t(translationId)}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  <BusinessRegistryTableEntries />
               </TableBody>
            </Table>
         </TableContainer>

         <BusinessRegistryTablePagination />
      </Paper>
   )
}

export default BusinessRegistryTable
