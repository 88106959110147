import { Box, DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCreateSubAccountMutation } from "services/commonApi"
import SubAccountForm, {
   FormValues,
} from "views/settings/SubAccounts/SubAccountsDialog/SubAccountForm"
import * as Yup from "yup"

const DIALOG_ID = "common_sub_account.new"
type Props = BaseDialogActions

const initialValues = {
   email: "",
   fiscal_id: "",
   fullname: "",
   password: "",
   enabled: false,
}

const validationSchema = Yup.object().shape({
   email: Yup.string().required(),
   fiscal_id: Yup.string().required(),
   password: Yup.string().required(),
})
const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [createSubAccount] = useCreateSubAccountMutation()
   const [error, setError] = useState<string | null>(null)

   const handleSubmit = async (values: FormValues) => {
      setError(null)
      const response = await createSubAccount(values)

      if ("data" in response) {
         enqueueSnackbar(t("common_subAccounts.create_success"), {
            variant: "success",
         })
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         setError((response.error.data as { detail: string }).detail)
         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>{t("common_subAccounts.title_create")}</DialogTitle>

         <DialogContent>
            {error && (
               <Box
                  bgcolor="error.main"
                  color="error.contrastText"
                  p={2}
                  mb={2}
               >
                  {error}
               </Box>
            )}

            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <SubAccountForm
                     formikProps={formikProps}
                     handleClose={handleClose}
                     type="new"
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}
const NewSubAccountDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default NewSubAccountDialog
