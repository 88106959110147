import {
   Box,
   Card,
   CardContent,
   CardHeader,
   Divider,
   FormControl,
   Grid,
   InputLabel,
   makeStyles,
   MenuItem,
   Select,
   Typography,
} from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import getInvoicesStats from "services/client/request/getInvoicesStats"
import { getCurrentYear, getYearsList } from "services/dates"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import InvoicesMonthCost from "views/common/Statistics/InvoicesMonthCost"
import InvoicesMonthCount from "views/common/Statistics/InvoicesMonthCount"
import InvoicesMonthNotificationCount from "views/common/Statistics/InvoicesMonthNotificationCount"
import InvoicesCost from "./InvoicesCost"
import InvoicesCount from "./InvoicesCount"
import SdiNotifications from "./SdiNotifications"

const useStyles = makeStyles((theme) => ({
   selectYear: {
      minWidth: "200px",
   },
}))

const Statistics = ({ fiscalId }) => {
   const classes = useStyles()
   const currentYear = getCurrentYear()
   const yearsList = getYearsList(currentYear, 2020)
   const { t } = useTranslation()

   const [selectedYear, setSelectedYear] = useState(currentYear)
   const [statsData, setStatsData] = useState({})

   const isNotAllowedToSeeCosts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "common.api.acubeapi.com",
         role: "ROLE_NO_COSTS",
      })
   )

   const apiCall = useCallback((year, queryParams) => {
      ;(async () => {
         try {
            const res = await getInvoicesStats(year, queryParams)
            setStatsData(res)
         } catch (e) {
            // This should occur only for 404s
            // TODO: provide more specific feedbacks for 5xx errors
            setStatsData({})
         }
      })()
   }, [])

   useEffect(() => {
      apiCall(selectedYear, { fiscal_id: fiscalId })
   }, [selectedYear])

   const isVisibleMonthStats = statsData?.stats?.months?.length > 0

   return (
      <Card>
         <CardHeader
            title={t("statistics.title.generic")}
            action={
               <FormControl className={classes.selectYear}>
                  <InputLabel htmlFor="statistics-year">
                     {t("statistics.text.select_year")}
                  </InputLabel>
                  <Select
                     value={selectedYear}
                     onChange={(e) => {
                        setSelectedYear(e.target.value)
                     }}
                     inputProps={{
                        name: "year",
                        id: "statistics-year",
                     }}
                  >
                     {yearsList.map((year) => (
                        <MenuItem key={year} value={year}>
                           {year}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            }
         />
         <Divider />
         <CardContent>
            {statsData.stats ? (
               <Grid container spacing={3}>
                  <Grid item xs={12} container spacing={3}>
                     <Grid item xs={12} md={isNotAllowedToSeeCosts ? 4 : 6}>
                        <InvoicesCount
                           invoicesSent={
                              statsData.stats?.customer_invoices.count
                           }
                           invoicesReceived={
                              statsData.stats?.supplier_invoice.count
                           }
                           detailOnLeft={isNotAllowedToSeeCosts}
                        />
                     </Grid>

                     {!isNotAllowedToSeeCosts && (
                        <Grid item xs={12} md={6}>
                           <InvoicesCost
                              invoicesSentCost={Number(
                                 statsData.stats?.customer_invoices.cost
                              )}
                              invoicesReceivedCost={Number(
                                 statsData.stats?.supplier_invoice.cost
                              )}
                           />
                        </Grid>
                     )}

                     {isVisibleMonthStats && (
                        <Grid item xs={12} md={isNotAllowedToSeeCosts ? 8 : 6}>
                           <InvoicesMonthCount
                              monthStats={statsData.stats?.months}
                           />
                        </Grid>
                     )}

                     {!isNotAllowedToSeeCosts && isVisibleMonthStats && (
                        <Grid item xs={12} md={6}>
                           <InvoicesMonthCost
                              monthStats={statsData.stats?.months}
                           />
                        </Grid>
                     )}

                     <Grid
                        item
                        xs={12}
                        md={
                           isNotAllowedToSeeCosts
                              ? isVisibleMonthStats
                                 ? 6
                                 : 8
                              : isVisibleMonthStats
                              ? 6
                              : 12
                        }
                     >
                        <SdiNotifications
                           data={statsData.stats?.notifications}
                        />
                     </Grid>

                     {isVisibleMonthStats && (
                        <Grid item xs={12} md={isNotAllowedToSeeCosts ? 6 : 6}>
                           <InvoicesMonthNotificationCount
                              monthStats={statsData.stats?.months}
                           />
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            ) : (
               <Grid container spacing={3} alignItems="center" justify="center">
                  <Grid item>
                     <Box m={10}>
                        <Typography
                           variant="h3"
                           color="textSecondary"
                           align="center"
                        >
                           {t("statistics.text.no_data_1", {
                              year: selectedYear,
                           })}
                        </Typography>
                        <Typography variant="h5" align="center">
                           {t("statistics.text.no_data_2")}
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            )}
         </CardContent>
      </Card>
   )
}

export default Statistics
