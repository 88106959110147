import {
   Box,
   Card,
   CardContent,
   colors,
   makeStyles,
   Typography,
   useTheme,
} from "@material-ui/core"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles(() => ({
   text_percentage: {
      fontSize: "14px",
   },
}))

const NOTIFICATIONS_COLOR = {
   AT: colors.pink[300],
   DT: colors.cyan[300],
   MC: colors.amber[300],
   NE: colors.green[300],
   NS: colors.purple[300],
   RC: colors.deepOrange[300],
}

const InvoicesMonthNotificationCount = ({ className, monthStats }) => {
   const classes = useStyles()
   const theme = useTheme()
   const { t } = useTranslation()

   const data = {
      datasets: [
         {
            label: "AT",
            data: monthStats.map((month) => month.notifications.at),
            backgroundColor: NOTIFICATIONS_COLOR.AT,
         },
         {
            label: "DT",
            data: monthStats.map((month) => month.notifications.dt),
            backgroundColor: NOTIFICATIONS_COLOR.DT,
         },
         {
            label: "MC",
            data: monthStats.map((month) => month.notifications.mc),
            backgroundColor: NOTIFICATIONS_COLOR.MC,
         },
         {
            label: "NE",
            data: monthStats.map((month) => month.notifications.ne),
            backgroundColor: NOTIFICATIONS_COLOR.NE,
         },
         {
            label: "NS",
            data: monthStats.map((month) => month.notifications.ns),
            backgroundColor: NOTIFICATIONS_COLOR.NS,
         },
         {
            label: "RC",
            data: monthStats.map((month) => month.notifications.rc),
            backgroundColor: NOTIFICATIONS_COLOR.RC,
         },
      ],
      labels: [
         "January",
         "February",
         "March",
         "April",
         "May",
         "June",
         "July",
         "August",
         "September",
         "October",
         "November",
         "December",
      ],
   }

   const options = {
      animation: false,
      cutoutPercentage: 80,
      layout: { padding: 0 },
      legend: {
         display: true,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
         backgroundColor: theme.palette.background.default,
         bodyFontColor: theme.palette.text.secondary,
         borderColor: theme.palette.divider,
         borderWidth: 2,
         enabled: true,
         footerFontColor: theme.palette.text.secondary,
         intersect: false,
         mode: "index",
         titleFontColor: theme.palette.text.primary,
      },
      scales: {
         xAxes: [
            {
               stacked: true,
            },
         ],
         yAxes: [
            {
               stacked: true,
            },
         ],
      },
   }

   return (
      <Card className={clsx(classes.root, className)}>
         <CardContent>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t("statistics.title.sdi_notifications_at_month")}
            </Typography>
            <Box
               display="flex"
               justifyContent="space-around"
               mt={2}
               flexDirection={"column"}
            >
               <Box mt={2} height={300} position="relative">
                  <Bar options={options} data={data} />
               </Box>
            </Box>
         </CardContent>
      </Card>
   )
}

InvoicesMonthNotificationCount.propTypes = {
   className: PropTypes.string,
   monthStats: PropTypes.array,
}

InvoicesMonthNotificationCount.defaultProps = {
   monthStats: [],
}

export default InvoicesMonthNotificationCount
