import {
   Box,
   Card,
   CardContent,
   CardHeader,
   Divider,
   makeStyles,
   Typography,
} from "@material-ui/core"
import clsx from "clsx"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
   root: {},
}))

const CodiceDestinatario = (props, { className, ...rest }) => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Card className={clsx(classes.root, className)} {...rest}>
         <CardHeader
            subheader={t("User.Questo è il tuo codice destinatario associato")}
            title={t("User.Codice Destinatario")}
         />
         <Divider />
         <CardContent>
            <Box alignItems="center" display="flex" flexDirection="column">
               <Typography color="textPrimary" style={{ fontSize: "48px" }}>
                  {props.userRecipientCode.recipient_code}
               </Typography>
            </Box>
         </CardContent>
      </Card>
   )
}

CodiceDestinatario.propTypes = {
   className: PropTypes.string,
}

export default CodiceDestinatario
