import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

const NewPreSaleActionCTA = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const handleOpenDialog = () => {
      dispatch(openDialog({ id: "settings_new_pre_sale_action" }))
   }

   return (
      <Button color="primary" variant="contained" onClick={handleOpenDialog}>
         {t("settings.pre_sale_actions.new_pre_sale_action_cta")}
      </Button>
   )
}

export default NewPreSaleActionCTA
