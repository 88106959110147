import { ListDirResponse } from "services/transfer"

export const listDirsToDataTree = (
   data: ListDirResponse | undefined
): DataTree | null => {
   if (!data) {
      return null
   }
   return {
      id: data.root_dir,
      name: data.root_dir,
      children:
         data.dirs?.map((dir) => {
            return {
               id: dir,
               name: dir,
               children: [],
            }
         }) ?? [],
   }
}

export const removePrefix = (rootDir: string, dir: string): string => {
   return dir.replace(rootDir, "")
}

export type DataTree = {
   id: string
   name: string
   children: DataTree[]
}
