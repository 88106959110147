import { Divider } from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { Notification } from "services/gov-it"
import LabelNotification from "views/common/LabelNotification"

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
   },
}))

type Props = {
   notification: Notification
}
export function NSNotificationDetails({ notification }: Props) {
   const classes = useStyles()
   const { t } = useTranslation()

   const errors = notification?.message?.lista_errori
      .map(({ Errore }) => Errore)
      .flat()

   return (
      <List className={classes.root}>
         <ListItem>
            <ListItemText primary="UUID" />
            <ListItemSecondaryAction>
               <ListItemText primary={notification?.uuid} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Tipo")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={<LabelNotification value={notification?.type} />}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Data ora ricezione")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={notification?.message?.data_ora_ricezione}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Identificativo SDI")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={notification?.message?.identificativo_sdi}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Nome file")} />
            <ListItemSecondaryAction>
               <ListItemText primary={notification?.message?.nome_file} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText
               primary={t("CustomerInvoices.Note")}
               secondary={notification?.message?.note}
            />
         </ListItem>

         <Divider />

         {errors.map(({ Codice, Descrizione, Suggerimento }, i) => {
            return (
               <>
                  <ListItem>
                     <ListItemText
                        primary={t("CustomerInvoices.Errore codice")}
                        primaryTypographyProps={{ variant: "subtitle2" }}
                     />
                     <ListItemSecondaryAction>
                        <ListItemText
                           primary={Codice}
                           primaryTypographyProps={{ variant: "subtitle2" }}
                        />
                     </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                     <ListItemText
                        primary={t("CustomerInvoices.Errore descrizione")}
                        secondary={Descrizione}
                        secondaryTypographyProps={{ color: "error" }}
                     />
                  </ListItem>
                  {Suggerimento != null && (
                     <ListItem>
                        <ListItemText
                           primary={t("CustomerInvoices.Errore suggerimento", {
                              i: i + 1,
                           })}
                           secondary={Suggerimento}
                        />
                     </ListItem>
                  )}
               </>
            )
         })}
      </List>
   )
}
