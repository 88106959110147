import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import en from "./translations/en.json"
import it from "./translations/it.json"
import pl from "./translations/pl.json"

const resources = {
   it: {
      translation: it,
   },
   en: {
      translation: en,
   },
   pl: {
      translation: pl,
   },
}

i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      resources,
      detection: {
         lookupCookie: "language",
         lookupLocalStorage: "language",
         order: [
            "querystring",
            "cookie",
            "localStorage",
            "sessionStorage",
            "navigator",
            "htmlTag",
         ],
      },
      fallbackLng: "en",
      keySeparator: ".",
      interpolation: {
         escapeValue: false,
      },
      debug: true,
   })
   .then(function (t) {
      switch (i18n.language) {
         case "it":
            i18n.changeLanguage("it")
            break
         case "pl":
            i18n.changeLanguage("pl")
            break
         default:
            i18n.changeLanguage("en")
      }
   })
