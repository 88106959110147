import { govPlApi } from ".."
import type { GovPLLegalEntity } from "../legalEntities"

type GetOnboardingLegalEntityResponse = Pick<
   GovPLLegalEntity,
   | "authFlowCreatedAt"
   | "authFlowDeadline"
   | "authFlowIsValid"
   | "authFlowStatus"
   | "authFlowUUID"
   | "authFlowUpdatedAt"
   | "authFlowUrl"
> & {
   customer: {
      addressLine: string
      city: string
      countryCode: null
      customerName: string
      nip: number
   }
   nip: string
}

type InitOnboardResponse = {
   url: string
}

type CreateTokenResponse = {
   uuid: string
   authorizationToken: string
   isActive: boolean
   isSelected: boolean
}

type CreateTokenBody = {
   authorizationToken: string
   autoSelect: boolean
}

type StartOnboardResponse = {
   authFlowUuid: string
   time: string
}

type ActivateLegalEntityBody = {
   type: "onip"
   identifier: string
   signedFile: string
}

const onboardApi = govPlApi.injectEndpoints({
   endpoints: (builder) => ({
      initOnboard: builder.mutation<InitOnboardResponse, { uuid: string }>({
         query: ({ uuid }) => ({
            method: "POST",
            url: `/legal-entities/${uuid}/onboard/init`,
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
      }),

      createToken: builder.mutation<
         CreateTokenResponse,
         CreateTokenBody & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            method: "POST",
            url: `/legal-entities/${uuid}/ksef-token/create`,
            body,
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
      }),

      getOnboardingLegalEntity: builder.query<
         GetOnboardingLegalEntityResponse,
         { uuid: string; hash: string }
      >({
         query: ({ uuid, hash }) => ({
            url: `/onboarding/${uuid}/${hash}`,
         }),
         providesTags: ["LEGAL_ENTITIES"],
         extraOptions: { requireAuth: false },
      }),

      startOnboard: builder.mutation<
         StartOnboardResponse,
         { uuid: string; hash: string }
      >({
         query: ({ uuid, hash }) => ({
            method: "POST",
            url: `/legal-entities/${uuid}/onboard/start/${hash}`,
         }),
         invalidatesTags: ["LEGAL_ENTITIES"],
         extraOptions: { requireAuth: false },
      }),

      downloadOnboardFile: builder.query<
         unknown,
         { uuid: string; hash: string }
      >({
         query: ({ uuid, hash }) => ({
            url: `/authorization-flows/${uuid}/onboard/signature-file/${hash}`,
            responseHandler: (response) => response.text(),
         }),
         extraOptions: { requireAuth: false },
      }),

      activateLegalEntity: builder.mutation<
         unknown,
         { uuid: string; hash: string; body: ActivateLegalEntityBody }
      >({
         query: ({ uuid, hash, body }) => ({
            method: "POST",
            url: `/legal-entities/${uuid}/onboard/activate/${hash}`,
            body,
         }),
         extraOptions: { requireAuth: false },
      }),
   }),
})

export const {
   useGetOnboardingLegalEntityQuery,
   useInitOnboardMutation,
   useCreateTokenMutation,
   useStartOnboardMutation,
   useLazyDownloadOnboardFileQuery,
   useActivateLegalEntityMutation,
} = onboardApi
