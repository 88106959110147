import { Box, Drawer, Hidden, List, makeStyles } from "@material-ui/core"
import ListSubheader from "@material-ui/core/ListSubheader"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "store"
import { selectActiveContext, selectEnabledContexts } from "store/app"
import { selectIsUserStripe, selectRolesForContext } from "store/auth"
import { NAVBARCONTEXTITEMS } from "views/layout/NavBarContextItems"
import { selectHasPid } from "../../store/auth"
import ListMenu from "./ListMenu"
import NavItem from "./NavItem"

const useStyles = makeStyles((theme) => ({
   mobileDrawer: {
      backgroundColor: theme.palette.background.dark,
      width: 256,
      top: 56,
      height: "calc(100% - 56px)",
      scrollbarWidth: "none",
   },
   desktopDrawer: {
      backgroundColor: theme.palette.background.dark,
      width: 256,
      top: 64,
      height: "calc(100% - 64px)",
      scrollbarWidth: "none",
   },
   companyName: {
      color: theme.palette.text.white,
   },
   companyDetails: {
      color: theme.palette.text.white,
   },
}))

const NavBarItem = ({ item }) => {
   const isSubAccount = useAppSelector((state) => selectHasPid(state))
   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))
   const roles = useAppSelector((state) =>
      selectRolesForContext(state, item.rolesContext?.context)
   )
   const hasSubArray = (arr, target) => target.every((v) => arr.includes(v))

   const isAllowedRole =
      !item.hasOwnProperty("subheader") &&
      (!item.rolesContext ||
         ((item.rolesContext?.rolesToAllowed
            ? roles.length > 0
               ? item.rolesContext.rolesToAllowed.some((role) => {
                    if (item.rolesContext.onlyForSubAccount && !isSubAccount) {
                       return true
                    }

                    return roles.includes(role)
                 })
               : false
            : roles.length > 0) &&
            (item.rolesContext?.rolesToDenied
               ? roles.length > 0
                  ? !hasSubArray(roles, item.rolesContext?.rolesToDenied)
                  : true
               : roles.length > 0)))

   const isAllowed =
      isAllowedRole &&
      (item.rolesContext?.allowOnlyForStripeAccount
         ? item.rolesContext?.allowOnlyForStripeAccount && isStripeUser
         : true)

   const isAllowedSubheaderRole =
      item.hasOwnProperty("subheader") &&
      (!item.rolesContext ||
         ((item.rolesContext?.rolesToAllowed
            ? roles.length > 0
               ? roles.some((role) => {
                    return item.rolesContext?.rolesToAllowed.includes(role)
                 })
               : false
            : roles.length > 0) &&
            (item.rolesContext?.rolesToDenied
               ? roles.length > 0
                  ? !hasSubArray(roles, item.rolesContext?.rolesToDenied)
                  : false
               : roles.length > 0)))

   const isAllowedSubheader =
      isAllowedSubheaderRole &&
      (item.rolesContext?.allowOnlyForStripeAccount
         ? item.rolesContext?.allowOnlyForStripeAccount && isStripeUser
         : true)

   const { t } = useTranslation()

   return item.hasOwnProperty("subheader") ? (
      isAllowedSubheader ? (
         <ListSubheader disableGutters={true}>
            {t(item.subheader).toUpperCase()}
         </ListSubheader>
      ) : null
   ) : isAllowed ? (
      <NavItem
         href={item.href}
         external={item.external}
         title={t(item.title)}
         icon={item.icon}
      />
   ) : null
}

const navItemsAllowedForPid = [
   "Menu.Dashboard",
   "Menu.Integrazione",
   "Menu.Business Registry",
   "Menu.Fatture Passive",
   "Menu.Fatture Attive",
   "Menu.gov_it.invoice_transfers",
   "Menu.gov_it.invoice_drafts",
   "Menu.Documentazione",
   "Menu.Forum",
   "acubeapi.com",
   "Menu.News archive",
   "Menu.Newsletter subscribe",
]

const navBarSubheaderAllowedForPid = [
   "Menu.Fatture",
   "Menu.Utility",
   "Menu.Newsletter",
]

/*const navItemsAllowedForStripeUser = [
   "Menu.Fatture Attive",
   "Menu.Fatture Passive",
]*/

//const navBarSubheaderAllowedForStripeUser = ["Menu.Fatture"]

const NavBar = ({ onMobileClose, openMobile }) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const { pathname } = useLocation()
   const enabledContexts = useAppSelector(selectEnabledContexts)
   const activeContext = useAppSelector(selectActiveContext)
   const userHasPid = useAppSelector((state) => selectHasPid(state))
   const isStripeUser = useAppSelector((state) => selectIsUserStripe(state))

   const navbarFilterForPid = (item) => {
      if (!userHasPid) return true
      if (item.hasOwnProperty("subheader"))
         return navBarSubheaderAllowedForPid.includes(item.subheader)
      return navItemsAllowedForPid.includes(item.title)
   }

   /*const navbarFilterForStripeUser = (item) => {
      if (!isStripeUser) return true
      if (item.hasOwnProperty("subheader"))
         return navBarSubheaderAllowedForStripeUser.includes(item.subheader)
      return navItemsAllowedForStripeUser.includes(item.title)
   }*/

   useEffect(() => {
      if (openMobile && onMobileClose) {
         onMobileClose()
      }
   }, [pathname])

   const content = (
      <Box height="100%" display="flex" flexDirection="column">
         <Box p={2}>
            <List disablePadding>
               {enabledContexts.length > 1 && (
                  <>
                     <ListSubheader
                        key="menu-item-context"
                        disableGutters={true}
                     >
                        {t("Menu.Contesto").toUpperCase()}
                     </ListSubheader>
                     <ListMenu />
                  </>
               )}
               {NAVBARCONTEXTITEMS[activeContext]
                  ?.filter(navbarFilterForPid)
                  //?.filter(navbarFilterForStripeUser)
                  .map((item, index) => (
                     <NavBarItem item={item} key={"menu-item-" + index} />
                  ))}

               {/*{isStripeUser &&
                  UTILITY_STRIPE.map((item, index) => (
                     <NavBarItem
                        item={
                           item.title === "FAQ"
                              ? {
                                   ...item,
                                   href: t("Menu.stripe_utility_FAQ_URL"),
                                }
                              : { ...item }
                        }
                        key={"menu-item-" + index}
                     />
                  ))}*/}
            </List>
         </Box>
         <Box flexGrow={1} />
      </Box>
   )

   return (
      <>
         <Hidden lgUp>
            <Drawer
               anchor="left"
               classes={{ paper: classes.mobileDrawer }}
               onClose={onMobileClose}
               open={openMobile}
               variant="temporary"
            >
               {content}
            </Drawer>
         </Hidden>
         <Hidden mdDown>
            <Drawer
               anchor="left"
               classes={{ paper: classes.desktopDrawer }}
               open
               variant="persistent"
            >
               {content}
            </Drawer>
         </Hidden>
      </>
   )
}

NavBar.propTypes = {
   onMobileClose: PropTypes.func,
   openMobile: PropTypes.bool,
}

NavBar.defaultProps = {
   onMobileClose: () => {},
   openMobile: false,
}

export default NavBar
