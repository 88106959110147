import { Box, IconButton, Tooltip } from "@material-ui/core"
import { Description, OpenInNew } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { Invoice as PlInvoice } from "services/gov-pl/invoices"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"
import DownloadInvoicePdf from "views/pl/components/DownloadInvoicePdf"
import DownloadInvoiceXml from "views/pl/components/DownloadInvoiceXml"

type Props = {
   invoice: PlInvoice
}

const RowCTAs = ({ invoice }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   return (
      <Box display="flex" gridGap={-4}>
         <DownloadInvoicePdf invoiceUuid={invoice.uuid} asIcon />

         <DownloadInvoiceXml invoiceUuid={invoice.uuid} asIcon />

         <Tooltip title={t("pl.invoices.ctas.invoice_details")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "pl_invoice_details",
                        data: invoice,
                     })
                  )
               }}
            >
               <Description />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("pl.invoices.ctas.go_to_invoice")}>
            <IconButton
               onClick={() => {
                  window.open(`/gov-pl/invoices/${invoice.uuid}`)
               }}
            >
               <OpenInNew />
            </IconButton>
         </Tooltip>
      </Box>
   )
}

export default RowCTAs
