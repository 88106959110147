import ReactDOM from "react-dom"
import { RouterProvider } from "react-router"
import "typeface-roboto"
import "./i18n"
import { router } from "./router"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
   <RouterProvider router={router} />,
   document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
