import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { Marking } from "../../Marking"

import { useTranslation } from "react-i18next"

function OrderResponseDetails(props) {
   const order = props.order
   const { t } = useTranslation()

   return (
      <List>
         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.uuid")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.uuid || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.direction")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.direction?.toUpperCase() || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.format")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.format || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.number")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.number || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.date")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.date || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.sender")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.sender?.name || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.recipient")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.recipient?.name || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem divider={true}>
            <ListItemText primary={t("OrderResponses.Details.createdAt")} />
            <ListItemSecondaryAction>
               <ListItemText primary={order.createdAt || "-"} />
            </ListItemSecondaryAction>
         </ListItem>

         {order.processInformation?.responseCode && (
            <ListItem divider={true}>
               <ListItemText
                  primary={t("OrderResponses.Details.responseCode")}
               />
               <ListItemSecondaryAction>
                  <Marking marking={order.processInformation.responseCode} />
               </ListItemSecondaryAction>
            </ListItem>
         )}
      </List>
   )
}

export default function TabPanelInfo(props) {
   const { value, index, order } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && order?.uuid && (
            <Box p={3}>
               <OrderResponseDetails order={order} />
            </Box>
         )}
      </div>
   )
}
