import { commonApi } from "./index"

type LoginResponse = { token: string }
export const authCommonApi = commonApi.injectEndpoints({
   endpoints: (builder) => ({
      login: builder.mutation<
         LoginResponse,
         { email: string; password: string }
      >({
         query: (body) => ({
            url: "/login",
            method: "POST",
            body,
         }),
      }),

      askResetPassword: builder.mutation<void, { email: string }>({
         query: ({ ...body }) => ({
            url: "/ask-reset-password",
            method: "POST",
            body,
         }),
      }),
   }),
})

export const { useLoginMutation, useAskResetPasswordMutation } = authCommonApi
