import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { CUSTOMER_INVOICE_TYPE } from "config"
import moment from "moment"
import { useEffect } from "react"
import formatInvoice from "services/client/formatter/formatInvoice"
import { useGetDraftInvoicesQuery } from "services/gov-it"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectRefetch,
   selectTableCheckedColumns,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   TableName,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import { Marking } from "views/common/Marking"
import ActionsButtons from "views/gov-it/InvoiceDrafts/Buttons/ActionsButtons"
import ActionsCheckedFooterTableButtons from "views/gov-it/InvoiceDrafts/Buttons/ActionsCheckedFooterTableButtons"
import RowCTAs from "views/gov-it/InvoiceDrafts/Table/RowCTAs"

const table: TableName = "invoice.drafts"
export type InvoiceDraftTableConfigurableColumns = NonNullable<
   (typeof columnsDefinition)[number]["rowKey"]
>

const DraftsInvoicesTable = () => {
   const dispatch = useAppDispatch()
   const { sender, recipient, ...filters } = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const { data, isFetching, isError } = useGetDraftInvoicesQuery({
      ...(filters.uuid
         ? { uuid: filters.uuid.trim() }
         : {
              ...filters,
              createdAt: {
                 after: `${filters.createdAt.after} 00:00:00`,
                 before: `${filters.createdAt.before} 23:59:59`,
              },
              ...(filters.invoiceDate.after && filters.invoiceDate.before
                 ? {
                      invoiceDate: {
                         after: `${filters.invoiceDate.after} 00:00:00`,
                         before: `${filters.invoiceDate.before} 23:59:59`,
                      },
                   }
                 : {}),
              ...(sender.fiscalId ? { sender: sender.fiscalId } : {}),
              ...(recipient.fiscalId ? { recipient: recipient.fiscalId } : {}),
              ...(sender.businessName
                 ? { "sender.business_name": sender.businessName }
                 : {}),
              ...(recipient.businessName
                 ? { "recipient.business_name": recipient.businessName }
                 : {}),
              type: CUSTOMER_INVOICE_TYPE,
              ...(itemsPerPage === null ? {} : { itemsPerPage }),
              ...(currentPage === null ? {} : { page: currentPage }),
              ...(refresh === null ? {} : { refetch: refresh }),
           }),
   })

   const items = data
      ? "hydra:member" in data
         ? data["hydra:member"]?.map((row: GovITInvoice) => {
              return formatInvoice(row) as GovITInvoice
           })
         : [formatInvoice(data) as GovITInvoice]
      : undefined

   const fetchedTotalItems =
      data && "hydra:totalItems" in data ? data["hydra:totalItems"] : null

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            BottomButtonActions={
               checkedColumns.length > 0 ? (
                  <ActionsCheckedFooterTableButtons invoices={items} />
               ) : null
            }
            buttonActions={<ActionsButtons invoices={items} />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="CustomerInvoices.no_data"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withChecked={true}
            onCheckedColumnsChange={(checked) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "checkedColumns",
                     value: checked,
                  })
               )
            }}
            checkedColumns={checkedColumns}
            checkedButtonActions={
               <ActionsCheckedFooterTableButtons invoices={items} />
            }
         />
      </Paper>
   )
}

export default DraftsInvoicesTable

const columnsDefinition: TableColumn<GovITInvoice>[] = [
   {
      rowKey: "created_at",
      labelTranslationId: "CustomerInvoices.Creata il",
      format: (value) => moment(value as string).format("DD MMM YYYY HH:mm:ss"),
      configurable: true,
   },
   {
      rowKey: "senderFormatted",
      labelTranslationId: "CustomerInvoices.Mittente",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "recipientFormatted",
      labelTranslationId: "CustomerInvoices.Destinatario",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "senderVatNumberFormatted",
      labelTranslationId: "CustomerInvoices.sender_vat_number",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "recipientVatNumberFormatted",
      labelTranslationId: "CustomerInvoices.recipient_vat_number",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "invoiceDate",
      labelTranslationId: "CustomerInvoices.InvoiceData",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "invoiceNumber",
      labelTranslationId: "CustomerInvoices.InvoiceNumber",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "invoiceTotal",
      labelTranslationId: "CustomerInvoices.invoice_total",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "document_type",
      labelTranslationId: "CustomerInvoices.document_type",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "marking",
      labelTranslationId: "CustomerInvoices.Marking",
      format: (value) => {
         return <Marking marking={value} />
      },
      configurable: true,
   },
   {
      rowKey: null,
      labelTranslationId: null,
      format: (value, item) => <RowCTAs item={item} />,
      configurable: false,
   },
]
