import {
   makeStyles,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import InvoicesDemoTableEntries from "./Entries"

const useStyles = makeStyles((theme) => ({
   container: {
      overflowX: "initial",
   },
}))

const InvoicesDemoTable = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Paper>
         <TableContainer className={classes.container}>
            <Table size="medium" stickyHeader>
               <TableHead>
                  <TableRow>
                     <TableCell />

                     <TableCell component="td">
                        {t(
                           "invoices_demo.batch_invoices.table_head.invoice_creator_nip"
                        )}
                     </TableCell>

                     <TableCell component="td">
                        {t(
                           "invoices_demo.batch_invoices.table_head.invoice_recipient_nip"
                        )}
                     </TableCell>

                     <TableCell component="td">
                        {t(
                           "invoices_demo.batch_invoices.table_head.invoice_number"
                        )}
                     </TableCell>

                     <TableCell component="td">
                        {t("invoices_demo.batch_invoices.table_head.direction")}
                     </TableCell>

                     <TableCell component="td">
                        {t("invoices_demo.batch_invoices.table_head.status")}
                     </TableCell>
                  </TableRow>
               </TableHead>

               <TableBody>
                  <InvoicesDemoTableEntries />
               </TableBody>
            </Table>
         </TableContainer>
      </Paper>
   )
}

export default InvoicesDemoTable
