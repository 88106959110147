import {
   Box,
   Button,
   Checkbox,
   CircularProgress,
   FormControlLabel,
   Grid,
   IconButton,
   Tooltip,
} from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import DeleteIcon from "@material-ui/icons/Delete"
import { Field, FieldArray, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { useGetDocumentTypesQuery } from "services/peppol/documentTypes"
import { useGetProcessesQuery } from "services/peppol/processes"
import PeppolSelect from "../Select"

export type FormValues = {
   registeredName: string
   country: string
   address: string
   city: string
   stateOrProvince: string
   zipCode: string
   identifierScheme: string
   identifierValue: string
   receivedDocumentNotificationEmails: string
   smpEnabled?: boolean
   capabilities?: {
      documentType: string
      process: string
   }[]
}

type LegalEntityField = {
   name: keyof FormValues
   labelTranslationId: string
   type: "textfield" | "checkbox"
   columnSize: 4 | 6 | 12
}

const fields: LegalEntityField[] = [
   {
      name: "registeredName",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_registered_name",
      type: "textfield",
      columnSize: 12,
   },
   {
      name: "identifierScheme",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_identifier_scheme",
      type: "textfield",
      columnSize: 6,
   },
   {
      name: "identifierValue",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_identifier_value",
      type: "textfield",
      columnSize: 6,
   },
   {
      name: "address",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_address",
      type: "textfield",
      columnSize: 6,
   },
   {
      name: "zipCode",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_zip_code",
      type: "textfield",
      columnSize: 6,
   },
   {
      name: "country",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_country",
      type: "textfield",
      columnSize: 4,
   },
   {
      name: "stateOrProvince",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_state_or_province",
      type: "textfield",
      columnSize: 4,
   },
   {
      name: "city",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_city",
      type: "textfield",
      columnSize: 4,
   },
   {
      name: "smpEnabled",
      labelTranslationId:
         "peppol.legal_entities.edit_legal_entity_form.field_smp_enabled",
      type: "checkbox",
      columnSize: 12,
   },
]

type Props = {
   onCancel: VoidFunction
   formikProps: FormikProps<FormValues>
   type: "create" | "update"
}

const Form = ({ onCancel, formikProps, type }: Props) => {
   const { t } = useTranslation()
   const { data: documentTypes } = useGetDocumentTypesQuery({})
   const { data: processes } = useGetProcessesQuery({})

   const filteredFields =
      type === "create"
         ? fields.filter(({ name }) => name !== "smpEnabled")
         : fields

   const {
      handleSubmit,
      touched,
      values,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={2}>
            {filteredFields.map(
               ({ name, labelTranslationId, columnSize, ...field }) => (
                  <Grid key={name} item xs={columnSize}>
                     {field.type === "textfield" && (
                        <Field
                           component={TextField}
                           type="text"
                           label={t(labelTranslationId)}
                           name={name}
                           helperText={touched[name] != null && errors[name]}
                           error={touched[name] != null && errors[name] != null}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           fullWidth
                        />
                     )}

                     {field.type === "checkbox" && (
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={values[name] as boolean}
                                 onChange={handleChange}
                                 name={name}
                                 color="primary"
                              />
                           }
                           label={t(labelTranslationId)}
                        />
                     )}
                  </Grid>
               )
            )}

            <FieldArray name="capabilities">
               {({ remove, push }) =>
                  values.capabilities != null && (
                     <>
                        <Grid item xs={12}>
                           <Box textAlign="right">
                              <Button
                                 color="default"
                                 variant="contained"
                                 onClick={() => {
                                    push({
                                       documentType: "",
                                       process: "",
                                    })
                                 }}
                              >
                                 {t(
                                    "peppol.legal_entities.edit_legal_entity_form.add_capability"
                                 )}
                              </Button>
                           </Box>
                        </Grid>

                        {values.capabilities.length > 0 &&
                           values.capabilities.map((_, index) => (
                              <>
                                 <Grid item xs={6}>
                                    <PeppolSelect
                                       labelId="peppol.legal_entities.edit_legal_entity_form.field_document_type"
                                       name={`capabilities[${index}].documentType`}
                                       value={
                                          values.capabilities![index]
                                             .documentType
                                       }
                                       touched={
                                          // @ts-expect-error
                                          touched.capabilities?.[index]
                                             ?.documentType
                                       }
                                       error={
                                          // prettier-ignore
                                          // @ts-expect-error
                                          errors.capabilities?.[index]?.documentType && "Required"
                                       }
                                       options={[
                                          { label: "", value: "" },
                                          ...documentTypes!["hydra:member"].map(
                                             ({ name, value }) => ({
                                                label: name,
                                                value,
                                             })
                                          ),
                                       ]}
                                       onChange={handleChange}
                                    />
                                 </Grid>

                                 <Grid item xs={5}>
                                    <PeppolSelect
                                       labelId="peppol.legal_entities.edit_legal_entity_form.field_process_scheme"
                                       name={`capabilities[${index}].process`}
                                       value={
                                          values.capabilities![index].process
                                       }
                                       touched={
                                          // @ts-expect-error
                                          touched.capabilities?.[index]?.process
                                       }
                                       error={
                                          // prettier-ignore
                                          // @ts-expect-error
                                          errors.capabilities?.[index]?.process && "Required"
                                       }
                                       options={[
                                          { label: "", value: "" },
                                          ...processes!["hydra:member"].map(
                                             ({ value }) => ({
                                                label: value,
                                                value,
                                             })
                                          ),
                                       ]}
                                       onChange={handleChange}
                                    />
                                 </Grid>

                                 <Grid item xs={1}>
                                    <Tooltip title={t("global.remove")}>
                                       <IconButton
                                          style={{ color: red[600] }}
                                          onClick={() => {
                                             remove(index)
                                          }}
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Grid>
                              </>
                           ))}
                     </>
                  )
               }
            </FieldArray>
         </Grid>

         <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={8}
            mt={4}
            mb={1}
         >
            <Button color="default" variant="contained" onClick={onCancel}>
               {t("global.cancel")}
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {!isSubmitting && t("global.submit")}
               {isSubmitting && <CircularProgress color="inherit" size={22} />}
            </Button>
         </Box>
      </form>
   )
}

export default Form
