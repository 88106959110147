import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasContext } from "store/auth"
import Page from "views/common/components/Page"
import PreservationDocumentDetailsDialog from "views/Preservation/Documents/DetailsDialog"
import PreservationDocumentsTable from "views/Preservation/Documents/Table"
import PreservationDocumentsFilters from "./Filters"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const PreservationDocuments = () => {
   const navigate = useNavigate()
   const classes = useStyles()
   const { t } = useTranslation()

   const isAllowedToReadPreservation = useAppSelector((state) =>
      selectHasContext(state, "preservation.api.acubeapi.com")
   )

   useEffect(() => {
      if (!isAllowedToReadPreservation) {
         navigate("/dashboard", { replace: true })
      }
   }, [isAllowedToReadPreservation, navigate])

   if (!isAllowedToReadPreservation) {
      return null
   }

   return (
      <>
         <Page
            className={classes.root}
            title={t("Preservation.documents.title")}
         >
            <Container maxWidth={false}>
               <PreservationDocumentsFilters />

               <PreservationDocumentsTable />
            </Container>
         </Page>

         <PreservationDocumentDetailsDialog />
      </>
   )
}

export default PreservationDocuments
