import { useTranslation } from "react-i18next"
import {
   AuthFlowStatus,
   LegalEntityStatus,
} from "services/gov-pl/legalEntities"
import Label from "views/common/Label"

type Props = {
   authFlowStatus: AuthFlowStatus | null
   authFlowIsValid: boolean | null
   legalEntityStatus: LegalEntityStatus
}

const OnboardStatus = ({
   authFlowStatus,
   authFlowIsValid,
   legalEntityStatus,
}: Props) => {
   const { t } = useTranslation()

   // Onboard initialized
   if (authFlowStatus === AuthFlowStatus.NEW) {
      return (
         <Label color="warning">
            {t("legal_entities.onboard_status.initialized")}
         </Label>
      )
   }

   // Onboard started
   if (authFlowStatus === AuthFlowStatus.IN_PROGRESS) {
      // Valid
      if (authFlowIsValid != null && authFlowIsValid) {
         return (
            <Label color="warning">
               {t("legal_entities.onboard_status.started")}
            </Label>
         )
      }

      // Expired
      if (authFlowIsValid != null && !authFlowIsValid) {
         return (
            <Label color="error">
               {t("legal_entities.onboard_status.expired")}
            </Label>
         )
      }
   }

   // Onboard waiting result
   if (authFlowStatus === AuthFlowStatus.USED) {
      return (
         <Label color="warning">
            {t("legal_entities.onboard_status.waiting_result")}
         </Label>
      )
   }

   // Onboard completed
   if (
      legalEntityStatus === LegalEntityStatus.ACTIVE ||
      authFlowStatus === AuthFlowStatus.COMPLETED
   ) {
      return (
         <Label color="success">
            {t("legal_entities.onboard_status.completed")}
         </Label>
      )
   }

   if (authFlowStatus === AuthFlowStatus.ERROR) {
      return (
         <Label color="error">{t("legal_entities.onboard_status.error")}</Label>
      )
   }

   return null
}

export default OnboardStatus
