import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import EditWebhookDialog from "./EditWebhookDialog"
import NewWebhookCTA from "./NewWebhookCTA"
import NewWebhookDialog from "./NewWebhookDialog"
import WebhooksTable from "./Table"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const OpenbankingWebhooks = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <>
         <Page className={classes.root} title={t("openbanking.webhooks.title")}>
            <Container maxWidth={false}>
               <Box mb={3}>
                  <Box mb={3}>
                     <NewWebhookCTA />
                  </Box>

                  <WebhooksTable />
               </Box>
            </Container>
         </Page>

         <NewWebhookDialog />
         <EditWebhookDialog />
      </>
   )
}

export default OpenbankingWebhooks
