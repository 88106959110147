import { Box, Button, Grid, makeStyles } from "@material-ui/core"
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"

export type FormValues = {
   email: string
   nip: string
   name: string
   addressLine1: string
   addressLine2: string
   postcode: string
   city: string
}

const useStyles = makeStyles((theme) => ({
   actions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      marginTop: theme.spacing(2),
   },
}))

const fields = [
   {
      name: "email",
      labelTranslationId: "legal_entities.create_legal_entity.fields.email",
   },
   {
      name: "name",
      labelTranslationId: "legal_entities.create_legal_entity.fields.name",
   },
   {
      name: "nip",
      labelTranslationId: "legal_entities.create_legal_entity.fields.nip",
   },
   {
      name: "addressLine1",
      labelTranslationId:
         "legal_entities.create_legal_entity.fields.address_line_1",
   },
   {
      name: "addressLine2",
      labelTranslationId:
         "legal_entities.create_legal_entity.fields.address_line_2",
   },
   {
      name: "postcode",
      labelTranslationId: "legal_entities.create_legal_entity.fields.postcode",
   },
   {
      name: "city",
      labelTranslationId: "legal_entities.create_legal_entity.fields.city",
   },
] as const

type Props = {
   onCancel: VoidFunction
   formikProps: FormikProps<FormValues>
}

const EditLegalEntityForm = ({ onCancel, formikProps }: Props) => {
   const classes = useStyles()
   const { t } = useTranslation()

   const {
      handleSubmit,
      touched,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={3}>
            {fields.map(({ name, labelTranslationId }) => (
               <Grid key={name} item md={12} xs={12}>
                  <Field
                     component={TextField}
                     type="text"
                     label={t(labelTranslationId)}
                     name={name}
                     helperText={touched[name] != null && errors[name]}
                     error={touched[name] != null && errors[name] != null}
                     onBlur={handleBlur}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
            ))}
         </Grid>

         <Box className={classes.actions}>
            <Button color="default" variant="contained" onClick={onCancel}>
               {t("global.cancel")}
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {t("global.confirm")}
            </Button>
         </Box>
      </form>
   )
}

export default EditLegalEntityForm
