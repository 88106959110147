import { Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TableSmartReceiptKeyType } from "services/gov-it/smartReceipt/type"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type ActionsButtonsProps = {
   table: TableSmartReceiptKeyType
}
const ActionsButtons = ({ table }: ActionsButtonsProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   return (
      <Grid
         container
         style={{
            marginBottom: "10px",
         }}
      >
         <Grid
            item
            xs={12}
            md={6}
            style={{
               padding: "5px",
            }}
         >
            {table === "smart_receipt.sale" && (
               <Button
                  onClick={() => {
                     dispatch(
                        openDialog({
                           id: "smartReceipt.form",
                           data: {
                              mode: "new",
                           },
                        })
                     )
                  }}
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
               >
                  {t("smartReceipt.new_receipt")}
               </Button>
            )}
         </Grid>
         <Grid item xs={false} md={6} />
      </Grid>
   )
}

export default ActionsButtons
