import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { download } from "helper/download"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { AuthFlowStatus } from "services/gov-pl/legalEntities"
import {
   useLazyDownloadOnboardFileQuery,
   useStartOnboardMutation,
} from "services/gov-pl/onboard"
import { useAppDispatch } from "store"
import { setOnboardMode1CurrentStep } from "store/slices/onboard"

type Props = {
   authFlowStatus: AuthFlowStatus
   authFlowUuid: string
}

const DownloadSignatureFile = ({ authFlowStatus, authFlowUuid }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const { legalEntityUuid, hash } = useParams()
   const [downloaded, setDownloaded] = useState(false)

   const [startOnboard, { isLoading }] = useStartOnboardMutation()
   const [downloadOnboardFile, { isLoading: isDownloading }] =
      useLazyDownloadOnboardFileQuery()

   const isOnboardStarting = isLoading || isDownloading

   const handleDownload = async () => {
      if (authFlowStatus === AuthFlowStatus.NEW) {
         const startOnboardResponse = await startOnboard({
            uuid: legalEntityUuid!,
            hash: hash!,
         })

         if ("error" in startOnboardResponse) {
            enqueueSnackbar(t("onboard.error.generic"), { variant: "error" })
            return
         }
      }

      const response = await downloadOnboardFile({
         uuid: authFlowUuid,
         hash: hash!,
      })

      if ("error" in response) {
         enqueueSnackbar(t("onboard.error.generic"), { variant: "error" })
         return
      }

      if ("data" in response) {
         download(response.data, "signature_file.xml")
         setDownloaded(true)
         return
      }
   }

   const handleGoToNextStep = () => {
      dispatch(setOnboardMode1CurrentStep("sign"))
   }

   return (
      <>
         <Box textAlign="center">
            <Typography variant="h2">
               {t("onboard.steps.download.title")}
            </Typography>
         </Box>

         <Box marginTop={6} marginBottom={6}>
            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.download.body_1")}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.download.body_2")}
               </Typography>
            </Box>

            <Box marginBottom={2}>
               <Typography variant="body1">
                  {t("onboard.steps.download.body_3")}
               </Typography>
            </Box>
         </Box>

         <Box display="flex" justifyContent="center">
            <Button
               disabled={isOnboardStarting}
               color="primary"
               variant="contained"
               size="large"
               onClick={handleDownload}
            >
               {!isOnboardStarting && t("onboard.steps.download.download_cta")}
               {isOnboardStarting && (
                  <CircularProgress color="inherit" size={22} />
               )}
            </Button>
         </Box>

         <Box textAlign="right" marginTop={8}>
            <Button
               disabled={!downloaded}
               color="primary"
               variant="contained"
               size="large"
               onClick={handleGoToNextStep}
            >
               {t("onboard.steps.download.next_cta")}
            </Button>
         </Box>
      </>
   )
}

export default DownloadSignatureFile
