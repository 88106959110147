import {
   Box,
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   Divider,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   makeStyles,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import { PreservationDocumentStatus } from "services/preservation"
import { DialogId, DialogState } from "store/slices/dialogs"
import { Status } from "views/Preservation/Documents/components/Status"

const DIALOG_ID: DialogId = "preservation.document_details"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ item, handleClose }: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const inOrderStatusHistory = {
      ...(item.status_history?.to_be_stored
         ? { to_be_stored: item.status_history?.to_be_stored }
         : {}),
      ...(item.status_history?.sent ? { sent: item.status_history?.sent } : {}),
      ...(item.status_history?.sent_metadata
         ? { sent_metadata: item.status_history?.sent_metadata }
         : {}),
      ...(item.status_history?.stored
         ? { stored: item.status_history?.stored }
         : {}),
   }

   return (
      <>
         <DialogTitle>
            <Typography variant="h3" gutterBottom>
               {t("Preservation.documents.details_dialog.title")}
            </Typography>

            <Divider />
         </DialogTitle>

         <DialogContent className={classes.content}>
            <Typography variant="h5" gutterBottom>
               {t("Preservation.documents.details_dialog.list_status_history")}
            </Typography>
            <List dense>
               {item.status_history ? (
                  Object.entries(inOrderStatusHistory).map(([key, value]) => (
                     <>
                        <ListItem
                           key={key}
                           style={{
                              margin: "0.2rem 0",
                           }}
                        >
                           <Box
                              display="flex"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                           >
                              <Status
                                 status={key as PreservationDocumentStatus}
                              />
                              <ListItemSecondaryAction>
                                 {value && (
                                    <Typography variant="body1">
                                       {moment(value).format(
                                          "DD MMM YYYY HH:mm:ss"
                                       )}
                                    </Typography>
                                 )}
                              </ListItemSecondaryAction>
                           </Box>
                        </ListItem>

                        <Divider />
                     </>
                  ))
               ) : (
                  <ListItem>
                     <ListItemText
                        primary={t(
                           "Preservation.documents.details_dialog.list_status_history.no_data"
                        )}
                     />
                  </ListItem>
               )}
            </List>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const PreservationDocumentDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="sm" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default PreservationDocumentDetailsDialog
