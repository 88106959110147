import { HydraResponse } from "services/types"
import { peppolApi, PeppolPaginationParams } from ".."

export type PeppolDocumentType = {
   scheme: string
   name: string
   value: string
   deprecated: boolean
}

type GetDocumentTypesResponse = HydraResponse<PeppolDocumentType>
type GetDocumentTypesParams = PeppolPaginationParams

const peppolDocumentTypesApi = peppolApi.injectEndpoints({
   endpoints: (builder) => ({
      getDocumentTypes: builder.query<
         GetDocumentTypesResponse,
         GetDocumentTypesParams
      >({
         query: () => ({
            url: "/document-types",
            headers: {
               accept: "application/ld+json",
            },
         }),
         providesTags: ["PEPPOL_DOCUMENT_TYPES"],
      }),
   }),
})

export const { useGetDocumentTypesQuery } = peppolDocumentTypesApi
