import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { peppolApi } from "services/peppol"
import { preservationApi } from "services/preservation"
import { commonApi } from "../services/commonApi"
import { authCommonApi } from "../services/commonApi/authCommonApi"
import { govItApi } from "../services/gov-it"
import { govPlApi } from "../services/gov-pl"
import { openbankingApi } from "../services/openbankingApi"
import { transferApi } from "../services/transfer"
import appReducer from "./app"
import { reducer as authReducer } from "./auth"
import { listenerMiddleware } from "./listener"
import {
   name as dialogsReducerName,
   reducer as dialogsReducer,
} from "./slices/dialogs"
import invoicesDemoRecuder from "./slices/invoicesDemo"
import onboardReducer from "./slices/onboard"
import userReducer from "./slices/user"
import {
   name as tablesStateName,
   reducer as tablesStateReducer,
} from "./tablesState"

const makeStore = () => {
   return configureStore({
      reducer: {
         app: appReducer,
         auth: authReducer,
         onboard: onboardReducer,
         invoicesDemo: invoicesDemoRecuder,
         [dialogsReducerName]: dialogsReducer,
         user: userReducer,
         [tablesStateName]: tablesStateReducer,
         [commonApi.reducerPath]: commonApi.reducer,
         [peppolApi.reducerPath]: peppolApi.reducer,
         [authCommonApi.reducerPath]: authCommonApi.reducer,
         [govItApi.reducerPath]: govItApi.reducer,
         [transferApi.reducerPath]: transferApi.reducer,
         [govPlApi.reducerPath]: govPlApi.reducer,
         [openbankingApi.reducerPath]: openbankingApi.reducer,
         [preservationApi.reducerPath]: preservationApi.reducer,
      },
      middleware: (getDefaultMiddlewares) =>
         getDefaultMiddlewares().concat(
            listenerMiddleware.middleware,
            commonApi.middleware,
            peppolApi.middleware,
            govItApi.middleware,
            transferApi.middleware,
            govPlApi.middleware,
            openbankingApi.middleware,
            preservationApi.middleware
         ),
   })
}

const store = makeStore()
export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
