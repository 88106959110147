import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import moment from "moment"
import { useEffect, useMemo } from "react"
import { useGetSmartReceiptsQuery } from "services/gov-it/smartReceipt"
import {
   SmartReceipt,
   TableSmartReceiptKeyType,
   TypeSmartReceipt,
} from "services/gov-it/smartReceipt/type"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectRefetch,
   selectTableCheckedColumns,
   selectTableColumns,
   selectTableFilters,
   selectTotalItems,
   updateData,
   updateTotalItems,
} from "store/tablesState"
import { Marking } from "views/common/Marking"
import {
   getFormattedSmartReceipt,
   getSmartReceiptQueryParams,
} from "../../helpers"
import ActionsButtons from "../ActionsButtons"
import RowCTAs from "./RowCTAs"
export type SmartReceiptTableConfigurableColumns = NonNullable<
   TableColumn<SmartReceipt>[][number]["rowKey"]
>

type SmartReceiptsTableProps = {
   type: TypeSmartReceipt
   table: TableSmartReceiptKeyType
}
const SmartReceiptsTable = ({ table, type }: SmartReceiptsTableProps) => {
   const dispatch = useAppDispatch()

   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )

   const selectedColumns = useAppSelector((state) =>
      selectTableColumns(state, { table })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   const refresh = useAppSelector((state) => selectRefetch(state, { table }))

   const { data, isFetching, isError } = useGetSmartReceiptsQuery({
      ...getSmartReceiptQueryParams({
         filters,
         itemsPerPage,
         currentPage,
         refresh,
         type,
      }),
   })

   const items = useMemo(() => getFormattedSmartReceipt(data), [data])

   const fetchedTotalItems =
      data && "hydra:totalItems" in data ? data["hydra:totalItems"] : null

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems, table])

   return (
      <Paper>
         <Table
            columnsDefinition={columnsDefinition({ table })}
            rows={items}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            buttonActions={<ActionsButtons table={table} />}
            withConfigurableColumns={true}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={(updatedColumns) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "columns",
                     value: updatedColumns,
                  })
               )
            }}
            emptyStateTranslationId="smartReceipt.no_data"
            withPagination={true}
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}

            /*
            withChecked={true}
            checkedColumns={checkedColumns}
            onCheckedColumnsChange={(checked) => {
               dispatch(
                  updateData({
                     table: table,
                     param: "checkedColumns",
                     value: checked,
                  })
               )
            }}
            checkedButtonActions={
               <ActionsCheckedFooterTableButtons table={table} />
            }*/
         />
      </Paper>
   )
}

export default SmartReceiptsTable

type ColumnDefinitionProps = {
   table: TableSmartReceiptKeyType
}
const columnsDefinition = ({
   table,
}: ColumnDefinitionProps): TableColumn<SmartReceipt>[] => [
   {
      rowKey: "created_at",
      labelTranslationId: "smartReceipt.Creata il",
      format: (value) => moment(value as string).format("DD MMM YYYY HH:mm:ss"),
      configurable: true,
   },
   {
      rowKey: "status",
      labelTranslationId: "smartReceipt.status",
      format: (value) => <Marking marking={value} />,
      configurable: true,
   },
   {
      rowKey: "fiscal_id",
      labelTranslationId: "smartReceipt.fiscalId",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "total_amount",
      labelTranslationId: "smartReceipt.total_amount",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "transaction_id",
      labelTranslationId: "smartReceipt.transaction_id",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: "document_date",
      labelTranslationId: "smartReceipt.document_date",
      format: (value) =>
         value ? moment(value as string).format("DD MMM YYYY HH:mm:ss") : "-",
      configurable: true,
   },
   {
      rowKey: "document_number",
      labelTranslationId: "smartReceipt.document_number",
      format: (value) => value,
      configurable: true,
   },
   {
      rowKey: null,
      labelTranslationId: null,
      format: (value, item) => <RowCTAs item={item} table={table} />,
      configurable: false,
   },
]
