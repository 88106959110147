import { HydraResponse } from "services/types"
import { openbankingApi, OpenbankingPaginationParams } from ".."

export enum OpenbankingWebhookEvent {
   CONNECT = "connect",
   RECONNECT = "reconnect",
   PAYMENT = "payment",
}

export enum OpenbankingWebhookAuthenticationType {
   QUERY = "query",
   HEADER = "header",
}

export type OpenbankingWebhook = {
   uuid: string
   event: OpenbankingWebhookEvent
   targetUrl: string
   authenticationType: OpenbankingWebhookAuthenticationType | null
   authenticationKey: string | null
   authenticationToken: string | null
}

type GetWebhookParams = void
type GetWebhooksParams = OpenbankingPaginationParams
export type CreateOpenbankingWebhookBody = Omit<OpenbankingWebhook, "uuid">
type UpdateWebhookBody = Omit<OpenbankingWebhook, "uuid">
type DeleteWebhookParams = void

const openbankingWebhooksApi = openbankingApi.injectEndpoints({
   endpoints: (builder) => ({
      getWebhooks: builder.query<
         HydraResponse<OpenbankingWebhook>,
         GetWebhooksParams
      >({
         query: (params) => ({
            url: "/webhooks",
            params,
            headers: {
               Accept: "application/ld+json",
            },
         }),
         providesTags: ["WEBHOOKS"],
      }),

      getWebhook: builder.query<
         OpenbankingWebhook,
         GetWebhookParams & { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/webhooks/${uuid}`,
         }),
      }),

      createWebhook: builder.mutation<
         OpenbankingWebhook,
         CreateOpenbankingWebhookBody
      >({
         query: ({ ...body }) => ({
            url: "/webhooks",
            method: "POST",
            body,
         }),
         invalidatesTags: ["WEBHOOKS"],
      }),

      updateWebhook: builder.mutation<
         OpenbankingWebhook,
         UpdateWebhookBody & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/webhooks/${uuid}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["WEBHOOKS"],
      }),

      deleteWebhook: builder.mutation<void, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `/webhooks/${uuid}`,
            method: "DELETE",
         }),
         invalidatesTags: ["WEBHOOKS"],
      }),
   }),
})

export const {
   useGetWebhookQuery,
   useGetWebhooksQuery,
   useCreateWebhookMutation,
   useUpdateWebhookMutation,
   useDeleteWebhookMutation,
} = openbankingWebhooksApi
