import { Box, Button, CircularProgress } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useResetDemoInvoicesMutation } from "services/gov-pl/demoInvoices"

const ResetCta = () => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [resetDemoInvoices, { isLoading }] = useResetDemoInvoicesMutation()

   const handleReset = async () => {
      const response = await resetDemoInvoices()

      if ("error" in response) {
         enqueueSnackbar(t("invoices_demo.error.generic"), { variant: "error" })
         return
      }

      if ("data" in response) {
         enqueueSnackbar(t("invoices_demo.batch_invoices.success.reset"), {
            variant: "success",
         })
         return
      }
   }

   return (
      <Button
         color="primary"
         disabled={isLoading}
         fullWidth
         size="large"
         type="button"
         variant="contained"
         onClick={handleReset}
      >
         {isLoading ? (
            <>
               <Box display="flex" mr={1}>
                  <CircularProgress size={24} color="inherit" />{" "}
               </Box>
               {t("invoices_demo.batch_invoices.reset_cta_loading")}
            </>
         ) : (
            t("invoices_demo.batch_invoices.reset_cta")
         )}
      </Button>
   )
}

export default ResetCta
