import { Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import BaseDialog from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import formatInvoice from "services/client/formatter/formatInvoice"
import {
   useGetDraftInvoiceQuery,
   useGetInvoiceNotificationsQuery,
   useGetInvoicePreservationDocumentQuery,
   useGetInvoiceQuery,
   useGetIsFixableInvoiceQuery,
} from "services/gov-it"
import { useAppSelector } from "store"
import { selectHasContext, selectHasRole } from "store/auth"
import { selectRefetch, TableName } from "store/tablesState"
import InvoiceDialogTabs from "views/common/InvoiceDetails/InvoiceDialogTabs"
import InvoiceDraftsDialogTabs from "views/gov-it/InvoiceDrafts/Components/InvoiceDraftsDialogTabs"
import { isFixableErrorCode } from "views/gov-it/InvoiceFix/ErrorCodeGuard"
import { getLastNSNotification } from "views/gov-it/InvoiceFix/utils"
import Loader from "../Loader"

type Props = {
   table: TableName
   invoiceUuid: string
   mode:
      | "info"
      | "attachments"
      | "preservations"
      | "json"
      | "xml"
      | "preview"
      | "actions"
   handleClose: () => void
}
function Content({ table, invoiceUuid, mode, handleClose }: Props) {
   const classes = useStyles()
   const refresh = useAppSelector((state) => selectRefetch(state, { table }))
   const { t } = useTranslation()
   const { data: notificationsData, isFetching } =
      useGetInvoiceNotificationsQuery(
         { uuid: invoiceUuid },
         { skip: table === "invoice.drafts" }
      )

   const isFixableError =
      notificationsData == null ||
      getLastNSNotification(notificationsData)?.message?.lista_errori?.some(
         ({ Errore }) => {
            if (Array.isArray(Errore)) {
               return Errore.some(({ Codice }) => isFixableErrorCode(Codice))
            }
            //@ts-ignore
            return isFixableErrorCode(Errore?.Codice)
         }
      )

   const {
      data: invoiceData,
      isLoading,
      error,
   } = useGetInvoiceQuery(
      {
         uuid: invoiceUuid,
         ...(refresh && { refresh }),
      },
      { skip: table === "invoice.drafts" }
   )

   const { data: invoiceIsFixableData } = useGetIsFixableInvoiceQuery({
      uuid: invoiceUuid,
   })

   const hasRoleToFixInvoice = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_FIX_INVOICE",
      })
   )
   const hasContextToFixInvoice = useAppSelector((state) =>
      selectHasContext(state, "stripe.acubeapi.com")
   )

   const isAllowedToFixInvoice =
      (hasRoleToFixInvoice || hasContextToFixInvoice) &&
      invoiceIsFixableData?.is_fixable

   const {
      data: invoiceDraftData,
      isLoading: isLoadingDraft,
      error: errorDraft,
   } = useGetDraftInvoiceQuery(
      {
         uuid: invoiceUuid,
         ...(refresh && { refresh }),
      },
      {
         skip: table !== "invoice.drafts",
      }
   )

   const { data: preservedDocument } = useGetInvoicePreservationDocumentQuery(
      {
         invoiceUuid: invoiceData?.uuid as string,
         ...(refresh && { refresh }),
      },
      { skip: invoiceData?.uuid == null || table == "invoice.drafts" }
   )

   const invoice = invoiceData
      ? formatInvoice(invoiceData)
      : invoiceDraftData
      ? formatInvoice(invoiceDraftData)
      : null

   const notifications = notificationsData ?? []

   return (
      <>
         {isLoading || isFetching || isLoadingDraft ? (
            <Loader />
         ) : (
            <DialogContent>
               {error || errorDraft ? (
                  <>
                     <Typography
                        variant="h5"
                        style={{
                           textAlign: "center",
                        }}
                        component="h2"
                     >
                        something went wrong
                     </Typography>
                  </>
               ) : table === "invoice.drafts" ? (
                  <InvoiceDraftsDialogTabs
                     //@ts-ignore
                     mode={mode}
                     invoice={invoice}
                     notifications={notifications}
                  />
               ) : (
                  <InvoiceDialogTabs
                     table={table}
                     mode={mode}
                     invoice={invoice}
                     isAllowedToFixInvoice={isAllowedToFixInvoice}
                     notifications={isFixableError ? notifications : null}
                     preservedDocument={preservedDocument ?? null}
                  />
               )}
            </DialogContent>
         )}

         <DialogActions className={classes.buttons}>
            <Button onClick={handleClose} color="default" variant="contained">
               {t("Default.Chiudi")}
            </Button>
         </DialogActions>
      </>
   )
}

type props = {
   table: TableName
}
export default function InvoiceDialog({ table }: props) {
   return (
      <BaseDialog id="gov_it_invoices_details" maxWidth="lg" fullWidth>
         {(data) => <Content table={table} {...data} />}
      </BaseDialog>
   )
}

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      marginRight: 12,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
