import styles from "./index.module.css"

type Props = {
   size: number
}

const AnimatedCross = ({ size }: Props) => {
   return (
      <svg
         className={styles.cross}
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 52 52"
         width={size}
         height={size}
      >
         <circle
            className={styles.cross__circle}
            cx="26"
            cy="26"
            r="25"
            fill="none"
         />
         <path
            className={styles.cross__check}
            fill="none"
            d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
         />
      </svg>
   )
}

export default AnimatedCross
