import { useTranslation } from "react-i18next"
import { LegalEntityStatus } from "services/gov-pl/legalEntities"
import Label from "views/common/Label"

type Props = {
   legalEntityStatus: LegalEntityStatus
}

const Status = ({ legalEntityStatus }: Props) => {
   const { t } = useTranslation()

   if (legalEntityStatus === LegalEntityStatus.PENDING) {
      return <Label color="warning">{t("legal_entities.status.pending")}</Label>
   }

   if (legalEntityStatus === LegalEntityStatus.ACTIVE) {
      return <Label color="success">{t("legal_entities.status.active")}</Label>
   }

   if (legalEntityStatus === LegalEntityStatus.BLOCKED) {
      return <Label color="error">{t("legal_entities.status.blocked")}</Label>
   }

   return null
}

export default Status
