export const STATUS_NEW = "new"
export const STATUS_RETRY = "retry"
export const STATUS_SUBMITTED = "submitted"
export const STATUS_READY = "ready"
export const STATUS_FAILED = "failed"
export const STATUS_VOIDED = "voided"

export const SmartReceiptStatus = [
   STATUS_NEW,
   STATUS_RETRY,
   STATUS_SUBMITTED,
   STATUS_READY,
   STATUS_FAILED,
   STATUS_VOIDED,
]
export type SmartReceiptStatusType = (typeof SmartReceiptStatus)[number]

export const TYPE_SALE = "sale"
export const TYPE_RETURN = "return"
export const TYPE_VOID = "void"

export type SmartReceipt = {
   return_receipts: ItemReceipt[]
   voiding_receipt: ItemReceipt
   returned_receipt: ItemReceipt
   voided_receipt: ItemReceipt
   uuid: string
   type: TypeSmartReceipt
   status: SmartReceiptStatusType
   fiscal_id: string
   created_at: string
   total_amount: number
   transaction_id: string
   document_number: string
   document_date: string
}

export type ItemReceipt = {
   uuid: string
   type: TypeSmartReceipt
   status: SmartReceiptStatusType
   fiscal_id: string
   created_at: string
   total_amount: number
   transaction_id: string
   document_number: string
   document_date: string
}

export type SmartReceiptDetails = {
   total_taxable_amount: number
   total_uncollected_amount: number
   deductible_amount: number
   total_vat_amount: number
   total_discount: number
   total_gross_discount: number
   discount: number
   items: SmartReceiptItemPDFDetail[]
   uuid: string
   type: TypeSmartReceipt
   status: SmartReceiptStatusType
   fiscal_id: string
   created_at: string
   total_amount: number
   transaction_id: string
   document_number: string
   document_date: string
}

export type SmartReceiptItemPDFDetail = {
   id: string
   quantity: number
   description: string
   gross_price: number
   vat_rate: string
   gross_discount: number
   complimentary: boolean
   unit_price: number
   return: number
   taxable_amount: number
   net_taxable_amount: number
   vat_amount: number
   total_amount: number
   unit_discount: number
}

export type CreateSmartReceiptBody = {
   fiscal_id: string
   lottery_code: string | null
   items: CreateSmartReceiptIem[]
   invoice_issuing: boolean | null
   services_uncollected_amount: number | null
   goods_uncollected_amount: number | null
   cash_payment_amount: number | null
   electronic_payment_amount: number | null
   ticket_restaurant_payment_amount: number | null
   ticket_restaurant_quantity: number | null
   discount: number | null
   linked_receipt: string | null
}

export type CreateSmartReceiptIem = {
   quantity: string
   description: string
   unit_price: string
   vat_rate_code:
      | "4"
      | "5"
      | "10"
      | "22"
      | "2"
      | "6.4"
      | "7"
      | "7.3"
      | "7.5"
      | "7.65"
      | "7.95"
      | "8.3"
      | "8.5"
      | "8.8"
      | "9.5"
      | "12.3"
      | "N1"
      | "N2"
      | "N3"
      | "N4"
      | "N5"
      | "N6"
   discount: string | null
   complimentary: boolean | null
}

export type CreateReturnItemsInSmartReceipt = {
   items: {
      id: string
      quantity: string
   }[]
}

export type TypeSmartReceipt =
   | typeof TYPE_SALE
   | typeof TYPE_RETURN
   | typeof TYPE_VOID

export type TableSmartReceiptKeyType =
   | "smart_receipt.return"
   | "smart_receipt.void"
   | "smart_receipt.sale"
