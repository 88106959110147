import { Paper } from "@material-ui/core"
import Table, { TableColumn } from "components/Table"
import { format, parseISO } from "date-fns"
import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
   Invoice as GovPLInvoice,
   useGetInvoicesQuery,
} from "services/gov-pl/invoices"
import { useAppDispatch, useAppSelector } from "store"
import {
   changePage,
   selectCurrentPage,
   selectItemsPerPage,
   selectTableFilters,
   selectTotalItems,
   TablesState,
   updateTotalItems,
} from "store/tablesState"
import Label from "views/common/Label"
import RowCTAs from "./RowCTAs"

const filtersToQueryParams = ({
   status,
   createdAfter,
   createdBefore,
   invoiceDateAfter,
   invoiceDateBefore,
   ...others
}: TablesState[
   | "pl.incoming_invoices"
   | "pl.outgoing_invoices"]["filters"]) => ({
   invoiceStatus: status,
   "createdAt[after]": createdAfter,
   "createdAt[before]": createdBefore,
   "invoiceDate[after]": invoiceDateAfter,
   "invoiceDate[before]": invoiceDateBefore,
   ...others,
})

const statusToColor = {
   valid: "neutral",
   sent: "primary",
   delivered: "warning",
   confirmed: "success",
   error: "error",
}

const columns: TableColumn<GovPLInvoice>[] = [
   {
      rowKey: "createdAt",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.created_at",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "dd MMM yyyy HH:mm:ss")
            : null,
   },
   {
      rowKey: "invoiceDate",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.invoice_date",
      format: (value) =>
         typeof value === "string"
            ? format(parseISO(value), "yyyy-MM-dd")
            : null,
   },
   {
      rowKey: "invoiceNumber",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.invoice_number",
      format: (value) => value,
   },
   {
      rowKey: "senderName",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.sender_name",
      format: (value) => value,
   },
   {
      rowKey: "recipientName",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.recipient_name",
      format: (value) => value,
   },
   {
      rowKey: "invoiceCreatorNip",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.creator_nip",
      format: (value) => value,
   },
   {
      rowKey: "invoiceRecipientNip",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.recipient_nip",
      format: (value) => value,
   },
   {
      rowKey: "invoiceType",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.type",
      format: (value) => value,
   },
   {
      rowKey: "status",
      configurable: true,
      labelTranslationId: "pl.invoices.table_head.status",
      format: (value) => (
         <Label color={statusToColor[value as keyof typeof statusToColor]}>
            {value}
         </Label>
      ),
   },
   {
      rowKey: null,
      configurable: false,
      labelTranslationId: null,
      format: (_value, row) => <RowCTAs invoice={row} />,
   },
]

type Props = {
   direction: "incoming" | "outgoing"
}

const InvoicesTable = ({ direction }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const table = `pl.${direction}_invoices` as const

   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const totalItems = useAppSelector((state) =>
      selectTotalItems(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )
   const filters = useAppSelector((state) =>
      selectTableFilters(state, { table })
   )
   const queryParams = filtersToQueryParams(filters)
   const { data, isError, isFetching } = useGetInvoicesQuery({
      direction,
      // itemsPerPage,
      page: currentPage,
      ...queryParams,
   })

   useEffect(() => {
      if (isError) {
         enqueueSnackbar(t("Default.generic_error"), { variant: "error" })
      }
   }, [enqueueSnackbar, t, isError])

   useEffect(() => {
      const totalItems = data?.["hydra:totalItems"]

      if (totalItems != null) {
         dispatch(updateTotalItems({ table, totalItems }))
      }
   }, [dispatch, data, table])

   return (
      <Paper>
         <Table
            columnsDefinition={columns}
            rows={data?.["hydra:member"]}
            rowKeyId="uuid"
            isFetching={isFetching}
            isError={isError}
            emptyStateTranslationId="pl.invoices.messages.no_invoices"
            withPagination
            rowsPerPage={itemsPerPage}
            totalRows={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => {
               dispatch(changePage({ table, page }))
            }}
            withConfigurableColumns={false}
         />
      </Paper>
   )
}

export default InvoicesTable
