import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from ".."

export type GovPLPaginationParams = {
   page?: number
}

export const govPlApi = createApi({
   reducerPath: "govPlApi",
   tagTypes: ["LEGAL_ENTITIES", "DEMO_INVOCES", "WEBHOOKS"],
   baseQuery: baseQuery({
      baseUrl: process.env.REACT_APP_GOV_PL_BASE_URL as string,
   }),
   endpoints: () => ({}),
})
