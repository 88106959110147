import { Field } from "formik"
import { TextField } from "formik-material-ui"

export function Text(props) {
   return (
      <Field
         component={TextField}
         disabled={props.disabled}
         label={props.label}
         name={props.name}
         type={props.type}
         fullWidth
      />
   )
}
