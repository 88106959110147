import { IconButton, Tooltip } from "@material-ui/core"
import ReplayIcon from "@material-ui/icons/Replay"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import { openDialog } from "store/slices/dialogs"

type Props = {
   fiscalId: string
   supplierInvoiceEnabled: boolean
   [key: string]: unknown
}

const RecoverInvoicesCTA = ({
   fiscalId,
   supplierInvoiceEnabled,
   ...props
}: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const hasRoleInvoiceRecover = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_INVOICING_RECOVER",
      })
   )

   const openRecoverInvoicesDialog = () => {
      dispatch(
         openDialog({ id: "gov_it_invoices_recover", data: { fiscalId } })
      )
   }

   if (!hasRoleInvoiceRecover) {
      return null
   }

   if (!supplierInvoiceEnabled) {
      return <div style={{ width: "40px" }} />
   }

   return (
      <Tooltip title={t("BusinessRegistry.recover_invoices")} {...props}>
         <IconButton onClick={openRecoverInvoicesDialog}>
            <ReplayIcon />
         </IconButton>
      </Tooltip>
   )
}

export default RecoverInvoicesCTA
