import { Box, Container, Typography } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectAuthToken, selectHasRole } from "store/auth"
import Logo from "views/layout/Logo"
import DemoInvoicesTabs from "./Tabs"

const InvoicesDemo = () => {
   const { t } = useTranslation()
   const token = useAppSelector(selectAuthToken)
   const hasRoleAdmin = useAppSelector((state) =>
      selectHasRole(state, {
         context: "pl.api.acubeapi.com",
         role: "ROLE_ADMIN",
      })
   )
   const navigate = useNavigate()

   useEffect(() => {
      if (!hasRoleAdmin) {
         navigate("/")
      }
   }, [token, navigate, hasRoleAdmin])

   return (
      <Box
         minHeight="100vh"
         display="flex"
         flexDirection="column"
         bgcolor="background.white"
      >
         <Box px={4} py={2} borderBottom={1} borderColor="grey.300">
            <Logo />
         </Box>

         <Box px={4} py={6}>
            <Container maxWidth="lg">
               <Box mb={6}>
                  <Typography variant="h1">
                     {t("invoices_demo.title")}
                  </Typography>
               </Box>

               <DemoInvoicesTabs />
            </Container>
         </Box>
      </Box>
   )
}

export default InvoicesDemo
