import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Grid } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { InvoicePayload, InvoiceSimplifiedPayload } from "services/gov-it/types"
import { useAppDispatch } from "store"
import { closeDialog, InvoiceDraftsFormMode } from "store/slices/dialogs"
import { ProjectTypeForCommonInvoiceForm } from "views/common/InvoiceForm/CommonInvoiceForm"
import DisplaySimpleSection from "views/common/InvoiceForm/CommonInvoiceForm/components/Displays/DisplaySimpleSection"
import { FieldGroup, FieldGroupByName, FormInvoiceType } from "../configFields"
import DisplayVariableSection from "../Displays/DisplayVariableSection"

type BodyFormsProps<T extends FormInvoiceType> = {
   type: T
   initialValues: T extends "simple" ? InvoicePayload : InvoiceSimplifiedPayload
   handleSubmit: any
   validationSchema: any
   setIsValidForm: any
   sections1: FieldGroup[]
   sections2: FieldGroup[]
   sectionsVariable: FieldGroupByName<T>[]
   updateGroupSection: any
   handleValidate: any
   isLoading: boolean
   isValidForm: boolean
   errors: any
   message: any
   resetErrors: any
   resetMessage: any
   project: ProjectTypeForCommonInvoiceForm
   modeDraft: InvoiceDraftsFormMode | undefined
   disableFields?: string[]
   isCreditNoteCreated?: boolean
}

const BodyForms = <T extends FormInvoiceType>({
   type,
   initialValues,
   handleSubmit,
   validationSchema,
   setIsValidForm,
   sections1,
   sections2,
   sectionsVariable,
   updateGroupSection,
   handleValidate,
   isLoading,
   isValidForm,
   errors,
   message,
   resetErrors,
   resetMessage,
   modeDraft,
   project,
   disableFields,
   isCreditNoteCreated,
}: BodyFormsProps<T>) => {
   const { t } = useTranslation()

   const {
      register,
      handleSubmit: handleSubmitForm,
      getValues,
      setError,
      setValue,
      control,
      clearErrors,
      watch,
      formState: { errors: errorsForm, isValid, isSubmitting },
   } = useForm<T extends "simple" ? InvoicePayload : InvoiceSimplifiedPayload>({
      defaultValues: async () => await initialValues,
      resolver: yupResolver(validationSchema) as any,
   })

   const onSubmit: SubmitHandler<typeof initialValues> = (data) =>
      handleSubmit(data, {
         setError,
         getValues,
         setValue,
      })

   return (
      <>
         {errors && errors.length > 0 && (
            <Box mt={2} mb={2}>
               <Alert severity="error" onClose={resetErrors}>
                  <AlertTitle>
                     {t("CustomerInvoices.CreateInvoice.Validation Error")}
                  </AlertTitle>
                  {errors.map(function (error: {
                     propertyPath: string
                     message: string
                  }) {
                     return (
                        <p>
                           <strong>{error.propertyPath}</strong> {error.message}
                        </p>
                     )
                  })}
               </Alert>
            </Box>
         )}

         {message && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="success"
                  style={{
                     margin: 2,
                  }}
                  onClose={resetMessage}
               >
                  <AlertTitle>
                     {t("Invoices.invoice_success_message")}
                  </AlertTitle>
                  {message}
               </Alert>
            </Box>
         )}
         <form onSubmit={handleSubmitForm(onSubmit)}>
            <Grid container>
               <DisplaySimpleSection
                  watch={watch}
                  sections={sections1}
                  register={register}
                  getValues={getValues}
                  setError={setError}
                  setValue={setValue}
                  control={control}
                  errorsForm={errorsForm}
                  disableFields={disableFields}
               />

               {sectionsVariable.map((section, index) => (
                  <DisplayVariableSection
                     watch={watch}
                     type={type}
                     key={index}
                     groupSections={section as FieldGroupByName<T>}
                     updateGroupSection={updateGroupSection}
                     register={register}
                     getValues={getValues}
                     setError={setError}
                     setValue={setValue}
                     control={control}
                     errorsForm={errorsForm}
                     disableFields={disableFields}
                  />
               ))}

               <DisplaySimpleSection
                  watch={watch}
                  sections={sections2}
                  register={register}
                  getValues={getValues}
                  setError={setError}
                  setValue={setValue}
                  control={control}
                  errorsForm={errorsForm}
                  disableFields={disableFields}
               />

               <Box
                  width="100%"
                  style={{
                     position: "sticky",
                     bottom: 0,
                     background: "#fff",
                     padding: "20px",
                     zIndex: 1000,
                  }}
               >
                  <GetBodyButtons
                     project={project}
                     isSubmitting={isSubmitting}
                     isValid={isValid}
                     isLoading={isLoading}
                     values={getValues()}
                     getValues={getValues}
                     setError={setError}
                     isValidForm={isValidForm}
                     handleValidate={handleValidate}
                     modeDraft={modeDraft}
                     clearErrors={clearErrors}
                     isCreditNoteCreated={isCreditNoteCreated}
                  />
               </Box>
            </Grid>
         </form>
      </>
   )
}

type GetBodyButtonsProps = {
   project: ProjectTypeForCommonInvoiceForm
   isSubmitting: boolean
   isValid: boolean
   isLoading: boolean
   values: any
   setError: any
   getValues: any
   isValidForm: boolean
   handleValidate: any
   clearErrors: any
   modeDraft?: InvoiceDraftsFormMode
   isCreditNoteCreated?: boolean
}

const GetBodyButtons = ({
   project,
   isSubmitting,
   isValid,
   isLoading,
   values,
   setError,
   getValues,
   isValidForm,
   handleValidate,
   modeDraft,
   clearErrors,
   isCreditNoteCreated,
}: GetBodyButtonsProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()

   if (project === "invoice_sent" || project === "invoice_fix") {
      return (
         <Grid container justifyContent="center">
            {!isCreditNoteCreated ? (
               <>
                  <Button
                     disabled={!isValid || isLoading}
                     color="default"
                     variant="contained"
                     size="medium"
                     style={{ marginRight: "20px" }}
                     onClick={() => {
                        clearErrors()
                        handleValidate(values, setError, getValues)
                     }}
                  >
                     {isLoading ? (
                        <>{t("global.validating")}</>
                     ) : (
                        t("gov_it.invoice_fix.validate_schema")
                     )}
                  </Button>

                  <Button
                     disabled={isValidForm ? isSubmitting : true}
                     color={isSubmitting ? "default" : "primary"}
                     variant="contained"
                     size="medium"
                     type="submit"
                  >
                     {isValidForm ? t("global.submit") : t("global.validate")}
                  </Button>
               </>
            ) : (
               <Button
                  color={isSubmitting ? "default" : "primary"}
                  variant="contained"
                  size="medium"
                  type="submit"
               >
                  {t("global.creaCreditNote")}
               </Button>
            )}

            <Button
               onClick={() => {
                  if (project === "invoice_fix") {
                     navigate("/customer-invoice")
                     return
                  }
                  dispatch(closeDialog())
               }}
               color="default"
               variant="contained"
               style={{ marginLeft: "10px" }}
            >
               {t("Default.Annulla")}
            </Button>
         </Grid>
      )
   }

   if (project === "invoice_draft" && modeDraft) {
      return (
         <Grid container justifyContent="center">
            {modeDraft === "send" && (
               <Button
                  disabled={!isValid || isLoading}
                  color="default"
                  variant="contained"
                  size="medium"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                     clearErrors()
                     handleValidate(values, setError, getValues)
                  }}
               >
                  {isLoading ? (
                     <>{t("global.validating")}</>
                  ) : (
                     t("gov_it.invoice_fix.validate_schema")
                  )}
               </Button>
            )}

            <Button
               disabled={
                  modeDraft == "send"
                     ? isValidForm
                        ? isSubmitting
                        : true
                     : isSubmitting
               }
               color={isSubmitting ? "default" : "primary"}
               variant="contained"
               size="medium"
               type="submit"
            >
               {modeDraft === "send"
                  ? isValidForm
                     ? t("global.submit")
                     : t("global.validate")
                  : modeDraft === "edit"
                  ? t("global.update")
                  : t("global.create")}
            </Button>

            <Button
               onClick={() => {
                  dispatch(closeDialog())
               }}
               color="default"
               variant="contained"
               style={{ marginLeft: "10px" }}
            >
               {modeDraft ? t("Default.Chiudi") : t("Default.Annulla")}
            </Button>
         </Grid>
      )
   }

   return (
      <>
         <Grid container justifyContent="center">
            <Button
               onClick={() => {
                  dispatch(closeDialog())
               }}
               color="default"
               variant="contained"
               style={{ marginLeft: "10px" }}
            >
               {t("Default.Chiudi")}
            </Button>
         </Grid>
      </>
   )
}

export default BodyForms
