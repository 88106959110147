import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { cancel, closeDialog, confirm, DialogState } from "store/slices/dialogs"

const DIALOG_ID = "confirmation_dialog"

type ContentProps = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ message }: ContentProps) => {
   const [isLoading, setConfirmed] = useState(false)
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const handleConfirm = () => {
      setConfirmed(true)
      dispatch(confirm())
   }

   const handleCancel = () => {
      dispatch(cancel())
      dispatch(closeDialog())
   }

   return (
      <Box p={2}>
         <Typography variant="body1" style={{ textAlign: "center" }}>
            {message}
         </Typography>

         <Box display="flex" justifyContent="center" gridGap={8} mt={2}>
            <Button
               disabled={isLoading}
               onClick={handleConfirm}
               color="primary"
               variant="contained"
               style={{ width: "max-content" }}
            >
               {!isLoading && t("global.confirm")}
               {isLoading && <CircularProgress size={20} color="inherit" />}
            </Button>

            <Button
               style={{ width: "max-content" }}
               onClick={handleCancel}
               color="default"
               variant="contained"
            >
               {t("global.cancel")}
            </Button>
         </Box>
      </Box>
   )
}

const ConfirmationDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="sm" fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default ConfirmationDialog
