import { Button } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import {
   closeDialog,
   openConfirmationDialog,
   openDialog,
} from "store/slices/dialogs"
import { selectTableCheckedColumns, TableName } from "store/tablesState"

const table: TableName = "invoice.drafts"
type props = {
   invoices: GovITInvoice[] | undefined
}
const DeletedCheckedActionsButton = ({ invoices }: props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const { enqueueSnackbar } = useSnackbar()

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   const handleDelete = async () => {
      if (checkedColumns.length === 0) {
         enqueueSnackbar(t("global.nothing_selected"), { variant: "warning" })
         return
      }

      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("gov_it.invoice_drafts.delete_n_invoice_confirmation", {
               n: checkedColumns.length,
            }),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      dispatch(closeDialog())

      dispatch(
         openDialog({
            id: "invoice.drafts.sentBlockInvoices",
            data: {
               uuids: checkedColumns,
               mode: "delete",
               invoices: invoices ?? ([] as GovITInvoice[]),
            },
         })
      )
   }
   return (
      <Button
         variant="contained"
         startIcon={<DeleteIcon />}
         onClick={handleDelete}
         style={{
            marginRight: "10px",
            minWidth: "max-content",
            backgroundColor: "#f44336",
            color: "white",
         }}
      >
         {t("global.delete_selected")}
      </Button>
   )
}

export default DeletedCheckedActionsButton
