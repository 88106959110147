import { Grid } from "@material-ui/core"
import { FieldGroup } from "views/common/InvoiceForm/CommonInvoiceForm/components/configFields"
import SectionItem from "views/common/InvoiceForm/CommonInvoiceForm/components/ListOfFields"

type DisplaySimpleSectionProps = {
   sections: FieldGroup[]
   register: any
   getValues: any
   setError: any
   setValue: any
   control: any
   watch: any
   errorsForm: any
   disableFields?: string[]
}

const DisplaySimpleSection = ({
   sections,
   register,
   getValues,
   setError,
   control,
   setValue,
   watch,
   errorsForm,
   disableFields,
}: DisplaySimpleSectionProps) => {
   return (
      <>
         {sections.map(({ key }, index) => (
            <Grid
               item
               xs={12}
               lg={
                  key === "cedente_prestatore" ||
                  key === "cessionario_committente"
                     ? 6
                     : 12
               }
               key={window.crypto.randomUUID()}
            >
               <SectionItem
                  watch={watch}
                  key={window.crypto.randomUUID()}
                  sections={sections}
                  index={index}
                  register={register}
                  getValues={getValues}
                  setError={setError}
                  setValue={setValue}
                  control={control}
                  errorsForm={errorsForm}
                  disableFields={disableFields}
               />
            </Grid>
         ))}
      </>
   )
}

export default DisplaySimpleSection
