import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { useGetCategoriesQuery } from "services/openbankingApi/transactions"
import { FormValues } from ".."

// Use a high value to retrieve all the categories with a single request.
const ITEMS_PER_PAGE = 100

const name = "category"

const CategoriesSelect = () => {
   const { t } = useTranslation()

   const { data } = useGetCategoriesQuery({ itemsPerPage: ITEMS_PER_PAGE })
   const categories = data?.["hydra:member"] ?? []

   const { values, handleChange } = useFormikContext<FormValues>()

   return (
      <FormControl fullWidth>
         <InputLabel>
            {t("openbanking.transactions.filters.categories")}
         </InputLabel>

         <Select
            multiple
            value={values[name]}
            name={name}
            onChange={handleChange}
            label="Age"
         >
            {categories.map(({ category }) => (
               <MenuItem key={category} value={category}>
                  {category}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   )
}

export default CategoriesSelect
