import { Button, CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
   AuthFlowStatus,
   LegalEntityStatus,
} from "services/gov-pl/legalEntities"
import { useInitOnboardMutation } from "services/gov-pl/onboard"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   uuid: string
   status: LegalEntityStatus
   authFlowStatus: AuthFlowStatus | null
}

const InitOnboardCTA = ({ uuid, status, authFlowStatus }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const [triggerInitOnboard, { isLoading }] = useInitOnboardMutation()

   const initOnboard = async () => {
      const response = await triggerInitOnboard({ uuid })

      if ("data" in response) {
         const { url: path } = response.data
         const url = `${window.location.origin}${path}`

         dispatch(
            openDialog({
               id: "legal_entity_onboard_init_success",
               data: { url },
            })
         )
      }
   }

   if (
      status !== LegalEntityStatus.ACTIVE &&
      [
         null,
         AuthFlowStatus.NEW,
         AuthFlowStatus.IN_PROGRESS,
         AuthFlowStatus.ERROR,
      ].includes(authFlowStatus)
   ) {
      return (
         <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={initOnboard}
         >
            {!isLoading && t("legal_entities.init_onboard_cta")}
            {isLoading && <CircularProgress color="inherit" size={22} />}
         </Button>
      )
   }

   return null
}

export default InitOnboardCTA
