import {
   Box,
   ButtonGroup,
   CircularProgress,
   TableCell,
   TableRow,
} from "@material-ui/core"
import { Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useGetBusinessRegistriesQuery } from "services/openbankingApi/businessRegistry"
import { useAppDispatch, useAppSelector } from "store"
import {
   selectCurrentPage,
   selectItemsPerPage,
   selectTableData,
   updateTotalItems,
} from "store/tablesState"
import AccountsCTA from "../AccountsCTA"
import AccountsTable from "../AccountsTable"
import ConnectCTA from "../ConnectCTA"
import DataReadingToggle from "../DataReadingToggle"
import DeleteBusinessRegistryCTA from "../DeleteBusinessRegistryCTA"
import EditBusinessRegistryCTA from "../EditBusinessRegistryCTA"
import PaymentsCTA from "../PaymentsCTA"
import SubscriptionDetailsCTA from "../SubscriptionDetailsCTA"
import TransactionsCTA from "../TransactionsCTA"

const table = "openbanking.business_registries"

const BusinessRegistryTableEntries = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const { expandedRows } = useAppSelector((state) =>
      selectTableData(state, { table: "openbanking.business_registries" })
   )
   const itemsPerPage = useAppSelector((state) =>
      selectItemsPerPage(state, { table })
   )
   const currentPage = useAppSelector((state) =>
      selectCurrentPage(state, { table })
   )

   const { data, isError, isFetching } = useGetBusinessRegistriesQuery({
      itemsPerPage,
      page: currentPage,
   })

   const items = data?.["hydra:member"]
   const fetchedTotalItems = data?.["hydra:totalItems"]

   useEffect(() => {
      if (fetchedTotalItems != null) {
         dispatch(updateTotalItems({ table, totalItems: fetchedTotalItems }))
      }
   }, [dispatch, fetchedTotalItems])

   if (isFetching) {
      return (
         <TableRow>
            <TableCell colSpan={8}>
               <Box display="flex" justifyContent="center" color="grey.500">
                  <CircularProgress size={40} color="inherit" />
               </Box>
            </TableCell>
         </TableRow>
      )
   }

   if (isError) {
      return (
         <TableRow>
            <TableCell colSpan={8}>
               {t("global.messages.generic_error")}
            </TableCell>
         </TableRow>
      )
   }

   if (items == null || items.length === 0) {
      return (
         <TableRow>
            <TableCell colSpan={8}>
               {t(
                  "openbanking.business_registry.messages.no_business_registry_entries"
               )}
            </TableCell>
         </TableRow>
      )
   }

   return (
      <>
         {items.map((item) => {
            const { fiscalId, email, businessName, enabled } = item
            const isExpanded = expandedRows.includes(fiscalId)
            const style = isExpanded
               ? { border: 0 }
               : { borderBottomWidth: "2px" }

            return (
               <Fragment key={fiscalId}>
                  <TableRow hover>
                     <TableCell style={style}>
                        <AccountsCTA fiscalId={fiscalId} />
                     </TableCell>

                     <TableCell style={style}>{fiscalId}</TableCell>

                     <TableCell style={style}>{email}</TableCell>

                     <TableCell style={style}>{businessName}</TableCell>

                     <TableCell style={style}>
                        {enabled && <PaymentsCTA fiscalId={fiscalId} />}
                     </TableCell>

                     <TableCell style={style}>
                        {enabled && <TransactionsCTA fiscalId={fiscalId} />}
                     </TableCell>

                     <TableCell style={style}>
                        {enabled && <ConnectCTA fiscalId={fiscalId} />}
                     </TableCell>

                     <TableCell style={style}>
                        <DataReadingToggle
                           fiscalId={fiscalId}
                           enabled={enabled}
                        />
                     </TableCell>

                     <TableCell style={style}>
                        <ButtonGroup>
                           <SubscriptionDetailsCTA fiscalId={fiscalId} />

                           <EditBusinessRegistryCTA businessRegistry={item} />

                           <DeleteBusinessRegistryCTA fiscalId={fiscalId} />
                        </ButtonGroup>
                     </TableCell>
                  </TableRow>

                  <AccountsTable fiscalId={fiscalId} />
               </Fragment>
            )
         })}
      </>
   )
}

export default BusinessRegistryTableEntries
