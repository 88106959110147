import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import TabPanelDocumentReference from "./tab/TabPanelDocumentReference"
import TabPanelInfo from "./tab/TabPanelInfo"
import TabPanelJson from "./tab/TabPanelJson"
import TabPanelXml from "./tab/TabPanelXml"

export default function OrderResponseDetailsTabs(props) {
   const classes = useStyles()
   const [value, setValue] = React.useState(0)

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const a11yProps = (index) => ({
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   })

   return (
      <Paper className={classes.root} elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
         >
            <Tab label="info" {...a11yProps(0)} />
            <Tab label="json" {...a11yProps(1)} />
            <Tab label="xml" {...a11yProps(2)} />
            {props.order.order && (
               <Tab label="document reference" {...a11yProps(3)} />
            )}
         </Tabs>

         <TabPanelInfo order={props.order} value={value} index={0} />
         <TabPanelJson order={props.JSONorder} value={value} index={1} />
         <TabPanelXml order={props.XMLorder} value={value} index={2} />
         {props.order.order && (
            <TabPanelDocumentReference
               order={props.order.order}
               value={value}
               index={3}
               handleDownloadXML={() =>
                  props.handleDownloadOrderXML(
                     props.order.order.uuid,
                     `${props.order.order.number}.xml`
                  )
               }
            />
         )}
      </Paper>
   )
}

const useStyles = makeStyles({
   root: {
      flexGrow: 1,
      width: "100%",
   },
})
