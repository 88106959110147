import {
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemText,
   makeStyles,
   Typography,
} from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useTranslation } from "react-i18next"
import { DialogId, DialogState } from "store/slices/dialogs"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID: DialogId = "gov_it_invoice_transfer_violations_details"

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ violations, handleClose }: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const violationsJson = JSON.parse(violations) as {
      propertyPath: string
      message: string
   }[]
   return (
      <>
         <DialogTitle>
            {t("gov_it.invoice_transfers.details_violations_dialog_title")}
         </DialogTitle>

         <DialogContent className={classes.content}>
            <Accordion
               expanded
               style={{
                  width: "100%",
               }}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
               >
                  <Typography
                     style={{
                        fontWeight: 400,
                     }}
                  >
                     {t("gov_it.invoice_transfers.table_head.violations")}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  {violationsJson && (
                     <List
                        style={{
                           width: "100%",
                           padding: "0",
                           margin: "0",
                        }}
                     >
                        {violationsJson.map(
                           ({ propertyPath, message }, index) => (
                              <ListItem key={index} divider>
                                 <ListItemText
                                    primary={`PropertyPath:  ${propertyPath}`}
                                    secondary={`Message:  ${message}`}
                                 />
                              </ListItem>
                           )
                        )}
                     </List>
                  )}
               </AccordionDetails>
            </Accordion>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const InvoiceTransferViolationDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default InvoiceTransferViolationDetailsDialog
