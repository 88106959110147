import { DialogContent, DialogTitle } from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Formik } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useCreateBusinessRegistryMutation } from "services/openbankingApi/businessRegistry"
import * as Yup from "yup"
import EditBusinessRegistryForm, {
   FormValues,
} from "../EditBusinessRegistryForm"

const DIALOG_ID = "openbanking_new_business_registry"

const initialValues = {
   fiscalId: "",
   email: "",
   businessName: "",
   emailAlerts: true,
}

const validationSchema = Yup.object().shape({
   fiscalId: Yup.string().required(),
   email: Yup.string().email().required(),
   businessName: Yup.string().required(),
})

type Props = BaseDialogActions

const Content = ({ handleClose }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [createBusinessRegistryEntry] = useCreateBusinessRegistryMutation()

   const handleSubmit = async (values: FormValues) => {
      const response = await createBusinessRegistryEntry(values)

      if ("data" in response) {
         enqueueSnackbar(
            t(
               "openbanking.business_registry.messages.new_business_registry_created"
            ),
            { variant: "success" }
         )
         handleClose()

         return
      }

      if ("status" in response.error && response.error.status === 422) {
         enqueueSnackbar((response.error.data as { detail: string }).detail, {
            variant: "error",
         })

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <>
         <DialogTitle>
            {t(
               "openbanking.business_registry.new_business_registry_dialog_title"
            )}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
            >
               {(formikProps) => (
                  <EditBusinessRegistryForm
                     onCancel={handleClose}
                     formikProps={formikProps}
                     isCreationForm
                  />
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const NewBusinessRegistryDialog = () => (
   <BaseDialog id={DIALOG_ID}>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default NewBusinessRegistryDialog
