import { ButtonGroup } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import EditIcon from "@material-ui/icons/Edit"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteApiConfigurationMutation } from "services/gov-it/apiConfigurations"
import { ApiConfiguration } from "services/gov-it/apiConfigurations/types"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { DeleteButton } from "views/common/DeleteItem/DeleteButton"

type Props = {
   item: ApiConfiguration
}

const table: TableName = "api.configurations"
export const RowCTAs = ({ item }: Props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [deleteApiConfiguration] = useDeleteApiConfigurationMutation()
   const { enqueueSnackbar } = useSnackbar()

   const handleDeleteApiConfiguration = async (id: string) => {
      const response = await deleteApiConfiguration({
         id,
      })

      if ("error" in response && "message" in response.error) {
         enqueueSnackbar(response.error.message, { variant: "error" })
         return
      }

      persistTableRefresh(table)
      dispatch(
         updateRefetch({
            table,
         })
      )
      enqueueSnackbar(t("Integrazione.Eliminato"), { variant: "success" })
   }

   const handleOpenDialog = (id: string) => {
      dispatch(
         openDialog({
            id: "api.configuration.form",
            data: {
               uuid: id,
            },
         })
      )
   }

   return (
      <ButtonGroup>
         <Tooltip title={t("Integrazione.Modifica")}>
            <IconButton
               onClick={() => {
                  handleOpenDialog(item.uuid)
               }}
            >
               <EditIcon />
            </IconButton>
         </Tooltip>
         <DeleteButton
            title={t("Integrazione.Cancella")}
            handleDelete={() => handleDeleteApiConfiguration(item.uuid)}
         />
      </ButtonGroup>
   )
}
