import { fade, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { NOTIFICATION_MC, NOTIFICATION_NS, NOTIFICATION_RC } from "config"

// @ts-ignore
const useStyles = makeStyles((theme) => ({
   root: {
      fontFamily: theme.typography.fontFamily,
      alignItems: "center",
      borderRadius: 2,
      display: "inline-flex",
      flexGrow: 0,
      whiteSpace: "nowrap",
      cursor: "default",
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 20,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: "uppercase",
   },
   primary: {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.08),
   },
   secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.secondary.main, 0.08),
   },
   error: {
      color: theme.palette.error.main,
      backgroundColor: fade(theme.palette.error.main, 0.08),
   },
   success: {
      color: theme.palette.success.main,
      backgroundColor: fade(theme.palette.success.main, 0.08),
   },
   warning: {
      color: theme.palette.warning.main,
      backgroundColor: fade(theme.palette.warning.main, 0.08),
   },
}))

type Props = {
   value: string
   children?: React.ReactNode
   className?: string
   style?: object
   color?: "primary" | "secondary" | "error" | "warning" | "success"
}
const LabelNotification = ({ value }: Props) => {
   const classes = useStyles()

   let color = "secondary",
      label
   label = value

   if (value === NOTIFICATION_RC) {
      color = "success"
   }
   if (value === NOTIFICATION_MC) {
      color = "warning"
   }
   if (value === NOTIFICATION_NS) {
      color = "error"
   }

   return (
      <span
         className={
            // @ts-ignore
            clsx(classes.root, {
               // @ts-ignore
               [classes[color]]: color,
            })
         }
      >
         {label}
      </span>
   )
}

export default LabelNotification
