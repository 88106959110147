import { Box, Button, Grid, makeStyles, Paper } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Formik, FormikErrors, FormikHelpers, FormikValues } from "formik"
import { useSnackbar } from "notistack"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import {
   ImportDraftFormValues,
   useGetListDirsQuery,
   useImportDraftInvoiceMutation,
   useLazyGetListDirsQuery,
} from "services/transfer"
import { useAppDispatch } from "store"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import CustomTreeViewInput, {
   CustomTreeViewLoading,
} from "views/common/CustomTreeView"
import { DataTree, listDirsToDataTree } from "views/common/CustomTreeView/utils"
import CustomInputFileField from "views/gov-it/CustomerSupplierInvoice/ImportInvoiceDialog/Components/CustomInputFileField"
import * as Yup from "yup"

type FormValues = {
   content: string
   project: string
   path: string
}

const initialValues = {
   content: "",
   project: "",
   path: "",
} as FormValues

const validationSchemaForImportDraft = Yup.object().shape({
   content: Yup.string().required("Required"),
   path: Yup.string().required("Required"),
})

type UploadFormProps = {
   handleClose: () => void
}

const table: TableName = "gov_it.invoice_transfers"

const UploadForm = ({ handleClose }: UploadFormProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const classes = useStyles()
   const [errors, setErrors] = React.useState<object | null>(null)
   const [message, setMessage] = React.useState<string | null>(null)
   const [fileName, setFileName] = React.useState<string | null>(null)
   const genericError = {
      generic: "An error occurred",
   }

   const [importDraftInvoice, { isLoading }] = useImportDraftInvoiceMutation()

   const { data: listDirs, isLoading: isLoadingListDirs } = useGetListDirsQuery(
      {
         project: "it",
      }
   )

   const allPaths = listDirsToDataTree(listDirs)

   const [getListDirs] = useLazyGetListDirsQuery()

   const fetchChildrenFn = async (node: DataTree) => {
      const response = await getListDirs({
         project: "it",
         prefix: node.id.replace(/\/$/, ""),
      })
      return response.data
   }

   const { enqueueSnackbar } = useSnackbar()
   const handleSubmit = async (
      values: FormValues,
      helpers: FormikHelpers<FormValues>
   ) => {
      await importDraft(values, helpers)
   }

   const importDraft = async (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      setMessage(null)
      setErrors(null)

      const body = {
         content: values.content,
         project: "it",
         path: `${values.path
            .replace(/^\//, "")
            .replace(/\/$/, "")}/${fileName}`,
      } as ImportDraftFormValues

      let response = await importDraftInvoice(body)
      if ("error" in response && "data" in response.error) {
         if ("errors" in (response.error.data as any)) {
            setErrors((response.error.data as any).errors)
            const errs = Object.entries((response.error.data as any).errors)
               .map(([key, value]) => `${key}: ${value}`)
               .join(", ")
            enqueueSnackbar(errs, {
               variant: "error",
            })

            const data = response.error.data as {
               errors?: { key: string }
            } | null

            if (data && data.errors) {
               Object.entries(data.errors).map(([key, value]) =>
                  setFieldError(key, value)
               )
            }
            return
         }

         if ("detail" in (response.error.data as any)) {
            setErrors({
               Error: (response.error.data as any).detail,
            })
            enqueueSnackbar((response.error.data as any).detail, {
               variant: "error",
            })
            return
         }
         setErrors(genericError)
         return
      }

      if ("data" in response) {
         setMessage(`${t("transfer.file_imported")}`)
         enqueueSnackbar(t("transfer.file_imported"), {
            variant: "success",
         })

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const invoiceRef = useRef<HTMLInputElement>(null)

   const handleFileChosen = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
      setFieldValue: (
         field: string,
         value: any,
         shouldValidate?: boolean
      ) => Promise<void | FormikErrors<FormikValues>>
   ) => {
      setFileName(null)
      const file = e.target && "files" in e.target ? e.target.files?.[0] : null
      if (file) {
         const base64 = await fileToBase64(file).then((result) => result)
         setFieldValue(field, base64)
         setFileName(file.name)
         return
      }
      setFieldValue(field, field === "content" ? "" : null)
      setFileName(null)
   }

   const fileToBase64 = (file: File) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            const base64String = (reader.result as string)
               .replace("data:", "")
               .replace(/^.+,/, "")

            resolve(base64String)
         }
         reader.onerror = (error) => reject(error)
      })
   }

   const resetFileField = (field: string) => {
      if (field === "content" && invoiceRef.current) {
         invoiceRef.current.value = ""
      }
   }

   const handleChangePath = (
      value: string,
      setFieldValue: FormikHelpers<FormValues>["setFieldValue"]
   ) => {
      setFieldValue("path", value)
   }

   return (
      <>
         {errors && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="error"
                  onClose={() => {
                     setErrors(null)
                  }}
               >
                  <AlertTitle>
                     {t("CustomerInvoices.CreateInvoice.Validation Error")}
                  </AlertTitle>
                  {Object.entries(errors).map(([key, value], index) => (
                     <p key={index}>
                        <strong>{key}:</strong> {value}
                     </p>
                  ))}
               </Alert>
            </Box>
         )}

         {message && (
            <Box mt={2} mb={2}>
               <Alert
                  severity="success"
                  style={{
                     margin: 2,
                  }}
                  onClose={() => {
                     setMessage(null)
                  }}
               >
                  <AlertTitle>{t("transfer.file_imported")}</AlertTitle>
                  {message}
               </Alert>
            </Box>
         )}

         <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchemaForImportDraft}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
         >
            {({
               handleSubmit,
               isSubmitting,
               getFieldMeta,
               setFieldValue,
               handleChange,
               values,
               errors,
               isValid,
            }) => (
               <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} key={"content"}>
                        <Paper style={{ width: "100%", height: "100%" }}>
                           <Box p={2.5} style={{ width: "100%" }}>
                              <CustomInputFileField
                                 name={"content"}
                                 label={t(
                                    "transfer.ImportInvoice.Upload file invoice"
                                 )}
                                 invoiceRef={invoiceRef}
                                 handleFileChosen={handleFileChosen}
                                 getFieldMeta={getFieldMeta}
                                 resetFileField={resetFileField}
                                 setFieldValue={setFieldValue}
                              />
                           </Box>
                        </Paper>
                     </Grid>

                     <Grid item xs={12}>
                        {isLoadingListDirs ? (
                           <CustomTreeViewLoading />
                        ) : (
                           <CustomTreeViewInput
                              dataTree={allPaths}
                              label={t("transfer.ImportInvoice.path_name")}
                              handleChange={(value) => {
                                 handleChangePath(value, setFieldValue)
                              }}
                              error={errors.path}
                              fetchChildrenFn={fetchChildrenFn}
                           />
                        )}
                     </Grid>

                     <Box
                        width="100%"
                        style={{
                           position: "sticky",
                           bottom: "-10px",
                           background: "#fff",
                           padding: "20px",
                           zIndex: 1000,
                        }}
                     >
                        <Grid container justifyContent="flex-end">
                           <Button
                              disabled={isSubmitting || !isValid}
                              color={isSubmitting ? "default" : "primary"}
                              variant="contained"
                              size="medium"
                              type="submit"
                           >
                              {t("transfer.ImportInvoice.upload_invoice")}
                           </Button>

                           <Button
                              onClick={() => {
                                 handleClose()
                              }}
                              color="default"
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                           >
                              {t("Default.Chiudi")}
                           </Button>
                        </Grid>
                     </Box>
                  </Grid>
               </form>
            )}
         </Formik>
      </>
   )
}

export default UploadForm

const useStyles = makeStyles((theme) => ({
   formControl: {
      margin: theme.spacing(1),
   },
   formLabel: {
      fontSize: 16,
   },
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
