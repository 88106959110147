import { Box, CircularProgress, Paper, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useGetProtectedInvoiceQuery } from "services/gov-pl/invoices"
import DownloadInvoicePdf from "views/pl/components/DownloadInvoicePdf"
import DownloadInvoiceXml from "views/pl/components/DownloadInvoiceXml"
import DownloadUpo from "views/pl/components/DownloadUpo"
import InvoiceAttachments from "./Attachments"
import InvoiceContent from "./Content"

type Props = {
   protectedInvoiceCode: string
}

const InvoiceDetails = ({ protectedInvoiceCode }: Props) => {
   const { t } = useTranslation()
   const { invoiceUuid } = useParams()

   const { data: jsonData } = useGetProtectedInvoiceQuery({
      accept: "application/json",
      uuid: invoiceUuid!,
      code: protectedInvoiceCode,
   })

   const {
      data: htmlData,
      isLoading,
      isError,
   } = useGetProtectedInvoiceQuery({
      accept: "text/html",
      uuid: invoiceUuid!,
      code: protectedInvoiceCode,
   })

   const invoice = jsonData as Extract<typeof jsonData, { uuid: string }>
   const data = htmlData as Extract<typeof htmlData, { html: string }>

   return (
      <>
         <Box display="flex" mb={4} gridGap={16}>
            <DownloadInvoicePdf
               invoiceUuid={invoiceUuid!}
               protectedInvoiceCode={protectedInvoiceCode}
            />

            <DownloadInvoiceXml
               invoiceUuid={invoiceUuid!}
               protectedInvoiceCode={protectedInvoiceCode}
            />

            {invoice != null && invoice.status === "confirmed" && (
               <DownloadUpo
                  invoiceUuid={invoiceUuid!}
                  protectedInvoiceCode={protectedInvoiceCode}
                  buttonSize="large"
               />
            )}
         </Box>

         <Paper>
            <Box p={2}>
               {isLoading && (
                  <Box display="flex" justifyContent="center" color="grey.500">
                     <CircularProgress size={40} color="inherit" />
                  </Box>
               )}

               {isError && (
                  <Typography variant="body1">
                     {t("pl.public_invoice.error.generic")}
                  </Typography>
               )}

               {!isLoading && data != null && (
                  <>
                     <InvoiceAttachments
                        attachments={invoice.attachments}
                        protectedInvoiceCode={protectedInvoiceCode}
                     />

                     <InvoiceContent html={data.html} />
                  </>
               )}
            </Box>
         </Paper>
      </>
   )
}

export default InvoiceDetails
