import { buildSearchParams } from "helper/buildSearchParams"
import { ApiConfiguration } from "services/gov-it/apiConfigurations/types"
import { HydraResponse } from "services/types"
import { govItApi, GovITPaginationParams } from ".."

type GetApiConfigurationsParams = {
   "business_registry_configurations.fiscal_id"?: string[]
   targetUrl?: string
} & GovITPaginationParams

type ApiConfigurationsResponse = HydraResponse<ApiConfiguration>

type ApiConfigurationResponse = ApiConfiguration

type Nullable<T> = {
   [K in keyof T]: Nullable<T[K]> | null
}

type EditApiConfigurationParams = Nullable<
   Omit<ApiConfiguration, "business_registry_configurations" | "uuid">
>

type CreateApiConfigurationParams = EditApiConfigurationParams

const govItApiConfigurationsApi = govItApi.injectEndpoints({
   endpoints: (builder) => ({
      getApiConfigurations: builder.query<
         ApiConfigurationsResponse,
         GetApiConfigurationsParams
      >({
         query: (params) => ({
            url: `/api-configurations${buildSearchParams({
               ...params,
               ...(params.refetch
                  ? { refetch: params.refetch * Math.random() }
                  : { refetch: Math.random() * 100 }),
            })}`,
         }),
         providesTags: ["API_CONFIGURATIONS"],
      }),

      getApiConfiguration: builder.query<
         ApiConfigurationResponse,
         { uuid: string }
      >({
         query: ({ uuid }) => ({
            url: `/api-configurations/${uuid}`,
         }),
         providesTags: ["API_CONFIGURATIONS"],
      }),

      createApiConfiguration: builder.mutation<
         ApiConfigurationResponse,
         CreateApiConfigurationParams
      >({
         query: (body) => ({
            url: `/api-configurations`,
            method: "POST",
            body,
         }),
         invalidatesTags: ["API_CONFIGURATIONS"],
      }),

      editApiConfiguration: builder.mutation<
         void,
         EditApiConfigurationParams & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/api-configurations/${uuid}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["API_CONFIGURATIONS"],
      }),

      deleteApiConfiguration: builder.mutation<void, { id: string }>({
         query: ({ id }) => ({
            url: `/api-configurations/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["API_CONFIGURATIONS"],
      }),
   }),
})

export const {
   useGetApiConfigurationsQuery,
   useLazyGetApiConfigurationsQuery,
   useGetApiConfigurationQuery,
   useDeleteApiConfigurationMutation,
   useEditApiConfigurationMutation,
   useCreateApiConfigurationMutation,
} = govItApiConfigurationsApi
