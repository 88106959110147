import { zodResolver } from "@hookform/resolvers/zod"
import {
   Box,
   Button,
   CircularProgress,
   DialogContent,
   DialogTitle,
   Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   useAddNewSubAccountToBusinessRegistryConfigurationMutation,
   useGetBusinessRegistryConfigurationsQuery,
   useLazyGetBusinessRegistryConfigurationQuery,
   useLazyListBusinessRegistryConfigurationsBySubAccountEmailQuery,
   useListBusinessRegistryConfigurationsBySubAccountEmailQuery,
   useRemoveSubAccountFromBusinessRegistryConfigurationMutation,
} from "services/gov-it/businessRegistryConfigurations"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import { formatBusinessRegistryConfigurations } from "views/BusinessRegistryConfigurations/helper"
import SelectAutoComplete from "views/common/SelectAutoComplete"
import {
   ConnectSubAccountToBRCSchema,
   ConnectSubAccountToBRCSchemaType,
} from "views/settings/SubAccounts/SubAccountsDialog/ConnectSubAccountToBRC/utils"

const DIALOG_ID = "settingsAccountConnectBusinessRegistry"

type Props = BaseDialogActions & {
   email: string
}

const Content = ({ handleClose, email }: Props) => {
   const { data: BusinessRegistryData, isLoading: isLoadingFiscalId } =
      useGetBusinessRegistryConfigurationsQuery({})

   const itemsBusinessRegistryData = BusinessRegistryData
      ? "hydra:member" in BusinessRegistryData
         ? formatBusinessRegistryConfigurations(
              BusinessRegistryData["hydra:member"]
           )
         : formatBusinessRegistryConfigurations([BusinessRegistryData])
      : undefined

   const { data: brcs, isLoading: isLoadingBrcs } =
      useListBusinessRegistryConfigurationsBySubAccountEmailQuery({
         email,
      })

   const [getBusinessRegistryConfigurations] =
      useLazyListBusinessRegistryConfigurationsBySubAccountEmailQuery()

   const { t } = useTranslation()

   const [loading, setLoading] = useState(false)

   /*const [listBrcs, setListBrcs] = useState<
      BusinessRegistryConfigurationType[]
   >([])*/

   const [addNewSubAccount] =
      useAddNewSubAccountToBusinessRegistryConfigurationMutation()

   const [removeSubAccount] =
      useRemoveSubAccountFromBusinessRegistryConfigurationMutation()

   const [progress, setProgress] = useState<{
      connect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
      disconnect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
   }>({ connect: null, disconnect: null })

   /* const getListBrcsBySubAccountEmail = async (email: string) => {
      const { data } = await getBusinessRegistryConfigurations({
         email,
      })

      if (data) {
         setListBrcs(data)
      }
   }*/

   const isValidateDate = (
      data: ConnectSubAccountToBRCSchemaType["data"][number]
   ) => {
      if (!data.businessRegistryId || data.businessRegistryId === "") {
         console.log("error")
         return false
      }

      return true
   }

   const handleSubmitForm = async (
      formData: ConnectSubAccountToBRCSchemaType
   ) => {
      setLoading(true)
      setProgress({
         connect: null,
         disconnect: null,
      })

      const { data: listBrcs } = await getBusinessRegistryConfigurations({
         email,
      })

      const subAccountsToRemove =
         listBrcs
            ?.filter(
               (brc) =>
                  !formData.data.some(
                     (formItem) => formItem.businessRegistryId === brc.fiscal_id
                  )
            )
            .map((item) => ({
               businessRegistryId: item.fiscal_id,
               businessRegistryName: item.name,
            })) ?? []

      const newConnections = formData.data.filter(
         (item) =>
            !listBrcs?.some(
               (brc) => brc.fiscal_id === item.businessRegistryId
            ) &&
            !subAccountsToRemove.some(
               (subAccount) =>
                  subAccount.businessRegistryId === item.businessRegistryId
            )
      )

      if (newConnections.length > 0) {
         setProgress((prev) => ({
            ...prev,
            connect: {
               success: null,
               error: null,
            },
         }))
         newConnections.map(async (data) => {
            if (isValidateDate(data)) {
               await processAddConnection(data)
            }
         })
      }

      if (subAccountsToRemove.length > 0) {
         setProgress((prev) => ({
            ...prev,
            disconnect: {
               success: null,
               error: null,
            },
         }))
         subAccountsToRemove.map(async (data) => {
            if (isValidateDate(data)) {
               await processRemoveConnection(data)
            }
         })
      }
      setLoading(false)
   }

   const processRemoveConnection = async (
      data: ConnectSubAccountToBRCSchemaType["data"][number]
   ) => {
      const response = await removeSubAccount({
         email: email,
         id: data.businessRegistryId!,
      })

      if (
         "error" in response &&
         "data" in response.error &&
         response.error.data
      ) {
         const detail = (response.error.data as { detail: string }).detail

         setProgress((prev) => ({
            ...prev,
            disconnect: {
               ...prev.disconnect,
               success: prev.disconnect?.success ?? null,
               error: [
                  ...(prev.disconnect?.error ?? []),
                  {
                     id: data.businessRegistryId!,
                     msg: detail ?? t("BusinessRegistry.removeSubAccountError"),
                  },
               ],
            },
         }))

         return
      }

      if ("data" in response) {
         setProgress((prev) => ({
            ...prev,
            disconnect: {
               ...prev.disconnect,
               success: [
                  ...(prev.disconnect?.success ?? []),
                  data.businessRegistryId!,
               ],
               error: prev.disconnect?.error ?? null,
            },
         }))
      }
   }

   const processAddConnection = async (
      data: ConnectSubAccountToBRCSchemaType["data"][number]
   ) => {
      const response = await addNewSubAccount({
         email: email,
         id: data.businessRegistryId!,
      })

      if (
         "error" in response &&
         "data" in response.error &&
         response.error.data
      ) {
         const detail = (response.error.data as { detail: string }).detail
         setProgress((prev) => ({
            ...prev,
            connect: {
               ...prev.connect,
               success: prev.connect?.success ?? null,
               error: [
                  ...(prev.connect?.error ?? []),
                  {
                     id: data.businessRegistryId!,
                     msg: detail ?? t("BusinessRegistry.removeSubAccountError"),
                  },
               ],
            },
         }))

         return
      }

      if ("data" in response) {
         setProgress((prev) => ({
            ...prev,
            connect: {
               ...prev.connect,
               success: [
                  ...(prev.connect?.success ?? []),
                  data.businessRegistryId!,
               ],
               error: prev.connect?.error ?? null,
            },
         }))
      }
   }

   /*useEffect(() => {
      if (brcs) {
         setListBrcs(brcs)
      }
   }, [brcs])*/

   if (isLoadingBrcs || isLoadingFiscalId) {
      return (
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 200 }}
         >
            <Box mt={2}>
               <CircularProgress />
            </Box>
         </Box>
      )
   }

   return (
      <ProcessForm
         setProgress={setProgress}
         loading={loading}
         brcs={brcs ?? []}
         handleSubmitForm={handleSubmitForm}
         progress={progress}
         itemsBusinessRegistryData={itemsBusinessRegistryData ?? []}
         handleClose={handleClose}
      />
   )
}

type ProcessFormProps = {
   handleSubmitForm: (data: ConnectSubAccountToBRCSchemaType) => Promise<void>
   progress: {
      connect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
      disconnect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
   }
   setProgress: (data: {
      connect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
      disconnect: {
         success: string[] | null
         error: { id: string; msg: string }[] | null
      } | null
   }) => void
   brcs: BusinessRegistryConfigurationType[]
   itemsBusinessRegistryData: BusinessRegistryConfigurationType[]
   handleClose: () => void
   loading?: boolean
}
const ProcessForm = (props: ProcessFormProps) => {
   const {
      progress,
      brcs,
      itemsBusinessRegistryData,
      handleSubmitForm,
      handleClose,
      setProgress,
      loading,
   } = props

   const { t } = useTranslation()

   const [
      getBusinessRegistryConfiguration,
      { isLoading, isError: isErrorFetching },
   ] = useLazyGetBusinessRegistryConfigurationQuery()

   const updateOptionsFiscalIds = async (value: string) => {
      const { data } = await getBusinessRegistryConfiguration({
         fiscal_id: value,
      })

      let fiscalIds: string[] = []
      if (isErrorFetching) {
         fiscalIds = []
      }

      if (data) {
         fiscalIds = data.receipts_enabled ? [data.fiscal_id] : []
      }

      return fiscalIds
   }

   const methods = useForm({
      defaultValues: {
         data: brcs.map((item) => ({
            businessRegistryId: item.fiscal_id,
            businessRegistryName: item.name,
         })),
      },
      mode: "all",
      resolver: zodResolver(ConnectSubAccountToBRCSchema),
   })

   const { handleSubmit, getValues, setValue } = methods

   const formatLabel = (id?: string | null, name?: string | null) => {
      if (name) {
         return `${name}: ${id}`
      }

      return id
   }

   const values = getValues()

   return (
      <>
         <DialogTitle>{t("BusinessRegistry.linkToBRC")}</DialogTitle>

         <DialogContent>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
               <Box display="flex" flexDirection="column" gridGap={16} p={2}>
                  {progress.connect && (
                     <Box mt={2} mb={2}>
                        {progress.connect.success &&
                           progress.connect.success.length > 0 && (
                              <Alert
                                 severity="success"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       connect: {
                                          error:
                                             progress.connect?.error || null,
                                          success: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.success")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.connectSubAccountsSuccess"
                                    )}
                                 </Typography>{" "}
                                 <br />
                                 <ul>
                                    {progress.connect?.success?.map((item) => (
                                       <li key={item}>{item}</li>
                                    ))}
                                 </ul>
                              </Alert>
                           )}

                        {progress.connect.error &&
                           progress.connect.error.length > 0 && (
                              <Alert
                                 severity="error"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       connect: {
                                          success:
                                             progress.connect?.success || null,
                                          error: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.error")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.connectSubAccountsError"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.connect?.error?.map((item) => (
                                       <li key={`${item.id}-${item.msg}`}>
                                          {item.id} :{" "}
                                          <b>
                                             <i>{item.msg}</i>
                                          </b>{" "}
                                       </li>
                                    ))}
                                 </ul>
                              </Alert>
                           )}
                     </Box>
                  )}

                  {progress.disconnect && (
                     <Box mt={2} mb={2}>
                        {progress.disconnect.success &&
                           progress.disconnect.success.length > 0 && (
                              <Alert
                                 severity="success"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       disconnect: {
                                          error:
                                             progress.disconnect?.error || null,
                                          success: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.success")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.disconnectSubAccountsSuccess"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.disconnect?.success?.map(
                                       (email) => (
                                          <li key={email}>{email}</li>
                                       )
                                    )}
                                 </ul>
                              </Alert>
                           )}

                        {progress.disconnect.error &&
                           progress.disconnect.error.length > 0 && (
                              <Alert
                                 severity="error"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setProgress({
                                       ...progress,
                                       disconnect: {
                                          success:
                                             progress.disconnect?.success ||
                                             null,
                                          error: null,
                                       },
                                    })
                                 }}
                              >
                                 <AlertTitle>{t("global.error")}</AlertTitle>
                                 <Typography>
                                    {t(
                                       "BusinessRegistry.disconnectSubAccountsError"
                                    )}
                                 </Typography>
                                 <br />
                                 <ul>
                                    {progress.disconnect?.error?.map((item) => (
                                       <li key={`${item.id}-${item.msg}`}>
                                          {item.id} :{" "}
                                          <b>
                                             <i>{item.msg}</i>
                                          </b>{" "}
                                       </li>
                                    ))}
                                 </ul>
                              </Alert>
                           )}
                     </Box>
                  )}

                  <SelectAutoComplete
                     disabled={loading}
                     name="data"
                     label="Business Registry"
                     placeholder={t("global.typeToSearch")}
                     initialOptions={
                        itemsBusinessRegistryData?.map((data) => ({
                           name: data.fiscal_id,
                           label: formatLabel(data.fiscal_id, data.name),
                        })) ?? []
                     }
                     loading={isLoading}
                     handleUpdateOptions={updateOptionsFiscalIds}
                     multiple
                     handleChange={(value) => {
                        if (!value) return
                        const currentBusinessRegistryIds = values?.data?.map(
                           (a) => a.businessRegistryId
                        )
                        if (
                           value.length !==
                              currentBusinessRegistryIds?.length ||
                           value.some(
                              (v) =>
                                 !currentBusinessRegistryIds?.includes(v.name)
                           )
                        ) {
                           const result = value.map((item) => {
                              const findInBusinessRegistryData =
                                 itemsBusinessRegistryData?.find(
                                    (businessRegistry) =>
                                       item.name === businessRegistry.fiscal_id
                                 )
                              if (!findInBusinessRegistryData) {
                                 return {
                                    businessRegistryId: "",
                                    businessRegistryName: item.name,
                                 }
                              }

                              return {
                                 businessRegistryId:
                                    findInBusinessRegistryData.fiscal_id,
                                 businessRegistryName:
                                    findInBusinessRegistryData.name,
                              }
                           })

                           setValue("data", result)
                        }
                     }}
                     value={
                        values?.data?.map((data) => ({
                           name: data.businessRegistryId,
                           label: formatLabel(
                              data.businessRegistryId,
                              data.businessRegistryName
                           ),
                        })) ?? []
                     }
                  />

                  <Box
                     display="flex"
                     justifyContent="flex-end"
                     gridGap={8}
                     mt={2}
                     mb={1}
                  >
                     <Button
                        color="default"
                        variant="contained"
                        onClick={handleClose}
                     >
                        Cancel
                     </Button>

                     <Button
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        type="submit"
                     >
                        {loading ? (
                           <CircularProgress size={20} />
                        ) : (
                           t("global.connect")
                        )}
                     </Button>
                  </Box>
               </Box>
            </form>
         </DialogContent>
      </>
   )
}

const ConnectSubAccountToBRC = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="sm" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default ConnectSubAccountToBRC
