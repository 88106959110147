import { Icon } from "@iconify/react"
import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   OpenbankingAccount,
   useReconnectAccountMutation,
} from "services/openbankingApi/accounts"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   account: OpenbankingAccount
}

const RowCTAs = ({ account }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [reconnectAccount, { isLoading }] = useReconnectAccountMutation()

   const handleReconnect = async () => {
      if (!account.enabled) return

      const response = await reconnectAccount({ uuid: account.uuid })

      if ("data" in response && response.data.reconnectUrl) {
         window.open(response.data.reconnectUrl, "_blank")
      }

      if ("error" in response) {
         enqueueSnackbar(t("openbanking.business_registry.reconnect_error"), {
            variant: "error",
         })
      }
   }

   return (
      <ButtonGroup>
         {account.enabled && (
            <Tooltip title={t("openbanking.business_registry.reconnet")}>
               <IconButton disabled={isLoading} onClick={handleReconnect}>
                  <Icon icon="hugeicons:connect" height={27} width={27} />
               </IconButton>
            </Tooltip>
         )}

         <Tooltip title={t("openbanking.business_registry.account_details")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "openbanking_account_details",
                        data: account,
                     })
                  )
               }}
            >
               <Description />
            </IconButton>
         </Tooltip>
      </ButtonGroup>
   )
}

export default RowCTAs
