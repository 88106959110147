import { Box } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import { FormikHelpers } from "formik"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import {
   useCreateApiConfigurationMutation,
   useEditApiConfigurationMutation,
   useGetApiConfigurationQuery,
} from "services/gov-it/apiConfigurations"
import { useAppDispatch } from "store"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import ApiConfigurationForm, {
   FormValues,
} from "views/ApiConfiguration/ApiConfigurationForm"
import Loader from "views/common/Loader"

const DIALOG_ID = "api.configuration.form"
const table: TableName = "api.configurations"

type ViolationType = {
   propertyPath: string
   message: string
}

type Props = {
   handleClose: () => void
   uuid: string
}
function Content({ handleClose, uuid }: Props) {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const dispatch = useAppDispatch()

   const { data, isLoading, error } = useGetApiConfigurationQuery(
      { uuid },
      { skip: !uuid }
   )

   const [editApiConfiguration, { isLoading: isUpdating }] =
      useEditApiConfigurationMutation()

   const [createApiConfiguration, { isLoading: isCreating }] =
      useCreateApiConfigurationMutation()

   const handleSaveApiConfiguration = async (
      uuid: string | null,
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      setErrors(null)
      setMessage(null)
      let response
      if (uuid) {
         response = await editApiConfiguration({
            ...values,
            uuid,
         })
      } else {
         response = await createApiConfiguration({
            ...values,
         })
      }

      if (
         "error" in response &&
         "data" in response.error &&
         "violations" in (response.error.data as any)
      ) {
         const violations = (response.error.data as any)
            ?.violations as ViolationType[]
         setErrors(violations)
         violations.forEach((v) => setFieldError(v.propertyPath, v.message))
         enqueueSnackbar(t("Integrazione.Validation Error"), {
            variant: "error",
         })
         return
      }

      if ("data" in response) {
         const evento = response.data
         if (uuid) {
            setMessage(`${t("Integrazione.Aggiornato")} `)
            enqueueSnackbar(`${t("Integrazione.Aggiornato")}`, {
               variant: "success",
            })
         } else {
            setMessage(`${t("Integrazione.Salvato")} - UUID: ${evento?.uuid}`)
            enqueueSnackbar(
               `${t("Integrazione.Salvato")} - UUID: ${evento?.uuid}`,
               { variant: "success" }
            )
         }

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const apiConfiguration = data

   return (
      <>
         {isLoading ? (
            <Loader />
         ) : (
            <>
               <DialogTitle id="api-configuration-dialog-title">
                  {t("Integrazione.Integrazione")}{" "}
                  {apiConfiguration && apiConfiguration.uuid
                     ? apiConfiguration.uuid
                     : null}
               </DialogTitle>

               <DialogContent>
                  {errors && errors.length > 0 && (
                     <Box mt={2} mb={2}>
                        <Alert
                           severity="error"
                           onClose={() => {
                              setErrors([])
                           }}
                        >
                           <AlertTitle>
                              {t("Integrazione.Validation Error")}
                           </AlertTitle>
                           {errors.map(function (error) {
                              return (
                                 <p>
                                    <strong>{error.propertyPath}</strong>{" "}
                                    {error.message}
                                 </p>
                              )
                           })}
                        </Alert>
                     </Box>
                  )}

                  {message && (
                     <Box mt={2} mb={2}>
                        <Alert
                           severity="success"
                           style={{
                              margin: 2,
                           }}
                           onClose={() => {
                              setMessage(null)
                           }}
                        >
                           <AlertTitle>{t("global.success")}</AlertTitle>
                           {message}
                        </Alert>
                     </Box>
                  )}

                  <ApiConfigurationForm
                     handleClose={handleClose}
                     isLoading={isUpdating || isLoading || isCreating}
                     handleSaveApiConfiguration={handleSaveApiConfiguration}
                     apiConfiguration={apiConfiguration}
                  />
               </DialogContent>
            </>
         )}
      </>
   )
}

const ApiConfigurationDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="md" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default ApiConfigurationDialog
