export const safeJsonParse = <T = any>(payload: unknown): T | null => {
   if (typeof payload !== "string") {
      return null
   }

   try {
      return JSON.parse(payload)
   } catch (error) {
      return null
   }
}
