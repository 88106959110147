export const API_CONFIGURATIONS = "/api-configurations"
export const BUSINESS_REGISTRY_CONFIGURATIONS =
   "/business-registry-configurations"
export const INVOICES = "/invoices"
export const INVOICES_DRAFT = "/invoices/draft"
export const INVOICES_SIMPLIFIED_DRAFT = "/invoices/simplified/draft"
export const SUB_ACCOUNTS = "/users/me/accounts"
export const EXPENSES = "/sistema-ts/expenses"
export const RECEIPTS = "/receipts"
export const RECEIPT = "/receipt"
export const INVOICES_STATS = "/invoices/stats"
export const INVOICES_SIMPLIFIED = "/invoices/simplified"
export const NOTIFICATIONS = "/notifications"
export const USERS_ME = "/users/me"
export const USER_CHANGE_PASSWORD = "/users/me/change-password"
export const USERS_ME_ALERT_CONFIGURATION = "/users/me/alert-configuration"
export const USERS_ME_RECIPIENT_CODE = "/users/me/recipient-code"
export const USERS_ME_ACCEPT_ONLY_VERIFIED_INVOICES =
   "/users/me/accept-only-verified-invoices"
// export const LOGIN = "/login_check";
export const PRE_SALES = "/pre-sales"
export const CONSUMPTIONS = "/consumptions"

// PEPPOL
export const ORDERS = "/orders"
export const ORDER_RESPONSES = "/order-responses"
export const DESPATCH_ADVICES = "/despatch-advices"
export const CREDIT_NOTES = "/credit-notes"
export const WEBHOOKS = "/webhooks"

// AUTH
export const LOGIN = "/login"
