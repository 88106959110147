import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { GovITInvoice } from "services/gov-it/types"
import { useAppDispatch, useAppSelector } from "store"
import { openDialog } from "store/slices/dialogs"
import { selectTableCheckedColumns, TableName } from "store/tablesState"

const table: TableName = "invoice.drafts"
type props = {
   invoices: GovITInvoice[] | undefined
}
const CheckedActionsButtons = ({ invoices }: props) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const checkedColumns = useAppSelector((state) =>
      selectTableCheckedColumns(state, { table })
   )

   return (
      <Button
         onClick={() => {
            dispatch(
               openDialog({
                  id: "invoice.drafts.sentBlockInvoices",
                  data: {
                     uuids: checkedColumns,
                     invoices: invoices ?? ([] as GovITInvoice[]),
                     mode: "send",
                  },
               })
            )
         }}
         color="primary"
         variant="contained"
         style={{
            marginRight: "10px",
            minWidth: "max-content",
         }}
      >
         {t("global.send_selected")}
      </Button>
   )
}

export default CheckedActionsButtons
