import {
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   makeStyles,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { format, parseISO } from "date-fns"
import { useTranslation } from "react-i18next"
import { DialogState } from "store/slices/dialogs"
import Label from "views/common/Label"

const useStyles = makeStyles((theme) => ({
   content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
}))

const DIALOG_ID = "openbanking_account_details"

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({
   iban,
   bban,
   swift,
   providerName,
   name,
   nature,
   balance,
   currencyCode,
   consentExpiresAt,
   handleClose,
}: Props) => {
   const { t } = useTranslation()
   const classes = useStyles()

   return (
      <>
         <DialogTitle>
            {t("openbanking.business_registry.account_details_dialog.title")}
         </DialogTitle>

         <DialogContent className={classes.content}>
            <List>
               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.iban"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={iban} />
                  </ListItemSecondaryAction>
               </ListItem>

               {iban == null && (
                  <ListItem divider>
                     <ListItemText
                        primary={t(
                           "openbanking.business_registry.account_details_dialog.bban"
                        )}
                     />
                     <ListItemSecondaryAction>
                        <ListItemText primary={bban} />
                     </ListItemSecondaryAction>
                  </ListItem>
               )}

               {iban == null && bban == null && (
                  <ListItem divider>
                     <ListItemText
                        primary={t(
                           "openbanking.business_registry.account_details_dialog.swift"
                        )}
                     />
                     <ListItemSecondaryAction>
                        <ListItemText primary={swift} />
                     </ListItemSecondaryAction>
                  </ListItem>
               )}

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.provider_name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={providerName} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.name"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={name} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.nature"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <Label color="primary">{nature}</Label>
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.balance"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={balance} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.currency_code"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText primary={currencyCode} />
                  </ListItemSecondaryAction>
               </ListItem>

               <ListItem divider>
                  <ListItemText
                     primary={t(
                        "openbanking.business_registry.account_details_dialog.consent_expires_at"
                     )}
                  />
                  <ListItemSecondaryAction>
                     <ListItemText
                        primary={format(
                           parseISO(consentExpiresAt),
                           "dd MMM yyyy HH:mm:ss"
                        )}
                     />
                  </ListItemSecondaryAction>
               </ListItem>
            </List>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color="default" variant="contained">
               Close
            </Button>
         </DialogActions>
      </>
   )
}

const AccountDetailsDialog = () => (
   <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
      {(data) => <Content {...data} />}
   </BaseDialog>
)

export default AccountDetailsDialog
