import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { TypeSmartReceipt } from "services/gov-it/smartReceipt/type"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import Page from "views/common/components/Page"
import CreateSmartReceiptDialog from "./components/CreateSmartReceiptDialog"
import SmartReceiptTableFilters from "./components/Filters"
import SmartReceiptDialog from "./components/SmartReceiptDialog"
import SmartReceiptsTable from "./components/Table"
import { getConfig } from "./helpers"

type SectionType = {
   type: TypeSmartReceipt
}
const SmartReceipt = ({ type }: SectionType) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()

   const hasRoleReceipts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: "ROLE_RECEIPTS",
      })
   )

   const config = getConfig(type)

   useEffect(() => {
      if (!hasRoleReceipts) {
         navigate("/")
      }
   }, [hasRoleReceipts, navigate])

   return (
      <>
         <Page className={classes.root} title={t(config.title)}>
            <Container maxWidth={false}>
               <SmartReceiptTableFilters table={config.table} />
               <SmartReceiptsTable type={type} table={config.table} />
            </Container>
         </Page>

         <SmartReceiptDialog table={config.table} />
         <CreateSmartReceiptDialog table={config.table} />
      </>
   )
}

export default SmartReceipt

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))
