import {
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   InputLabel,
   MenuItem,
   Select,
} from "@material-ui/core"
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { PreSaleAction } from "services/commonApi"

export type FormValues = {
   threshold: number
   action_type: PreSaleAction["action_type"]
   target: string
   enabled: boolean
}

type PreSaleActionField = {
   name: keyof FormValues
   labelTranslationId: string
   helperTranslationId?: string
} & ({ type: "text" | "number" } | { type: "select"; options: string[] })

const fields: PreSaleActionField[] = [
   {
      name: "threshold",
      labelTranslationId: "settings.pre_sale_actions.form_fields.threshold",
      type: "number",
      helperTranslationId:
         "settings.pre_sale_actions.form_fields.threshold_helper",
   },
   {
      name: "action_type",
      labelTranslationId: "settings.pre_sale_actions.form_fields.action_type",
      type: "select",
      options: ["alert_mail"],
   },
   {
      name: "target",
      labelTranslationId: "settings.pre_sale_actions.form_fields.target",
      type: "text",
      helperTranslationId:
         "settings.pre_sale_actions.form_fields.target_helper",
   },
]

type Props = {
   onCancel: VoidFunction
   formikProps: FormikProps<FormValues>
}

const Form = ({ onCancel, formikProps }: Props) => {
   const { t } = useTranslation()

   const {
      handleSubmit,
      values,
      touched,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
   } = formikProps

   return (
      <form onSubmit={handleSubmit}>
         <Grid container spacing={3}>
            {fields.map(
               ({
                  name,
                  labelTranslationId,
                  helperTranslationId,
                  ...field
               }) => (
                  <Grid key={name} item md={12} xs={12}>
                     {(field.type === "text" || field.type === "number") && (
                        <>
                           <Field
                              component={TextField}
                              type={field.type}
                              label={t(labelTranslationId)}
                              name={name}
                              helperText={touched[name] != null && errors[name]}
                              error={
                                 touched[name] != null && errors[name] != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                           />
                           {helperTranslationId != null && (
                              <FormHelperText>
                                 {t(helperTranslationId)}
                              </FormHelperText>
                           )}
                        </>
                     )}

                     {field.type === "select" && (
                        <FormControl
                           fullWidth
                           error={touched[name] != null && errors[name] != null}
                        >
                           <InputLabel>{t(labelTranslationId)}</InputLabel>
                           <Select
                              value={values[name]}
                              name={name}
                              onChange={handleChange}
                              label="Age"
                           >
                              {field.options.map((option) => (
                                 <MenuItem key={option} value={option}>
                                    {option}
                                 </MenuItem>
                              ))}
                           </Select>
                           {touched[name] != null && errors[name] != null && (
                              <FormHelperText>{errors[name]}</FormHelperText>
                           )}
                        </FormControl>
                     )}
                  </Grid>
               )
            )}

            <Grid item md={12} xs={12}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={values.enabled}
                        onChange={handleChange}
                        name="enabled"
                        color="primary"
                     />
                  }
                  label={t("settings.pre_sale_actions.form_fields.enabled")}
               />
            </Grid>
         </Grid>

         <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={8}
            mt={2}
            mb={1}
         >
            <Button color="default" variant="contained" onClick={onCancel}>
               {t("settings.pre_sale_actions.edit_form_cancel_cta")}
            </Button>

            <Button
               disabled={isSubmitting}
               color="primary"
               variant="contained"
               type="submit"
            >
               {t("settings.pre_sale_actions.edit_form_save_cta")}
            </Button>
         </Box>
      </form>
   )
}

export default Form
