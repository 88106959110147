import formatRequests from "./formatRequests"

export default function formatExpense(row) {
   return {
      id: row["@id"],
      uuid: row.id,
      codice_fiscale_proprietario: row.codice_fiscale_proprietario,
      codice_fiscale_cittadino: row.codice_fiscale_cittadino,
      amount: row.amount,
      created_at: row.created_at,
      updated_at: row.updated_at,
      requests: formatRequests(row.requests),
   }
}
