import formatResponses from "./formatResponses"

export default function formatRequest(row) {
   return {
      request_id: row.id,
      protocol_id: row.protocol_id,
      esito_chiamata: row.esito_chiamata,
      requested_at: row.requested_at,
      request_type: row.request_type,
      responses: formatResponses(row.responses),
   }
}
