import DateFnsUtils from "@date-io/date-fns"
import {
   KeyboardDatePicker,
   MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { format } from "date-fns"
import localeEn from "date-fns/locale/en-GB"
import localeIt from "date-fns/locale/it"
import { useField, useFormikContext } from "formik"
import T from "prop-types"
import { useTranslation } from "react-i18next"

const dateLocales = {
   en: localeEn,
   it: localeIt,
}
export default function DatePicker({ label, helperText, ...props }) {
   const { t, i18n } = useTranslation()
   const [{ onChange, ...field }, meta] = useField(props)
   const context = useFormikContext()
   const translatedHelperText = helperText ? t(helperText) : ""
   let error = meta.touched && meta.error
   let translatedError

   if (error && Object.entries(error).length !== 0) {
      const thunks = error.split("|")
      translatedError = t(
         thunks.shift(),
         thunks.reduce((acc, next, index) => {
            acc[`val${index + 1}`] = next
            return acc
         }, {})
      )
   }

   function handleOnChange(value) {
      let dateToSet
      try {
         dateToSet = format(value, "yyyy-MM-dd")

         if (props.autoSelectBeginningOfTheDay === true) {
            dateToSet += " 23:59:59"
         }

         if (props.autoSelectEndOfTheDay === true) {
            dateToSet += " 23:59:59"
         }
      } catch (e) {
         dateToSet = ""
      }
      context.setFieldValue(field.name, dateToSet)
   }

   let language = i18n.language
   if (!Object.keys(dateLocales).includes(language)) language = "it"
   return (
      <MuiPickersUtilsProvider
         locale={dateLocales[language]}
         utils={DateFnsUtils}
      >
         <KeyboardDatePicker
            disabled={props.disabled}
            clearable
            format="dd/MM/yyyy"
            label={t(label)}
            KeyboardButtonProps={{
               "aria-label": "change date",
            }}
            error={!!error}
            helperText={translatedError || translatedHelperText}
            {...field}
            {...props}
            onChange={handleOnChange}
         />
      </MuiPickersUtilsProvider>
   )
}
DatePicker.propTypes = {
   label: T.string,
   autoSelectBeginningOfTheDay: T.bool,
   autoSelectEndOfTheDay: T.bool,
}
DatePicker.defaultProps = {
   autoSelectBeginningOfTheDay: false,
   autoSelectEndOfTheDay: false,
}
