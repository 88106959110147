import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Page from "views/common/components/Page"
import AccessCodeCheck from "./AccessCodeCheck"
import InvoiceDetails from "./Details"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const PublicInvoice = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const [protectedInvoiceCode, setProtectedInvoiceCode] = useState<
      string | null
   >(null)

   return (
      <Page className={classes.root} title={t("pl.public_invoice.title")}>
         <Container maxWidth="lg">
            <Box mb={4}>
               <Typography variant="h1">
                  {t("pl.public_invoice.title")}
               </Typography>
            </Box>

            {protectedInvoiceCode == null && (
               <AccessCodeCheck
                  onSuccessfulVerification={(code) => {
                     setProtectedInvoiceCode(code)
                  }}
               />
            )}

            {protectedInvoiceCode != null && (
               <InvoiceDetails protectedInvoiceCode={protectedInvoiceCode} />
            )}
         </Container>
      </Page>
   )
}

export default PublicInvoice
