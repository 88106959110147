import Box from "@material-ui/core/Box"
import ReactJson from "react-json-view"

export default function TabPanelJson(props) {
   const { value, index, order } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         {value === index && (
            <Box p={3}>
               <ReactJson
                  src={JSON.parse(order)}
                  name={false}
                  enableClipboard={function (copy) {
                     navigator.clipboard
                        .writeText(JSON.stringify(copy.src, null, 4))
                        .then()
                  }}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  iconStyle="triangle"
               />
            </Box>
         )}
      </div>
   )
}
